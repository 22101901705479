import React from "react";
import { Link } from "react-router-dom";

const FiltersTab = () => {
  return (
    <div>
      <Link to="/Institutions/NewPlayerRegistration">
        <button className="w-54 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform">
          Add New Player
        </button>
      </Link>
      {/* Filters Tab */}
    </div>
  );
};

export default FiltersTab;
