import React from "react";
import Creatable from "react-select/creatable";
import demoImage from "../../../../../assets/redball.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCityList } from "../../../Helper";
import { useEffect, useState } from "react";

const EditableForm = ({
  updatedName,
  setUpdatedName,
  updatedAddress,
  setUpdatedAddress,
  updatedCity,
  setUpdatedCity,
  updatedPincode,
  setUpdatedPincode,
  updatedGender,
  setUpdatedGender,
  updatedParentOccupation,
  setUpdatedParentOccupation,
  updatedContactNo,
  setUpdatedContactNo,
  updatedFatherContactNo,
  setUpdatedFatherContactNo,
  updatedMotherContactNo,
  setUpdatedMotherContactNo,
  updatedDob,
  setUpdatedDob,
  updatedDateOfJoining,
  setUpdatedDateOfJoining,
  updatedPreferredTiming,
  setUpdatedPreferredTiming,
  updatedNameOfAcademicInstitution,
  setUpdatedNameofAcademicInstitution,
  updatedBatchAllotted,
  setUpdatedBatchAllotted,
  updatedPersona,
  setUpdatedPersona,
  updatedPlayerProfile,
  setUpdatedPlayerProfile,
  updatedIsAllRounder,
  setUpdatedIsAllRounder,
  updatedIsWicketKeeper,
  setUpdatedIsWicketKeeper,
  updatedBatting,
  setUpdatedBatting,
  updatedBowling,
  setUpdatedBowling,
  updatedFielding,
  setUpdatedFielding,
  updatedMedicalCondition,
  setUpdatedMedicalCondition,
}) => {
  // console.log(updatedDob);
  // console.log(updatedDateOfJoining);
  const [cityList, setCityList] = useState([]);
  const fetchCityList = async () => {
    try {
      const response = await getCityList();
      // console.log(response)
      setCityList(response);
    } catch (error) {
      console.error("Error while fetching cities list", error);
    }
  };
  useEffect(() => {
    fetchCityList();
  }, []);

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleNameUpdate = (e) => {
    setUpdatedName(e.target.value);
  };

  const handleAddressUpdate = (e) => {
    setUpdatedAddress(e.target.value);
  };

  const handleCityUpdate = (city) => {
    setUpdatedCity(city.value);
  };

  const handlePincodeUpdate = (e) => {
    setUpdatedPincode(e.target.value);
  };

  const handleGenderUpdate = (gender) => {
    setUpdatedGender(gender);
  };

  const handleParentOccupationUpdate = (parentOccupation) => {
    setUpdatedParentOccupation(parentOccupation);
  };

  const handleContactNoUpdate = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/\D/g, "");
    setUpdatedContactNo(numericInput);
  };

  const handleFatherContactNoUpdate = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/\D/g, "");
    setUpdatedFatherContactNo(numericInput);
  };

  const handleMotherContactNoUpdate = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/\D/g, "");
    setUpdatedMotherContactNo(numericInput);
  };

  const handleDobUpdate = (dob) => {
    const utcDate = new Date(dob);
    utcDate.setUTCHours(0, 0, 0, 0);
    setUpdatedDob(utcDate);
  };

  const handleDateOfJoiningUpdate = (date_of_joining) => {
    const utcDate = new Date(date_of_joining);
    utcDate.setUTCHours(0, 0, 0, 0);
    setUpdatedDateOfJoining(utcDate);
  };

  const handlePreferredTimingUpdate = (preferred_timing) => {
    setUpdatedPreferredTiming(preferred_timing);
  };

  const handleNameofAcademicInstitutionUpdate = (e) => {
    setUpdatedNameofAcademicInstitution(e.target.value);
  };

  const handlePersonaUpdate = (persona) => {
    setUpdatedPersona(persona);
  };

  const handleIsAllRounderUpdate = () => {
    console.log("Before toggle:", updatedIsAllRounder);
    setUpdatedIsAllRounder((prevValue) => (prevValue === "1" ? "0" : "1"));
    console.log("After toggle:", updatedIsAllRounder);
  };

  const handleisWicketKeeperUpdate = () => {
    setUpdatedIsWicketKeeper((prevValue) => (prevValue === "1" ? "0" : "1"));
  };

  const handlePlayerProfileUpdate = (player_profile) => {
    setUpdatedPlayerProfile(player_profile);
  };

  const handleBattingUpdate = (batting) => {
    setUpdatedBatting(batting);
  };

  const handleBowlingUpdate = (bowling) => {
    setUpdatedBowling(bowling);
  };

  const handleFieldingUpdate = (fielding) => {
    setUpdatedFielding(fielding);
  };

  const handleMedicalCondtionUpdate = (e) => {
    setUpdatedMedicalCondition(e.target.value);
  };
  return (
    <div className="grid grid-cols-2 gap-36">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={updatedName ?? ""}
            onChange={handleNameUpdate}
            required
            className="col-span-2 p-2 rounded"
            placeholder="Name"
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="address">
            Address
          </label>
          <input
            type="text"
            id="address"
            value={updatedAddress ?? ""}
            onChange={handleAddressUpdate}
            className="col-span-2 p-2 rounded"
            placeholder="Address"
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="address">
            City
          </label>
          <Creatable
            options={cityList}
            placeholder="Select city"
            value={{
              value: updatedCity ? updatedCity : "",
              label: updatedCity ? updatedCity : "Select city",
            }}
            onChange={handleCityUpdate}
            isSearchable={true}
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="address">
            Pin Code
          </label>
          <input
            type="text"
            id="pin code"
            value={updatedPincode ?? ""}
            onChange={handlePincodeUpdate}
            className="p-2 rounded"
            placeholder="Pin Code"
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center">Gender</label>
          <div className="col-span-2 grid grid-cols-3 gap-4">
            <button
              type="button"
              onClick={() => handleGenderUpdate("Male")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedGender === "Male" ? "bg-gray-300" : "transparent"
              }`}
            >
              Male
            </button>
            <button
              type="button"
              onClick={() => handleGenderUpdate("Female")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedGender === "Female" ? "bg-gray-300" : "transparent"
              }`}
            >
              Female
            </button>
            <button
              type="button"
              onClick={() => handleGenderUpdate("Other")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedGender === "Other" ? "bg-gray-300" : "transparent"
              }`}
            >
              Other
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center">Parents' Occupation</label>
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={() => handleParentOccupationUpdate("service")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedParentOccupation === "service"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Service
            </button>
            <button
              type="button"
              onClick={() => handleParentOccupationUpdate("business")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedParentOccupation === "business"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Business
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="name">
            Contact Number
          </label>
          <div className="col-span-2 grid grid-cols-3 gap-4">
            <input
              type="text"
              id="name"
              value={updatedContactNo ?? ""}
              onChange={handleContactNoUpdate}
              required
              className="p-2 rounded"
              placeholder="Contact No."
            />
            <input
              type="text"
              id="name"
              value={updatedFatherContactNo ?? ""}
              onChange={handleFatherContactNoUpdate}
              className="p-2 rounded"
              placeholder="Father's No."
            />
            <input
              type="text"
              id="name"
              value={updatedMotherContactNo ?? ""}
              onChange={handleMotherContactNoUpdate}
              className="p-2 rounded"
              placeholder="Mother's No."
            />
          </div>
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="name">
            Date of Birth
          </label>
          <DatePicker
            // selected={
            //   isSameDate(updatedDob, new Date())
            //     ? registrationDataArray.date_of_birth != null
            //       ? new Date(registrationDataArray.date_of_birth)
            //       : new Date()
            //     : updatedDob
            // }
            selected={updatedDob}
            onChange={handleDobUpdate}
            dateFormat="dd-MM-yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={75}
            className="text-center p-1 rounded border border-black"
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="name">
            Date of Joining
          </label>
          <DatePicker
            // selected={
            //   isSameDate(updatedDateOfJoining, new Date())
            //     ? registrationDataArray.date_of_joining != null
            //       ? new Date(registrationDataArray.date_of_joining)
            //       : new Date()
            //     : updatedDateOfJoining
            // }
            selected={updatedDateOfJoining}
            onChange={handleDateOfJoiningUpdate}
            dateFormat="dd-MM-yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={75}
            className="text-center p-1 rounded border border-black"
          />
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center">Preferred Timing</label>
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={() => handlePreferredTimingUpdate("morning")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPreferredTiming === "morning"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Morning
            </button>
            <button
              type="button"
              onClick={() => handlePreferredTimingUpdate("evening")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPreferredTiming === "evening"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Evening
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3">
          <label className="flex items-center" htmlFor="name">
            Name of School/College
          </label>
          <input
            type="text"
            id="name"
            value={updatedNameOfAcademicInstitution ?? ""}
            onChange={handleNameofAcademicInstitutionUpdate}
            className="col-span-2 p-2 rounded"
            placeholder="School/College"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 justify-start">
        {/* Remove invisible class from css below to enable this */}
        <div className="flex flex-col items-center gap-4 invisible">
          <img className="w-40 border border-black border-2" src={demoImage} />
          <button className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform">
            Upload
          </button>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center">Persona</label>
          <div className="col-span-3 grid grid-cols-3 gap-4">
            <button
              type="button"
              onClick={() => handlePersonaUpdate("1")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPersona === "1" ? "bg-gray-300" : "transparent"
              }`}
            >
              Player
            </button>
            <button
              type="button"
              onClick={() => handlePersonaUpdate("2")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPersona === "2" ? "bg-gray-300" : "transparent"
              }`}
            >
              Coach
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center">Player Profile</label>
          <div className="col-span-3 grid grid-cols-4 gap-4">
            <button
              type="button"
              onClick={handleIsAllRounderUpdate}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedIsAllRounder === "1" ? "bg-gray-300" : "transparent"
              }`}
            >
              All-rounder
            </button>
            <button
              type="button"
              onClick={() => handlePlayerProfileUpdate("Batsman")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPlayerProfile === "Batsman"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Batsman
            </button>
            <button
              type="button"
              onClick={() => handlePlayerProfileUpdate("Bowler")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedPlayerProfile === "Bowler"
                  ? "bg-gray-300"
                  : "transparent"
              }`}
            >
              Bowler
            </button>
            <button
              type="button"
              onClick={handleisWicketKeeperUpdate}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedIsWicketKeeper === "1" ? "bg-gray-300" : "transparent"
              }`}
            >
              Wicketkeeper
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center">Batting</label>
          <div className="col-span-3 grid grid-cols-3 gap-4">
            <button
              type="button"
              onClick={() => handleBattingUpdate("Right Hand")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedBatting === "Right Hand" ? "bg-gray-300" : "transparent"
              }`}
            >
              Right Hand
            </button>
            <button
              type="button"
              onClick={() => handleBattingUpdate("Left Hand")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedBatting === "Left Hand" ? "bg-gray-300" : "transparent"
              }`}
            >
              Left Hand
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center">Bowling</label>
          <div className="col-span-3 grid grid-cols-3 gap-4">
            <button
              type="button"
              onClick={() => handleBowlingUpdate("Fast")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedBowling === "Fast" ? "bg-gray-300" : "transparent"
              }`}
            >
              Fast
            </button>
            <button
              type="button"
              onClick={() => handleBowlingUpdate("Off Spin")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedBowling === "Off Spin" ? "bg-gray-300" : "transparent"
              }`}
            >
              Off Spin
            </button>
            <button
              type="button"
              onClick={() => handleBowlingUpdate("Leg Spin")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedBowling === "Leg Spin" ? "bg-gray-300" : "transparent"
              }`}
            >
              Leg Spin
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center">Fielding</label>
          <div className="col-span-3 grid grid-cols-3 gap-4">
            <button
              type="button"
              onClick={() => handleFieldingUpdate("Right Hand")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedFielding === "Right Hand" ? "bg-gray-300" : "transparent"
              }`}
            >
              Right Hand
            </button>
            <button
              type="button"
              onClick={() => handleFieldingUpdate("Left Hand")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedFielding === "Left Hand" ? "bg-gray-300" : "transparent"
              }`}
            >
              Left Hand
            </button>
            <button
              type="button"
              onClick={() => handleFieldingUpdate("keeping")}
              className={`rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform ${
                updatedFielding === "keeping" ? "bg-gray-300" : "transparent"
              }`}
            >
              Wicketkeeper
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <label className="flex items-center" htmlFor="medical condition">
            Medical Condition
          </label>
          <input
            type="text"
            id="medical condition"
            value={updatedMedicalCondition ?? ""}
            onChange={handleMedicalCondtionUpdate}
            className="col-span-3 p-2 rounded"
            placeholder="Medical Condition"
          />
        </div>
      </div>
    </div>
  );
};

export default EditableForm;
