import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { userLogin } from "./Helper";
import { useForm } from "react-hook-form";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const handleLogin = (credentials) => {
    userLogin(credentials)
      .then((data) => {
        console.log(data.data);
        localStorage.setItem("WellplayedToken", JSON.stringify(data.data));
        navigate("/Institutions");
        window.location.reload();
      })
      .catch((err) => {
        alert("Invalid Credentials");
        console.log(err);
      });
  };
  const forgotPassword = (e) => {
    e.preventDefault();
    navigate("/ForgotPassword");
    // navigate("/ForgotPassword", { state: { username: details.username } })
  };

  return (
    <div className="h-screen" style={{ backgroundColor: "#2B303B" }}>
      <div className="p-3">
        {/* <img src={TwaritLogo} height={50} alt="LOGO"/> */}
        <div className="p-2 grid grid-cols-3 gap-5">
          <div></div>
          <div className="pt-24 ">
            <div className="shadow-2xl p-8 bg-white rounded-md">
              <h4 className="font-bold text-2xl ">Log In</h4>

              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="pt-4">
                  <div className="form-control">
                    <label
                      htmlFor="username"
                      className="form-label inline-block mb-2 text-gray-700"
                    >
                      Email ID
                    </label>
                    <input
                      {...register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      })}
                      type="text"
                      id="email"
                      placeholder="Email"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                    {errors.email && errors.email.type === "required" && (
                      <p className="errorMsg">Email is required.</p>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <p className="errorMsg">Email is not valid.</p>
                    )}
                  </div>

                  <br />

                  <div className="form-control">
                    <label
                      htmlFor="password"
                      className="form-label inline-block mb-2 text-gray-700"
                    >
                      Password
                    </label>
                    <input
                      {...register("password", {
                        required: true,
                        pattern:
                          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        minLength: 8,
                      })}
                      type="password"
                      id="password"
                      placeholder="Password"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                    {errors.password && errors.password.type === "required" && (
                      <p className="errorMsg">Password is required.</p>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <p className="errorMsg">
                        Password must contain At least One Capital Letter, One
                        Small Letter, One Special Character
                      </p>
                    )}
                    {errors.password &&
                      errors.password.type === "minLength" && (
                        <p className="errorMsg">
                          Password Should Be At Least 8 Character
                        </p>
                      )}
                  </div>

                  <div className="py-4 grid grid-cols-2 w-full">
                    <div className="text-left"></div>
                    <h5
                      className="text-right cursor-pointer"
                      style={{ color: "#757575" }}
                      onClick={forgotPassword}
                    >
                      Forgot Password?
                    </h5>
                  </div>
                  <div className="form-control">
                    <Button
                      type="submit"
                      className="w-full"
                      variant="contained"
                      style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
