import React, { useEffect, useState, useMemo } from 'react';
import { GetAllAnalysis, GetPlayerListName, addMissingVideoLink, DeCompressAllVideosAPI, addMissingVideoLinkNew, AddVideoTagList, VideoGalleryListByInning, CheckUmpireOTP, ZipDownloadMatchAPI, ZipDownloadMatchCount } from '../Match/Helper';
import Pagination from '../../../assets/js/pagination';
import data from './demodata.json';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Swal from "sweetalert2";
import firebase from '../../../firebase';
import validateInput from '../../MatchCenter/MatchAna/MatchValidation';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { useOutletContext, useNavigate } from "react-router-dom";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Button, FormControl, FormControlLabel, RadioGroup, Radio, ButtonGroup, IconButton, Typography, ListItem, ListItemText, List, CircularProgress,
} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import "./PreviousMatchData.css";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Screenshort1 from "../../../assets/icons2/Screenshot1.jpg";
import Screenshort2 from "../../../assets/icons2/Screenshot2.jpg";
import Screenshort3 from "../../../assets/icons2/Screenshot3.jpg";
// import Screenshort2 from ".../assets/icons2/Screenshot2.jpg";


const Match = () => {
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [showVideoTagModal, setShowVideoTagModal] = React.useState(false);
  const [showVideoTagGalleryModal, setShowVideoTagGalleryModal] = React.useState(false);
  const [downloadVideoHeadingTournament, setDownloadVideoHeadingTournament] = React.useState("");
  const [selectedPlayerId, setSelectedPlayerId] = React.useState('all');
  const [playerListData, setPlayerList] = useState([]);
  const [downloadVideoHeadingMatch, setDownloadVideoHeadingMatch] = React.useState("");
  const [downloadVideoHeadingMatchID, setDownloadVideoHeadingMatchID] = React.useState("");
  const [downloadInning, setDownloadInning] = useState("1");
  const [selectedPlayer, setSelectedPlayer] = useState("all");
  const [selectedDownloadType, setSelectedDownloadType] = useState("4,6");
  const [videoCount, setVideoCount] = useState(null);
  const [loadingcount, setLoadingCount] = useState(true);


  useEffect(() => {
    getVideoCount(downloadVideoHeadingMatchID, downloadInning, selectedPlayer, selectedDownloadType);
  }, []);


  // const handleDownloadInning = (e) => {
  //   setDownloadInning(e.target.value);
  // }
  const handleDownloadInning = (e) => {
    setDownloadInning(e.target.value);
    // Call API to get video count
    getVideoCount(downloadVideoHeadingMatchID, e.target.value, selectedPlayer, selectedDownloadType);
  }
  const handleDownloadPlayer = (e) => {
    setSelectedPlayer(e.target.value);
    // Call API to get video count
    getVideoCount(downloadVideoHeadingMatchID, downloadInning, e.target.value, selectedDownloadType);
  }
  const handleDownloadType = (e) => {
    setSelectedDownloadType(e.target.value);
    // Call API to get video count
    getVideoCount(downloadVideoHeadingMatchID, downloadInning, selectedPlayer, e.target.value);
  }

  // Define the function to fetch the video count
  const getVideoCount = async (match_id, current_inning, player_id, download_type) => {
    try {
      setLoadingCount(true);
      const videoCount = await ZipDownloadMatchCount(match_id, current_inning, player_id, download_type); // Call your API function with the match_id
      console.log("Video count:", videoCount);
      setVideoCount(videoCount.videoCount);
      setLoadingCount(false);

      // Handle the video count as needed
    } catch (err) {
      setLoadingCount(false);

      console.error("Error fetching video count:", err);
      // Handle errors
    }
  }
  const handleDownloadModalClose = () => {
    setDownloadInning('1'); // Reset the inning field
    setSelectedPlayer('all'); // Reset the player field
    setSelectedDownloadType('4,6'); // Reset the downloadType field
    setShowDownloadModal(false); // Close the modal
  }


  const [popupMatch, setPopupMatch] = useState([]);
  const [IsDataLoaded, setIsDataLoaded] = useState(0);
  const [current_inning, setCurrentInning] = useState([]);
  const [camera1User, setCamera1User] = useState([]);
  const [camera2User, setCamera2User] = useState([]);
  const [cameraLiveFirstAvailable, setCameraLiveFirstAvailable] = useState([]);
  const [cameraLiveSecondAvailable, setLiveCameraSecondAvailable] = useState([]);
  const [cameraFirstAvailable, setCameraFirstAvailable] = useState([]);
  const [cameraSecondAvailable, setCameraSecondAvailable] = useState([]);
  const db = firebase.firestore();
  const [showTeamBModal, setShowTeamBModal] = React.useState(false);
  const startStopCamCollection = firebase.firestore().collection("StartStopCamCollections");
  const startStopLiveCollection = firebase.firestore().collection("StartStopLiveCollections");
  const [currentPage, setCurrentPage] = useState(1);
  const [previousMatches, setPreviousMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const PageSize = 10; // number of items to display per page
  const [pageCount, setPageCount] = useState(0);
  const [IsVideoTaggedClick, setIsVideoTaggedClick] = useState(0);
  const [IsVideoTaggedClickNew, setIsVideoTaggedClickNew] = useState(0);
  const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
  const getAllDocuments = async (collectionName) => {
    const documents = await db.collection(collectionName).get();
    return documents.docs.map((doc) => doc.id);
  };
  const getAllLiveDocuments = async (collectionName) => {
    const livedocuments = await db.collection(collectionName).get();
    return livedocuments.docs.map((doc) => doc.id);
  };
  const getLiveDocument = async (collectionName, documentId) => {
    const documentRef = db.collection(collectionName).doc(documentId);
    const documentSnapshot = await documentRef.get();
    if (documentSnapshot.exists) {
      return documentSnapshot.data();
    } else {
      return null;
    }
  };
  const [toggle, setToggle] = useState(true);
  const [tagmatchid, setTagmatchid] = useState('');
  const [gallerytagmatchid, setGalleryTagmatchid] = useState('');
  const [cameraError, setCameraError] = useState({});
  const [analysis, setAnalysis] = useState({ tournament_id: "", match_id: "", bat1_id: "", ball1_id: "", camera_1: "", camera_2: "" });
  const [headerTitle, setHeaderTitle] = useOutletContext();
  useEffect(() => {
    setHeaderTitle("Previous Match Data");
    GetAllAnalysis()
      .then((data) => {
        if (data?.status) {
          setPreviousMatches(data?.data);
          setPageCount(data.data.length);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false); // set loading to false once data is received (or error occurs)
      });
  }, [currentPage, setHeaderTitle]);
  const currentTableData = useMemo(() => {
    const startIndex = (currentPage - 1) * PageSize;
    const endIndex = startIndex + PageSize;
    return previousMatches?.slice(startIndex, endIndex);
  }, [currentPage, previousMatches]);
  const [umpire1id, setumpire1id] = useState();
  const [umpire2id, setumpire2id] = useState();
  const redirectToAnalysis = async (match, matchValidate) => {
    if (matchValidate == 'validate') {
      setPopupMatch(match);
      setIsDataLoaded(match.id)
      setCurrentInning(match.current_inning);
      setumpire1id(match.umpire1.id)
      setumpire2id(match.umpire2.id)
      setCamera1User(match.umpire1.full_name);
      setCamera2User(match.umpire2.full_name);
      cameraLiveFirstAvailable.length = 0;
      cameraLiveSecondAvailable.length = 0;
      cameraFirstAvailable.length = 0;
      cameraSecondAvailable.length = 0;
      if (match.Camera1User != "") {
        let cam1_id = match.Camera1User;
        const documents = await getAllDocuments('StartStopCamCollections');
        let livedocuments = []; // declare and initialize the variable outside the loop
        livedocuments = await getAllLiveDocuments('StartStopLiveCollections');
        for (let i = 0; i < livedocuments.length; i++) {
          const livedoc = await getLiveDocument('StartStopLiveCollections', livedocuments[i]);
          if ((livedoc != null) && (typeof livedoc.Camera1 != "undefined") && (typeof livedoc.Camera2 != "undefined")) {
            if ((livedoc.Camera1.UserID == cam1_id) || (livedoc.Camera2.UserID == cam1_id)) {
              let document_detail = { document_name: livedocuments[i] };
              cameraLiveFirstAvailable.push(document_detail);
            }
          }
        }
        for (let i = 0; i < documents.length; i++) {
          const documentData = await getLiveDocument('StartStopCamCollections', documents[i]);
          if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
            if ((documentData.Camera1.UserID == cam1_id) || (documentData.Camera2.UserID == cam1_id)) {
              let document_detail = { document_name: documents[i] };
              cameraFirstAvailable.push(document_detail);
            }
          }
        }
      }
      if (match.Camera2User != "") {
        let cam2_id = match.Camera2User;
        const documents = await getAllDocuments('StartStopCamCollections');
        let livedocuments = []; // declare and initialize the variable outside the loop
        livedocuments = await getAllLiveDocuments('StartStopLiveCollections');
        for (let i = 0; i < livedocuments.length; i++) {
          const livedoc = await getLiveDocument('StartStopLiveCollections', livedocuments[i]);
          if ((livedoc != null) && (typeof livedoc.Camera1 != "undefined") && (typeof livedoc.Camera2 != "undefined")) {
            if ((livedoc.Camera1.UserID == cam2_id) || (livedoc.Camera2.UserID == cam2_id)) {
              let document_detail = { document_name: livedocuments[i] };
              cameraLiveSecondAvailable.push(document_detail);
            }
          }
        }
        for (let i = 0; i < documents.length; i++) {
          const documentData = await getLiveDocument('StartStopCamCollections', documents[i]);
          if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
            if ((documentData.Camera1.UserID == cam2_id) || (documentData.Camera2.UserID == cam2_id)) {
              let document_detail = { document_name: documents[i] };
              cameraSecondAvailable.push(document_detail);
            }
          }
        }
      }
      if ((cameraFirstAvailable.length > 0) || (cameraSecondAvailable.length > 0)) {
        setIsDataLoaded(0)
        setShowTeamBModal(true)
      }
      else {
        saveMatchDetail(match).then(async saveInfo => {
          const match_data = match;
          const Camera1_User = match_data.umpire1;
          const Camera2_User = match_data.umpire2;
          let all_val = {
            match_data,
            doc: saveInfo,
            Camera1User: { id: Camera1_User.id, name: Camera1_User.full_name },
            Camera2User: { id: Camera2_User.id, name: Camera2_User.full_name }
          };
          let combined_data = {};
          combined_data = all_val["match_data"];
          combined_data.Camera1User = { id: Camera1_User.id, name: Camera1_User.full_name };
          combined_data.Camera2User = { id: Camera2_User.id, name: Camera2_User.full_name };
          combined_data.doc = saveInfo;
          navigate(`/MatchCenter/MatchAnalysisInput`, {
            state: combined_data
          });
        })
      }
    }
    else {
      saveMatchDetail(match).then(async saveInfo => {
        const match_data = match;
        const Camera1_User = match_data.umpire1;
        const Camera2_User = match_data.umpire2;
        let all_val = {
          match_data,
          doc: saveInfo,
          Camera1User: { id: Camera1_User.id, name: Camera1_User.full_name },
          Camera2User: { id: Camera2_User.id, name: Camera2_User.full_name }
        };
        let combined_data = {};
        combined_data = all_val["match_data"];
        combined_data.Camera1User = { id: Camera1_User.id, name: Camera1_User.full_name };
        combined_data.Camera2User = { id: Camera2_User.id, name: Camera2_User.full_name };
        combined_data.doc = saveInfo;
        navigate(`/MatchCenter/MatchAnalysisInput`, {
          state: combined_data
        });
      })
    }
  };
  const addMissingVideo = (match) => {
    setIsVideoTaggedClick(match.id);
    addMissingVideoLink(match).then(response => {
      if (response.data == "success") {
        setIsVideoTaggedClick(0);
        Swal.fire("Completed", "All video for selected tournament has been tagged successfully"
        );
      }
    });
  };
  const addMissingVideoNew = (match) => {
    setIsVideoTaggedClickNew(match.id);
    addMissingVideoLinkNew(match).then(response => {
      if (response.data == "success") {
        setIsVideoTaggedClickNew(0);
        Swal.fire("Completed", "All video for selected tournament has been tagged successfully"
        );
      }
    });
  };
  // const AddVideoTag = (match) => {
  //   AddVideoTagList(match.match_id).then(response => {
  //     console.log("response===>", response);
  //     if (response.success === "success") {
  //       Swal.fire("Completed", "All videos for the selected tournament have been tagged successfully");
  //     }
  //   });
  // };
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideoPopup, setShowVideoPopup] = useState(false);


  const [videoFiles, setVideoFiles] = React.useState([]);
  const AddVideoTag = (match) => {
    setTagmatchid(match.match_id);
    setDownloadVideoHeadingTournament(match.tournament_name);
    setDownloadVideoHeadingMatch(match.match_name);
    AddVideoTagList(match.match_id).then(response => {
      console.log("response===>", response);
      try {
        setShowVideoTagModal(true);
        setVideoFiles(response.allVideoFileTbl); // Store the response data in videoFiles state
      } catch (error) {
        console.error("Error processing response:", error);
      }
    }).catch(error => {
      console.error("Error calling API:", error);
    });
    setShowVideoTagModal(false);
  };

  const AddVideoTagLoad = () => {
    AddVideoTagList(tagmatchid).then(response => {
      console.log("response===>", response);
      try {
        setShowVideoTagModal(true);
        setVideoFiles(response.allVideoFileTbl); // Store the response data in videoFiles state
      } catch (error) {
        console.error("Error processing response:", error);
      }
    }).catch(error => {
      console.error("Error calling API:", error);
    });
    setShowVideoTagModal(false);
  };

  // const VideoTagGallery = (match) => {
  //   setGalleryTagmatchid(match.match_id);
  //   VideoGalleryListByInning(match.match_id, current_inning).then(response => {
  //     console.log("response===>", response);
  //     try {
  //       setShowVideoTagGalleryModal(true);
  //       setVideoFiles(response.allVideoFileTbl); // Store the response data in videoFiles state
  //     } catch (error) {
  //       console.error("Error processing response:", error);
  //     }
  //   }).catch(error => {
  //     console.error("Error calling API:", error);
  //   });
  //   setShowVideoTagGalleryModal(false);
  // };
  const [videoLoading, setVideoLoading] = React.useState(false);
  const [videoGallery, setVideoGallery] = React.useState([]);
  const [selectedInning, setSelectedInning] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [showLoadMore, setShowLoadMore] = React.useState(true);
  const VideoTagGallery = (match, current_inning = 1) => {
    setGalleryTagmatchid(match.match_id);
    setDownloadVideoHeadingTournament(match.tournament_name);
    setDownloadVideoHeadingMatch(match.match_name);
    VideoGalleryListByInning(match.match_id, current_inning,).then(response => {
      console.log("response===>", response);
      try {

        setVideoGallery(response.data); // Store the response data in videoFiles state
        setShowVideoTagGalleryModal(true);
        setShowLoadMore(true); // Show the Load More button
        setSelectedInning(1);
      } catch (error) {
        console.error("Error processing response:", error);
      }
    }).catch(error => {
      console.error("Error calling API:", error);
    });
    setShowVideoTagGalleryModal(false);
  };

  const VideoGalleryByInning = (current_inning) => {
    VideoGalleryListByInning(gallerytagmatchid, current_inning).then(response => {
      console.log("response===>", response);
      try {

        setVideoGallery(response.data);// Store the response data in videoFiles state
        setShowVideoTagGalleryModal(true);
        setShowLoadMore(true); // Show the Load More button
      } catch (error) {
        console.error("Error processing response:", error);
      }
    }).catch(error => {
      console.error("Error calling API:", error);
    });
    setShowVideoTagGalleryModal(false);
  };
  const loadMoreVideos = (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page

    // Increment the offset by the current length of the videoGallery array
    const newOffset = videoGallery.length;
    setOffset(newOffset);
    VideoGalleryListByInning(gallerytagmatchid, selectedInning, newOffset).then(response => {
      try {
        // Append the new videos to the existing videoGallery state
        setVideoGallery(prevGallery => [...prevGallery, ...response.data]);
        setShowLoadMore(response.data.length > 0);
      } catch (error) {
        console.error("Error processing response:", error);
      }
    }).catch(error => {
      console.error("Error calling API:", error);
    });
  };

  // const loadMoreVideos = () => {
  //   // Increment the offset by the current length of the videoGallery array
  //   const newOffset = offset + videoGallery.length;
  //   setOffset(newOffset);
  //   VideoGalleryListByInning(gallerytagmatchid, selectedInning, newOffset).then(response => {
  //     try {
  //       // Append the new videos to the existing videoGallery state
  //       setVideoGallery(prevGallery => [...prevGallery, ...response.data]);
  //     } catch (error) {
  //       console.error("Error processing response:", error);
  //     }
  //   }).catch(error => {
  //     console.error("Error calling API:", error);
  //   });
  // };

  // const LoadmoreVideoGallery = (match, current_inning = 1,) => {
  //   setGalleryTagmatchid(match.match_id);
  //   setDownloadVideoHeadingTournament(match.tournament_name);
  //   setDownloadVideoHeadingMatch(match.match_name);
  //   VideoGalleryListByInning(match.match_id, current_inning,).then(response => {
  //     console.log("response===>", response);
  //     try {

  //       setVideoGallery(response.data); // Store the response data in videoFiles state
  //       setShowVideoTagGalleryModal(true);
  //       setSelectedInning(1);
  //     } catch (error) {
  //       console.error("Error processing response:", error);
  //     }
  //   }).catch(error => {
  //     console.error("Error calling API:", error);
  //   });
  //   setShowVideoTagGalleryModal(false);
  // };

  // const refreshVideo = (index) => {
  //   const updatedVideoGallery = [...videoGallery];
  //   updatedVideoGallery[index].video_url += `?t=${new Date().getTime()}`;
  //   setVideoGallery(updatedVideoGallery);
  // };
  // const handleRefreshVideo = (e, videoUrl, index) => {
  //   e.preventDefault();

  //   // Log the current state before the update
  //   console.log("Current video gallery state:", videoGallery);

  //   // Update the video gallery array with the new video data
  //   setVideoGallery(prevState => {
  //     const newVideoGallery = [...prevState];
  //     newVideoGallery[index] = { ...newVideoGallery[index], video_url: videoUrl };
  //     console.log("Updated video gallery:", newVideoGallery); // Log the updated array
  //     return newVideoGallery;
  //   });

  //   // Log the state after the update
  //   console.log("New video gallery state:", videoGallery);
  // };
  const handleRefreshVideo = (e, videoUrl, index) => {
    e.preventDefault();
    setVideoLoading(true);

    // Generate a random number for refresh
    const randomRefresh = Math.floor(Math.random() * 1000000);

    // Update the video gallery array with the new video data
    setVideoGallery(prevState => {
      const newVideoGallery = [...prevState];
      newVideoGallery[index] = { ...newVideoGallery[index], video_url: `${videoUrl}?date=${randomRefresh}` };
      return newVideoGallery;
    });
    setVideoLoading(false);
  };

  const handleCloseVideoGalleryModal = () => {
    setShowVideoTagGalleryModal(false);
    setShowLoadMore(true); // Show the Load More button
  }




  const ZipDownloadMatch = async (match) => {
    console.log("Match Detail", match)
    setShowDownloadModal(true);
    setDownloadVideoHeadingTournament(match.tournament_name);
    setDownloadVideoHeadingMatch(match.match_name);
    setDownloadVideoHeadingMatchID(match.match_id);
    try {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await GetPlayerListName(match.match_id);
      const playerListData = response.teamplayer; // Extract the teamplayer array
      console.log("Player List", playerListData);

      setPlayerList(playerListData);
      Swal.close();
      getVideoCount(match.match_id, downloadInning, selectedPlayer, selectedDownloadType);
    } catch (error) {
      Swal.close();
      console.error("Error fetching player list:", error);
    }

  };


  const saveMatchDetail = (match) => {
    return new Promise((resolve, reject) => {
      const Camera_1_User_ID = match.Camera1User;
      const Camera_2_User_ID = match.Camera2User;
      startStopCamCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const _doc = { ...doc.data() };
          if (JSON.stringify(doc.data()) !== JSON.stringify(_doc)) {
            doc.ref.set(_doc, { merge: true });
          }
        });
        const date = new Date(match.match_details.date);
        const dateObj = new Date(date);
        const formattedDate = dateObj.toISOString().split('T')[0];
        let docKey;
        if (current_inning == 1) {
          docKey = match.tournament_name + '_' + match.match_name + '_' + match.team_bat1 + '_' + formattedDate;
        }
        else {
          docKey = match.tournament_name + '_' + match.match_name + '_' + match.team_bat2 + '_' + formattedDate;
        }
        const doc = {
          Camera1: {
            CameraStatus: false,
            UserID: Camera_1_User_ID.toString()
          },
          Camera2: {
            CameraStatus: false,
            UserID: Camera_2_User_ID.toString()
          }
        }
        startStopCamCollection.doc(docKey).set(doc).then(() => {
          Swal.mixin({ position: 'bottom' }).fire({ text: `${docKey} added sucessfully.` });
          resolve({ [docKey]: doc });
        }).catch(err => {
          Swal.mixin({ position: 'bottom' }).fire({ text: `Error while adding document.` });
          reject(err);
        });
        startStopLiveCollection.doc(docKey).set(doc).then(() => {
          Swal.mixin({ position: 'bottom' }).fire({ text: `${docKey} added sucessfully.` });
          resolve({ [docKey]: doc });
        }).catch(err => {
          Swal.mixin({ position: 'bottom' }).fire({ text: `Error while adding document.` });
          reject(err);
        });
      });
    });
  };
  const isValid = () => {
    const { cameraError, isValid } = validateInput(analysis);
    return isValid;
  };

  const DeCompressAllVideos = () => {
    setIsUnzipVideoClick(true);
    DeCompressAllVideosAPI().then(response => {
      if (response.success == "success") {
        setIsUnzipVideoClick(false);
        Swal.fire("Completed", "All video has been unzip successfully"
        );
      }
    });
  }

  const [umpire1Popup, setUmpire1Popup] = useState(false)
  const [umpire1otp, setumpire1otp] = useState("")
  const OpenUmpire1Popup = (document_name) => { setUmpire1Popup(true) }
  const [umpire2Popup, setUmpire2Popup] = useState(false)
  const [umpire2otp, setumpire2otp] = useState("")
  const OpenUmpire2Popup = (document_name) => { setUmpire2Popup(true) }
  const ReleaseUmpire1 = () => {
    CheckUmpireOTP({ umpireid: umpire1id, umpire_otp: umpire1otp }).then(async (data) => {
      if (data.msg == 'match found') {
        let document_name = cameraFirstAvailable[0].document_name;
        const docRef = db.collection('StartStopCamCollections').doc(document_name);
        docRef.delete()
          .then(() => {
            const updatedData = cameraFirstAvailable.filter(item => item.document_name !== document_name);
            setCameraFirstAvailable(updatedData);
            Swal.fire("Deleted", "Selected match record has been deleted successfully"
            );
          })
          .catch((error) => {
          });
        setUmpire1Popup(false)
      } else {
        Swal.fire({ icon: 'error', title: '👎', text: 'Please enter valid match code' })
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', title: '⛔', text: err })
    })
  }
  const ReleaseUmpire2 = () => {
    CheckUmpireOTP({ umpireid: umpire2id, umpire_otp: umpire2otp }).then(async (data) => {
      if (data.msg == 'match found') {
        let document_name = cameraSecondAvailable[0].document_name;
        const docRef = db.collection('StartStopCamCollections').doc(document_name);
        docRef.delete()
          .then(() => {
            const updatedData = cameraSecondAvailable.filter(item => item.document_name !== document_name);
            setCameraSecondAvailable(updatedData);
            Swal.fire(
              "Deleted",
              "Selected match record has been deleted successfully"
            );
          })
          .catch((error) => {
          });
        setUmpire2Popup(false)
      } else {
        Swal.fire({
          icon: 'error', title: '👎', text: 'Please enter valid match code'
        })
      }
    }).catch((err) => {
      Swal.fire({
        icon: 'error', title: '⛔', text: err
      })
    })
  }
  const LiveReleaseUmpire1 = (document_name) => {
    const docRef = db.collection('StartStopLiveCollections').doc(document_name);
    docRef.delete()
      .then(() => {
        const updatedData = cameraLiveFirstAvailable.filter(item => item.document_name !== document_name);
        setCameraLiveFirstAvailable(updatedData);
        Swal.fire(
          "Deleted",
          "Selected match record has been deleted successfully"
        );
      })
      .catch((error) => {
      });
  }
  const LiveReleaseUmpire2 = (document_name) => {
    const docRef = db.collection('StartStopLiveCollections').doc(document_name);
    docRef.delete()
      .then(() => {
        const updatedData = cameraLiveSecondAvailable.filter(item => item.document_name !== document_name);
        setLiveCameraSecondAvailable(updatedData);
        Swal.fire("Deleted", "Selected match record has been deleted successfully"
        );
      })
      .catch((error) => {
      });
  }


  // const handleDownloadModalSubmit = (e) => {
  //   e.preventDefault();
  //   alert(downloadInning);
  // }
  // const handleDownloadModalSubmit = (e) => {
  //   e.preventDefault(); // Prevent default form submission
  //   const formData = {
  //    // tournament: downloadVideoHeadingTournament,
  //    match_id: downloadVideoHeadingMatchID,
  //    current_inning: e.target.elements.inning_id.value,
  //     player_id: e.target.elements.player_id.value,
  //     download_type: e.target.elements.download_type_id.value,
  //   };
  //   console.log("Form Data:", formData);
  //   // Add code to handle form submission (e.g., send data to server)
  // };
  const handleDownloadModalSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    const match_data = {
      // tournament: downloadVideoHeadingTournament,
      match_id: downloadVideoHeadingMatchID,
      current_inning: e.target.elements.inning_id.value,
      player_id: e.target.elements.player_id.value,
      download_type: e.target.elements.download_type_id.value,
    };
    const filename = `Current_inning_${match_data.current_inning}_Tournament_(${downloadVideoHeadingTournament})_Match_(${downloadVideoHeadingMatch})_Download_type_(${match_data.download_type})`;
    console.log("Form Data:", match_data);

    // Add code to send formData to your API downloadVideoHeadingMatch 
    ZipDownloadMatchAPI(match_data, filename).then(response => {
      // Handle the API response here
      if (response.success) {
        console.log("Download successful:", response.message);
        setShowDownloadModal(false);
      } else {
        console.log("Download failed:", response.message);
      }

    }).catch(error => {
      // Handle any errors that occur during the API call
      console.error("API Error:", error);
    });
  };


  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }}>
          Previous Match Data
        </h1>
      </div>
      <div style={{ textAlign: 'center' }}>
        {loading ? (
          // Render the loader while data is still being fetched
          <>
            <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>Please Wait Data is <span style={{ color: 'orange', fontWeight: '600' }}>Loading...</span></h2></>
        ) : (
          // Render the table once data is received
          <>
            {/* <Button
              size="medium"
              style={{ backgroundColor: "#0DF6E3", color: "#2B303B", marginRight: 9, float: 'right', marginBottom: '10px' }}
              variant="contained" onClick={DeCompressAllVideos} >
              <FolderZipIcon /> &nbsp;De-compress All Videos &nbsp;{IsUnzipVideoClick ? <><div className="loader"></div> &nbsp; <span style={{ color: "#FF0000" }}>Compressing, Please Wait...</span></> : ''}
            </Button> */}
            <table className="alternate-row-colors " style={{ borderCollapse: 'collapse', width: '100%', }}>
              <thead>
                <tr>
                  <th className="previousDatath">Tournament Name</th>
                  <th className="previousDatath">Match Name</th>
                  <th className="previousDatath">Match Date</th>
                  <th className="previousDatath">First Inning Batting</th>
                  <th className="previousDatath">Second Inning Batting</th>
                  <th className="previousDatath">Umpire 1</th>
                  <th className="previousDatath">Umpire 2</th>
                  <th className="previousDatath">Overs</th>
                  <th className="previousDatath">First Inning Status</th>
                  <th className="previousDatath">Second Inning Status</th>
                  <th className="previousDatath">Action</th>
                </tr>
              </thead>
              <tbody >
                {currentTableData?.map(match => (
                  <tr key={match.id} >
                    <td className='dataTd'>{match.tournament_name}</td>
                    <td className='dataTd'>{match.match_name}</td>
                    <td className='dataTd'>{match.match_details.date}</td>
                    <td className='dataTd'>{match.team_bat1}</td>
                    <td className='dataTd'>{match.team_bat2}</td>
                    <td className='dataTd'>{match.umpire1.full_name}</td>
                    <td className='dataTd'>{match.umpire2.full_name}</td>
                    <td className='dataTd'>{match.overs}</td>
                    <td className='dataTd'>{match.inning1_end_match_over == '0' ? (<div>In Progress</div>) : (<div>Completed</div>)}</td>
                    <td className='dataTd'>{match.inning2_end_match_over == '0' ? (<div>In Progress</div>) : (<div>Completed</div>)}</td>
                    <td className='dataTd' id="action">
                      {IsDataLoaded == match.id ? (<div className="loader"></div>) : ''}
                      {match.umpire1 && match.umpire2 ? (<><Button onClick={() => redirectToAnalysis(match, 'validate')}><VisibilityIcon id={`visibilityIcon_${match.id}`} /></Button>
                        <ReactTooltip
                          anchorId={`visibilityIcon_${match.id}`}
                          place="left"
                          content="Start Analysis"
                          className="tooltip" />
                      </>) : (
                        <><Button><DoNotDisturbAltIcon id="no_umpire" style={{ color: 'red' }}></DoNotDisturbAltIcon></Button>
                          <ReactTooltip
                            anchorId="no_umpire"
                            place="bottom top"
                            content="Start analysis - No umpire available"
                            className="tooltip" /></>
                      )}
                      {IsVideoTaggedClick == match.id ? (<div className="loader"></div>) : ''}
                      {IsVideoTaggedClickNew == match.id ? (<div className="loader"></div>) : ''}
                      {/* <Button><VideoLabelIcon id={`all_video_tag_new_${match.id}`} onClick={() => addMissingVideoNew(match)} style={{ color: '#006400' }} /></Button> */}
                      <Button><LocalOfferIcon id={`all_video_tag_new_${match.id}`} onClick={() => AddVideoTag(match)} style={{ color: '#006400' }} /></Button>
                      <Button><OndemandVideoIcon id={`video_gallery${match.id}`} onClick={() => VideoTagGallery(match, 1)} style={{ color: '#006400' }} /></Button>
                      <ReactTooltip
                        anchorId={`all_video_tag_new_${match.id}`}
                        place="left"
                        content="Tag Pending Videos To Balls"
                        className="tooltip" />

                      <Button><DownloadIcon id={`download_${match.id}`} onClick={() => ZipDownloadMatch(match)} style={{ color: '#006400' }} /></Button>

                      <a
                        href={match.match_report || '#'}
                        target={match.match_report ? '_blank' : '_self'}
                        id={`download_report${match.id}`}
                        style={{
                          cursor: match.match_report ? 'pointer' : 'not-allowed',
                          color: match.match_report ? 'green' : 'red',
                          marginTop: '5px'
                        }}
                      >
                        <InsertDriveFileIcon id={`download_excel_${match.id}`} />
                      </a>
                      <ReactTooltip
                        anchorId={`download_report${match.id}`}
                        place="left"
                        content={match.match_report ? "Download Mobile Match Report" : "Mobile Match Report Not Available"}
                        className="tooltip" />

                      <ReactTooltip
                        anchorId={`download_${match.id}`}
                        place="left"
                        content="Download Match Videos"
                        className="tooltip" />
                      <ReactTooltip
                        anchorId={`video_gallery${match.id}`}
                        place="left"
                        content="Video Gallery"
                        className="tooltip" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table><Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={pageCount}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)} />
            <form>
              {showTeamBModal ? (
                <>
                  <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '800px' }}>
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h3 className="text-3xl font-semibold" style={{ fontSize: '22px' }}>
                            Firebase Database Result
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowTeamBModal(false)}>
                            <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                              ×
                            </span>
                          </button>
                        </div>
                        <div>
                          <p><strong>Camera Operating Umpires</strong></p>
                          <div style={{ display: "flex" }}>
                            <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 0px 11px 12px' }}>
                              <th style={{ fontSize: '14px', height: '21px' }}>Umpire 1 ({camera1User})</th>
                              <tbody>
                                <>
                                  {cameraFirstAvailable?.map(item => (
                                    <tr key={item.id}>
                                      <td style={{ border: '1px solid #9e9e9e' }}>{item.document_name}</td>
                                      <td style={{ border: '1px solid #9e9e9e' }} onClick={() => OpenUmpire1Popup(item.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                                    </tr>
                                  ))}
                                  {cameraFirstAvailable.length === 0 ? (
                                    <tr key={cameraFirstAvailable.length}>
                                      <td style={{ border: '1px solid #9e9e9e', color: 'green' }}>Umpire Is Now Available</td>
                                    </tr>
                                  ) : null}
                                </>
                              </tbody>
                            </table>
                            <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 14px 11px -1px' }}>
                              <th style={{ fontSize: '14px', height: '21px' }}>Umpire 2 ({camera2User})</th>
                              <tbody>
                                <>
                                  {cameraSecondAvailable?.map(item => (
                                    <tr key={item.id}>
                                      <td style={{ border: '1px solid #9e9e9e' }}>{item.document_name}</td>
                                      <td style={{ border: '1px solid #9e9e9e' }} onClick={() => OpenUmpire2Popup(item.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                                    </tr>
                                  ))}
                                  {cameraSecondAvailable.length === 0 ? (
                                    <tr key={cameraSecondAvailable.length}>
                                      <td style={{ border: '1px solid #9e9e9e', color: 'green' }}>Umpire Is Now Available</td>
                                    </tr>
                                  ) : null}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* this table is for live umpires */}
                        <div>
                          <p><strong>Live Streaming Umpires</strong></p>
                          <div style={{ display: "flex" }}>
                            <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 0px 11px 12px' }}>
                              <th style={{ fontSize: '14px', height: '21px' }}>Umpire 1 ({camera1User})</th>
                              <tbody>
                                <>
                                  {cameraLiveFirstAvailable?.map(item => (
                                    <tr key={item.id}>
                                      <td style={{ border: '1px solid #9e9e9e' }}>{item.document_name}</td>
                                      <td style={{ border: '1px solid #9e9e9e' }} onClick={() => LiveReleaseUmpire1(item.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                                    </tr>
                                  ))}
                                  {cameraLiveFirstAvailable.length === 0 ? (
                                    <tr key={cameraLiveFirstAvailable.length}>
                                      <td style={{ border: '1px solid #9e9e9e', color: 'green' }}>Umpire Is Now Available</td>
                                    </tr>
                                  ) : null}
                                </>
                              </tbody>
                            </table>
                            <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 14px 11px -1px' }}>
                              <th style={{ fontSize: '14px', height: '21px' }}>Umpire 2 ({camera2User})</th>
                              <tbody>
                                <>
                                  {cameraLiveSecondAvailable?.map(item => (
                                    <tr key={item.id}>
                                      <td style={{ border: '1px solid #9e9e9e' }}>{item.document_name}</td>
                                      <td style={{ border: '1px solid #9e9e9e' }} onClick={() => LiveReleaseUmpire2(item.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                                    </tr>
                                  ))}
                                  {cameraLiveSecondAvailable.length === 0 ? (
                                    <tr key={cameraLiveSecondAvailable.length}>
                                      <td style={{ border: '1px solid #9e9e9e', color: 'green' }}>Umpire Is Now Available</td>
                                    </tr>
                                  ) : null}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                          <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button" onClick={() => setShowTeamBModal(false)}>
                            Close
                          </button>
                          {cameraFirstAvailable.length == 0 && cameraSecondAvailable.length == 0 && cameraLiveFirstAvailable.length == 0 && cameraLiveSecondAvailable.length == 0 && (
                            <button type='button'
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              onClick={() => redirectToAnalysis(popupMatch, 'notValidate')}>
                              Start Analysis
                            </button>)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </form>
          </>
        )}
      </div>


      {umpire1Popup ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
            <div className="relative  my-6 mx-auto max-w-3xl" style={{ width: '38%', padding: '5px', marginBottom: '0%' }}>
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    Umpire 1 ({camera1User})
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setUmpire1Popup(false)}
                  ><ClearIcon />
                  </button>
                </div>
                {/* <div className="relative p-2 flex-auto">
                  <div className="m-2 grid gap-5">
                    <input type="number" id="overs" placeholder="Match Code" onChange={(e) => { setumpire1otp(e.target.value) }}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                  </div>
                </div> */}
                <div className="relative p-2 flex-auto">
                  <div className="m-2 grid gap-5">
                    <div className="w-full bg-white shadow-md rounded-lg p-4" style={{ height: '70px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px' }}>
                      <input
                        type="number"
                        id="overs"
                        placeholder="Enter Match Code"
                        onChange={(e) => { setumpire1otp(e.target.value) }}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button"
                    onClick={() => setUmpire1Popup(false)} >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button" onClick={ReleaseUmpire1}>
                    Verify Match Code
                  </button>
                </div>
                <div>
                  {/* <h2>Hello</h2> */}
                  <div className="flex justify-between" style={{ height: '26rem' }}>
                    <div className="w-1/2 p-4 rounded-lg shadow-md bg-white" style={{ height: '400px', marginLeft: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', background: "linear-gradient(to right, #0ED2F7, #B2FEFA)" }}>
                      {/* Content for first card */}
                      <img src={Screenshort1} alt="Image 1" id='DetailSS1' className="w-full h-full object-cover rounded-lg" style={{ width: '125%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', cursor: 'pointer' }} />
                    </div>
                    <div className="w-1/2 p-4 rounded-lg shadow-md bg-white ml-2" style={{ height: '400px', marginRight: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', background: 'linear-gradient(to right, #b0f4fff7, #36D1DC)' }}>
                      {/* Content for second card */}
                      {/* <img src={Screenshort2} alt="Image 2" className="w-full h-auto" /> */}
                      <img src={Screenshort3} alt="Image 1" id='DetailSS2' className="w-full h-full object-cover rounded-lg" style={{ width: '125%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', cursor: 'pointer' }} />
                    </div>
                  </div>
                  <ReactTooltip
                    anchorId="DetailSS1"
                    place="top"
                    html="
                                    <h2 style='text-align:center;color:lightgreen'>Record Video</h2><hr/>
                                    <h3 style='text-align:center;'>You need to sign in with Umpire credentials in the mobile app to delete data from the Firebase database.<br />Once you are logged in, click on the left-side drawer and then click on the 'Record Video' link.</h3>
                                    "
                    // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                    className="tooltip" />
                  <ReactTooltip
                    anchorId="DetailSS2"
                    place="top"
                    html="
                                    <h2 style='text-align:center;color:lightgreen'>Match Code</h2><hr/>
                                    <h3 style='text-align:center;'>Once you click on 'Record Video', you can see the Match Code at the left-bottom side.<br />Enter this match code in the input box above to delete it..</h3>
                                    "
                    // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                    className="tooltip" />
                </div>

              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {umpire2Popup ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto max-w-3xl" style={{ width: '38%', padding: '5px', marginBottom: '0%' }}>
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    Umpire 2 ({camera2User})
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setUmpire2Popup(false)}
                  ><ClearIcon />
                  </button>
                </div>
                <div className="relative p-2 flex-auto">
                  <div className="m-2 grid  gap-5">
                    <div className="w-full bg-white shadow-md rounded-lg p-4" style={{ height: '70px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px' }}>
                      <input type="number" id="overs"
                        placeholder="Enter Match Code"
                        onChange={(e) => { setumpire2otp(e.target.value) }}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button"
                    onClick={() => setUmpire2Popup(false)}>
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button" onClick={ReleaseUmpire2}>
                    Verify Match Code
                  </button>
                </div>
                <div>
                  {/* <h2>Hello</h2> */}
                  <div className="flex justify-between" style={{ height: '26rem' }}>
                    <div className="w-1/2 p-4 rounded-lg shadow-md bg-white" style={{ height: '400px', marginLeft: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', background: "linear-gradient(to right, #0ED2F7, #B2FEFA)" }}>
                      {/* Content for first card */}
                      <img src={Screenshort1} alt="Image 1" id='DetailSS1' className="w-full h-full object-cover rounded-lg" style={{ width: '125%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', cursor: 'pointer' }} />
                    </div>
                    <div className="w-1/2 p-4 rounded-lg shadow-md bg-white ml-2" style={{ height: '400px', marginRight: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', background: 'linear-gradient(to right, #b0f4fff7, #36D1DC)' }}>
                      {/* Content for second card */}
                      {/* <img src={Screenshort2} alt="Image 2" className="w-full h-auto" /> */}
                      <img src={Screenshort3} alt="Image 1" id='DetailSS2' className="w-full h-full object-cover rounded-lg" style={{ width: '125%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', cursor: 'pointer' }} />
                    </div>
                  </div>
                  <ReactTooltip
                    anchorId="DetailSS1"
                    place="top"
                    html="
                                    <h2 style='text-align:center;color:lightgreen'>Record Video</h2><hr/>
                                    <h3 style='text-align:center;'>You need to sign in with Umpire credentials in the mobile app to delete data from the Firebase database.<br />Once you are logged in, click on the left-side drawer and then click on the 'Record Video' link.</h3>
                                    "
                    // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                    className="tooltip" />
                  <ReactTooltip
                    anchorId="DetailSS2"
                    place="top"
                    html="
                                    <h2 style='text-align:center;color:lightgreen'>Match Code</h2><hr/>
                                    <h3 style='text-align:center;'>Once you click on 'Record Video', you can see the Match Code at the left-bottom side.<br />Enter this match code in the input box above to delete it..</h3>
                                    "
                    // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                    className="tooltip" />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}




      <form onSubmit={handleDownloadModalSubmit}>
        {showDownloadModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative my-6 mx-auto max-w-3xl" style={{ minWidth: '60rem', paddingBottom: '14%' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className="font-semibold p-3 bg-transparent border-0 text-black leading-none font-semibold outline-none focus:outline-none">
                      Download Match Video
                    </h3>

                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={handleDownloadModalClose}
                    ><ClearIcon />
                    </button>
                  </div>


                  <div className="relative p-2 flex-auto">
                    <div style={{ textAlign: 'center' }}>
                      <h3 className='design_previous'><b>Tournament</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingTournament}</span> <b>Match</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingMatch}</span></h3>
                    </div>
                    <div className="m-2 grid grid-cols-3 gap-5">



                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          <b>Inning</b><span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select
                          id="inning_id"
                          value={downloadInning}
                          onChange={handleDownloadInning}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        >
                          <option value='1'>First Inning</option>
                          <option value='2'>Second Inning</option>
                        </select>
                      </div>

                      <div className="form-control">
                        <label className="form-label inline-block mb-2 text-gray-700">
                          <b>Player</b><span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select
                          id="player_id"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          //defaultValue={defaults.BOWLER}
                          value={selectedPlayer}
                          onChange={handleDownloadPlayer}
                        >
                          <option value='all'>All Players</option>
                          {playerListData.map(player => (
                            <option key={player.id} value={player.userId}>{player.user_name[0].full_name} ({player.team_name.name})</option>
                          ))}
                        </select>
                      </div>



                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          <b>Download Type</b><span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select
                          id="download_type_id"
                          //value={bowlerId}
                          //onChange={handleBolwerDetails}
                          value={selectedDownloadType}
                          onChange={handleDownloadType}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        //defaultValue={defaults.BOWLER}
                        >
                          <option value='4,6'>4 & 6 Runs</option>
                          <option value='wicket'>Wickets</option>
                        </select>
                      </div>


                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    {/* <div className='videocount'>Video Count: {loading ? 'Loading...' : (videoCount !== null ? videoCount : 0)}</div> */}
                    <div className='videocount_previous'>Video Count: {loading ? 'Loading...' : (videoCount !== null ? (videoCount > 0 ? videoCount + ' 😃' : '0 😐') : 'Loading...')}</div>

                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      // onClick={() => setShowDownloadModal(false)}
                      onClick={handleDownloadModalClose}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"
                    ><DownloadIcon />
                      Download To Laptop
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      {/* VideoTagModal */}
      <form>
        {showVideoTagModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative my-6 mx-auto max-w-8xl" style={{ minWidth: '60rem' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{ width: '110%' }}>
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className="font-semibold p-3 bg-transparent border-0 text-black leading-none font-semibold outline-none focus:outline-none">
                      Tag Pending Videos To Balls<span><img src="https://i.pinimg.com/originals/4d/a3/bb/4da3bb12ff8b214818481019ace3fb97.gif" style={{ width: '35px', marginLeft: '15rem', marginTop: '-27px' }} alt="Loading..." /></span> <span></span>
                    </h3>

                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowVideoTagModal(false)}
                    >
                      <ClearIcon />
                    </button>
                  </div>

                  {/* <div className="p-2 flex-auto">
                    {videoFiles.length > 0 ? (
                      <table >
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>File Name</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Video Player</th>
                          </tr>
                        </thead>
                        <tbody>
                          {videoFiles.map((file, index) => (
                            <tr key={file.fileid}>
                              <td>{index + 1}</td>
                              <td>{file.zip_filepath}</td>

                              <td>{file.status === 'uploaddone' ? 'Video Tagged' : file.status}</td>
                              <td>{file.createdAt}</td>

                              <td >
                                <video className='videodesign' width="120" height="120" controls key={file.full_video_url}>
                                  <source src={file.full_video_url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className='designtag'>No Videos For Tagged</p>
                    )}
                  </div> */}
                  {/* //newtable */}
                  <div className="p-2 flex-auto">
                    <div style={{ textAlign: 'center' }}>
                      <h3 className='designs_previous'><b>Tournament</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingTournament}</span> <b>Match</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingMatch}</span></h3>
                    </div>
                    {videoFiles.length > 0 ? (
                      <><ul className="responsive-table_previous" style={{ maxHeight: videoFiles.length > 2 ? '400px' : 'auto', overflowY: 'auto' }}>
                        <li className="table-header_previous">
                          <div className="col col-1">Sr. No.</div>
                          <div className="col col-2">File Name</div>
                          <div className="col col-3">Status</div>
                          <div className="col col-4">Date</div>
                          <div className="col col-5">Video</div>
                        </li>
                        {videoFiles.map((file, index) => (
                          <li className="table-row_previous" key={file.fileid}>
                            <div className="col col-1" data-label="Sr. No.">{index + 1}</div>
                            <div className="col col-2" data-label="File Name">{file.zip_filepath}</div>
                            <div className="col col-3" data-label="Status" style={{ color: "green" }}> <CheckCircleIcon />{file.status === 'uploaddone' ? 'Video Tagged' : file.status}</div>
                            {/* <div className="col col-4" data-label="Date"><AccessTimeIcon /> {file.createdAt}</div> */}
                            <div className="col col-4" data-label="Date">{new Date(file.createdAt).toISOString().split('T')[0]}</div>
                            <div className="col col-5" data-label="Video Player">
                              <video
                                className='videodesign_previous'
                                width="120"
                                height="120"
                                muted
                                loop
                                playsInline
                                onMouseOver={(e) => { e.currentTarget.controls = true; e.currentTarget.style.width = "300px"; e.currentTarget.style.height = "300px"; }}
                                onMouseOut={(e) => { e.currentTarget.controls = false; e.currentTarget.style.width = "120px"; e.currentTarget.style.height = "120px"; }}
                              >
                                <source src={file.full_video_url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>

                          </li>
                        ))}
                      </ul>
                        <img src="https://cdn.dribbble.com/users/5008510/screenshots/10840297/media/df7b4d1933701ea86c581ac730063966.gif" style={{ width: '48px', marginLeft: '4.8rem', marginBottom: '-40px', borderRadius: '13px' }} alt="Loading..." />
                        <p style={{ textAlign: 'center', backgroundColor: 'lightgray', borderRadius: '5px' }}>
                          <strong>Note!</strong> Unzipping and Tagging is a heavy process in this functionality. That's why we have divided it into small portions.<br />
                          If you want to unzip and tag more balls, click this button.👉🏽<Button variant="outlined"><LocalOfferIcon id='all_video_tag_new_' onClick={() => AddVideoTagLoad()} style={{ color: '#006400' }} /></Button>
                        </p></>
                    ) : (
                      <><p className='designtag_previous fade-in_previous'>No Videos For Tagged</p><img src="https://cdn.dribbble.com/users/406903/screenshots/6723682/drawkit-grape-pack-illustration-3-dribbble-export-v0.1.gif" style={{ width: '273px', marginLeft: '23rem' }} alt="Loading..." /></>

                    )}
                  </div>
                  <ReactTooltip
                    anchorId=" all_video_tag_new_"
                    place="left"
                    content="Tag All Videos - New"
                    className="tooltip" />


                  {/* // */}


                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">

                    <Button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', height: '30px', color: 'red' }}
                      onClick={() => setShowVideoTagModal(false)}
                    >
                      Close
                    </Button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      {/* VideoTagGalleryModal */}
      <form onSubmit={loadMoreVideos}>
        {showVideoTagGalleryModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-auto overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto max-w-8xl" style={{ minWidth: '85rem' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{ width: '100%' }}>
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                    <div className='butondesign_previous'>
                      <div>
                        <Button variant={selectedInning === 1 ? "contained" : "outlined"} onClick={() => { setSelectedInning(1); VideoGalleryByInning(1); }}>Inning 1
                        </Button>
                      </div>
                      <img src="https://i.pinimg.com/originals/4d/a3/bb/4da3bb12ff8b214818481019ace3fb97.gif" style={{ width: '35px', marginRight: '0rem' }} alt="Loading..." />
                      <div>
                        <Button variant={selectedInning === 2 ? "contained" : "outlined"} onClick={() => { setSelectedInning(2); VideoGalleryByInning(2); }}>Inning 2
                          {/* <OndemandVideoIcon id='all_video_tag_new_2' style={{ color: '#006400' }} /> */}
                        </Button>
                      </div>
                    </div>
                    {/* <h3 className="font-semibold p-3 bg-transparent border-0 text-black leading-none font-semibold outline-none focus:outline-none" style={{ marginLeft: '119px' }}>
                      Video Gallery<span><img src="https://i.pinimg.com/originals/4d/a3/bb/4da3bb12ff8b214818481019ace3fb97.gif" style={{ width: '35px', marginLeft: '-17rem', marginTop: '-27px' }} alt="Loading..." /></span> <span></span>
                    </h3> */}
                    <div className="flex items-center justify-center">
                      {/* <img src="https://i.pinimg.com/originals/4d/a3/bb/4da3bb12ff8b214818481019ace3fb97.gif" style={{ width: '35px', marginRight: '1rem' }} alt="Loading..." /> */}
                      <h3 style={{ marginLeft: '25rem' }} className="font-semibold p-3 bg-transparent border-0 text-black leading-none font-semibold outline-none focus:outline-none">
                        Video Gallery
                      </h3>
                    </div>


                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      // onClick={() => setShowVideoTagGalleryModal(false)}
                      onClick={handleCloseVideoGalleryModal}
                    >
                      <ClearIcon />
                    </button>
                  </div>

                  <div className="p-2" style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className='designs_previous'><b>Tournament</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingTournament}</span> <b>Match</b> : <span style={{ color: '#24fff7' }}>{downloadVideoHeadingMatch}</span></h3>

                    </div>
                    {videoGallery.length > 0 ? (
                      <div className="grid grid-cols-5 gap-4">
                        {videoGallery.map((video, index) => (
                          <div style={{ width: '270px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset', borderRadius: '20px', fontWeight: '500' }} className="table-row_previous" key={video.fileid}>
                            <div style={{ height: '208px' }} className="bg-white shadow-md rounded-lg overflow-hidden" onClick={() => {
                              setSelectedVideo(video);
                              setShowVideoPopup(true);
                            }}>
                              <div>

                                <video
                                  key={video.video_url}
                                  className='videodesign_previousgallery'
                                  style={{ width: '100%', height: '161px', background: 'linear-gradient(180deg, rgba(0,36,36,1) 0%, rgba(172,172,172,0.5802696078431373) 100%, rgba(255,255,255,0) 100%)' }}
                                  muted
                                  loop
                                  playsInline
                                  onMouseOver={(e) => { e.currentTarget.controls = true; }}
                                  onMouseOut={(e) => { e.currentTarget.controls = false; }}
                                  onLoadStart={() => setVideoLoading(true)}
                                  onLoadedData={() => setVideoLoading(false)}
                                >
                                  <source src={video.video_url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>


                                <div className="p-3">

                                  <p style={{ width: '245px', background: 'linear-gradient(rgb(0 145 160) 0%, rgba(172, 172, 172, 0.58) 100%, rgba(255, 255, 255, 0) 100%)', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px' }} className="text-center">
                                    Ball: {video.current_ball} {video.extra_ball_delivery_result && (
                                      <>
                                        (<span style={{ color: 'red', fontWeight: 'bold' }}>{video.extra_ball_delivery_result}</span>)
                                      </>
                                    )}<span><Button id={`gallery_${video.id}`} style={{ padding: '0px', color: 'black', backgroundColor: '#0df6e3' }} variant="outlined" size="small" onClick={(e) => handleRefreshVideo(e, video.video_url, index)}>
                                      <CachedIcon />
                                    </Button></span>
                                  </p>
                                  <ReactTooltip
                                    anchorId={`gallery_${video.id}`}
                                    place="bottom"
                                    html="
                                    <h2 style='text-align:center;color:lightgreen'>Refresh Video</h2><hr/>
                                    <h3 style='text-align:center;'>Sometimes, the video may not load properly due to a network issue.<br />In such cases, you can refresh the video and try loading the video again.</h3>
                                    "
                                    // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                                    className="tooltip" />




                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                    ) : (
                      <><p className='videogallerytag_previous fade-in_previous'>No Videos In The Gallery</p><img src="https://www.campusvarta.com/cmpv/images/article-not-found.gif" className='imagedesign_previous' alt="Loading..." /></>
                    )}


                  </div>


                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    {videoLoading && <div className="loader"></div>}
                    {videoGallery.length < 2 ? (
                      <div className="text-center text-lg" style={{ marginRight: '2%' }}>Total Video: {videoGallery.length}</div>
                    ) : (
                      <div className="text-center text-lg" style={{ marginRight: '2%' }}>Total Videos: {videoGallery.length}</div>
                    )}
                    {showLoadMore && videoGallery.length >= 10 && (
                      <Button type="submit" id="videogalleryloadmore" style={{ height: '30px', backgroundColor: '#00efdc', color: 'black', marginRight: '37px' }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                    )}
                    {/* <Button type="submit" id="videogalleryloadmore" style={{ height: '30px', backgroundColor: '#00efdc', color: 'black', marginRight: '37px' }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button> */}

                    <Button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', height: '30px', color: 'red' }}
                      // onClick={() => setShowVideoTagGalleryModal(false)}
                      onClick={handleCloseVideoGalleryModal}
                    >
                      Close
                    </Button>
                  </div>
                  <ReactTooltip
                    anchorId="videogalleryloadmore"
                    place="bottom"
                    content="Click to load more"
                    className="tooltip"
                  />
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
    </>




  );
};
export default Match;