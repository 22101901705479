import React from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Graph from "./Graph";
import { useState } from "react";
import GraphObjectsList from "./GraphObjectsList";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  useEffect(() => {
    setHeaderTitle("Dashboard");
  }, []);

  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubCategory, setActiveSubCategory] = useState("");
  // const initialGraphList = GraphList;
  const [GraphList, setGraphList] = useState(GraphObjectsList);

  const handleOptionClick = (option) => {
    setActiveCategory(option);
    setActiveSubCategory("");
  };

  const handleSubOptionClick = (option) => {
    setActiveSubCategory(option);
  };

  const uniqueCategories = [...new Set(GraphList.map((obj) => obj.category))];

  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    const chosenGraph = GraphList.find((obj) => obj.plotBool && obj.category === activeCategory && obj.subcategory === activeSubCategory);
    if (chosenGraph) {
      chosenGraph
        .queryFunction()
        .then((fetchedData) => {
          chosenGraph.dataSets[0].x = fetchedData[chosenGraph.xKeys];
          chosenGraph.dataSets[0].y = fetchedData[chosenGraph.yKeys];
          // console.log(chosenGraph);
          setPlotData(chosenGraph);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // this will remove old graph if user clicks on any other new category
      setPlotData(null);
    }
  }, [activeCategory, activeSubCategory]);

  useEffect(() => {
    const chosenGraphs = GraphList.filter((obj) => obj.category === activeCategory);
    if (chosenGraphs) {
      chosenGraphs.forEach((chosenGraph) => {
        chosenGraph
          .tabFunction()
          .then((fetchedData) => {
            const updatedGraphs = [...GraphList];
            const graphIndex = updatedGraphs.findIndex((graph) => graph === chosenGraph);
            updatedGraphs[graphIndex].tabData = fetchedData[chosenGraph.tabYKey];
            setGraphList(updatedGraphs);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, [activeCategory]);

  return (
    <>
      <div className="flex">
        <div>
          <div className="top-bar flex flex-start gap-10 flex-wrap py-8">
            {uniqueCategories.map((category) => (
              <div key={category}>
                <div
                  className={`option ${activeCategory === category ? "active bg-emerald-200" : ""} border-solid border-2 px-12 py-2 cursor-pointer rounded-lg w-48 text-center`}
                  onClick={() => handleOptionClick(category)}
                >
                  {category}
                </div>
              </div>
            ))}
          </div>

          <div className="top-bar-2 grid grid-cols-5 gap-10 flex-wrap pb-4">
            {GraphList.filter((obj) => obj.category === activeCategory).map((graphObj, index) => (
              <div
                key={index}
                className={`option ${graphObj.subcategory === activeSubCategory ? "active bg-emerald-200" : ""} ${graphObj.tabBool ? "flex" : ""
                  } border-solid border-2 justify-around py-2 cursor-pointer rounded-lg`}
                onClick={() => handleSubOptionClick(graphObj.subcategory)}
              >
                {graphObj.tabBool ? `${graphObj.tabText}-${graphObj.tabData}` : graphObj.subcategory}
              </div>
            ))}
          </div>
        </div>

        {/* Add a button to navigate to /Dashboard2 */}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 mt-8 h-10"
          onClick={() => navigate("/Dashboard2")}
        >
          Go to Dashboard2
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 mt-8 h-10 "
          onClick={() => navigate("/Dashboard3")}
        >
          Go to Dashboard3
        </button>
      </div>

      <div>{plotData && <div className="graphs-container">{<Graph data={plotData} />}</div>}</div>
    </>
  );
};

export default Dashboard;
