import axios from "axios";

export const saveNewPlayerRegistrationForm = async (form) => {
  // console.log('inSaveNewPlayerRegstration')
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/saveNewPlayerRegistration`,
      form,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    // console.log(res)
    return res.data;
  } catch (err) {
    console.log("Error in saveNewPlayerRegistrationForm.");
    console.log(err);
    return { message: "Data not saved.", status: false };
  }
};

export const getCityList = async () => {
  // console.log('in getCityList')
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/getCityList`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log("Error in getCityList.");
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const getLoggedInUserInstitutionID = async () => {
  try {
    // Check if loggedUserInstitutionID exists in local storage
    const loggedUserInstitutionID = sessionStorage.getItem(
      "loggedUserInstitutionID"
    );
    if (loggedUserInstitutionID) {
      // If it exists, parse and return the data from local storage
      return JSON.parse(loggedUserInstitutionID);
    }

    const wellplayedTokenString = localStorage.getItem("WellplayedToken");
    const wellplayedTokenObject = JSON.parse(wellplayedTokenString);

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/getLoggedInUserInstitutionID`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${wellplayedTokenObject.token}`,
        },
        params: {
          email_id: wellplayedTokenObject.user.email,
        },
      }
    );

    const responseJSONString = JSON.stringify(res.data);

    sessionStorage.setItem("loggedUserInstitutionID", responseJSONString);
    return res.data;
  } catch (err) {
    console.log("Error in getLoggedInUserInstitutionID.");
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const fetchInstitutionPlayers = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchInstitutionPlayers`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          institution_id: sessionStorage.getItem("loggedUserInstitutionID"),
        },
      }
    );
    // console.log("fetchInstitutionPlayers response:");
    // console.log(res);
    return res.data;
  } catch (err) {
    console.log("Error in fetchInstitutionPlayers:", err.message);
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const fetchPlayerFeeDetails = async (selected_user_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchPlayerFeeDetails`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          institution_id: sessionStorage.getItem("loggedUserInstitutionID"),
          user_id: selected_user_id,
        },
      }
    );
    // console.log("fetchPlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in fetchPlayerFeeDetails:", err.message);
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const updatePlayerFeeDetails = async (updatedData, dbDataRowIndex) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/updatePlayerFeeDetails`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          // institution_id: sessionStorage.getItem('loggedUserInstitutionID'),
          // user_id: selected_user_id,
          id: dbDataRowIndex,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in updatePlayerFeeDetails:", err.message);
    console.log(err);
    return { message: "Data not updated.", status: false };
  }
};

export const addPlayerFeeDetails = async (feeData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/addPlayerFeeDetails`,
      feeData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in addPlayerFeeDetails:", err.message);
    console.log(err);
    return { message: "Data not added.", status: false };
  }
};

export const getUserRole = async () => {
  try {
    const wellplayedTokenString = localStorage.getItem("WellplayedToken");
    const wellplayedTokenObject = JSON.parse(wellplayedTokenString);

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/getUserRole`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          email_id: wellplayedTokenObject.user.email,
        },
      }
    );

    // const responseJSONString = JSON.stringify(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in getUserRole.");
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const fetchPlayerRegistration = async (player_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchPlayerRegistration`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          player_id: player_id,
        },
      }
    );
    // console.log("fetchPlayerRegistration response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in fetchPlayerRegistration:", err.message);
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const updatePlayerRegistration = async (
  updatedData,
  selectedPlayerIndex
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/updatePlayerRegistration`,
      updatedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          id: selectedPlayerIndex,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in updatePlayerFeeDetails:", err.message);
    console.log(err);
    return { message: "Data not updated.", status: false };
  }
};

export const addNewFeePlan = async (planData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/addNewFeePlan`,
      planData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in addPlayerFeeDetails:", err.message);
    console.log(err);
    return { message: "Data not added.", status: false };
  }
};

export const fetchPlanList = async (institution_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchPlanList`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          institution_id: institution_id,
        },
      }
    );
    // console.log("fetchPlayerRegistration response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in fetchPlanList:", err.message);
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};

export const addNewSubscription = async (planData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/institutions/addNewSubscription`,
      planData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in addNewSubscription:", err.message);
    console.log(err);
    return { message: "Data not added.", status: false };
  }
};

export const fetchPlayerActiveSubscriptionList = async (
  user_id,
  institution_id
) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchPlayerActiveSubscriptionList`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          user_id: user_id,
          institution_id: institution_id,
        },
      }
    );
    // console.log("updatePlayerFeeDetails response:");
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error in fetchPlayerActiveSubscriptionList:", err.message);
    console.log(err);
    return { message: "Data not added.", status: false };
  }
};

export const fetchInstitutionPlayersAndPendingFees = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions/fetchInstitutionPlayersAndPendingFees`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        params: {
          institution_id: sessionStorage.getItem("loggedUserInstitutionID"),
        },
      }
    );
    // console.log("fetchInstitutionPlayers response:");
    // console.log(res);
    return res.data;
  } catch (err) {
    console.log("Error in fetchInstitutionPlayers:", err.message);
    console.log(err);
    return { message: "Data not fetched.", status: false };
  }
};
