import React, { useEffect, useState } from "react";
import logo from "./../assets/logo.png";
import "../App.css";
import { Link, useLocation } from "react-router-dom";
import videoIcon from "../assets/icons2/video.png";
import notificationIcon from "../assets/icons2/notification.png";
import announcementIcon from "../assets/icons2/announcement.png";
import configIcon from "../assets/icons2/config.png";
import dashboardIcon from "../assets/icons2/dashboard.png";
import error1 from "../assets/icons2/error1.png";
import authorizationIcon from "../assets/icons2/authorization.png";
import logoutIcon from "../assets/icons2/logout.png";
import { logout, getUserRoleAccessSidebar } from "../pages/Auth/Helper";
import TaskIcon from "@mui/icons-material/Task";
function Sidebar(props) {
  const [user, setUser] = useState([]);
  const [userRoleName, setUserRoleName] = useState("");
  const [isDashboardView, setIsDashboardView] = useState(false);
  const [isManageAccessView, setIsManageAccessView] = useState(false);
  const [isVideoView, setIsVideoView] = useState(false);
  const [isNotificationView, setIsNotificationView] = useState(false);
  const [isAnnouncementView, setIsAnnouncementView] = useState(false);
  const [isTrainingProgramView, setIsTrainingProgramView] = useState(false);
  const [isMyAuthorizationView, setIsMyAuthorizationView] = useState(false);
  const [isRoleAccessView, setIsRoleAccessView] = useState(false);

  const [isMatchCenterConfigurationView, setIsMatchCenterConfigurationView] = useState(false);
  const [isNewTournamentView, setIsNewTournamentView] = useState(false);
  const [isNewMatchView, setIsNewMatchView] = useState(false);
  const [isNewAnalysisView, setIsNewAnalysisView] = useState(false);
  const [isPreMatchDataView, setIsPreMatchDataView] = useState(false);

  const [isInstitutionView, setIsInstitutionView] = useState(false);
  const [isNewPlayerRegistrationView, setIsNewPlayerRegistrationView] = useState(false);
  const [isPlayerRegisterView, setIsPlayerRegisterView] = useState(false);
  const [isPlayerFeeDetailView, setIsPlayerFeeDetailView] = useState(false);
  const [isSetupFeeView, setIsSetupFeeView] = useState(false);
  const [isAttendanceReportView, setIsAttendanceReportView] = useState(false);
  const [isMembershipReportView, setIsMembershipReportView] = useState(false);

  const [isErrorLogView, setIsErrorLogView] = useState(false);
  const [isApiErrorLogView, setIsApiErrorLogView] = useState(false);
  const [isAWSUploadErrorLogView, setIsAWSuploadErrorLogView] = useState(false);
  const [isAWSErrorLogView, setIsAWSErrorLogView] = useState(false);
  const [isRecordVideoLogView, setIsRecordVideoLogView] = useState(false);
  const [isMatchAnalysisErrorLogView, setIsMatchAnalysisErrorLogView] = useState(false);

  const [isMonitoringView, setIsMonitoringView] = useState(false);
  const [isPracticeVideoMonitoringView, setIsPracticeVideoMonitoringView] = useState(false);
  const [isTrainingProgramMonitoringView, setIsTrainingProgramMonitoringView] = useState(false);
  const [isInstitutionVideoMonitoringView, setIsInstitutionVideoMonitoringView] = useState(false);



  const handleLinkClick = (component_path) => {
    // Refresh the page after navigating to "/dashboard"
    window.location.href = component_path;
  };

  useEffect(() => {
    let userTokenDetail = JSON.parse(localStorage.getItem("WellplayedToken"));
    setUser(userTokenDetail);

    getUserRoleAccessSidebar(userTokenDetail.user.id)
      .then((data) => {
        if (userTokenDetail.user.user_type == "superadmin") {

          setIsDashboardView(true);
          setIsVideoView(true);
          setIsManageAccessView(true);
          setIsNotificationView(true);
          setIsAnnouncementView(true);
          setIsTrainingProgramView(true);
          setIsMyAuthorizationView(true);
          setIsRoleAccessView(true);
          setIsNewTournamentView(true);
          setIsMatchCenterConfigurationView(true);
          setIsNewMatchView(true);
          setIsNewAnalysisView(true);
          setIsPreMatchDataView(true);
          setIsNewPlayerRegistrationView(true);
          setIsInstitutionView(true);
          setIsPlayerRegisterView(true);
          setIsPlayerFeeDetailView(true);
          setIsSetupFeeView(true);
          setIsAttendanceReportView(true);
          setIsMembershipReportView(true);
          setIsApiErrorLogView(true);
          setIsAWSuploadErrorLogView(true);
          setIsAWSErrorLogView(true);
          setIsRecordVideoLogView(true);
          setIsErrorLogView(true);
          setIsMatchAnalysisErrorLogView(true);
          setIsPracticeVideoMonitoringView(true);
          setIsMonitoringView(true);
          setIsTrainingProgramMonitoringView(true);
          setIsInstitutionVideoMonitoringView(true);
        }
        else if (data["user_detail"]["admin_role_detail"] != false) {

          setUserRoleName(data["user_detail"]["admin_role_detail"]["role_name"]);

          if (data["user_detail"]["admin_role_detail"]["menu_list"].length > 0) {
            for (let i = 0; i < data["user_detail"]["admin_role_detail"]["menu_list"].length; i++) {

              if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "admin_dashboard") {
                setIsDashboardView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "video") {
                setIsVideoView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "manage_access") {
                setIsManageAccessView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "notifications") {
                setIsNotificationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "announcement") {
                setIsAnnouncementView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "training_program") {
                setIsTrainingProgramView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "my_authorizations") {
                setIsMyAuthorizationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "role_and_access") {
                setIsRoleAccessView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "match_center_configuration_new_tournament_new_tournament") {
                setIsNewTournamentView(true);
                setIsMatchCenterConfigurationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "match_center_configuration_new_tournament_new_match") {
                setIsNewMatchView(true);
                setIsMatchCenterConfigurationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "match_center_configuration_new_tournament_new_analysis") {
                setIsNewAnalysisView(true);
                setIsMatchCenterConfigurationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "match_center_configuration_new_tournament_previous_match_data") {
                setIsPreMatchDataView(true);
                setIsMatchCenterConfigurationView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_new_player_registration") {
                setIsNewPlayerRegistrationView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_player_register") {
                setIsPlayerRegisterView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_player_fee_details") {
                setIsPlayerFeeDetailView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_setup_fees") {
                setIsSetupFeeView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_attendance_reports") {
                setIsAttendanceReportView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "institutions_membership_reports") {
                setIsMembershipReportView(true);
                setIsInstitutionView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "error_logs_api_error_log") {
                setIsApiErrorLogView(true);
                setIsErrorLogView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "error_logs_aws_upload_error_log") {
                setIsAWSuploadErrorLogView(true);
                setIsErrorLogView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "error_logs_record_video_log") {
                setIsRecordVideoLogView(true);
                setIsErrorLogView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "error_logs_match_analysis_error_log") {
                setIsMatchAnalysisErrorLogView(true);
                setIsErrorLogView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "monitoring_practice_video") {
                setIsPracticeVideoMonitoringView(true);
                setIsMonitoringView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "monitoring_training_program") {
                setIsTrainingProgramMonitoringView(true);
                setIsMonitoringView(true);
              }
              else if (data["user_detail"]["admin_role_detail"]["menu_list"][i]["unique_identifier"] == "monitoring_institution_video") {
                setIsInstitutionVideoMonitoringView(true);
                setIsMonitoringView(true);
              }
            }
          }
        }
      })
      .catch((err) => {
        //console.log("error in fetching all previous match record", err);
      })
      .finally(() => {
        //setLoading(false);
      });

  }, [])


  let location = useLocation();
  let [selectedpanel, setselectedpanel] = useState("dashboard");
  let [drop, setdrop] = useState(0);
  let [arrow, setarrow] = useState({
    matchCenterConf: false,
  });
  const [active, setActive] = useState({
    matchCenterConf: "hidden py-2 space-y-2",
    institutionCenterConf: "hidden py-2 space-y-2",
    errorlogConf: "hidden py-2 space-y-2",
    monitoringConf: "hidden py-2 space-y-2"
  });

  const handleChange = (e, name) => {
    e.preventDefault();
    setselectedpanel(name);
    setdrop(0);
    if (active[name] === "py-2 space-y-2") {
      setActive({
        ...active,
        [name]: "hidden py-2 space-y-2",
      });
      setarrow({ ...arrow, [name]: false });
    } else {
      setActive({
        matchCenterConf: "hidden py-2 space-y-2",
        institutionCenterConf: "hidden py-2 space-y-2",
        errorlogConf: "hidden py-2 space-y-2",
      });
      setActive({
        ...active,
        [name]: "py-2 space-y-2",
      });
      setarrow({ ...arrow, [name]: true });
      console.log(active);
    }
  };


  let isSidebarShow = "";
  if (window.location.pathname == "/MatchCenter/LiveFeed") {
    isSidebarShow = "none";
  }
  const [isSideNeed, setIsSideNeed] = useState(isSidebarShow);

  // return (
  //     <div className="h-screen" aria-label="Sidebar" style={{ display: isSideNeed }}>
  //         <div className="min-h-screen overflow-y-auto sticky left-0" style={{ backgroundColor: '#2B303B' }}>
  //             <ul className="space-y-4 bg-black h-20 flex align-center justify-center">
  //                 <li className="flex align-center justify-center">
  //                     <a onClick={(e) => {
  //                         e.preventDefault();
  //                         props.setHide(!props.hide);
  //                     }} href="#" className="flex items-center w-full p-2 text-base font-normal text-gray-900 rounded-lg text-white ">
  //                         <img src={logo} className={`${props.hide ? "w-10" : "w-32"}`} alt="Avatar" />
  //                     </a>
  //                 </li>
  //             </ul>
  //             <br />
  //             <ul className="space-y-4">
  //                 <li onClick={() => setselectedpanel("admin-dashboard")}>
  //                     <Link to="/Dashboard" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "admin-dashboard" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={dashboardIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Admin Dashboard</span>
  //                     </Link>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("video")}>
  //                     <Link to="/Video" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "video" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={videoIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Video</span>
  //                     </Link>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("notification")}>
  //                     <Link to="/Notification" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "notifications" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={notificationIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Notifications</span>
  //                     </Link>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("announcement")}>
  //                     <Link to="/Announcement" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "announcement" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={announcementIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Announcement</span>
  //                     </Link>
  //                 </li>
  //                 <li>
  //                     <button onClick={e => handleChange(e, "matchCenterConf")} type="button" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "matchCenterConf" ? "text-white" : "text-gray-400"}`} aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
  //                         <img src={configIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Match Center Configuration</span>
  //                     </button>
  //                     <ul id="dropdown-example" className={active.matchCenterConf}>
  //                         <li onClick={() => {
  //                             setdrop(1)
  //                             setselectedpanel("matchCenterConf")
  //                         }} >
  //                             <Link to="/MatchCenter/Tournament" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: selectedpanel === "matchCenterConf" && drop === 1 ? 'white' : 'white' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 1 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>New Tournamant</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(3); setselectedpanel("matchCenterConf") }}>
  //                             <Link to="/MatchCenter/Match" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: selectedpanel === "matchCenterConf" && drop === 1 ? 'white' : 'white' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 3 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>New Match</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(2); setselectedpanel("matchCenterConf") }}>
  //                             <Link to="/MatchCenter/MatchAnalysis" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: selectedpanel === "matchCenterConf" && drop === 1 ? 'white' : 'white' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 2 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>New Analysis</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(4); setselectedpanel("matchCenterConf") }}>
  //                             <Link to="/PrevoiusMatchData" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: selectedpanel === "matchCenterConf" && drop === 1 ? 'white' : 'white' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 4 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>Previous Match Data</Link>
  //                         </li>
  //                     </ul>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("admin-task-planner")}>
  //                     <Link to="/TaskPlanner" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "admin-task-planner" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <TaskIcon style={{ marginLeft: '-5px' }} />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15, marginLeft: '5px' }}>Task Planner</span>
  //                     </Link>
  //                 </li>
  //                 <li>
  //                     <button onClick={e => handleChange(e, "institutionCenterConf")} type="button" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "institutionCenterConf" ? "text-white" : "text-gray-400"}`} aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
  //                         <img src={configIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item="true" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Institutions</span>
  //                     </button>
  //                     <ul id="dropdown-example" className={active.institutionCenterConf}>
  //                         <li onClick={() => {
  //                             setdrop(1)
  //                             setselectedpanel("institutionCenterConf")
  //                         }} >
  //                             <Link to="/Institutions/NewPlayerRegistration" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: '#fff' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 1 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>New Player Registration</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(2); setselectedpanel("institutionCenterConf") }}>
  //                             <Link to="/Institutions/PlayerDetails" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: '#fff' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 2 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>Player Details</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(3); setselectedpanel("institutionCenterConf") }}>
  //                             <Link to="/Institutions/SetupFees" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: '#fff' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 3 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>Setup Fees</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(4); setselectedpanel("institutionCenterConf") }}>
  //                             <Link to="/Institutions/AttendanceReports" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: '#fff' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 4 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>Attendance Reports</Link>
  //                         </li>
  //                         <li onClick={() => { setdrop(5); setselectedpanel("institutionCenterConf") }}>
  //                             <Link to="/Institutions/MembershipReports" style={{ display: props?.hide ? 'none' : '', fontSize: 15, color: '#fff' }} className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 5 ? "text-white" : "text-gray-400"} hover:bg-gray-700`}>Membership Reports</Link>
  //                         </li>

  //                     </ul>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("my-authorizations")}>
  //                     <Link to="/MyAuthorization" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "my-authorizations" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={authorizationIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>My Authorizations</span>
  //                     </Link>
  //                 </li>
  //                 <li onClick={() => setselectedpanel("subscription-plans")}>
  //                     <Link to="/SubscriptionPlans" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "my-authorizations" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={authorizationIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Subscription Plans</span>
  //                     </Link>
  //                 </li>
  //                 <li onClick={() => {
  //                     logout();
  //                     window.location.reload();
  //                 }}>
  //                     <Link to="/Login" className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "logout" ? "text-white" : "text-gray-400"}  hover:bg-gray-700`}>
  //                         <img src={logoutIcon} className="h-4" />
  //                         <span className="flex-1 ml-3 text-left whitespace-nowrap" style={{ display: props?.hide ? 'none' : '', fontSize: 15 }}>Logout</span>
  //                     </Link>
  //                 </li>
  //             </ul>
  //         </div>
  //     </div>
  // );
  return (
    <div
      className="h-screen"
      aria-label="Sidebar"
      style={{ display: isSideNeed }}
    >
      <div
        className="min-h-screen overflow-y-auto sticky left-0"
        style={{ backgroundColor: "#2B303B" }}
      >
        <ul className="space-y-4 bg-black h-20 flex align-center justify-center">
          <li className="flex align-center justify-center">
            <a
              onClick={(e) => {
                e.preventDefault();
                props.setHide(!props.hide);
              }}
              href="#"
              className="flex items-center w-full p-2 text-base font-normal text-gray-900 rounded-lg text-white "
            >
              <img
                src={logo}
                className={`${props.hide ? "w-10" : "w-32"}`}
                alt="Avatar"
              />
            </a>
          </li>
        </ul>

        <div style={{ textAlign: 'center', color: '#FFF', paddingTop: '5px', paddingBottom: '5px', display: props?.hide ? "none" : "" }}>
          {/* {JSON.stringify(user)} */}
          {user.user ? user.user.name : ''} <br />
          {user.user ? user.user.email : ''} <br />
          {userRoleName}
        </div>
        <hr style={{ display: props?.hide ? "none" : "" }} />
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <ul className="space-y-4">

            {isDashboardView ? <>
              <li onClick={() => setselectedpanel("admin-dashboard")}>
                <Link

                  onClick={() => handleLinkClick('/Dashboard')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "admin-dashboard"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={dashboardIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Admin Dashboard
                  </span>
                </Link>
              </li>
            </> : <></>}


            {isDashboardView ? <>
              <li onClick={() => setselectedpanel("manage-access")}>
                <Link
                  to="/MatchAccess"
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "manage-access"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={dashboardIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Match Access
                  </span>
                </Link>
              </li>
            </> : <></>}



            {isVideoView ? <>
              <li onClick={() => setselectedpanel("video")}>
                <Link

                  onClick={() => handleLinkClick('/Video')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "video" ? "text-white" : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={videoIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Video
                  </span>
                </Link>
              </li>
            </> : <></>}

            {isNotificationView ? <>
              <li onClick={() => setselectedpanel("notification")}>
                <Link
                  onClick={() => handleLinkClick('/Notification')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "notifications"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={notificationIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Notifications
                  </span>
                </Link>
              </li>
            </> : <></>}

            {isAnnouncementView ? <>
              <li onClick={() => setselectedpanel("announcement")}>
                <Link
                  onClick={() => handleLinkClick('/Announcement')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "announcement"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={announcementIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Announcement
                  </span>
                </Link>
              </li>
            </> : <></>}


            {isMatchCenterConfigurationView ? <>
              <li>
                <button
                  onClick={(e) => handleChange(e, "matchCenterConf")}
                  type="button"
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "matchCenterConf"
                    ? "text-white"
                    : "text-gray-400"
                    }`}
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <img src={configIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    sidebar-toggle-item="true"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Match Center Configuration
                  </span>
                </button>
                <ul id="dropdown-example" className={active.matchCenterConf}>

                  {isNewTournamentView ? <>
                    <li
                      onClick={() => {
                        setdrop(1);
                        setselectedpanel("matchCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/MatchCenter/Tournament')}
                        style={{
                          display: props?.hide ? "none" : "",
                          fontSize: 15,
                          color:
                            selectedpanel === "matchCenterConf" && drop === 1
                              ? "white"
                              : "white",
                        }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 1
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        New Tournament
                      </Link>
                    </li>
                  </> : <></>}

                  {isNewMatchView ? <>
                    <li
                      onClick={() => {
                        setdrop(3);
                        setselectedpanel("matchCenterConf");
                      }}
                    >
                      <Link

                        onClick={() => handleLinkClick('/MatchCenter/Match')}
                        style={{
                          display: props?.hide ? "none" : "",
                          fontSize: 15,
                          color:
                            selectedpanel === "matchCenterConf" && drop === 1
                              ? "white"
                              : "white",
                        }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 3
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        New Match
                      </Link>
                    </li>
                  </> : <></>}


                  {isNewAnalysisView ? <>
                    <li
                      onClick={() => {
                        setdrop(2);
                        setselectedpanel("matchCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/MatchCenter/MatchAnalysis')}
                        style={{
                          display: props?.hide ? "none" : "",
                          fontSize: 15,
                          color:
                            selectedpanel === "matchCenterConf" && drop === 1
                              ? "white"
                              : "white",
                        }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 2
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        New Analysis
                      </Link>
                    </li>
                  </> : <></>}

                  {isPreMatchDataView ? <>
                    <li
                      onClick={() => {
                        setdrop(4);
                        setselectedpanel("matchCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/PreviousMatchData')}
                        style={{
                          display: props?.hide ? "none" : "",
                          fontSize: 15,
                          color:
                            selectedpanel === "matchCenterConf" && drop === 1
                              ? "white"
                              : "white",
                        }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "matchCenterConf" && drop === 4
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Previous Match Data
                      </Link>
                    </li>
                  </> : <></>}

                </ul>
              </li>
            </> : <></>}

            {isTrainingProgramView ? <>
              <li onClick={() => setselectedpanel("admin-task-planner")}>
                <Link
                  onClick={() => handleLinkClick('/TaskPlanner')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "admin-task-planner"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <TaskIcon style={{ marginLeft: "-5px" }} />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{
                      display: props?.hide ? "none" : "",
                      fontSize: 15,
                      marginLeft: "5px",
                    }}
                  >
                    Training Program
                  </span>
                </Link>
              </li>
            </> : <></>}


            {isInstitutionView ? <>
              <li>
                <button
                  onClick={(e) => handleChange(e, "institutionCenterConf")}
                  type="button"
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "institutionCenterConf"
                    ? "text-white"
                    : "text-gray-400"
                    }`}
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <img src={configIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    sidebar-toggle-item="true"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Institutions
                  </span>
                </button>
                <ul id="dropdown-example" className={active.institutionCenterConf}>

                  {isNewPlayerRegistrationView ? <>
                    <li
                      onClick={() => {
                        setdrop(1);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/NewPlayerRegistration')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 1
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        New Player Registration
                      </Link>
                    </li>
                  </> : <></>}


                  {isPlayerRegisterView ? <>
                    <li
                      onClick={() => {
                        setdrop(2);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/PlayerRegister')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 2
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Player Register
                      </Link>
                    </li>
                  </> : <></>}


                  {isPlayerFeeDetailView ? <>
                    <li
                      onClick={() => {
                        setdrop(3);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/PlayerFeeDetails')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 3
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Player Fee Details
                      </Link>
                    </li>
                  </> : <></>}

                  {isSetupFeeView ? <>
                    <li
                      onClick={() => {
                        setdrop(4);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/SetupFees')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 4
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Setup Fees
                      </Link>
                    </li>
                  </> : <></>}

                  {isAttendanceReportView ? <>
                    <li
                      onClick={() => {
                        setdrop(5);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/AttendanceReports')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 5
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Attendance Reports
                      </Link>
                    </li>
                  </> : <></>}

                  {isMembershipReportView ? <>
                    <li
                      onClick={() => {
                        setdrop(6);
                        setselectedpanel("institutionCenterConf");
                      }}
                    >
                      <Link
                        onClick={() => handleLinkClick('/Institutions/MembershipReports')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 6
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Membership Reports
                      </Link>
                    </li>
                  </> : <></>}
                </ul>
              </li>
            </> : <></>}

            {isMyAuthorizationView ? <>
              <li onClick={() => setselectedpanel("my-authorizations")}>
                <Link
                  onClick={() => handleLinkClick('/MyAuthorization')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "my-authorizations"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={authorizationIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    My Authorizations
                  </span>
                </Link>
              </li>
            </> : <></>}

            {/* ErrorLog */}

            {isErrorLogView ? <>
              <li>
                <button
                  onClick={(e) => handleChange(e, "errorlogConf")}
                  type="button"
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "matchCenterConf"
                    ? "text-white"
                    : "text-gray-400"
                    }`}
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <img src={configIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    sidebar-toggle-item="true"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Error Logs
                  </span>
                </button>
                <ul id="dropdown-example" className={active.errorlogConf}>

                  {isApiErrorLogView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>

                      {/* <Link
                        onClick={() => handleLinkClick('/Institutions/MembershipReports')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 6
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Membership Reports
                      </Link> */}

                      <Link
                        onClick={() => handleLinkClick('/ApiErrorLog')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          API Error Logs
                        </span>
                      </Link>
                    </li>
                  </> : <></>}

                  {isMatchAnalysisErrorLogView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>
                      <Link
                        onClick={() => handleLinkClick('/MatchAnalysisErrorLog')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          Match Analysis Error Logs
                        </span>
                      </Link>
                    </li>
                  </> : <></>}
                  {isAWSUploadErrorLogView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>

                      {/* <Link
                        onClick={() => handleLinkClick('/Institutions/MembershipReports')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 6
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Membership Reports
                      </Link> */}

                      <Link
                        onClick={() => handleLinkClick('/Awserrorlog')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          AWS Upload Error Logs
                        </span>
                      </Link>
                    </li>
                  </> : <></>}
                  {isRecordVideoLogView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>

                      {/* <Link
                        onClick={() => handleLinkClick('/Institutions/MembershipReports')}
                        style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "institutionCenterConf" && drop === 6
                          ? "text-white"
                          : "text-gray-400"
                          } hover:bg-gray-700`}
                      >
                        Membership Reports
                      </Link> */}

                      <Link
                        onClick={() => handleLinkClick('/Recordvideolog')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          Record Video Logs
                        </span>
                      </Link>
                    </li>
                  </> : <></>}
                </ul>
              </li>
            </> : <></>}


            {isMonitoringView ? <>
              <li>
                <button
                  onClick={(e) => handleChange(e, "monitoringConf")}
                  type="button"
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light pl-5 flex items-center w-full p-2 text-base font-normal rounded-lg text-white  hover:bg-gray-700 ${selectedpanel === "monitoringConf"
                    ? "text-white"
                    : "text-gray-400"
                    }`}
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  <img src={configIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    sidebar-toggle-item="true"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Monitoring
                  </span>
                </button>
                <ul id="dropdown-example" className={active.monitoringConf}>

                  {isPracticeVideoMonitoringView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>
                      <Link
                        onClick={() => handleLinkClick('/PracticeVideoMonitoring')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          Practice Video
                        </span>
                      </Link>
                    </li>
                  </> : <></>}


                  {isTrainingProgramMonitoringView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>
                      <Link
                        onClick={() => handleLinkClick('/MonitoringTrainingProgram')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          Training Program
                        </span>
                      </Link>
                    </li>
                  </> : <></>}

                  {isInstitutionVideoMonitoringView ? <>
                    <li onClick={() => setselectedpanel("my-authorizations")}>
                      <Link
                        onClick={() => handleLinkClick('/MonitoringInstitutionVideo')}
                        className={`flex items-center p-2 pl-11 w-full text-base font-normal  rounded-lg transition duration-75 group hover:bg-gray-100 ${selectedpanel === "my-authorizations"
                          ? "text-white"
                          : "text-gray-400"
                          }  hover:bg-gray-700`}
                      >
                        {/* <img src={authorizationIcon} className="h-4" /> */}
                        <span
                          className="flex-1 ml-3 text-left whitespace-nowrap"
                          style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                        >
                          Institution Video
                        </span>
                      </Link>
                    </li>
                  </> : <></>}


                </ul>
              </li>
            </> : <></>}

            {isRoleAccessView ? <>
              <li onClick={() => setselectedpanel("my-authorizations")}>
                <Link
                  onClick={() => handleLinkClick('/RoleAndAccess')}
                  className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "my-authorizations"
                    ? "text-white"
                    : "text-gray-400"
                    }  hover:bg-gray-700`}
                >
                  <img src={authorizationIcon} className="h-4" />
                  <span
                    className="flex-1 ml-3 text-left whitespace-nowrap"
                    style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                  >
                    Role and Access
                  </span>
                </Link>
              </li>
            </> : <></>}

            {/* EndErrorLog MatchAnalysisErrorLog */}
            <li
              onClick={() => {
                logout();
                window.location.reload();
              }}
            >
              <Link
                to="/Login"
                className={`hover:bg-gradient-to-r from-lg-blue to-lg-blue-light flex items-center w-full p-2 pl-5 text-base font-normal rounded-lg ${selectedpanel === "logout" ? "text-white" : "text-gray-400"
                  }  hover:bg-gray-700`}
              >
                <img src={logoutIcon} className="h-4" />
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  style={{ display: props?.hide ? "none" : "", fontSize: 15 }}
                >
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
