import React from "react";
import { useEffect, useState } from "react";
// import { useOutletContext } from "react-router-dom";
import Header from "./Components/Header";
import SearchAndFiltersSection from "./Components/PlayerRegister/SearchAndFilterSection";
import PlayerList from "./Components/PlayerRegister/PlayerList";
import PlayerForm from "./Components/PlayerRegister/PlayerForm";
import { getLoggedInUserInstitutionID } from "./Helper";

const PlayerRegister = () => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedPlayerName, setSelectedPlayerName] = useState("");
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState("");
  const [reloadPlayerList, setReloadPlayerList] = useState(0);
  // Keep track of whether the loggedInUserInstitutionID has been fetched - otherwise there is an error in PlayerTable component of PlayerList
  const [isLoading, setIsLoading] = useState(true);

  const [loggedInUserInstitutionID, setLoggedInUserInstitutionID] =
    useState("");

  // this sets institution id in browser session and is used by playerList
  useEffect(() => {
    const fetchData = async () => {
      const data = await getLoggedInUserInstitutionID();
      setLoggedInUserInstitutionID(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="m-12 px-12 py-6 border border-black border-2 rounded-xl flex flex-col gap-4">
        <Header title={"Player Register"} />
        <SearchAndFiltersSection
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <PlayerList
            searchInput={searchInput}
            selectedFilter={selectedFilter}
            setSelectedPlayerName={setSelectedPlayerName}
            loggedInUserInstitutionID={loggedInUserInstitutionID}
            setSelectedPlayerIndex={setSelectedPlayerIndex}
            selectedPlayerIndex={selectedPlayerIndex}
            reloadPlayerList={reloadPlayerList}
          />
        )}
        <PlayerForm
          selectedPlayerIndex={selectedPlayerIndex}
          setReloadPlayerList={setReloadPlayerList}
          reloadPlayerList={reloadPlayerList}
        />
      </div>
    </>
  );
};

export default PlayerRegister;
