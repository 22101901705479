import React, { useState, useEffect, useRef } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Swal from "sweetalert2";
import RefreshIcon from '@mui/icons-material/Refresh';
import SwitchIcon from "../../../assets/switch.png";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import CancelIcon from '@mui/icons-material/Cancel';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { SignalWifiOff } from '@mui/icons-material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  ButtonGroup,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  List,
  CircularProgress,
  Checkbox,
} from "@mui/material";

import {
  AddAnalysis,
  GetAllBatBallTypes,
  GetAllTeamMatchMembersListByTeamID,
  getBallAnalysis,
  UpdateScoreAnalysis,
  getScoreOverAnalysis,
  changeMasterInningSwitch,
  CheckSubmitInning,
  AddLogCheckPageActiveFirebase,
  GetAppUserList,
  AddPlayerInTeamFromMatchAnalysis,
  CreateUserInTeamFromMatchAnalysis,
  GetLatestThreeBallDetail,
  GetSingleBowlerStatistics,
  getStrikerNonStrikerStatistics,
  getUserForScoreboard,
  getBowlerForScoreboard,
  getListOfFirstTotalOver,
  updateBookmarkToBall,
  updateForceCompleteToBall,
  getLatestEighteenBallDetail,
  unZipVideoFromFileName,
  getCurrentBallVideo,
  updateInninginMaster,
  getPromicingPlayerList,
  AddPromicingPlayerList,
  AddPromicingBatsman,
  updateVideoUrlPrevious,
  addErrorLogMatchAnalysis,
  calculateInternetSpeed,
  addErrorLogMatchAnalysisInternet,
  GetNonDeliveredBallAnalysis,
  CheckSwtchinning,
  getWideNoBallCount,
  cancelAllRequests,
  update_random_analysis_db,
  checkLastBallDeliverData,
  updateNeglectToBall,
  logRecordingTime
} from "./Helper";

import BowlingInput from "../../../components/BowlingInput";
import BattingInput from "../../../components/BattingInput";
import { ordinal_suffix_of } from "../../../utils";
import * as defaults from "./Helper/defaults";
import LoadingIndicator from "../../../components/LoadingIndicator";
import firebase from '../../../firebase';
import axios from "axios";
import VideoPlayerDialog from "./videoPlayerDialog";
import Switch from '@mui/material/Switch';

const AnalysisInput = () => {
  const [showSpeedTestModal, setShowSpeedTestModal] = React.useState(false);
  const OpenSpeedTestModal = () => {
    setShowSpeedTestModal(true);
  }

  const [cameraType, setCameraType] = useState('external');
  const toggleCameraType = () => {
    if (cameraType === 'mobile') {
      //console.log('Switching camera to external');
      setCameraType('external');
    } else if (cameraType === 'external') {
      // console.log('Switching camera to mobile');
      setCameraType('mobile');
    }
  };

  const [eighteenballLoader, setEighteenBallLoader] = useState(true);
  const [submitBowlerDisabled, setSubmitBowlerDisabled] = useState(false);
  const [submitBatsmanDisabled, setSubmitBatsmanDisabled] = useState(false);
  const [submitAppUserDisabled, setSubmitAppUserDisabled] = useState(false);
  const [isEditHavingWicket, setIsEditHavingWicket] = useState(false);


  const [tournamentNameScoreLoader, setTournamentNameScoreLoader] = useState(true);
  const [latestThreeBallLoader, setlatestThreeBallLoader] = useState(true);
  const [frontBackFootSkillLoader, setfrontBackFootSkillLoader] = useState(true);
  const [battingBowlingImgLoader, setbattingBowlingImgLoader] = useState(false);
  const [sixDigitNumber, setSixDigitNumber] = useState("");
  const [isUseEffectCall, setIsUseEffectCall] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [speed, setSpeed] = useState("0.00");
  const [camHeader, setcamHeader] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [startNewTime, setStartNewTime] = React.useState("");
  const [time, setTime] = useState(0);
  const start_time = useRef();
  const end_interval = useRef();
  const global_initial_val = { "initial_val": 0 };
  const [initial_start_page, setInitialStartPage] = useState({
    initial: false
  });
  const [intervalId, setIntervalId] = useState(null);
  const [lapStartTime, setLapStartTime] = useState(0);
  const [currentLapDiff, setCurrentLapDiff] = useState(0);
  const [status, setStatus] = useState("stopped");
  const [demoValue, setDemoValue] = useState(0);
  const [laps, setLaps] = useState([]);
  const db = firebase.firestore();
  const [videoSourcFile, setVideoSourcFile] = useState("");
  const [showLiveUmpireModalLoader, setshowLiveUmpireModalLoader] = React.useState(false);
  const [cameraFirstAvailable, setCameraFirstAvailable] = useState([]);
  const [cameraSecondAvailable, setCameraSecondAvailable] = useState([]);
  const [cameraLiveFirstAvailable, setCameraLiveFirstAvailable] = useState([]);
  const [cameraLiveSecondAvailable, setLiveCameraSecondAvailable] = useState([]);
  const [camType, setCamType] = useState("Live");
  const [showLiveUmpireModal, setshowLiveUmpireModal] = React.useState(false);
  const [reqiredAnalysisdataForLiveUmpire, setReqiredAnalysisdataForLiveUmpire] = useState([]);
  const [parentMarkerData, setParentMarkerData] = useState({
    parent: false,
    parent_selectedMarkerOption: 'pitch',
    parent_position_pitch_x: 0,
    parent_position_pitch_y: 0,
    parent_position_stump_x: 0,
    parent_position_stump_y: 0,
    parent_bowling_length: '',
    parent_bowling_result: ''
  });

  const [isNonDeliveryBallClick, setisNonDeliveryBallClick] = React.useState(false);
  const [NonDeliveryCurrentBall, setNonDeliveryCurrentBall] = React.useState("");
  const [isCheckInternet, setIsCheckInternet] = useState(false);
  const intervalRef = useRef(null);
  //let allIntervalIds = [];
  const [allIntervalIds, setAllIntervalIds] = useState([]);
  const [autoSixDigitNumber, setAutoSixDigitNumber] = useState("");
  const [autoSixDigitNumberEdit, setAutoSixDigitNumberEdit] = useState("");
  const [isNextBallClick, setIsNextBallClick] = React.useState(false);

  let demoCount = 0;
  useEffect(() => {
    localStorage.setItem("isMbInternetConnectionLost", false);
    localStorage.setItem("allActiveIntervalIds", JSON.stringify([]));
    localStorage.setItem("allActiveIntervalStatusIds", JSON.stringify([]));
    localStorage.setItem("localStartNewTime", "");
    localStorage.setItem("WideNoBallRandomNumber", "");
    localStorage.setItem("isNextBallClick", false);
    localStorage.setItem("isFirstInningChange", false);
  }, [])

  const calculateInternetSpeedBtn = (buttonVal) => (e) => {
    setIsCheckInternet(buttonVal)
    if (buttonVal == true) {
      calculateInternetSpeed().then(async (internet_data) => {
        if (typeof internet_data != "undefined") {
          setSpeed(internet_data.data);
        }
        else {
          setSpeed('0.00');
        }
      });
      intervalRef.current = setInterval(() => {
        calculateInternetSpeed().then(async (internet_data) => {
          if (typeof internet_data != "undefined") {
            setSpeed(internet_data.data);
          }
          else {
            setSpeed('0.00');
          }
        });
      }, 1000);
    }
    else {
      clearInterval(intervalRef.current);
      setSpeed('0.00');
    }
  }

  useEffect(() => {
    const handleOnlineStatusChange = async () => {
      if (navigator.onLine == true) {
        if (localStorage.getItem("offline_internet_logs") != null) {
          let offline_internet_logs = JSON.parse(localStorage.getItem("offline_internet_logs"));

          const add_error_log_match_analysis_internet = await addErrorLogMatchAnalysisInternet(offline_internet_logs);
          localStorage.removeItem("offline_internet_logs");
        }
      } else {
        let offline_internet_logs = [];

        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = "";
        match_analysis_log["overs"] = "";
        match_analysis_log["current_ball"] = "";
        match_analysis_log["type_of_error"] = "Internet Connection Alert";
        match_analysis_log["error_description"] = "You are offline. Please check your internet connection.";
        match_analysis_log["input_data"] = {};

        if (localStorage.getItem("offline_internet_logs") != null) {
          offline_internet_logs = JSON.parse(localStorage.getItem("offline_internet_logs"));
        }
        offline_internet_logs.push(match_analysis_log);
        localStorage.setItem("offline_internet_logs", JSON.stringify(offline_internet_logs));
      }
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);
  useEffect(() => {
    localStorage.removeItem("recording_ball_detail_first");
    localStorage.removeItem("recording_ball_detail_second");
  }, []);
  const [battingTeamName, setBattingTeamName] = useState("");
  const [ballingTeamName, setBallingTeamName] = useState("");
  const [battingTeamDetails, setBattingTeamDetails] = useState("");
  const [ballingTeamDetails, setBallingTeamDetails] = useState("");
  const [showBowlerModal, setShowBowlerModal] = React.useState(false);
  const [isFirstInningWon, setIsFirstInningWon] = React.useState(false);
  const [appUserList, setAppUserList] = useState([])
  const [appUser, setAppUser] = useState()
  const [showPlayerEditModal2, setShowPlayerEditModal2] = React.useState(false);
  const [showPlayerEditModal3, setShowPlayerEditModal3] = React.useState(false);
  const OpenPlayerEditModal2 = () => {
    setShowPlayerEditModal2(true)
    GetAppUserList()
      .then((data) => {
        setAppUserList(data.data)
      })
      .catch((err) => {
      });
  }

  const OpenPlayerEditModal3 = () => {
    setShowPlayerEditModal3(true)
    GetAppUserList()
      .then((data) => {
        setAppUserList(data.data)
      })
      .catch((err) => {
      });
  }
  const handleAppUser = (val) => (e) => {
    e.preventDefault();
    setAppUser(e.target.value);
  };

  const handleAppUserModalSubmit = (val) => async (e) => {
    e.preventDefault();
    let isPlayerExist = false;
    battingTeamDataDetails.forEach((player) => {
      if (player.id == appUser) {
        isPlayerExist = true;
      }
    })
    bowlingTeamDataDetails.forEach((player) => {
      if (player.id == appUser) {
        isPlayerExist = true;
      }
    })
    if ((appUser != '') && (appUser != null) && (appUser != 'Select Player') && (!isPlayerExist)) {
      if (val == 'Bowler') {
        setSubmitAppUserDisabled(true);
        AddPlayerInTeamFromMatchAnalysis({
          id: appUser,
          teamDetails: ballingTeamDetails,
          type: 'Bowler',
          teamMatchId: navData.match_id,
          current_inning: innings
        }).then(async (data) => {
          setAppUser('')
          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            innings === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );

          let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
            let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (bowlingTeamData?.status)
            setBowlingTeamDataDetails(mergedBallArray);

          if (data?.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Player added successfully'
            });
            setShowPlayerEditModal2(false)
          } else {
            let match_analysis_log = {};
            match_analysis_log["analysis_master_id"] = navData.id;
            match_analysis_log["tournament_id"] = navData.tournament_id;
            match_analysis_log["match_id"] = navData.match_id;
            match_analysis_log["current_inning"] = innings;
            match_analysis_log["overs"] = totalOvers;
            match_analysis_log["current_ball"] = current_ball;
            match_analysis_log["type_of_error"] = "Player Exist";
            match_analysis_log["error_description"] = "Player already exist";
            match_analysis_log["input_data"] = {
              id: appUser,
              teamDetails: ballingTeamDetails,
              type: 'Bowler',
              teamMatchId: navData.match_id,
              current_inning: innings
            };

            const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Player already exist'
            })
          }
        }).catch((err) => {
          Swal.fire({
            icon: 'error',
            title: '⛔',
            text: err
          })
        }).finally(() => {
          setSubmitAppUserDisabled(false); // Enable the button
        });
      }
      else {
        setSubmitAppUserDisabled(true);
        AddPlayerInTeamFromMatchAnalysis({
          id: appUser,
          teamDetails: battingTeamDetails,
          type: 'Batsman',
          teamMatchId: navData.match_id,
          current_inning: innings
        }).then(async (data) => {
          setAppUser('')
          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            innings === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );

          let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
            let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (battingTeamData?.status)
            setBattingTeamDataDetails(mergedBatArray);

          if (data?.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Player added successfully'
            });
            setShowPlayerEditModal3(false)
          } else {
            let match_analysis_log = {};
            match_analysis_log["analysis_master_id"] = navData.id;
            match_analysis_log["tournament_id"] = navData.tournament_id;
            match_analysis_log["match_id"] = navData.match_id;
            match_analysis_log["current_inning"] = innings;
            match_analysis_log["overs"] = totalOvers;
            match_analysis_log["current_ball"] = current_ball;
            match_analysis_log["type_of_error"] = "Player Exist";
            match_analysis_log["error_description"] = "Player already exist";
            match_analysis_log["input_data"] = {
              id: appUser,
              teamDetails: battingTeamDetails,
              type: 'Batsman',
              teamMatchId: navData.match_id,
              current_inning: innings
            };
            const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Player already exist.'
            })
          }
        }).catch((err) => {
          Swal.fire({
            icon: 'error',
            title: '⛔',
            text: err
          })
        }).finally(() => {
          setSubmitAppUserDisabled(false); // Enable the button
        });
      }
    }
    else {
      if (!isPlayerExist) {
        Swal.fire({
          icon: 'error',
          title: '👎',
          text: 'Please select player'
        })
      }
      else {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Player Already Exist";
        match_analysis_log["error_description"] = "This player is already a member of either the same team or another team";
        match_analysis_log["input_data"] = {
          player: appUser
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
        Swal.fire({
          icon: 'error',
          title: '👎',
          text: 'This player is already a member of either the same team or another team'
        })
      }
    }
  }

  const [systemTime, setSystemTime] = useState(new Date());
  const [startLapTime, setStartLapTime] = useState(0);
  const updateSystemTime = () => {
    setSystemTime(new Date());
  };

  useEffect(() => {
    const timer = setInterval(updateSystemTime, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleBowlerPlayerFullname = (val) => (e) => {
    e.preventDefault();
    setBowlerPlayerFullname(e.target.value);
  };

  const handleBowlerPlayerMobile = (val) => (e) => {
    e.preventDefault();
    setBowlerPlayerMobile(e.target.value);
  };

  const handleBowlerPlayerBatting = (val) => (e) => {
    e.preventDefault();
    setBowlerPlayerBatting(e.target.value);
  };

  const handleBowlerPlayerBowling = (val) => (e) => {
    e.preventDefault();
    setBowlerPlayerBowling(e.target.value);
  };

  const handleBowlerModalSubmit = (e) => {
    e.preventDefault();
    if ((BowlerPlayerFullname == '') || (BowlerPlayerMobile == '') || (BowlerPlayerBatting == '') || (BowlerPlayerBowling == '') || (BowlerPlayerBatting == 'Select Batting Type') || (BowlerPlayerBowling == 'Select Bowling Type')) {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'All Fields Are Compulsory'
      })
    }
    else {
      setSubmitBowlerDisabled(true); // Disable the button
      CreateUserInTeamFromMatchAnalysis({
        playerDetails: {
          fullname: BowlerPlayerFullname,
          mobile: BowlerPlayerMobile,
          batting: BowlerPlayerBatting,
          bowling: BowlerPlayerBowling,
        },
        teamDetails: ballingTeamDetails,
        type: 'Bowler',
        teamMatchId: navData.match_id,
        current_inning: innings
      }).then(async (data) => {

        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player added successfully'
          });
          setShowBowlerModal(false)
          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            innings === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );

          let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
            let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (bowlingTeamData?.status)
            setBowlingTeamDataDetails(mergedBallArray);
          setBowlerPlayerFullname("")
          setBowlerPlayerMobile("")
          setBowlerPlayerBatting("")
          setBowlerPlayerBowling("")
        } else {
          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Player Exist - New";
          match_analysis_log["error_description"] = "Player already exist, Please try another mobile number";
          match_analysis_log["input_data"] = {
            playerDetails: {
              fullname: BowlerPlayerFullname,
              mobile: BowlerPlayerMobile,
              batting: BowlerPlayerBatting,
              bowling: BowlerPlayerBowling,
            },
            teamDetails: ballingTeamDetails,
            type: 'Bowler',
            teamMatchId: navData.match_id,
            current_inning: innings
          };

          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Player already exist, Please try another mobile number'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      }).finally(() => {
        setSubmitBowlerDisabled(false); // Enable the button
      });
    }
  }
  const [BowlerPlayerFullname, setBowlerPlayerFullname] = useState("");
  const [BowlerPlayerMobile, setBowlerPlayerMobile] = useState("");
  const [BowlerPlayerBatting, setBowlerPlayerBatting] = useState("");
  const [BowlerPlayerBowling, setBowlerPlayerBowling] = useState("");
  const [showBatsmanModal, setShowBatsmanModal] = React.useState(false);
  const [BatsmanPlayerFullname, setBatsmanPlayerFullname] = useState("");
  const [BatsmanPlayerMobile, setBatsmanPlayerMobile] = useState("");
  const [BatsmanPlayerBatting, setBatsmanPlayerBatting] = useState("");
  const [BatsmanPlayerBowling, setBatsmanPlayerBowling] = useState("");

  const handleBatsmanPlayerFullname = (val) => (e) => {
    e.preventDefault();
    setBatsmanPlayerFullname(e.target.value);
  };

  const handleBatsmanPlayerMobile = (val) => (e) => {
    e.preventDefault();
    setBatsmanPlayerMobile(e.target.value);
  };

  const handleBatsmanPlayerBatting = (val) => (e) => {
    e.preventDefault();
    setBatsmanPlayerBatting(e.target.value);
  };

  const handleBatsmanPlayerBowling = (val) => (e) => {
    e.preventDefault();
    setBatsmanPlayerBowling(e.target.value);
  };

  const handleBatsmanModalSubmit = (e) => {
    e.preventDefault();
    if ((BatsmanPlayerFullname == '') || (BatsmanPlayerMobile == '') || (BatsmanPlayerBatting == '') || (BatsmanPlayerBowling == '') || (BatsmanPlayerBatting == 'Select Batting Type') || (BatsmanPlayerBowling == 'Select Bowling Type')) {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'All Fields Are Compulsory'
      })
    }
    else {
      setSubmitBatsmanDisabled(true);
      CreateUserInTeamFromMatchAnalysis({
        playerDetails: {
          fullname: BatsmanPlayerFullname,
          mobile: BatsmanPlayerMobile,
          batting: BatsmanPlayerBatting,
          bowling: BatsmanPlayerBowling,
        },
        teamDetails: battingTeamDetails,
        type: 'Batsman',
        teamMatchId: navData.match_id,
        current_inning: innings
      }).then(async (data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player added successfully'
          });
          setShowBatsmanModal(false)
          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            innings === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );

          let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
            let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (battingTeamData?.status)
            setBattingTeamDataDetails(mergedBatArray);

          setBatsmanPlayerFullname("")
          setBatsmanPlayerMobile("")
          setBatsmanPlayerBatting("")
          setBatsmanPlayerBowling("")
        } else {
          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Player Exist - New";
          match_analysis_log["error_description"] = "Player already exist, Please try another mobile number";
          match_analysis_log["input_data"] = {
            playerDetails: {
              fullname: BatsmanPlayerFullname,
              mobile: BatsmanPlayerMobile,
              batting: BatsmanPlayerBatting,
              bowling: BatsmanPlayerBowling,
            },
            teamDetails: battingTeamDetails,
            type: 'Batsman',
            teamMatchId: navData.match_id,
            current_inning: innings
          };

          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Player already exist, Please try another mobile number'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      }).finally(() => {
        setSubmitBatsmanDisabled(false); // Enable the button
      });
    }
  }
  const batting_options = ['Right Hand', 'Left Hand'];
  const bowling_options = ['Fast', 'Leg Spin', 'Off Spin'];

  const getAllDocuments = async (collectionName) => {
    const documents = await db.collection(collectionName).get();
    return documents.docs.map((doc) => doc.id);
  };

  const getAllLiveDocuments = async (collectionName) => {
    const livedocuments = await db.collection(collectionName).get();
    return livedocuments.docs.map((doc) => doc.id);
  };

  const getLiveDocument = async (collectionName, documentId) => {
    const documentRef = db.collection(collectionName).doc(documentId);
    const documentSnapshot = await documentRef.get();
    if (documentSnapshot.exists) {
      return documentSnapshot.data();
    } else {
      return null;
    }
  };

  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => {
          const seconds = prevTimer.seconds + 1;
          const minutes = prevTimer.minutes + Math.floor(seconds / 60);
          const hours = prevTimer.hours + Math.floor(minutes / 60);

          return {
            seconds: seconds % 60,
            minutes: minutes % 60,
            hours: hours
          };
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive]);


  const handleSetCamType = (val) => (e) => {
    e.preventDefault();
    if (val === "Live") {
      setCamType("Live")
      setShowLiveSection(true);
      setShowCamSection(false);
    }
    else {
      setCamType("Cam")
      setShowLiveSection(false);
      setShowCamSection(true);
    }
  }

  function handleStartTime() {
    let h_time;
    if (timer.hours <= 9) {
      h_time = "0" + timer.hours;
    }
    else {
      h_time = timer.hours;
    }

    let m_time;
    if (timer.minutes <= 9) {
      m_time = "0" + timer.minutes;
    }
    else {
      m_time = timer.minutes;
    }

    let s_time;
    if (timer.seconds <= 9) {
      s_time = "0" + timer.seconds;
    }
    else {
      s_time = timer.seconds;
    }

    setStartNewTime(`${h_time}:${m_time}:${s_time}`)
  }

  function handleLap(value, clickFrom) {
    if ((startLapTime !== 0)) {
      const currentTimeInSeconds = systemTime.getHours() * 3600 + systemTime.getMinutes() * 60 + systemTime.getSeconds();
      const timeDifference = currentTimeInSeconds - startLapTime;
      if (timeDifference < 10) {
        setDemoValue(timeDifference); // Update demoValue with time difference in seconds
      }
    }
  }

  useEffect(() => {
    if (status === "running") {
      const id = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 1000);
      setIntervalId(id);
    } else if (status === "stopped") {
      clearInterval(intervalId);
      setTime(0);
      setLaps([]);
      setLapStartTime(0);
      setCurrentLapDiff(0);
    }
    return () => clearInterval(intervalId);
  }, [status]);

  const [showLiveSection, setShowLiveSection] = useState(true);
  const [showCamSection, setShowCamSection] = useState(true);
  const [parentWagonMsgData, setParentWagonMsgData] = useState({
    parent: false,
    parent_batting_msg: ''
  });
  const [runs, setRuns] = useState(0);
  const [wickets, setWickets] = useState(0);
  const [nextSubmitInning, setNextSubmitInning] = useState(false);
  const [end_match_inning, setEnd_match_inning] = useState(0);
  const [end_match_inning1, setEnd_match_inning1] = useState(0);
  const [end_match_inning2, setEnd_match_inning2] = useState(0);
  const [HeadingCommonName, setHeadingCommonName] = useState("");
  const [positionBatType, setPositionBatType] = useState({ pitch_position_bat_type: "Right" });
  const [positionBatTypeBatInput, setPositionBatTypeBatInput] = useState({ waghoon_wheel_position_bat_type: "Right" });
  const [mobileVideoStatus, setMobileVideoStatus] = useState("");
  const [update_runs, setUpdateRuns] = useState(0);
  const [update_wickets, setUpdateWickets] = useState(0);
  const handleUpdateRuns = (events) => {
    setUpdateRuns(events.target.value)
  }
  const handleUpdateWickets = (events) => {
    setUpdateWickets(events.target.value)
  }
  const [showScoreEditPanel, setShowScoreEditPanel] = useState(false);
  const toggleShowScoreEditPanel = () => {
    setShowScoreEditPanel(!showScoreEditPanel);
    setUpdateRuns(runs);
    setUpdateWickets(wickets);
  };
  const [current_runs, setCurrentRuns] = useState(0);
  const handleRuns = (events) => {
    setCurrentRuns(events.target.value)
  }

  const handleWickets = (events) => {
  }

  const handleSetExtraBallResult = (val) => (e) => {
    e.preventDefault();
    setExtraBallResult(val);
  };

  const [extraBallResult, setExtraBallResult] = useState('');

  const setExtraBallBlank = ({ data, blank }) => {
    setExtraBallResult(!blank ? data.extra_ball_delivery_result : "");
  }

  const [wicketType, setWicketType] = useState();
  const [showRunOutButtons, setShowRunOutButtons] = useState(false);
  const [IsAppeal, setIsAppeal] = useState(false);
  const toggleShowIsAppeal = () => setIsAppeal(!IsAppeal);
  const [IsBeaten, setIsBeaten] = useState(false);
  const toggleShowIsBeaten = () => setIsBeaten(!IsBeaten);
  const [IsWicketTaking, setIsWicketTaking] = useState(false);
  const toggleShowIsWicketTaking = () => setIsWicketTaking(!IsWicketTaking);
  const [IsBatsmanUncomfortable, setIsBatsmanUncomfortable] = useState(false);
  const toggleShowIsBatsmanUncomfortable = () => setIsBatsmanUncomfortable(!IsBatsmanUncomfortable);

  const handleWicketType = (val) => (e) => {
    e.preventDefault();
    setWicketType(val);
    if (val === "runOut") {
      setShowRunoutModalWindow(true)
      setShowRunOutButtons(!showRunOutButtons);
    } else {
      setShowRunOutButtons(false);
      setRunOutRunResult(null)
    }
  }

  const showLiveUmpire = async (data) => {
    setshowLiveUmpireModalLoader(true)
    cameraFirstAvailable.length = 0;
    cameraSecondAvailable.length = 0;
    cameraLiveFirstAvailable.length = 0;
    cameraLiveSecondAvailable.length = 0;
    if (data.Camera1User != "") {

      let cam1_id = data.analysisMasterData.Camera1User;
      const documents = await getAllDocuments('StartStopCamCollections');

      let livedocuments = []; // declare and initialize the variable outside the loop
      livedocuments = await getAllLiveDocuments('StartStopLiveCollections');

      for (let i = 0; i < livedocuments.length; i++) {

        const livedoc = await getLiveDocument('StartStopLiveCollections', livedocuments[i]);
        if ((livedoc != null) && (typeof livedoc.Camera1 != "undefined") && (typeof livedoc.Camera2 != "undefined")) {

          if ((livedoc.Camera1.UserID == cam1_id) || (livedoc.Camera2.UserID == cam1_id)) {

            let document_detail = { document_name: livedocuments[i] };

            cameraLiveFirstAvailable.push(document_detail);
          }
        }
      }
      for (let i = 0; i < documents.length; i++) {
        const documentData = await getLiveDocument('StartStopCamCollections', documents[i]);

        if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
          if ((documentData.Camera1.UserID == cam1_id) || (documentData.Camera2.UserID == cam1_id)) {
            let document_detail = { document_name: documents[i] };

            cameraFirstAvailable.push(document_detail);
          }
        }
      }
    }
    if (data.Camera2User != "") {
      let cam2_id = data.analysisMasterData.Camera2User;
      const documents = await getAllDocuments('StartStopCamCollections');
      let livedocuments = []; // declare and initialize the variable outside the loop
      livedocuments = await getAllLiveDocuments('StartStopLiveCollections');

      for (let i = 0; i < livedocuments.length; i++) {
        const livedoc = await getLiveDocument('StartStopLiveCollections', livedocuments[i]);
        if ((livedoc != null) && (typeof livedoc.Camera1 != "undefined") && (typeof livedoc.Camera2 != "undefined")) {
          if ((livedoc.Camera1.UserID == cam2_id) || (livedoc.Camera2.UserID == cam2_id)) {
            let document_detail = { document_name: livedocuments[i] };
            cameraLiveSecondAvailable.push(document_detail);
          }
        }
      }
      for (let i = 0; i < documents.length; i++) {
        const documentData = await getLiveDocument('StartStopCamCollections', documents[i]);
        if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
          if ((documentData.Camera1.UserID == cam2_id) || (documentData.Camera2.UserID == cam2_id)) {
            let document_detail = { document_name: documents[i] };
            cameraSecondAvailable.push(document_detail);
          }
        }
      }
    }

    if ((cameraFirstAvailable.length > 0) ||
      (cameraSecondAvailable.length > 0) ||
      (cameraLiveFirstAvailable.length > 0) ||
      (cameraLiveSecondAvailable.length > 0)) {
      setshowLiveUmpireModal(true)
      setshowLiveUmpireModalLoader(false)
    } else {
      Swal.fire({
        icon: "info",
        title: "",
        text: "Umpire is not engaged in any other match"
      });
      setshowLiveUmpireModalLoader(false);
    }
  }

  const [runOutRunResult, setRunOutRunResult] = useState(0);
  const handleRunOutRun = (val) => (e) => {
    e.preventDefault();
    setRunOutRunResult(val);
  }

  const startStopCamCollection = firebase.firestore().collection("StartStopCamCollections");
  const startStopLiveCollection = firebase.firestore().collection("StartStopLiveCollections");
  const [selectedVideoURLOption, setSelectedVideoURLOption] = useState('');

  const handleVideoURLOptionChange = (event) => {
    setSelectedVideoURLOption(event.target.value);
  };

  const [variableValue, setVariableValue] = useState('');

  const handleVariableChange = (value) => {
    setVariableValue(value);
  };

  const [isBowlingVisible, setIsBowlingVisible] = useState(true);
  const [isBattingVisible, setIsBattingVisible] = useState(false);

  const changeRightArrow = (event) => {
    setIsBattingVisible(false);
    setIsBowlingVisible(true);
  };

  const changeLeftArrow = (event) => {
    setIsBattingVisible(true);
    setIsBowlingVisible(false);
  };

  const switchCamera = (e) => {
    if (selecteCamera == 'Camera1') {
      setSelecteCamera("Camera2");
    }
    else {
      setSelecteCamera("Camera1");
    }
  }

  const switchBatsmanNonBatsman = async (event) => {
    try {
      let nonStrikerId_switch = nonStrikerId;
      let strikerId_switch = strikerId;

      let nonStrikerName_switch = nstrikerName;
      let strikerName_switch = strikerName;
      // Check if both striker and non-striker IDs are available
      if (!nonStrikerId_switch || !strikerId_switch) {

        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Player Shuffle";
        match_analysis_log["error_description"] = "Please select both a striker and a non-striker.";
        match_analysis_log["input_data"] = {
          nonStrikerId_switch: nonStrikerId_switch,
          strikerId_switch: strikerId_switch
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "Please select both a striker and a non-striker."
        );
        return;
      }
      // Set loading state to true
      setbattingBowlingImgLoader(true);
      // Use the getStrikerNonStrikerStatistics API to get the handedness of both the current striker and non-striker
      const batsmenData = await Promise.all([
        getStrikerNonStrikerStatistics({
          match_id: navData.match_id,
          current_inning: innings,
          bat_striker_id: strikerId_switch,
          bat_nstriker_id: null
        }),
        getStrikerNonStrikerStatistics({
          match_id: navData.match_id,
          current_inning: innings,
          bat_striker_id: nonStrikerId_switch,
          bat_nstriker_id: null
        })
      ]);
      const [strikerData, nonStrikerData] = batsmenData;
      // Check if the data for both striker and non-striker is available
      if (!strikerData || !nonStrikerData || !strikerData.bat_striker_info || !nonStrikerData.bat_striker_info) {
        // Show a message to the user indicating that there was an issue getting batsman information        
        Swal.fire(
          "Info",
          "There was an issue getting batsman information. Please try again."
        );
        return;
      }
      setStrikerId(nonStrikerId_switch);
      setNonStrikerId(strikerId_switch);
      setStrikerName(nonStrikerName_switch)
      setnStrikerName(strikerName_switch)
      let TotalnStrikerRun = total_nstriker_run;
      let TotalnStrikerBall = total_nstriker_ball;
      let TotalnStrikerFourRun = total_nstriker_four_run;
      let TotalnStrikerSixRun = total_nstriker_six_run;
      let nStrikerStrikeRate = nstriker_strike_rate;
      setTotalStrikerRun(TotalnStrikerRun)
      setTotalStrikerBall(TotalnStrikerBall)
      setTotalStrikerFourRun(TotalnStrikerFourRun)
      setTotalStrikerSixRun(TotalnStrikerSixRun)
      setStrikerStrikeRate(nStrikerStrikeRate)
      let TotalStrikerRun = total_striker_run;
      let TotalStrikerBall = total_striker_ball;
      let TotalStrikerFourRun = total_striker_four_run;
      let TotalStrikerSixRun = total_striker_six_run;
      let StrikerStrikeRate = striker_strike_rate;
      setTotalnStrikerRun(TotalStrikerRun)
      setTotalnStrikerBall(TotalStrikerBall)
      setTotalnStrikerFourRun(TotalStrikerFourRun)
      setTotalnStrikerSixRun(TotalStrikerSixRun)
      setnStrikerStrikeRate(StrikerStrikeRate)
      // Update batsman type and position bat type for the new striker
      setBatsmanType(strikerData.bat_striker_info.batsman_detail.batting);
      setPositionBatType({
        pitch_position_bat_type: strikerData.bat_striker_info.batsman_detail.batting === "Left Hand" ? "Left" : "Right"
      });
      // Update batsman type and position bat type for the new non-striker
      setBatsmanType(nonStrikerData.bat_striker_info.batsman_detail.batting);
      setPositionBatType({
        pitch_position_bat_type: nonStrikerData.bat_striker_info.batsman_detail.batting === "Left Hand" ? "Left" : "Right"
      });
      // Set loading state to true
      setbattingBowlingImgLoader(false);
    } catch (error) {
      // Handle errors if any
      console.error("Error:", error);
      // Set loading state to false in case of an error
      setbattingBowlingImgLoader(false);
    }
    // setBatsmanType("Left Hand")
  };

  const [selectedBowlingOption, setSelectedBowlingOption] = useState('fast_bowling');
  const handleBowlingOptionChange = (event) => {
    setSelectedBowlingOption(event.target.value);
  }
  const [selectedSpinBowlingOption, setSelectedSpinBowlingOption] = useState('front_foot');
  const handleSpinBowlingOptionChange = (event) => {
    setSelectedSpinBowlingOption(event.target.value);
  }
  const [selectedBattingOption, setSelectedBattingOption] = useState('front_foot');
  const handleBattingOptionChange = (event) => {
    setSelectedBattingOption(event.target.value);
  }
  const { state: navData } = useLocation();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [backFootTypes, setBackFootTypes] = useState([]);
  const [frontFootTypes, setFrontFootTypes] = useState([]);
  const [battingTypes, setbattingTypes] = useState([]);
  const [battingTeam, setBattingTeam] = useState([]);
  const [battingTeamDataDetails, setBattingTeamDataDetails] = useState([]);
  const [bowlingTeamDataDetails, setBowlingTeamDataDetails] = useState([]);
  const [fastBowlingTypes, setFastBowlingTypes] = useState([]);
  const [fastBowlingTypesFirst, setFastBowlingTypesFirst] = useState([]);
  const [fastBowlingTypesSecond, setFastBowlingTypesSecond] = useState([]);
  const [spinBowlingTypes, setSpinBowlingTypes] = useState([]);
  const [spinBowlingTypesFirst, setSpinBowlingTypesFirst] = useState([]);
  const [spinBowlingTypesSecond, setSpinBowlingTypesSecond] = useState([]);
  const [bowlingTeam, setBowlingTeam] = useState([]);

  const [showEditPanel, setShowEditPanel] = useState(false);
  const toggleShowEditPanel = () => {
    setShowEditPanel(!showEditPanel);
  }

  const [innings, setInnings] = useState("");
  const [current_ball, setCurrentBall] = useState(
    parseFloat(
      navData?.current_inning == 1
        ? navData?.inning1_current_ball
        : navData?.inning2_current_ball
    ).toFixed(1)
  );

  const [bowlerId, setBowlerId] = useState("");
  const [selectedWicketOption, setWicketAppeal] = useState("false");
  const [selectedIsBatsmanBeatenOption, IsBatsmanBeatenAppeal] = useState("false");
  const handleWicketOptionChange = (event) => {
    setWicketAppeal(event.target.value);
  };
  const handleIsBatsmanBeatenOptionChange = (event) => {
    IsBatsmanBeatenAppeal(event.target.value);
  };

  const [ballType, setBallType] = useState({ category: "", type: "" });
  const [bowlingData, setBowlingData] = useState({});
  const [ballResult, setBallResult] = useState("0");
  const [selecteCamera, setSelecteCamera] = useState('Camera1');
  const [isRecording, setIsRecording] = useState(false);
  const [isStopRecordingClicked, setIsStopRecordingClicked] = useState(false);
  const [isDisabledRecordButton, setIsDisabledRecordButton] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [openVideoPlayer, setOpenVideoPlayer] = useState({
    src: "",
    fileName: ""
  });
  const [isMbInternetConnectionLost, setIsMbInternetConnectionLost] = useState(false);

  const [bowlingPitchData, setBowlingPitchData] = useState({
    position_pitch_x: 0,
    position_pitch_y: 0
  });

  const [bowlingStumpData, setBowlingStumpData] = useState({
    position_stump_x: 0,
    position_stump_y: 0
  });

  const handleSetBallResult = (val) => (e) => {
    e.preventDefault();
    setBallResult(val);
    if (val === "W") {
      setShowRunOutButtons(!showRunOutButtons);
      setShowRunOutButtons(false);
    } else {
      setShowRunOutButtons(false);
      setWicketType(null);
      setRunOutRunResult(null);
    }
  };
  const [strikerId, setStrikerId] = useState("");
  const [nonStrikerId, setNonStrikerId] = useState("");
  const [battingResult, setBattingResult] = useState("");
  const [file, setFile] = useState(null);
  const fileInput = useRef();
  const handleFileInput = ({ target: { files } }) => {
    if (files[0].type.split("/")[0] !== "video") {
      Swal.fire({
        icon: "info",
        title: "😑",
        text: "Only video files are accepted",
      });
    } else setFile(files[0]);
  };

  const [updateFormData, setUpdateFormData] = useState({
    inning: null,
    over: null,
    ball: null,
  });

  const [latest_three_ball_details, setlatest_three_ball_details] = useState([]);
  const [latestEighteenBall, setlatestEighteenBall] = useState([]);
  const [secondInnNeedRuns, setSecondInnNeedRuns] = useState("");
  const [tournamentName, setTournamentName] = useState("");
  const [matchName, setMatchName] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [secondInnRemainingBalls, setSecondInnRemainingBalls] = useState("");
  const [isRequiredRun, setisRequiredRun] = useState(false);
  const [showRuns, setShowRuns] = React.useState(0);
  const [showOvers, setShowOvers] = React.useState(0);
  const [totalOvers, setTotalOvers] = React.useState(0);
  const [showWickets, setShowWickets] = React.useState(0);
  const [useEffectNum, setUseEffectNum] = useState({ initialValue: 0 });

  useEffect(() => {
    setUseEffectNum(prevValue => ({
      ...prevValue,
      initialValue: 1,
    }));
  }, []);

  useEffect(() => {
    if ((isUseEffectCall == true) && (useEffectNum.initialValue == 1)) {
      fetchTypes();
      let useEffectInning = "";
      getScoreOverAnalysis({
        tournament_id: navData.tournament_id,
        match_id: navData.match_id
      })
        .then(async (data) => {
          setInnings(data.data.current_inning);
          useEffectInning = data.data.current_inning;
          let current_ball;
          if (data.data.current_inning == 1) {
            setRuns(data.data.inning1_run);
            setWickets(data.data.inning1_wicket);
            setCurrentBall(data.data.inning1_current_ball);
            current_ball = data.data.inning1_current_ball;
          }
          else {
            setRuns(data.data.inning2_run);
            setWickets(data.data.inning2_wicket);
            setCurrentBall(data.data.inning2_current_ball);
            current_ball = data.data.inning2_current_ball;
            let new_over = navData.overs + '.1';
            if (new_over == data.data.inning2_current_ball) {
              setCurrentBall(navData.overs);
            }
          }

          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            data.data.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );

          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            data.data.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );

          let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
            let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
            let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (battingTeamData?.status)
            setBattingTeamDataDetails(mergedBatArray);

          if (bowlingTeamData?.status)
            setBowlingTeamDataDetails(mergedBallArray);

          if (data.data.current_inning == 2) {
            let nav_over = navData.overs + '.1';
            if (parseFloat(nav_over) == parseFloat(current_ball)) {
              current_ball = navData.overs;
            }
            if (data.data.inning2_end_match_over != 0) {
              if (parseFloat(current_ball) > data.data.inning2_end_match_over) {
                current_ball = data.data.inning2_end_match_over;
                setCurrentBall(current_ball);
              }
            }
          }

          getBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: data.data.current_inning,
            current_ball: current_ball,
            analysis_id: "",
            tournament_name: data.data.tournament_name,
            match_name: data.data.match_name,
            match_date: data.data.match_date,
            team_bat1: data.data.team_bat1,
            team_bat2: data.data.team_bat2
          })
            .then((fetchedData) => {
              if (fetchedData.analysisMasterData.current_inning == 1) {
                if (fetchedData.analysisMasterData.inning1_end_match_over != 0) {
                  setNextSubmitInning(true);
                  setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
                  setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
                }
              }
              else {
                if (fetchedData.analysisMasterData.inning2_end_match_over != 0) {
                  setNextSubmitInning(true);
                  setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over);
                  setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over);
                }
              }
              setShowRuns(fetchedData.analysisMasterData.total_combine_run)
              setShowOvers(fetchedData.analysisMasterData.heading_current_over)
              setShowWickets(fetchedData.analysisMasterData.total_wickets)
              setTotalOvers(fetchedData.analysisMasterData.overs)
              setReqiredAnalysisdataForLiveUmpire(fetchedData);
              setIsLoading(false);
              setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs)
              setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball)
              setisRequiredRun(true)

              if ((fetchedData.analysisMasterData.inning1_end_match_over != 0) && (fetchedData.analysisMasterData.inning2_end_match_over != 0)) {
                if ((fetchedData.collect_score_need_sec_inning.total_combine_run_first >= fetchedData.collect_score_need_sec_inning.total_combine_run_second)) {
                  setIsFirstInningWon(true);
                }
              }
              setinn1BatTeamName(fetchedData.team_name.team_bat1.name)
              setinn2BatTeamName(fetchedData.team_name.team_ball1.name)

              if (data.data.current_inning == 1) {
                setBallingTeamDetails(fetchedData.analysisMasterData.ball1_id)
                setBattingTeamDetails(fetchedData.analysisMasterData.bat1_id)
                setBattingTeamName(fetchedData.team_name.team_bat1.name)
                setBallingTeamName(fetchedData.team_name.team_ball1.name)
              }
              else {
                setBallingTeamDetails(fetchedData.analysisMasterData.ball2_id)
                setBattingTeamDetails(fetchedData.analysisMasterData.bat2_id)
                setBattingTeamName(fetchedData.team_name.team_bat2.name)
                setBallingTeamName(fetchedData.team_name.team_ball2.name)
              }
              setTournamentName(fetchedData.dynamic_team_name.tournament_name)
              setMatchName(fetchedData.dynamic_team_name.match_name)
              setMatchDate(fetchedData.dynamic_team_name.match_date)
              setTournamentNameScoreLoader(false)

              let header_name;
              if (data.data.current_inning == 1) {
                header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat1 + '_' + fetchedData.dynamic_team_name.match_date;
              }
              else {
                header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat2 + '_' + fetchedData.dynamic_team_name.match_date;
              }
              setcamHeader(header_name);
              localStorage.setItem('header_name', header_name);

              if (data.data.current_inning == 1) {
                setHeadingCommonName(header_name);
                setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + fetchedData.team_name.team_bat1.name + ') (Bowling -- ' + fetchedData.team_name.team_ball1.name + ')');
              } else {
                setHeadingCommonName(header_name);
                setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + fetchedData.team_name.team_bat2.name + ') (Bowling -- ' + fetchedData.team_name.team_ball2.name + ')');
              }
              if (header_name != "") {
                //fetchVideosListFirst(header_name);
              }

              if (fetchedData?.exists) {
                if (data.data.current_inning == 1) {
                  if (fetchedData.analysisMasterData.inning1_end_match_over != 0) {

                    setNextSubmitInning(true);
                    setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
                    setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
                  }
                }
                else {
                  if (fetchedData.analysisMasterData.inning2_end_match_over != 0) {
                    setNextSubmitInning(true);
                    setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over);
                    setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over);
                  }
                }
                setAnalysisData({ data: fetchedData.data, blank: false });
              } else {
              };

              GetLatestThreeBallDetail({
                match_id: navData.match_id,
                current_inning: useEffectInning

              }).then(async (data) => {
                setlatest_three_ball_details(data.latest_three_ball_details)
                setlatestThreeBallLoader(false)
              })
                .catch((e) => {
                });

              getLatestEighteenBallDetail({
                match_id: navData.match_id,
                current_inning: useEffectInning
              }).then(async (data) => {
                let arr_reverse = data.latest_eighteen_ball_details;
                let reversedArray = arr_reverse.slice().reverse();
                setlatestEighteenBall(reversedArray)
                setEighteenBallLoader(false)
              })
                .catch((e) => {
                });
            })
            .catch((e) => {
              setIsLoading(false);
              setAnalysisData({ data: null, blank: true });
            });
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [isUseEffectCall, useEffectNum.initialValue]); //, camHeader, innings // pass an empty array as the second argument to run it only once

  const handleDatabaseButtonClick = () => {
    setDisableCreateDbButton(true);
    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: innings,
      current_ball: current_ball,
      analysis_id: "",
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (fetchedData) => {
        let header_name;
        if (innings == 1) {
          header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat1 + '_' + fetchedData.dynamic_team_name.match_date;
        }
        else {
          header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat2 + '_' + fetchedData.dynamic_team_name.match_date;
        }
        setHeadingCommonName(header_name);
        setIsUseEffectCall(false);
        setcamHeader(header_name);
        localStorage.setItem('header_name', header_name);


        const documents = await getAllDocuments('StartStopCamCollections');
        for (let i = 0; i < documents.length; i++) {
          const documentData = await getLiveDocument('StartStopCamCollections', documents[i]);

          if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
            if ((documentData.Camera1.UserID == navData.Camera1User.id) ||
              (documentData.Camera2.UserID == navData.Camera1User.id) ||
              (documentData.Camera1.UserID == navData.Camera2User.id) ||
              (documentData.Camera2.UserID == navData.Camera2User.id)) {
              let document_detail = { document_name: documents[i] };

              const myRec = document_detail.document_name;
              const docRef = db.collection('StartStopCamCollections').doc(myRec);

              docRef.delete()
                .then(() => {
                  const docKey = header_name;
                  const doc = {
                    Camera1: {
                      CameraStatus: false,
                      UserID: navData.Camera1User.id.toString()
                    },
                    Camera2: {
                      CameraStatus: false,
                      UserID: navData.Camera2User.id.toString()
                    }
                  }

                  if ((i + 1) == documents.length) {
                    startStopCamCollection.doc(docKey).set(doc).then(() => {
                      Swal.fire({
                        icon: 'success',
                        title: '🚀',
                        text: 'Your firebase database created successfully'
                      });
                    });
                    setDisableCreateDbButton(false);
                  }
                })
                .catch((error) => {
                });
            }
            else {
              const docKey = header_name;
              const doc = {
                Camera1: {
                  CameraStatus: false,
                  UserID: navData.Camera1User.id.toString()
                },
                Camera2: {
                  CameraStatus: false,
                  UserID: navData.Camera2User.id.toString()
                }
              }

              if ((i + 1) == documents.length) {
                startStopCamCollection.doc(docKey).set(doc).then(() => {
                  Swal.fire({
                    icon: 'success',
                    title: '🚀',
                    text: 'Your firebase database created successfully'
                  });
                  setDisableCreateDbButton(false);
                });
              }
            }
          }
        }
      })
  };

  const handleScoreUpdate = () => {
    const formDataJson = {
      analysis_master_id: navData.id,
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      runs: update_runs,
      wickets: update_wickets,
      current_inning: innings
    };

    if (!formDataJson.analysis_master_id) {
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    Object.keys(formDataJson).forEach((key) =>
      formData.append(key, formDataJson[key])
    );
    UpdateScoreAnalysis(formDataJson)
      .then((data) => {
        if (data.status) {
          setRuns(update_runs);
          setWickets(update_wickets);
          setShowScoreEditPanel(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const handleTimeChange = (event) => {
    setStartNewTime(event.target.value);
  };
  const setFormData = (field) => (e) =>
    setUpdateFormData({ ...updateFormData, [field]: parseInt(e.target.value) });
  const resetAnalysisData = () => {
    setSelectedVideoURLOption("");
    setParentWagonMsgData(
      {
        parent: true,
        parent_batting_msg: ''
      }
    );
    setPositionBatType({
      pitch_position_bat_type: "Right"
    })
    setPositionBatTypeBatInput({
      waghoon_wheel_position_bat_type: "Right"
    })
    setBallType({
      category: "",
      type: ""
    });
    setStartNewTime("");
    setDemoValue("");
    setSelectedSpinBowlingOption("front_foot");
    setIsAppeal("");
    setIsBatsmanUncomfortable("");
    setIsBeaten("");
    setIsWicketTaking("");
    setShowRunOutButtons(false);
    setRunOutRunResult("");
    setBallResult('0');
    setExtraBallResult('');
    setWicketType("");
  };

  const setAnalysisData = ({ data, blank }) => {
    let fileName;
    if ((data != null)) {
      if (data.video_url != "") {
        fileName = data.video_url.split("/").pop();
        setSelectedVideoURLOption(fileName);
      }
      else {
        setSelectedVideoURLOption("");
      }

      if (data.bowler_id != null) {
        setBowlerId(data.bowler_id);
      }
      else {
        setBowlerId("");
      }

      if (data.bat_striker_id != null) {
        setStrikerId(data.bat_striker_id);
      }
      else {
        setStrikerId("");
      }

      if (data.bat_nstriker_id != null) {
        setNonStrikerId(data.bat_nstriker_id);
      }
      else {
        setNonStrikerId("");
      }

      if (data.start_time != null) {
        setStartNewTime(data.start_time)
      } else {
        setStartNewTime("");
      }

      if (data.end_interval != null) {
        setDemoValue(data.end_interval)
      } else {
        setDemoValue("");
      }

      setParentMarkerData(
        {
          parent: true,
          parent_selectedMarkerOption: 'pitch',
          parent_position_pitch_x: data.position_pitch_x,
          parent_position_pitch_y: data.position_pitch_y,
          parent_position_stump_x: data.position_stump_x,
          parent_position_stump_y: data.position_stump_y,
          parent_bowling_length: data.bowling_length,
          parent_bowling_result: data.bowling_result
        }
      );

      setParentWagonMsgData(
        {
          parent: true,
          parent_batting_msg: data.batting_result
        }
      );

      setExtraBallResult(!blank ? data.extra_ball_delivery_result : "");
      setWicketType(!blank ? data.wicket_type : "");
      setBallResult(!blank ? data.ball_delivery_result : "");
      setBallType({
        category: !blank ? data.ball_type_category : "",
        type: !blank ? data.ball_type : "",
      });

      if (data.ball_type_category == "Spin Bowling") {
        setSelectedBowlingOption("spin_bowling");
      }
      else {
        setSelectedBowlingOption("fast_bowling");
      }

      if (data.bat_type_category == "Back Foot") {
        setSelectedSpinBowlingOption("back_foot");
      }
      else {
        setSelectedSpinBowlingOption("front_foot");
      }

      setBattingResult(!blank ? data.batting_result : "");
      setBowlingData({
        bowling_result: !blank ? data.bowling_result : "",
        bowling_length: !blank ? data.bowling_length : "",
      });
      setPositionBatType({
        pitch_position_bat_type: !blank ? data.pitch_position_bat_type : "",
      });
      setPositionBatTypeBatInput({
        waghoon_wheel_position_bat_type: !blank ? data.waghoon_wheel_position_bat_type : "",
      });
      setFile(null);
      setExtraBallResult(!blank ? data.extra_ball_delivery_result : "");
      setWicketType(!blank ? data.wicket_type : "");
      setBallResult(!blank ? data.ball_delivery_result : "");
      if (data.wicket_type == "runOut") {
        setShowRunOutButtons(true);
        setRunOutRunResult(!blank ? data.run_out_ball_delivery_result : "");
      } else {
        setShowRunOutButtons(false);
      }
      setIsAppeal(!blank ? data.is_appeal : "");
      setIsBatsmanUncomfortable(!blank ? data.is_batsman_uncomfortable : "");
      setIsBeaten(!blank ? data.is_beaten : "");
      setIsWicketTaking(!blank ? data.is_wicket_taking : "");
    } else {
      resetAnalysisData();
    }
  };

  const [nextButtonEnabled, setNextButtonEnabled] = useState(true);
  const [inning2_end_match_over, setinning2_end_match_over] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disableCreateDbButton, setDisableCreateDbButton] = useState(false);

  const handleSubmitInning = (val) => async (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const fn = handleNext(val, "confirm");
        fn();
      } else {
      }
    });
  }

  function handleCountDownStartTime() {
    const videoStreamTimer = localStorage.setItem('videoStreamTimer', "timerStart");
  }

  function handleCountDownEndTime() {
    const videoStreamTimer = localStorage.setItem('videoStreamTimer', "timerStop");
  }

  const handleResetNext = (e) => {
    setAutoSixDigitNumberEdit("");
    setAutoSixDigitNumber("");
    localStorage.setItem("WideNoBallRandomNumber", "");
    setIsEditHavingWicket(false);
    setStartNewTime("");
    setDemoValue("");
    setVideoSourcFile("");
    setSelectType("")
    setSend_analysis_id('')
    setisNonDeliveryBallClick(false);
    setNonDeliveryCurrentBall("");
    setParentMarkerData({
      parent: true,
      parent_selectedMarkerOption: 'pitch',
      parent_position_pitch_x: 0,
      parent_position_pitch_y: 0,
      parent_position_stump_x: 0,
      parent_position_stump_y: 0,
      parent_bowling_length: '',
      parent_bowling_result: ''
    });
    setParentWagonMsgData({
      parent: true,
      parent_batting_msg: ''
    });

    setSend_analysis_id("")
    if ((bowler_type == 'Off Spin') || (bowler_type == 'Leg Spin')) {
      setSelectedBallType("Off Spin");
    }
    else {
      setSelectedBallType("Straight");
    }
    setFoot('Front Foot');
    setBatType('Cover Drive');
    setBallResult("0");
    setBattingResult("");
    setBowlingData({
      bowling_result: "",
      bowling_length: "",
    });
    setBowlingPitchData({
      position_pitch_x: 0,
      position_pitch_y: 0
    });
    setBowlingStumpData({
      position_stump_x: 0,
      position_stump_y: 0
    });
    setExtraBallResult("");
    setIsAppeal("");
    setIsBatsmanUncomfortable("");
    setIsBeaten("");
    setIsWicketTaking("");
    setRunOutRunResult("");
    setCustomRuns(0)
    setWicketType("");
  }

  const handleReset = (e) => {
    setAutoSixDigitNumberEdit("");
    setAutoSixDigitNumber("");
    localStorage.setItem("WideNoBallRandomNumber", "");
    setIsEditHavingWicket(false);
    setStartNewTime("");
    setDemoValue("");
    setVideoSourcFile("");
    setSelectType("")
    setSend_analysis_id('')
    setisNonDeliveryBallClick(false);
    setNonDeliveryCurrentBall("");
    setParentMarkerData({
      parent: true,
      parent_selectedMarkerOption: 'pitch',
      parent_position_pitch_x: 0,
      parent_position_pitch_y: 0,
      parent_position_stump_x: 0,
      parent_position_stump_y: 0,
      parent_bowling_length: '',
      parent_bowling_result: ''
    });
    setParentWagonMsgData({
      parent: true,
      parent_batting_msg: ''
    });

    setSend_analysis_id("")
    if ((bowler_type == 'Off Spin') || (bowler_type == 'Leg Spin')) {
      setSelectedBallType("Off Spin");
    }
    else {
      setSelectedBallType("Straight");
    }
    setFoot('Front Foot');
    setBatType('Cover Drive');
    setBallResult("0");
    setBattingResult("");
    setBowlingData({
      bowling_result: "",
      bowling_length: "",
    });
    setBowlingPitchData({
      position_pitch_x: 0,
      position_pitch_y: 0
    });
    setBowlingStumpData({
      position_stump_x: 0,
      position_stump_y: 0
    });
    setExtraBallResult("");
    setIsAppeal("");
    setIsBatsmanUncomfortable("");
    setIsBeaten("");
    setIsWicketTaking("");
    setRunOutRunResult("");
    setCustomRuns(0)
    setWicketType("");

    getScoreOverAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id
    })
      .then(async (data) => {
        if (data.data.current_inning == 1) {
          setCurrentBall(data.data.inning1_current_ball);
        }
        else {
          setCurrentBall(data.data.inning2_current_ball);
        }
      });

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: innings,
      current_ball: current_ball,
      analysis_id: "",
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (fetchedData) => {
        setShowRuns(fetchedData.analysisMasterData.total_combine_run)
        setShowOvers(fetchedData.analysisMasterData.heading_current_over)
        setShowWickets(fetchedData.analysisMasterData.total_wickets)
        setTotalOvers(fetchedData.analysisMasterData.overs)
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const handleNext = (val, is_confirm) => async (e) => {

    if (bowlerId != '' && strikerId != '' && nonStrikerId != '') {
      if (disableNextButton) {
        return; // Exit the function if the button is disabled
      }

      setDisableNextButton(true);
      const CheckSubmitInningData = await CheckSubmitInning({
        analysis_master_id: navData.id,
        current_inning: innings
      });

      let lastBallDeliveryCount = 0;
      if ((innings == 2) && (((send_analysis_id == '') || (send_analysis_id == null)))) {
        const checkLastBallDeliverDataAPI = await checkLastBallDeliverData({
          analysis_master_id: navData.id,
          tournament_id: navData.tournament_id,
          match_id: navData.match_id,
          current_inning: innings,
          totalOvers: totalOvers
        });

        lastBallDeliveryCount = checkLastBallDeliverDataAPI.data.length;
      }


      if (lastBallDeliveryCount > 0) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Second Inning Ended";
        match_analysis_log["error_description"] = "Sorry, you cannot deliver the next ball as the match has already finished.";
        match_analysis_log["input_data"] = {
          lastBallDeliveryCount: lastBallDeliveryCount
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "Sorry, you cannot deliver the next ball as the match has already finished."
        );
        setDisableNextButton(false);
      }
      else if (isRecording == true) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Video Recording Started";
        match_analysis_log["error_description"] = "Please either stop the video recording manually or wait for 7 seconds for the automatic stop to save your information.";
        match_analysis_log["input_data"] = {
          isRecording: isRecording
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "Please either stop the video recording manually or wait for 7 seconds for the automatic stop to save your information."
        );
        setDisableNextButton(false);
      }
      else if ((end_match_inning != 0) && ((end_match_inning < parseFloat(nextBall(showOvers)))) && ((send_analysis_id == '') || (send_analysis_id == null))) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Next Ball Unavailable";
        match_analysis_log["error_description"] = "Match is submitted on " + end_match_inning + " Over and you can't submit next ball.";
        match_analysis_log["input_data"] = {
          end_match_inning: end_match_inning,
          showOvers: showOvers,
          send_analysis_id: send_analysis_id
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "Match is submitted on " + end_match_inning + " Over and you can't submit next ball."
        );
        setDisableNextButton(false);
      }
      else if ((val == 'submitInning') && (isNonDeliveryBallClick == true) && (parseFloat(CheckSubmitInningData.data) > parseFloat(NonDeliveryCurrentBall))) {

        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = NonDeliveryCurrentBall;
        match_analysis_log["type_of_error"] = "Innings Locked Until Submitted Over";
        match_analysis_log["error_description"] = "You have already filled data upto " + CheckSubmitInningData.data.toFixed(1) + " Over and you can't submit inning before " + CheckSubmitInningData.data.toFixed(1) + " Over";
        match_analysis_log["input_data"] = {
          val: 'submitInning',
          CheckSubmitInningData: CheckSubmitInningData.data,
          next_current_ball_submit: NonDeliveryCurrentBall,
          isNonDeliveryBallClick: isNonDeliveryBallClick
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "You have already filled data upto " + CheckSubmitInningData.data.toFixed(1) + " Over and you can't submit inning before " + CheckSubmitInningData.data.toFixed(1) + " Over"
        );
        setDisableNextButton(false);
      }
      else if ((val == 'submitInning') && (isNonDeliveryBallClick == false) && (parseFloat(CheckSubmitInningData.data) > parseFloat(showOvers))) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Innings Locked Until Submitted Over";
        match_analysis_log["error_description"] = "You have already filled data upto " + CheckSubmitInningData.data.toFixed(1) + " Over and you can't submit inning before " + CheckSubmitInningData.data.toFixed(1) + " Over";
        match_analysis_log["input_data"] = {
          val: 'submitInning',
          CheckSubmitInningData: CheckSubmitInningData.data,
          next_current_ball_submit: current_ball,
          isNonDeliveryBallClick: isNonDeliveryBallClick
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

        Swal.fire(
          "Info",
          "You have already filled data upto " + CheckSubmitInningData.data.toFixed(1) + " Over and you can't submit inning before " + CheckSubmitInningData.data.toFixed(1) + " Over"
        );
        setDisableNextButton(false);
      }
      // else if ((bowlingPitchData.position_pitch_x == 0) || (bowlingPitchData.position_pitch_y == 0) || (bowlingStumpData.position_stump_x == 0) || (bowlingStumpData.position_stump_y == 0)) {
      else if ((bowlingStumpData.position_stump_x == 0) || (bowlingStumpData.position_stump_y == 0)) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Pitch Map Detail";
        match_analysis_log["error_description"] = "Please fill Pitch Map detail";
        match_analysis_log["input_data"] = {
          position_pitch_x: 0,
          position_pitch_y: 0,
          position_stump_x: 0,
          position_stump_y: 0
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
        Swal.fire(
          "Info",
          "Please fill Pitch Map detail"
        );
        setDisableNextButton(false);
      }
      else if ((((battingResult == '') && (ballResult != 0) && (ballResult != "W") && (wicketType != "runOut"))) || (((wicketType == "runOut") && ((runOutRunResult != '') && (runOutRunResult != 0)) && (battingResult == '')))) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Wagon Wheel Detail";
        match_analysis_log["error_description"] = "Please fill Wagon Wheel detail";
        match_analysis_log["input_data"] = {
          battingResult: battingResult,
          ballResult: ballResult,
          wicketType: wicketType,
          runOutRunResult: runOutRunResult
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
        Swal.fire(
          "Info",
          "Please fill Wagon Wheel detail"
        );
        setDisableNextButton(false);
      }
      else if ((ballResult == 'W') && ((wicketType == "") || (wicketType == null))) {
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Wicket Type Unavailable";
        match_analysis_log["error_description"] = "Please select wicket type";
        match_analysis_log["input_data"] = {
          ballResult: ballResult,
          wicketType: wicketType
        };

        const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
        Swal.fire(
          "Info",
          "Please select wicket type"
        );
        setDisableNextButton(false);
      }
      else {
        //setIsNextBallClick(true);
        localStorage.setItem("isNextBallClick", true);
        let next_current_ball_submit = nextBall(current_ball);
        let local_recording_ball_detail;
        if (innings == 1) {
          local_recording_ball_detail = localStorage.getItem("recording_ball_detail_first");
        }
        else {
          local_recording_ball_detail = localStorage.getItem("recording_ball_detail_second");
        }

        if (local_recording_ball_detail != null) {
          let parse_local_recording_ball_detail = JSON.parse(local_recording_ball_detail);

          if (send_analysis_id == "") {
            let prev_ball_detail = parseFloat(nextBall(showOvers).toFixed(1));

            if ((autoSixDigitNumber != "") && (isNonDeliveryBallClick == false)) {
              parse_local_recording_ball_detail[nextBall(showOvers).toFixed(1) + "_" + autoSixDigitNumber] = nextBall(showOvers).toFixed(1) + "_" + autoSixDigitNumber;
            }
            else if ((sixDigitNumber != "") && (isNonDeliveryBallClick == false)) {
              parse_local_recording_ball_detail[nextBall(showOvers).toFixed(1) + "_" + sixDigitNumber] = nextBall(showOvers).toFixed(1) + "_" + sixDigitNumber;
            }
            else {
              if (isNonDeliveryBallClick == true) {
                parse_local_recording_ball_detail[parseFloat(prevBall(prev_ball_detail)).toFixed(1)] = parseFloat(prevBall(prev_ball_detail)).toFixed(1);
              }
              else {
                parse_local_recording_ball_detail[nextBall(showOvers).toFixed(1)] = nextBall(showOvers).toFixed(1);
              }

            }
          }
          else {
            let prev_ball_detail = parseFloat(nextBall(showOvers).toFixed(1));
            if (autoSixDigitNumber != "") {
              parse_local_recording_ball_detail[parseFloat(prevBall(prev_ball_detail)).toFixed(1) + "_" + autoSixDigitNumber] = parseFloat(prevBall(prev_ball_detail)).toFixed(1) + "_" + autoSixDigitNumber;
            }
            else if (sixDigitNumber != "") {
              parse_local_recording_ball_detail[parseFloat(prevBall(prev_ball_detail)).toFixed(1) + "_" + sixDigitNumber] = parseFloat(prevBall(prev_ball_detail)).toFixed(1) + "_" + sixDigitNumber;
            }
            else {
              parse_local_recording_ball_detail[parseFloat(prevBall(prev_ball_detail)).toFixed(1)] = parseFloat(prevBall(prev_ball_detail)).toFixed(1);
            }
          }

          const timeoutIdVideo = setTimeout(() => {
            if (innings == 1) {
              localStorage.setItem("recording_ball_detail_first", JSON.stringify(parse_local_recording_ball_detail));
            }
            else {
              localStorage.setItem("recording_ball_detail_second", JSON.stringify(parse_local_recording_ball_detail));
            }
          }, 3000);


        }

        // if (is_confirm == "confirm") {
        //   setIsUseEffectCall(true);
        // }
        setIsLoading(true);
        setDisableNextButton(true);
        let video_url = '';

        if (selectedVideoURLOption != "") {
          video_url = "https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/well_played_tournament/" + selectedVideoURLOption;
        }
        let batting_type = "Front Foot";
        if (selectedSpinBowlingOption == "back_foot") {
          batting_type = "Back Foot";
        }

        let new_over_ball = current_ball;
        if ((send_analysis_id == "") && ((extraBallResult != 'WD') || (extraBallResult != 'NB'))) {
          new_over_ball = nextBall(showOvers);
        }

        if (isNonDeliveryBallClick == true) {
          new_over_ball = NonDeliveryCurrentBall;
        }

        let BatsmanRunout = null;
        if (wicketType == 'runOut') {
          BatsmanRunout = runout_batsman_id;
        }
        else {
          BatsmanRunout = null;
        }

        let combineSixDigitNumber = "";

        if (autoSixDigitNumberEdit != "") {
          combineSixDigitNumber = autoSixDigitNumberEdit;
        }
        else if (autoSixDigitNumber != "") {
          combineSixDigitNumber = autoSixDigitNumber;
        } else {
          combineSixDigitNumber = sixDigitNumber;
        }

        const formDataJson = {
          analysis_id: send_analysis_id,
          analysis_master_id: navData.id,
          tournament_id: navData.tournament_id,
          match_id: navData.match_id,
          bat1_id: navData.bat1_id,
          ball1_id: navData.ball1_id,
          bat2_id: navData.bat2_id,
          ball2_id: navData.ball2_id,
          current_inning: innings,
          overs: navData.overs,
          current_ball: new_over_ball,
          ball_type_category: bowler_type,
          ball_type: selectedBallType,
          bowler_id: bowlerId === defaults.BOWLER ? null : bowlerId,
          bat_type_category: foot,
          bat_type: batType,
          bat_nstriker_id: nonStrikerId === defaults.NON_STRIKER ? null : nonStrikerId,
          bat_striker_id: strikerId === defaults.STRIKER ? null : strikerId,
          ball_delivery_result: ballResult,
          batting_result: battingResult,
          bowling_result: localStorage.getItem('setStumpMsg'),
          bowling_length: localStorage.getItem('setPitchMsg'),
          files: file,
          position_pitch_x: bowlingPitchData.position_pitch_x,
          position_pitch_y: bowlingPitchData.position_pitch_y,
          position_stump_x: bowlingStumpData.position_stump_x,
          position_stump_y: bowlingStumpData.position_stump_y,
          radio_video_url: video_url,
          extra_ball_delivery_result: extraBallResult,
          wicket_type: wicketType,
          run_out_ball_delivery_result: runOutRunResult,
          is_appeal: IsAppeal,
          is_beaten: IsBeaten,
          is_wicket_taking: IsWicketTaking,
          is_batsman_uncomfortable: IsBatsmanUncomfortable,
          isNext: val,
          pitch_position_bat_type: positionBatType.pitch_position_bat_type,
          waghoon_wheel_position_bat_type: positionBatTypeBatInput.waghoon_wheel_position_bat_type,
          start_time: startNewTime,
          end_interval: demoValue,
          cam_live_type: camType,
          video_url: videoSourcFile,
          runout_batsman_id: BatsmanRunout,
          sixDigitNumber: combineSixDigitNumber,
          tournamentName: tournamentName,
          matchName: matchName,
          inn1BatTeamName: inn1BatTeamName,
          inn2BatTeamName: inn2BatTeamName,
          matchDate: matchDate
        };

        if (!formDataJson.analysis_master_id) {
          let offline_internet_logs = [];

          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Internet Server Down";
          match_analysis_log["error_description"] = "The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.";
          match_analysis_log["input_data"] = formDataJson;

          if (localStorage.getItem("offline_internet_logs") != null) {
            offline_internet_logs = JSON.parse(localStorage.getItem("offline_internet_logs"));
          }
          offline_internet_logs.push(match_analysis_log);
          localStorage.setItem("offline_internet_logs", JSON.stringify(offline_internet_logs));

          Swal.fire("The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.");
          setIsLoading(false);
          setDisableNextButton(false);
          return;
        }

        const formData = new FormData();
        Object.keys(formDataJson).forEach((key) =>
          formData.append(key, formDataJson[key])
        );

        AddAnalysis(formDataJson)
          .then(async (data) => {
            if (data?.status) {

              let collect_local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
              let filter_new_intervals = [];
              for (let interval_index = 0; interval_index < collect_local_intervals.length; interval_index++) {
                clearInterval(collect_local_intervals[interval_index]["interval_id"]);
              }
              localStorage.setItem("allActiveIntervalIds", JSON.stringify(filter_new_intervals));

              setAutoSixDigitNumberEdit("");
              setAutoSixDigitNumber("");
              localStorage.setItem("WideNoBallRandomNumber", "");


              let last_ball = "";
              const { data: addAnalysis } = data;
              setCurrentBall(addAnalysis.current_ball);
              setInnings(addAnalysis.current_inning);
              setIsCustomRuns(false)
              setSelectType("")
              setVideoSourcFile("")
              setisNonDeliveryBallClick(false);
              setNonDeliveryCurrentBall("");

              if (val == 'submitInning') {
                setTotalStrikerRun(0)
                setTotalStrikerBall(0)
                setTotalStrikerFourRun(0)
                setTotalStrikerSixRun(0)
                setStrikerStrikeRate(0)
                setTotalnStrikerRun(0)
                setTotalnStrikerBall(0)
                setTotalnStrikerFourRun(0)
                setTotalnStrikerSixRun(0)
                setnStrikerStrikeRate(0)
                setTotalBowlerOver(0)
                setTotalBowlerMaiden(0)
                setTotalBowlerBallWd(0)
                setEconomyBowler(0)
                setTotalBowlerExtraRuns(0)
                setShowOvers(0);
              }
              else {
                if ((ballResult == 'W') || (isEditHavingWicket == true)) {
                  const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
                    data.data.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
                  );

                  const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
                    data.data.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
                  );

                  let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
                    let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
                    if (matchingItem) {
                      result.push({ ...item, ...matchingItem });
                    } else {
                      result.push(item);
                    }
                    return result;
                  }, []);

                  let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
                    let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
                    if (matchingItem) {
                      result.push({ ...item, ...matchingItem });
                    } else {
                      result.push(item);
                    }
                    return result;
                  }, []);

                  if (battingTeamData?.status)
                    setBattingTeamDataDetails(mergedBatArray);

                  if (bowlingTeamData?.status)
                    setBowlingTeamDataDetails(mergedBallArray);
                }
              }

              if (last_ball == "") {
                last_ball = parseFloat(addAnalysis.current_ball);
              }
              setIsEditHavingWicket(false);
              handleResetNext();
              getBallAnalysis({
                tournament_id: navData.tournament_id,
                match_id: navData.match_id,
                current_inning: addAnalysis.current_inning,
                current_ball: last_ball,
                analysis_id: "",
                tournament_name: tournamentName,
                match_name: matchName,
                match_date: matchDate,
                team_bat1: inn1BatTeamName,
                team_bat2: inn2BatTeamName
              })
                .then(async (fetchedData) => {
                  setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs)
                  setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball)
                  setShowRuns(fetchedData.analysisMasterData.total_combine_run)
                  setShowOvers(fetchedData.analysisMasterData.heading_current_over)
                  setShowWickets(fetchedData.analysisMasterData.total_wickets)
                  setTotalOvers(fetchedData.analysisMasterData.overs)

                  if ((fetchedData.analysisMasterData.inning1_end_match_over != 0) && (fetchedData.analysisMasterData.inning2_end_match_over != 0)) {
                    if ((fetchedData.collect_score_need_sec_inning.total_combine_run_first >= fetchedData.collect_score_need_sec_inning.total_combine_run_second)) {
                      setIsFirstInningWon(true);
                    }
                    else {
                      setIsFirstInningWon(false);
                    }
                  }
                  let header_name;
                  if (fetchedData.analysisMasterData.current_inning == 1) {
                    header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat1 + '_' + fetchedData.dynamic_team_name.match_date;
                    setHeadingCommonName(header_name);
                    setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + fetchedData.team_name.team_bat1.name + ') (Bowling -- ' + fetchedData.team_name.team_ball1.name + ')');

                    if (fetchedData.analysisMasterData.inning1_end_match_over != 0) {
                      setNextSubmitInning(true);
                      setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
                      setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
                    }
                    else {
                      setNextSubmitInning(false);
                      setEnd_match_inning("");
                      setEnd_match_inning1("");
                    }
                  }
                  else {
                    header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat2 + '_' + fetchedData.dynamic_team_name.match_date;
                    setHeadingCommonName(header_name);
                    setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + fetchedData.team_name.team_bat2.name + ') (Bowling -- ' + fetchedData.team_name.team_ball2.name + ')');
                    if (fetchedData.analysisMasterData.inning2_end_match_over != 0) {
                      setNextSubmitInning(true);
                      setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over)
                      setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over)
                    }
                    else {
                      setNextSubmitInning(false);
                      setEnd_match_inning("");
                      setEnd_match_inning2("");
                    }
                  }
                  if (header_name != "") {
                    //fetchVideosListFirst(header_name);
                  }

                  if ((innings == 1) && (fetchedData.analysisMasterData.current_inning == 2)) {

                    const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
                      addAnalysis.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
                    );

                    const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
                      addAnalysis.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
                    );

                    let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
                      let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
                      if (matchingItem) {
                        result.push({ ...item, ...matchingItem });
                      } else {
                        result.push(item);
                      }
                      return result;
                    }, []);

                    let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
                      let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
                      if (matchingItem) {
                        result.push({ ...item, ...matchingItem });
                      } else {
                        result.push(item);
                      }
                      return result;
                    }, []);

                    if (battingTeamData?.status)
                      setBattingTeamDataDetails(mergedBatArray);

                    if (bowlingTeamData?.status)
                      setBowlingTeamDataDetails(mergedBallArray);

                    if (fetchedData?.exists) {
                      setAnalysisData({ data: fetchedData.data, blank: false });
                    } else {
                      resetAnalysisData();
                    };

                    setcamHeader(header_name);
                    localStorage.setItem('header_name', header_name);
                    const myRec = camHeader;
                    const docRef = db.collection('StartStopCamCollections').doc(myRec);
                    const LivedocRef = db.collection('StartStopLiveCollections').doc(myRec);

                    docRef.delete()
                      .then(() => {
                        LivedocRef.delete()
                        const docKey = header_name;

                        const doc = {
                          Camera1: {
                            CameraStatus: false,
                            UserID: navData.Camera1User.id.toString()
                          },
                          Camera2: {
                            CameraStatus: false,
                            UserID: navData.Camera2User.id.toString()
                          }
                        }
                        startStopCamCollection.doc(docKey).set(doc).then(() => {
                        })
                      })
                      .catch((error) => {
                      });

                    LivedocRef.delete()
                      .then(() => {
                        const docKey = header_name;

                        const doc = {
                          Camera1: {
                            CameraStatus: false,
                            UserID: navData.Camera1User.id.toString()
                          },
                          Camera2: {
                            CameraStatus: false,
                            UserID: navData.Camera2User.id.toString()
                          }
                        }
                        startStopLiveCollection.doc(docKey).set(doc).then(() => {
                        });
                      })

                    setBattingTeamName(fetchedData.team_name.team_ball1.name)
                    setTotalStrikerRun(0);
                    setTotalStrikerBall(0);
                    setTotalStrikerFourRun(0);
                    setTotalStrikerSixRun(0);
                    setStrikerStrikeRate(0);
                    setTotalnStrikerRun(0);
                    setTotalnStrikerBall(0);
                    setTotalnStrikerFourRun(0);
                    setTotalnStrikerSixRun(0);
                    setnStrikerStrikeRate(0);
                    setTotalBowlerOver(0);
                    setTotalBowlerMaiden(0);
                    setTotalBowlerBallWd(0);
                    setEconomyBowler(0);
                    setTotalBowlerExtraRuns(0);
                    setShowOvers(0);

                    setShowOversAnalysis(false);

                    GetLatestThreeBallDetail({
                      match_id: navData.match_id,
                      current_inning: fetchedData.analysisMasterData.current_inning
                    }).then(async (data) => {
                      setlatest_three_ball_details(data.latest_three_ball_details)
                    })
                      .catch((e) => {
                      });

                    getLatestEighteenBallDetail({
                      match_id: navData.match_id,
                      current_inning: fetchedData.analysisMasterData.current_inning
                    }).then(async (data) => {
                      let arr_reverse = data.latest_eighteen_ball_details;
                      let reversedArray = arr_reverse.slice().reverse();
                      setlatestEighteenBall(reversedArray)
                      setEighteenBallLoader(false)
                    })
                      .catch((e) => {
                      });

                    Swal.fire(
                      "Inning Changed",
                      "Match first inning finished and second inning is started"
                    );

                    localStorage.setItem("isFirstInningChange", true);
                    const timeoutId = setTimeout(() => {
                      localStorage.setItem("isFirstInningChange", false);
                    }, 30000);
                  }
                  else if ((innings == 2) && (val == 'submitInning')) {
                    Swal.fire(
                      "Match Over",
                      "Match is over now"
                    );
                    setNextSubmitInning(true);
                    setShowOversAnalysis(false);
                  }
                  else {
                    if ((innings == 2) && (fetchedData.analysisMasterData.inning2_end_match_over != 0)) {

                      if (current_ball == fetchedData.analysisMasterData.inning2_end_match_over) {
                        Swal.fire(
                          "Match Over",
                          "Match is over now"
                        );
                        setCurrentBall(current_ball);
                      }
                    }
                    else {
                      let new_over = navData.overs + '.1';
                      if (new_over == addAnalysis.current_ball) {
                        Swal.fire(
                          "Match Over",
                          "Match is over now"
                        );
                        setCurrentBall(navData.overs);
                      }
                    }
                  }

                  setIsLoading(false);
                  setDisableNextButton(false);
                  if (fetchedData?.exists) {
                    setAnalysisData({ data: fetchedData.data, blank: false });
                  } else {
                    resetAnalysisData();
                  };
                })
                .catch((e) => {
                  setIsLoading(false);
                  setDisableNextButton(false);
                  setAnalysisData({ data: null, blank: true });
                });

              if (val == 'submitInning') {
                setTotalStrikerRun(0)
                setTotalStrikerBall(0)
                setTotalStrikerFourRun(0)
                setTotalStrikerSixRun(0)
                setStrikerStrikeRate(0)
                setTotalnStrikerRun(0)
                setTotalnStrikerBall(0)
                setTotalnStrikerFourRun(0)
                setTotalnStrikerSixRun(0)
                setnStrikerStrikeRate(0)
                setTotalBowlerOver(0)
                setTotalBowlerMaiden(0)
                setTotalBowlerBallWd(0)
                setEconomyBowler(0)
                setTotalBowlerExtraRuns(0)
              }
              else {
                GetSingleBowlerStatistics({
                  match_id: navData.match_id,
                  current_inning: innings,
                  bowler_id: bowlerId
                }).then(async (data) => {
                  setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
                  setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
                  setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
                  setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
                  setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
                  setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
                })
                  .catch((e) => {
                  });

                let nonStrikerId_switch = nonStrikerId;
                let strikerId_switch = strikerId;
                let nonStrikerName_switchname = nstrikerName;
                let strikerName_switchname = strikerName;
                if ((ballResult == '1') || (ballResult == '3')) {
                  setStrikerId(nonStrikerId_switch);
                  setNonStrikerId(strikerId_switch);
                  setStrikerName(nonStrikerName_switchname)
                  setnStrikerName(strikerName_switchname)
                  nonStrikerId_switch = strikerId;
                  strikerId_switch = nonStrikerId;
                }

                if (ballResult == 'W') {
                  setStrikerId('')
                  setTotalStrikerRun(0)
                  setTotalStrikerBall(0)
                  setTotalStrikerFourRun(0)
                  setTotalStrikerSixRun(0)
                  setStrikerStrikeRate(0)
                  setNonStrikerId('')
                  setTotalnStrikerRun(0)
                  setTotalnStrikerBall(0)
                  setTotalnStrikerFourRun(0)
                  setTotalnStrikerSixRun(0)
                  setnStrikerStrikeRate(0)
                }
                else {
                  getStrikerNonStrikerStatistics({
                    match_id: navData.match_id,
                    current_inning: innings,
                    bat_striker_id: strikerId_switch,
                    bat_nstriker_id: nonStrikerId_switch
                  }).then(async (data) => {
                    if ((data.bat_striker_info.batsman_detail.batting == 'NA') || (data.bat_striker_info.batsman_detail.batting == '') || (data.bat_striker_info.batsman_detail.batting == null) || (data.bat_striker_info.batsman_detail.batting == 'Select Batting Type')) {
                      setBatsmanType("Right Hand")
                      setTotalStrikerRun(0)
                      setTotalStrikerBall(0)
                      setTotalStrikerFourRun(0)
                      setTotalStrikerSixRun(0)
                      setStrikerStrikeRate(0)
                    }
                    else {
                      setBatsmanType(data.bat_striker_info.batsman_detail.batting)
                      setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
                      setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
                      setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
                      setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
                      setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
                    }

                    if ((data.bat_striker_info.batsman_detail.batting == 'NA') || (data.bat_striker_info.batsman_detail.batting == '') || (data.bat_striker_info.batsman_detail.batting == null) || (data.bat_striker_info.batsman_detail.batting == 'Select Batting Type')) {
                      setBatsmanType("Right Hand")
                      setTotalnStrikerRun(0)
                      setTotalnStrikerBall(0)
                      setTotalnStrikerFourRun(0)
                      setTotalnStrikerSixRun(0)
                      setnStrikerStrikeRate(0)
                    }
                    else {
                      setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run + data.bat_nstriker_info.total_batsman_no_ball_runs + data.bat_nstriker_info.total_batsman_run_out_runs)
                      setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
                      setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
                      setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
                      setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
                    }
                  })
                    .catch((e) => {
                    });
                }

                GetLatestThreeBallDetail({
                  match_id: navData.match_id,
                  current_inning: innings
                }).then(async (data) => {
                  setlatest_three_ball_details(data.latest_three_ball_details)
                })
                  .catch((e) => {
                  });

                getLatestEighteenBallDetail({
                  match_id: navData.match_id,
                  current_inning: innings
                }).then(async (data) => {
                  let arr_reverse = data.latest_eighteen_ball_details;
                  let reversedArray = arr_reverse.slice().reverse();
                  setlatestEighteenBall(reversedArray)
                })
                  .catch((e) => {
                  });
              }


              if (addAnalysis.completed) handleCompletedAnalysis();
            } else {
              let offline_internet_logs = [];

              let match_analysis_log = {};
              match_analysis_log["analysis_master_id"] = navData.id;
              match_analysis_log["tournament_id"] = navData.tournament_id;
              match_analysis_log["match_id"] = navData.match_id;
              match_analysis_log["current_inning"] = innings;
              match_analysis_log["overs"] = totalOvers;
              match_analysis_log["current_ball"] = current_ball;
              match_analysis_log["type_of_error"] = "Internet Server Down";
              match_analysis_log["error_description"] = "The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.";
              match_analysis_log["input_data"] = formDataJson;

              if (localStorage.getItem("offline_internet_logs") != null) {
                offline_internet_logs = JSON.parse(localStorage.getItem("offline_internet_logs"));
              }
              offline_internet_logs.push(match_analysis_log);
              localStorage.setItem("offline_internet_logs", JSON.stringify(offline_internet_logs));


              Swal.fire("The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.");
              setIsLoading(false);
              setDisableNextButton(false);
            }
          })
          .catch((e) => {
          });
      }
    }
    else {
      let match_analysis_log = {};
      match_analysis_log["analysis_master_id"] = navData.id;
      match_analysis_log["tournament_id"] = navData.tournament_id;
      match_analysis_log["match_id"] = navData.match_id;
      match_analysis_log["current_inning"] = innings;
      match_analysis_log["overs"] = totalOvers;
      match_analysis_log["current_ball"] = current_ball;
      match_analysis_log["type_of_error"] = "Player Detail Unavailable";
      match_analysis_log["error_description"] = "Bowler, striker and non-striker are compulsory.";
      match_analysis_log["input_data"] = {
        bowlerId: bowlerId,
        strikerId: strikerId,
        nonStrikerId: nonStrikerId
      };

      const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

      Swal.fire({
        icon: 'warning',
        title: '',
        text: 'Bowler, striker and non-striker are compulsory.',
        confirmButtonText: 'OK',
      });
    }
  };

  function handleInputChange(event) {
    setDemoValue(event.target.value);
  }

  function prevBall(currentBall) {
    currentBall = currentBall.toFixed(1);
    let return_data;
    const currentBallString = String(currentBall); // Ensure currentBall is a string
    const [over, ball] = currentBall.split('.').map(Number);
    if (ball === 0) {
      if (over === 0) {
        return_data = currentBall; // Handle the case when it's already the first ball of the first over.
      }
      return_data = `${over - 1}.5`;
    } else {
      return_data = `${over}.${ball - 1}`;
    }
    return return_data;
  }

  function prevBall_old(update_ball) {
    let previous_ball;
    let previous_over;
    let combine_ball_over;
    let new_ball = calculatePrevBall(parseFloat(update_ball).toFixed(1));

    if (isNaN(new_ball[1])) {
      new_ball[1] = 5;
    }

    if ((new_ball[0] == 0) && (new_ball[1] == 0)) {
      combine_ball_over = "0.0";
    }
    else {
      if (new_ball[1] != 0) {
        previous_ball = parseInt(new_ball[1]);
        previous_over = new_ball[0];
      }
      else {
        previous_ball = parseInt(new_ball.join(''));
        previous_over = new_ball[0] - 1;
      }
      combine_ball_over = previous_over + "." + previous_ball;
    }
    return combine_ball_over;
  }

  const handlePrev = () => async (e) => {
    let previous_ball;
    let previous_over;
    let new_ball = calculatePrevBall(parseFloat(current_ball).toFixed(1));
    if (isNaN(new_ball[1])) {
      new_ball[1] = 5;
    }

    if ((new_ball[0] == 0) && (new_ball[1] == 0)) {
      Swal.fire(
        "Info",
        "This is first ball of inning, So you can't go to previous ball"
      );
    }
    else {
      if (new_ball[1] != 0) {
        previous_ball = parseInt(new_ball[1]);
        previous_over = new_ball[0];
      }
      else {
        previous_ball = parseInt(new_ball.join(''));
        previous_over = new_ball[0] - 1;
      }

      let previous_inning = innings;
      const fn = handleBallSwitch('previous', previous_over, previous_inning, previous_ball);
      fn();
    }
  }

  const calculatePrevBall = (over) => {
    const [integerPart, fractionalPart] = over.toString().split('.');
    const prevBall = parseInt(fractionalPart, 10) - 1;
    const prevOver = prevBall < 0 ? parseInt(integerPart, 10) - 1 : parseInt(integerPart, 10);
    const prevBallAdjusted = prevBall < 0 ? 5 : prevBall;
    return [prevOver, prevBallAdjusted];
  }

  const handleBallSwitch = (switch_type, switch_over, switch_inning, switch_ball) => (e) => {
    let {
      over: updatedOver,
      inning: updatedInning,
      ball: updatedBall,
    } = updateFormData;

    if (switch_type == 'previous') {
      updatedOver = switch_over;
      updatedInning = switch_inning;
      updatedBall = switch_ball;
      let {
        over: newOver,
        inning: newInning,
        ball: newBall,
      } = {
        over: updatedOver,
        inning: updatedInning,
        ball: updatedBall,
      };
    }
    setIsLoading(true);
    setNextButtonEnabled(true);
    setShowEditPanel(false);
    const myRec = camHeader;
    const docRef = db.collection('StartStopCamCollections').doc(myRec);
    const LivedocRef = db.collection('StartStopLiveCollections').doc(myRec);
    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: updatedInning,
      current_ball: updatedOver + updatedBall / 10,
      analysis_id: '',
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (data) => {
        let header_name;
        if (updatedInning == 1) {
          header_name = data.dynamic_team_name.tournament_name + '_' + data.dynamic_team_name.match_name + '_' + data.dynamic_team_name.team_bat1 + '_' + data.dynamic_team_name.match_date;
        }
        else {
          header_name = data.dynamic_team_name.tournament_name + '_' + data.dynamic_team_name.match_name + '_' + data.dynamic_team_name.team_bat2 + '_' + data.dynamic_team_name.match_date;
        }

        docRef.delete()
          .then(() => {
            const docKey = header_name;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: navData.Camera1User.id.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: navData.Camera2User.id.toString()
              }
            }
            startStopCamCollection.doc(docKey).set(doc).then(() => {
              startStopLiveCollection.doc(docKey).set(doc).then(() => {
              });
            }).catch(err => {
            });
          })
          .catch((error) => {
          });

        LivedocRef.delete()
          .then(() => {

          })
          .catch((error) => {
          });

        setcamHeader(header_name);
        localStorage.setItem('header_name', header_name);
        if (updatedInning == 1) {
          setHeadingCommonName(header_name);
          setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + data.team_name.team_bat1.name + ') (Bowling -- ' + data.team_name.team_ball1.name + ')');
        } else {
          setHeadingCommonName(header_name);
          setHeaderTitle("Match Analysis -- " + header_name + ' (Batting -- ' + data.team_name.team_bat2.name + ') (Bowling -- ' + data.team_name.team_ball2.name + ')');
        }

        if (header_name != "") {

          //fetchVideosListFirst(header_name);
        }

        let isGreater = 0;
        if (updatedInning == 1) {
          if (data.analysisMasterData.inning1_end_match_over != 0) {
            let new_current_ball_selected = updatedOver + updatedBall / 10;
            if (parseFloat(new_current_ball_selected) > parseFloat(data.analysisMasterData.inning1_end_match_over)) {
              Swal.fire(
                "Info",
                `Inning is end in ${data.analysisMasterData.inning1_end_match_over} over and You can't select greater ball than inning end ball`
              );

              isGreater = 1;
            }
          }
        }
        else {
          if (data.analysisMasterData.inning2_end_match_over != 0) {
            let new_current_ball_selected = updatedOver + updatedBall / 10;
            if (parseFloat(new_current_ball_selected) > parseFloat(data.analysisMasterData.inning2_end_match_over)) {
              Swal.fire(
                "Info",
                `Inning is end in ${data.analysisMasterData.inning2_end_match_over} over and You can't select greater ball than inning end ball`
              );

              isGreater = 1;
            }
          }
        }

        if (isGreater == 1) {
          setIsLoading(false);
          setDisableNextButton(false);
        } else {
          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            updatedInning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );

          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            updatedInning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );

          if (battingTeamData?.status)
            setBattingTeam(battingTeamData?.team_members);

          if (bowlingTeamData?.status)
            setBowlingTeam(bowlingTeamData?.team_members);


          if (updatedInning == 1) {
            setRuns(data.analysisMasterData.inning1_run);
            setWickets(data.analysisMasterData.inning1_wicket);

            if (data.analysisMasterData.inning1_end_match_over != 0) {
              setNextSubmitInning(true);
              setEnd_match_inning(data.analysisMasterData.inning1_end_match_over);
              setEnd_match_inning1(data.analysisMasterData.inning1_end_match_over);

            }
            else {
              setNextSubmitInning(false);
              setEnd_match_inning("");
              setEnd_match_inning1("");
            }
          } else {
            setRuns(data.analysisMasterData.inning2_run);
            setWickets(data.analysisMasterData.inning2_wicket);

            if (data.analysisMasterData.inning2_end_match_over != 0) {
              setNextSubmitInning(true);
              setEnd_match_inning(data.analysisMasterData.inning2_end_match_over);
              setEnd_match_inning2(data.analysisMasterData.inning2_end_match_over);
            }
            else {
              setNextSubmitInning(false);
              setEnd_match_inning("");
              setEnd_match_inning("");
            }
          }

          setIsLoading(false);
          setDisableNextButton(false);
          setInnings(updatedInning);
          setCurrentBall(parseFloat(updatedOver + updatedBall / 10).toFixed(1));

          if (data?.exists) setAnalysisData({ data: data.data, blank: false });
          if (!data?.exists) resetAnalysisData();

          Swal.fire(
            "Switched To",
            `${updatedInning === 1 ? "First Inning" : "Second Inning"}'s
          ${ordinal_suffix_of(updatedOver + 1)} Over's
          ${ordinal_suffix_of(updatedBall === 10 ? 6 : updatedBall)} Ball`
          );
        }
      })
      .catch((e) => {
        let offline_internet_logs = [];
        let match_analysis_log = {};
        match_analysis_log["analysis_master_id"] = navData.id;
        match_analysis_log["tournament_id"] = navData.tournament_id;
        match_analysis_log["match_id"] = navData.match_id;
        match_analysis_log["current_inning"] = innings;
        match_analysis_log["overs"] = totalOvers;
        match_analysis_log["current_ball"] = current_ball;
        match_analysis_log["type_of_error"] = "Internet Server Down";
        match_analysis_log["error_description"] = "The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.";
        match_analysis_log["input_data"] = {};

        if (localStorage.getItem("offline_internet_logs") != null) {
          offline_internet_logs = JSON.parse(localStorage.getItem("offline_internet_logs"));
        }
        offline_internet_logs.push(match_analysis_log);
        localStorage.setItem("offline_internet_logs", JSON.stringify(offline_internet_logs));

        Swal.fire("The server has crashed, so I kindly request you to wait for 2 minutes and try to submit again, otherwise refresh your page.");
        setIsLoading(false);
        setDisableNextButton(false);
      });
    changeMasterInningSwitch({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: updatedInning,
      current_ball: updatedOver + updatedBall / 10,
    }).then(async (data) => {
    }).catch((e) => {
    });
  };

  const fetchTypes = async () => {
    try {
      const data = await GetAllBatBallTypes();
      if (data?.status) {
        const FastBowling_midpoint = Math.ceil(data?.types?.fast.length / 2);
        const SpinBowling_midpoint = Math.ceil(data?.types?.spin.length / 2);

        setBackFootTypes(data?.types?.back);
        setFrontFootTypes(data?.types?.front);

        let frontFootTypeData = data?.types?.front;
        let backFootTypeData = data?.types?.back;

        let mergedArray = frontFootTypeData.concat(backFootTypeData);
        setbattingTypes(mergedArray)
        setFastBowlingTypesFirst(data?.types?.fast);
        setSpinBowlingTypesFirst(data?.types?.spin);
        setfrontBackFootSkillLoader(false);
      }
    } catch (e) {
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
        navData.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
      );

      const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
        navData.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
      );

      if (battingTeamData?.status)
        setBattingTeam(battingTeamData?.team_members);

      if (bowlingTeamData?.status)
        setBowlingTeam(bowlingTeamData?.team_members);
    } catch (e) {
    }
  };

  const handleCompletedAnalysis = () => {
    // Swal.fire(
    //   "Both Innings Completed!",
    //   "Head over to the android app for the results!",
    //   "success"
    // );
    setNextButtonEnabled(false);
  };

  // * Initialize
  useEffect(() => {
    setIsLoading(true);
    //fetchTeamMembers();
    setIsLoading(false);
    if (
      navData?.inning1_current_ball == navData?.overs &&
      navData?.inning2_current_ball == navData?.overs
    )
      handleCompletedAnalysis();
  }, [battingTypes]);

  const onCameraSelect = (value) => (event) => {
    setSelecteCamera(value);
  }

  // const [showCheckVideoMessage, setShowCheckVideoMessage] = React.useState(false);//mobile
  const [showCheckWebVideoMessage, setShowCheckWebVideoMessage] = React.useState(false);//web

  function generateRandomSixDigitNumber() {
    const min = 100000; // Smallest 6-digit number
    const max = 999999; // Largest 6-digit number
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
  }

  function calculateTimeDifference() {
    let startTimeRecording = localStorage.getItem("localStartNewTime");
    let now_time = new Date();
    let end_record_hours = now_time.getHours() <= 9 ? "0" + now_time.getHours() : now_time.getHours();
    let end_record_minutes = now_time.getMinutes() <= 9 ? "0" + now_time.getMinutes() : now_time.getMinutes();
    let end_record_seconds = now_time.getSeconds() <= 9 ? "0" + now_time.getSeconds() : now_time.getSeconds();

    let endTimeRecording = end_record_hours + ":" + end_record_minutes + ":" + end_record_seconds;

    let start = new Date(`2000-01-01T${startTimeRecording}`);
    let end = new Date(`2000-01-01T${endTimeRecording}`);

    // Calculate the time difference in milliseconds
    let difference = end - start;

    // Convert the time difference to a readable format
    let seconds = Math.floor(difference / 1000) % 60;
    setDemoValue(seconds);
  }

  const [isAutoClick, setIsAutoClick] = React.useState(false);//mobile

  const handleRecording = (value, clickFrom) => (event) => {

    if (cameraType === 'mobile') {
      if (value == true) {
        setShowCheckWebVideoMessage(false)
        // setShowCheckVideoMessage(false)
        setMobileVideoStatus("")
        setDemoValue("");
        setIsStopRecordingClicked(false);
      }
      else {
        setIsStopRecordingClicked(true);
      }
      setIsDisabledRecordButton(true);
      setTimeout(() => setIsDisabledRecordButton(false), 2000);
      setIsRecording(value);
      const currentTimeInSeconds = systemTime.getHours() * 3600 + systemTime.getMinutes() * 60 + systemTime.getSeconds();
      if (value) {
        let record_hours = systemTime.getHours() <= 9 ? "0" + systemTime.getHours() : systemTime.getHours();
        let record_minutes = systemTime.getMinutes() <= 9 ? "0" + systemTime.getMinutes() : systemTime.getMinutes();
        let record_seconds = systemTime.getSeconds() <= 9 ? "0" + systemTime.getSeconds() : systemTime.getSeconds();
        setStartNewTime(record_hours + ":" + record_minutes + ":" + record_seconds);
        setStartLapTime(currentTimeInSeconds);
        localStorage.setItem("localStartNewTime", record_hours + ":" + record_minutes + ":" + record_seconds);
      }
      else {
        calculateTimeDifference();
      }
    }
    else {

      let GetWideNoBallRandomNumber = "";
      if (value == false) {
        GetWideNoBallRandomNumber = localStorage.getItem("WideNoBallRandomNumber");
        if (GetWideNoBallRandomNumber != "") {
          setAutoSixDigitNumber(GetWideNoBallRandomNumber);
        }
        localStorage.setItem("WideNoBallRandomNumber", "");
      }
      else {
        //setIsNextBallClick(false);
        localStorage.setItem("isNextBallClick", false);
      }

      if (isOnline == false) {
        Swal.fire({
          icon: 'warning',
          title: 'Internet Connection Alert',
          text: 'You are offline. Please check your internet connection.',
          confirmButtonText: 'OK',
        });
      } else {
        global_initial_val.initial_val = 1;
        if (clickFrom != "AutoClick") {
          checkVideoStatus(clickFrom);
        }

        if (value == true) {
          setShowCheckWebVideoMessage(false)
          // setShowCheckVideoMessage(false)
          setMobileVideoStatus("")
          setIsStopRecordingClicked(false);
        }
        else {
          setIsStopRecordingClicked(true);
        }
        setIsDisabledRecordButton(true);
        setTimeout(() => setIsDisabledRecordButton(false), 3000);
        setIsRecording(value);
        const currentTimeInSeconds = systemTime.getHours() * 3600 + systemTime.getMinutes() * 60 + systemTime.getSeconds();
        const doc = startStopCamCollection.doc(HeadingCommonName);
        doc.get().then(async res => {
          if (!res.exists) {
            let match_analysis_log = {};
            match_analysis_log["analysis_master_id"] = navData.id;
            match_analysis_log["tournament_id"] = navData.tournament_id;
            match_analysis_log["match_id"] = navData.match_id;
            match_analysis_log["current_inning"] = innings;
            match_analysis_log["overs"] = totalOvers;
            match_analysis_log["current_ball"] = current_ball;
            match_analysis_log["type_of_error"] = "Firebase Database Unavailable";
            match_analysis_log["error_description"] = "Firebase database record not available to start video";
            match_analysis_log["input_data"] = doc;

            const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

            Swal.fire(
              "Info",
              "Firebase database record not available to start video"
            );
            setIsRecording(false);
            return;
          }

          let fireBaseCameraStatus = false;
          if (selecteCamera == "Camera1") {
            if (typeof res.data().pageActiveCam1 != "undefined") {
              fireBaseCameraStatus = res.data().pageActiveCam1.pageActive;
            }

          } else {
            if (typeof res.data().pageActiveCam2 != "undefined") {
              fireBaseCameraStatus = res.data().pageActiveCam2.pageActive;
            }
          }
          let combine_mili_sec = '';
          if (fireBaseCameraStatus) {
            if (value) {
              let record_hours = systemTime.getHours() <= 9 ? "0" + systemTime.getHours() : systemTime.getHours();
              let record_minutes = systemTime.getMinutes() <= 9 ? "0" + systemTime.getMinutes() : systemTime.getMinutes();
              let record_seconds = systemTime.getSeconds() <= 9 ? "0" + systemTime.getSeconds() : systemTime.getSeconds();
              let record_seconds_mili = systemTime.getMilliseconds() <= 99 ? "0" + systemTime.getMilliseconds() : systemTime.getMilliseconds();

              setStartNewTime(record_hours + ":" + record_minutes + ":" + record_seconds);
              setStartLapTime(currentTimeInSeconds);
              localStorage.setItem("localStartNewTime", record_hours + ":" + record_minutes + ":" + record_seconds);
              combine_mili_sec = record_hours + ":" + record_minutes + ":" + record_seconds + "." + record_seconds_mili;
            }
            else {
              calculateTimeDifference();

              // if (isAutoClick == true) {
              //   setDemoValue(7);
              //   setIsAutoClick(false);
              // } else {
              //   handleLap(value, clickFrom);
              // }
            }

            let next_ball;
            if (isNonDeliveryBallClick == true) {
              next_ball = showOvers;
              next_ball = next_ball.toFixed(1);
            }
            else if (send_analysis_id == "") {
              next_ball = nextBall(showOvers);
              next_ball = next_ball.toFixed(1);
            } else {
              next_ball = showOvers;
              next_ball = next_ball.toFixed(1);
            }

            let wide_no_ball_check = {};
            wide_no_ball_check["analysis_master_id"] = navData.id;
            wide_no_ball_check["tournament_id"] = navData.tournament_id;
            wide_no_ball_check["match_id"] = navData.match_id;
            wide_no_ball_check["current_inning"] = innings;
            wide_no_ball_check["next_ball"] = next_ball;

            const get_wide_no_ball_count = await getWideNoBallCount(wide_no_ball_check);
            let local_sixDigitNumber = "";

            if (send_analysis_id == "") {
              if ((GetWideNoBallRandomNumber != "") && (get_wide_no_ball_count.ball_count > 0)) {
                next_ball = next_ball + '_' + GetWideNoBallRandomNumber;
              }
              else if (get_wide_no_ball_count.ball_count != 0) {
                if (value == false) {
                  // local_sixDigitNumber = generateRandomSixDigitNumber();                
                  // setSixDigitNumber(local_sixDigitNumber);
                  // next_ball = next_ball + '_' + local_sixDigitNumber;
                }
              }
              else {
                next_ball = next_ball;
                setSixDigitNumber("");
              }
            } else {
              if ((GetWideNoBallRandomNumber != "") && (get_wide_no_ball_count.ball_count > 0)) {
                next_ball = next_ball + '_' + GetWideNoBallRandomNumber;
              }
              else if (get_wide_no_ball_count.ball_count > 1) {
                if (value == false) {
                  local_sixDigitNumber = generateRandomSixDigitNumber();
                  setSixDigitNumber(local_sixDigitNumber);
                  next_ball = next_ball + '_' + local_sixDigitNumber;
                }
              }
              else {
                next_ball = next_ball;
                setSixDigitNumber("");
              }
            }

            let full_ballname = "";
            full_ballname = HeadingCommonName + '_Over_' + (innings === 1 ? `FI_${next_ball}` : `SI_${next_ball}`);

            let local_recording_ball_detail;
            if (innings == 1) {
              local_recording_ball_detail = localStorage.getItem("recording_ball_detail_first");
            }
            else {
              local_recording_ball_detail = localStorage.getItem("recording_ball_detail_second");
            }

            if (local_recording_ball_detail != null) {
              let parse_local_recording_ball_detail = JSON.parse(local_recording_ball_detail);

              if (isNonDeliveryBallClick == true) {
                parse_local_recording_ball_detail[next_ball] = parseFloat(prevBall(showOvers)).toFixed(1);
              }
              else if (send_analysis_id == "") {
                parse_local_recording_ball_detail[next_ball] = showOvers.toFixed(1);
              }
              else {
                parse_local_recording_ball_detail[next_ball] = parseFloat(prevBall(showOvers)).toFixed(1);
              }

              if (innings == 1) {
                localStorage.setItem("recording_ball_detail_first", JSON.stringify(parse_local_recording_ball_detail));
              }
              else {
                localStorage.setItem("recording_ball_detail_second", JSON.stringify(parse_local_recording_ball_detail));
              }
            }
            else {
              let recording_ball_detail = {};

              if (isNonDeliveryBallClick == true) {
                recording_ball_detail[next_ball] = parseFloat(prevBall(showOvers)).toFixed(1);
              }
              else if (send_analysis_id == "") {
                recording_ball_detail[next_ball] = showOvers.toFixed(1);
              }
              else {
                recording_ball_detail[next_ball] = parseFloat(prevBall(showOvers)).toFixed(1);
              }

              if (innings == 1) {
                localStorage.setItem("recording_ball_detail_first", JSON.stringify(recording_ball_detail));
              }
              else {
                localStorage.setItem("recording_ball_detail_second", JSON.stringify(recording_ball_detail));
              }
            }

            if (value == false) {
              // setShowCheckVideoMessage(false);
              let intervalCount = 0;
              const intervalId = setInterval(() => {
                getCurrentBallVideo({
                  fileName: full_ballname,
                  unzip_ball: next_ball
                })
                  .then(async (fetchedData) => {
                    if (fetchedData.videoCount > 0) {
                      let collect_local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
                      let filter_new_intervals = [];
                      for (let interval_index = 0; interval_index < collect_local_intervals.length; interval_index++) {
                        clearInterval(collect_local_intervals[interval_index]["interval_id"]);
                      }
                      localStorage.setItem("allActiveIntervalIds", JSON.stringify(filter_new_intervals));
                      cancelAllRequests();

                      const refresh_timestamp = Date.now();
                      setVideoSourcFile("https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/refresh.mp4?unique_token=" + refresh_timestamp);

                      const timeoutId = setTimeout(() => {
                        const new_file_path = "https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/well_played_tournament/" + full_ballname + ".mp4";
                        setVideoSourcFile(new_file_path);
                      }, 1000);
                    }

                  })
                  .catch((e) => {
                  });

                // unZipVideoFromFileName({
                //   fileName: full_ballname,
                //   unzip_ball: next_ball
                // }).then(async (unzip_data) => {

                //   if (unzip_data.final_filename != "") {
                //     cancelAllRequests();
                //     const refresh_timestamp = Date.now();
                //     setVideoSourcFile("https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/refresh.mp4?unique_token=" + refresh_timestamp);

                //     const timeoutId = setTimeout(() => {

                //       let isVideoShow = false;

                //       let get_local_recording_ball_detail;
                //       if (innings == 1) {
                //         get_local_recording_ball_detail = localStorage.getItem("recording_ball_detail_first");
                //       }
                //       else {
                //         get_local_recording_ball_detail = localStorage.getItem("recording_ball_detail_second");
                //       }

                //       if (get_local_recording_ball_detail != null) {
                //         let get_parse_local_recording_ball_detail = JSON.parse(get_local_recording_ball_detail);
                //         let right_parse_local_recording_ball_detail = nextBall(get_parse_local_recording_ball_detail[next_ball]).toFixed(1);

                //         let compare_number = "";

                //         if (send_analysis_id == "") {
                //           if ((GetWideNoBallRandomNumber != "") && (get_wide_no_ball_count.ball_count > 0)) {
                //             if (localStorage.getItem("isNextBallClick") == "true") {
                //               compare_number = right_parse_local_recording_ball_detail + '_' + GetWideNoBallRandomNumber + "_";
                //             }
                //             else {
                //               compare_number = right_parse_local_recording_ball_detail + '_' + GetWideNoBallRandomNumber;
                //             }
                //             local_sixDigitNumber = GetWideNoBallRandomNumber;
                //           }
                //           else if (get_wide_no_ball_count.ball_count != 0) {
                //             compare_number = right_parse_local_recording_ball_detail + '_' + local_sixDigitNumber;
                //           }
                //           else {

                //             if (localStorage.getItem("isNextBallClick") == "true") {
                //               compare_number = right_parse_local_recording_ball_detail + "_";
                //             }
                //             else {
                //               compare_number = right_parse_local_recording_ball_detail;
                //             }

                //           }
                //         } else {
                //           if ((GetWideNoBallRandomNumber != "") && (get_wide_no_ball_count.ball_count > 0)) {
                //             if (localStorage.getItem("isNextBallClick") == "true") {
                //               compare_number = right_parse_local_recording_ball_detail + '_' + GetWideNoBallRandomNumber + "_";
                //             }
                //             else {
                //               compare_number = right_parse_local_recording_ball_detail + '_' + GetWideNoBallRandomNumber;
                //             }

                //             local_sixDigitNumber = GetWideNoBallRandomNumber;
                //           }
                //           else if (get_wide_no_ball_count.ball_count > 1) {
                //             compare_number = right_parse_local_recording_ball_detail + '_' + local_sixDigitNumber;
                //           }
                //           else {
                //             compare_number = right_parse_local_recording_ball_detail;
                //           }
                //         }

                //         if (compare_number == next_ball) {
                //           isVideoShow = true;
                //         }
                //       }

                //       const timestamp = Date.now();
                //       let dynamicVideoPath = unzip_data.final_filename + "?unique_token=" + timestamp;
                //       if (isVideoShow == true) {
                //         setVideoSourcFile(dynamicVideoPath);
                //       }
                //       else {
                //         setVideoSourcFile("");
                //         updateVideoUrlPrevious({
                //           analysis_master_id: navData.id,
                //           current_ball: unzip_data.unzip_ball,
                //           current_inning: innings,
                //           video_url: unzip_data.final_filename,
                //           sixDigitNumber: local_sixDigitNumber
                //         }).then(async (data) => {

                //           if ((localStorage.getItem("isFirstInningChange") == "true") && (innings == 1)) {
                //             //localStorage.setItem("isFirstInningChange", false);
                //           }
                //           else {
                //             getLatestEighteenBallDetail({
                //               match_id: navData.match_id,
                //               current_inning: innings

                //             }).then(async (data) => {
                //               let arr_reverse = data.latest_eighteen_ball_details;
                //               let reversedArray = arr_reverse.slice().reverse();
                //               setlatestEighteenBall(reversedArray)
                //             })
                //               .catch((e) => {
                //               });
                //           }


                //         });
                //       }

                //       setShowCheckWebVideoMessage(true)
                //       setShowCheckVideoMessage(false)
                //       let collect_local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
                //       let filter_new_intervals = [];
                //       for (let interval_index = 0; interval_index < collect_local_intervals.length; interval_index++) {

                //         if (collect_local_intervals[interval_index]["interval_id"] == intervalId) {
                //           clearInterval(collect_local_intervals[interval_index]["interval_id"]);
                //         }
                //         else {
                //           filter_new_intervals.push(collect_local_intervals[interval_index]);
                //         }
                //       }
                //       localStorage.setItem("allActiveIntervalIds", JSON.stringify(filter_new_intervals));

                //     }, 3000);
                //   }
                //   else {
                //     if (localStorage.getItem("isMbInternetConnectionLost") == "true") {
                //       localStorage.setItem("isMbInternetConnectionLost", false);
                //       setIsMobileStopInterval(false);
                //       let collect_local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
                //       let filter_new_intervals = [];
                //       for (let interval_index = 0; interval_index < collect_local_intervals.length; interval_index++) {
                //         if (collect_local_intervals[interval_index]["interval_id"] == intervalId) {
                //           clearInterval(collect_local_intervals[interval_index]["interval_id"]);
                //         }
                //         else {
                //           filter_new_intervals.push(collect_local_intervals[interval_index]);
                //         }
                //       }
                //       localStorage.setItem("allActiveIntervalIds", JSON.stringify(filter_new_intervals));

                //       setShowCheckWebVideoMessage(true)
                //       setShowCheckVideoMessage(false)
                //       setMobileVideoStatus("")
                //       setIsStopRecordingClicked(false);
                //     }
                //   }


                //   let interval_update_time = new Date();
                //   let interval_update_record_hours = interval_update_time.getHours() <= 9 ? "0" + interval_update_time.getHours() : interval_update_time.getHours();
                //   let interval_update_record_minutes = interval_update_time.getMinutes() <= 9 ? "0" + interval_update_time.getMinutes() : interval_update_time.getMinutes();
                //   let interval_update_record_seconds = interval_update_time.getSeconds() <= 9 ? "0" + interval_update_time.getSeconds() : interval_update_time.getSeconds();

                //   let interval_updateTimeRecording = interval_update_record_hours + ":" + interval_update_record_minutes + ":" + interval_update_record_seconds;

                //   let collect_local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));

                //   let filter_new_intervals = [];
                //   for (let interval_index = 0; interval_index < collect_local_intervals.length; interval_index++) {
                //     let close_start = new Date(`2000-01-01T${collect_local_intervals[interval_index]["intervalTimeRecording"]}`);
                //     let close_end = new Date(`2000-01-01T${interval_updateTimeRecording}`);

                //     // Calculate the time difference in milliseconds
                //     let close_difference = close_end - close_start;

                //     // Convert the time difference to a readable format
                //     let close_seconds = Math.floor(close_difference / 1000) % 60;
                //     if (close_seconds > 30) {
                //       // clear interval
                //       clearInterval(collect_local_intervals[interval_index]["interval_id"]);
                //     }
                //     else {
                //       filter_new_intervals.push(collect_local_intervals[interval_index]);
                //     }
                //     //clearInterval(collect_local_intervals[interval_index]["interval_id"]);
                //   }
                //   localStorage.setItem("allActiveIntervalIds", JSON.stringify(filter_new_intervals));
                // }).catch((e) => {
                // });

              }, 3000);

              let interval_time = new Date();
              let interval_record_hours = interval_time.getHours() <= 9 ? "0" + interval_time.getHours() : interval_time.getHours();
              let interval_record_minutes = interval_time.getMinutes() <= 9 ? "0" + interval_time.getMinutes() : interval_time.getMinutes();
              let interval_record_seconds = interval_time.getSeconds() <= 9 ? "0" + interval_time.getSeconds() : interval_time.getSeconds();

              let intervalTimeRecording = interval_record_hours + ":" + interval_record_minutes + ":" + interval_record_seconds;

              let local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
              local_intervals.push({ interval_id: intervalId, intervalTimeRecording: intervalTimeRecording });
              localStorage.setItem("allActiveIntervalIds", JSON.stringify(local_intervals));
            }

            let docToUpdate;

            let check_nextball = next_ball
            if (get_wide_no_ball_count.ball_count > 0) {
              let check_sixDigitNumber = "";
              if (send_analysis_id != "") {
                check_sixDigitNumber = autoSixDigitNumberEdit;
                setAutoSixDigitNumber("");
              }
              else {
                check_sixDigitNumber = generateRandomSixDigitNumber();
              }

              if (value == true) {
                localStorage.setItem("WideNoBallRandomNumber", check_sixDigitNumber);
              }
              check_nextball = check_nextball + '_' + check_sixDigitNumber
              //setSixDigitNumber(check_sixDigitNumber);
            }
            if (value == true) {
              let record_mili_sec = {};
              record_mili_sec["input_data"] = { "zipFileName": full_ballname + ".mp4" };
              record_mili_sec["upload_type"] = "Match Analysis - Start Time";
              record_mili_sec["event_time"] = combine_mili_sec;
              const log_recording_time = await logRecordingTime(record_mili_sec);

              docToUpdate = {
                ...res.data(),
                [selecteCamera]: { ...res.data()[selecteCamera], CameraStatus: value },
                Inning_Over: innings === 1 ? `FI_${check_nextball}` : `SI_${check_nextball}`,
                recordingStarted: false,
                videoAlreadyExist: false,
                videoRecorded: false,
                videoUploaded: false,
                videoUploading: false,
                videoUploadingFailed: false
              };
            } else {
              if (get_wide_no_ball_count.ball_count > 0) {
                docToUpdate = {
                  ...res.data(),
                  [selecteCamera]: { ...res.data()[selecteCamera], CameraStatus: value },
                  // Inning_Over: innings === 1 ? `FI_${check_nextball}` : `SI_${check_nextball}`
                };
              }
              else {
                docToUpdate = {
                  ...res.data(),
                  [selecteCamera]: { ...res.data()[selecteCamera], CameraStatus: value },
                  Inning_Over: innings === 1 ? `FI_${check_nextball}` : `SI_${check_nextball}`
                };
              }
            }

            doc.update(docToUpdate).then(() => {
              setIsRecording(value);
            }).catch(err => {
              setIsRecording(false);
            });
          } else {
            setIsRecording(false);
            let loggin_detail = JSON.parse(localStorage.getItem("WellplayedToken"));
            const AddLogCheckPageActiveFirebaseData = await AddLogCheckPageActiveFirebase({
              firebase_data: res.data(),
              user_id: loggin_detail.user.id
            });

            let match_analysis_log = {};
            match_analysis_log["analysis_master_id"] = navData.id;
            match_analysis_log["tournament_id"] = navData.tournament_id;
            match_analysis_log["match_id"] = navData.match_id;
            match_analysis_log["current_inning"] = innings;
            match_analysis_log["overs"] = totalOvers;
            match_analysis_log["current_ball"] = current_ball;
            match_analysis_log["type_of_error"] = "Mobile Video Window Off";
            match_analysis_log["error_description"] = "Record Video Window not available in Mobile. Please check mobile.";
            match_analysis_log["input_data"] = {
              firebase_data: res.data(),
              user_id: loggin_detail.user.id
            };

            const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
            Swal.fire(
              "Record Video Window not available in Mobile. Please check mobile."
            );
          }
        });
      }
    }
  }

  const onDownloadClick = (file) => (event) => {
    axios.get(`https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/well_played_tournament/${file.filepath}`, { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.filepath);
      document.body.appendChild(link);
      link.click();
    }).catch(error => { console.debug("error while downloading video...", error) });
  }

  const fetchVideosList = () => axios.post(`${process.env.REACT_APP_BASE_URL}/get-match-videos`, { filter_type: HeadingCommonName }).then(response => {
    setVideoFiles(response.data.videoList);
    return response;
  }).catch(error => {
    return error;
  });


  function getStatusTextColor(status) {
    switch (status) {
      case "Recording Started":
        return "green"; // Set the color for "Recording Started" status
      case "Video Recorded":
        return "blue"; // Set the color for "Video Recorded" status
      case "Video Uploading":
        return "orange"; // Set the color for "Video Uploading" status
      case "Video Uploaded":
        return "green"; // Set the color for "Video Uploaded" status
      case "Video Uploading Failed":
        return "purple"; // Set the color for "Video Uploading Failed" status
      case "Video Already Exist":
        return "gray"; // Set the color for "Video Already Exist" status
      default:
        return "black"; // Default color
    }
  }


  // Effect to check video status every 10 seconds when recording starts
  useEffect(() => {
    if (isRecording && cameraType == "external") {
      const intervalIdMobileStatus = setInterval(async () => {
        if (!mobileVideoStatus) {
          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Mobile Internet Connection Unavailable";
          match_analysis_log["error_description"] = "Check your internet connection in mobile app!";
          match_analysis_log["input_data"] = {
            mobileVideoStatus: mobileVideoStatus,
            isRecording: isRecording
          };

          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

          Swal.fire({
            icon: 'warning',
            title: 'Mobile Status Alert',
            text: 'Check your internet connection in mobile app!',
            confirmButtonText: 'OK',
          });
          clearInterval(intervalIdMobileStatus);

          const doc = startStopCamCollection.doc(HeadingCommonName);
          doc.get().then(async res => {
            if (res.exists) {
              let docToUpdate;
              docToUpdate = {
                ...res.data(),
                [selecteCamera]: { ...res.data()[selecteCamera], CameraStatus: false },
                Inning_Over: innings === 1 ? `FI_${parseFloat(nextBall(showOvers)).toFixed(1)}` : `SI_${parseFloat(nextBall(showOvers)).toFixed(1)}`
              };
              docToUpdate["videoRecorded"] = false;
              docToUpdate["videoUploadingFailed"] = false;
              docToUpdate["recordingStarted"] = false;
              docToUpdate["videoUploading"] = false;
              docToUpdate["videoAlreadyExist"] = false;
              docToUpdate["videoUploaded"] = false;
              doc.update(docToUpdate).then(() => {
                setIsRecording(false);
              }).catch(err => {
                setIsRecording(false);
              });
            }
          });
        }
      }, 10000); // 10 seconds interval
      return () => clearInterval(intervalIdMobileStatus);

    }
  }, [isRecording, mobileVideoStatus]);

  const [isMobileStopInterval, setIsMobileStopInterval] = React.useState(false);

  // useEffect(() => {
  //   setIsMbInternetConnectionLost(isMbInternetConnectionLost);
  //   localStorage.setItem("isMbInternetConnectionLost", false);

  //   console.log("UseEffect isMbInternetConnectionLost", isMbInternetConnectionLost)
  // }, [isMbInternetConnectionLost]);

  useEffect(() => {
    if (isStopRecordingClicked && !isRecording && ((mobileVideoStatus == "Recording Started") || (mobileVideoStatus == "")) && (cameraType == "external")) {
      const intervalIdMobileStatusStopped = setInterval(async () => {

        if ((mobileVideoStatus == "Recording Started") || (mobileVideoStatus == "")) {
          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Mobile Internet Connection Unavailable";
          match_analysis_log["error_description"] = "Check your internet connection in mobile app!";
          match_analysis_log["input_data"] = {
            mobileVideoStatus: mobileVideoStatus,
            isRecording: isRecording,
            isStopRecordingClicked: isStopRecordingClicked
          };

          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

          Swal.fire({
            icon: 'warning',
            title: 'Mobile Status Alert',
            text: 'Check your internet connection in mobile app!',
            confirmButtonText: 'OK',
          });
          // setIsMbInternetConnectionLost(true);
          localStorage.setItem("isMbInternetConnectionLost", true);
          clearInterval(intervalIdMobileStatusStopped);


          const doc = startStopCamCollection.doc(HeadingCommonName);
          doc.get().then(async res => {
            if (res.exists) {
              let docToUpdate;
              docToUpdate = {
                ...res.data(),
                [selecteCamera]: { ...res.data()[selecteCamera], CameraStatus: false },
                Inning_Over: innings === 1 ? `FI_${parseFloat(nextBall(showOvers)).toFixed(1)}` : `SI_${parseFloat(nextBall(showOvers)).toFixed(1)}`
              };
              docToUpdate["videoRecorded"] = false;
              docToUpdate["videoUploadingFailed"] = false;
              docToUpdate["recordingStarted"] = false;
              docToUpdate["videoUploading"] = false;
              docToUpdate["videoAlreadyExist"] = false;
              docToUpdate["videoUploaded"] = false;
              doc.update(docToUpdate).then(() => {
                setIsRecording(false);
              }).catch(err => {
                setIsRecording(false);
              });
            }
          });
        }
      }, 10000); // 10 seconds interval
      return () => clearInterval(intervalIdMobileStatusStopped);
    }
  }, [!isRecording, mobileVideoStatus]);

  const fnRecording = handleRecording(false, "AutoClick");

  const [collectStatusIntervalIds, setCollectStatusIntervalIds] = useState([]);

  function checkVideoStatus(clickFrom) {
    let autoClickCount = 0;
    if (localStorage.getItem('header_name') != "") {
      const doc = startStopCamCollection.doc(localStorage.getItem('header_name'));
      let intervalIdStatus = setInterval(() => {
        doc.onSnapshot(
          (doc) => {

            if (JSON.parse(localStorage.getItem("allActiveIntervalStatusIds")).includes(intervalIdStatus)) {

              if (doc.exists) {
                const data = doc.data();

                if (data["recordingStarted"]) {
                  setMobileVideoStatus("Recording Started");
                  // setShowCheckVideoMessage(false);
                  setShowCheckWebVideoMessage(false);
                } else if (data["videoRecorded"]) {
                  setMobileVideoStatus("Video Recorded");
                  setShowCheckWebVideoMessage(false);

                  if (isRecording == false) {
                    if (autoClickCount == 0) {
                      setIsAutoClick(true);
                      fnRecording();
                      autoClickCount++;
                    }
                  }
                } else if (data["videoUploading"]) {
                  setMobileVideoStatus("Video Uploading");
                  setShowCheckWebVideoMessage(false);

                  if (isRecording == false) {
                    if (autoClickCount == 0) {
                      fnRecording();
                      autoClickCount++;
                    }
                  }
                } else if (data["videoUploaded"]) {
                  setMobileVideoStatus("Video Uploaded");
                  let local_intervals = JSON.parse(localStorage.getItem("allActiveIntervalIds"));
                  // if (local_intervals.length > 0) {
                  //   setShowCheckVideoMessage(true);
                  // }

                  if (isRecording == false) {
                    if (autoClickCount == 0) {
                      fnRecording();
                      autoClickCount++;
                    }
                  }
                } else if (data["videoUploadingFailed"]) {
                  setMobileVideoStatus("Video Uploading Failed");
                  setShowCheckWebVideoMessage(false)
                } else if (data["videoAlreadyExist"]) {
                  setMobileVideoStatus("Video Already Exist");
                  setShowCheckWebVideoMessage(false)
                }
                else if (!data["videoUploaded"]) {
                  setShowCheckWebVideoMessage(false)
                }

                if (
                  data["videoUploaded"] === true ||
                  data["videoAlreadyExist"] === true || data["pageActive"] === true
                ) {
                  clearInterval(intervalIdStatus);
                  if (data["videoUploaded"]) {
                    // Call a function to start playing the video
                    setShowCheckWebVideoMessage(false);
                    startVideoPlayback();
                  }
                }
              }
            }

          },
          (error) => { }
        );

      }, 2000);

      setCollectStatusIntervalIds(prevIds => [...prevIds, intervalIdStatus]);
      collectStatusIntervalIds.forEach(intervalIdStatus => clearInterval(intervalIdStatus));

      let collect_all_active_interval_status = JSON.parse(localStorage.getItem("allActiveIntervalStatusIds"));
      collect_all_active_interval_status = [intervalIdStatus];
      localStorage.setItem("allActiveIntervalStatusIds", JSON.stringify(collect_all_active_interval_status));
    }
  }

  const startVideoPlayback = () => {
    const videoPlayer = document.getElementById('your-video-player-id');
    if (videoPlayer) {
      videoPlayer.src = videoSourcFile;
      videoPlayer.play();
    }
  };

  const fetchVideosListFirst = (HeadingVideoName) => axios.post(`${process.env.REACT_APP_BASE_URL}/get-match-videos`, { filter_type: HeadingVideoName }).then(response => {
    setVideoFiles(response.data.videoUrl);
    return response;
  }).catch(error => {
    console.debug("error while getting video files...", error);
    return error;
  });

  useEffect(() => {
    if (!isRecording) {
      fetchVideosList();
    }
  }, [isRecording]);

  const onRefreshClick = (event) => {
    fetchVideosList().then(res => {
      const newVideosFiles = res.data?.videoList?.filter(nv => !videoFiles?.find(v => v.fileid === nv.fileid));
      if (res.data.videoList == null) {
        Swal.fire({
          icon: "info",
          title: "😑",
          text: "Video not available"
        });
      }
      else {
        setVideoFiles([...newVideosFiles, ...videoFiles]);

        if (newVideosFiles.length > 0) {
          newVideosFiles.forEach(v => { onDownloadClick(v)(event) });
        }
      }
    });
  }

  const onUnzipClick = (event) => {
    MatchUnzipList().then(res => {
      fetchVideosList();
    });
  }

  const onLiveStreamingClick = (event) => {
    const docKey = HeadingCommonName;
    const doc = startStopLiveCollection.doc(docKey);
    doc.get().then((res) => {
      if (!res.exists) {
        Swal.fire(
          "Info",
          "Firebase database record not available to start video"
        );
        return;
      }
      let docToUpdate;
      docToUpdate = {
        Camera1: {
          CameraStatus: false,
          UserID: navData.Camera1User.id.toString()
        },
        Camera2: {
          CameraStatus: false,
          UserID: navData.Camera2User.id.toString()
        },
        streamId: "",
        streamKey: "",
      }
      doc.update(docToUpdate)
        .then(() => {
          var myWindow = window.open("/MatchCenter/LiveFeed?analysis_master_id=" + navData.id, "", "width=500,height=700");
        })
        .catch((err) => {
        });
    });
  }

  const DownloadCropMuxVideoClick = (event) => {
    var myWindow = window.open("/MatchCenter/liveFeedCutVideo?analysis_master_id=" + navData.id, "_blank", "");
  }

  const MatchUnzipList = () => axios.post(`${process.env.REACT_APP_BASE_URL}/match-unzip-list`, { filter_type: HeadingCommonName }).then(response => {
  }).catch(error => {
    return error;
  });

  const onPlayClick = (file) => (event) => {
    setOpenVideoPlayer({
      src: `https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/well_played_tournament/${file.filepath}`,
      fileName: file.filepath
    });
  }

  const closeVideoPlayer = (event) => {
    setOpenVideoPlayer({ src: '', fileName: '' });
  }

  const onRetryUploadClick = (file) => {
    return new Promise((resolve, reject) => {
      const doc = startStopCamCollection.doc(Object.keys(navData.doc)[0]);
      doc.get().then(res => {
        if (!res.exists) {
          return;
        }
        const docToUpdate = {
          ...res.data(),
          failedUploadVideoStatus: true
        };
        doc.update(docToUpdate).then(() => {
          fetchVideosList().then(response => {
            setVideoFiles(response.data.videoList);
          });
          resolve(docToUpdate);
        }).catch(err => {
          reject(err);
        });
      });
    });
  }

  const [foot, setFoot] = useState('Front Foot');
  const handleFoot = (value) => (e) => {
    setFoot(value);
  };

  const [batType, setBatType] = useState('Cover Drive');
  const handleBatType = (value) => (e) => {
    setBatType(value);
  };

  const [selectedBallType, setSelectedBallType] = useState("Straight");
  const setBallTypes = (value) => (e) => {
    setSelectedBallType(value);
  };

  const handleResetClick = () => {
    setExtraBallResult(null);
  };

  const [bowling_analysis_size, setBowlingAnalysisSize] = useState(100);
  const handleClickZoomOut = () => {
    let bowling_analysis_size_new = bowling_analysis_size - 5;
    setBowlingAnalysisSize(bowling_analysis_size_new);
    document.getElementById("bowling_analysis_div").style.zoom = bowling_analysis_size_new + "%";
  };

  const handleClickZoomIn = () => {
    let bowling_analysis_size_new = bowling_analysis_size + 5;
    setBowlingAnalysisSize(bowling_analysis_size_new);
    document.getElementById("bowling_analysis_div").style.zoom = bowling_analysis_size_new + "%";
  };

  const [showSwitch, setShowSwitch] = React.useState(false);
  const [selectInn, setSelectInn] = React.useState('inn_1');
  const handleSwitchSubmit = (e) => {
    e.preventDefault();
  }

  const handleSwitchInning = (val) => (e) => {
    setSelectInn(val)
    e.preventDefault();
  }

  const [inn1ScoreboardBatting, setinn1ScoreboardBatting] = React.useState([]);
  const [inn1ScoreboardBowling, setinn1ScoreboardBowling] = React.useState([]);
  const [inn2ScoreboardBatting, setinn2ScoreboardBatting] = React.useState([]);
  const [inn2ScoreboardBowling, setinn2ScoreboardBowling] = React.useState([]);
  const [IsSecondLoadmore, setIsSecondLoadmore] = React.useState(true);
  const [SecondInnTotalRec, setSecondInnTotalRec] = React.useState(0);
  const [IsFirstLoadmore, setIsFirstLoadmore] = React.useState(true);
  const [FirstInnTotalRec, setFirstInnTotalRec] = React.useState(0);
  const [showPopupRunsInn1, setShowPopupRunsInn1] = React.useState(0);
  const [showPopupOversInn1, setShowPopupOversInn1] = React.useState(0);
  const [showPopupWicketsInn1, setShowPopupWicketsInn1] = React.useState(0);
  const [totalPopupOversInn1, setTotalPopupOversInn1] = React.useState(0);
  const [showPopupRunsInn2, setShowPopupRunsInn2] = React.useState(0);
  const [showPopupOversInn2, setShowPopupOversInn2] = React.useState(0);
  const [showPopupWicketsInn2, setShowPopupWicketsInn2] = React.useState(0);
  const [totalPopupOversInn2, setTotalPopupOversInn2] = React.useState(0);

  const handleModalSwitch = (e) => {
    e.preventDefault();

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 1,
      current_ball: current_ball,
      analysis_id: "",
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then((fetchedData) => {
        setShowPopupRunsInn1(fetchedData.analysisMasterData.total_combine_run)
        setShowPopupOversInn1(fetchedData.analysisMasterData.heading_current_over)
        setShowPopupWicketsInn1(fetchedData.analysisMasterData.total_wickets)
        setTotalPopupOversInn1(fetchedData.analysisMasterData.overs)
      });

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 2,
      current_ball: current_ball,
      analysis_id: "",
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then((fetchedData) => {
        setShowPopupRunsInn2(fetchedData.analysisMasterData.total_combine_run)
        setShowPopupOversInn2(fetchedData.analysisMasterData.heading_current_over)
        setShowPopupWicketsInn2(fetchedData.analysisMasterData.total_wickets)
        setTotalPopupOversInn2(fetchedData.analysisMasterData.overs)
      });

    getUserForScoreboard({
      match_id: navData.match_id,
      current_inning: 1
    }).then(async (data) => {
      setinn1ScoreboardBatting(data.scoreBoardAppUser)
    })
      .catch((e) => {
      });

    getBowlerForScoreboard({
      match_id: navData.match_id,
      current_inning: 1
    }).then(async (data) => {
      setinn1ScoreboardBowling(data.final_bowler_statitics)
    })
      .catch((e) => {
      });

    getUserForScoreboard({
      match_id: navData.match_id,
      current_inning: 2
    }).then(async (data) => {
      setinn2ScoreboardBatting(data.scoreBoardAppUser)
    })
      .catch((e) => {
      });

    getBowlerForScoreboard({
      match_id: navData.match_id,
      current_inning: 2
    }).then(async (data) => {
      setinn2ScoreboardBowling(data.final_bowler_statitics)
    })
      .catch((e) => {
      });
    if (innings == 1) {
      setSelectInn('inn_1')
      setShowSwitch(true)
    }
    else {
      setSelectInn('inn_2')
      setShowSwitch(true)
    }
  }

  const [showOversAnalysis, setShowOversAnalysis] = React.useState(false);
  const [selectInnOvers, setSelectInnOvers] = React.useState('inn_1');
  const handleSwitchInningOvers = (val) => (e) => {
    if (val == "inn_2") {
      CheckSwtchinning({
        analysis_master_id: navData.id,
      }).then(async (data) => {
        if (data.querydata.inning1_end_match_over == 0) {
          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Second Inning Not Started";
          match_analysis_log["error_description"] = "Please submit the first inning before starting the second inning";
          match_analysis_log["input_data"] = {
            analysis_master_id: navData.id,
          };
          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);
          Swal.fire({
            icon: "info",
            title: "",
            text: "Please submit the first inning before starting the second inning"
          });
        } else {
          setSelectInnOvers(val)
          getBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: 1,
            current_ball: 1,
            analysis_id: "",
            tournament_name: tournamentName,
            match_name: matchName,
            match_date: matchDate,
            team_bat1: inn1BatTeamName,
            team_bat2: inn2BatTeamName
          })
            .then((fetchedData) => {
              setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over);
              setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over);
            })
            .catch((e) => {
            });
        }
      })
      getBallAnalysis({
        tournament_id: navData.tournament_id,
        match_id: navData.match_id,
        current_inning: 2,
        current_ball: 1,
        analysis_id: "",
        tournament_name: tournamentName,
        match_name: matchName,
        match_date: matchDate,
        team_bat1: inn1BatTeamName,
        team_bat2: inn2BatTeamName
      })
        .then((fetchedData) => {
          setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
          setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
        })
        .catch((e) => {
        });
    } else {
      setSelectInnOvers(val)
      getBallAnalysis({
        tournament_id: navData.tournament_id,
        match_id: navData.match_id,
        current_inning: 1,
        current_ball: 1,
        analysis_id: "",
        tournament_name: tournamentName,
        match_name: matchName,
        match_date: matchDate,
        team_bat1: inn1BatTeamName,
        team_bat2: inn2BatTeamName
      })
        .then((fetchedData) => {
          setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
          setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
        })
        .catch((e) => {
        });
    }
    e.preventDefault();
  }
  const [inn1limit, setinn1Limit] = React.useState(200);
  const [inn2limit, setinn2Limit] = React.useState(200);
  const [totalOverAnalysis, setTotalOverAnalysis] = React.useState([]);
  const [collectOverAnalysis, setCollectOverAnalysis] = React.useState([]);
  const [totalOverAnalysisInn2, setTotalOverAnalysisInn2] = React.useState([]);
  const [collectOverAnalysisInn2, setCollectOverAnalysisInn2] = React.useState([]);
  const [lastKeyinn1, setlastKeyinn1] = React.useState(0);
  const [lastKeyinn2, setlastKeyinn2] = React.useState(1);
  const handleModalSwitchOvers = (e) => {
    e.preventDefault();
    setinn1Over("1");
    setinn2Over("1");
    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 1,
      next_record_from: 1,
      record_limit: inn1limit
    }).then(async (data) => {
      for (let i = 1; i <= totalOvers; i++) {
        if (!data.collect_over_decimal.hasOwnProperty(i)) {
          data.collect_over_decimal[i] = [
            [
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".1",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".2",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".3",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".4",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".5",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + 1,
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
            ]
          ];
        } else {
          for (let j = 1; j <= 6; j++) {
            let DeliveryBall;

            if (j == 6) {
              DeliveryBall = (i - 1) + 1;
            } else {
              DeliveryBall = (i - 1) + "." + j;
            }
            const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBall) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
            if (typeof ball_Exists == "undefined") {
              data.collect_over_decimal[i][0].push({
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": parseFloat(DeliveryBall),
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              })
            }
          }
          data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
        }
      }

      setCollectOverAnalysis(data.collect_over_decimal)
      const array = Object.keys(data.collect_over_decimal);
      setTotalOverAnalysis(array)
      const lastKey = array[array.length - 1];
      setlastKeyinn1(lastKey)

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 1,
        next_record_from: 1,
        record_limit: 30
      }).then(async (total_over_data) => {
        setTotalBallsAnalysis(data.collect_over_decimal[1][0])
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 2,
      next_record_from: 1,
      record_limit: inn2limit
    }).then(async (data) => {
      for (let i = 1; i <= totalOvers; i++) {
        if (!data.collect_over_decimal.hasOwnProperty(i)) {
          data.collect_over_decimal[i] = [
            [
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".1",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".2",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".3",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".4",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + ".5",
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
              {
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": (i - 1) + 1,
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              },
            ]
          ];
        } else {
          for (let j = 1; j <= 6; j++) {
            let DeliveryBallInning2;

            if (j == 6) {
              DeliveryBallInning2 = (i - 1) + 1;
            } else {
              DeliveryBallInning2 = (i - 1) + "." + j;
            }

            const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning2) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
            if (typeof ball_Exists == "undefined") {
              data.collect_over_decimal[i][0].push({
                "id": "",
                "is_bookmark": "no",
                "is_force_to_complete": "no",
                "current_ball": parseFloat(DeliveryBallInning2),
                "extra_ball_delivery_result": "",
                "ball_delivery_result": "0",
                "wicket_type": null,
                "run_out_ball_delivery_result": "0",
                "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                "is_neglect_ball": "no"
              })
            }
          }
          data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
        }
      }
      setCollectOverAnalysisInn2(data.collect_over_decimal)
      const array = Object.keys(data.collect_over_decimal);
      setTotalOverAnalysisInn2(array)
      setSecondInnTotalRec(array.length)
      const lastKey = Object.keys(array)[Object.keys(array).length - 1];
      setlastKeyinn2(lastKey)

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 2,
        next_record_from: 1,
        record_limit: 1
      }).then(async (total_over_data2) => {
        if (typeof data.collect_over_decimal[1] != "undefined") {
          if (typeof data.collect_over_decimal[1][0] != "undefined") {
            if (data.collect_over_decimal[1][0].length > 0) {
              setTotalBallsAnalysisInn2(data.collect_over_decimal[1][0])
            }
          }
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    if (innings == 1) {
      setSelectInnOvers('inn_1')
      setShowOversAnalysis(true)
    }
    else {
      setSelectInnOvers('inn_2')
      setShowOversAnalysis(true)
    }
  }
  const [totalBallsAnalysis, setTotalBallsAnalysis] = React.useState([]);
  const [inn1Over, setinn1Over] = React.useState("1");
  const [inn2Over, setinn2Over] = React.useState("");
  const handleOverDetails = (value) => (e) => {
    e.preventDefault();
    setTotalBallsAnalysis(collectOverAnalysis[value][0])
    setinn1Over(value)
  }

  const [totalBallsAnalysisInn2, setTotalBallsAnalysisInn2] = React.useState([]);
  const handleOverDetailsInn2 = (value) => (e) => {
    e.preventDefault();
    setTotalBallsAnalysisInn2(collectOverAnalysisInn2[value][0])
    setinn2Over(value)
  }

  const [visibleInn1Records, setVisibleInn1Records] = useState(30);
  const handleLoadmoreInn1Popup = (e) => {
    e.preventDefault();
    setVisibleInn1Records(visibleInn1Records + 30);
  }

  const handleLoadmoreInn1 = (e) => {
    e.preventDefault();

    const lk = parseInt(inn1limit) + 30;
    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 1,
      next_record_from: 1,
      record_limit: lk
    }).then(async (data) => {
      setinn1Limit(lk)
      setCollectOverAnalysis(data.collect_over_decimal)
      const array = Object.keys(data.collect_over_decimal);
      setTotalOverAnalysis(array)
      if (FirstInnTotalRec == array.length) {
        setIsFirstLoadmore(false)
      }
      setFirstInnTotalRec(array.length)

    })
      .catch((e) => {
      });
  }

  function handleSwitchBallBookmark(ball_id, bookmark) {
    updateBookmarkToBall({
      is_bookmark: bookmark,
      analysis_id: ball_id

    }).then(async (data) => {
      if (bookmark == 'yes') {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been added successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been removed successfully'
        });
      }

      getLatestEighteenBallDetail({
        match_id: navData.match_id,
        current_inning: innings

      }).then(async (data) => {
        let arr_reverse = data.latest_eighteen_ball_details;
        let reversedArray = arr_reverse.slice().reverse();
        setlatestEighteenBall(reversedArray)
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });
  }

  function handleSwitchBallTag(ball_id, is_force_to_complete) {
    updateForceCompleteToBall({
      is_force_to_complete: is_force_to_complete,
      analysis_id: ball_id
    }).then(async (data) => {
      if (is_force_to_complete == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been done successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been removed successfully'
        });
      }

      getLatestEighteenBallDetail({
        match_id: navData.match_id,
        current_inning: innings
      }).then(async (data) => {
        let arr_reverse = data.latest_eighteen_ball_details;
        let reversedArray = arr_reverse.slice().reverse();
        setlatestEighteenBall(reversedArray)
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });
  }

  function handleSwitchBallNeglect(ball_id, is_neglect_ball) {
    updateNeglectToBall({
      is_neglect_ball: is_neglect_ball,
      analysis_id: ball_id
    }).then(async (data) => {
      if (is_neglect_ball == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been neglected or ignored successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been declared legal successfully'
        });
      }

      getBallAnalysis({
        tournament_id: navData.tournament_id,
        match_id: navData.match_id,
        current_inning: innings,
        current_ball: current_ball,
        analysis_id: "",
        tournament_name: tournamentName,
        match_name: matchName,
        match_date: matchDate,
        team_bat1: inn1BatTeamName,
        team_bat2: inn2BatTeamName
      })
        .then((fetchedData) => {

          setShowRuns(fetchedData.analysisMasterData.total_combine_run);
          setShowOvers(fetchedData.analysisMasterData.heading_current_over);
          setShowWickets(fetchedData.analysisMasterData.total_wickets);
          setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs);
          setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball);
        });

      GetSingleBowlerStatistics({
        match_id: navData.match_id,
        current_inning: innings,
        bowler_id: bowlerId
      }).then(async (data) => {
        setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
        setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
        setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
        setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
        setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
        setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
      })
        .catch((e) => {
        });

      getStrikerNonStrikerStatistics({
        match_id: navData.match_id,
        current_inning: innings,
        bat_striker_id: strikerId,
        bat_nstriker_id: nonStrikerId
      }).then(async (data) => {

        if ((strikerId != null) && (strikerId != '')) {
          setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
          setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
          setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
          setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
          setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
        }
        else {
          setTotalStrikerRun(0)
          setTotalStrikerBall(0)
          setTotalStrikerFourRun(0)
          setTotalStrikerSixRun(0)
          setStrikerStrikeRate(0)
        }

        if ((nonStrikerId != null) && (nonStrikerId != '')) {
          setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
          setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
          setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
          setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
          setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
        }
        else {
          setTotalnStrikerRun(0)
          setTotalnStrikerBall(0)
          setTotalnStrikerFourRun(0)
          setTotalnStrikerSixRun(0)
          setnStrikerStrikeRate(0)
        }

      })

      GetLatestThreeBallDetail({
        match_id: navData.match_id,
        current_inning: innings
      }).then(async (data) => {
        setlatest_three_ball_details(data.latest_three_ball_details)
      })
        .catch((e) => {
        });

      getLatestEighteenBallDetail({
        match_id: navData.match_id,
        current_inning: innings
      }).then(async (data) => {
        let arr_reverse = data.latest_eighteen_ball_details;
        let reversedArray = arr_reverse.slice().reverse();
        setlatestEighteenBall(reversedArray)
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });
  }

  const handleSwitchinn2 = (e) => {
    const updateInn = updateInninginMaster({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 2
    })
    window.location.reload();
  }

  function handleSwitchInn1BallBookmark(ball_id, bookmark) {
    updateBookmarkToBall({
      is_bookmark: bookmark,
      analysis_id: ball_id

    }).then(async (data) => {
      if (bookmark == 'yes') {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been added successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been removed successfully'
        });
      }

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 1,
        next_record_from: 1,
        record_limit: inn1limit
      }).then(async (data) => {
        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning1;

              if (j == 6) {
                DeliveryBallInning1 = (i - 1) + 1;
              } else {
                DeliveryBallInning1 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning1) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {
                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning1),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }
        setCollectOverAnalysis(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysis(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 1,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysis(data.collect_over_decimal[inn1Over][0])
                setinn1Over(inn1Over)
              }
            }
          }
        })
          .catch((e) => {
          });

        if (innings == 1) {
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 1
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 1,
      next_record_from: inn1Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysis(data.collect_over_decimal[inn1Over][0])
      setinn1Over(inn1Over)
    })
      .catch((e) => {
      });
  }

  function handleSwitchInn1BallTag(ball_id, is_force_to_complete) {
    updateForceCompleteToBall({
      is_force_to_complete: is_force_to_complete,
      analysis_id: ball_id

    }).then(async (data) => {

      if (is_force_to_complete == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been done successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been removed successfully'
        });
      }

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 1,
        next_record_from: 1,
        record_limit: inn1limit
      }).then(async (data) => {

        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning1;

              if (j == 6) {
                DeliveryBallInning1 = (i - 1) + 1;
              } else {
                DeliveryBallInning1 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning1) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {
                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning1),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }

        setCollectOverAnalysis(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysis(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 1,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysis(data.collect_over_decimal[inn1Over][0])
                setinn1Over(inn1Over)
              }
            }
          }
        })
          .catch((e) => {
          });
        if (innings == 1) {
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 1

          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 1,
      next_record_from: inn1Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysis(collectOverAnalysis[inn1Over][0])
      setinn1Over(inn1Over)
    })
      .catch((e) => {
      });
  }


  function handleSwitchBall1Neglect(ball_id, is_neglect_ball) {
    updateNeglectToBall({
      is_neglect_ball: is_neglect_ball,
      analysis_id: ball_id

    }).then(async (data) => {

      if (is_neglect_ball == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been neglected or ignored successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been declared legal successfully'
        });
      }

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 1,
        next_record_from: 1,
        record_limit: inn1limit
      }).then(async (data) => {

        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning1;

              if (j == 6) {
                DeliveryBallInning1 = (i - 1) + 1;
              } else {
                DeliveryBallInning1 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning1) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {
                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning1),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }

        setCollectOverAnalysis(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysis(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 1,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysis(data.collect_over_decimal[inn1Over][0])
                setinn1Over(inn1Over)
              }
            }
          }
        })
          .catch((e) => {
          });
        if (innings == 1) {
          getBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: innings,
            current_ball: current_ball,
            analysis_id: "",
            tournament_name: tournamentName,
            match_name: matchName,
            match_date: matchDate,
            team_bat1: inn1BatTeamName,
            team_bat2: inn2BatTeamName
          })
            .then((fetchedData) => {

              setShowRuns(fetchedData.analysisMasterData.total_combine_run);
              setShowOvers(fetchedData.analysisMasterData.heading_current_over);
              setShowWickets(fetchedData.analysisMasterData.total_wickets);
              setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs);
              setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball);
            });

          GetSingleBowlerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bowler_id: bowlerId
          }).then(async (data) => {
            setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
            setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
            setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
            setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
            setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
            setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
          })
            .catch((e) => {
            });

          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bat_striker_id: strikerId,
            bat_nstriker_id: nonStrikerId
          }).then(async (data) => {

            if ((strikerId != null) && (strikerId != '')) {
              setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
              setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
              setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
              setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
              setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
            }
            else {
              setTotalStrikerRun(0)
              setTotalStrikerBall(0)
              setTotalStrikerFourRun(0)
              setTotalStrikerSixRun(0)
              setStrikerStrikeRate(0)
            }

            if ((nonStrikerId != null) && (nonStrikerId != '')) {
              setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
              setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
              setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
              setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
              setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
            }
            else {
              setTotalnStrikerRun(0)
              setTotalnStrikerBall(0)
              setTotalnStrikerFourRun(0)
              setTotalnStrikerSixRun(0)
              setnStrikerStrikeRate(0)
            }

          })

          GetLatestThreeBallDetail({
            match_id: navData.match_id,
            current_inning: innings
          }).then(async (data) => {
            setlatest_three_ball_details(data.latest_three_ball_details)
          })
            .catch((e) => {
            });

          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 1

          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 1,
      next_record_from: inn1Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysis(collectOverAnalysis[inn1Over][0])
      setinn1Over(inn1Over)
    })
      .catch((e) => {
      });
  }

  function handleSwitchInn2BallBookmark(ball_id, bookmark) {
    updateBookmarkToBall({
      is_bookmark: bookmark,
      analysis_id: ball_id

    }).then(async (data) => {
      if (bookmark == 'yes') {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been added successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Bookmark has been removed added successfully'
        });
      }

      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 2,
        next_record_from: 1,
        record_limit: inn2limit
      }).then(async (data) => {

        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning2;

              if (j == 6) {
                DeliveryBallInning2 = (i - 1) + 1;
              } else {
                DeliveryBallInning2 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning2) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {

                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning2),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }
        setCollectOverAnalysisInn2(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysisInn2(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 2,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
                setinn2Over(inn2Over)
              }
            }
          }
        })
          .catch((e) => {
          });

        if (innings == 2) {
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 2
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 2,
      next_record_from: inn2Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
      setinn2Over(inn2Over)
    })
      .catch((e) => {
      });
  }

  function handleSwitchInn2BallTag(ball_id, is_force_to_complete) {
    updateForceCompleteToBall({
      is_force_to_complete: is_force_to_complete,
      analysis_id: ball_id

    }).then(async (data) => {
      if (is_force_to_complete == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been done successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Data completion has been removed successfully'
        });
      }
      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 2,
        next_record_from: 1,
        record_limit: inn2limit
      }).then(async (data) => {

        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning2;

              if (j == 6) {
                DeliveryBallInning2 = (i - 1) + 1;
              } else {
                DeliveryBallInning2 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning2) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {

                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning2),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }
        setCollectOverAnalysisInn2(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysisInn2(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 2,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
                setinn2Over(inn2Over)
              }
            }
          }
        })
          .catch((e) => {
          });

        if (innings == 2) {
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 2
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 2,
      next_record_from: inn2Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
      setinn2Over(inn2Over)
    })
      .catch((e) => {
      });
  }


  function handleSwitchBall2Neglect(ball_id, is_neglect_ball) {
    updateNeglectToBall({
      is_neglect_ball: is_neglect_ball,
      analysis_id: ball_id

    }).then(async (data) => {
      if (is_neglect_ball == 'yes') {

        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been neglected or ignored successfully'
        });
      }
      else {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Ball has been declared legal successfully'
        });
      }
      getListOfFirstTotalOver({
        match_id: navData.match_id,
        current_inning: 2,
        next_record_from: 1,
        record_limit: inn2limit
      }).then(async (data) => {

        for (let i = 1; i <= totalOvers; i++) {
          if (!data.collect_over_decimal.hasOwnProperty(i)) {
            data.collect_over_decimal[i] = [
              [
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".1",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".2",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".3",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".4",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + ".5",
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
                {
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": (i - 1) + 1,
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                },
              ]
            ];
          } else {
            for (let j = 1; j <= 6; j++) {
              let DeliveryBallInning2;

              if (j == 6) {
                DeliveryBallInning2 = (i - 1) + 1;
              } else {
                DeliveryBallInning2 = (i - 1) + "." + j;
              }

              const ball_Exists = data.collect_over_decimal[i][0].find(item => ((item.current_ball == DeliveryBallInning2) && (item.extra_ball_delivery_result != "WD") && (item.extra_ball_delivery_result != "NB")));
              if (typeof ball_Exists == "undefined") {

                data.collect_over_decimal[i][0].push({
                  "id": "",
                  "is_bookmark": "no",
                  "is_force_to_complete": "no",
                  "current_ball": parseFloat(DeliveryBallInning2),
                  "extra_ball_delivery_result": "",
                  "ball_delivery_result": "0",
                  "wicket_type": null,
                  "run_out_ball_delivery_result": "0",
                  "ball_box_color": "linear-gradient(to right, #868f96 0%, #596164 100%)",
                  "is_neglect_ball": "no"
                })
              }
            }
            data.collect_over_decimal[i][0].sort((a, b) => a.current_ball - b.current_ball);
          }
        }
        setCollectOverAnalysisInn2(data.collect_over_decimal)
        const array = Object.keys(data.collect_over_decimal);
        setTotalOverAnalysisInn2(array)
        setSecondInnTotalRec(array.length)
        const lastKey = Object.keys(array)[Object.keys(array).length - 1];
        setlastKeyinn2(lastKey)

        getListOfFirstTotalOver({
          match_id: navData.match_id,
          current_inning: 2,
          next_record_from: 1,
          record_limit: 1
        }).then(async (total_over_data2) => {
          if (typeof data.collect_over_decimal[1] != "undefined") {
            if (typeof data.collect_over_decimal[1][0] != "undefined") {
              if (data.collect_over_decimal[1][0].length > 0) {
                setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
                setinn2Over(inn2Over)
              }
            }
          }
        })
          .catch((e) => {
          });

        if (innings == 2) {
          getBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: innings,
            current_ball: current_ball,
            analysis_id: "",
            tournament_name: tournamentName,
            match_name: matchName,
            match_date: matchDate,
            team_bat1: inn1BatTeamName,
            team_bat2: inn2BatTeamName
          })
            .then((fetchedData) => {

              setShowRuns(fetchedData.analysisMasterData.total_combine_run);
              setShowOvers(fetchedData.analysisMasterData.heading_current_over);
              setShowWickets(fetchedData.analysisMasterData.total_wickets);
              setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs);
              setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball);
            });

          GetSingleBowlerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bowler_id: bowlerId
          }).then(async (data) => {
            setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
            setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
            setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
            setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
            setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
            setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
          })
            .catch((e) => {
            });

          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bat_striker_id: strikerId,
            bat_nstriker_id: nonStrikerId
          }).then(async (data) => {

            if ((strikerId != null) && (strikerId != '')) {
              setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
              setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
              setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
              setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
              setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
            }
            else {
              setTotalStrikerRun(0)
              setTotalStrikerBall(0)
              setTotalStrikerFourRun(0)
              setTotalStrikerSixRun(0)
              setStrikerStrikeRate(0)
            }

            if ((nonStrikerId != null) && (nonStrikerId != '')) {
              setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
              setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
              setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
              setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
              setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
            }
            else {
              setTotalnStrikerRun(0)
              setTotalnStrikerBall(0)
              setTotalnStrikerFourRun(0)
              setTotalnStrikerSixRun(0)
              setnStrikerStrikeRate(0)
            }

          })

          GetLatestThreeBallDetail({
            match_id: navData.match_id,
            current_inning: innings
          }).then(async (data) => {
            setlatest_three_ball_details(data.latest_three_ball_details)
          })
            .catch((e) => {
            });

          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 2
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
      })
        .catch((e) => {
        });
    })
      .catch((e) => {
      });

    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 2,
      next_record_from: inn2Over,
      record_limit: 1
    }).then(async (data) => {
      setTotalBallsAnalysisInn2(data.collect_over_decimal[inn2Over][0])
      setinn2Over(inn2Over)
    })
      .catch((e) => {
      });
  }

  const [selectType, setSelectType] = React.useState('')
  const [send_analysis_id, setSend_analysis_id] = React.useState('');
  const nextBall = (ball) => {
    const decimal = (ball - Math.floor(ball)).toFixed(1);
    return decimal < 0.5 ? Number(ball) + 0.1 : Math.floor(ball) + 1;
  };

  const handleSwitchBall = (value, switch_current_ball) => (e) => {
    e.preventDefault();
    setSend_analysis_id(value)
    setSelectType(value)
    setisNonDeliveryBallClick(false);
    setNonDeliveryCurrentBall("");

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: innings,
      current_ball: nextBall(switch_current_ball),
      analysis_id: value,
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (fetchedData) => {
        if (fetchedData.data.sixDigitNumber == "") {
          let updateRandomNumber = generateRandomSixDigitNumber();
          let update_random_analysis = {};
          update_random_analysis["id"] = fetchedData.data.id;
          update_random_analysis["sixDigitNumber"] = updateRandomNumber;

          const update_random_analysis_db_const = await update_random_analysis_db(update_random_analysis);
          setAutoSixDigitNumberEdit(updateRandomNumber);
        }
        else {
          setAutoSixDigitNumberEdit(fetchedData.data.sixDigitNumber);
          localStorage.setItem("WideNoBallRandomNumber", fetchedData.data.sixDigitNumber);
        }

        setStartNewTime(fetchedData.data.start_time)
        setDemoValue(fetchedData.data.end_interval)

        const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
          fetchedData.data.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
        );

        const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
          fetchedData.data.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
        );

        // convert this array inside 2 obj into one array
        let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
          let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
          if (matchingItem) {
            result.push({ ...item, ...matchingItem });
          } else {
            result.push(item);
          }
          return result;
        }, []);

        let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
          let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
          if (matchingItem) {
            result.push({ ...item, ...matchingItem });
          } else {
            result.push(item);
          }
          return result;
        }, []);

        if (battingTeamData?.status)
          setBattingTeamDataDetails(mergedBatArray);

        if (bowlingTeamData?.status)
          setBowlingTeamDataDetails(mergedBallArray);

        setWicketType(fetchedData.data.wicket_type)
        setShowRuns(fetchedData.analysisMasterData.total_combine_run)
        setShowOvers(fetchedData.analysisMasterData.heading_current_over)
        setShowWickets(fetchedData.analysisMasterData.total_wickets)
        setTotalOvers(fetchedData.analysisMasterData.overs)

        if ((fetchedData.data.bowler_id == null) || (fetchedData.data.bowler_id == '') || (fetchedData.data.bowler_id == 'Select Bowling Type')) {
          setBowlerId('')
          setTotalBowlerOver(0)
          setTotalBowlerMaiden(0)
          setTotalBowlerBallWd(0)
          setEconomyBowler(0)
          setTotalBowlerExtraRuns(0)
        }
        else {
          setBowlerId(fetchedData.data.bowler_id)
          GetSingleBowlerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bowler_id: fetchedData.data.bowler_id

          }).then(async (data) => {

            setBowlerType(data.final_bowler_statitics[0].bowler_detail?.bowling)
            setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
            setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
            setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
            setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
            setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)

          })
            .catch((e) => {
            });
        }

        if ((fetchedData.data.bat_striker_id == null) || (fetchedData.data.bat_striker_id == '') || (fetchedData.data.bat_striker_id == 'Select Batting Type')) {
          setStrikerId('')
          setTotalStrikerRun(0)
          setTotalStrikerBall(0)
          setTotalStrikerFourRun(0)
          setTotalStrikerSixRun(0)
          setStrikerStrikeRate(0)
        }
        else {
          setStrikerId(fetchedData.data.bat_striker_id)
          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bat_striker_id: fetchedData.data.bat_striker_id,
            bat_nstriker_id: fetchedData.data.bat_nstriker_id
          }).then(async (data) => {
            setStrikerName(data.bat_striker_info.batsman_detail.full_name)
            setnStrikerName(data.bat_nstriker_info.batsman_detail.full_name)
            if (data.bat_striker_info.batsman_detail == null) {
              setBatsmanType("Right Hand");
            }
            else if (data.bat_striker_info.batsman_detail.batting == 'NA' || data.bat_striker_info.batsman_detail.batting == '') {
              setBatsmanType("Right Hand");

            }
            else {
              setBatsmanType(data.bat_striker_info.batsman_detail.batting)

            }
          })
        }

        if ((fetchedData.data.bat_nstriker_id == null) || (fetchedData.data.bat_nstriker_id == '') || (fetchedData.data.bat_nstriker_id == 'Select Batting Type')) {
          setNonStrikerId('')
          setTotalnStrikerRun(0)
          setTotalnStrikerBall(0)
          setTotalnStrikerFourRun(0)
          setTotalnStrikerSixRun(0)
          setnStrikerStrikeRate(0)
        }
        else {
          setNonStrikerId(fetchedData.data.bat_nstriker_id)
          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: innings,
            bat_striker_id: fetchedData.data.bat_striker_id,
            bat_nstriker_id: fetchedData.data.bat_nstriker_id
          }).then(async (data) => {
            setnStrikerName(data.bat_nstriker_info.batsman_detail.full_name)
            if (data.bat_striker_info.batsman_detail == null) {
              setBatsmanType("Right Hand");
            }
            else if (data.bat_striker_info.batsman_detail.batting == 'NA' || data.bat_striker_info.batsman_detail.batting == '') {
              setBatsmanType("Right Hand");

            }
            else {
              setBatsmanType(data.bat_striker_info.batsman_detail.batting)

            }
          })
        }

        if (fetchedData.data.wicket_type == 'runOut') {
          setShowRunOutButtons(true);
        }
        else {
          setShowRunOutButtons(false);
        }
        if (fetchedData.data.ball_delivery_result == 'W') {
          setIsEditHavingWicket(true);
        }
        else {
          setIsEditHavingWicket(false);
        }
        setRunout_batsman_id(fetchedData.data.runout_batsman_id);
        setRunOutRunResult(fetchedData.data.run_out_ball_delivery_result);
        setSelectedBallType(fetchedData.data.ball_type)
        setFoot(fetchedData.data.bat_type_category)
        setBatType(fetchedData.data.bat_type)
        setBallResult(fetchedData.data.ball_delivery_result)
        setIsAppeal(fetchedData.data.is_appeal);
        setIsBatsmanUncomfortable(fetchedData.data.is_batsman_uncomfortable)
        setIsBeaten(fetchedData.data.is_beaten)
        setIsWicketTaking(fetchedData.data.is_wicket_taking)
        setBattingResult(fetchedData.data.batting_result)
        setBowlerType(fetchedData.data.ball_type_category)
        setCurrentBall(fetchedData.data.current_ball)
        setExtraBallResult(fetchedData.data.extra_ball_delivery_result);

        const refresh_timestamp = Date.now();
        setVideoSourcFile("https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/refresh.mp4?unique_token=" + refresh_timestamp);

        const timeoutId = setTimeout(() => {
          if (fetchedData.data.video_url == null) {
            setVideoSourcFile("");
          }
          else {
            const timestamp = Date.now();
            let dynamicVideoPath = fetchedData.data.video_url + "?unique_token=" + timestamp;
            setVideoSourcFile(dynamicVideoPath);
          }

        }, 1000);

        setPositionBatTypeBatInput({
          waghoon_wheel_position_bat_type: fetchedData.data.pitch_position_bat_type
        })

        getStrikerNonStrikerStatistics({
          match_id: navData.match_id,
          current_inning: innings,
          bat_striker_id: fetchedData.data.bat_striker_id,
          bat_nstriker_id: fetchedData.data.bat_nstriker_id
        }).then(async (data) => {
          if (data.bat_striker_info.batsman_detail == null) {
            setBatsmanType("Right Hand");
          }
          else if (data.bat_striker_info.batsman_detail.batting == 'NA' || data.bat_striker_info.batsman_detail.batting == '') {
            setBatsmanType("Right Hand");

          }
          else {
            setBatsmanType(data.bat_striker_info.batsman_detail.batting)

          }

          if ((fetchedData.data.bat_striker_id != null) && (fetchedData.data.bat_striker_id != '')) {
            setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
            setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
            setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
            setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
            setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
          }
          else {
            setTotalStrikerRun(0)
            setTotalStrikerBall(0)
            setTotalStrikerFourRun(0)
            setTotalStrikerSixRun(0)
            setStrikerStrikeRate(0)
          }

          if ((fetchedData.data.bat_nstriker_id != null) && (fetchedData.data.bat_nstriker_id != '')) {
            setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
            setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
            setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
            setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
            setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
          }
          else {
            setTotalnStrikerRun(0)
            setTotalnStrikerBall(0)
            setTotalnStrikerFourRun(0)
            setTotalnStrikerSixRun(0)
            setnStrikerStrikeRate(0)
          }

          const timeoutId = setTimeout(() => {
            setParentWagonMsgData(
              {
                parent: true,
                parent_batting_msg: fetchedData.data.batting_result
              }
            );

            setParentMarkerData(
              {
                parent: true,
                parent_selectedMarkerOption: 'pitch',
                parent_position_pitch_x: fetchedData.data.position_pitch_x,
                parent_position_pitch_y: fetchedData.data.position_pitch_y,
                parent_position_stump_x: fetchedData.data.position_stump_x,
                parent_position_stump_y: fetchedData.data.position_stump_y,
                parent_bowling_length: fetchedData.data.bowling_length,
                parent_bowling_result: fetchedData.data.bowling_result
              }
            );
          }, 1000);
        })
          .catch((e) => {
          });
      })
  }

  const handleSwitchInn1Ball = (value, switch_current_ball) => (e) => {
    e.preventDefault();
    setSelectType("")
    setSelectType(value)
    setSend_analysis_id(value)
    setShowOversAnalysis(false)
    const updateInn = updateInninginMaster({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 1
    })

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 1,
      current_ball: nextBall(switch_current_ball),
      analysis_id: value,
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (fetchedData) => {
        if (fetchedData.data == null) {
          if (innings == 2) {
            setBatsmanType("Right Hand")
            setBowlerType('Fast')
            setSelectedBallType("Straight");

            // bowler 
            setBowlerId('')
            setTotalBowlerOver(0)
            setTotalBowlerMaiden(0)
            setTotalBowlerBallWd(0)
            setEconomyBowler(0)
            setTotalBowlerExtraRuns(0)

            // striker 
            setStrikerId('')
            setTotalStrikerRun(0)
            setTotalStrikerBall(0)
            setTotalStrikerFourRun(0)
            setTotalStrikerSixRun(0)
            setStrikerStrikeRate(0)

            // n-striker 
            setNonStrikerId('')
            setTotalnStrikerRun(0)
            setTotalnStrikerBall(0)
            setTotalnStrikerFourRun(0)
            setTotalnStrikerSixRun(0)
            setnStrikerStrikeRate(0)

            const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
              navData.bat1_id, navData.match_id
            );

            const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
              navData.ball1_id, navData.match_id
            );

            let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
              let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
              if (matchingItem) {
                result.push({ ...item, ...matchingItem });
              } else {
                result.push(item);
              }
              return result;
            }, []);

            let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
              let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
              if (matchingItem) {
                result.push({ ...item, ...matchingItem });
              } else {
                result.push(item);
              }
              return result;
            }, []);

            if (battingTeamData?.status)
              setBattingTeamDataDetails(mergedBatArray);

            if (bowlingTeamData?.status)
              setBowlingTeamDataDetails(mergedBallArray);

            GetLatestThreeBallDetail({
              match_id: navData.match_id,
              current_inning: 1
            }).then(async (data) => {
              setlatest_three_ball_details(data.latest_three_ball_details)
            })
              .catch((e) => {
              });

            getLatestEighteenBallDetail({
              match_id: navData.match_id,
              current_inning: 1
            }).then(async (data) => {
              let arr_reverse = data.latest_eighteen_ball_details;
              let reversedArray = arr_reverse.slice().reverse();
              setlatestEighteenBall(reversedArray)
            })
              .catch((e) => {
              });

          }
          // else {
          //   if ((bowler_type == 'Off Spin') || (bowler_type == 'Leg Spin')) {
          //     setSelectedBallType("Off Spin");
          //   }
          //   else {
          //     setSelectedBallType("Straight");
          //   }
          // }

          setStartNewTime("");
          setDemoValue("");
          setVideoSourcFile("");
          setSend_analysis_id("");
          setAutoSixDigitNumberEdit("");
          setAutoSixDigitNumber("");
          localStorage.setItem("WideNoBallRandomNumber", "");

          if (innings == 2) {
            setParentMarkerData({
              parent: true,
              parent_selectedMarkerOption: 'pitch',
              parent_position_pitch_x: 0,
              parent_position_pitch_y: 0,
              parent_position_stump_x: 0,
              parent_position_stump_y: 0,
              parent_bowling_length: '',
              parent_bowling_result: ''
            });
            setParentWagonMsgData({
              parent: true,
              parent_batting_msg: ''
            });
            setFoot('Front Foot');
            setBatType('Cover Drive');
            setBallResult("0");
            setBattingResult("");
            setBowlingData({
              bowling_result: "",
              bowling_length: "",
            });
            setBowlingPitchData({
              position_pitch_x: 0,
              position_pitch_y: 0
            });
            setBowlingStumpData({
              position_stump_x: 0,
              position_stump_y: 0
            });
            setExtraBallResult("");
            setRunOutRunResult("");
            setCustomRuns(0)
            setWicketType("");
            setIsAppeal("");
            setIsBatsmanUncomfortable("");
            setIsBeaten("");
            setIsWicketTaking("");
          }


          setisNonDeliveryBallClick(true);
          setNonDeliveryCurrentBall(switch_current_ball);
          GetNonDeliveredBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: 1,
            current_ball: nextBall(switch_current_ball),
            analysis_id: value
          })
            .then(async (fetchedData) => {
              setShowRuns(fetchedData.analysisMasterData.total_combine_run)
              setShowOvers(parseFloat(switch_current_ball))
              setShowWickets(fetchedData.analysisMasterData.total_wickets)
              setInnings(1)
              setBattingTeamName(fetchedData.team_name.team_bat1.name)
              setBallingTeamName(fetchedData.team_name.team_ball1.name)
              if (fetchedData.analysisMasterData.inning1_end_match_over != 0) {
                setNextSubmitInning(true);
                setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
                setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
              }
              else {
                setNextSubmitInning(false);
                setEnd_match_inning(0);
                setEnd_match_inning1(0);
              }
            })
        }
        else {
          if (fetchedData.data.sixDigitNumber == "") {
            let updateRandomNumber = generateRandomSixDigitNumber();
            let update_random_analysis = {};
            update_random_analysis["id"] = fetchedData.data.id;
            update_random_analysis["sixDigitNumber"] = updateRandomNumber;

            const update_random_analysis_db_const = await update_random_analysis_db(update_random_analysis);
            setAutoSixDigitNumberEdit(updateRandomNumber);
          }
          else {
            setAutoSixDigitNumberEdit(fetchedData.data.sixDigitNumber);
            localStorage.setItem("WideNoBallRandomNumber", fetchedData.data.sixDigitNumber);
          }

          setisNonDeliveryBallClick(false);
          setNonDeliveryCurrentBall("");
          setCurrentBall(fetchedData.data.current_ball)
          setStartNewTime(fetchedData.data.start_time)
          setDemoValue(fetchedData.data.end_interval)
          setBattingTeamName(fetchedData.team_name.team_bat1.name)
          setBallingTeamName(fetchedData.team_name.team_ball1.name)

          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            fetchedData.data.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );
          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            fetchedData.data.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );
          let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
            let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
            let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (battingTeamData?.status)
            setBattingTeamDataDetails(mergedBatArray);

          if (bowlingTeamData?.status)
            setBowlingTeamDataDetails(mergedBallArray);

          setWicketType(fetchedData.data.wicket_type)
          setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs)
          setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball)
          setInnings(1)
          setShowRuns(fetchedData.analysisMasterData.total_combine_run)
          setShowOvers(fetchedData.analysisMasterData.heading_current_over)
          setShowWickets(fetchedData.analysisMasterData.total_wickets)
          setTotalOvers(fetchedData.analysisMasterData.overs)
          if ((fetchedData.data.bowler_id == null) || (fetchedData.data.bowler_id == '') || (fetchedData.data.bowler_id == 'Select Bowling Type')) {
            setBowlerId('')
            setTotalBowlerOver(0)
            setTotalBowlerMaiden(0)
            setTotalBowlerBallWd(0)
            setEconomyBowler(0)
            setTotalBowlerExtraRuns(0)
          }
          else {
            setBowlerId(fetchedData.data.bowler_id)
          }

          if ((fetchedData.data.bat_striker_id == null) || (fetchedData.data.bat_striker_id == '') || (fetchedData.data.bat_striker_id == 'Select Batting Type')) {
            setStrikerId('')
            setTotalStrikerRun(0)
            setTotalStrikerBall(0)
            setTotalStrikerFourRun(0)
            setTotalStrikerSixRun(0)
            setStrikerStrikeRate(0)
          }
          else {
            setStrikerId(fetchedData.data.bat_striker_id);
            getStrikerNonStrikerStatistics({
              match_id: navData.match_id,
              current_inning: innings,
              bat_striker_id: fetchedData.data.bat_striker_id,
              bat_nstriker_id: fetchedData.data.bat_nstriker_id
            }).then(async (data) => {
              setStrikerName(data.bat_striker_info.batsman_detail.full_name);
              setnStrikerName(data.bat_nstriker_info.batsman_detail.full_name);
            })
          }
          if ((fetchedData.data.bat_nstriker_id == null) || (fetchedData.data.bat_nstriker_id == '') || (fetchedData.data.bat_nstriker_id == 'Select Batting Type')) {
            setNonStrikerId('')
            setTotalnStrikerRun(0)
            setTotalnStrikerBall(0)
            setTotalnStrikerFourRun(0)
            setTotalnStrikerSixRun(0)
            setnStrikerStrikeRate(0)
          }
          else {
            setNonStrikerId(fetchedData.data.bat_nstriker_id)
          }

          if (fetchedData.data.wicket_type == 'runOut') {
            setShowRunOutButtons(true);
          }
          else {
            setShowRunOutButtons(false);
          }
          if (fetchedData.data.ball_delivery_result == 'W') {
            setIsEditHavingWicket(true);
          }
          else {
            setIsEditHavingWicket(false);
          }
          setRunout_batsman_id(fetchedData.data.runout_batsman_id);
          setRunOutRunResult(fetchedData.data.run_out_ball_delivery_result);
          setSelectedBallType(fetchedData.data.ball_type)
          setFoot(fetchedData.data.bat_type_category)
          setBatType(fetchedData.data.bat_type)
          setBallResult(fetchedData.data.ball_delivery_result)
          setIsAppeal(fetchedData.data.is_appeal);
          setIsBatsmanUncomfortable(fetchedData.data.is_batsman_uncomfortable)
          setIsBeaten(fetchedData.data.is_beaten)
          setIsWicketTaking(fetchedData.data.is_wicket_taking)
          setBattingResult(fetchedData.data.batting_result)

          const refresh_timestamp = Date.now();
          setVideoSourcFile("https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/refresh.mp4?unique_token=" + refresh_timestamp);

          const timeoutId = setTimeout(() => {

            if (fetchedData.data.video_url == null) {
              setVideoSourcFile("");
            }
            else {
              const timestamp = Date.now();
              let dynamicVideoPath = fetchedData.data.video_url + "?unique_token=" + timestamp;
              setVideoSourcFile(dynamicVideoPath);
            }
          }, 1000);

          setExtraBallResult(fetchedData.data.extra_ball_delivery_result);
          setBatsmanType(fetchedData.data.waghoon_wheel_position_bat_type)

          if (fetchedData.analysisMasterData.inning1_end_match_over != 0) {
            setNextSubmitInning(true);
            setEnd_match_inning(fetchedData.analysisMasterData.inning1_end_match_over);
            setEnd_match_inning1(fetchedData.analysisMasterData.inning1_end_match_over);
          }
          else {
            setNextSubmitInning(false);
            setEnd_match_inning(0);
            setEnd_match_inning1(0);
          }

          GetSingleBowlerStatistics({
            match_id: navData.match_id,
            current_inning: 1,
            bowler_id: fetchedData.data.bowler_id

          }).then(async (data) => {
            setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
            setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
            setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
            setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
            setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
            setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
          })
            .catch((e) => {
            });

          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: 1,
            bat_striker_id: fetchedData.data.bat_striker_id,
            bat_nstriker_id: fetchedData.data.bat_nstriker_id
          }).then(async (data) => {

            if (data.bat_striker_info.batsman_detail == null) {
              setBatsmanType("Right Hand")
            }
            else if (data.bat_striker_info.batsman_detail.batting == 'NA' || data.bat_striker_info.batsman_detail.batting == '') {
              setBatsmanType("Right Hand")
            }
            else {
              setBatsmanType(data.bat_striker_info.batsman_detail.batting)
            }
            setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
            setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
            setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
            setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
            setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
            setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
            setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
            setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
            setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
            setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)

            const timeoutId = setTimeout(() => {
              setParentWagonMsgData(
                {
                  parent: true,
                  parent_batting_msg: fetchedData.data.batting_result
                }
              );

              setParentMarkerData(
                {
                  parent: true,
                  parent_selectedMarkerOption: 'pitch',
                  parent_position_pitch_x: fetchedData.data.position_pitch_x,
                  parent_position_pitch_y: fetchedData.data.position_pitch_y,
                  parent_position_stump_x: fetchedData.data.position_stump_x,
                  parent_position_stump_y: fetchedData.data.position_stump_y,
                  parent_bowling_length: fetchedData.data.bowling_length,
                  parent_bowling_result: fetchedData.data.bowling_result
                }
              );
            }, 1000);
          })
            .catch((e) => {
            });

          GetLatestThreeBallDetail({
            match_id: navData.match_id,
            current_inning: 1
          }).then(async (data) => {
            setlatest_three_ball_details(data.latest_three_ball_details)
          })
            .catch((e) => {
            });
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 1
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
        let header_name;
        header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat1 + '_' + fetchedData.dynamic_team_name.match_date;
        setHeadingCommonName(header_name);
        setIsUseEffectCall(false);
        setcamHeader(header_name);
        localStorage.setItem('header_name', header_name);
        const myRec = camHeader;
        const docRef = db.collection('StartStopCamCollections').doc(myRec);
        const LivedocRef = db.collection('StartStopLiveCollections').doc(myRec);
        // Detete
        docRef.delete()
          .then(() => {
            LivedocRef.delete()
            // ADD/SET
            const docKey = header_name;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: navData.Camera1User.id.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: navData.Camera2User.id.toString()
              }
            }
            startStopCamCollection.doc(docKey).set(doc).then(() => {
            })
          })
          .catch((error) => {
            console.error('Error removing document:', error);
          });
        LivedocRef.delete()
          .then(() => {
            // ADD/SET
            const docKey = header_name;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: navData.Camera1User.id.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: navData.Camera2User.id.toString()
              }
            }
            // It is use for Live Streaming
            startStopLiveCollection.doc(docKey).set(doc).then(() => {
            });
          })
      })
  }

  const handleSwitchInn2Ball = (value, switch_current_ball) => (e) => {

    e.preventDefault();
    setSelectType("")
    setSelectType(value)
    setSend_analysis_id(value)
    setShowOversAnalysis(false)
    const updateInn = updateInninginMaster({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 2
    })

    getBallAnalysis({
      tournament_id: navData.tournament_id,
      match_id: navData.match_id,
      current_inning: 2,
      current_ball: nextBall(switch_current_ball),
      analysis_id: value,
      tournament_name: tournamentName,
      match_name: matchName,
      match_date: matchDate,
      team_bat1: inn1BatTeamName,
      team_bat2: inn2BatTeamName
    })
      .then(async (fetchedData) => {
        if (fetchedData.data == null) {
          if (innings == 1) {
            setBatsmanType("Right Hand")
            setBowlerType('Fast')
            setSelectedBallType("Straight");

            // bowler 
            setBowlerId('')
            setTotalBowlerOver(0)
            setTotalBowlerMaiden(0)
            setTotalBowlerBallWd(0)
            setEconomyBowler(0)
            setTotalBowlerExtraRuns(0)

            // striker 
            setStrikerId('')
            setTotalStrikerRun(0)
            setTotalStrikerBall(0)
            setTotalStrikerFourRun(0)
            setTotalStrikerSixRun(0)
            setStrikerStrikeRate(0)

            setNonStrikerId('')
            setTotalnStrikerRun(0)
            setTotalnStrikerBall(0)
            setTotalnStrikerFourRun(0)
            setTotalnStrikerSixRun(0)
            setnStrikerStrikeRate(0)

            const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
              navData.bat2_id, navData.match_id
            );

            const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
              navData.ball2_id, navData.match_id
            );

            let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
              let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
              if (matchingItem) {
                result.push({ ...item, ...matchingItem });
              } else {
                result.push(item);
              }
              return result;
            }, []);

            let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
              let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
              if (matchingItem) {
                result.push({ ...item, ...matchingItem });
              } else {
                result.push(item);
              }
              return result;
            }, []);

            if (battingTeamData?.status)
              setBattingTeamDataDetails(mergedBatArray);

            if (bowlingTeamData?.status)
              setBowlingTeamDataDetails(mergedBallArray);

            GetLatestThreeBallDetail({
              match_id: navData.match_id,
              current_inning: 2
            }).then(async (data) => {
              setlatest_three_ball_details(data.latest_three_ball_details)
            })
              .catch((e) => {
              });

            getLatestEighteenBallDetail({
              match_id: navData.match_id,
              current_inning: 2
            }).then(async (data) => {
              let arr_reverse = data.latest_eighteen_ball_details;
              let reversedArray = arr_reverse.slice().reverse();
              setlatestEighteenBall(reversedArray)
            })
              .catch((e) => {
              });

          }
          // else {
          //   if ((bowler_type == 'Off Spin') || (bowler_type == 'Leg Spin')) {
          //     setSelectedBallType("Off Spin");
          //   }
          //   else {
          //     setSelectedBallType("Straight");
          //   }
          // }

          setStartNewTime("");
          setDemoValue("");
          setVideoSourcFile("");
          setSend_analysis_id("");
          setAutoSixDigitNumberEdit("");
          setAutoSixDigitNumber("");
          localStorage.setItem("WideNoBallRandomNumber", "");

          if (innings == 1) {
            setParentMarkerData({
              parent: true,
              parent_selectedMarkerOption: 'pitch',
              parent_position_pitch_x: 0,
              parent_position_pitch_y: 0,
              parent_position_stump_x: 0,
              parent_position_stump_y: 0,
              parent_bowling_length: '',
              parent_bowling_result: ''
            });
            setParentWagonMsgData({
              parent: true,
              parent_batting_msg: ''
            });
            setFoot('Front Foot');
            setBatType('Cover Drive');
            setBallResult("0");
            setBattingResult("");
            setBowlingData({
              bowling_result: "",
              bowling_length: "",
            });
            setBowlingPitchData({
              position_pitch_x: 0,
              position_pitch_y: 0
            });
            setBowlingStumpData({
              position_stump_x: 0,
              position_stump_y: 0
            });
            setExtraBallResult("");
            setRunOutRunResult("");
            setCustomRuns(0)
            setWicketType("");
            setIsAppeal("");
            setIsBatsmanUncomfortable("");
            setIsBeaten("");
            setIsWicketTaking("");
          }

          setisNonDeliveryBallClick(true);
          setNonDeliveryCurrentBall(switch_current_ball);
          GetNonDeliveredBallAnalysis({
            tournament_id: navData.tournament_id,
            match_id: navData.match_id,
            current_inning: 2,
            current_ball: nextBall(switch_current_ball),
            analysis_id: value
          })
            .then(async (fetchedData) => {
              setShowRuns(fetchedData.analysisMasterData.total_combine_run)
              setShowOvers(parseFloat(switch_current_ball))
              setShowWickets(fetchedData.analysisMasterData.total_wickets)
              setInnings(2)
              setBattingTeamName(fetchedData.team_name.team_bat2.name)
              setBallingTeamName(fetchedData.team_name.team_ball2.name)
              if (fetchedData.analysisMasterData.inning2_end_match_over != 0) {
                setNextSubmitInning(true);
                setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over);
                setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over);
              } else {
                setNextSubmitInning(false);
                setEnd_match_inning(0);
                setEnd_match_inning2(0);
              }
            })
        }
        else {

          if (fetchedData.data.sixDigitNumber == "") {
            let updateRandomNumber = generateRandomSixDigitNumber();
            let update_random_analysis = {};
            update_random_analysis["id"] = fetchedData.data.id;
            update_random_analysis["sixDigitNumber"] = updateRandomNumber;

            const update_random_analysis_db_const = await update_random_analysis_db(update_random_analysis);
            setAutoSixDigitNumberEdit(updateRandomNumber);
          }
          else {
            setAutoSixDigitNumberEdit(fetchedData.data.sixDigitNumber);
            localStorage.setItem("WideNoBallRandomNumber", fetchedData.data.sixDigitNumber);
          }


          setisNonDeliveryBallClick(false);
          setNonDeliveryCurrentBall("");
          setCurrentBall(fetchedData.data.current_ball)
          setStartNewTime(fetchedData.data.start_time)
          setDemoValue(fetchedData.data.end_interval)
          setBattingTeamName(fetchedData.team_name.team_bat2.name)
          setBallingTeamName(fetchedData.team_name.team_ball2.name)

          const battingTeamData = await GetAllTeamMatchMembersListByTeamID(
            fetchedData.data.current_inning === 1 ? navData.bat1_id : navData.bat2_id, navData.match_id
          );

          const bowlingTeamData = await GetAllTeamMatchMembersListByTeamID(
            fetchedData.data.current_inning === 1 ? navData.ball1_id : navData.ball2_id, navData.match_id
          );

          let mergedBatArray = battingTeamData.team_members.reduce((result, item) => {
            let matchingItem = battingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          let mergedBallArray = bowlingTeamData.team_members.reduce((result, item) => {
            let matchingItem = bowlingTeamData.all_team_members_with_details.find(element => element.id === item.id);
            if (matchingItem) {
              result.push({ ...item, ...matchingItem });
            } else {
              result.push(item);
            }
            return result;
          }, []);

          if (battingTeamData?.status)
            setBattingTeamDataDetails(mergedBatArray);

          if (bowlingTeamData?.status)
            setBowlingTeamDataDetails(mergedBallArray);

          setWicketType(fetchedData.data.wicket_type)
          setSecondInnNeedRuns(fetchedData.collect_score_need_sec_inning.required_runs)
          setSecondInnRemainingBalls(fetchedData.collect_score_need_sec_inning.total_sec_inning_remaining_ball)
          setInnings(2)
          setShowRuns(fetchedData.analysisMasterData.total_combine_run)
          setShowOvers(fetchedData.analysisMasterData.heading_current_over)
          setShowWickets(fetchedData.analysisMasterData.total_wickets)
          setTotalOvers(fetchedData.analysisMasterData.overs)
          if (fetchedData.data.wicket_type == 'runOut') {
            setShowRunOutButtons(true);
          }
          else {
            setShowRunOutButtons(false);
          }
          if (fetchedData.data.ball_delivery_result == 'W') {
            setIsEditHavingWicket(true);
          }
          else {
            setIsEditHavingWicket(false);
          }
          setRunout_batsman_id(fetchedData.data.runout_batsman_id);
          setRunOutRunResult(fetchedData.data.run_out_ball_delivery_result);

          if ((fetchedData.data.bowler_id == null) || (fetchedData.data.bowler_id == '') || (fetchedData.data.bowler_id == 'Select Bowling Type')) {
            setBowlerId('')
            setTotalBowlerOver(0)
            setTotalBowlerMaiden(0)
            setTotalBowlerBallWd(0)
            setEconomyBowler(0)
            setTotalBowlerExtraRuns(0)
          }
          else {
            setBowlerId(fetchedData.data.bowler_id)
          }

          if ((fetchedData.data.bat_striker_id == null) || (fetchedData.data.bat_striker_id == '') || (fetchedData.data.bat_striker_id == 'Select Batting Type')) {
            setStrikerId('')
            setTotalStrikerRun(0)
            setTotalStrikerBall(0)
            setTotalStrikerFourRun(0)
            setTotalStrikerSixRun(0)
            setStrikerStrikeRate(0)
          }
          else {
            setStrikerId(fetchedData.data.bat_striker_id)
          }
          if ((fetchedData.data.bat_nstriker_id == null) || (fetchedData.data.bat_nstriker_id == '') || (fetchedData.data.bat_nstriker_id == 'Select Batting Type')) {
            setNonStrikerId('')
            setTotalnStrikerRun(0)
            setTotalnStrikerBall(0)
            setTotalnStrikerFourRun(0)
            setTotalnStrikerSixRun(0)
            setnStrikerStrikeRate(0)

          }
          else {
            setNonStrikerId(fetchedData.data.bat_nstriker_id)
          }
          setSelectedBallType(fetchedData.data.ball_type)
          setFoot(fetchedData.data.bat_type_category)
          setBatType(fetchedData.data.bat_type)
          setBallResult(fetchedData.data.ball_delivery_result)
          setIsAppeal(fetchedData.data.is_appeal);
          setIsBatsmanUncomfortable(fetchedData.data.is_batsman_uncomfortable)
          setIsBeaten(fetchedData.data.is_beaten)
          setIsWicketTaking(fetchedData.data.is_wicket_taking)
          setBattingResult(fetchedData.data.batting_result)

          const refresh_timestamp = Date.now();
          setVideoSourcFile("https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/refresh.mp4?unique_token=" + refresh_timestamp);

          const timeoutId = setTimeout(() => {
            if (fetchedData.data.video_url == null) {
              setVideoSourcFile("");
            }
            else {
              const timestamp = Date.now();
              let dynamicVideoPath = fetchedData.data.video_url + "?unique_token=" + timestamp;
              setVideoSourcFile(dynamicVideoPath);
            }
          }, 1000);

          setExtraBallResult(fetchedData.data.extra_ball_delivery_result);

          if (fetchedData.analysisMasterData.inning2_end_match_over != 0) {
            setNextSubmitInning(true);
            setEnd_match_inning(fetchedData.analysisMasterData.inning2_end_match_over);
            setEnd_match_inning2(fetchedData.analysisMasterData.inning2_end_match_over);
          } else {
            setNextSubmitInning(false);
            setEnd_match_inning(0);
            setEnd_match_inning2(0);
          }

          GetSingleBowlerStatistics({
            match_id: navData.match_id,
            current_inning: 2,
            bowler_id: fetchedData.data.bowler_id

          }).then(async (data) => {
            setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
            setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
            setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
            setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
            setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
            setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)

          })
            .catch((e) => {
            });

          getStrikerNonStrikerStatistics({
            match_id: navData.match_id,
            current_inning: 2,
            bat_striker_id: fetchedData.data.bat_striker_id,
            bat_nstriker_id: fetchedData.data.bat_nstriker_id
          }).then(async (data) => {
            if (data.bat_striker_info.batsman_detail.batting == 'NA' || data.bat_striker_info.batsman_detail.batting == '') {
              setBatsmanType("Right Hand")
            }
            else {
              setBatsmanType(data.bat_striker_info.batsman_detail.batting)
            }
            setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
            setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
            setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
            setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
            setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)

            setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run)
            setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
            setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
            setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
            setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)

            const timeoutId = setTimeout(() => {
              setParentWagonMsgData(
                {
                  parent: true,
                  parent_batting_msg: fetchedData.data.batting_result
                }
              );

              setParentMarkerData(
                {
                  parent: true,
                  parent_selectedMarkerOption: 'pitch',
                  parent_position_pitch_x: fetchedData.data.position_pitch_x,
                  parent_position_pitch_y: fetchedData.data.position_pitch_y,
                  parent_position_stump_x: fetchedData.data.position_stump_x,
                  parent_position_stump_y: fetchedData.data.position_stump_y,
                  parent_bowling_length: fetchedData.data.bowling_length,
                  parent_bowling_result: fetchedData.data.bowling_result
                }
              );
            }, 1000);

          })
            .catch((e) => {
            });

          GetLatestThreeBallDetail({
            match_id: navData.match_id,
            current_inning: 2
          }).then(async (data) => {
            setlatest_three_ball_details(data.latest_three_ball_details)
          })
            .catch((e) => {
            });
          getLatestEighteenBallDetail({
            match_id: navData.match_id,
            current_inning: 2
          }).then(async (data) => {
            let arr_reverse = data.latest_eighteen_ball_details;
            let reversedArray = arr_reverse.slice().reverse();
            setlatestEighteenBall(reversedArray)
          })
            .catch((e) => {
            });
        }
        let header_name;
        header_name = fetchedData.dynamic_team_name.tournament_name + '_' + fetchedData.dynamic_team_name.match_name + '_' + fetchedData.dynamic_team_name.team_bat2 + '_' + fetchedData.dynamic_team_name.match_date;
        setHeadingCommonName(header_name);
        setIsUseEffectCall(false);
        setcamHeader(header_name);
        localStorage.setItem('header_name', header_name);
        const myRec = camHeader;
        const docRef = db.collection('StartStopCamCollections').doc(myRec);
        const LivedocRef = db.collection('StartStopLiveCollections').doc(myRec);
        // Detete
        docRef.delete()
          .then(() => {
            LivedocRef.delete()
            // ADD/SET
            const docKey = header_name;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: navData.Camera1User.id.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: navData.Camera2User.id.toString()
              }
            }
            startStopCamCollection.doc(docKey).set(doc).then(() => {
            })
          })
          .catch((error) => {
            console.error('Error removing document:', error);
          });
        LivedocRef.delete()
          .then(() => {
            // ADD/SET
            const docKey = header_name;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: navData.Camera1User.id.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: navData.Camera2User.id.toString()
              }
            }
            // It is use for Live Streaming
            startStopLiveCollection.doc(docKey).set(doc).then(() => {
            });
          })

      })
  }

  const [visibleInn2Records, setVisibleInn2Records] = useState(30);
  const handleLoadmoreInn2Popup = (e) => {
    e.preventDefault();
    setVisibleInn2Records(visibleInn2Records + 30);
  }

  const handleLoadmoreInn2 = (e) => {
    e.preventDefault();

    const lk = parseInt(inn2limit) + 30;
    getListOfFirstTotalOver({
      match_id: navData.match_id,
      current_inning: 2,
      next_record_from: 1,
      record_limit: lk
    }).then(async (data) => {
      setinn2Limit(lk)
      setCollectOverAnalysisInn2(data.collect_over_decimal)
      const array = Object.keys(data.collect_over_decimal);
      setTotalOverAnalysisInn2(array)
      if (SecondInnTotalRec == array.length) {
        setIsSecondLoadmore(false)
      }
      setSecondInnTotalRec(array.length)

    })
      .catch((e) => {
      });
  }

  const [total_bowler_over, setTotalBowlerOver] = React.useState(0);
  const [total_bowler_maiden, setTotalBowlerMaiden] = React.useState(0);
  const [total_bowler_ball_wd, setTotalBowlerBallWd] = React.useState(0);
  const [economy_bowler, setEconomyBowler] = React.useState(0);
  const [bowler_type, setBowlerType] = React.useState("Fast");
  const [total_bowler_extra_runs, setTotalBowlerExtraRuns] = React.useState(0);
  const handleBolwerDetails = (e) => {
    setBowlerId(e.target.value)
    if (e.target.value == '') {
      setTotalBowlerOver(0)
      setTotalBowlerMaiden(0)
      setTotalBowlerBallWd(0)
      setEconomyBowler(0)
      setTotalBowlerExtraRuns(0)
    }
    else {
      GetSingleBowlerStatistics({
        match_id: navData.match_id,
        current_inning: innings,
        bowler_id: e.target.value
      }).then(async (data) => {
        if ((data.final_bowler_statitics[0].bowler_detail.bowling == 'NA') || (data.final_bowler_statitics[0].bowler_detail.bowling == '') || (data.final_bowler_statitics[0].bowler_detail.bowling == null) || (data.final_bowler_statitics[0].bowler_detail.bowling == "Select Bowling Type")) {

          let match_analysis_log = {};
          match_analysis_log["analysis_master_id"] = navData.id;
          match_analysis_log["tournament_id"] = navData.tournament_id;
          match_analysis_log["match_id"] = navData.match_id;
          match_analysis_log["current_inning"] = innings;
          match_analysis_log["overs"] = totalOvers;
          match_analysis_log["current_ball"] = current_ball;
          match_analysis_log["type_of_error"] = "Bowler Handedness Unavailable";
          match_analysis_log["error_description"] = "Warning: You have not selected Bowler's handedness. Kindly add to continue otherwise bowler options will not load.";
          match_analysis_log["input_data"] = {
            bowling: data.final_bowler_statitics[0].bowler_detail.bowling
          };

          const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

          Swal.fire({
            icon: "info",
            title: "",
            text: "Warning: You have not selected Bowler's handedness. Kindly add to continue otherwise bowler options will not load."
          });
          setBowlerId('')
        }
        else {

          setBowlerType(data.final_bowler_statitics[0].bowler_detail.bowling)
        }
        if ((data.final_bowler_statitics[0].bowler_detail.bowling == 'Off Spin') || (data.final_bowler_statitics[0].bowler_detail.bowling == 'Leg Spin')) {
          setSelectedBallType("Off Spin")
        }
        else {
          setSelectedBallType("Straight")
        }
        setTotalBowlerOver(data.final_bowler_statitics[0].total_bowler_over)
        setTotalBowlerMaiden(data.final_bowler_statitics[0].total_bowler_maiden)
        setTotalBowlerBallWd(data.final_bowler_statitics[0].total_bowler_ball_wd)
        setEconomyBowler(data.final_bowler_statitics[0].economy_bowler)
        setTotalBowlerExtraRuns(data.final_bowler_statitics[0].total_bowler_extra_runs)
      })
        .catch((e) => {

        });
    }
  }

  const resetStrikerDetails = () => {
    setSelectedVideoURLOption("");
    setParentWagonMsgData(
      {
        parent: true,
        parent_batting_msg: ''
      }
    );

    setPositionBatType({
      pitch_position_bat_type: "Right"
    })

    setPositionBatTypeBatInput({
      waghoon_wheel_position_bat_type: "Right"
    })
    setBallType({
      category: "",
      type: ""
    });

    setSelectedSpinBowlingOption("front_foot");
    setIsAppeal("");
    setIsBatsmanUncomfortable("");
    setIsBeaten("");
    setIsWicketTaking("");
    setShowRunOutButtons(false);
    setRunOutRunResult("");
    setBallResult('0');
    setExtraBallResult('');
    setWicketType("");
  };

  const [batsmanType, setBatsmanType] = React.useState("Right Hand");
  const [total_striker_run, setTotalStrikerRun] = React.useState(0);
  const [total_striker_ball, setTotalStrikerBall] = React.useState(0);
  const [total_striker_four_run, setTotalStrikerFourRun] = React.useState(0);
  const [total_striker_six_run, setTotalStrikerSixRun] = React.useState(0);
  const [striker_strike_rate, setStrikerStrikeRate] = React.useState(0);
  const [resetPitchStumpMsg, setResetPitchStumpMsg] = React.useState(false);

  const handleStrikerDetails = async (e) => {
    setRunout_batsman_id(e.target.value);

    if (nonStrikerId != e.target.value) {
      setbattingBowlingImgLoader(true)
      setStrikerId(e.target.value)
      resetStrikerDetails()
      if (e.target.value == '') {
        setTotalStrikerRun(0);
        setTotalStrikerBall(0);
        setTotalStrikerFourRun(0);
        setTotalStrikerSixRun(0);
        setStrikerStrikeRate(0);
        setbattingBowlingImgLoader(false);
      }
      else {
        getStrikerNonStrikerStatistics({
          match_id: navData.match_id,
          current_inning: innings,
          bat_striker_id: e.target.value,
          bat_nstriker_id: null
        }).then(async (data) => {
          setStrikerName(data.bat_striker_info.batsman_detail.full_name)
          if ((data.bat_striker_info.batsman_detail.batting == 'NA') || (data.bat_striker_info.batsman_detail.batting == '') || (data.bat_striker_info.batsman_detail.batting == null) || (data.bat_striker_info.batsman_detail.batting == 'Select Batting Type')) {
            let match_analysis_log = {};
            match_analysis_log["analysis_master_id"] = navData.id;
            match_analysis_log["tournament_id"] = navData.tournament_id;
            match_analysis_log["match_id"] = navData.match_id;
            match_analysis_log["current_inning"] = innings;
            match_analysis_log["overs"] = totalOvers;
            match_analysis_log["current_ball"] = current_ball;
            match_analysis_log["type_of_error"] = "Batsman Handedness Unavailable";
            match_analysis_log["error_description"] = "Warning: You have not selected Batsman's handedness. Kindly add to continue otherwise pitch map image will not load.";
            match_analysis_log["input_data"] = {
              batting: data.bat_striker_info.batsman_detail.batting
            };

            const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

            Swal.fire({
              icon: "info",
              title: "",
              text: "Warning: You have not selected Batsman's handedness. Kindly add to continue otherwise pitch map image will not load."
            });
            setStrikerId("")
          }
          else {
            setBatsmanType(data.bat_striker_info.batsman_detail.batting)

            if (data.bat_striker_info.batsman_detail.batting == "Left Hand") {
              setPositionBatType({
                pitch_position_bat_type: "Left"
              });
            }
            else {
              setPositionBatType({
                pitch_position_bat_type: "Right"
              });
            }
          }
          setTotalStrikerRun(data.bat_striker_info.total_batsman_run + data.bat_striker_info.total_batsman_no_ball_runs + data.bat_striker_info.total_batsman_run_out_runs)
          setTotalStrikerBall(data.bat_striker_info.total_batsman_ball)
          setTotalStrikerFourRun(data.bat_striker_info.total_batsman_four_run)
          setTotalStrikerSixRun(data.bat_striker_info.total_batsman_six_run)
          setStrikerStrikeRate(data.bat_striker_info.batsman_strike_rate)
          setbattingBowlingImgLoader(false)
        })
          .catch((e) => {

          });
      }
    }
    else {
      let match_analysis_log = {};
      match_analysis_log["analysis_master_id"] = navData.id;
      match_analysis_log["tournament_id"] = navData.tournament_id;
      match_analysis_log["match_id"] = navData.match_id;
      match_analysis_log["current_inning"] = innings;
      match_analysis_log["overs"] = totalOvers;
      match_analysis_log["current_ball"] = current_ball;
      match_analysis_log["type_of_error"] = "Player Already Selected";
      match_analysis_log["error_description"] = "Warning: Already selected as non-striker.";
      match_analysis_log["input_data"] = {
        nonStrikerId: nonStrikerId,
        current_player_id: e.target.value
      };

      const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

      Swal.fire({
        icon: "info",
        title: "",
        text: "Warning: Already selected as non-striker."
      });
      setbattingBowlingImgLoader(false);
    }
  }

  const [total_nstriker_run, setTotalnStrikerRun] = React.useState(0);
  const [total_nstriker_ball, setTotalnStrikerBall] = React.useState(0);
  const [total_nstriker_four_run, setTotalnStrikerFourRun] = React.useState(0);
  const [total_nstriker_six_run, setTotalnStrikerSixRun] = React.useState(0);
  const [nstriker_strike_rate, setnStrikerStrikeRate] = React.useState(0);
  const [strikerName, setStrikerName] = React.useState('');
  const [nstrikerName, setnStrikerName] = React.useState('');

  const handleNStrikerDetails = async (e) => {
    if (strikerId != e.target.value) {
      setNonStrikerId(e.target.value)
      if (e.target.value == '') {
        setTotalnStrikerRun(0)
        setTotalnStrikerBall(0)
        setTotalnStrikerFourRun(0)
        setTotalnStrikerSixRun(0)
        setnStrikerStrikeRate(0)
      }
      else {
        getStrikerNonStrikerStatistics({
          match_id: navData.match_id,
          current_inning: innings,
          bat_striker_id: null,
          bat_nstriker_id: e.target.value
        }).then(async (data) => {
          if ((data.bat_nstriker_info.batsman_detail.batting == 'NA') || (data.bat_nstriker_info.batsman_detail.batting == '') || (data.bat_nstriker_info.batsman_detail.batting == null) || (data.bat_nstriker_info.batsman_detail.batting == 'Select Batting Type')) {
            Swal.fire({
              icon: "info",
              title: "",
              text: "Warning: You have not selected Batsman's handedness. Kindly add to continue otherwise pitch map image will not load."
            });
            setNonStrikerId("")
          }
          setnStrikerName(data.bat_nstriker_info.batsman_detail.full_name)
          setTotalnStrikerRun(data.bat_nstriker_info.total_batsman_run + data.bat_nstriker_info.total_batsman_no_ball_runs + data.bat_nstriker_info.total_batsman_run_out_runs)
          setTotalnStrikerBall(data.bat_nstriker_info.total_batsman_ball)
          setTotalnStrikerFourRun(data.bat_nstriker_info.total_batsman_four_run)
          setTotalnStrikerSixRun(data.bat_nstriker_info.total_batsman_six_run)
          setnStrikerStrikeRate(data.bat_nstriker_info.batsman_strike_rate)
        })
          .catch((e) => {
          });
      }
    }
    else {
      let match_analysis_log = {};
      match_analysis_log["analysis_master_id"] = navData.id;
      match_analysis_log["tournament_id"] = navData.tournament_id;
      match_analysis_log["match_id"] = navData.match_id;
      match_analysis_log["current_inning"] = innings;
      match_analysis_log["overs"] = totalOvers;
      match_analysis_log["current_ball"] = current_ball;
      match_analysis_log["type_of_error"] = "Player Already Selected";
      match_analysis_log["error_description"] = "Warning: Already selected as striker.";
      match_analysis_log["input_data"] = {
        strikerId: strikerId,
        current_player_id: e.target.value
      };

      const add_error_log_match_analysis = await addErrorLogMatchAnalysis(match_analysis_log);

      Swal.fire({
        icon: "info",
        title: "",
        text: "Warning: Already selected as striker."
      });
    }
  }

  const [inn1BatTeamName, setinn1BatTeamName] = React.useState('')
  const [inn2BatTeamName, setinn2BatTeamName] = React.useState('')
  const [customRunsModal, setCustomRunsModal] = React.useState(false);

  const OpenCustomRunsModal = () => {
    setCustomRunsModal(true)
  }

  const [customRuns, setCustomRuns] = React.useState(0)
  const [isCustomRuns, setIsCustomRuns] = React.useState(false)
  const handleCustomRuns = (val) => (e) => {
    e.preventDefault();
    setCustomRuns(e.target.value);
  };

  const handleCustomRunsModalSubmit = (e) => {
    e.preventDefault();
    setBallResult(customRuns)
    setCustomRunsModal(false)
    setIsCustomRuns(true)
  }

  const [showColorCode, setShowColorCode] = React.useState(false)
  const [showRunoutModalWindow, setShowRunoutModalWindow] = React.useState(false)
  const [runout_batsman_id, setRunout_batsman_id] = React.useState(null)
  const handleRunout = (e) => {
    setRunout_batsman_id(e.target.value)
    setShowRunoutModalWindow(false)
  }

  const [showManOfTheMatch, setShowManOfTheMatch] = React.useState(false)
  const [PromicingPlayerListTeamA, setPromicingPlayerListTeamA] = React.useState([])
  const [PromicingPlayerListTeamB, setPromicingPlayerListTeamB] = React.useState([])
  const [checkedItemsTeam, setCheckedItemsTeam] = React.useState([]);

  const handleManOfTheMatch = (e) => {

    getPromicingPlayerList({
      analysis_master_id: navData.id,

    }).then(async (data) => {
      setPromicingPlayerListTeamA(data.data[0].team_first_detail)
      setPromicingPlayerListTeamB(data.data[1].team_second_detail)
      let mergeArr = [];
      for (const item of data.data[0].team_first_detail) {
        if (item.is_promicing_player == true) {
          let single_promicing_detail = {};
          single_promicing_detail["analysis_master_id"] = navData.id;
          single_promicing_detail["team_id"] = item.teamId;
          single_promicing_detail["user_id"] = item.userId;
          mergeArr.push(single_promicing_detail);
        }
      }

      for (const item of data.data[1].team_second_detail) {
        if (item.is_promicing_player == true) {
          let single_promicing_detail = {};
          single_promicing_detail["analysis_master_id"] = navData.id;
          single_promicing_detail["team_id"] = item.teamId;
          single_promicing_detail["user_id"] = item.userId;
          mergeArr.push(single_promicing_detail);
        }
      }
      setCheckedItemsTeam(mergeArr)
    })
      .catch((e) => {
      });

    setShowManOfTheMatch(true)
  }

  const handleToggle = (e, user_id, team_id, index, teamType) => {
    if (teamType == 'teamA') {
      let TeamA = PromicingPlayerListTeamA;
      TeamA[index]["is_promicing_player"] = e.target.checked;
      setPromicingPlayerListTeamA(TeamA);
    }
    else {
      let TeamB = PromicingPlayerListTeamB;
      TeamB[index]["is_promicing_player"] = e.target.checked;
      setPromicingPlayerListTeamB(TeamB);
    }

    if (e.target.checked) {
      setCheckedItemsTeam([...checkedItemsTeam, {
        analysis_master_id: navData.id,
        team_id: team_id,
        user_id: user_id
      }]);
    } else {
      setCheckedItemsTeam(checkedItemsTeam.filter((checkedItemTeam) => checkedItemTeam.user_id !== user_id));
    }

  }

  const submitManOfTheMatch = () => {
    if (checkedItemsTeam.length != 0) {
      AddPromicingPlayerList(checkedItemsTeam).then(async (data) => {
        setShowManOfTheMatch(false)
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Promising player added successfully'
        });
      })
        .catch((e) => {
        });

    } else {
      Swal.fire({
        icon: "info",
        title: "",
        text: "Select atleast one player."
      });
    }
  }

  const handleBattingBowllingPromissing = async (user_id, team_id, is_promising_player, index, batting_bowling_type) => {
    try {
      const data = {
        analysis_master_id: navData.id,
        team_id: team_id,
        user_id: user_id,
        batting_bowling_type: batting_bowling_type,
        is_promising_player: !is_promising_player,
      };
      // Call the API to add or remove bookmark
      const response = await AddPromicingBatsman(data);
      if (response && response.status) {

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.msg,
        });
        if (batting_bowling_type == 'batting') {
          inn1ScoreboardBatting[index].is_promising_player = !is_promising_player
        } else {
          inn1ScoreboardBowling[index].is_promising_player = !is_promising_player
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating bookmark status.',
      });
    }
  };


  const handleBattingBowllingPromissingSecond = async (user_id, team_id, is_promising_player, index, batting_bowling_type) => {
    try {
      const data = {
        analysis_master_id: navData.id,
        team_id: team_id,
        user_id: user_id,
        batting_bowling_type: batting_bowling_type,
        is_promising_player: !is_promising_player,
      };
      // Call the API to add or remove bookmark
      const response = await AddPromicingBatsman(data);
      if (response && response.status) {

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.msg,
        });
        if (batting_bowling_type == 'batting') {
          inn2ScoreboardBatting[index].is_promising_player = !is_promising_player
        } else {
          inn2ScoreboardBowling[index].is_promising_player = !is_promising_player
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating bookmark status.',
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSwitchSubmit}>
        {showSwitch ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto ">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <span style={{ margin: '7px' }}>
                      <Button style={{ height: "25px", width: '42px', marginRight: '2px', borderRadius: '15px' }}
                        variant={selectInn == 'inn_1' ? "contained" : "outlined"}
                        onClick={handleSwitchInning("inn_1")}
                      >inn1</Button>
                      <Button style={{ height: "25px", width: '42px', borderRadius: '15px' }}
                        variant={selectInn == 'inn_2' ? "contained" : "outlined"}
                        onClick={handleSwitchInning("inn_2")}
                      >inn2</Button>
                    </span>
                    <h2 style={{ textAlign: 'center', margin: 'auto' }}><span style={{ color: 'rgb(4, 170, 109)' }}>{battingTeamName}</span> VS. <span style={{ color: 'rgb(4, 170, 109)' }}>{ballingTeamName}</span></h2>
                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowSwitch(false)}
                    ><ClearIcon />
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', textAlign: 'center', margin: 'auto'
                  }}>
                    {selectInn == 'inn_1' ? (
                      <>
                        <span > <b style={{ color: "rgb(4, 170, 109)" }}>{showPopupRunsInn1}/{showPopupWicketsInn1}</b> &nbsp;in <b style={{ color: "rgb(4, 170, 109)" }}>{`${showPopupOversInn1.toFixed(1)}`}{`(${totalPopupOversInn1})`}</b>&nbsp;Overs
                        </span>
                        <div className="grid lg:grid-cols-2 grid-cols-2 grid-rows-1 gap-5" >
                          <div>
                            <table className="table-auto custom-table">
                              <thead>
                                <tr style={{
                                  backgroundColor: '#04AA6D',
                                  color: 'white',
                                  fontSize: '10px'
                                }}>
                                  <th colSpan={2}>Batter (Inning 1)</th>
                                  <th>R</th>
                                  <th>B</th>
                                  <th>4s</th>
                                  <th>6s</th>
                                  <th>S.R.</th>
                                </tr>
                              </thead>
                              <tbody>
                                {inn1ScoreboardBatting.length != 0 ? (
                                  <>
                                    {inn1ScoreboardBatting.map((scoreboard, index) => (
                                      <>
                                        {scoreboard.full_name != null ? (
                                          <>
                                            <tr>
                                              <td>
                                                <span
                                                  style={{
                                                    fontSize: '16px',
                                                    marginLeft: '5px',
                                                    color: scoreboard.is_promising_player ? 'goldenrod' : 'initial',
                                                  }}
                                                  onClick={() => handleBattingBowllingPromissing(scoreboard.id, scoreboard.team_id, scoreboard.is_promising_player, index, "batting")}
                                                >
                                                  {scoreboard.is_promising_player ? (
                                                    <StarIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                  ) : (
                                                    <StarBorderIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                  )}
                                                </span>
                                                {scoreboard.full_name}{' '}
                                              </td>
                                              {scoreboard.batsman_wicket.bowler_id.id ? (
                                                <td>{scoreboard.batsman_wicket.wicket_type} b {scoreboard.batsman_wicket.bowler_id.full_name}</td>
                                              ) : (<td>not out</td>)}
                                              <td>{scoreboard.total_batsman_run + scoreboard.total_batsman_no_ball_runs + scoreboard.total_batsman_run_out_runs}</td>
                                              <td>{scoreboard.total_batsman_ball}</td>
                                              <td>{scoreboard.total_batsman_four_run}</td>
                                              <td>{scoreboard.total_batsman_six_run}</td>
                                              <td>{scoreboard.batsman_strike_rate}</td>
                                            </tr>
                                          </>) : (<></>)}
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan={7}>Data not found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <table className="table-auto custom-table">
                              <thead>
                                <tr style={{
                                  backgroundColor: '#04AA6D',
                                  color: 'white',
                                  fontSize: '10px'
                                }}>
                                  <th>Bowler (Inning 1)</th>
                                  <th>Over</th>
                                  <th>Maiden</th>
                                  <th>R</th>
                                  <th>W</th>
                                  <th>NB</th>
                                  <th>WD</th>
                                  <th>Eco</th>
                                  <th>Extras</th>
                                </tr>
                              </thead>
                              <tbody>
                                {inn1ScoreboardBowling.length != 0 ? (
                                  <>
                                    {inn1ScoreboardBowling.map((scoreboard, index) => (
                                      <>
                                        {scoreboard.bowler_detail?.full_name != null ? (
                                          <>
                                            <tr>
                                              <td>
                                                <span
                                                  style={{
                                                    fontSize: '16px',
                                                    marginLeft: '5px',
                                                    marginTop: "-5px",
                                                    color: scoreboard.is_promising_player ? 'goldenrod' : 'initial',
                                                  }}
                                                  onClick={() => handleBattingBowllingPromissing(scoreboard.bowler_id, scoreboard.team_id, scoreboard.is_promising_player, index, "bowling")}
                                                >
                                                  {scoreboard.is_promising_player ? (
                                                    <StarIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                  ) : (
                                                    <StarBorderIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                  )}
                                                </span>
                                                {scoreboard.bowler_detail?.full_name}{' '}
                                              </td>
                                              <td>{scoreboard.total_bowler_over}</td>
                                              <td>{scoreboard.total_bowler_maiden}</td>
                                              <td>{scoreboard.total_bowler_runs}</td>
                                              <td>{scoreboard.total_bowler_wicket}</td>
                                              <td>{scoreboard.total_bowler_ball_nb}</td>
                                              <td>{scoreboard.total_bowler_ball_wd}</td>
                                              <td>{scoreboard.economy_bowler}</td>
                                              <td>{scoreboard.total_bowler_extra_runs}</td>
                                            </tr>
                                          </>) : (<></>)}
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan={9}>Data not found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>)
                      :
                      (
                        <>
                          <span > <b style={{ color: "rgb(4, 170, 109)" }}>{showPopupRunsInn2}/{showPopupWicketsInn2}</b> &nbsp;in <b style={{ color: "rgb(4, 170, 109)" }}>{`${showPopupOversInn2.toFixed(1)}`}{`(${totalPopupOversInn2})`}</b>&nbsp;Overs
                          </span>
                          <div className="grid lg:grid-cols-2 grid-cols-2 grid-rows-1 gap-5" >
                            <div>
                              <table className="table-auto custom-table">
                                <thead>
                                  <tr style={{
                                    backgroundColor: '#04AA6D',
                                    color: 'white',
                                    fontSize: '10px'
                                  }}>
                                    <th colSpan={2}>Batter (Inning 2)</th>
                                    <th>R</th>
                                    <th>B</th>
                                    <th>4s</th>
                                    <th>6s</th>
                                    <th>S.R.</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {inn2ScoreboardBatting.length != 0 ? (
                                    <>
                                      {inn2ScoreboardBatting.map((scoreboard, index) => (
                                        <>
                                          {scoreboard.full_name != null ? (
                                            <>
                                              <tr>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: '16px',
                                                      marginLeft: '5px',
                                                      color: scoreboard.is_promising_player ? 'goldenrod' : 'initial',
                                                    }}
                                                    onClick={() => handleBattingBowllingPromissingSecond(scoreboard.id, scoreboard.team_id, scoreboard.is_promising_player, index, "batting")}
                                                  >
                                                    {scoreboard.is_promising_player ? (
                                                      <StarIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                    ) : (
                                                      <StarBorderIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                    )}
                                                  </span>
                                                  {scoreboard.full_name}{' '}
                                                </td>
                                                {scoreboard.batsman_wicket.bowler_id.id ? (
                                                  <td>{scoreboard.batsman_wicket.wicket_type} b {scoreboard.batsman_wicket.bowler_id.full_name}</td>
                                                ) : (<td>not out</td>)}
                                                <td>{scoreboard.total_batsman_run + scoreboard.total_batsman_no_ball_runs + scoreboard.total_batsman_run_out_runs}</td>
                                                <td>{scoreboard.total_batsman_ball}</td>
                                                <td>{scoreboard.total_batsman_four_run}</td>
                                                <td>{scoreboard.total_batsman_six_run}</td>
                                                <td>{scoreboard.batsman_strike_rate}</td>
                                              </tr>
                                            </>) : (<></>)}
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>Data not found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <table className="table-auto custom-table">
                                <thead>
                                  <tr style={{
                                    backgroundColor: '#04AA6D',
                                    color: 'white',
                                    fontSize: '10px'
                                  }}>
                                    <th>Bowler (Inning 2)</th>
                                    <th>Over</th>
                                    <th>Maiden</th>
                                    <th>R</th>
                                    <th>W</th>
                                    <th>NB</th>
                                    <th>WD</th>
                                    <th>Eco</th>
                                    <th>Extras</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {inn2ScoreboardBowling.length != 0 ? (
                                    <>
                                      {inn2ScoreboardBowling.map((scoreboard, index) => (
                                        <>
                                          {scoreboard.bowler_detail?.full_name != null ? (
                                            <>
                                              <tr>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: '16px',
                                                      marginLeft: '5px',
                                                      marginTop: "-5px",
                                                      color: scoreboard.is_promising_player ? 'goldenrod' : 'initial',
                                                    }}
                                                    onClick={() => handleBattingBowllingPromissingSecond(scoreboard.bowler_id, scoreboard.team_id, scoreboard.is_promising_player, index, "bowling")}
                                                  >
                                                    {scoreboard.is_promising_player ? (
                                                      <StarIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                    ) : (
                                                      <StarBorderIcon style={{ width: '16px', cursor: 'pointer', marginTop: "-5px", marginRight: "5px" }} />
                                                    )}
                                                  </span>
                                                  {scoreboard.bowler_detail?.full_name}{' '}
                                                </td>
                                                <td>{scoreboard.total_bowler_over}</td>
                                                <td>{scoreboard.total_bowler_maiden}</td>
                                                <td>{scoreboard.total_bowler_runs}</td>
                                                <td>{scoreboard.total_bowler_wicket}</td>
                                                <td>{scoreboard.total_bowler_ball_nb}</td>
                                                <td>{scoreboard.total_bowler_ball_wd}</td>
                                                <td>{scoreboard.economy_bowler}</td>
                                                <td>{scoreboard.total_bowler_extra_runs}</td>
                                              </tr>
                                            </>) : (<></>)}
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan={9}>Data not found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>)
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleSwitchSubmit}>
        {showOversAnalysis ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto ">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <span style={{ margin: '7px' }}>
                      <Button style={{ height: "25px", width: '42px', marginRight: '2px', borderRadius: '15px' }}
                        variant={selectInnOvers == 'inn_1' ? "contained" : "outlined"}
                        onClick={handleSwitchInningOvers("inn_1")}
                      >inn1</Button>
                      <Button style={{ height: "25px", width: '42px', borderRadius: '15px' }}
                        variant={selectInnOvers == 'inn_2' ? "contained" : "outlined"}
                        onClick={handleSwitchInningOvers("inn_2")}
                      >inn2</Button>
                    </span>
                    <h2 style={{ textAlign: 'center', margin: 'auto' }}><span style={{ color: 'rgb(4, 170, 109)' }}>{battingTeamName}</span> VS. <span style={{ color: 'rgb(4, 170, 109)' }}>{ballingTeamName}</span></h2>
                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowOversAnalysis(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', textAlign: 'center', margin: 'auto',
                  }}>
                    {selectInnOvers == 'inn_1' ? (
                      <div style={{ width: '100%', height: '350px', overflowY: 'scroll' }}>
                        <h2 style={{ textAlign: 'center', margin: 'auto' }}><span style={{ color: 'rgb(4, 170, 109)' }}>{inn1BatTeamName}</span></h2>
                        <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-1" style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          width: '420px',
                          justifyContent: 'flex-start'
                        }}>
                          {totalOverAnalysis.length != 0 ? (
                            <>
                              {totalOverAnalysis.length != 0 ? (
                                <>
                                  {totalOverAnalysis.slice(0, visibleInn1Records).map((over, index) => (
                                    <>
                                      {end_match_inning1 != 0 ? (
                                        <Button id="caughtOut" className="selectOvers"
                                          disabled={Math.ceil(end_match_inning1) < over ? true : false}
                                          variant={inn1Over === over || (inn1Over === "" && over === "1") ? "contained" : "outlined"}
                                          style={{ cursor: 'pointer', minWidth: '0px', margin: '1px' }} onClick={handleOverDetails(over)}
                                        >{over}</Button >
                                      ) : (
                                        <Button id="caughtOut" className="selectOvers "
                                          variant={inn1Over === over || (inn1Over === "" && over === "1") ? "contained" : "outlined"}
                                          style={{ cursor: 'pointer', minWidth: '0px', margin: '1px' }} onClick={handleOverDetails(over)}
                                        >{over}</Button >
                                      )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <div>Data not found</div>
                              )}
                            </>
                          ) : (
                            <div>Data not found</div>
                          )}

                          {visibleInn1Records < totalOverAnalysis.length && (
                            <div variant="contained" aria-label="outlined button group" style={{ justifyContent: 'center', width: '100%', margin: '5px' }}  >
                              <Button style={{ padding: '0 15px' }}
                                title={`Load More`} onClick={handleLoadmoreInn1Popup} >
                                <ExpandCircleDownIcon style={{ marginRight: '5px' }} />
                                Load More
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-1" style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}>
                          {totalBallsAnalysis.length != 0 ? (
                            <>
                              {totalBallsAnalysis.map((ball, id) => (
                                <>
                                  {(end_match_inning1 != 0) ? (<>
                                    {ball.current_ball <= end_match_inning1 ? (<>
                                      <div id="caughtOut" className="sliderMargin sliderButton"
                                        style={{
                                          margin: '1px', width: '80px',
                                          height: '80px', color: `${ball.is_neglect_ball == 'no' ? 'floralwhite' : 'gray'}`, border: '3px solid green', background: `${ball.is_neglect_ball == 'no' ? ball.ball_box_color : 'lightgray'}`
                                        }} >

                                        {ball.is_neglect_ball == 'no' ? <>
                                          {ball.ball_delivery_result != 'W' ? (
                                            <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                                              {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                            </span>) : (
                                            <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                                              {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                            </span>)}
                                          <br />
                                          <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                        </> :
                                          <>
                                            {ball.ball_delivery_result != 'W' ? (
                                              <span>
                                                {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                              </span>) : (
                                              <span>
                                                {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                              </span>)}
                                            <br />
                                            <span>{ball.current_ball}</span>
                                          </>
                                        }


                                        <br />
                                        {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                          <span>
                                            {ball.is_neglect_ball == 'no' ? <>{ball.is_bookmark != 'no' ? (
                                              <span style={{ marginRight: '2px' }}>
                                                <StarIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallBookmark(ball.id, 'no')} />
                                              </span>) : (
                                              <span style={{ marginRight: '2px' }}>
                                                <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallBookmark(ball.id, 'yes')} />
                                              </span>)}</> : ''}

                                            {ball.is_neglect_ball == 'no' ? <>{ball.is_force_to_complete != 'no' ? (
                                              <span style={{ marginRight: '4px' }}>
                                                <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallTag(ball.id, 'no')} />
                                              </span>) : (
                                              <span style={{ marginRight: '4px' }}>
                                                <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallTag(ball.id, 'yes')} />
                                              </span>)}</> : ''}

                                            {ball.is_neglect_ball != 'no' ? (
                                              <span>
                                                <ThumbDownIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall1Neglect(ball.id, 'no')} />
                                              </span>) : (
                                              <span>
                                                <ThumbUpIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall1Neglect(ball.id, 'yes')} />
                                              </span>)}

                                          </span>) : (<></>)}
                                      </div>
                                    </>) : (<>
                                      <div id="caughtOut" className="sliderMargin sliderButton inactive-element"
                                        style={{
                                          margin: '1px', width: '65px',
                                          height: '80px', color: 'floralwhite', border: '3px solid green', background: `${ball.ball_box_color}`
                                        }} >
                                        {ball.ball_delivery_result != 'W' ? (
                                          <span style={{ cursor: 'pointer' }}>
                                            {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                          </span>) : (
                                          <span style={{ cursor: 'pointer' }}>
                                            {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                          </span>)}
                                        <br />
                                        <span style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                        <br />
                                        {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                          <span>
                                            {ball.is_bookmark != 'no' ? (
                                              <span>
                                                <StarIcon style={{ width: '16px', cursor: 'pointer' }} />
                                              </span>) : (
                                              <span>
                                                <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} />
                                              </span>)}
                                            {ball.is_force_to_complete != 'no' ? (
                                              <span>
                                                <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} />
                                              </span>) : (
                                              <span>
                                                <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} />
                                              </span>)}
                                          </span>
                                        ) : (<></>)}
                                      </div>
                                    </>)}
                                  </>) : (
                                    <>

                                      <div id="caughtOut" className="sliderMargin sliderButton"
                                        style={{
                                          margin: '1px', width: '80px',
                                          height: '80px', color: `${ball.is_neglect_ball == 'no' ? 'floralwhite' : 'gray'}`, border: '3px solid green', background: `${ball.is_neglect_ball == 'no' ? ball.ball_box_color : 'lightgray'}`
                                        }} >
                                        {ball.is_neglect_ball == 'no' ? <>
                                          {ball.ball_delivery_result != 'W' ? (
                                            <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                                              {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                            </span>) : (
                                            <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                                              {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                            </span>)}
                                          <br />
                                          <span onClick={handleSwitchInn1Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                        </> :
                                          <>
                                            {ball.ball_delivery_result != 'W' ? (
                                              <span>
                                                {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                              </span>) : (
                                              <span>
                                                {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                              </span>)}
                                            <br />
                                            <span>{ball.current_ball}</span>
                                          </>
                                        }

                                        <br />
                                        {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                          <span>

                                            {ball.is_neglect_ball == 'no' ? <>{ball.is_bookmark != 'no' ? (
                                              <span style={{ marginRight: '2px' }}>
                                                <StarIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallBookmark(ball.id, 'no')} />
                                              </span>) : (
                                              <span style={{ marginRight: '2px' }}>
                                                <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallBookmark(ball.id, 'yes')} />
                                              </span>)}</> : ''}


                                            {ball.is_neglect_ball == 'no' ? <>{ball.is_force_to_complete != 'no' ? (
                                              <span style={{ marginRight: '4px' }}>
                                                <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallTag(ball.id, 'no')} />
                                              </span>) : (
                                              <span style={{ marginRight: '4px' }}>
                                                <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn1BallTag(ball.id, 'yes')} />
                                              </span>)}</> : ''}


                                            {ball.is_neglect_ball != 'no' ? (
                                              <span>
                                                <ThumbDownIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall1Neglect(ball.id, 'no')} />
                                              </span>) : (
                                              <span>
                                                <ThumbUpIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall1Neglect(ball.id, 'yes')} />
                                              </span>)}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )
                      :
                      (
                        <div style={{ width: '100%', height: '350px', overflowY: 'scroll' }}>
                          <h2 style={{ textAlign: 'center', margin: 'auto' }}><span style={{ color: 'rgb(4, 170, 109)' }}>{inn2BatTeamName}</span></h2>
                          <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-1" style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '420px',
                            justifyContent: 'flex-start'
                          }}>
                            {totalOverAnalysisInn2.length != 0 ? (
                              <>
                                {totalOverAnalysisInn2.slice(0, visibleInn2Records).map((over, id) => (

                                  <React.Fragment key={id}>
                                    {end_match_inning2 == 0 ? (
                                      <Button id="caughtOut" className="selectOvers "
                                        variant={inn2Over === over || (inn2Over === "" && over === "1") ? "contained" : "outlined"}
                                        style={{ cursor: 'pointer', minWidth: '0px', margin: '1px' }}
                                        onClick={handleOverDetailsInn2(over)}
                                      >{over}</Button>
                                    ) : (
                                      <Button id="caughtOut" className="selectOvers "
                                        disabled={Math.ceil(end_match_inning2) < over ? true : false}
                                        variant={inn2Over === over || (inn2Over === "" && over === "1") ? "contained" : "outlined"}
                                        style={{ cursor: 'pointer', minWidth: '0px', margin: '1px' }}
                                        onClick={handleOverDetailsInn2(over)}
                                      >{over}</Button>
                                    )}
                                  </React.Fragment>

                                ))}
                              </>
                            ) : (
                              <div>Data not found</div>
                            )}
                            {visibleInn2Records < totalOverAnalysis.length && (
                              <div variant="contained" aria-label="outlined button group" style={{ justifyContent: 'center', width: '100%', margin: '5px' }}  >
                                <Button style={{ padding: '0 15px' }}
                                  title={`Load More`} onClick={handleLoadmoreInn2Popup} >
                                  <ExpandCircleDownIcon style={{ marginRight: '5px' }} />
                                  Load More
                                </Button>
                              </div>
                            )}
                          </div>
                          <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-1" style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}>
                            {totalBallsAnalysisInn2.length != 0 ? (
                              <>
                                {totalBallsAnalysisInn2.map((ball, id) => (
                                  <React.Fragment key={id}>
                                    {end_match_inning2 != 0 ? (
                                      <>
                                        {ball.current_ball <= end_match_inning2 ? (<>
                                          <div id="caughtOut" className="sliderMargin sliderButton"
                                            style={{
                                              margin: '1px', width: '65px',
                                              height: '80px', color: 'floralwhite', border: '3px solid green', background: `${ball.ball_box_color}`
                                            }} >
                                            {ball.ball_delivery_result != 'W' ? (
                                              <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)}>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                                </span>
                                              </span>) : (
                                              <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)}>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                                </span>
                                              </span>)}
                                            <br />
                                            <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                            <br />
                                            {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                              <span>
                                                {ball.is_bookmark != 'no' ? (
                                                  <span>
                                                    <StarIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallBookmark(ball.id, 'no')} />
                                                  </span>) : (
                                                  <span>
                                                    <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallBookmark(ball.id, 'yes')} />
                                                  </span>)}
                                                {ball.is_force_to_complete != 'no' ? (
                                                  <span>
                                                    <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallTag(ball.id, 'no')} />
                                                  </span>) : (
                                                  <span>
                                                    <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallTag(ball.id, 'yes')} />
                                                  </span>)}
                                              </span>
                                            ) : (<></>)}
                                          </div>
                                        </>) : (<>
                                          <div id="caughtOut" className="sliderMargin sliderButton inactive-element"

                                            style={{
                                              margin: '1px', width: '65px',
                                              height: '80px', color: 'floralwhite', border: '3px solid green', background: `${ball.ball_box_color}`
                                            }} >
                                            {ball.ball_delivery_result != 'W' ? (
                                              <span>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                                </span>
                                              </span>) : (
                                              <span>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                                </span>
                                              </span>)}
                                            <br />
                                            <span style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                            <br />
                                            {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                              <span>
                                                {ball.is_bookmark != 'no' ? (
                                                  <span>
                                                    <StarIcon style={{ width: '16px', cursor: 'pointer' }} />
                                                  </span>) : (
                                                  <span>
                                                    <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} />
                                                  </span>)}
                                                {ball.is_force_to_complete != 'no' ? (
                                                  <span>
                                                    <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} />
                                                  </span>) : (
                                                  <span>
                                                    <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} />
                                                  </span>)}
                                              </span>
                                            ) : (<></>)}
                                          </div>
                                        </>)}
                                      </>) : (<>
                                        <div id="caughtOut" className="sliderMargin sliderButton"
                                          style={{
                                            margin: '1px', width: '80px',
                                            height: '80px', color: `${ball.is_neglect_ball == 'no' ? 'floralwhite' : 'gray'}`, border: '3px solid green', background: `${ball.is_neglect_ball == 'no' ? ball.ball_box_color : 'lightgray'}`
                                          }} >

                                          {ball.is_neglect_ball == 'no' ? <>
                                            {ball.ball_delivery_result != 'W' ? (
                                              <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)}>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                                </span>
                                              </span>) : (
                                              <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)}>
                                                <span style={{ cursor: 'pointer' }}>
                                                  {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                                </span>
                                              </span>)}
                                            <br />
                                            <span onClick={handleSwitchInn2Ball(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                                          </> :
                                            <>
                                              {ball.ball_delivery_result != 'W' ? (
                                                <span>
                                                  <span>
                                                    {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                                                  </span>
                                                </span>) : (
                                                <span>
                                                  <span>
                                                    {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                                                  </span>
                                                </span>)}
                                              <br />
                                              <span>{ball.current_ball}</span>
                                            </>
                                          }


                                          <br />
                                          {ball.ball_box_color != "linear-gradient(to right, #868f96 0%, #596164 100%)" ? (
                                            <span>
                                              {ball.is_neglect_ball == 'no' ? <>{ball.is_bookmark != 'no' ? (
                                                <span style={{ marginRight: '2px' }}>
                                                  <StarIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallBookmark(ball.id, 'no')} />
                                                </span>) : (
                                                <span style={{ marginRight: '2px' }}>
                                                  <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallBookmark(ball.id, 'yes')} />
                                                </span>)}</> : ''}
                                              {ball.is_neglect_ball == 'no' ? <>{ball.is_force_to_complete != 'no' ? (
                                                <span style={{ marginRight: '4px' }}>
                                                  <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallTag(ball.id, 'no')} />
                                                </span>) : (
                                                <span style={{ marginRight: '4px' }}>
                                                  <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchInn2BallTag(ball.id, 'yes')} />
                                                </span>)}</> : ''}


                                              {ball.is_neglect_ball != 'no' ? (
                                                <span>
                                                  <ThumbDownIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall2Neglect(ball.id, 'no')} />
                                                </span>) : (
                                                <span>
                                                  <ThumbUpIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBall2Neglect(ball.id, 'yes')} />
                                                </span>)}
                                            </span>
                                          ) : (<></>)}
                                        </div>
                                      </>)}
                                  </React.Fragment>
                                ))}
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      )
                    }
                    <Button disabled={nextSubmitInning} variant={nextSubmitInning ? "contained" : "outlined"} style={{ color: '#ff0000' }} onClick={handleSubmitInning('submitInning')}> Submit {innings === 1 ? "First Inning" : "Second Inning"} <ArrowRightIcon /></Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      {showColorCode ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                <div style={{ margin: '2px', justifyContent: 'end', alignItems: 'baseline' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <span style={{ margin: 'auto', fontWeight: 'bold' }}>Color Code</span>
                  <button style={{ width: '40px', margin: '0' }}
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowColorCode(false)}
                  >
                    <ClearIcon />
                  </button>
                </div>
                <div className="relative p-6 flex-auto" style={{
                  width: '100%',
                  paddingTop: '0', textAlign: 'center', margin: 'auto',
                }}>
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(76, 175, 80)' }}></th>
                        <td>This color indicate for all data is filled.</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(25, 118, 210)' }}></th>
                        <td>This color indicate video is not available</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(210 127 25)' }}></th>
                        <td>This color indicate bowler, striker, non-striker is not available.</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(96 25 210)' }}></th>
                        <td>This color indicate ball type is not available.</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(20 210 207)' }}></th>
                        <td>This color indicate batting result is not available</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(210 25 145)' }}></th>
                        <td>This color indicate bat type is not available</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'lightgray' }}></th>
                        <td>This color indicate for ball is neglected or ignored</td>
                      </tr>
                      <tr>
                        <th style={{ padding: '20px', backgroundColor: 'rgb(89, 97, 100)' }}></th>
                        <td>This color indicate non-develiver ball</td>
                      </tr>

                    </tbody>
                  </table>
                  <br></br>
                  <div style={{ marginTop: '-10px', marginBottom: '10px' }}>
                    <span style={{ margin: 'auto', fontWeight: 'bold' }}>Internet Speed Color Code </span>
                  </div>
                  <hr></hr>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ padding: '20px', backgroundColor: '#A10000', width: '8%' }}></th>
                          <td>This color indicate weak internet speed.</td>
                        </tr>
                        <tr>
                          <th style={{ padding: '20px', backgroundColor: '#008000', width: '8%' }}></th>
                          <td>This color indicate strong internet speed.</td>
                        </tr>
                        <tr>
                          <th style={{ padding: '20px', backgroundColor: '#FF0000', width: '8%' }}></th>
                          <td>This color indicate that you are offline.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {!isOnline ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                <div style={{ margin: '2px', justifyContent: 'end', alignItems: 'baseline' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <span style={{ margin: 'auto', fontWeight: 'bold' }}>Internet Connection Alert</span>
                </div>
                <div className="relative p-6 flex-auto" style={{
                  width: '100%',
                  paddingTop: '0', textAlign: 'center', margin: 'auto',
                }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p style={{ backgroundColor: 'red', color: '#FFF', fontSize: '16px', textAlign: 'center' }}> <CancelIcon color="error" style={{ color: '#FFF' }} /> You are offline. Please check your internet connection.</p>
                          <div className="offline-text" style={{ fontSize: '8rem' }}><SignalWifiOff style={{ fontSize: '8rem' }} /></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ opacity: '0.60' }}></div>
        </>
      ) : null}

      <div className="w-full flex " >
        <div className="grid grid-cols-3 divide-x" style={{
          backgroundColor: "white",
          width: "100%"
        }}>
          <div className="w-full" style={{ width: "90%" }}>
            <div className="bg-white p-2 h-max " >
              <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: " 45px" }}>
                {tournamentNameScoreLoader ? (
                  <div className="loader" style={{ margin: 'auto', padding: '30px' }}></div>
                ) : (
                  <div style={{ textAlign: 'center' }} className="text-base font-semibold pb-2 grid grid-cols-1 grid-rows-3 p-2">
                    <h2>{tournamentName}</h2>
                    <h2>{matchName}</h2>
                    <h2><span style={{ color: 'rgb(4, 170, 109)' }}>{battingTeamName}</span> VS. <span style={{ color: 'rgb(4, 170, 109)' }}>{ballingTeamName}</span></h2>
                  </div>
                )}
                <span className="text-left" style={{
                  padding: '0px 10px'
                }} >
                  <h2 className="" style={{ color: "#2B303B", display: 'flex', flexDirection: 'column' }}>
                    <span id="caughtOut" className="sliderMargin sliderButton capitalize"
                      onClick={handleModalSwitch} style={{ margin: '1px 2px', border: 'none', color: 'rgb(4, 170, 109)', cursor: 'pointer' }}>{battingTeamName}</span>
                    <span style={{ textAlign: 'center' }}>
                      {innings === 1 ? "First Inning" : "Second Inning"}
                      <span hidden={!nextSubmitInning}>{innings === 1 ? (
                        <span className="sliderMargin sliderButton capitalize"
                          onClick={handleSwitchinn2} style={{ margin: '1px 2px', border: 'none', color: 'rgb(4, 170, 109)', cursor: 'pointer' }}>(End - <b style={{ color: "rgb(4, 170, 109)" }}>{end_match_inning}</b> Overs)</span>
                      ) : (
                        <>(End - <b style={{ color: "rgb(4, 170, 109)" }}>{end_match_inning}</b> Overs)</>
                      )}
                      </span>
                    </span>
                  </h2>
                  <h2 className="text-base font-semibold" style={{ color: "#2B303B", fontSize: "20px", textAlign: 'center' }} >
                    <b style={{ color: "rgb(4, 170, 109)" }}>{showRuns}/{showWickets}</b>
                    &nbsp;in <b style={{ color: "rgb(4, 170, 109)" }}>{`${showOvers.toFixed(1)}`}{`(${totalOvers})`}</b>
                    &nbsp;Overs
                  </h2>
                </span>
                <div className="text-base font-semibold  grid grid-cols-1 grid-rows-1 " style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)' }}>
                  <table className="table-auto custom-table">
                    <thead>
                      <tr style={{
                        backgroundColor: '#04AA6D',
                        color: 'white',
                        fontSize: '10px'
                      }}>
                        <th style={{ width: '45%' }}>Bowler <IconButton
                          type="button"
                          size="small"
                          variant="contained"
                          onClick={() => OpenPlayerEditModal2()}
                        >
                          <AddIcon />
                        </IconButton> <IconButton
                          type="button"
                          size="small"
                          variant="contained"
                          onClick={() => setShowBowlerModal(true)}
                        >
                            <GroupAddIcon />
                          </IconButton>
                        </th>
                        <th>Over</th>
                        <th>Maiden</th>
                        <th>Wd</th>
                        <th>Eco</th>
                        <th>Extras</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><select
                          id="bowler"
                          value={bowlerId}
                          onChange={handleBolwerDetails}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        //defaultValue={defaults.BOWLER}
                        >
                          <option value=''>Select Bowler</option>
                          {bowlingTeamDataDetails.map((player, id) => (
                            <option value={player.id} key={id}>
                              {player.name}
                              {player.bowling == 'Fast' ? ('(F)') : ('(S)')}
                            </option>
                          ))}
                        </select></td>
                        <td>{total_bowler_over}</td>
                        <td>{total_bowler_maiden}</td>
                        <td>{total_bowler_ball_wd}</td>
                        <td>{economy_bowler}</td>
                        <td>{total_bowler_extra_runs}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-base font-semibold  grid grid-cols-1 grid-rows-1" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)' }}>
                  <table className="table-auto custom-table">
                    <thead>
                      <tr style={{
                        backgroundColor: '#04AA6D',
                        color: 'white',
                        fontSize: '10px'
                      }}>
                        <th style={{ width: '45%' }}>Striker
                          <IconButton
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={() => OpenPlayerEditModal3()}
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={() => setShowBatsmanModal(true)}
                          >
                            <GroupAddIcon />
                          </IconButton>
                        </th>
                        <td>R</td>
                        <td>B</td>
                        <td>4s</td>
                        <td>6s</td>
                        <td>S.R.</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            id="bat2"
                            value={strikerId}
                            onChange={handleStrikerDetails}
                            //defaultValue={defaults.STRIKER}
                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          >
                            <option value=''>Select Striker</option>
                            {battingTeamDataDetails.map((player, id) => (
                              <React.Fragment key={id}>
                                <>
                                  {player.batsman_wicket.is_wicket == 'yes' ?
                                    <>
                                      <option value={player.id} disabled style={{ color: '#FF0000' }}>
                                        {player.name}{player.batting == 'Left Hand' ? ('(L)') : ('(R)')} ({player.batsman_wicket.wicket_type})

                                      </option>
                                    </> :
                                    <>
                                      <option value={player.id} >
                                        {player.name}{player.batting == 'Left Hand' ? ('(L)') : ('(R)')}
                                      </option>
                                    </>}
                                </>
                              </React.Fragment>
                            ))}
                          </select>
                        </td>
                        <td>{total_striker_run}</td>
                        <td>{total_striker_ball}</td>
                        <td>{total_striker_four_run}</td>
                        <td>{total_striker_six_run}</td>
                        <td>{striker_strike_rate}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} ><img src={SwitchIcon} alt="Switch Batsman" style={{ width: 20, cursor: 'pointer', margin: ' 0 50%' }} onClick={switchBatsmanNonBatsman} /></td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr style={{
                        backgroundColor: '#04AA6D',
                        color: 'white',
                        fontSize: '10px'
                      }}>
                        <th> Non Striker
                          <IconButton
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={() => OpenPlayerEditModal3()}
                          >
                            <AddIcon />
                          </IconButton><IconButton
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={() => setShowBatsmanModal(true)}
                          >
                            <GroupAddIcon />
                          </IconButton>
                        </th>
                        <td>R</td>
                        <td>B</td>
                        <td>4s</td>
                        <td>6s</td>
                        <td>S.R.</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            id="bat1"
                            onChange={handleNStrikerDetails}
                            //defaultValue={defaults.NON_STRIKER}
                            value={nonStrikerId}
                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          >
                            <option value=''>Select Non Striker</option>
                            {battingTeamDataDetails.map((player, id) => (
                              <React.Fragment key={id}>
                                <>
                                  {player.batsman_wicket.is_wicket == 'yes' ?
                                    <>
                                      <option value={player.id} disabled style={{ color: '#FF0000' }}>
                                        {player.name}{player.batting == 'Left Hand' ? ('(L)') : ('(R)')} ({player.batsman_wicket.wicket_type})
                                      </option>
                                    </> :
                                    <>
                                      <option value={player.id}>
                                        {player.name}{player.batting == 'Left Hand' ? ('(L)') : ('(R)')}
                                      </option>
                                    </>}
                                </>
                              </React.Fragment>
                            ))}
                          </select>
                        </td>
                        <td>{total_nstriker_run}</td>
                        <td>{total_nstriker_ball}</td>
                        <td>{total_nstriker_four_run}</td>
                        <td>{total_nstriker_six_run}</td>
                        <td>{nstriker_strike_rate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-base font-semibold pb-2 grid grid-cols-1 grid-rows-1" >
                  {showCamSection ? (
                    <ButtonGroup style={{ margin: "auto" }} >
                      <Button style={{ margin: "2px", fontSize: "10px" }} onClick={onCameraSelect("Camera1")} variant={selecteCamera == "Camera1" ? "contained" : "outlined"} disabled={isRecording}><p style={{ width: '120px' }}>{`Cam1:${navData?.Camera1User.name}`}</p></Button>
                      <img src={SwitchIcon} alt="Switch Batsman" style={{ width: 20, cursor: 'pointer', transform: 'rotate(90deg)', margin: '10px' }} onClick={switchCamera} />
                      <Button style={{ margin: "2px", fontSize: "10px" }} onClick={onCameraSelect("Camera2")} variant={selecteCamera == "Camera2" ? "contained" : "outlined"} disabled={isRecording}><p style={{ width: '120px' }}>{`Cam2:${navData?.Camera2User.name}`}</p></Button>
                    </ButtonGroup>) : null}
                  <div className="grid grid-cols-2 gap-3">
                    <div style={{ margin: 'auto', marginLeft: '10px', }}>
                      <div style={{
                        border: '3px solid rgb(76, 175, 80)', fontSize: '20px', textAlign: 'center', padding: '5px 50px',
                        borderRadius: '4px', marginRight: '5px', color: 'floralwhite', background: 'rgb(25, 118, 210)'
                      }}>{(`${systemTime.getHours()}` <= 9 ? `0${systemTime.getHours()}` : `${systemTime.getHours()}`)}:
                        {(`${systemTime.getMinutes()}` <= 9 ? `0${systemTime.getMinutes()}` : `${systemTime.getMinutes()}`)}:
                        {(`${systemTime.getSeconds()}` <= 9 ? `0${systemTime.getSeconds()}` : `${systemTime.getSeconds()}`)}
                      </div>
                    </div>
                    <div className="text-base font-semibold" style={{ display: 'flex', justifyContent: 'space-around', margin: 'auto', width: '100%' }}>
                      <ButtonGroup variant="contained" aria-label="outlined button group">
                        {cameraType === 'mobile' && (
                          <Button
                            style={{ fontSize: '10px', backgroundColor: 'rgb(161, 0, 0)', color: '#FFF' }}
                            title={`External Camera`}
                            // onClick={handleRecording(false, "UserClick")}
                            disabled={isDisabledRecordButton}
                          >
                            External Camera
                          </Button>
                        )}
                        {cameraType === 'external' && (
                          <Button
                            style={{ fontSize: '10px', backgroundColor: 'rgb(4, 170, 109)', color: '#FFF' }}
                            title={`Mobile Camera`}
                            // onClick={handleRecording(true, "UserClick")}
                            disabled={isDisabledRecordButton}
                          >
                            Mobile Camera
                          </Button>
                        )}
                        <FormControlLabel style={{ marginLeft: '2px', height: '37px', marginRight: 'auto' }}
                          control={<Switch checked={cameraType === 'external'} onChange={toggleCameraType} />}
                          id="swtichbuttoncameraid"
                        />

                        <ReactTooltip
                          anchorId="swtichbuttoncameraid"
                          place="top"
                          html="
                        <h2 style='text-align:center;color:lightgreen'>Switch Between External To Mobile Camera</h2><hr/>
                        <h3 style='text-align:center;'>This switch button provides two-way functionality, with the external camera selected by default.<br /> The operator can easily switch between the external and mobile cameras.<br /> We recommend use the external camera on slow networks because when the network is slow, <br />the process of zipping the video, uploading it to an AWS bucket, and <br />tagging the video for the current ball takes a considerable amount of time. <br />This can result in delays in data entry.<br/>Using the external camera on slow networks allows you to perform data entry without any delays or interruptions.<br /> Since it only captures the timestamp and interval, it completely breaks the connection with the mobile device,<br /> saving your time and ensuring fast and smooth data entry.</h3>
                        "
                          className="tooltip"
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <div className="form-group" style={{ padding: '3px 4px 3px 0px' }}>
                        <label htmlFor="appt-time" style={{ display: 'block' }}>Start Time: </label>
                        <input
                          id="appt-time"
                          type="text"
                          name="appt-time"
                          step="1"
                          value={startNewTime}
                          ref={start_time}
                          onChange={handleTimeChange}
                          style={{ padding: '7px 0px 7px 5px' }}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          placeholder="Start Time"
                        />
                      </div>
                      <div className="form-group" style={{ padding: '3px 4px 3px 0px' }}>
                        <label htmlFor="appt-time" style={{ display: 'block' }}>Time Interval: </label>
                        <input type="number" name="endtime"
                          onChange={handleInputChange}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" placeholder="Time Interval" style={{ padding: '7px 0px 7px 5px' }} value={demoValue} ref={end_interval} />
                      </div>
                    </div>
                  </div>
                  <div className="text-base font-semibold " style={{ display: 'flex', justifyContent: 'space-around', margin: 'auto', width: '100%' }}>
                    {showCamSection && (
                      <div>
                        {isRecording ? (
                          <>
                            <ButtonGroup variant="contained" aria-label="outlined button group"  >
                              <Button style={{ fontSize: '10px', backgroundColor: 'rgb(161, 0, 0)', color: '#FFF' }}
                                title={`Stop Recording`} onClick={handleRecording(false, "UserClick")} disabled={isDisabledRecordButton} >
                                Stop Recording
                              </Button>
                            </ButtonGroup>
                          </>
                        ) : (
                          <>
                            <ButtonGroup variant="contained" aria-label="outlined button group" >
                              <Button style={{ fontSize: '10px', backgroundColor: 'rgb(4, 170, 109)', color: '#FFF' }}
                                title={`Start Recording`} onClick={handleRecording(true, "UserClick")} disabled={isDisabledRecordButton} >
                                Start Recording
                              </Button>
                            </ButtonGroup>
                          </>
                        )}
                      </div>
                    )}
                    <div style={{ marginLeft: '10px' }}>
                      <Button style={{ fontSize: '10px' }} variant="contained" onClick={() => setShowColorCode(true)}>Color code</Button>
                      <ReactTooltip
                        anchorId="firebasedatabase"
                        place="top"
                        html="
                        <h2 style='text-align:center;color:lightgreen'>Create Database in Firebase</h2><hr/>
                        <h3 style='text-align:center;'>If the Firebase database is deleted, you can create a new one by clicking here.<br />In the event of an internet connection loss after starting recording, and <br />you need to reset the database connection to synchronize with the mobile app screen,<br /> click here to initiate the reset.</h3>
                        "
                        className="tooltip"
                      />
                      <Button style={{ fontSize: '10px', marginLeft: '10px', marginRight: '-26px' }} id="firebasedatabase" variant="contained" onClick={handleDatabaseButtonClick} disabled={disableCreateDbButton}>Create DB</Button>
                    </div>
                  </div>
                  <div>
                    <div style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '10px', textAlign: 'center', marginTop: '7px' }}>Mobile Status:
                      <span style={{ color: getStatusTextColor(mobileVideoStatus) }}> {mobileVideoStatus}</span>
                    </div>
                    {/* {showCheckVideoMessage && (
                      <div style={{ backgroundColor: '#06744F', padding: '5px', border: '1px solid #ccc', borderRadius: '5px', width: '60%', marginLeft: '21%', textAlign: 'center', color: 'white' }}><span style={{ color: 'black' }}>Web Status:</span> Unzipping Video...</div>
                    )} */}
                    {showCheckWebVideoMessage && mobileVideoStatus != "Recording Started" && (
                      <div style={{ backgroundColor: '#4CAF50', padding: '5px', border: '1px solid #ccc', borderRadius: '5px', width: '60%', marginLeft: '21%', textAlign: 'center', color: 'white' }}><span style={{ color: 'black' }}>Web Status:</span>  Video Received</div>
                    )}
                  </div>
                </div>
                <span className="text-right">
                  {file ? (
                    <h2
                      className="text-base font-semibold pb-2"
                      style={{ color: "#2B303B", fontSize: "15px" }}
                    >
                      {file.name}
                    </h2>
                  ) : null}
                  <input
                    ref={fileInput}
                    type="file"
                    className="hidden"
                    onChange={handleFileInput}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-3" style={{
            position: "relative",
            width: "128%",
            height: "72vh", right: '45px', marginTop: '4px', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)'
          }}>
            <div className="flex" style={{ height: "70vh" }}>
              {battingBowlingImgLoader ? (
                <div className="loader" style={{ margin: 'auto', padding: '20px', margin: '140px 127px' }}></div>
              ) : (
                <BowlingInput
                  bowlingData={bowlingData}
                  setBowlingData={setBowlingData}
                  bowlingPitchData={bowlingPitchData}
                  setBowlingPitchData={setBowlingPitchData}
                  bowlingStumpData={bowlingStumpData}
                  setBowlingStumpData={setBowlingStumpData}
                  positionBatType={positionBatType}
                  setBatsmanType={batsmanType}
                  setPositionBatType={setPositionBatType}
                  parentMarkerData={parentMarkerData}
                />
              )}
              <div style={{ width: "100%", height: "55%" }}>
                <video width="320" height="100" controls style={{ height: '327px' }} key={videoSourcFile}>
                  <source src={videoSourcFile} type="video/mp4"></source>
                </video>
              </div>
            </div>
            <div className="bowlerButtonGroup" style={{ marginTop: '35%', display: bowler_type == 'Fast' ? 'block' : 'none' }}>
              <ButtonGroup
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '2px',
                  justifyContent: 'center',
                  marginLeft: '25px'
                }}
              >
                {fastBowlingTypesFirst.map((type, id) => (
                  <FormControlLabel
                    key={id}
                    value={type?.type}
                    control={<Button size="small" variant={selectedBallType === type?.type ? "contained" : "outlined"} onClick={setBallTypes(type?.type)} style={{ height: "35px", width: '80px' }} ><p style={{ fontSize: '9px' }}>{type?.type}</p></Button>}
                  />
                ))}
              </ButtonGroup>
            </div>
            <div className="bowlerButtonGroup" style={{ marginTop: '35%', display: ((bowler_type == 'Off Spin') || (bowler_type == 'Leg Spin')) ? 'block' : 'none', }}>
              <ButtonGroup
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '2px',
                  justifyContent: 'center',
                  marginLeft: '25px'
                }}
              >
                {spinBowlingTypesFirst.map((type, id) => (
                  <FormControlLabel
                    key={id}
                    value={type?.type}
                    control={<Button size="small" variant={selectedBallType === type?.type ? "contained" : "outlined"} onClick={setBallTypes(type?.type)} style={{ height: "35px", width: '80px' }} ><p style={{ fontSize: '9px' }}>{type?.type}</p></Button>}
                  />
                ))}
              </ButtonGroup>
            </div>
            <div className="adjustButtonGp" style={{
              position: 'relative',
              top: '100%',
              fontSize: '12px'
            }}>
              {innings == 2 && isRequiredRun ? (
                <>
                  {(isFirstInningWon == true) ? (
                    <>
                      <div className="font-semibold" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', backgroundColor: 'rgb(21 101 192 / 62%)', color: "#FFF", padding: '2px', textAlign: "center" }}>{`${battingTeamName}`} lost the match</div>
                    </>) :
                    <>
                      {(secondInnNeedRuns > 0) ? (
                        <>
                          <div className="font-semibold" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', backgroundColor: 'rgb(21 101 192 / 62%)', color: "#FFF", padding: '2px', textAlign: "center" }}>{`${battingTeamName}`} need {`${secondInnNeedRuns}`} {secondInnNeedRuns < 2 ? (<span>run</span>) : (<span>runs</span>)}  in {`${secondInnRemainingBalls}`}
                            {secondInnRemainingBalls < 2 ? (<span>ball</span>) : (<span> balls</span>)}
                          </div>
                        </>) :
                        (<>
                          <div className="font-semibold" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', backgroundColor: 'rgb(21 101 192 / 62%)', color: "#FFF", padding: '2px', textAlign: "center" }}>{`${battingTeamName}`} won the match </div>
                        </>
                        )}
                    </>
                  }
                </>
              ) : (<> </>)}
              <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7' }}>
                <thead>
                  <tr style={{
                    backgroundColor: '#04AA6D',
                    color: 'white'
                  }}>
                    <th>Delivery</th>
                    <th>Inning</th>
                    <th>Bowler</th>
                    <th>Striker</th>
                    <th>Non Striker</th>
                  </tr>
                </thead>
                <tbody>
                  {latestThreeBallLoader ? (
                    <tr >
                      <td colSpan="5"><div className="loader" style={{ padding: '15px', margin: 'auto' }}></div></td>
                    </tr>
                  ) : (
                    <>
                      {latest_three_ball_details.length != 0 ? (
                        <>
                          {latest_three_ball_details.map((match_detail, id) => (
                            <tr key={id}>
                              <td className="uppercase">
                                {((match_detail.extra_ball_delivery_result != "")) && ((match_detail.extra_ball_delivery_result == 'WD') || (match_detail.extra_ball_delivery_result == 'NB')) ? (
                                  <>
                                    {`${match_detail.current_ball}`} <span style={{ color: 'red' }}>{`(${match_detail.extra_ball_delivery_result})`}</span>
                                  </>
                                ) : (
                                  <>
                                    {match_detail.current_ball}
                                  </>
                                )}
                              </td>
                              <td>{match_detail.current_inning}</td>
                              <td>{match_detail.bowler_detail?.full_name}</td>
                              <td>{match_detail.bat_striker_detail?.full_name}</td>
                              <td>{match_detail.bat_nstriker_detail?.full_name}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}>Data not found</td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <div>
                {isOnline ? (
                  <div style={{ backgroundColor: speed > 10 ? 'green' : '#A10000', color: '#FFF', fontSize: '16px', textAlign: 'center' }}> <CheckCircleIcon color="success" style={{ color: '#FFF' }} /> You are online <span>
                    {!isCheckInternet ? (
                      <>
                        <button style={{ fontSize: '10px', background: '#1976d2', padding: '0px 8px', fontSize: '12px', fontWeight: 'bold', marginTop: '5px', borderRadius: '3px' }} className="btn btn-info"
                          title={`Check Internet Speed`} onClick={calculateInternetSpeedBtn(true)} >
                          <SignalWifi4BarIcon /> Check Internet Speed
                        </button>

                        <ReactTooltip
                          anchorId="checkInternetSpeedFast"
                          place="top"
                          html="
                        <h2 style='text-align:center;color:lightgreen;font-weight:600;'>Internet Speed - Fast.com</h2><hr/>
                        <h3 style='text-align:center;font-weight:600;'>You can check your internet speed using fast.com.<br/> A good internet speed is typically in the range of 20-25 Mbps or higher, according to fast.com.</h3>
                        "
                          className="tooltip"
                        />
                        <button style={{ fontSize: '10px', background: '#1976d2', padding: '0px 8px', fontSize: '12px', fontWeight: 'bold', marginLeft: '5px', marginTop: '5px', borderRadius: '3px' }} className="btn btn-info" id="checkInternetSpeedFast"
                          onClick={() => OpenSpeedTestModal()} >
                          <SignalWifi4BarIcon /> Check Internet Speed - Fast.com
                        </button>

                      </>
                    ) : (
                      <>
                        <button style={{ fontSize: '10px', background: 'orange', padding: '0px 8px', fontSize: '12px', fontWeight: 'bold', marginTop: '5px', borderRadius: '3px' }} className="btn btn-info"
                          title={`Stop Internet Speed`} onClick={calculateInternetSpeedBtn(false)} >
                          <SignalWifiOffIcon /> Stop Internet Speed
                        </button>

                        <ReactTooltip
                          anchorId="checkInternetSpeedFast"
                          place="top"
                          html="
                        <h2 style='text-align:center;color:lightgreen;font-weight:600;'>Internet Speed - Fast.com</h2><hr/>
                        <h3 style='text-align:center;font-weight:600;'>You can check your internet speed using fast.com.<br/> A good internet speed is typically in the range of 20-25 Mbps or higher, according to fast.com.</h3>
                        "
                          className="tooltip"
                        />
                        <button style={{ fontSize: '10px', background: 'orange', padding: '0px 8px', fontSize: '12px', fontWeight: 'bold', marginLeft: '5px', marginTop: '5px', borderRadius: '3px' }} className="btn btn-info"
                          id="checkInternetSpeedFast" onClick={() => OpenSpeedTestModal()} >
                          <SignalWifi4BarIcon /> Check Internet Speed - Fast.com
                        </button>
                      </>
                    )}
                  </span>
                    <br />
                    <span>Your internet speed is: {speed} Mbps</span></div>
                ) : (
                  <p style={{ backgroundColor: 'red', color: '#FFF', fontSize: '16px', textAlign: 'center' }}> <CancelIcon color="error" style={{ color: '#FFF' }} /> You are offline. Please check your internet connection.</p>
                )}
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-1 grid-cols-1 grid-rows-2" style={{
            background: '#d5fff7',
            borderRadius: " 45px",
            width: '75%',
            position: 'relative',
            left: "22%",
            height: '105%', marginBottom: '20%',
            marginTop: '4px', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)'
          }}>
            {battingBowlingImgLoader ? (
              <div className="loader" style={{ margin: 'auto', padding: '20px' }}></div>
            ) : (
              <BattingInput
                battingResult={battingResult}
                setBattingResult={setBattingResult}
                parentWagonMsgData={parentWagonMsgData}
                setBatsmanType={batsmanType}
                positionBatTypeBatInput={positionBatTypeBatInput}
                setPositionBatTypeBatInput={setPositionBatTypeBatInput}
                style={{ justifyContent: 'center' }}
              />
            )}
            <div style={{ height: '0vh', marginTop: '-15%' }}>
              <ButtonGroup sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                justifyContent: 'center',
                margin: '0px 0 4px 0px',
              }}>
                <Button style={{
                  padding: '8px', height: "35px", width: '48%'
                }} variant={foot === "Front Foot" ? "contained" : "outlined"} onClick={handleFoot('Front Foot')}><p style={{ fontSize: '9px' }}>Front Foot</p></Button>
                <Button style={{
                  padding: '8px', height: "35px", width: '44%',
                }} variant={foot === "Back Foot" ? "contained" : "outlined"} onClick={handleFoot('Back Foot')}><p style={{ fontSize: '9px' }}>Back Foot</p></Button>
              </ButtonGroup>
              {frontBackFootSkillLoader ? (
                <div className="loader" style={{
                  padding: '30px',
                  margin: '70px 150px'
                }}></div>
              ) : (
                <ButtonGroup
                  value={batType}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '7px',
                    paddingLeft: '22px'
                  }}
                >
                  {battingTypes.map((type, id) => (
                    <FormControlLabel
                      key={id}
                      value={type?.type}
                      control={<Button size="small" variant={batType === type?.type ? "contained" : "outlined"} onClick={handleBatType(type?.type)} style={{ height: "35px", width: '50px' }} ><p style={{ fontSize: '9px' }}>{type?.type}</p></Button>} />
                  ))}
                </ButtonGroup>
              )}
              <ButtonGroup
                variant="outlined"
                size="Large"
                aria-label="outlined button group"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '5px',
                  justifyContent: 'center',
                  margin: '4px 0 4px 0px'
                }}
              >
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "0" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("0")}
                >
                  0️⃣
                </Button>
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "1" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("1")}
                >
                  1️⃣
                </Button>
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "2" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("2")}
                >
                  2️⃣
                </Button>
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "3" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("3")}
                >
                  3️⃣
                </Button>
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "4" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("4")}
                >
                  4️⃣
                </Button>
                <Button style={{ height: "25px", width: '42px' }}
                  variant={ballResult === "6" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("6")}
                >
                  6️⃣
                </Button>
                <Button id="Wicket" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={ballResult === "W" ? "contained" : "outlined"}
                  onClick={handleSetBallResult("W")}
                >
                  W
                </Button>
              </ButtonGroup>
              {ballResult === "W" && (
                <ButtonGroup className="p-1" variant="outlined" size="large" aria-label="outlined button group" sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '2px',
                  margin: '0px 0 4px 0px'
                }}>
                  <Button id="caughtOutW" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "caught" ? "contained" : "outlined"}
                    onClick={handleWicketType("caught")}>C</Button>
                  <ReactTooltip
                    anchorId="caughtOutW"
                    place="top"
                    content="Caught"
                    className="tooltip"
                  />
                  <Button id="bowled" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "bowled" ? "contained" : "outlined"}
                    onClick={handleWicketType("bowled")}>B</Button>
                  <ReactTooltip
                    anchorId="bowled"
                    place="top"
                    content="Bowled"
                    className="tooltip"
                  />
                  <Button id="LBW" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "lbw" ? "contained" : "outlined"}
                    onClick={handleWicketType("lbw")}>LBW</Button>
                  <ReactTooltip
                    anchorId="LBW"
                    place="top"
                    content="Leg Before Wicket"
                    className="tooltip"
                  />
                  <Button id="Stumped" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "stumped" ? "contained" : "outlined"}
                    onClick={handleWicketType("stumped")}>S</Button>
                  <ReactTooltip
                    anchorId="Stumped"
                    place="top"
                    content="Stumped"
                    className="tooltip"
                  />
                  <Button id="Obstracting" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "obstracting" ? "contained" : "outlined"}
                    onClick={handleWicketType("obstracting")}>O</Button>
                  <ReactTooltip
                    anchorId="Obstracting"
                    place="top"
                    content="Obstracting"
                    className="tooltip"
                  />
                  <Button id="HitWicket" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "hitwicket" ? "contained" : "outlined"}
                    onClick={handleWicketType("hitwicket")}>HW</Button>
                  <ReactTooltip
                    anchorId="HitWicket"
                    place="top"
                    content="Hit Wicket"
                    className="tooltip"
                  />
                  <Button id="TimeOut" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "timeOut" ? "contained" : "outlined"}
                    onClick={handleWicketType("timeOut")}>TO</Button>
                  <ReactTooltip
                    anchorId="TimeOut"
                    place="top"
                    content="Time Out"
                    className="tooltip"
                  />
                  <Button id="HitBallTwice" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "hitBallTwice" ? "contained" : "outlined"}
                    onClick={handleWicketType("hitBallTwice")}>HBT</Button>
                  <ReactTooltip
                    anchorId="HitBallTwice"
                    place="top"
                    content="Hit Ball Twice"
                    className="tooltip"
                  />
                  <Button id="RetiredHurt" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "retiredOut" ? "contained" : "outlined"}
                    onClick={handleWicketType("retiredOut")}>RH</Button>
                  <ReactTooltip
                    anchorId="RetiredHurt"
                    place="top"
                    content="Retired Hurt"
                    className="tooltip"
                  />
                  <Button id="RunOut" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                    variant={wicketType === "runOut" ? "contained" : "outlined"}
                    onClick={handleWicketType("runOut")}>RO</Button>
                  <ReactTooltip
                    anchorId="RunOut"
                    place="top"
                    content="Run Out"
                    className="tooltip"
                  />
                </ButtonGroup>
              )}
              {showRunOutButtons && (
                <ButtonGroup
                  variant="outlined"
                  size="large"
                  aria-label="outlined button group"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '4px',
                    margin: '0px 0 4px 5px'
                  }}
                >
                  <Button style={{ height: "25px", width: '42px' }}
                    variant={runOutRunResult === "0" ? "contained" : "outlined"}
                    onClick={handleRunOutRun("0")}>
                    0️⃣
                  </Button>
                  <Button style={{ height: "25px", width: '42px' }}
                    variant={runOutRunResult === "1" ? "contained" : "outlined"}
                    onClick={handleRunOutRun("1")}>
                    1️⃣
                  </Button>
                  <Button style={{ height: "25px", width: '42px' }}
                    variant={runOutRunResult === "2" ? "contained" : "outlined"}
                    onClick={handleRunOutRun("2")}>
                    2️⃣
                  </Button>
                  <Button style={{ height: "25px", width: '42px' }}
                    variant={runOutRunResult === "3" ? "contained" : "outlined"}
                    onClick={handleRunOutRun("3")}>
                    3️⃣
                  </Button>
                </ButtonGroup>)}
              <ButtonGroup sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                justifyContent: 'start', margin: '0px 0 4px 7px', borderRadius: '0'
              }}>
                <ReactTooltip
                  anchorId="Wicket"
                  place="top"
                  content="Wicket"
                  className="tooltip"
                />
                <Button id="Appeal" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={IsAppeal === true ? "contained" : "outlined"}
                  onClick={toggleShowIsAppeal}>
                  A
                </Button>
                <ReactTooltip
                  anchorId="Appeal"
                  place="top"
                  content="Appeal"
                  className="tooltip"
                />
                <Button id="Beaten" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={IsBeaten == true ? "contained" : "outlined"}
                  onClick={toggleShowIsBeaten}>B
                </Button>
                <ReactTooltip
                  anchorId="Beaten"
                  place="top"
                  content="Beaten"
                  className="tooltip"
                />
                <Button id="WicketTaking" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={IsWicketTaking === true ? "contained" : "outlined"}
                  onClick={toggleShowIsWicketTaking}>
                  WT
                </Button>
                <ReactTooltip
                  anchorId="WicketTaking"
                  place="top"
                  content="Wicket Taking"
                  className="tooltip"
                />
                <Button id="BatsmanUncomfortable" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={IsBatsmanUncomfortable == true ? "contained" : "outlined"}
                  onClick={toggleShowIsBatsmanUncomfortable}>
                  BU
                </Button>
                <ReactTooltip
                  anchorId="BatsmanUncomfortable"
                  place="top"
                  content="Batsman Uncomfortable"
                  className="tooltip"
                />
                <Button id="WdBall" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={extraBallResult === "WD" ? "contained" : "outlined"}
                  onClick={handleSetExtraBallResult("WD")}
                >
                  WD
                </Button>
                <ReactTooltip
                  anchorId="WdBall"
                  place="top"
                  content="Wide Ball"
                  className="tooltip"
                />
                <Button id="NoBall" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={extraBallResult === "NB" ? "contained" : "outlined"}
                  onClick={handleSetExtraBallResult("NB")}
                >
                  NB
                </Button>
                <ReactTooltip
                  anchorId="NoBall"
                  place="top"
                  content="No Ball"
                  className="tooltip"
                />
                <Button id="LBBall" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={extraBallResult === "LB" ? "contained" : "outlined"}
                  onClick={handleSetExtraBallResult("LB")}
                >
                  LB
                </Button>
                <ReactTooltip
                  anchorId="LBBall"
                  place="top"
                  content="Leg By"
                  className="tooltip"
                />
                <Button id="byes" style={{ height: "25px", width: '42px', fontSize: '9px' }}
                  variant={extraBallResult === "B" ? "contained" : "outlined"}
                  onClick={handleSetExtraBallResult("B")}
                >
                  B
                </Button>
                <ReactTooltip
                  anchorId="byes"
                  place="top"
                  content="Byes"
                  className="tooltip"
                />
              </ButtonGroup>
              <ButtonGroup sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                margin: '0px 0 4px 5px'
              }}>
                <Button style={{
                  padding: '8px', height: "35px", width: '48%', backgroundColor: '#04AA6D',
                  color: 'white'
                }} onClick={() => OpenCustomRunsModal()}><p style={{ fontSize: '9px' }}>Custom Runs{isCustomRuns ? (<b>({customRuns})</b>) : (<></>)}</p></Button>
                <Button style={{
                  padding: '8px', height: "35px", width: '48%', backgroundColor: '#FF0000',
                  color: 'white'
                }} onClick={handleResetClick}><p style={{ fontSize: '9px' }}>Reset Extras</p></Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '80px', }}></div>
      {showRunoutModalWindow ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    Batsman - {battingTeamName}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowRunoutModalWindow(false)}
                  ><ClearIcon />
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  {strikerId ? (
                    <>
                      <div className="font-semibold">Which batsman is runout?</div>
                      <Button id=""
                        variant={runout_batsman_id == strikerId ? "contained" : "outlined"}
                        value={strikerId}
                        onClick={handleRunout}
                      >
                        {strikerName ? strikerName : 'Select striker'}
                      </Button>&nbsp;
                      <Button id=""
                        variant={runout_batsman_id == nonStrikerId ? "contained" : "outlined"}
                        value={nonStrikerId}
                        onClick={handleRunout}
                      >
                        {nstrikerName ? nstrikerName : 'Select non striker'}
                      </Button>
                    </>
                  ) : (
                    <><span style={{ color: '#FF0000' }}>Please select striker and non-striker for runout bastman selection</span></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showManOfTheMatch ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    <span style={{ color: 'rgb(4, 170, 109)' }}>{battingTeamName}</span> VS. <span style={{ color: 'rgb(4, 170, 109)' }}>{ballingTeamName}</span>
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={(e) => setShowManOfTheMatch(false)}
                  ><ClearIcon />
                  </button>
                </div>
                <div className="relative p-6 flex-auto" style={{
                  height: "80vh", width: "100%",
                  overflow: 'auto',
                  paddingTop: '0', textAlign: 'center', margin: 'auto'
                }}>
                  <>
                    <span > <b style={{ color: "rgb(4, 170, 109)" }}>Promising Talent</b>
                    </span>
                    <div className="grid lg:grid-cols-2 grid-cols-2 grid-rows-1 gap-5" >
                      <div>
                        <table className="table-auto custom-table">
                          <thead>
                            <tr style={{
                              backgroundColor: '#04AA6D',
                              color: 'white',
                              fontSize: '10px'
                            }}>
                              <th>{battingTeamName}</th>
                              <th style={{ textAlign: 'center', width: '75px' }}><p>Select</p></th>
                            </tr>
                          </thead>
                          <tbody>
                            {PromicingPlayerListTeamA.length != 0 ? (
                              <>
                                {PromicingPlayerListTeamA.map((player, index) => (
                                  <tr>
                                    <td key={index} >{player.user_detail.full_name}</td>
                                    <td style={{ textAlign: 'center' }}> <Checkbox style={{ padding: '0px' }} checked={player.is_promicing_player} onChange={(e) => handleToggle(e, player.user_detail.id, player.teamId, index, 'teamA')} /></td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={2}>Data not found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <table className="table-auto custom-table" style={{ width: '350px' }}>
                          <thead>
                            <tr style={{
                              backgroundColor: '#04AA6D',
                              color: 'white',
                              fontSize: '10px'
                            }}>
                              <th>{ballingTeamName}</th>
                              <th style={{ textAlign: 'center', width: '75px' }}><p>Select</p></th>
                            </tr>
                          </thead>
                          <tbody>
                            {PromicingPlayerListTeamB.length != 0 ? (
                              <>
                                {PromicingPlayerListTeamB.map((player, index) => (
                                  <tr>
                                    <td key={index} >{player.user_detail.full_name}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      <Checkbox style={{ padding: '0px' }} checked={player.is_promicing_player} onChange={(e) => handleToggle(e, player.user_detail.id, player.teamId, index, 'teamB')} />
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={2}>Data not found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button"
                    onClick={() => setShowManOfTheMatch(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="submit"
                    onClick={submitManOfTheMatch}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="grid lg:grid-cols-2 grid-cols-2 grid-rows-1" style={{
        display: 'flex', position: 'fixed', bottom: '0', left: '0',
        width: '100%', background: 'white', justifyItems: 'center', justifyContent: 'center'
      }}>
        <div id="RestartAltIcon" className="sliderMargin sliderButton"
          onClick={handleReset} style={{ cursor: 'pointer', borderColor: '#1976d2' }}><RestartAltIcon sx={{ fontSize: "20px", marginTop: '12px', color: '#1976d2' }} /><br /><span style={{ fontSize: '10px', color: '#1976d2', fontWeight: 'bold' }}>Reset</span></div>
        <ReactTooltip
          anchorId="RestartAltIcon"
          place="top"
          content="Reset"
          className="tooltip"
        />
        <div id="SportsCricketIcon" className="sliderMargin sliderButton"
          onClick={handleModalSwitchOvers} style={{ cursor: 'pointer', borderColor: '#1976d2' }}><SportsCricketIcon sx={{ fontSize: "20px", marginTop: '25px', color: '#1976d2' }} /></div>
        <ReactTooltip
          anchorId="SportsCricketIcon"
          place="top"
          content="Overs Analysis"
          className="tooltip"
        />
        {eighteenballLoader ? (
          <div className="loader" style={{ margin: 'auto', padding: '10px', margin: '24px 5px' }}></div>
        ) : (<>
          {latestEighteenBall.length != 0 ? (
            <>
              {latestEighteenBall.map((ball, id) => (
                <div id="caughtOut" className={`sliderMargin sliderButton ${selectType === ball.id ? 'selected' : ''}`}
                  style={{
                    margin: '1px', color: `${ball.is_neglect_ball == 'no' ? 'floralwhite' : 'gray'}`, padding: `${ball.is_neglect_ball == 'no' ? '0px 4px' : '0px 18px'}`, border: '3px solid rgb(76, 175, 80)', background: `${ball.is_neglect_ball == 'no' ? ball.ball_box_color : 'lightgray'}`, ...(selectType === ball.id ? {
                      border: '5px solid yellow'
                    } : {}),
                  }} key={id}>

                  {ball.is_neglect_ball == 'no' ? <>
                    {ball.ball_delivery_result != 'W' ? (
                      <span onClick={handleSwitchBall(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                        {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                      </span>) : (
                      <span onClick={handleSwitchBall(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>
                        {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                      </span>)}
                    <br />
                    <span onClick={handleSwitchBall(ball.id, ball.current_ball)} style={{ cursor: 'pointer' }}>{ball.current_ball}</span>
                  </> :
                    <>
                      {ball.ball_delivery_result != 'W' ? (
                        <span>
                          {ball.ball_delivery_result}{ball.extra_ball_delivery_result}
                        </span>) : (
                        <span>
                          {ball.extra_ball_delivery_result}({ball.ball_delivery_result}{ball.run_out_ball_delivery_result})
                        </span>)}
                      <br />
                      <span>{ball.current_ball}</span>
                    </>
                  }
                  <br />
                  <span>
                    {ball.is_neglect_ball == 'no' ? <>{ball.is_bookmark != 'no' ? (
                      <span style={{ marginRight: '-3px' }}>
                        <StarIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallBookmark(ball.id, 'no')} />
                      </span>) : (
                      <span style={{ marginRight: '-3px' }}>
                        <StarBorderIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallBookmark(ball.id, 'yes')} />
                      </span>)}</> : ''}


                    {ball.is_neglect_ball == 'no' ? <> {ball.is_force_to_complete != 'no' ? (
                      <span style={{ marginRight: '4px' }}>
                        <CheckCircleIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallTag(ball.id, 'no')} />
                      </span>) : (
                      <span style={{ marginRight: '4px' }}>
                        <RadioButtonUncheckedIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallTag(ball.id, 'yes')} />
                      </span>)}</> : ''}

                    {ball.is_neglect_ball != 'no' ? (
                      <span>
                        <ThumbDownIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallNeglect(ball.id, 'no')} />
                      </span>) : (
                      <span>
                        <ThumbUpIcon style={{ width: '16px', cursor: 'pointer' }} onClick={() => handleSwitchBallNeglect(ball.id, 'yes')} />
                      </span>)}
                  </span>
                </div>
              ))}
            </>
          ) : (
            <div></div>
          )}
        </>)}
        <div id="SaveIcon" className="sliderMargin sliderButton"
          onClick={handleNext("next", "")}
          style={{
            borderColor: '#1976d2',
            cursor: 'pointer',
            opacity: disableNextButton ? 0.5 : 1,
          }}
          disabled={disableNextButton}
        ><SaveIcon sx={{ fontSize: "20px", marginTop: '12px', color: '#1976d2' }} /><br /><span style={{ fontSize: '11px', color: '#1976d2', fontWeight: 'bold' }}>Next<br />Ball</span></div>
        <ReactTooltip
          anchorId="SaveIcon"
          place="top"
          content="Save & Next"
          className="tooltip"
        />
      </div>
      <LoadingIndicator loading={isLoading} />
      <VideoPlayerDialog videoInfo={openVideoPlayer} handleClose={closeVideoPlayer} />
      <form onSubmit={handleCustomRunsModalSubmit}>
        {customRunsModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Custom Runs
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setCustomRunsModal(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <input
                          type="text"
                          value={customRuns}
                          placeholder="Custom Runs"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          onChange={handleCustomRuns(customRuns)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setCustomRunsModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form>
        {showLiveUmpireModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-4xl" style={{ width: '800px' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white h-full outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t" style={{ padding: '10px' }}>
                    <h3 className="text-3xl font-semibold" style={{ fontSize: '19px' }}>
                      Umpires (Firebase Database)
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setshowLiveUmpireModal(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 30px' }}>
                        <th style={{ fontSize: '14px', background: 'darkgray' }}>Cam Umpire 1 {` (${navData?.Camera1User.name})`}</th>
                        <tbody>
                          <>
                            {cameraFirstAvailable?.map(item => (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E' }}>{item.document_name}</td>
                              </tr>
                            ))}
                            {cameraFirstAvailable.length === 0 ? (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E', color: 'green' }}>Umpire Is Now Available</td>
                              </tr>
                            ) : null}
                          </>
                        </tbody>
                      </table>
                      <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 30px' }}>
                        <th style={{ fontSize: '14px', background: 'darkgray' }}>Cam Umpire 2 {` (${navData?.Camera2User.name})`}</th>
                        <tbody>
                          <>
                            {cameraSecondAvailable?.map(item => (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E' }}>{item.document_name}</td>
                              </tr>
                            ))}
                            {cameraSecondAvailable.length === 0 ? (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E', color: 'green' }}>Umpire Is Now Available</td>
                              </tr>
                            ) : null}
                          </>
                        </tbody>
                      </table>
                    </div>
                    <div style={{ display: "flex" }}>
                      <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 30px' }}>
                        <th style={{ fontSize: '14px', background: 'darkgray' }}>Live Umpire 1 {` (${navData?.Camera1User.name})`}</th>
                        <tbody>
                          <>
                            {cameraLiveFirstAvailable?.map(item => (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E' }}>{item.document_name}</td>
                              </tr>
                            ))}
                            {cameraLiveFirstAvailable.length === 0 ? (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E', color: 'green' }}>Umpire Is Now Available</td>
                              </tr>
                            ) : null}
                          </>
                        </tbody>
                      </table>
                      <table style={{ border: '1px solid rgb(189 189 189)', margin: '11px 30px' }}>
                        <th style={{ fontSize: '14px', background: 'darkgray' }}>Live Umpire 2 {` (${navData?.Camera2User.name})`}</th>
                        <tbody>
                          <>
                            {cameraLiveSecondAvailable?.map(item => (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E' }}>{item.document_name}</td>
                              </tr>
                            ))}
                            {cameraLiveSecondAvailable.length === 0 ? (
                              <tr>
                                <td style={{ border: '1px solid #9E9E9E', color: 'green' }}>Umpire Is Now Available</td>
                              </tr>
                            ) : null}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form onSubmit={handleBowlerModalSubmit}>
        {showBowlerModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Bowler - {ballingTeamName}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowBowlerModal(false)}
                    ><ClearIcon />
                    </button>
                  </div>

                  <div className="relative p-2 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Full Name<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={BowlerPlayerFullname}
                          placeholder="Name"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          onChange={handleBowlerPlayerFullname(BowlerPlayerFullname)}
                        />
                      </div>

                      <div className="form-control">
                        <label
                          htmlFor="overs"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Mobile No.<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="number"
                          id="overs"
                          value={BowlerPlayerMobile}
                          placeholder="Mobile No."
                          onChange={handleBowlerPlayerMobile(BowlerPlayerMobile)}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        />
                      </div>

                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Batting<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={BowlerPlayerBatting} onChange={handleBowlerPlayerBatting(BowlerPlayerBatting)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Batting Type</option>
                          {batting_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Bowling<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={BowlerPlayerBowling} onChange={handleBowlerPlayerBowling(BowlerPlayerBowling)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Bowling Type</option>
                          {bowling_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setShowBowlerModal(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"
                    >
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitBowlerDisabled}>
                      {submitBowlerDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form onSubmit={handleBatsmanModalSubmit}>
        {showBatsmanModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Batsman - {battingTeamName}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowBatsmanModal(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Full Name<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={BatsmanPlayerFullname}
                          placeholder="Name"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          onChange={handleBatsmanPlayerFullname(BatsmanPlayerFullname)}
                        />
                      </div>

                      <div className="form-control">
                        <label
                          htmlFor="overs"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Mobile No.<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="number"
                          id="overs"
                          value={BatsmanPlayerMobile}
                          placeholder="Mobile No."
                          onChange={handleBatsmanPlayerMobile(BatsmanPlayerMobile)}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        />
                      </div>

                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Batting<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={BatsmanPlayerBatting} onChange={handleBatsmanPlayerBatting(BatsmanPlayerBatting)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Batting Type</option>
                          {batting_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Bowling<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={BatsmanPlayerBowling} onChange={handleBatsmanPlayerBowling(BatsmanPlayerBowling)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Bowling Type</option>
                          {bowling_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setShowBatsmanModal(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"
                    >
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitBatsmanDisabled}>
                      {submitBatsmanDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form onSubmit={handleAppUserModalSubmit("Bowler")}>
        {showPlayerEditModal2 ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Bowler - {ballingTeamName}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowPlayerEditModal2(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-2 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Players<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={appUser} onChange={handleAppUser(appUser)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Player</option>
                          {appUserList.map((appuser, index) => (
                            <option key={appuser.id} value={appuser.id}>
                              {appuser.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setShowPlayerEditModal2(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"

                    >
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitAppUserDisabled}>
                      {submitAppUserDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form onSubmit={handleAppUserModalSubmit("Batsman")}>
        {showPlayerEditModal3 ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Batsman - {battingTeamName}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowPlayerEditModal3(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Players<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={appUser} onChange={handleAppUser(appUser)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Player</option>
                          {appUserList.map((appuser, index) => (
                            <option key={appuser.id} value={appuser.id}>
                              {appuser.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setShowPlayerEditModal3(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="submit"
                    >
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitAppUserDisabled}>
                      {submitAppUserDisabled ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

      <form>
        {showSpeedTestModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h3 className=" font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                      Speed Test - Fast.com
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowSpeedTestModal(false)}
                    ><ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-1">
                      <iframe src="https://fast.com/" height={"400px"} width={"300px"} />
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                      type="button"
                      onClick={() => setShowSpeedTestModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>

    </>
  );
};
export default AnalysisInput;

const RetryHandler = ({ file, difference = 0.5 * 60, onRetry, fetchVideos }) => {
  const timerRef = useRef(null);
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (countDown !== null && countDown < difference) {
      timerRef.current = setInterval(() => {
        setCountDown(countDown + 1);
      }, 1000);
    }

    if (countDown === difference) {
      setCountDown(null);
      fetchVideos();
    }
    return () => {
      clearInterval(timerRef.current);
    }
  }, [countDown]);

  const onRetryClick = (file) => event => {
    onRetry(file).then(res => {
      setCountDown(0);
    });
  }

  return (
    <>
      {
        countDown ? <CircularProgress size={15} />
          :
          <>
            <IconButton edge="end" aria-label="download" size="small" title={`Retry Upload ${file?.filepath}`} onClick={onRetryClick(file)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          </>
      }
    </>
  )
}