import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addPlayerFeeDetails, fetchPlayerFeeDetails } from "../../Helper";
import Creatable from "react-select/creatable";

const AddFees = ({
  selectedPlayerIndex,
  feeDataArray,
  setFeeDataArray,
  setIsFeeDataLoading,
  // isActiveSubscriptionListLoading,
  // setIsActiveSubscriptionListLoading,
  isActiveSubscriptionList,
  // setIsActiveSubscriptionList,
  addedNewSubscriptionReload,
  setAddNewSubscriptionReload,
}) => {
  const institution_id = sessionStorage.getItem("loggedUserInstitutionID");
  const [addFeesToggle, setAddFeesToggle] = useState(false);
  const [fee, setFee] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountReason, setDiscountReason] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [validFrom, setValidFrom] = useState(new Date());
  // Calculate validTill as validFrom + 1 month
  const validTillDate = new Date(validFrom);
  validTillDate.setMonth(validTillDate.getMonth() + 1);
  validTillDate.setDate(validTillDate.getDate() - 1);
  const [validTill, setValidTill] = useState(validTillDate);
  const [submitStatus, setSubmitStatus] = useState("");
  const [submitStatusToggle, setSubmitStatusToggle] = useState(false);
  const [selectPlayerWarningBool, setSelectPlayerWarningBool] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] =
    useState("");
  const [selectedPlanDataObject, setSelectedPlanDataObject] = useState(null);

  const handleSubscriptionSelect = (selectedOption) => {
    if (selectedOption) {
      const selectedPlanData = isActiveSubscriptionList.find(
        (plan) => plan.plan_name === selectedOption.label
      );

      setSelectedSubscriptionOption({
        plan_id: selectedPlanData.id,
        plan_name: selectedOption.label,
        // payment_duration: selectedPlanData.payment_duration,
      });
      setSelectedSubscriptionPlan(selectedPlanData.id);
      console.log(selectedPlanData);
      setSelectedPlanDataObject(selectedPlanData);
    } else {
      setSelectedSubscriptionOption("");
      setSelectedSubscriptionPlan(null);
    }
  };

  const handleAddFeesToggleChange = () => {
    if (selectedPlayerIndex) {
      setAddFeesToggle(true);
    } else {
      setSelectPlayerWarningBool(true);
      setTimeout(hideAddFeeWarning, 1500);
    }
  };
  const handleFeeChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setFee(numericInput);
  };

  const handleDiscountChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setDiscount(numericInput);
  };
  const handleDiscountReasonChange = (e) => {
    setDiscountReason(e.target.value);
  };
  const handlePaymentDateChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setPaymentDate(utcDate);
  };
  const handleValidFromChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidFrom(utcDate);
  };
  const handleValidTillChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidTill(utcDate);
  };
  const hideSubmitMessage = () => {
    setSubmitStatusToggle(false);
    setSubmitStatus("");
  };
  const hideAddFeeWarning = () => {
    setSelectPlayerWarningBool(false);
  };

  const handleSaveFees = async () => {
    const feeData = {
      selectedPlayerIndex,
      institution_id,
      fee,
      discount,
      discountReason,
      paymentDate,
      validFrom,
      validTill,
      selectedSubscriptionPlan,
    };

    try {
      const res = await addPlayerFeeDetails(feeData);
      console.log("res:", res);
      if (res.status == true) {
        setAddFeesToggle(false);
        setSubmitStatus(res.message);
        setSubmitStatusToggle(true);
        setTimeout(hideSubmitMessage, 1000);
        try {
          setIsFeeDataLoading(true);
          const data = await fetchPlayerFeeDetails(selectedPlayerIndex);
          setFeeDataArray(data);
          setAddNewSubscriptionReload(addedNewSubscriptionReload + 1);
        } catch (error) {
          console.log("Error in fetchPlayerFeeDetails:", error.message);
        } finally {
          setIsFeeDataLoading(false); // Set loading to false regardless of success or error
          handleCancelClick();
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleCancelClick = () => {
    setAddFeesToggle(false);
    setFee("");
    setDiscount("");
    setDiscountReason("");
    setPaymentDate(new Date());
    setValidFrom(new Date());
    // validtill reset is handled by changeValidTillAsPerInputs and corresponding useEffect
    setSelectedSubscriptionOption("");
    setSelectedSubscriptionPlan("");
  };

  const changeValidTillAsPerInputs = () => {
    if (selectedPlanDataObject) {
      const netFeesToBePaidPerMonthAfterAddingNewDiscount =
        (selectedPlanDataObject.fee -
          selectedPlanDataObject.total_discount -
          discount) /
        12;
      // following ternary is a special case which handles validtill in case of cancel click
      const feeToNetFeesToBePaidPerMonthAfterAddingNewDiscountRatio =
        Math.ceil(fee / netFeesToBePaidPerMonthAfterAddingNewDiscount) < 1
          ? 1
          : Math.ceil(fee / netFeesToBePaidPerMonthAfterAddingNewDiscount);

      const newValidTill = new Date(validFrom);
      newValidTill.setMonth(
        newValidTill.getMonth() +
          feeToNetFeesToBePaidPerMonthAfterAddingNewDiscountRatio
      );
      newValidTill.setDate(newValidTill.getDate() - 1);
      setValidTill(newValidTill);
    }
  };

  useEffect(() => {
    if (selectedPlanDataObject) {
      // find all fees paid for selected plan data object from fee history
      const feeListForSelectedPlan = feeDataArray.filter((feeData) => {
        return feeData.active_plan_id == selectedPlanDataObject.id;
      });
      // get the latest valid till date in that
      const validTillValues = feeListForSelectedPlan.map(
        (feeData) => feeData.valid_till
      );
      const validTillValuesInDateFormat = validTillValues.map(
        (validTillString) => new Date(validTillString)
      );
      const maxValidTill = new Date(Math.max(...validTillValuesInDateFormat));
      console.log(maxValidTill);
      // increment this date by one and set it as new valid from date as default
      maxValidTill.setDate(maxValidTill.getDate() + 1);
      setValidFrom(maxValidTill);
    }
  }, [selectedPlanDataObject]);

  useEffect(() => {
    changeValidTillAsPerInputs();
  }, [fee, discount, validFrom]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <button
          className={`w-36 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            !addFeesToggle ? "" : "hidden"
          }`}
          onClick={handleAddFeesToggleChange}
        >
          Add Fees
        </button>
      </div>
      <div
        className={`grid grid-cols-10 place-content-center gap-4 ${
          addFeesToggle ? "" : "hidden"
        }`}
      >
        <div className="col-span-2 z-30 text-center grid items-center align-center justify-items-stretch">
          <Creatable
            options={isActiveSubscriptionList
              // .filter((plan) => new Date(plan.valid_till) > new Date())
              .map((subscription) => ({
                label: subscription.plan_name,
                value: subscription.plan_name,
              }))}
            placeholder="Choose subscription"
            value={
              selectedSubscriptionOption
                ? {
                    label: selectedSubscriptionOption.plan_name,
                    value: selectedSubscriptionOption.plan_name,
                  }
                : null
            }
            onChange={handleSubscriptionSelect}
            isSearchable={true}
            isCreatable={false}
            isRequired
            className="col-span-1 items-center"
          />
        </div>
        <div className="col-span-1 flex flex-col items-center">
          <div>Fee</div>
          <input
            type="text"
            id="fees"
            value={fee}
            onChange={handleFeeChange}
            required
            className="col-span-2 p-1 rounded text-center w-full border border-black"
            placeholder="Fee(₹)"
          />
        </div>
        <div className="col-span-1 flex flex-col items-center">
          <div>Discount</div>
          <input
            type="text"
            id="discount"
            value={discount}
            onChange={handleDiscountChange}
            required
            className="col-span-2 p-1 rounded text-center w-full border border-black"
            placeholder="Discount(₹)"
          />
        </div>
        <div className="col-span-1 flex flex-col items-center">
          <div>Discount Reason</div>
          <input
            type="text"
            id="reason for discount"
            value={discountReason}
            onChange={handleDiscountReasonChange}
            required
            className="col-span-2 p-1 rounded text-center w-full border border-black"
            placeholder="Reason for discount."
          />
        </div>
        <div className="z-20 flex flex-col items-center">
          <div>Payment Date</div>
          <div>
            <DatePicker
              selected={paymentDate}
              onChange={handlePaymentDateChange}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              className="flex justify-center text-center w-full rounded border border-black p-1"
            />
          </div>
        </div>
        <div className="z-20 flex flex-col items-center">
          <div>Valid from</div>
          <div>
            <DatePicker
              selected={validFrom}
              onChange={handleValidFromChange}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              className="flex justify-center text-center w-full rounded border border-black p-1"
            />
          </div>
        </div>
        <div className="z-20 flex flex-col items-center">
          <div>Valid till</div>
          <div>
            <DatePicker
              selected={validTill}
              onChange={handleValidTillChange}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              placeholderText=""
              className="flex justify-center text-center w-full rounded border border-black p-1"
            />
          </div>
        </div>
        <div className="flex gap-2 col-span-1 justify-end items-center">
          <button
            className="bg-green-500 hover:bg-green-400 p-1 rounded p-2"
            onClick={handleSaveFees}
          >
            Save
          </button>
          <button
            className="bg-red-600 hover:bg-red-500 p-1 rounded p-2"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
      {submitStatusToggle ? (
        <div className="rounded text-center bg-green-500">{submitStatus}</div>
      ) : null}
      {selectPlayerWarningBool ? (
        <div className="rounded text-center bg-red-500">
          Select a player to add fees!
        </div>
      ) : null}
    </>
  );
};

export default AddFees;
