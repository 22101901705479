export const ADMIN_MENU = [
  {
    MAIN_MENU: "Admin Dashboard",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "admin_dashboard",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Video",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "video",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Notifications",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "notifications",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Announcement",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "announcement",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Match Center Configuration",
    SUB_MENU: "New Tournament",
    UNIQUE_IDENTIFIER:
      "match_center_configuration_new_tournament_new_tournament",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Match Center Configuration",
    SUB_MENU: "New Match",
    UNIQUE_IDENTIFIER: "match_center_configuration_new_tournament_new_match",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Match Center Configuration",
    SUB_MENU: "New Analysis",
    UNIQUE_IDENTIFIER: "match_center_configuration_new_tournament_new_analysis",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Match Center Configuration",
    SUB_MENU: "Previous Match Data",
    UNIQUE_IDENTIFIER:
      "match_center_configuration_new_tournament_previous_match_data",
    CHECKED: false,
  },

  {
    MAIN_MENU: "Training Program",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "training_program",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "New Player Registration",
    UNIQUE_IDENTIFIER: "institutions_new_player_registration",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "Player Register",
    UNIQUE_IDENTIFIER: "institutions_player_register",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "Player Fee Details",
    UNIQUE_IDENTIFIER: "institutions_player_fee_details",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "Setup Fees",
    UNIQUE_IDENTIFIER: "institutions_setup_fees",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "Attendance Reports",
    UNIQUE_IDENTIFIER: "institutions_attendance_reports",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Institutions",
    SUB_MENU: "Membership Reports",
    UNIQUE_IDENTIFIER: "institutions_membership_reports",
    CHECKED: false,
  },

  {
    MAIN_MENU: "My Authorizations",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "my_authorizations",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Role and Access",
    SUB_MENU: "",
    UNIQUE_IDENTIFIER: "role_and_access",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Error Logs",
    SUB_MENU: "API Error Log",
    UNIQUE_IDENTIFIER: "error_logs_api_error_log",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Error Logs",
    SUB_MENU: "Match Analysis Error Log",
    UNIQUE_IDENTIFIER: "error_logs_match_analysis_error_log",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Error Logs",
    SUB_MENU: "AWS Upload Error Log",
    UNIQUE_IDENTIFIER: "error_logs_aws_upload_error_log",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Error Logs",
    SUB_MENU: "Record Video Log",
    UNIQUE_IDENTIFIER: "error_logs_record_video_log",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Monitoring",
    SUB_MENU: "Practice Video",
    UNIQUE_IDENTIFIER: "monitoring_practice_video",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Monitoring",
    SUB_MENU: "Training Program",
    UNIQUE_IDENTIFIER: "monitoring_training_program",
    CHECKED: false,
  },
  {
    MAIN_MENU: "Monitoring",
    SUB_MENU: "Institution Video",
    UNIQUE_IDENTIFIER: "monitoring_institution_video",
    CHECKED: false,
  },
];
