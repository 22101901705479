import React from "react";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

const LoadingIndicator = ({ loading }) => {
  return loading ? (
    <div className="absolute inset-0 bg-black/80">
      <div className="flex justify-center items-center h-full">
        <Dots color="#FFF" size={32} speed={1} animating={true} />
      </div>
    </div>
  ) : (
    null
  );
};

export default LoadingIndicator;
