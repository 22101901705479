import React, { useEffect, useState } from "react";
import { fetchPlanList } from "../../Helper";

const FeePlanList = ({
  selectedPlanIndex,
  setSelectedPlanIndex,
  loggedInUserInstitutionID,
  postSaveRefresh,
}) => {
  const [isInstituteIDLoading, setIsInstituteIDIsLoading] = useState(true); // Add the loading state
  const [planList, setPlanList] = useState([]);
  const [isPlanListLoading, setIsPlanListLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutionID = sessionStorage.getItem("loggedUserInstitutionID");
        if (!institutionID) {
          console.log("Institution ID not available in sessionStorage");
        }
        const data = await fetchPlanList(institutionID);
        console.log("Fetched data:", data);
        setPlanList(data);
        setIsInstituteIDIsLoading(false);
        setIsPlanListLoading(false);
      } catch (error) {
        console.log("Error in fetchPlanList:", error.message);
      }
    };
    // This is fetching twice because setup fees is rendering twice - check. It appears that there is
    // some issue with sidebar probably - opening and closing side bar makes setupfees load twice. this
    // can be checked by inserting a console.log("render in setupfees.")
    if (loggedInUserInstitutionID != "") {
      fetchData();
    }
  }, [loggedInUserInstitutionID, postSaveRefresh]);

  const handlePlanSelect = (planId) => {
    setSelectedPlanIndex(planId);
  };

  return (
    <div className="h-60 overflow-auto">
      {isPlanListLoading ? (
        <div>Plan list is loading ...</div>
      ) : Array.isArray ? (
        planList.length > 0 ? (
          planList.map((plan, index) => (
            <div
              onClick={() => handlePlanSelect(plan.id)}
              key={index}
              className={`flex border-b border-black cursor-pointer ${
                selectedPlanIndex === plan.id ? "bg-neutral-400" : ""
              }`}
            >
              <div className="w-20 text-center border-r border-black">
                {index + 1}
              </div>
              <div className="grid grid-cols-8 w-full ">
                <div className="text-start pl-2 border-r border-black col-span-2">
                  {plan.plan_name}
                </div>
                <div className="text-center border-r border-black">
                  {plan.plan_fee_category}
                </div>
                <div className="text-center border-r border-black">
                  {plan.fee}
                </div>
                <div className="text-center border-r border-black">
                  {plan.payment_duration}
                </div>
                <div className="text-center border-r border-black">
                  {plan.payment_frequency}
                </div>
                <div className="text-center border-r border-black">
                  {plan.valid_from
                    ? new Date(plan.valid_from)
                        .toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                          year: "numeric",
                        })
                        .replace(/\b0+/g, "")
                    : ""}
                </div>
                <div className="text-center border-black">
                  {plan.valid_till
                    ? new Date(plan.valid_till)
                        .toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                          year: "numeric",
                        })
                        .replace(/\b0+/g, "")
                    : ""}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">No subscriptions found.</div>
        )
      ) : (
        <div>Subscriptions are not in a list - contact support.</div>
      )}
    </div>
  );
};

export default FeePlanList;
