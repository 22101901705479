import React, { useEffect, useState, useMemo } from 'react';
import { isSameDay } from 'date-fns';
import Pagination from '../../assets/js/pagination';
import Swal from "sweetalert2";
import "./Recordvideolog.css";
import { Button, CircularProgress, } from "@mui/material";
import { GetControllerList, GetRecordvideologAPI } from './Helper';
import { useForm } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import { useOutletContext } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DatePicker from "react-datepicker";


const Recordvideolog = () => {
    const PageSize = 10;
    const [loading, setLoading] = useState(true);
    const [loadingforsearch, setLoadingForSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousMatches, setPreviousMatches] = useState([]);
    const [subscriptiondata, setSubscriptionData] = useState([]);
    const [razorpayplan, setRazorpayplan] = useState([]);
    const [appcitydata, setappcitydata] = useState([]);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [razorpayid, setrazorpayid] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [headerTitle, setHeaderTitle] = useOutletContext();
    const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
    const [showAddTask, setShowAddTask] = React.useState(false);
    const [showAddPlan, setShowAddPlan] = React.useState(false);
    const [showeditplan, setshoweditplan] = React.useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEditCity, setSelectedEditCity] = useState(null);
    const [errorLogData, setErrorLogData] = useState([]);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null);
    const [logsToFetch, setLogsToFetch] = useState(100); // Number of logs to fetch initially
    const [loadingMessage, setLoadingMessage] = useState('');
    const [controllerList, setControllerList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBall, setSearchBall] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedController, setSelectedController] = useState('');
    const [totalDataLoaded, setTotalDataLoaded] = useState(0);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
    } = useForm();


    const loadMoreData = async () => {
        try {
            setLoadingMessage('Loading...');
            setLoading(true);

            const data = await GetRecordvideologAPI(subscriptiondata.length, searchQuery);

            if (data?.status) {
                if (data.querydata.get_record_video_log.length > 0) {
                    setSubscriptionData((prevData) => [...prevData, ...data.querydata.get_record_video_log]);
                    setPageCount((prevPageCount) => prevPageCount + data.querydata.get_record_video_log.length);
                    setTotalDataLoaded((prevTotalDataLoaded) => prevTotalDataLoaded + data.querydata.get_record_video_log.length);

                    setCurrentPage((prevPage) => prevPage + 1);
                } else {
                    setShowLoadMoreButton(false);
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            setLoadingMessage('');
        }
    };


    // Initial load without click
    useEffect(() => {
        const initialLoad = async () => {
            try {
                setHeaderTitle("Previous Match Data");
                setLoading(true);

                const data = await GetRecordvideologAPI(0);

                if (data?.status) {

                    if (data.querydata.get_record_video_log.length > 0) {
                        setSubscriptionData(data?.querydata.get_record_video_log);
                        setPageCount(data.querydata.get_record_video_log.length);
                        setTotalDataLoaded(data.querydata.get_record_video_log.length);
                    }
                    else {
                        setShowLoadMoreButton(false);
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        initialLoad();
    }, []);

    // Use loadMoreData when clicking on the "Load More" button
    const handleLoadMore = () => {
        // setCurrentPage(prevPage => prevPage + 1);
        loadMoreData();
    };

    useEffect(() => {
        setHeaderTitle("Previous Match Data");
        GetControllerList()
            .then((data) => {
                if (data?.status) {
                    setControllerList(data?.controllerFiles);
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentPage, setHeaderTitle]);

    const formatDate = (timestamp) => {
        if (!timestamp) return 'NA';
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const handleReset = () => {
        setSearchQuery('');
        setSelectedDate('');
        setSearchBall('');
        setSelectedController('');
        setShowLoadMoreButton(true);
        handleSearchChange({ target: { value: '' } }); // Assuming handleSearchChange is your search function

        const initialLoad = async () => {
            try {
                setHeaderTitle("Previous Match Data");
                setLoading(true);

                const data = await GetRecordvideologAPI(0);

                if (data?.status) {
                    if (data.querydata.get_record_video_log.length > 0) {
                        setSubscriptionData(data?.querydata.get_record_video_log);
                        setPageCount(data.querydata.get_record_video_log.length);
                        setTotalDataLoaded(data.querydata.get_record_video_log.length);
                    }
                    else {
                        setShowLoadMoreButton(false);
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        initialLoad();
    };

    const handleSearchChange = (event) => {
        setLoadingForSearch(true);
        setSearchQuery(event.target.value);
        setCurrentPage(1);
        Swal.fire({
            title: "😣Please Wait Data is Loading......",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        GetRecordvideologAPI(0, event.target.value, searchBall)
            .then((data) => {
                setLoadingForSearch(false)
                if (data?.status) {
                    if (data.querydata.get_record_video_log.length > 0) {
                        setSubscriptionData(data.querydata.get_record_video_log);
                        setPageCount(data.querydata.get_record_video_log.length);
                        setTotalDataLoaded(data.querydata.get_record_video_log.length);
                    } else {
                        setSubscriptionData([]);
                        setPageCount(0);
                        setTotalDataLoaded(0);
                    }
                }
                Swal.close();
            })
            .catch((error) => {
                Swal.close();
                setLoadingForSearch(false);
                // Handle the API error
                console.error(error);
            });
    };

    const handleSearchBallChange = (event) => {
        setSearchBall(event.target.value);
        setCurrentPage(1);
    };

    const handleSearchBlur = (event) => {
        setLoadingForSearch(true);
        setSearchBall(event.target.value);
        setCurrentPage(1);
        Swal.fire({
            title: "😣Please Wait Data is Loading......",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        GetRecordvideologAPI(0, searchQuery, event.target.value)
            .then((data) => {
                setLoadingForSearch(false)
                if (data?.status) {
                    if (data.querydata.get_record_video_log.length > 0) {
                        setSubscriptionData(data.querydata.get_record_video_log);
                        setPageCount(data.querydata.get_record_video_log.length);
                        setTotalDataLoaded(data.querydata.get_record_video_log.length);
                    } else {
                        setSubscriptionData([]);
                        setPageCount(0);
                        setTotalDataLoaded(0);
                    }
                }
                Swal.close();
            })
            .catch((error) => {
                Swal.close();
                setLoadingForSearch(false);
                // Handle the API error
                console.error(error);
            });
        console.log("Input field blurred");
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
        setCurrentPage(1);
        setShowLoadMoreButton(false);
    };
    const handleControllerChange = (event) => {
        setSelectedController(event.target.value);
        setCurrentPage(1);
        setShowLoadMoreButton(false);
    };
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };

    const handleModalEditProgram = (log) => {
        setSelectedErrorLog(log);
        setshoweditplan(true);
    };

    const filteredData = useMemo(() => {
        return subscriptiondata.filter((log) =>
            log.type.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (selectedController === '' || log.controller === selectedController) &&
            (!selectedDate || isSameDay(new Date(log.createdAt), selectedDate))

        );
    }, [subscriptiondata, searchQuery, selectedController, selectedDate]);

    const currentTableData = useMemo(() => {
        const startIndex = (currentPage - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const slicedData = filteredData.slice(startIndex, endIndex);
        setPageCount(filteredData.length);
        return slicedData;
    }, [currentPage, PageSize, filteredData]);


    return (
        <>
            <div>
                <h1
                    className="text-center m-2"
                    style={{
                        color: "#000", backgroundColor: "#0df6e3",
                        fontSize: "18px",
                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                    }}
                >
                    Record Video Logs - Response of Mobile Record Window Screen
                </h1>
            </div>

            <div style={{ textAlign: 'center' }}>
                {loading ? (
                    <>
                        <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>
                            Please Wait Data is{" "}
                            <span style={{ color: 'orange', fontWeight: '600' }}>
                                Loading...
                            </span>
                        </h2>
                    </>
                ) : (
                    <>

                        <div className="search-container" >
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                className="controller-select"
                                placeholderText="Select Activity Date"
                            />

                            {/* <select style={{ backgroundColor: 'white', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }}
                                value={selectedController}
                                onChange={handleControllerChange}
                                className="controller-select"
                            >
                                <option value="">All Controllers</option>
                                {controllerList.map((controller, index) => (
                                    <option key={index} value={controller}>
                                        {controller}
                                    </option>
                                ))}
                            </select> */}
                            {/* <input
                                style={{ boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }}
                                type="text"
                                placeholder="Search by Method Name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-input"
                            /> */}
                            <select
                                style={{
                                    backgroundColor: 'white',
                                    // boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                                }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-input"
                            >
                                <option value="">Search by Upload Type...</option>
                                <option value="Recording Started">Recording Started</option>
                                <option value="Recording Stoped">Recording Stoped</option>
                                <option value="Video Recorded">Video Recorded</option>
                                <option value="Video Compressing">Video Compressing</option>
                                <option value="Video Uploading">Video Uploading</option>
                                <option value="Video Uploaded">Video Uploaded</option>
                                <option value="Video Recorded Local">Video Recorded Local</option>
                                <option value="Video Uploading Failed">Video Uploading Failed</option>
                                <option value="Video Compressing Local">Video Compressing Local</option>
                                <option value="Video Compress Succes Local">Video Compress Succes Local</option>

                            </select>
                            <input
                                // style={{ boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }}
                                type="text"
                                placeholder="Search by File Name..."
                                value={searchBall}
                                onChange={handleSearchBallChange}
                                onBlur={handleSearchBlur}
                                className="search-input"
                            />


                            <div >
                                <Button variant='contained' type="button" style={{ marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', borderRadius: '4px', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }} onClick={handleReset} className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <RefreshIcon /> Reset
                                </Button>
                            </div>

                            <div style={{ position: 'fixed', right: '-35px', zIndex: '999', marginTop: '10px' }}>
                                <div style={{ float: 'right', marginTop: '-20px', marginRight: '110px', color: 'blue' }}> <p>{loadingMessage && <p>{loadingMessage} <CircularProgress size="1rem" /></p>}</p> </div>
                                {showLoadMoreButton && (
                                    <Button onClick={handleLoadMore} id="matchloadmore" style={{ height: '30px', backgroundColor: '#00efdc', color: 'black', marginTop: '4px', }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                                )}
                            </div>
                        </div>

                        <table className="custom-row-colors" style={{ borderCollapse: 'collapse', width: '100%', marginTop: "12px" }}>
                            <thead>
                                <tr>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Sr. No.</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>File Name</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Current Ball</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Upload Type</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Activity Time</th>
                                </tr>
                            </thead>
                            {loadingforsearch ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>
                                        <p>Loading...</p>
                                    </td>
                                </tr>
                            ) : !loadingforsearch && currentTableData.length === 0 ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'red', fontWeight: '600' }}>Data not found</p>
                                    </td>
                                </tr>
                            ) : (
                                <tbody>
                                    {!loadingMessage && currentTableData.map((log, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='dataawsTdLog designtable'>{((currentPage - 1) * 10) + (index + 1)}</td>
                                            <td className='dataawsTdLog'>{log.document_id ? log.document_id : 'NA'}</td>
                                            <td className='dataawsTdLog'>{log.current_ball ? log.current_ball : 'NA'}</td>
                                            <td className='dataawsTdLog'>{log.type ? log.type : 'NA'}</td>
                                            <td className='dataawsTdLog'>{log.createdAt ? formatDate(log.createdAt) : 'NA'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>

                        {/* //modal */}
                        <form >
                            {showeditplan && selectedErrorLog ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/* header */}
                                                <div
                                                    style={{ margin: '18px', backgroundColor: '#1f242cd4', color: 'white' }}
                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >
                                                    <h2 style={{ textAlign: 'left', margin: '7px' }}>
                                                        <span style={{ color: 'white', fontWeight: '400' }}>ID : </span>{' '}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>
                                                            {selectedErrorLog.id ? selectedErrorLog.id : 'NA'}
                                                        </span>
                                                    </h2>
                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        Record Video Log Details
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'white' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setshoweditplan(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>

                                                {/* <div>
                                                    <div className="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div className="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Controller:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.controller ? selectedErrorLog.controller : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div className="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Method Name:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.method_name ? selectedErrorLog.method_name : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <hr></hr>
                                                <div>
                                                    <div className="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div className="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Created At:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.createdAt ? formatDate(selectedErrorLog.createdAt) : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div className="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Updated At:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.updatedAt ? formatDate(selectedErrorLog.updatedAt) : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>

                                                <div>
                                                    <div
                                                        className="overflow-x-auto overflow-y-auto"
                                                        style={{ maxHeight: '70vh', padding: '0 25px' }}
                                                    >
                                                        <div className="flex" style={{ textAlign: 'left' }}>
                                                            {/* Left side: Headers */}
                                                            <div style={{ width: "900%" }}>

                                                                <p className="text-gray-600 border-b mb-2 pb-2">
                                                                    {/* Error Description:{' '} */}
                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Error Description:</span>{' '}
                                                                    {selectedErrorLog.error_description ? (
                                                                        <pre style={{ background: '#000', color: '#FFF' }}>
                                                                            {JSON.stringify(
                                                                                JSON.parse(selectedErrorLog.error_description),
                                                                                null,
                                                                                2
                                                                            )}
                                                                        </pre>
                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                                <p className="text-gray-600 border-b mb-2 pb-2">
                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Input Data:</span>{' '}
                                                                    {selectedErrorLog.input_data ? (
                                                                        <pre style={{ background: '#000', color: '#FFF' }}>
                                                                            {JSON.stringify(
                                                                                JSON.parse(selectedErrorLog.input_data),
                                                                                null,
                                                                                2
                                                                            )}
                                                                        </pre>
                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                    <button style={{ color: 'white' }}
                                                        className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                                        type="button"
                                                        onClick={() => {
                                                            setshoweditplan(false);
                                                        }}
                                                    >
                                                        <CancelIcon /> Cancel Log
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>
                        <div className="search-container2">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pageCount}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <ReactTooltip
                            anchorId="matchloadmore"
                            place="left"
                            content="Click to load more"
                            className="tooltip"
                        />
                    </>
                )}
            </div>
        </>
    );
}
export default Recordvideolog;
