
import firebase from 'firebase';
// import "firebase/messaging";

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyCbPC-GfemuElXbEIu0ZYx7ssOAHok4HBA",
  authDomain: "wellplayeddev-b59e3.firebaseapp.com",
  projectId: "wellplayeddev-b59e3",
  storageBucket: "wellplayeddev-b59e3.appspot.com",
  messagingSenderId: "837827561130",
  appId: "1:837827561130:web:a3f27358c6fe7a2142f2d3",
  measurementId: "G-Q3MWRF0P8Q"
};

// const config = {
//   apiKey: "AIzaSyC8WaqU1x1ZQsFf4qC8rgLPWqC1TJG07_Q",
//   authDomain: "wellplayed-c20e1.firebaseapp.com",
//   projectId: "wellplayed-c20e1",
//   storageBucket: "wellplayed-c20e1.appspot.com",
//   messagingSenderId: "127039474897",
//   appId: "1:127039474897:web:13fbd449136530967689f0",
//   measurementId: "G-ZLELYPCYQN"
// };



firebase.initializeApp(config);


export default firebase;


//  // Import the functions you need from the SDKs you need
// //  import { initializeApp } from "firebase/app";
// //import { getFirestore } from "firebase/firestore";
//  import firebase from 'firebase/app';
// import "firebase/messaging";
//  // TODO: Add SDKs for Firebase products that you want to use
//  // https://firebase.google.com/docs/web/setup#available-libraries
//  // Your web app's Firebase configuration
//  const firebaseConfig = {
//     apiKey: "AIzaSyCbPC-GfemuElXbEIu0ZYx7ssOAHok4HBA",
//     authDomain: "wellplayeddev-b59e3.firebaseapp.com",
//     projectId: "wellplayeddev-b59e3",
//     storageBucket: "wellplayeddev-b59e3.appspot.com",
//     messagingSenderId: "837827561130",
//     appId: "1:837827561130:web:a3f27358c6fe7a2142f2d3",
//     measurementId: "G-Q3MWRF0P8Q"
//  };
//  // Initialize Firebase
 
//  const app = firebase.initializeApp(firebaseConfig);
//  // Export firestore database
//  // It will be imported into your react app whenever it is needed
//  export const db = getFirestore(app);