import axios from "axios";

export const GetErrorLogAPI = async (offset) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-error-log-list`,
      {
        offset,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err; // Rethrow the error to handle it in the calling function
  }
};

// export const GetAWSErrorLogAPI = async (offset, upload_type = "") => {
//   try {
//     const res = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/get-videouploading-tbl-error`,
//       {
//         offset,
//         upload_type,
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem("WellplayedToken")).token
//           }`,
//         },
//       }
//     );
//     return res.data;
//   } catch (err) {
//     console.log(err);
//     throw err; // Rethrow the error to handle it in the calling function
//   }
// };
export const GetAWSErrorLogAPI = async (
  offset,
  upload_type = "",
  search_text = ""
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-videouploading-tbl-error`,
      {
        offset,
        upload_type,
        search_text,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err; // Rethrow the error to handle it in the calling function
  }
};

//controller file list

export const GetControllerList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-controller-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
