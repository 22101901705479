import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Header from "./Components/Header";
import SearchTab from "./Components/SearchTab";
import PlayerList from "./Components/FeeHistory/PlayerList";
import FilterTab from "./Components/FeeHistory/FilterTab";
import FeeHistory from "./Components/FeeHistory/FeeHistory";
import { fetchPlanList, getLoggedInUserInstitutionID } from "./Helper";
import PlanManagement from "./Components/FeeHistory/PlanManagement";

const PlayerFeeDetails = () => {
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedPlayerName, setSelectedPlayerName] = useState("");
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState("");
  const [validPlanList, setValidPlanList] = useState([]); //These are plans which have not expired
  const [isPlanListLoading, setIsPlanListLoading] = useState(true);
  const [addedNewSubscriptionReload, setAddNewSubscriptionReload] = useState(0);
  const [addedOrEditedFeesReload, setAddedOrEditedFeesReload] = useState(0);

  useEffect(() => {
    setHeaderTitle("Player Fee Details");
  }, []);

  const [loggedInUserInstitutionID, setLoggedInUserInstitutionID] =
    useState("");
  // this sets institution id in browser session and is used by playerList
  useEffect(() => {
    const fetchData = async () => {
      const data = await getLoggedInUserInstitutionID();
      setLoggedInUserInstitutionID(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutionID = sessionStorage.getItem("loggedUserInstitutionID");
        if (!institutionID) {
          console.log("Institution ID not available in sessionStorage");
        }

        const data = await fetchPlanList(institutionID);
        console.log("Plans List:", data);
        setValidPlanList(data);
        // setIsInstituteIDIsLoading(false);
        setIsPlanListLoading(false);
      } catch (error) {
        console.log("Error in fetchPlanList:", error.message);
      }
    };
    if (loggedInUserInstitutionID != "") {
      fetchData();
    }
  }, [loggedInUserInstitutionID]);

  const [isActiveSubscriptionListLoading, setIsActiveSubscriptionListLoading] =
    useState(true);
  const [isActiveSubscriptionList, setIsActiveSubscriptionList] = useState([]);

  return (
    <>
      <div className="m-12 px-12 py-6 border border-black border-2 rounded-xl flex flex-col gap-2">
        <Header title={"Player Fee Details"} />
        <section>
          <SearchTab
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </section>
        <section>
          <FilterTab
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          />
        </section>
        <section>
          <PlayerList
            searchInput={searchInput}
            selectedFilter={selectedFilter}
            setSelectedPlayerName={setSelectedPlayerName}
            loggedInUserInstitutionID={loggedInUserInstitutionID}
            setSelectedPlayerIndex={setSelectedPlayerIndex}
            selectedPlayerIndex={selectedPlayerIndex}
          />
        </section>
        <section>
          <PlanManagement
            selectedPlayerIndex={selectedPlayerIndex}
            selectedPlayerName={selectedPlayerName}
            loggedInUserInstitutionID={loggedInUserInstitutionID}
            validPlanList={validPlanList}
            isActiveSubscriptionListLoading={isActiveSubscriptionListLoading}
            setIsActiveSubscriptionListLoading={
              setIsActiveSubscriptionListLoading
            }
            isActiveSubscriptionList={isActiveSubscriptionList}
            setIsActiveSubscriptionList={setIsActiveSubscriptionList}
            addedNewSubscriptionReload={addedNewSubscriptionReload}
            setAddNewSubscriptionReload={setAddNewSubscriptionReload}
          />
        </section>
        <section>
          <FeeHistory
            selectedPlayerName={selectedPlayerName}
            selectedPlayerIndex={selectedPlayerIndex}
            isActiveSubscriptionListLoading={isActiveSubscriptionListLoading}
            setIsActiveSubscriptionListLoading={
              setIsActiveSubscriptionListLoading
            }
            isActiveSubscriptionList={isActiveSubscriptionList}
            setIsActiveSubscriptionList={setIsActiveSubscriptionList}
            addedNewSubscriptionReload={addedNewSubscriptionReload}
            setAddNewSubscriptionReload={setAddNewSubscriptionReload}
          />
        </section>
      </div>
    </>
  );
};

export default PlayerFeeDetails;
