import { BrowserRouter, Route, Routes } from "react-router-dom";
import Announcement from "./pages/Announcement/Announcement";
import { isLoggedIn, getUserRoleAccessSidebar } from "./pages/Auth/Helper";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Holder from "./pages/Holder";
import Match from "./pages/MatchCenter/Match/Match";
import Input from "./pages/MatchCenter/MatchAna/Input";
import MatchAna from "./pages/MatchCenter/MatchAna/MatchAna";
import MatchCenter from "./pages/MatchCenter/MatchCenter";
import Tournament from "./pages/MatchCenter/Tournament/Tournament";
import MyAuth from "./pages/MyAuth/MyAuth";
import Notification from "./pages/Notification/Notification";
import Video from "./pages/Video/Video";
import LiveFeed from "./pages/MatchCenter/LiveFeed";
import PreviousMatchData from "./pages/PreviousMatchData/Match/PreviousMatchData";
import LiveFeedCutVideo from "./pages/LiveFeedCutVideo/LiveFeedCutVideo";
import Institutions from "./pages/Institutions/Institutions";
import NewPlayerRegistration from "./pages/Institutions/NewPlayerRegistration";
import PlayerFeeDetails from "./pages/Institutions/PlayerFeeDetails";
import SetupFees from "./pages/Institutions/SetupFees";
import AttendanceReports from "./pages/Institutions/AttendanceReports";
import MembershipReports from "./pages/Institutions/MembershipReports";
import React, { useEffect, useState } from "react";
import { getUserRole } from "./pages/Institutions/Helper";
import SubscriptionPlans from "./pages/Subscriptions/SubscriptionPlans";
import PlayerRegister from "./pages/Institutions/PlayerRegister";
import TaskPlanner from "./pages/TaskPlanner/TaskPlanner";
import Awserrorlog from "./pages/AWSErrorLog/Awserrorlog";
import ApiErrorLog from "./pages/ApiErrorLog/ApiErrorLog";
import Recordvideolog from "./pages/RecordVideoLog/Recordvideolog";
import Dashboard2 from "./pages/Dashboard2/Dashboard2";
import Dashboard3 from "./pages/Dashboard2/Dashboard3";
import MatchDetailsTable from "./pages/MatchVideoAcess/MatchVideoAccess";
import MatchAnalysisErrorLog from "./pages/MatchAnalysisErrorLog/MatchAnalysisErrorLog";
import RoleAndAccess from "./pages/RoleAndAccess/RoleAndAccess";
import PracticeVideoMonitoring from "./pages/Monitoring/Monitoring";
import MonitoringTrainingProgram from "./pages/MonitoringTrainingProgram/MonitoringTrainingProgram";
import MonitoringInstitutionVideo from "./pages/MonitoringInstitutionVideo/MonitoringInstitutionVideo";

import { useLocation } from "react-router-dom";

function App() {
  const ProtectedRoute = ({ element }) => {
    const [authorized, setAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState([]);
    const location = useLocation();

    useEffect(() => {
      // const checkAuthorization = async () => {
      //   try {
      //     const userRole = await getUserRole();
      //     setAuthorized(userRole.role_id === 1);
      //     setIsLoading(false);
      //   } catch (error) {
      //     console.error("Error while fetching userRole:", error);
      //     setAuthorized(false);
      //     setIsLoading(false);
      //   }
      // };

      // setAuthorized(true);
      let userTokenDetail = JSON.parse(localStorage.getItem("WellplayedToken"));
      setUser(userTokenDetail);

      const currentPath = location.pathname;
      //alert(currentPath)
      getUserRoleAccessSidebar(userTokenDetail.user.id)
        .then((data) => {
          if (userTokenDetail.user.user_type == "superadmin") {
            setAuthorized(true);
          } else if (data["user_detail"]["admin_role_detail"] != false) {
            if (
              data["user_detail"]["admin_role_detail"]["menu_list"].length > 0
            ) {
              for (
                let i = 0;
                i <
                data["user_detail"]["admin_role_detail"]["menu_list"].length;
                i++
              ) {
                if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "admin_dashboard" &&
                  currentPath == "/Dashboard"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "video" &&
                  currentPath == "/Video"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "notifications" &&
                  currentPath == "/Notification"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "announcement" &&
                  currentPath == "/Announcement"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "training_program" &&
                  currentPath == "/TaskPlanner"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "my_authorizations" &&
                  currentPath == "/MyAuthorization"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "role_and_access" &&
                  currentPath == "/RoleAndAccess"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] ==
                  "match_center_configuration_new_tournament_new_tournament" &&
                  currentPath == "/MatchCenter/Tournament"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "match_center_configuration_new_tournament_new_match" &&
                  currentPath == "/MatchCenter/Match"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] ==
                  "match_center_configuration_new_tournament_new_analysis" &&
                  currentPath == "/MatchCenter/MatchAnalysis"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] ==
                  "match_center_configuration_new_tournament_previous_match_data" &&
                  (currentPath == "/PreviousMatchData" ||
                    currentPath == "/MatchCenter/MatchAnalysisInput")
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_new_player_registration" &&
                  currentPath == "/Institutions/NewPlayerRegistration"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_player_register" &&
                  currentPath == "/Institutions/PlayerRegister"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_player_fee_details" &&
                  currentPath == "/Institutions/PlayerFeeDetails"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_setup_fees" &&
                  currentPath == "/Institutions/SetupFees"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_attendance_reports" &&
                  currentPath == "/Institutions/AttendanceReports"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "institutions_membership_reports" &&
                  currentPath == "/Institutions/MembershipReports"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "error_logs_api_error_log" &&
                  currentPath == "/ApiErrorLog"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "error_logs_record_video_log" &&
                  currentPath == "/Recordvideolog"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "error_logs_aws_upload_error_log" &&
                  currentPath == "/Awserrorlog"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "error_logs_match_analysis_error_log" &&
                  currentPath == "/MatchAnalysisErrorLog"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "monitoring_practice_video" &&
                  currentPath == "/PracticeVideoMonitoring"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "monitoring_training_program" &&
                  currentPath == "/MonitoringTrainingProgram"
                ) {
                  setAuthorized(true);
                } else if (
                  data["user_detail"]["admin_role_detail"]["menu_list"][i][
                  "unique_identifier"
                  ] == "monitoring_institution_video" &&
                  currentPath == "/MonitoringInstitutionVideo"
                ) {
                  setAuthorized(true);
                }
              }
            }
          }
        })
        .catch((err) => {
          //console.log("error in fetching all previous match record", err);
        })
        .finally(() => {
          //setLoading(false);
        });
      setIsLoading(false);
      //checkAuthorization();
    }, []);

    if (isLoading) {
      // Render a loading indicator or fallback component while the authorization check is in progress
      return <div>Loading...</div>;
    }

    if (authorized) {
      // console.log("element:", element);
      return element;
    } else {
      return (
        <div
          className="text-red-500 font-bold text-center text-lg "
          style={{ paddingTop: "260px" }}
        >
          You are not authorized to view this page.
        </div>
      );
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/Login"
          element={isLoggedIn() === true ? <Holder /> : <Login />}
        />
        <Route
          path="/"
          element={isLoggedIn() === true ? <Holder /> : <Login />}
        >
          <Route
            path="/Dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/Dashboard2"
            element={<ProtectedRoute element={<Dashboard2 />} />}
          />
          <Route
            path="/Dashboard3"
            element={<ProtectedRoute element={<Dashboard3 />} />}
          />
          <Route
            path="/MatchAccess"
            element={<ProtectedRoute element={<MatchDetailsTable />} />}
          />
          <Route
            path="/Announcement"
            element={<ProtectedRoute element={<Announcement />} />}
          />
          <Route
            path="/Notification"
            element={<ProtectedRoute element={<Notification />} />}
          />
          <Route
            path="/MatchCenter"
            element={<ProtectedRoute element={<MatchCenter />} />}
          >
            <Route path="Match" element={<Match />} />
            <Route path="MatchAnalysis" element={<MatchAna />} />
            <Route path="MatchAnalysisInput" element={<Input />} />
            <Route path="Tournament" element={<Tournament />} />
            <Route path="LiveFeedCutVideo" element={<LiveFeedCutVideo />} />
            <Route path="LiveFeed" element={<LiveFeed />} />
          </Route>
          <Route
            path="/Institutions"
            element={<ProtectedRoute element={<Institutions />} />}
          >
            <Route
              path="NewPlayerRegistration/"
              element={<NewPlayerRegistration />}
            />
            <Route path="PlayerRegister/" element={<PlayerRegister />} />
            <Route path="PlayerFeeDetails" element={<PlayerFeeDetails />} />
            <Route path="SetupFees" element={<SetupFees />} />
            <Route path="AttendanceReports" element={<AttendanceReports />} />
            <Route path="MembershipReports" element={<MembershipReports />} />
          </Route>
          {/* <Route path="/PrevoiusMatchData" element={<ProtectedRoute element={<PreviousMatchData />} />} /> */}
          <Route
            path="/TaskPlanner"
            element={<ProtectedRoute element={<TaskPlanner />} />}
          />
          <Route
            path="/PreviousMatchData"
            element={<ProtectedRoute element={<PreviousMatchData />} />}
          />
          <Route
            path="/Video"
            element={<ProtectedRoute element={<Video />} />}
          />
          <Route
            path="/MyAuthorization"
            element={<ProtectedRoute element={<MyAuth />} />}
          />
          <Route path="/SubscriptionPlans" element={<SubscriptionPlans />} />
          <Route
            path="/ApiErrorLog"
            element={<ProtectedRoute element={<ApiErrorLog />} />}
          />
          <Route
            path="/Awserrorlog"
            element={<ProtectedRoute element={<Awserrorlog />} />}
          />
          <Route
            path="/Recordvideolog"
            element={<ProtectedRoute element={<Recordvideolog />} />}
          />
          <Route
            path="/MatchAnalysisErrorLog"
            element={<ProtectedRoute element={<MatchAnalysisErrorLog />} />}
          />
          <Route
            path="/PracticeVideoMonitoring"
            element={<ProtectedRoute element={<PracticeVideoMonitoring />} />}
          />
          <Route
            path="/MonitoringTrainingProgram"
            element={<ProtectedRoute element={<MonitoringTrainingProgram />} />}
          />
          <Route
            path="/MonitoringInstitutionVideo"
            element={
              <ProtectedRoute element={<MonitoringInstitutionVideo />} />
            }
          />
          <Route
            path="/RoleAndAccess"
            element={<ProtectedRoute element={<RoleAndAccess />} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
