import React, { useEffect, useState } from "react";
import {
  fetchPlayerRegistration,
  updatePlayerRegistration,
} from "../../Helper";

import EditSection from "./PlayerForm/EditSection";
import EditableForm from "./PlayerForm/EditableForm";
import ViewForm from "./PlayerForm/ViewForm";

const PlayerForm = ({
  selectedPlayerIndex,
  setReloadPlayerList,
  reloadPlayerList,
}) => {
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [isRegistrationDataLoading, setIsRegistrationDataLoading] =
    useState(false);
  const [registrationDataArray, setRegistrationDataArray] = useState([]);

  const [submitStatus, setSubmitStatus] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  const [updatedName, setUpdatedName] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [originalAddress, setOriginalAddress] = useState("");
  const [updatedCity, setUpdatedCity] = useState("");
  const [originalCity, setOriginalCity] = useState("");
  const [updatedPincode, setUpdatedPincode] = useState("");
  const [originalPincode, setOriginalPincode] = useState("");
  const [updatedGender, setUpdatedGender] = useState("");
  const [originalGender, setOriginalGender] = useState("");
  const [updatedParentOccupation, setUpdatedParentOccupation] = useState("");
  const [originalParentOccupation, setOriginalParentOccupation] = useState("");
  const [updatedContactNo, setUpdatedContactNo] = useState("");
  const [originalContactNo, setOriginalContactNo] = useState("");
  const [updatedFatherContactNo, setUpdatedFatherContactNo] = useState("");
  const [originalFatherContactNo, setOriginalFatherContactNo] = useState("");
  const [updatedMotherContactNo, setUpdatedMotherContactNo] = useState("");
  const [originalMotherContactNo, setOriginalMotherContactNo] = useState("");
  const [updatedDob, setUpdatedDob] = useState(new Date());
  const [originalDob, setOriginalDob] = useState(new Date());
  const [isDobNull, setIsDobNull] = useState(false);
  const [updatedDateOfJoining, setUpdatedDateOfJoining] = useState(new Date());
  const [originalDateOfJoining, setOriginalDateOfJoining] = useState(
    new Date()
  );
  const [isDateOfJoiningNull, setIsDateOfJoiningNull] = useState(false);
  const [updatedPreferredTiming, setUpdatedPreferredTiming] = useState("");
  const [originalPreferredTiming, setOriginalPreferredTiming] = useState("");
  const [
    updatedNameOfAcademicInstitution,
    setUpdatedNameofAcademicInstitution,
  ] = useState("");
  const [
    originalNameofAcademicInstitution,
    setOriginalNameofAcademicInstitution,
  ] = useState("");
  const [updatedBatchAllotted, setUpdatedBatchAllotted] = useState("");
  const [updatedPersona, setUpdatedPersona] = useState("");
  const [originalPersona, setOriginalPersona] = useState("");
  const [updatedPlayerProfile, setUpdatedPlayerProfile] = useState("");
  const [originalPlayerProfile, setOriginalPlayerProfile] = useState("");
  const [updatedIsAllRounder, setUpdatedIsAllRounder] = useState("");
  const [originalIsAllRounder, setOriginalIsAllRounder] = useState("");
  const [updatedIsWicketKeeper, setUpdatedIsWicketKeeper] = useState("");
  const [originalIsWicketKeeper, setOriginalIsWicketKeeper] = useState("");
  const [updatedBatting, setUpdatedBatting] = useState("");
  const [originalBatting, setOriginalBatting] = useState("");
  const [updatedBowling, setUpdatedBowling] = useState("");
  const [originalBowling, setOriginalBowling] = useState("");
  const [updatedFielding, setUpdatedFielding] = useState("");
  const [originalFielding, setOriginalFielding] = useState("");
  const [updatedMedicalCondition, setUpdatedMedicalCondition] = useState("");
  const [originalMedicalCondition, setOriginalMedicalCondition] = useState("");

  const resetUpdatedFields = () => {
    // Reset input updated fields
    setUpdatedName(originalName);
    setUpdatedAddress(originalAddress);
    setUpdatedCity(originalCity);
    setUpdatedPincode(originalPincode);
    setUpdatedGender(originalGender);
    setUpdatedParentOccupation(originalParentOccupation);
    setUpdatedContactNo(originalContactNo);
    setUpdatedFatherContactNo(originalFatherContactNo);
    setUpdatedMotherContactNo(originalMotherContactNo);
    setUpdatedDob(new Date(originalDob));
    setUpdatedDateOfJoining(new Date(originalDateOfJoining));
    // setUpdatedDob(originalDob);
    // setUpdatedDateOfJoining(originalDateOfJoining);
    setUpdatedPreferredTiming(originalPreferredTiming);
    setUpdatedNameofAcademicInstitution(originalNameofAcademicInstitution);
    setUpdatedBatchAllotted("");
    setUpdatedPersona(originalPersona);
    setUpdatedPlayerProfile(originalPlayerProfile);
    setUpdatedIsAllRounder(originalIsAllRounder);
    setUpdatedIsWicketKeeper(originalIsWicketKeeper);
    setUpdatedBatting(originalBatting);
    setUpdatedBowling(originalBowling);
    setUpdatedFielding(originalFielding);
    setUpdatedMedicalCondition(originalMedicalCondition);
  };

  useEffect(() => {
    console.log("Selected this player:", selectedPlayerIndex);
    const fetchData = async () => {
      try {
        setIsRegistrationDataLoading(true);
        const data = await fetchPlayerRegistration(selectedPlayerIndex);
        setRegistrationDataArray(data);
        console.log(registrationDataArray);
      } catch (error) {
        console.log("Error in fetchPlayerRegistration:", error.message);
      } finally {
        setIsRegistrationDataLoading(false); // Set loading to false regardless of success or error
        // resetUpdatedFields();
      }
    };
    if (selectedPlayerIndex != "") {
      fetchData();
    }
    // this code will change editing state to avoid accidental edits
    setIsEditingForm(false);
  }, [selectedPlayerIndex]);

  useEffect(() => {
    if (registrationDataArray) {
      setUpdatedName(registrationDataArray.full_name);
      setOriginalName(registrationDataArray.full_name);
      setUpdatedAddress(registrationDataArray.address);
      setOriginalAddress(registrationDataArray.address);
      setUpdatedCity(registrationDataArray.city);
      setOriginalCity(registrationDataArray.city);
      setUpdatedPincode(registrationDataArray.pincode);
      setOriginalPincode(registrationDataArray.pincode);

      setUpdatedGender(registrationDataArray.gender);
      setOriginalGender(registrationDataArray.gender);
      setUpdatedParentOccupation(registrationDataArray.parent_occupation);
      setOriginalParentOccupation(registrationDataArray.parent_occupation);

      setUpdatedContactNo(registrationDataArray.mobile);
      setOriginalContactNo(registrationDataArray.mobile);
      setUpdatedFatherContactNo(registrationDataArray.mobile_father);
      setOriginalFatherContactNo(registrationDataArray.mobile_father);
      setUpdatedMotherContactNo(registrationDataArray.mobile_mother);
      setOriginalMotherContactNo(registrationDataArray.mobile_mother);

      if (registrationDataArray.date_of_birth) {
        setUpdatedDob(new Date(registrationDataArray.date_of_birth));
        setOriginalDob(new Date(registrationDataArray.date_of_birth));
        setIsDobNull(false);
      } else {
        setIsDobNull(true);
      }

      if (registrationDataArray.date_of_joining) {
        setUpdatedDateOfJoining(
          new Date(registrationDataArray.date_of_joining)
        );
        setOriginalDateOfJoining(
          new Date(registrationDataArray.date_of_joining)
        );
        setIsDateOfJoiningNull(false);
      } else {
        setIsDateOfJoiningNull(true);
      }

      setUpdatedPreferredTiming(registrationDataArray.preferred_timing);
      setOriginalPreferredTiming(registrationDataArray.preferred_timing);

      setUpdatedNameofAcademicInstitution(
        registrationDataArray.name_of_academic_institution
      );
      setOriginalNameofAcademicInstitution(
        registrationDataArray.name_of_academic_institution
      );

      setUpdatedPersona(registrationDataArray.user_type);
      setOriginalPersona(registrationDataArray.user_type);

      setUpdatedIsAllRounder(registrationDataArray.isAllrounder);
      setOriginalIsAllRounder(registrationDataArray.isAllrounder);
      setUpdatedPlayerProfile(registrationDataArray.profile);
      setOriginalPlayerProfile(registrationDataArray.profile);
      setUpdatedIsWicketKeeper(registrationDataArray.isWicketKeeper);
      setOriginalIsWicketKeeper(registrationDataArray.isWicketKeeper);

      setUpdatedBatting(registrationDataArray.batting);
      setOriginalBatting(registrationDataArray.batting);
      setUpdatedBowling(registrationDataArray.bowling);
      setOriginalBowling(registrationDataArray.bowling);
      setUpdatedFielding(registrationDataArray.fielding);
      setOriginalFielding(registrationDataArray.fielding);

      setUpdatedMedicalCondition(registrationDataArray.medical_info);
      setOriginalMedicalCondition(registrationDataArray.medical_info);
    }
  }, [registrationDataArray]);

  const handleSaveChanges = async () => {
    const updatedData = {};
    if (updatedName != originalName) {
      updatedData.full_name = updatedName;
    }
    if (updatedAddress != originalAddress) {
      updatedData.address = updatedAddress;
    }
    if (updatedCity != originalCity) {
      updatedData.city = updatedCity;
    }
    if (updatedPincode != originalPincode) {
      updatedData.pincode = updatedPincode;
    }
    if (updatedGender != originalGender) {
      updatedData.gender = updatedGender;
    }
    if (updatedParentOccupation != originalParentOccupation) {
      updatedData.parent_occupation = updatedParentOccupation;
    }
    if (updatedContactNo != originalContactNo) {
      updatedData.mobile = updatedContactNo;
    }
    if (updatedFatherContactNo != originalFatherContactNo) {
      updatedData.mobile_father = updatedFatherContactNo;
    }
    if (updatedMotherContactNo != originalMotherContactNo) {
      updatedData.mobile_mother = updatedMotherContactNo;
    }
    const updatedDobYear = updatedDob.getFullYear();
    const updatedDobMonth = updatedDob.getMonth();
    const updatedDobDay = updatedDob.getDate();

    const originalDobYear = originalDob.getFullYear();
    const originalDobMonth = originalDob.getMonth();
    const originalDobDay = originalDob.getDate();

    if (
      updatedDobYear !== originalDobYear ||
      updatedDobMonth !== originalDobMonth ||
      updatedDobDay !== originalDobDay
    ) {
      updatedData.date_of_birth = updatedDob;
    }
    const updatedDateOfJoiningYear = updatedDateOfJoining.getFullYear();
    const updatedDateOfJoiningMonth = updatedDateOfJoining.getMonth();
    const updatedDateOfJoiningDay = updatedDateOfJoining.getDate();

    const originalDateOfJoiningYear = originalDateOfJoining.getFullYear();
    const originalDateOfJoiningMonth = originalDateOfJoining.getMonth();
    const originalDateOfJoiningDay = originalDateOfJoining.getDate();

    if (
      updatedDateOfJoiningYear !== originalDateOfJoiningYear ||
      updatedDateOfJoiningMonth !== originalDateOfJoiningMonth ||
      updatedDateOfJoiningDay !== originalDateOfJoiningDay
    ) {
      updatedData.date_of_joining = updatedDateOfJoining;
    }

    if (updatedPreferredTiming != originalPreferredTiming) {
      updatedData.preferred_timing = updatedPreferredTiming;
    }
    if (updatedNameOfAcademicInstitution != originalNameofAcademicInstitution) {
      updatedData.name_of_academic_institution =
        updatedNameOfAcademicInstitution;
    }
    if (updatedPersona != originalPersona) {
      updatedData.user_type = updatedPersona;
    }
    if (updatedPlayerProfile != originalPlayerProfile) {
      updatedData.profile = updatedPlayerProfile;
    }
    if (updatedIsAllRounder != originalIsAllRounder) {
      updatedData.isAllrounder = updatedIsAllRounder;
    }
    if (updatedIsWicketKeeper != originalIsWicketKeeper) {
      updatedData.isWicketKeeper = updatedIsWicketKeeper;
    }
    if (updatedBatting != originalBatting) {
      updatedData.batting = updatedBatting;
    }
    if (updatedBowling != originalBowling) {
      updatedData.bowling = updatedBowling;
    }
    if (updatedFielding != originalFielding) {
      updatedData.fielding = updatedFielding;
    }
    if (updatedMedicalCondition != originalMedicalCondition) {
      updatedData.medical_info = updatedMedicalCondition;
    }

    console.log("updated data:", updatedData);
    console.log("updated data key length", Object.keys(updatedData).length);
    if (Object.keys(updatedData).length > 0) {
      try {
        const response = await updatePlayerRegistration(
          updatedData,
          selectedPlayerIndex
        );
        console.log(response);
        setSubmitStatus(response.status);
        setSubmitMessage(response.message);
      } catch (error) {
        console.log("Error while updating PlayerRegistration:", error.message);
        setSubmitStatus(error.status);
        setSubmitMessage(error.message);
      } finally {
        resetUpdatedFields();
        try {
          setIsRegistrationDataLoading(true);
          const data = await fetchPlayerRegistration(selectedPlayerIndex);
          setRegistrationDataArray(data);
        } catch (error) {
          console.log("Error in fetchPlayerFeeDetails:", error.message);
        } finally {
          setIsRegistrationDataLoading(false); // Set loading to false regardless of success or error
        }
      }
    } else {
      console.log("No fields to update.");
    }
  };

  return (
    <section className="flex flex-col justify-center relative gap-4">
      <h1 className="text-4xl font-bold text-center pb-10">
        Player Information
      </h1>

      {selectedPlayerIndex != "" ? (
        isEditingForm ? (
          <EditableForm
            updatedName={updatedName}
            setUpdatedName={setUpdatedName}
            updatedAddress={updatedAddress}
            setUpdatedAddress={setUpdatedAddress}
            updatedCity={updatedCity}
            setUpdatedCity={setUpdatedCity}
            updatedPincode={updatedPincode}
            setUpdatedPincode={setUpdatedPincode}
            updatedGender={updatedGender}
            setUpdatedGender={setUpdatedGender}
            updatedParentOccupation={updatedParentOccupation}
            setUpdatedParentOccupation={setUpdatedParentOccupation}
            updatedContactNo={updatedContactNo}
            setUpdatedContactNo={setUpdatedContactNo}
            updatedFatherContactNo={updatedFatherContactNo}
            setUpdatedFatherContactNo={setUpdatedFatherContactNo}
            updatedMotherContactNo={updatedMotherContactNo}
            setUpdatedMotherContactNo={setUpdatedMotherContactNo}
            updatedDob={updatedDob}
            setUpdatedDob={setUpdatedDob}
            updatedDateOfJoining={updatedDateOfJoining}
            setUpdatedDateOfJoining={setUpdatedDateOfJoining}
            updatedPreferredTiming={updatedPreferredTiming}
            setUpdatedPreferredTiming={setUpdatedPreferredTiming}
            updatedNameOfAcademicInstitution={updatedNameOfAcademicInstitution}
            setUpdatedNameofAcademicInstitution={
              setUpdatedNameofAcademicInstitution
            }
            updatedBatchAllotted={updatedBatchAllotted}
            setUpdatedBatchAllotted={setUpdatedBatchAllotted}
            updatedPersona={updatedPersona}
            setUpdatedPersona={setUpdatedPersona}
            updatedPlayerProfile={updatedPlayerProfile}
            setUpdatedPlayerProfile={setUpdatedPlayerProfile}
            updatedIsAllRounder={updatedIsAllRounder}
            setUpdatedIsAllRounder={setUpdatedIsAllRounder}
            updatedIsWicketKeeper={updatedIsWicketKeeper}
            setUpdatedIsWicketKeeper={setUpdatedIsWicketKeeper}
            updatedBatting={updatedBatting}
            setUpdatedBatting={setUpdatedBatting}
            updatedBowling={updatedBowling}
            setUpdatedBowling={setUpdatedBowling}
            updatedFielding={updatedFielding}
            setUpdatedFielding={setUpdatedFielding}
            updatedMedicalCondition={updatedMedicalCondition}
            setUpdatedMedicalCondition={setUpdatedMedicalCondition}
          />
        ) : (
          <ViewForm
            updatedName={updatedName}
            updatedAddress={updatedAddress}
            updatedCity={updatedCity}
            updatedPincode={updatedPincode}
            updatedGender={updatedGender}
            updatedParentOccupation={updatedParentOccupation}
            updatedContactNo={updatedContactNo}
            updatedFatherContactNo={updatedFatherContactNo}
            updatedMotherContactNo={updatedMotherContactNo}
            updatedDob={updatedDob}
            isDobNull={isDobNull}
            updatedDateOfJoining={updatedDateOfJoining}
            isDateOfJoiningNull={isDateOfJoiningNull}
            updatedPreferredTiming={updatedPreferredTiming}
            updatedNameOfAcademicInstitution={updatedNameOfAcademicInstitution}
            updatedBatchAllotted={updatedBatchAllotted}
            updatedPersona={updatedPersona}
            updatedPlayerProfile={updatedPlayerProfile}
            updatedIsAllRounder={updatedIsAllRounder}
            updatedIsWicketKeeper={updatedIsWicketKeeper}
            updatedBatting={updatedBatting}
            updatedBowling={updatedBowling}
            updatedFielding={updatedFielding}
            updatedMedicalCondition={updatedMedicalCondition}
          />
        )
      ) : (
        <div>Select a user.</div>
      )}

      {selectedPlayerIndex != "" ? (
        <div className="flex flex-col justify-center items-center gap-10">
          <EditSection
            isEditingForm={isEditingForm}
            setIsEditingForm={setIsEditingForm}
            resetUpdatedFields={resetUpdatedFields}
            submitStatus={submitStatus}
            submitMessage={submitMessage}
            handleSaveChanges={handleSaveChanges}
            setReloadPlayerList={setReloadPlayerList}
            reloadPlayerList={reloadPlayerList}
          />
        </div>
      ) : null}
    </section>
  );
};

export default PlayerForm;
