import {
  Button, Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  ButtonGroup,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  List,
  CircularProgress,
} from "@mui/material";
import VideoPlayerDialog from "../MatchCenter/MatchAna/videoPlayerDialog";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CircleIcon from '@mui/icons-material/Circle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { AddVideo, GetCategories, GetLearningCategories, GetLearningCenters, GetMatchCategories, GetMatchCenters } from "./Helper";
import { useForm } from "react-hook-form";
import { GetAllTournaments } from "../MatchCenter/Tournament/Helper";
import { GetAllMatchesByTournament, getLiveAnalysisDetail } from "../MatchCenter/MatchAna/Helper";
import { GetLiveStreamingVideos, GetCropLiveAnalysisDetail, getCropedTaggedVideo, DownloadMuxUploadAwsServer, GetCropAnalysisDetail, CropAndTagAllLiveVideo, getMuxStatusFromAsset } from "../LiveFeedCutVideo/Helper";
import LoadingIndicator from "../../components/LoadingIndicator";
//import demoVideo from "../../assets/demoVideo.mp4";
import AdjustIcon from '@mui/icons-material/Adjust';
import CancelIcon from '@mui/icons-material/Cancel'
import queryString from 'query-string';
//import demoImage from "../../assets/images.jpg"
const Video = () => {
  const [customErrors, setCustomErrors] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset
  } = useForm();
  //const [headerTitle, setHeaderTitle] = useOutletContext();
  const [video, setVideo] = useState({
    title: "",
    category: "",
    sub_category1: "",
    sub_category2: "",
  });
  const location = useLocation();
  const [liveStreamVideos, setLiveStreamVideos] = useState([]);
  const [liveStremVideo, setLiveStremVideo] = useState([]);
  const [AnalysisCropVideo, setAnalysisCropVideo] = useState([]);

  const [duration, setDuration] = useState("00:00:00");
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState([]);
  const [subCategories2, setSubCategories2] = useState([]);
  const [hideSub, setHideSub] = useState(false);
  const [hideInning, setHideInning] = useState(true);
  const [CameraInningOver, setCameraInningOver] = useState('');
  const [file, setFile] = useState();
  const [showDownloadMuxLoader, setShowDownloadMuxLoader] = useState(false);
  const [showCropVideoLoader, setShowCropVideoLoader] = useState(false);

  const [openVideoPlayer, setOpenVideoPlayer] = useState({
    src: "",
    fileName: ""
  });

  const closeVideoPlayer = (event) => {
    setOpenVideoPlayer({ src: '', fileName: '' });
  }

  const onDrop = (e) => {
    console.log(e[0]);
    if (e[0].type.split("/")[0] === "video") {
      setFile(e[0]);
      setCustomErrors(null)
    } else {
      Swal.fire({
        icon: 'info',
        title: '😑',
        text: "Only video files are accepted"
      })
    }
  }

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const queryParams = queryString.parse(location.search);
  const analysis_master_id = queryParams.analysis_master_id;
  //console.log("analysis_master_id--->", analysis_master_id)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  useEffect(() => {
    getLiveAnalysisDetail({
      analysis_master_id: analysis_master_id,
    })
      .then(async (data) => {
        if (data.AnalysisMasterDetail.length > 0) {
          setHeaderTitle(data.AnalysisMasterDetail[0].full_tournament_name);

        }
      })


  }, []);



  const onPlayClick = (file) => (event) => {
    const filename = file.substring(file.lastIndexOf('/') + 1);
    setOpenVideoPlayer({
      src: `${file}`,
      fileName: filename
    });
  }

  const onPlayMuxClick = (file) => (event) => {
    let url = "https://wellplayeds3bucket1.s3.ap-south-1.amazonaws.com/mux_server_file/" + file;

    const filename = url.substring(url.lastIndexOf('/') + 1);
    setOpenVideoPlayer({
      src: `${url}`,
      fileName: filename
    });
  }

  useEffect(() => {
    GetCropLiveAnalysisDetail({
      analysis_master_id: analysis_master_id
    })
      .then(async (data) => {
        if (data.AnalysisMasterDetail.length > 0) {

          setCameraInningOver(data.AnalysisMasterDetail[0].overs);

        }
      }).catch(error => {
        console.debug("error while getting video files...", error);
        return error;
      });




  }, []);



  const [liveStreamAssetId, setLiveStreamAssetId] = useState([])
  useEffect(() => {
    // alert("this is working")
    GetLiveStreamingVideos({
      analysis_master_id: analysis_master_id
    })
      .then(async (data) => {
        console.log("data MeraWalaa-->", data)
        setLiveStreamAssetId(data)
        // console.log("liveStremVideo-->", liveStremVideo)
        if (data.status) {
          setLiveStremVideo(data.data);
        }
      }).catch(error => {
        console.debug("error while getting video files...", error);
        return error;
      });

    GetCropAnalysisDetail({
      analysis_master_id: analysis_master_id
    })
      .then(async (analysis_data) => {
        setAnalysisCropVideo(analysis_data.analysis_data);
      }).catch(error => {
        console.debug("error while getting video files...", error);
        return error;
      });
  }, []);

  const DownloadMuxUploadAwsServerClick = async () => {
    setShowDownloadMuxLoader(true);
    DownloadMuxUploadAwsServer(analysis_master_id
    )
      .then(async (return_data) => {
        GetLiveStreamingVideos({
          analysis_master_id: analysis_master_id
        })
          .then(async (data) => {
            console.log("data MeraWalaa-->", data)
            // console.log("liveStremVideo-->", liveStremVideo)
            if (data.status) {
              setLiveStremVideo(data.data);
            }
            setShowDownloadMuxLoader(false);
            Swal.fire({
              title: 'Success',
              text: "All ready video uploaded to AWS Server successfully and Preparing or Running video will not affected"
            })
          }).catch(error => {
            console.debug("error while getting video files...", error);
            return error;
          });
      });
  };


  const CropVideoAndTagBallClick = async () => {
    setShowCropVideoLoader(true);
    CropAndTagAllLiveVideo(analysis_master_id
    )
      .then(async (return_data) => {
        GetCropAnalysisDetail({
          analysis_master_id: analysis_master_id
        })
          .then(async (analysis_data) => {
            setAnalysisCropVideo(analysis_data.analysis_data);
            setShowCropVideoLoader(false);

            Swal.fire({
              title: 'Success',
              text: "All video cropped successfully"
            })
          }).catch(error => {
            console.debug("error while getting video files...", error);
            return error;
          });
      });
  };

  const muxStatus = (asset_id) => (event) => {
    console.log("assset_id", asset_id);
    getMuxStatusFromAsset(asset_id
    )
      .then(async (return_data) => {
        if (typeof return_data.asset_data.error != "undefined") {
          if (return_data.asset_data.error.type == "not_found") {
            Swal.fire({
              title: 'Info',
              text: "Asset is not available in Mux Server Now"
            });
          }
          else {
            Swal.fire({
              title: 'Info',
              text: "Asset is not available in Mux Server Now"
            });
          }

        }
        else {
          if (typeof return_data.asset_data.data.static_renditions != "undefined") {
            if (return_data.asset_data.data.static_renditions.status == "preparing") {
              Swal.fire({
                title: 'Info',
                text: "Mux server is preparing your video on its server now"
              });
            }
            else if (return_data.asset_data.data.static_renditions.status == "ready") {
              Swal.fire({
                title: 'Info',
                text: "Video file is ready on Mux server now for download to AWS server"
              });
            }
            else {
              Swal.fire({
                title: 'Info',
                text: "Video file is ready on Mux server now for download to AWS server"
              });
            }
          }
          else {
            Swal.fire({
              title: 'Info',
              text: "Video file is running in Live Streaming and Still its not available for download and move"
            });
          }
        }
        //alert(return_data.asset_data.error);
        // Swal.fire({
        //   icon: 'Success',
        //   text: "All video uploaded to AWS Server successfully"
        // })
      });

  }

  return (
    <div className="grid grid-cols-3 gap-4">

      <div className="col-span-3 ..." style={{ height: '300px', width: '100%' }}>
        <div className="bg-white p-5 h-max shadow-lg">
          <div style={{
            color: "#0DF6E3",
            fontWeight: "bold",
            fontSize: "15px",
            textAlign: "center"
          }}>Mux Server Uploaded Videos</div></div>
        <table className="alternate-row-colors" style={{ borderCollapse: 'collapse', width: '100%', }}>
          <thead>
            <tr>
              <th className="previousDatath" style={{ width: '75px' }}>Sr. No.</th>
              <th className="previousDatath">Video Name</th>
              <th className="previousDatath">AWS S3 Video Name</th>
              <th className="previousDatath" style={{ width: '130px' }}>Video</th>
              <th className="previousDatath" style={{ width: '130px' }}>Status</th>
              <th className="previousDatath" style={{ width: '130px' }}>Mux Status</th>
            </tr>
          </thead>
          <tbody style={{ height: '100px', overflowY: 'scroll' }}>
            {liveStremVideo.map((video, index) => (
              <tr key={index}>
                <td style={{ fontSize: '15px' }}>{index + 1}</td>
                <td style={{ fontSize: '15px' }}>{video.video_file_name}</td>
                <td style={{ fontSize: '15px' }}>{video.aws_server_path}</td>
                <td style={{ fontSize: '15px' }}>
                  {(video.is_available_aws == 'completed') ?
                    <>
                      <IconButton edge="end" aria-label="play video" size="small" title={`Play Video`} onClick={onPlayMuxClick(video.aws_server_path)}>
                        <PlayCircleIcon fontSize="small" /> Play Video
                      </IconButton>
                    </> :
                    <><span style={{ color: '#FF0000' }}>Video Not Found</span></>}

                </td>


                <td style={{ fontSize: '15px' }}>
                  {video.is_available_aws == 'completed' ?
                    <>
                      <AdjustIcon style={{ color: 'green' }} /> {video.is_available_aws}
                    </> :
                    <><AdjustIcon style={{ color: 'red' }} /> {video.is_available_aws}</>}

                </td>
                <td style={{ fontSize: '15px' }}>
                  <IconButton edge="end" aria-label="mux status" size="small" title={`Check Mux Status`}
                    onClick={muxStatus(video.asset_id)}
                  >
                    <FactCheckIcon fontSize="small" /> Check Status
                  </IconButton>
                </td>

              </tr>
            ))}

            {(liveStremVideo.length == 0) ? <>
              <tr>
                <td colSpan={6} style={{ textAlign: 'center', color: '#FF0000' }}>No Record Found</td>
              </tr>
            </> : <></>}
          </tbody>

          {(liveStremVideo.length != 0) ? <>
            <thead>
              <tr>
                <th colSpan={5} style={{ textAlign: 'right' }}>{showDownloadMuxLoader == true ? (
                  <> <div className="loader" style={{ float: 'right' }}></div> Uploading to AWS bucket, Please wait...</>
                ) : null}</th>
                <th>

                  <input style={{ background: "#0DF6E3", cursor: "pointer" }} type="Button" className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" value="Move To AWS S3 Bucket" onClick={() => DownloadMuxUploadAwsServerClick()} /></th>
              </tr>
            </thead>
          </> : <></>}
        </table>



        <div className="bg-white p-5 h-max shadow-lg" style={{ marginTop: '40px' }}>
          <div style={{
            color: "#0DF6E3",
            fontWeight: "bold",
            fontSize: "15px",
            textAlign: "center"
          }}>Cropped With Ball Tagging Videos</div></div>
        <table className="alternate-row-colors" style={{ borderCollapse: 'collapse', width: '100%', }}>
          <thead>
            <tr>
              <th className="previousDatath" style={{ width: '75px' }}>Sr. No.</th>
              <th className="previousDatath">Inning</th>
              <th className="previousDatath">Over</th>
              <th className="previousDatath">Start Time</th>
              <th className="previousDatath">End Interval</th>
              <th className="previousDatath">Video</th>
              <th className="previousDatath" style={{ width: '130px' }}>Status</th>

            </tr>
          </thead>
          <tbody style={{ height: '100px', overflowY: 'scroll' }}>
            {AnalysisCropVideo.map((video, index) => (
              <tr key={index}>
                <td style={{ fontSize: '15px' }}>{index + 1}</td>
                <td style={{ fontSize: '15px' }}>
                  {video.current_inning == 1 ?
                    <>
                      First Inning
                    </> :
                    <>Second Inning</>}
                </td>
                <td style={{ fontSize: '15px' }}>{video.current_ball}</td>
                <td style={{ fontSize: '15px' }}>{video.start_time}</td>
                <td style={{ fontSize: '15px' }}>{video.end_interval}</td>

                {/* <td style={{ fontSize: '15px' }}>{video.video_url}</td> */}
                <td style={{ fontSize: '15px' }}>
                  {(video.video_url != '') && (video.video_url != null) ?
                    <>
                      <IconButton edge="end" aria-label="play video" size="small" title={`Play Video`} onClick={onPlayClick(video.video_url)}>
                        <PlayCircleIcon fontSize="small" /> Play Video
                      </IconButton>
                    </> :
                    <><span style={{ color: '#FF0000' }}>Video Not Found</span></>}


                </td>

                <td style={{ fontSize: '15px' }}>
                  {(video.video_url != '') && (video.video_url != null) ?
                    <>
                      <AdjustIcon style={{ color: 'green' }} /> Completed
                    </> :
                    <><AdjustIcon style={{ color: 'red' }} /> Pending</>}

                </td>
              </tr>
            ))}
            {(AnalysisCropVideo.length == 0) ? <>
              <tr>
                <td colSpan={7} style={{ textAlign: 'center', color: '#FF0000' }}>No Record Found</td>
              </tr>
            </> : <></>}
          </tbody>

          {(AnalysisCropVideo.length != 0) ? <>
            <thead>
              <tr>
                <th colSpan={6} style={{ textAlign: 'right' }}>{showCropVideoLoader == true ? (
                  <> <div className="loader" style={{ float: 'right' }}></div> Cropping Videos, Please wait...</>
                ) : null}</th>
                <th>

                  <input style={{ background: "#0DF6E3", cursor: "pointer" }} type="Button" className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" value="Crop Video and Tag Ball" onClick={() => CropVideoAndTagBallClick()} /></th>
              </tr>
            </thead>
          </> : <></>}
        </table>

      </div>
      <VideoPlayerDialog videoInfo={openVideoPlayer} handleClose={closeVideoPlayer} />
    </div>

  );
};

export default Video;
