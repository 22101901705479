import React, { useEffect, useState } from "react";
import { fetchInstitutionPlayersAndPendingFees } from "../../Helper";
import PlayerListHeader from "../PlayerList/PlayerListHeader";

const PlayerList = ({
  selectedPlayerIndex,
  setSelectedPlayerIndex,
  searchInput,
  selectedFilter,
  setSelectedPlayerName,
  loggedInUserInstitutionID,
}) => {
  const [playerObjectArray, setPlayerObjectArray] = useState([]);
  const [isInstituteIDLoading, setIsInstituteIDIsLoading] = useState(true); // Add the loading state
  const [searchedPlayerObjectArray, setSearchedPlayerObjectArray] = useState(
    []
  ); // Step 1: State for filtered players

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutionID = sessionStorage.getItem("loggedUserInstitutionID");
        if (!institutionID) {
          console.log("Institution ID not available in sessionStorage");
        }

        const data = await fetchInstitutionPlayersAndPendingFees(institutionID);
        console.log("Players list:", data);
        setPlayerObjectArray(data);
        setIsInstituteIDIsLoading(false);
      } catch (error) {
        console.log("Error in fetchInstitutionPlayers:", error.message);
      }
    };
    if (loggedInUserInstitutionID != "") {
      fetchData();
    }
  }, [loggedInUserInstitutionID]);

  const selectPlayer = (index) => {
    // console.log('Selected this player:', index)
    setSelectedPlayerIndex(index);
  };

  useEffect(() => {
    setSelectedPlayerName(
      playerObjectArray.findIndex(
        (playerObject) => playerObject.id === selectedPlayerIndex
      ) !== -1
        ? playerObjectArray.find(
            (playerObject) => playerObject.id === selectedPlayerIndex
          )?.full_name || ""
        : ""
    );
  }, [selectedPlayerIndex]);

  // Function to calculate age till latest sept 1 based on the date of birth
  const calculateAgeTillLatestSeptember1 = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    // Set the target date to the latest September 1st
    const targetDate = new Date(currentDate.getFullYear(), 8, 1); // 8 represents September (months are 0-based)

    let age = targetDate.getFullYear() - birthDate.getFullYear();

    // Compare the birthdate with the target date
    const monthDiff = targetDate.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && targetDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    // Step 2: Filter player objects based on searchInput
    if (Array.isArray(playerObjectArray)) {
      const filteredPlayers = playerObjectArray.filter((playerObject) =>
        playerObject.full_name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setSearchedPlayerObjectArray(filteredPlayers);
    }
  }, [searchInput, playerObjectArray]);

  if (isInstituteIDLoading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator.
  }

  // Apply filtering based on the selectedFilter and age of players
  let filteredPlayerObjectArray = [];

  if (Array.isArray(playerObjectArray)) {
    if (playerObjectArray.length > 0) {
      // Apply filtering based on the selectedFilter and age of players
      filteredPlayerObjectArray = searchedPlayerObjectArray.filter(
        (playerObject) => {
          if (selectedFilter === "All") {
            return true; // Display all players when 'All' is selected
          } else if (selectedFilter === "Fees Due") {
            // Filter players whose valid_till is before today's date
            // const currentDate = new Date();
            // const validTillDate = new Date(playerObject.valid_till);
            // return validTillDate < currentDate;
            // if(playerObject.pending_fees=='Yes'){
            //   return true
            // }
            return playerObject.pending_fees == "Yes" ? true : false;
          } else if (selectedFilter === "Under-13") {
            const age = calculateAgeTillLatestSeptember1(
              playerObject.date_of_birth
            );
            return age < 13;
          } else if (selectedFilter === "Under-16") {
            const age = calculateAgeTillLatestSeptember1(
              playerObject.date_of_birth
            );
            return age < 16;
          } else if (selectedFilter === "Under-19") {
            const age = calculateAgeTillLatestSeptember1(
              playerObject.date_of_birth
            );
            return age < 19;
          }
          // Add other filter conditions here if needed
          return false;
        }
      );
    }
  } else {
    console.log("playerObjectArray is not an array yet.");
  }

  return (
    <div className="border border-black h-40 overflow-auto">
      <PlayerListHeader />
      {filteredPlayerObjectArray.map((playerObject, index) => (
        <div
          key={index}
          className={`grid grid-cols-10 border-black border-b cursor-pointer ${
            selectedPlayerIndex === playerObject.id ? "bg-neutral-400" : ""
          }`}
          onClick={() => selectPlayer(playerObject.id)}
        >
          <div className="col-span-1 flex justify-center border-r border-black">
            {index + 1}
          </div>
          <div className="col-span-2 flex items-center border-r border-black pl-2">
            {playerObject.full_name}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.gender}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.mobile}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.mobile_father ? playerObject.mobile_father : ""}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.mobile_mother ? playerObject.mobile_mother : ""}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.date_of_birth
              ? new Date(playerObject.date_of_birth)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                    year: "numeric",
                  })
                  .replace(/\b0+/g, "")
              : ""}
          </div>
          <div className="col-span-1 flex items-center justify-center border-r border-black">
            {playerObject.date_of_joining
              ? new Date(playerObject.date_of_joining)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                    year: "numeric",
                  })
                  .replace(/\b0+/g, "")
              : ""}
          </div>

          <div
            className={`col-span-1 flex items-center justify-center ${
              playerObject.pending_fees == "Yes" ? "bg-red-500" : ""
            } `}
          >
            {playerObject.pending_fees}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerList;
