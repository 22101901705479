import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PlayerTable = () => {
  const [players, setPlayers] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const [matchDetails, setMatchDetails] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [userTypeFilter, setUserTypeFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [visiblePlayers, setVisiblePlayers] = useState(20);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [matchesSortOrder, setMatchesSortOrder] = useState('asc');
  const [bowlingVideosCount, setBowlingVideosCount] = useState({});
  const [battingVideosCount, setBattingVideosCount] = useState({});
  const [academicInstitutions, setAcademicInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState(null);

  const navigate = useNavigate();

  const monthNames = [
    '', 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-users`)
      .then(response => {
        setPlayers(response.data);
        setFilteredPlayers(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-matches`)
      .then(response => {
        setAllMatches(response.data);
      })
      .catch(error => {
        console.error('Error fetching all matches:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-match-details`)
      .then(response => {
        setMatchDetails(response.data);

        // Count bowling videos for each player
        const videosCount = {};
        const battingVideosCount = {};

        response.data.forEach((match) => {
          const bowlerId = match.bowler_id;
          const videoUrl = match.video_url;
          const strikerId = match.bat_striker_id;
          if (strikerId && videoUrl) {
            battingVideosCount[strikerId] = (battingVideosCount[strikerId] || 0) + 1;
          }

          if (bowlerId && videoUrl) {
            videosCount[bowlerId] = (videosCount[bowlerId] || 0) + 1;
          }
        });
        setBattingVideosCount(battingVideosCount);
        setBowlingVideosCount(videosCount);
      })
      .catch(error => {
        console.error('Error fetching match details:', error);
      });
  }, []);

  useEffect(() => {
    if (userTypeFilter !== null) {
      const filteredData = players.filter(player => player.user_type === userTypeFilter);
      setFilteredPlayers(filteredData);
    } else {
      setFilteredPlayers(players);
    }
  }, [userTypeFilter, players]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const filteredData = players.filter(player =>
        player.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPlayers(filteredData);
    } else {
      setFilteredPlayers(players);
    }
  }, [searchTerm]);


  useEffect(() => {
    setFilteredPlayers(prevFilteredPlayers => {
      const sortedPlayers = [...prevFilteredPlayers].sort((a, b) => {
        const nameA = a.full_name.toLowerCase();
        const nameB = b.full_name.toLowerCase();

        if (sortOrder === 'asc') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      return sortedPlayers;
    });
  }, [sortOrder]);

  useEffect(() => {
    const uniqueYears = [...new Set(players.map(player => new Date(player.createdAt).getFullYear()))];
    const uniqueMonths = [...new Set(players.map(player => new Date(player.createdAt).getMonth() + 1))];

    setSelectedYear(null);
    setSelectedMonth(null);
  }, [players]);

  useEffect(() => {
    const filteredData = players.filter(player => {
      const playerYear = new Date(player.createdAt).getFullYear();
      const playerMonth = new Date(player.createdAt).getMonth() + 1;

      if (
        (selectedYear === null || playerYear.toString() === selectedYear) &&
        (selectedMonth === null || playerMonth.toString() === selectedMonth)
      ) {
        return true;
      }

      return false;
    });

    setFilteredPlayers(filteredData);
  }, [selectedYear, selectedMonth, players]);

  useEffect(() => {
    // Count unique academic institutions
    const uniqueInstitutions = [...new Set(players.map(player => player.name_of_academic_institution || 'none'))];
    setAcademicInstitutions(uniqueInstitutions);
  }, [players]);

  const handleFilterByInstitution = () => {
    if (selectedInstitution) {
      const filteredData = players.filter(
        player => (player.name_of_academic_institution || 'none') === selectedInstitution
      );
      setFilteredPlayers(filteredData);
    } else {
      setFilteredPlayers(players.filter(player => player.name_of_academic_institution === null));
    }
  };

  const handleFilterChange = (event) => {
    const selectedUserType = event.target.value === '1' ? '1' : event.target.value === '2' ? '2' : null;
    setUserTypeFilter(selectedUserType);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const toggleMatchesSortOrder = () => {
    setMatchesSortOrder(matchesSortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleLoadMore = () => {
    setVisiblePlayers(prevVisiblePlayers => prevVisiblePlayers + 10);
  };

  const handleShowLess = () => {
    setVisiblePlayers(prevVisiblePlayers => Math.max(prevVisiblePlayers - 10, 20));
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value || null);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value || null);
  };

  const countBowlingVideos = (userId) => {
    return bowlingVideosCount[userId] || 0;
  };

  const countMatchesPlayed = (userId) => {
    const relevantMatches = matchDetails.filter(
      (match) => match.bat_striker_id === userId || match.bat_nstriker_id === userId || match.bowler_id === userId
    );

    const uniqueMatchIds = new Set(relevantMatches.map((match) => match.match_id));

    return uniqueMatchIds.size;
  };

  const formatDate = (dateString) => {
    try {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold"> Players And Coaches Details</h2>

        <button
          onClick={() => navigate('/Dashboard')}
          className="p-2 rounded-md bg-blue-500 text-white border-none mx-2 transition-all duration-300 hover:bg-blue-600 hover:shadow-lg cursor-pointer"
        >
          Go to Dashboard
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="userTypeFilter" className="text-blue-700 font-bold">Filter by User Type:</label>

        <select
          id="userTypeFilter"
          onChange={handleFilterChange}
          value={userTypeFilter || ''}
          className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer pr-8 bg-no-repeat bg-right-center"
          style={{ backgroundImage: 'url("path-to-your-custom-arrow-icon.png")', }}
        >
          <option value="">All</option>
          <option value="1">Player</option>
          <option value="2">Coach</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="searchInput">Search by Full Name:</label>
        <input
          type="text"
          id="searchInput"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Enter player's full name"
          className="p-2 rounded-md border border-gray-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="yearFilter">Filter by Joining Year:</label>
        <select
          id="yearFilter"
          onChange={handleYearChange}
          value={selectedYear || ''}
          className="p-2 rounded-md border border-gray-500 mx-2 pr-8 bg-no-repeat bg-right-center"
          style={{
            backgroundImage: 'url("path-to-your-custom-arrow-icon.png")',
          }}
        >
          <option value="">All</option>
          {players.length > 0 &&
            [...new Set(players.map(player => new Date(player.createdAt).getFullYear()))].map(year => (
              <option key={year} value={year}>{year}</option>
            ))
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="monthFilter">Filter by Joining Month:</label>
        <select
          id="monthFilter"
          onChange={handleMonthChange}
          value={selectedMonth || ''}
          className="p-2 rounded-md border border-gray-500 mx-2 pr-8 bg-no-repeat bg-right-center"
          style={{
            backgroundImage: 'url("path-to-your-custom-arrow-icon.png")',
          }}
        >
          <option value="">All</option>
          {players.length > 0 &&
            [...new Set(players.map(player => new Date(player.createdAt).getMonth() + 1))].map(month => (
              <option key={month} value={month}>{monthNames[month]}</option>
            ))
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="institutionFilter">Filter by Academic Institution:</label>
        <select
          id="institutionFilter"
          onChange={(e) => setSelectedInstitution(e.target.value)}
          value={selectedInstitution || ''}
          className="p-2 rounded-md border border-gray-500 mx-2 pr-8 bg-no-repeat bg-right-center"
          style={{
            backgroundImage: 'url("path-to-your-custom-arrow-icon.png")',
          }}
        >
          <option value="">All</option>
          {academicInstitutions.map(institution => (
            <option key={institution} value={institution}>
              {institution === 'none' ? 'None' : institution}
            </option>
          ))}
        </select>
        <button
          onClick={handleFilterByInstitution}
          className="p-2 rounded-md bg-orange-300 text-white border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
        >
          Apply Filter for Institution Name
        </button>
      </div>
      <button
        onClick={toggleSortOrder}
        className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer mb-7"
      >
        Sort by Full Name {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
      </button>
      <button
        onClick={toggleMatchesSortOrder}
        className="p-2 rounded-md bg-orange-300
 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
      >
        Sort by Matches Played {matchesSortOrder === 'asc' ? 'Ascending' : 'Descending'}
      </button>
      <table className="w-full border-collapse border-2 border-gray-500">
        <thead>
          <tr>
            <th className="border border-gray-500 p-2">Serial Number</th>
            <th className="border border-gray-500 p-2">Name</th>
            <th className="border border-gray-500 p-2">City</th>
            <th className="border border-gray-500 p-2">Gender</th>
            <th className="border border-gray-500 p-2">Batting</th>
            <th className="border border-gray-500 p-2">Bowling</th>
            <th className="border border-gray-500 p-2">User type</th>
            <th className="border border-gray-500 p-2">Matches Played</th>
            <th className="border border-gray-500 p-2">Bowling Videos</th>
            <th className="border border-gray-500 p-2">Batting Videos</th>
            <th className="border border-gray-500 p-2">Created At</th>
            <th className="border border-gray-500 p-2">Institution Name</th>
          </tr>
        </thead>
        <tbody>
          {filteredPlayers
            .sort((a, b) => {
              const matchesA = countMatchesPlayed(a.id);
              const matchesB = countMatchesPlayed(b.id);

              if (matchesSortOrder === 'asc') {
                return matchesA - matchesB;
              } else {
                return matchesB - matchesA;
              }
            })
            .slice(0, visiblePlayers)
            .map((player, index) => (
              <tr key={player.id}>
                <td className="border border-gray-500 p-2">{index + 1}.</td>
                <td className="border border-gray-500 p-2">{player.full_name}</td>
                <td className="border border-gray-500 p-2">{player.city}</td>
                <td className="border border-gray-500 p-2">{player.gender}</td>
                <td className="border border-gray-500 p-2">{player.batting}</td>
                <td className="border border-gray-500 p-2">{player.bowling}</td>
                <td className="border border-gray-500 p-2">{player.user_type === "1" ? 'Player' : 'Coach'}</td>
                <td className="border border-gray-500 p-2">{countMatchesPlayed(player.id)}</td>
                <td className="border border-gray-500 p-2">{countBowlingVideos(player.id)}</td>
                <td className="border border-gray-500 p-2">{battingVideosCount[player.id] || 0}</td>
                <td className="border border-gray-500 p-2">{formatDate(player.createdAt)}</td>
                <td className="border border-gray-500 p-2">{player.name_of_academic_institution || "None"}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {visiblePlayers < filteredPlayers.length && (
        <div className="text-center my-4">
          <button
            onClick={handleLoadMore}
            className="p-2 rounded-md bg-orange-300 text-white border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
          >
            Load More
          </button>
          <button
            onClick={handleShowLess}
            className="p-2 rounded-md bg-orange-300 text-white border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
          >
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default PlayerTable;
