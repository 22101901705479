import React, { useState, useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  saveNewPlayerRegistrationForm,
  getLoggedInUserInstitutionID,
} from "./Helper";
import Header from "./Components/Header";
import PlayerForm from "./Components/NewPlayerRegistration/PlayerForm";
import AddPlayerToSubscription from "./Components/NewPlayerRegistration/AddPlayerToSubscription";
import AddPlayerFees from "./Components/NewPlayerRegistration/AddPlayerFees";
import { fetchPlanList } from "./Helper";

const NewPlayerRegistration = () => {
  const [headerTitle, setHeaderTitle] = useOutletContext();
  useEffect(() => {
    setHeaderTitle("New Player Registration");
  }, []);

  // SAME USER ID MUST BE REGISTERED IN APP AND ADMIN PANEL FOR THIS TO WORK
  const [loggedInUserInstitutionID, setLoggedInUserInstitutionID] =
    useState("");
  const [selectedPlanDataObject, setSelectedPlanDataObject] = useState(null);
  // dateOfJoining is initiated here instead of in Playerform because subscription validfrom date depends on it.
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [personalDetails, setPersonalDetails] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [feeDetails, setFeeDetails] = useState({});
  // Plan list variables are displayed on this page as length of validplanlist is required to decide whether to display addplayerToSubscription and addPlayerFees component
  const [validPlanList, setValidPlanList] = useState([]); //These are plans which have not expired
  const [isPlanListLoading, setIsPlanListLoading] = useState(true);

  const playerFormRef = useRef(null);
  const addPlayerToSubscriptionRef = useRef(null);
  const addPlayerFeesRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getLoggedInUserInstitutionID();
      setLoggedInUserInstitutionID(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutionID = sessionStorage.getItem("loggedUserInstitutionID");
        if (!institutionID) {
          console.log("Institution ID not available in sessionStorage");
        }
        const data = await fetchPlanList(institutionID);
        console.log(loggedInUserInstitutionID);
        console.log("Plans List:", data);
        setValidPlanList(data);
        // setIsInstituteIDIsLoading(false);
        setIsPlanListLoading(false);
      } catch (error) {
        console.log("Error in fetchPlanList:", error.message);
      }
    };
    if (loggedInUserInstitutionID != "") {
      fetchData();
    }
  }, [loggedInUserInstitutionID]);

  const requiredFieldsNotification = () => {
    toast.warn("Name and Contact number are mandatory fields!");
  };

  // Function to reset fields in child components
  const resetAllFields = () => {
    // Reset fields in the PlayerForm component
    playerFormRef.current.resetFields();

    // Reset fields in the AddPlayerToSubscription component
    addPlayerToSubscriptionRef.current.resetFields();

    // Reset fields in the AddPlayerFees component
    addPlayerFeesRef.current.resetFields();
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    // Combine data from the three components
    const combinedData = {
      personal: personalDetails,
      subscription: subscriptionDetails,
      feePaid: feeDetails,
    };
    if (
      combinedData.personal.name != "" &&
      combinedData.personal.contactNo != ""
    ) {
      console.log("Combined Data:", combinedData);

      try {
        if (loggedInUserInstitutionID == "") {
          setLoggedInUserInstitutionID(
            sessionStorage.getItem("loggedUserInstitutionID")
          );
        }

        // Save the form data using the helper function
        const response = await saveNewPlayerRegistrationForm(combinedData);

        if (response.status == true) {
          toast.success(response.message);
          setTimeout(function () {
            resetAllFields();
          }, 1000); // 1000 milliseconds (1 second)
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Error encountered while submitting" + error.message);
      }
    } else {
      requiredFieldsNotification();
    }
  };
  return (
    <div className="m-12 px-12 py-6 border border-black rounded-xl flex flex-col gap-10">
      <Header title={"New Player Registration"} />
      <PlayerForm
        loggedInUserInstitutionID={loggedInUserInstitutionID}
        setPersonalDetails={setPersonalDetails}
        dateOfJoining={dateOfJoining}
        setDateOfJoining={setDateOfJoining}
        ref={playerFormRef}
      />
      {validPlanList.length > 0 ? <div className="flex flex-col gap-10">
        <AddPlayerToSubscription
          setSelectedPlanDataObject={setSelectedPlanDataObject}
          setSubscriptionDetails={setSubscriptionDetails}
          dateOfJoining={dateOfJoining}
          validPlanList={validPlanList}
          ref={addPlayerToSubscriptionRef}
        />
        <AddPlayerFees
          selectedPlanDataObject={selectedPlanDataObject}
          setFeeDetails={setFeeDetails}
          dateOfJoining={dateOfJoining}
          ref={addPlayerFeesRef}
        />
      </div>
        : <span className="text-red-500">
          Define subscription plans on Setup Fees page to input subscription and fees.
        </span>
      }
      <button
        onClick={handleSubmit}
        className="w-36 p-2 mb-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform self-center"
      >
        Submit
      </button>
      <ToastContainer
        position="top-center"
        autoClose={5000}
      // style={{ width: "750px" }}
      />
    </div>
  );
};

export default NewPlayerRegistration;
