import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VideoCountTable = () => {
  const [matchDetails, setMatchDetails] = useState([]);
  const [videoCount, setVideoCount] = useState({});
  const [expectedVideoCount, setExpectedVideoCount] = useState({});
  const [createdDates, setCreatedDates] = useState({});
  const [visibleMatches, setVisibleMatches] = useState(20);
  const [sortByCreatedDate, setSortByCreatedDate] = useState(true);
  const [selectedInning, setSelectedInning] = useState(null);


  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-match-details`)
      .then(response => {
        setMatchDetails(response.data);
        const { videosCount, expectedVideosCount, matchDates } = processMatchDetails(response.data);
        setVideoCount(videosCount);
        setExpectedVideoCount(expectedVideosCount);
        setCreatedDates(matchDates);
      })
      .catch(error => {
        console.error('Error fetching match details:', error);
      });
  }, []);

  const useMatchDetails = () => {
    const [matchDetails, setMatchDetails] = useState([]);

    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/get-all_matches-details`)
        .then((response) => {
          setMatchDetails(response.data.matches);
        })
        .catch((error) => {
          console.error('Error fetching match details:', error);
        });
    }, []);

    return matchDetails;
  };

  const matches = useMatchDetails();
  useEffect(() => {
    const sortedMatchIds = Array.from(new Set(matchDetails.map(match => match.match_id)))
      .sort((a, b) => {
        const dateA = new Date(createdDates[a]);
        const dateB = new Date(createdDates[b]);
        return sortByCreatedDate ? dateA - dateB : dateB - dateA;
      });

    setMatchDetails(matchDetails.sort((a, b) => {
      const dateA = new Date(createdDates[a.match_id]);
      const dateB = new Date(createdDates[b.match_id]);
      return sortByCreatedDate ? dateA - dateB : dateB - dateA;
    }));
  }, [sortByCreatedDate, createdDates, matchDetails]);

  const processMatchDetails = (matchDetails) => {
    const videosCount = {};
    const expectedVideosCount = {};
    const matchDates = {};

    matchDetails.forEach((match) => {
      const matchId = match.match_id;
      const videoUrl = match.video_url;

      if (matchId) {
        videosCount[matchId] = (videosCount[matchId] || 0) + (videoUrl ? 1 : 0);
        expectedVideosCount[matchId] = (expectedVideosCount[matchId] || 0) + 1;
        matchDates[matchId] = match.createdAt;
      }
    });

    return { videosCount, expectedVideosCount, matchDates };
  };

  const handleLoadMore = () => {
    setVisibleMatches(prevVisibleMatches => prevVisibleMatches + 10);
  };

  const handleShowLess = () => {
    setVisibleMatches(prevVisibleMatches => Math.max(prevVisibleMatches - 10, 20));
  };

  const toggleSortByCreatedDate = () => {
    setSortByCreatedDate(prevSort => !prevSort);
  };

  const getActualVideoCountForInning = (matchId) => {
    if (selectedInning === null || !videoCount[matchId]) {
      return videoCount[matchId] || 0;
    }

    const inningVideosCount = matchDetails
      .filter(match => match.current_inning === selectedInning && match.match_id === matchId)
      .reduce((count, match) => (match.video_url ? count + 1 : count), 0);

    return inningVideosCount;
  };

  const handleFilterByInning = (inning) => {
    setSelectedInning(inning);
  };


  const formatDate = (dateString) => {
    try {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const calculateInningScore = (matchId, inning) => {
    const inningDetails = matchDetails.filter(match => match.match_id === matchId && match.current_inning === inning);

    return inningDetails.reduce((score, inning) => {
      const { ball_delivery_result, extra_ball_delivery_result } = inning;

      // Add the value from ball_delivery_result to the score
      let ballScore = parseInt(ball_delivery_result) || 0;

      // If ball_delivery_result is "W", add 0 to the score
      if (ball_delivery_result === "W") {
        ballScore = 0;
      }

      // If extra_ball_delivery_result is "WD" or "NB", add ball_delivery_result + 1 to the score
      if (extra_ball_delivery_result === "WD" || extra_ball_delivery_result === "NB") {
        ballScore += 1;
      }

      return score + ballScore;
    }, 0);
  };

  const calculateExpectedVideoCount = (matchId, inning) => {

    const inningDetails = matchDetails.filter(
      (match) => match.match_id === matchId && match.current_inning === inning
    );


    return inningDetails.length;
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold"> Match Details</h2>

        <button
          onClick={() => navigate('/Dashboard')}
          className="p-2 rounded-md bg-blue-500 text-white border-none mx-2 transition-all duration-300 hover:bg-blue-600 hover:shadow-lg cursor-pointer"
        >
          Go to Dashboard
        </button>
      </div>
      <div className="mb-4">
        <button
          onClick={toggleSortByCreatedDate}
          className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer mb-4"
        >
          Sort by Created Date {sortByCreatedDate ? 'Asc' : 'Desc'}
        </button>
        <div className="mb-4">
          <select
            value={selectedInning || 'all'}
            onChange={(e) => handleFilterByInning(e.target.value === 'all' ? null : parseInt(e.target.value))}
            className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer mb-4 pr-8 bg-no-repeat bg-right-center"
            style={{
              backgroundImage: 'url("path-to-your-custom-arrow-icon.png")',
            }}
          >
            <option value="all">Both Innings</option>
            <option value="1">Inning 1</option>
            <option value="2">Inning 2</option>
          </select>
        </div>

      </div>

      <table className="w-full border-collapse border-2 border-gray-500">
        <thead>
          <tr>
            <th className="border border-gray-500 p-2">Serial Number</th>
            <th className="border border-gray-500 p-2">Match ID</th>
            <th className="border border-gray-500 p-2">Match Name</th>
            <th className="border border-gray-500 p-2">Actual Video Count</th>
            <th className="border border-gray-500 p-2">{`Expected Video Count ${selectedInning || ""}`} </th>
            <th className="border border-gray-500 p-2"> Accuracy</th>
            <th className="border border-gray-500 p-2">Created At</th>
            <th className="border border-gray-500 p-2">Innings</th>
            <th className="border border-gray-500 p-2">Inning 1 Score</th>
            <th className="border border-gray-500 p-2">Inning 2 Score</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(new Set(matchDetails.map(match => match.match_id))).slice(0, visibleMatches).map((matchId, index) => {
            const matchInfo = matches.find(match => match.id === matchId);
            return (
              <tr key={matchId}>
                <td className="border border-gray-500 p-2">{index + 1}</td>
                <td className="border border-gray-500 p-2">{matchId}</td>
                <td className="border border-gray-500 p-2">{matchInfo ? matchInfo.name : 'N/A'}</td>
                <td className="border border-gray-500 p-2">{getActualVideoCountForInning(matchId)}</td>
                {selectedInning === 1 &&
                  <td className="border border-gray-500 p-2">
                    {calculateExpectedVideoCount(matchId, 1)}
                  </td>
                }
                {
                  selectedInning === 2 &&
                  <td className="border border-gray-500 p-2">
                    {calculateExpectedVideoCount(matchId, 2)}
                  </td>
                }
                {
                  selectedInning === null &&
                  <td className="border border-gray-500 p-2">
                    {(calculateExpectedVideoCount(matchId, 2) + calculateExpectedVideoCount(matchId, 1))}
                  </td>
                }
                {selectedInning === 1 &&
                  <td className="border border-gray-500 p-2">
                    {(getActualVideoCountForInning(matchId) / calculateExpectedVideoCount(matchId, 1) * 100).toFixed(2)}
                  </td>
                }
                {selectedInning === 2 &&
                  <td className="border border-gray-500 p-2">
                    {(getActualVideoCountForInning(matchId) / calculateExpectedVideoCount(matchId, 2) * 100).toFixed(2)}
                  </td>
                }
                {selectedInning === null &&
                  <td className="border border-gray-500 p-2">
                    {(getActualVideoCountForInning(matchId) / (calculateExpectedVideoCount(matchId, 2) + calculateExpectedVideoCount(matchId, 1)) * 100).toFixed(2)}
                  </td>
                }
                <td className="border border-gray-500 p-2">{formatDate(createdDates[matchId])}</td>
                <td className="border border-gray-500 p-2">{selectedInning || 'Both'}</td>
                <td className="border border-gray-500 p-2">{calculateInningScore(matchId, 1)}</td>
                <td className="border border-gray-500 p-2">{calculateInningScore(matchId, 2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {visibleMatches < matchDetails.length && (
        <div className="text-center my-4">
          <button
            onClick={handleLoadMore}
            className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
          >
            Load More
          </button>
          <button
            onClick={handleShowLess}
            className="p-2 rounded-md bg-orange-300 text-black border-none mx-2 transition-all duration-300 hover:bg-orange-400 hover:shadow-lg cursor-pointer"
          >
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoCountTable;


