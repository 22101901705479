import React, { useEffect, useState, useMemo } from 'react';
import Pagination from '../../assets/js/pagination';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import Select from 'react-select';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./MonitoringTrainingProgram.css";
import {
    Button, CircularProgress,
} from "@mui/material";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { getTaskPlannerList, updateTrainingProgramStatus, updateAppUserBlockedStatus } from './Helper';
import { useForm } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import { useOutletContext, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';

const MonitoringTrainingProgram = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousMatches, setPreviousMatches] = useState([]);
    const [subscriptiondata, setSubscriptionData] = useState([]);
    const [razorpayplan, setRazorpayplan] = useState([]);
    const [appcitydata, setappcitydata] = useState([]);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [razorpayid, setrazorpayid] = useState([]);
    const PageSize = 10;
    const [pageCount, setPageCount] = useState(1);

    const [headerTitle, setHeaderTitle] = useOutletContext();
    const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
    const [showAddTask, setShowAddTask] = React.useState(false);
    const [showAddPlan, setShowAddPlan] = React.useState(false);
    const [showeditplan, setshoweditplan] = React.useState(false);

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEditCity, setSelectedEditCity] = useState(null);
    const [errorLogData, setErrorLogData] = useState([]);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
    } = useForm();

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedController, setSelectedController] = useState('');

    useEffect(() => {
        setHeaderTitle("Previous Match Data");

        getTaskPlannerList()
            .then((data) => {
                if (data?.status) {
                    setSubscriptionData(data?.task_planner_list);

                    setPageCount(data.task_planner_list.length);
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentPage, setHeaderTitle]);
    const [controllerList, setControllerList] = useState([]);

    useEffect(() => {
        setHeaderTitle("Previous Match Data");

    }, [currentPage, setHeaderTitle]);

    const formatDate = (timestamp) => {
        if (!timestamp) return 'NA';
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const handleReset = () => {
        setSearchQuery('');
        setSelectedController('');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };
    const handleControllerChange = (event) => {
        setSelectedController(event.target.value);
        setCurrentPage(1);
    };

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };

    const handleModalEditProgram = (log) => {
        setSelectedErrorLog(log);
        setshoweditplan(true);
    };

    const changeAdminRejection = (id, is_admin_rejected, index) => {
        const formDataJson = {
            id: id,
            is_admin_rejected: is_admin_rejected
        };
        updateTrainingProgramStatus(formDataJson)
            .then((update_status_data) => {

                setSubscriptionData((prevData) => {
                    const updatedSubscriptionData = [...prevData];
                    updatedSubscriptionData[index]["is_admin_rejected"] = is_admin_rejected;
                    return updatedSubscriptionData;
                });

                if (is_admin_rejected == 0) {
                    toast.success('The uploaded video has been "activated"');
                }
                else {
                    toast.success('The uploaded video has been "de-activated"');
                }
            })
            .catch((e) => {
            });
    };

    const changeAdminBlocked = (id, is_admin_blocked, index) => {
        const formDataJson = {
            id: id,
            is_admin_blocked: is_admin_blocked
        };

        updateAppUserBlockedStatus(formDataJson)
            .then((update_status_data) => {

                setSubscriptionData((prevData) => {
                    const updatedSubscriptionData = [...prevData];
                    if (updatedSubscriptionData.length > 0) {
                        for (let i = 0; i < updatedSubscriptionData.length; i++) {
                            if (updatedSubscriptionData[i]["player_detail"] != null) {
                                if (updatedSubscriptionData[i]["player_detail"]["id"] == id) {
                                    updatedSubscriptionData[i]["player_detail"]["is_admin_blocked"] = is_admin_blocked;
                                }
                            }
                        }
                    }

                    return updatedSubscriptionData;
                });

                if (is_admin_blocked == 0) {
                    toast.success('The user has been "unblocked"');
                }
                else {
                    toast.success('The user has been "blocked"');
                }
            })
            .catch((e) => {

            });
    };
    const filteredData = useMemo(() => {
        if (searchQuery != "") {
            return subscriptiondata.filter((log) =>
                log.player_detail &&
                log.player_detail.full_name &&
                log.player_detail.full_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        else {
            return subscriptiondata;
        }
    }, [subscriptiondata, searchQuery, selectedController]);

    const currentTableData = useMemo(() => {
        const startIndex = (currentPage - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const slicedData = filteredData.slice(startIndex, endIndex);
        setPageCount(filteredData.length);
        return slicedData;
    }, [currentPage, PageSize, filteredData]);

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        return dateObject.toISOString().split('T')[0];
    };

    return (
        <>
            <div>
                <h1
                    className="text-center m-2"
                    style={{
                        color: "#000", backgroundColor: "#0df6e3",
                        fontSize: "18px",
                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                    }}
                >
                    Training Program
                </h1>
            </div>
            <div style={{ textAlign: 'center' }}>
                {loading ? (
                    <>
                        <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>
                            Please Wait Data is{" "}
                            <span style={{ color: 'orange', fontWeight: '600' }}>
                                Loading...
                            </span>
                        </h2>
                    </>
                ) : (
                    <>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search by Uploaded By..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-input"
                            />
                            <div >
                                <button type="button" style={{ marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', marginLeft: '20px' }} onClick={handleReset} class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <RefreshIcon /> Reset
                                </button>
                            </div>
                        </div>
                        <table className="custom-row-colors" style={{ borderCollapse: 'collapse', width: '100%', marginTop: "12px" }}>
                            <thead>
                                <tr>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Sr. No.</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Uploaded By</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Training Program</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Focus Area</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Activity</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Category</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Video</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Created At</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Action</th>
                                </tr>
                            </thead>
                            {currentTableData.length === 0 ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'red', fontWeight: '600' }}>Data not found</p>
                                    </td>
                                </tr>
                            ) : (
                                <tbody>
                                    {currentTableData.map((log, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='dataTd'>{((currentPage - 1) * 10) + (index + 1)}</td>
                                            <td className='dataTd'>
                                                {
                                                    log.player_detail ?
                                                        <>
                                                            {log.player_detail.full_name}<br />
                                                            {log.player_detail.is_admin_blocked == 0 ?
                                                                <>
                                                                    <ReactTooltip
                                                                        anchorId={`person_icon_${log.id}`}
                                                                        place="top"
                                                                        content="Unblock user account in mobile app"
                                                                        className="tooltip"
                                                                    />
                                                                    <PersonIcon id={`person_icon_${log.id}`} style={{ color: 'green', 'cursor': 'pointer' }} onClick={() => changeAdminBlocked(log.player_detail.id, 1, ((currentPage - 1) * 10) + (index))} />
                                                                </> :
                                                                <>
                                                                    <ReactTooltip
                                                                        anchorId={`person_off_icon_${log.id}`}
                                                                        place="top"
                                                                        content="Block user account in mobile app"
                                                                        className="tooltip"
                                                                    />
                                                                    <PersonOffIcon id={`person_off_icon_${log.id}`} style={{ color: 'red', 'cursor': 'pointer' }} onClick={() => changeAdminBlocked(log.player_detail.id, 0, ((currentPage - 1) * 10) + (index))} /></>
                                                            }
                                                        </>
                                                        : ''
                                                }
                                            </td>
                                            <td className='dataTd'>{log.program_detail ? log.program_detail.planningName : ''}</td>
                                            <td className='dataTd'>{log.focus_area_detail ? log.focus_area_detail.focus_area_name : ''}</td>
                                            <td className='dataTd'>{log.task_detail ? log.task_detail.taskName : ''}</td>
                                            <td className='dataTd'>
                                                {log.activity_detail ? log.activity_detail.category_name : ''}

                                            </td>
                                            <td className='dataTd'>
                                                <video width="120" height="120" controls key={log.video_url && log.video_url.length > 0 ? log.video_url[0]["location"] : ''}>
                                                    <source src={log.video_url && log.video_url.length > 0 ? log.video_url[0]["location"] : ''} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </td>
                                            <td className='dataTd'>{log.createdAt ? formatDateString(log.createdAt) : 'NA'}</td>
                                            <td className='dataTd'>
                                                {log.is_admin_rejected == 0 ?
                                                    <>
                                                        <ReactTooltip
                                                            anchorId={`check_icon_${log.id}`}
                                                            place="left"
                                                            content="Hide this video in mobile app"
                                                            className="tooltip"
                                                        />
                                                        <CheckCircle id={`check_icon_${log.id}`} style={{ color: 'green', 'cursor': 'pointer' }} onClick={() => changeAdminRejection(log.id, 1, ((currentPage - 1) * 10) + (index))} />
                                                    </> :
                                                    <>
                                                        <ReactTooltip
                                                            anchorId={`cancel_icon_${log.id}`}
                                                            place="left"
                                                            content="Show this video in mobile app"
                                                            className="tooltip" />
                                                        <CancelIcon id={`cancel_icon_${log.id}`} style={{ color: 'red', 'cursor': 'pointer' }} onClick={() => changeAdminRejection(log.id, 0, ((currentPage - 1) * 10) + (index))} /></>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            style={{ width: "30%" }}
                        />

                        <form >
                            {showeditplan && selectedErrorLog ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                <div
                                                    style={{ margin: '18px', backgroundColor: '#1f242cd4', color: 'white' }}
                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >
                                                    <h2 style={{ textAlign: 'left', margin: '7px' }}>
                                                        <span style={{ color: 'white', fontWeight: '400' }}>ID : </span>{' '}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>
                                                            {selectedErrorLog.id ? selectedErrorLog.id : 'NA'}
                                                        </span>
                                                    </h2>
                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        {/* API Error Log Details */}
                                                        {/* Match Analysis Error Log Details */}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>Type of Error : </span>{' '}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>
                                                            {selectedErrorLog.type_of_error ? selectedErrorLog.type_of_error : 'NA'}
                                                        </span>
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'white' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setshoweditplan(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>
                                                <div>

                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Tournament:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.tournament_detail.name ? selectedErrorLog.tournament_detail.name : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Match Name:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.match_detail.name ? selectedErrorLog.match_detail.name : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div>
                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Current Inning:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>

                                                                {selectedErrorLog.current_inning ? (selectedErrorLog.current_inning) : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Current Ball:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>

                                                                {selectedErrorLog.current_ball ? (selectedErrorLog.current_ball) : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div>
                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Total Overs:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.overs ? (selectedErrorLog.overs) : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Created At:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.createdAt ? formatDate(selectedErrorLog.createdAt) : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>

                                                <div>
                                                    <div
                                                        className="overflow-x-auto overflow-y-auto"
                                                        style={{ maxHeight: '70vh', padding: '0 25px' }}
                                                    >
                                                        <div className="flex" style={{ textAlign: 'left' }}>
                                                            <div style={{ width: "100%" }}>
                                                                <p className="text-gray-600 border-b mb-2 pb-2">
                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Error Description:</span>{' '}
                                                                    {selectedErrorLog.error_description ? (
                                                                        <pre style={{ color: '#008b8b', fontWeight: '600' }}>
                                                                            {selectedErrorLog.error_description}
                                                                        </pre>

                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                                <p className="text-gray-600 border-b mb-2 pb-2">
                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Input Data:</span>{' '}
                                                                    {selectedErrorLog.input_data ? (
                                                                        <pre style={{ background: '#000', color: '#FFF' }}>
                                                                            {JSON.stringify(
                                                                                JSON.parse(selectedErrorLog.input_data),
                                                                                null,
                                                                                2
                                                                            )}
                                                                        </pre>
                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                    <button
                                                        className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                                        type="button"
                                                        onClick={() => {
                                                            setshoweditplan(false);
                                                        }}
                                                    >
                                                        <CancelIcon /> Cancel Log
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>

                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={pageCount}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </>
                )}
            </div>
        </>
    );
}
export default MonitoringTrainingProgram;
