import React, { useEffect, useState } from "react";
import AddPlayerToPlan from "./PlanManagement/AddPlayerToPlan";
import ActivePlans from "./PlanManagement/ActivePlans";

const PlanManagement = ({
  selectedPlayerIndex,
  selectedPlayerName,
  loggedInUserInstitutionID,
  validPlanList,
  isActiveSubscriptionListLoading,
  setIsActiveSubscriptionListLoading,
  isActiveSubscriptionList,
  setIsActiveSubscriptionList,
  addedNewSubscriptionReload,
  setAddNewSubscriptionReload,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <section>
        <AddPlayerToPlan
          selectedPlayerIndex={selectedPlayerIndex}
          selectedPlayerName={selectedPlayerName}
          loggedInUserInstitutionID={loggedInUserInstitutionID}
          validPlanList={validPlanList}
          addedNewSubscriptionReload={addedNewSubscriptionReload}
          setAddNewSubscriptionReload={setAddNewSubscriptionReload}
        />
      </section>
      <section>
        <ActivePlans
          selectedPlayerIndex={selectedPlayerIndex}
          loggedInUserInstitutionID={loggedInUserInstitutionID}
          addedNewSubscriptionReload={addedNewSubscriptionReload}
          isActiveSubscriptionListLoading={isActiveSubscriptionListLoading}
          setIsActiveSubscriptionListLoading={
            setIsActiveSubscriptionListLoading
          }
          isActiveSubscriptionList={isActiveSubscriptionList}
          setIsActiveSubscriptionList={setIsActiveSubscriptionList}
        />
      </section>
    </div>
  );
};

export default PlanManagement;
