import React from "react";

const PlanOptionsBar = ({
  addNewPlanToggle,
  setAddNewPlanToggle,
  handleSavePlanToggleClick,
  handleCancelPlanToggleClick,
  selectedPlanIndex,
  setSelectedPlanIndex,
  handleResetPlan,
}) => {
  const handleAddNewPlanClick = () => {
    setAddNewPlanToggle((addNewPlanToggle) => !addNewPlanToggle);
    handleResetPlan();
    setSelectedPlanIndex("");
  };

  const handleEditPlanClick = () => {
    setAddNewPlanToggle((addNewPlanToggle) => !addNewPlanToggle);
    handleResetPlan();
  };

  return (
    <div className="w-full flex justify-end">
      {addNewPlanToggle ? (
        <div className="flex gap-4">
          <button
            onClick={handleAddNewPlanClick}
            className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
          >
            Add New Plan
          </button>
          {/* {selectedPlanIndex != "" && (
            <button
              onClick={handleEditPlanClick}
              className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
            >
              Edit Plan
            </button>
          )} */}
        </div>
      ) : (
        <div className="flex gap-4">
          <button
            onClick={handleSavePlanToggleClick}
            className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
          >
            Save
          </button>
          <button
            onClick={handleCancelPlanToggleClick}
            className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default PlanOptionsBar;
