import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Creatable from "react-select/creatable";
import DatePicker from "react-datepicker";

const AddPlayerToSubscription = (
  {
    setSelectedPlanDataObject,
    setSubscriptionDetails,
    dateOfJoining,
    validPlanList,
  },
  addPlayerToSubscriptionRef
) => {
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTill, setValidTill] = useState("");
  const [selectedPlanOption, setSelectedPlanOption] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleValidFromChange = (valid_from) => {
    // setDateOfJoining(dateOfJoining);
    const utcDate = new Date(valid_from);
    utcDate.setUTCHours(0, 0, 0, 0);
    // Increase the date by one day
    // utcDate.setDate(utcDate.getDate() + 1);
    setValidFrom(utcDate);
    // setValidTill();
  };

  const handlePlanSelection = (selectedOption) => {
    if (selectedOption) {
      const selectedPlanData = validPlanList.find(
        (plan) => plan.plan_name === selectedOption.label
      );

      setSelectedPlanOption({
        plan_id: selectedPlanData.id,
        plan_name: selectedOption.label,
        // payment_duration: selectedPlanData.payment_duration,
      });
      setSelectedPlan({
        plan_id: selectedPlanData.id,
        institution_id: selectedPlanData.institution_id,
        plan_name: selectedOption.label,
        payment_duration: selectedPlanData.payment_duration,
      });
      // console.log(selectedPlanData);
      setSelectedPlanDataObject(selectedPlanData);
    } else {
      setSelectedPlan(null);
      setSelectedPlanOption(null);
      setSelectedPlanDataObject(null);
    }
  };

  useEffect(() => {
    setValidFrom(dateOfJoining);
  }, [dateOfJoining]);

  useEffect(() => {
    if (validFrom && selectedPlan?.payment_duration) {
      let monthsToAdd = 0;
      switch (selectedPlan?.payment_duration) {
        case "Annual":
          monthsToAdd = 12;
          break;
        case "Semi-Annual":
          monthsToAdd = 6;
          break;
        case "Tri-Annual":
          monthsToAdd = 4;
          break;
        case "Quarterly":
          monthsToAdd = 3;
          break;
        case "Monthly":
          monthsToAdd = 1;
          break;
        default:
          monthsToAdd = 0;
      }
      const newValidTill = new Date(validFrom);
      newValidTill.setMonth(newValidTill.getMonth() + monthsToAdd);
      newValidTill.setDate(newValidTill.getDate() - 1);
      setValidTill(newValidTill);
    }
  }, [selectedPlan, validFrom]);

  // for collating and sending subscriptionDetails to newplayerRegistration Page
  useEffect(() => {
    console.log("selectedPlan:", selectedPlan);
    const selectedPlanId = selectedPlan?.plan_id;
    const subscriptionDetails = {
      selectedPlanId,
      validFrom,
      validTill,
    };
    setSubscriptionDetails(subscriptionDetails);
  }, [validFrom, validTill, selectedPlan]);

  const resetFields = () => {
    setValidFrom(new Date());
    setSelectedPlanOption(null);
    setSelectedPlan(null);
    setValidTill("");
  };

  useImperativeHandle(addPlayerToSubscriptionRef, () => ({
    resetFields: resetFields,
  }));

  return (
    <div className="flex flex-col gap-4 w-full">
      <h2 className="text-center text-xl font-bold">
        Player Subscription Details
      </h2>
      <section className="flex flex-row gap-40 items-center justify-center">
        <div className="w-1/5">
          <Creatable
            options={validPlanList
              .filter((plan) => new Date(plan.valid_till) > new Date())
              .map((plan) => ({
                label: plan.plan_name,
                value: plan.plan_name,
              }))}
            placeholder="Choose Plan"
            value={
              selectedPlanOption
                ? {
                  label: selectedPlanOption.plan_name,
                  value: selectedPlanOption.plan_name,
                }
                : null
            }
            onChange={handlePlanSelection}
            isSearchable={true}
            isCreatable={false}
            isClearable={true}
          // isRequired
          />
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="text-end">Subscription valid from:</div>
          <div>
            <DatePicker
              selected={validFrom}
              onChange={handleValidFromChange}
              dateFormat="dd-MMM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={50}
              className="text-center p-1 rounded border border-black w-full"
            />
          </div>
        </div>

        <div className="text-center">
          {validTill
            ? "Subscription valid till: " +
            validTill.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            : "Subscription valid till: Pick a plan/valid from date!"}
        </div>
      </section>
    </div>
  );
};
export default forwardRef(AddPlayerToSubscription);
