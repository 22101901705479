import axios from 'axios';

export const GetAppPlansList = async () => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-app-plan-list`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};


export const AddAppPlansList = async (formData) => {
	let subcription = JSON.stringify(formData)
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/add-app-plan-list`,formData,
			
			{
				headers: {
					// 'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAppCityList = async () => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-app-city-list`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetRazorPayPlan = async () => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-razor-pay-plan`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const UpdateAppPlans = async (formData) => {
	let subcription = JSON.stringify(formData)
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/update-razor-pay-plan`,formData,
			
			{
				headers: {
					// 'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAppPlansListByID = async (planId) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-razor-pay-plan-id`,planId,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

