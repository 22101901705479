import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { ChangeAppUserPassword } from "./Helper";
import { useForm } from "react-hook-form";

const MyAuth = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();

  useEffect(() => {
    setHeaderTitle("My Authorisation");
  }, []);

  const handleSubmitChangePassword = (details) => {

    ChangeAppUserPassword(details).then((data) => {
      if (data?.status) {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Password Updated Successfully'
        })
      } else {
        if (data?.error === "User does not exist") {
          Swal.fire({
            icon: 'info',
            title: '😑',
            text: 'No App User registered with this Phone Number'
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Unable to update password'
          })
        }
      }
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: '⛔',
        text: err
      })
    })
  }
  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }}
        >
          My Authorizations
        </h1>
      </div>
      <div className="p-5 h-screen">
        <div className="grid grid-cols-4 gap-5">
          <div></div>
          <div className="pt-24 col-span-2 ">
            <form onSubmit={handleSubmit(handleSubmitChangePassword)}>
              <div className="p-4 shadow-2xl bg-white form-control">
                <h1 className="text-center m-2" style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "18px" }}>
                  Change App User Password
                </h1>
                <div>
                  <label
                    htmlFor="phone"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Phone Number
                  </label>
                  <input
                    {...register('phone', {
                      required: true,
                    })}
                    type="number"
                    id="phone"
                    placeholder="Phone"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <p className="errorMsg">Mobile Number is required.</p>
                  )}
                </div>
                <div className="pt-4">
                  <label
                    htmlFor="password"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Enter Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    {...register('newPassword', {
                      required: true,
                      pattern: /\d{4}/
                    })}
                    placeholder="Enter Password"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                  />
                  {errors.newPassword && errors.newPassword.type === "required" && (
                    <p className="errorMsg">Password is required.</p>
                  )}
                  {errors.newPassword && errors.newPassword.type === "pattern" && (
                    <p className="errorMsg">
                      Password must be a 4-digit number
                    </p>
                  )}
                </div>
                <div className="pt-4">
                  <label
                    htmlFor="cPassword"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="cPassword"
                    {...register('cPassword', {
                      required: true,
                      validate: () => getValues('newPassword') == getValues('cPassword')
                    })}
                    placeholder="Confirm Password"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                  />
                  {errors.cPassword && errors.cPassword.type === "required" && (
                    <p className="errorMsg">Confirm Password is required.</p>
                  )}
                  {errors.cPassword && errors.cPassword.type === "validate" && (
                    <p className="errorMsg">
                      Password and confirm password do not match
                    </p>
                  )}
                </div>
                <div className="pt-4">
                  <Button type="submit" style={{ backgroundColor: '#0DF6E3', color: '#2B303B' }} variant="contained" fullWidth>Change Password</Button>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyAuth;
