import axios from "axios";

// export const getRrrorLogMatchAnalysisListmatch = async (
//   offset,
//   // type_of_error = "",
//   match_id = ""
// ) => {
//   try {
//     const res = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/get-error-log-match-analysis-list`,
//       {
//         offset,
//         // type_of_error,
//         match_id,
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${
//             JSON.parse(localStorage.getItem("WellplayedToken")).token
//           }`,
//         },
//       }
//     );
//     return res.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getRrrorLogMatchAnalysisList = async (
  offset,
  type_of_error = "",
  match_id = ""
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-error-log-match-analysis-list`,
      {
        offset,
        type_of_error,
        match_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//controller file list

export const GetTypeofErrorList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-controller-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetAllTournamentsErrorLog = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/tournaments-error`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetMatchByTournamentErrorLog = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-matches-by-tournament-error/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
