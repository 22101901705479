import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCityList } from "../../Helper";
import Creatable from "react-select/creatable";

const PlayerForm = (
  {
    loggedInUserInstitutionID,
    setPersonalDetails,
    dateOfJoining,
    setDateOfJoining,
  },
  playerFormRef
) => {
  useEffect(() => {
    fetchCityList();
  }, []);
  // FORM DETAILS
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [gender, setGender] = useState("");
  const [parentOccupation, setParentOccupation] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [fatherContactNo, setFatherContactNo] = useState("");
  const [motherContactNo, setMotherContactNo] = useState("");
  const [dob, setDob] = useState(new Date());
  // date of joining added in parent component as it is also being used in a sibling component
  const [preferredTiming, setPreferredTiming] = useState("");
  const [nameOfAcademicInstitution, setNameofAcademicInstitution] =
    useState("");
  const [batchAllotted, setBatchAllotted] = useState("");
  const [persona, setPersona] = useState("");
  const [playerProfile, setPlayerProfile] = useState("");
  const [isAllRounder, setIsAllRounder] = useState(false);
  const [isWicketKeeper, setIsWicketKeeper] = useState(false);
  const [batting, setBatting] = useState("");
  const [bowling, setBowling] = useState("");
  const [fielding, setFielding] = useState("");
  const [medicalCondition, setMedicalCondition] = useState("");
  const [cityList, setCityList] = useState([]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCityChange = (city) => {
    setCity(city);
  };

  const handlePinCodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handleGenderChange = (gender) => {
    setGender(gender);
  };

  const handleParentOccupationChange = (parentOccupation) => {
    setParentOccupation(parentOccupation);
  };

  const handleContactNoChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setContactNo(numericInput);
  };

  const handleFatherContactNoChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setFatherContactNo(numericInput);
  };

  const handleMotherContactNoChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setMotherContactNo(numericInput);
  };

  const handleDobChange = (dob) => {
    // Following 2 lines are used to fix discrepencies in date records
    const utcDate = new Date(dob);
    utcDate.setUTCHours(0, 0, 0, 0);
    setDob(utcDate);
  };

  const handleDateOfJoiningChange = (dateOfJoining) => {
    // setDateOfJoining(dateOfJoining);
    const utcDate = new Date(dateOfJoining);
    utcDate.setUTCHours(0, 0, 0, 0);
    setDateOfJoining(utcDate);
  };

  const handlePreferredTimingChange = (preferredTiming) => {
    setPreferredTiming(preferredTiming);
  };

  const handleNameofAcademicInstitutionChange = (e) => {
    setNameofAcademicInstitution(e.target.value);
  };

  // const handleBatchAllottedChange = (e) => {
  //   setBatchAllotted(e.target.value);
  // };

  const handlePersonaChange = (persona) => {
    setPersona(persona);
  };

  const handlePlayerProfileChange = (playerProfile) => {
    setPlayerProfile(playerProfile);
  };

  const handleisAllRounderChange = () => {
    setIsAllRounder((isAllRounder) => !isAllRounder);
  };

  const handleIsWicketkeeperChange = () => {
    setIsWicketKeeper((prevIsWicketKeeper) => !prevIsWicketKeeper);
  };

  const handleBattingChange = (batting) => {
    setBatting(batting);
  };

  const handleBowlingChange = (bowling) => {
    setBowling(bowling);
  };

  const handleFieldingChange = (fielding) => {
    setFielding(fielding);
  };

  const handleMedicalCondtion = (e) => {
    setMedicalCondition(e.target.value);
  };

  // for collating and sending subscriptionDetails to newplayerRegistration Page
  useEffect(() => {
    const playerFormData = {
      name,
      address,
      city,
      pincode,
      gender,
      parentOccupation,
      contactNo,
      fatherContactNo,
      motherContactNo,
      dob,
      dateOfJoining,
      preferredTiming,
      nameOfAcademicInstitution,
      batchAllotted,
      persona,
      playerProfile,
      isAllRounder,
      isWicketKeeper,
      batting,
      bowling,
      fielding,
      medicalCondition,
      loggedInUserInstitutionID,
    };
    setPersonalDetails(playerFormData);
  }, [
    name,
    address,
    city,
    pincode,
    gender,
    parentOccupation,
    contactNo,
    fatherContactNo,
    motherContactNo,
    dob,
    dateOfJoining,
    preferredTiming,
    nameOfAcademicInstitution,
    batchAllotted,
    persona,
    playerProfile,
    isAllRounder,
    isWicketKeeper,
    batting,
    bowling,
    fielding,
    medicalCondition,
    loggedInUserInstitutionID,
  ]);

  const fetchCityList = async () => {
    try {
      const response = await getCityList();
      // console.log(response)
      setCityList(response);
    } catch (error) {
      console.error("Error while fetching cities list", error);
    }
  };

  // Function to reset fields in this component
  const resetFields = () => {
    // Reset the state in this component
    setName("");
    setAddress("");
    setCity("");
    setPincode("");
    setGender("");
    setParentOccupation("");
    setContactNo("");
    setFatherContactNo("");
    setMotherContactNo("");
    setDob(new Date());
    setDateOfJoining(new Date());
    setPreferredTiming("");
    setNameofAcademicInstitution("");
    setBatchAllotted("");
    setPersona("");
    setPlayerProfile("");
    setIsAllRounder(false);
    setIsWicketKeeper(false);
    setBatting("");
    setBowling("");
    setFielding("");
    setMedicalCondition("");
  };

  // Expose the resetFields function using useImperativeHandle
  useImperativeHandle(playerFormRef, () => ({
    resetFields: resetFields,
  }));

  return (
    <form className="flex flex-col justify-center items-center gap-10">
      <div className="grid grid-cols-2 gap-36">
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              required
              className="col-span-2 p-2 rounded"
              placeholder="Name"
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={handleAddressChange}
              // required
              className="col-span-2 p-2 rounded"
              placeholder="Address"
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="address">
              City
            </label>
            <Creatable
              options={cityList}
              placeholder="Select city"
              value={city}
              onChange={handleCityChange}
              // onInputChange={handleCityInputChange}
              isSearchable={true}
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="address">
              Pin Code
            </label>
            <input
              type="text"
              id="pin code"
              value={pincode}
              onChange={handlePinCodeChange}
              // required
              className="p-2 rounded"
              placeholder="Pin Code"
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center">Gender</label>
            <div className="col-span-2 grid grid-cols-3 gap-4">
              <button
                type="button"
                onClick={() => handleGenderChange("Male")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: gender === "Male" ? "#ccc" : "transparent",
                }}
              >
                Male
              </button>
              <button
                type="button"
                onClick={() => handleGenderChange("Female")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: gender === "Female" ? "#ccc" : "transparent",
                }}
              >
                Female
              </button>
              <button
                type="button"
                onClick={() => handleGenderChange("Other")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: gender === "Other" ? "#ccc" : "transparent",
                }}
              >
                Other
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center">Parents' Occupation</label>
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handleParentOccupationChange("service")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    parentOccupation === "service" ? "#ccc" : "transparent",
                }}
              >
                Service
              </button>
              <button
                type="button"
                onClick={() => handleParentOccupationChange("business")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    parentOccupation === "business" ? "#ccc" : "transparent",
                }}
              >
                Business
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="name">
              Contact Number
            </label>
            <div className="col-span-2 grid grid-cols-3 gap-4">
              <input
                type="text"
                id="name"
                value={contactNo}
                onChange={handleContactNoChange}
                required
                className="p-2 rounded"
                placeholder="Contact No."
              />
              <input
                type="text"
                id="name"
                value={fatherContactNo}
                onChange={handleFatherContactNoChange}
                className="p-2 rounded"
                placeholder="Father's No."
              />
              <input
                type="text"
                id="name"
                value={motherContactNo}
                onChange={handleMotherContactNoChange}
                className="p-2 rounded"
                placeholder="Mother's No."
              />
            </div>
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="name">
              Date of Birth
            </label>
            <DatePicker
              selected={dob}
              onChange={handleDobChange}
              dateFormat="dd-MMM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={75}
              className="text-center p-1 rounded border border-black"
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="name">
              Date of Joining
            </label>
            <DatePicker
              selected={dateOfJoining}
              onChange={handleDateOfJoiningChange}
              dateFormat="dd-MMM-yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={50}
              className="text-center p-1 rounded border border-black"
            />
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center">Preferred Timing</label>
            <div className="col-span-2 grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handlePreferredTimingChange("morning")}
                className="rounded-lg border border-black p-2transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    preferredTiming === "morning" ? "#ccc" : "transparent",
                }}
              >
                Morning
              </button>
              <button
                type="button"
                onClick={() => handlePreferredTimingChange("evening")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    preferredTiming === "evening" ? "#ccc" : "transparent",
                }}
              >
                Evening
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <label className="flex items-center" htmlFor="name">
              Name of School/College
            </label>
            <input
              type="text"
              id="name"
              value={nameOfAcademicInstitution}
              onChange={handleNameofAcademicInstitutionChange}
              // required
              className="col-span-2 p-2 rounded"
              placeholder="School/College"
            />
          </div>
          {/* <div className="grid grid-cols-3">
                  <label className="flex items-center" htmlFor="name">
                    Batch Allotted
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={batchAllotted}
                    onChange={handleBatchAllottedChange}
                    // required
                    className="col-span-2 p-2 rounded border"
                    placeholder="Batch"
                  />
                </div> */}
        </div>
        <div className="flex flex-col gap-4 justify-start">
          <div className="grid grid-cols-4">
            <label className="flex items-center">Persona</label>
            <div className="col-span-3 grid grid-cols-3 gap-4">
              <button
                type="button"
                onClick={() => handlePersonaChange("player")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    persona === "player" ? "#ccc" : "transparent",
                }}
              >
                Player
              </button>
              <button
                type="button"
                onClick={() => handlePersonaChange("coach")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: persona === "coach" ? "#ccc" : "transparent",
                }}
              >
                Coach
              </button>
              <button
                type="button"
                onClick={() => handlePersonaChange("other")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: persona === "other" ? "#ccc" : "transparent",
                }}
              >
                Other
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <label className="flex items-center">Player Profile</label>
            <div className="col-span-3 grid grid-cols-4 gap-4">
              <button
                type="button"
                onClick={() => handleisAllRounderChange("")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: isAllRounder ? "#ccc" : "transparent",
                }}
              >
                All-rounder
              </button>
              <button
                type="button"
                onClick={() => handlePlayerProfileChange("Batsman")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    playerProfile === "Batsman" ? "#ccc" : "transparent",
                }}
              >
                Batsman
              </button>
              <button
                type="button"
                onClick={() => handlePlayerProfileChange("Bowler")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    playerProfile === "Bowler" ? "#ccc" : "transparent",
                }}
              >
                Bowler
              </button>
              <button
                type="button"
                onClick={() => handleIsWicketkeeperChange()}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform truncate"
                style={{
                  backgroundColor: isWicketKeeper ? "#ccc" : "transparent",
                }}
              >
                Wicketkeeper
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <label className="flex items-center">Batting</label>
            <div className="col-span-3 grid grid-cols-3 gap-4">
              <button
                type="button"
                onClick={() => handleBattingChange("Right Hand")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    batting === "Right Hand" ? "#ccc" : "transparent",
                }}
              >
                Right Hand
              </button>
              <button
                type="button"
                onClick={() => handleBattingChange("Left Hand")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    batting === "Left Hand" ? "#ccc" : "transparent",
                }}
              >
                Left Hand
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <label className="flex items-center">Bowling</label>
            <div className="col-span-3 grid grid-cols-3 gap-4">
              <button
                type="button"
                onClick={() => handleBowlingChange("Fast")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor: bowling === "Fast" ? "#ccc" : "transparent",
                }}
              >
                Fast
              </button>
              <button
                type="button"
                onClick={() => handleBowlingChange("Off Spin")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    bowling === "Off Spin" ? "#ccc" : "transparent",
                }}
              >
                Off Spin
              </button>
              <button
                type="button"
                onClick={() => handleBowlingChange("Leg Spin")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    bowling === "Leg Spin" ? "#ccc" : "transparent",
                }}
              >
                Leg Spin
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <label className="flex items-center">Fielding</label>
            <div className="col-span-3 grid grid-cols-3 gap-4">
              <button
                type="button"
                onClick={() => handleFieldingChange("Right Hand")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    fielding === "Right Hand" ? "#ccc" : "transparent",
                }}
              >
                Right Hand
              </button>
              <button
                type="button"
                onClick={() => handleFieldingChange("Left Hand")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
                style={{
                  backgroundColor:
                    fielding === "Left Hand" ? "#ccc" : "transparent",
                }}
              >
                Left Hand
              </button>
              <button
                type="button"
                onClick={() => handleFieldingChange("keeping")}
                className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform truncate"
                style={{
                  backgroundColor:
                    fielding === "keeping" ? "#ccc" : "transparent",
                }}
              >
                Wicketkeeper
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <label className="flex items-center" htmlFor="medical condition">
              Medical Condition
            </label>
            <input
              type="text"
              id="medical condition"
              value={medicalCondition}
              onChange={handleMedicalCondtion}
              // required
              className="col-span-3 p-2 rounded"
              placeholder="Medical Condition"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default forwardRef(PlayerForm);
