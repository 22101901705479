import {
  AppUsersPerMonth,
  AppUsersPerYear,
  LearningCenterLikesYTD,
  MatchCenterLikesYTD,
  LearningCenterRatingsYTD,
  LearningCenterViewsYTD,
  MatchCenterRatingsYTD,
  MatchCenterViewsYTD,
  LikeVideoRequestsYTD,
  InstitutionPostsYTD,
  TasksYTD,
  TasksAssignorYTD,
  TasksAssigneeYTD,
  VideoAnnotationsYTD,
  MatchVideosTaggedYTD,
  PracticeVideosYTD,
  ResponseFromCoachesYTD,
} from "./Helper";

const GraphObjectsList = [
  {
    category: "Users Added",
    subcategory: "Year",
    title: "Users Added per Year",
    yAxisTitle: "Number of Users Added",
    xAxisTitle: "Year",
    dataSets: [{ x: [1, 2, 3, 4, 5], y: [1, 2, 4, 8, 16], color: "red", name: "Data 1" }],
    xKeys: ["years"],
    yKeys: ["userCounts"],
    queryFunction: AppUsersPerYear,
    plotBool: true,
    tabBool: false,
    tabFunction: AppUsersPerYear,
    tabText: "",
    tabData: "",
    tabXKey: "",
    tabYKey: "",
  },
  {
    category: "Users Added",
    subcategory: "Month",
    title: "Users Added per Month",
    yAxisTitle: "Number of Users Added",
    xAxisTitle: "Month",
    dataSets: [{ x: [1, 2, 3, 4, 5], y: [6, 8, 3, 9, 5], color: "red", name: "Data 1" }],
    xKeys: ["monthYear"],
    yKeys: ["userCounts"],
    queryFunction: AppUsersPerMonth,
    plotBool: true,
    tabBool: false,
    tabFunction: AppUsersPerMonth,
    tabText: "",
    tabData: "",
    tabXKey: "",
    tabYKey: "",
  },
  {
    category: "Activities",
    subcategory: "LC Likes",
    title: "Views",
    yAxisTitle: "Number of Views",
    xAxisTitle: "Time",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: ["months"],
    yKeys: ["userCounts"],
    queryFunction: "",
    plotBool: false,
    tabBool: true,
    tabFunction: LearningCenterLikesYTD,
    tabText: "LC Likes",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "LC Ratings",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: LearningCenterRatingsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: LearningCenterRatingsYTD,
    tabText: "LC Ratings",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "LC Views",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: LearningCenterViewsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: LearningCenterViewsYTD,
    tabText: "LC Views",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "MC Likes",
    title: "Views",
    yAxisTitle: "Number of Views",
    xAxisTitle: "Time",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: ["months"],
    yKeys: ["userCounts"],
    queryFunction: "",
    plotBool: false,
    tabBool: true,
    tabFunction: MatchCenterLikesYTD,
    tabText: "MC Likes",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "MC Ratings",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: MatchCenterRatingsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: MatchCenterRatingsYTD,
    tabText: "MC Ratings",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "MC Views",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: MatchCenterViewsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: MatchCenterViewsYTD,
    tabText: "MC Views",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Like Video Requests",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: LikeVideoRequestsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: LikeVideoRequestsYTD,
    tabText: "Like Video Requests",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Institution Posts",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: InstitutionPostsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: InstitutionPostsYTD,
    tabText: "Institution Posts",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Tasks",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: TasksYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: TasksYTD,
    tabText: "Tasks",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Tasks-Assignor",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: TasksAssignorYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: TasksAssignorYTD,
    tabText: "Tasks-Assignor",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Tasks-Assignee",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: TasksAssigneeYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: TasksAssigneeYTD,
    tabText: "Tasks-Assignee",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Video Annotations",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: VideoAnnotationsYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: VideoAnnotationsYTD,
    tabText: "Video Annotations",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Match Videos Tagged",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: MatchVideosTaggedYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: MatchVideosTaggedYTD,
    tabText: "Match Videos Tagged",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Practice Videos",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: PracticeVideosYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: PracticeVideosYTD,
    tabText: "Practice Videos",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
  {
    category: "Activities",
    subcategory: "Response from Coaches",
    title: "Views",
    yAxisTitle: "",
    xAxisTitle: "",
    dataSets: [{ x: [], y: [], color: "red", name: "Data 1" }],
    xKeys: [""],
    yKeys: [""],
    queryFunction: ResponseFromCoachesYTD,
    plotBool: false,
    tabBool: true,
    tabFunction: ResponseFromCoachesYTD,
    tabText: "Response from Coaches",
    tabData: "",
    tabXKey: "year",
    tabYKey: "userCount",
  },
];

export default GraphObjectsList;
