import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import "../App.css"
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'
import { useState } from 'react'
const Holder = () => {
    const [headerTitle, setHeaderTitle] = useState("");
    let isMatchAnalysisPage = false;
    if (window.location.pathname == '/MatchCenter/MatchAnalysisInput') {
        isMatchAnalysisPage = true;
    }
    const [hide, setHide] = useState(isMatchAnalysisPage);

    useEffect(() => {

    }, [hide])
    return (
        <div>
            <div className="h-screen flex bg-gray-100">
                <div className={`${"bg-gray-600"}${hide ? "w-24" : "w-1/6"}`}>
                    <Sidebar hide={hide} setHide={setHide} />
                </div>
                <div className="flex-1 flex overflow-hidden">
                    <div className="flex-1 overflow-y-scroll">
                        {/* <header className="h-14">
                            <Header title={headerTitle} />
                        </header> */}
                        <div className="">
                            <Outlet context={[headerTitle, setHeaderTitle]} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Holder