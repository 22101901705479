import React from "react";

const FeeHistoryHeader = () => {
  return (
    <div className="grid grid-cols-12 border-black border-l border-b sticky top-0 z-10 bg-slate-300 ">
      <div className="col-span-1 flex border-r border-black justify-center">
        Sr. No.
      </div>
      <div className="col-span-3 flex border-r border-black justify-center">
        Subscription
      </div>
      <div className="flex items-center justify-center border-r border-black text-center">
        Fee(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Discount(₹)
      </div>
      <div className="col-span-2 flex items-center justify-center border-r border-black text-center">
        Discount Reason
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Payment Date
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Valid From
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Valid Till
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Edit
      </div>
    </div>
  );
};

export default FeeHistoryHeader;
