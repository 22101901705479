import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { GetAllTournaments } from "../Tournament/Helper";
import { GetAllMatchesByTournament, GetTeamsByMatchID, StartAnalysis, GetStartAnalysisDetail, CheckUmpireOTP } from "./Helper";
import { useForm } from "react-hook-form";
import validateInput from './MatchValidation'
import axios from 'axios';
import { GetAllTeamsByInstituteID } from "../Match/Helper";
import firebase from '../../../firebase';
import Swal from "sweetalert2";
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
const MatchAna = () => {
  const startStopCamCollection = firebase.firestore().collection("StartStopCamCollections");
  const startStopLiveCollection = firebase.firestore().collection("StartStopLiveCollections");
  const [cameraConfig1, setCameraConfig1] = useState(false);
  const [cameraConfig2, setCameraConfig2] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const navigate = useNavigate();
  const [institutes, setInstitutes] = useState([]);
  const [umpires, setUmpires] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [cameraError, setCameraError] = useState({});
  const [checkingCameraFirstAvailable, setCheckingCameraFirstAvailable] = useState(false);
  const [cameraFirstAvailable, setCameraFirstAvailable] = useState([]);
  const [checkingCameraSecondAvailable, setCheckingCameraSecondAvailable] = useState(false);
  const [cameraSecondAvailable, setCameraSecondAvailable] = useState([]);
  const db = firebase.firestore();
  const [umpire1Popup, setUmpire1Popup] = useState(false)
  const [umpire1otp, setumpire1otp] = useState("")
  const [umpire1id, setumpire1id] = useState();
  const [documentName1, setDocumentName1] = useState("")
  const OpenUmpire1Popup = (document_name) => {
    setUmpire1Popup(true)
    setDocumentName1(document_name)
  }
  const [umpire2Popup, setUmpire2Popup] = useState(false)
  const [umpire2otp, setumpire2otp] = useState("")
  const [umpire2id, setumpire2id] = useState();
  const [documentName2, setDocumentName2] = useState("")
  const OpenUmpire2Popup = (document_name) => {
    setUmpire2Popup(true)
    setDocumentName2(document_name)
  }
  const [firebaseUserId, setFirebaseUserId] = useState("d3uScF1exPR1Lkfkq3RfD29qUo43");
  const [analysis, setAnalysis] = useState({
    tournament_id: "", match_id: "", bat1_id: "", ball1_id: "", camera_1: "", camera_2: ""
  });
  useEffect(() => {
    setHeaderTitle("New Match Analysis");
    GetAllTournaments()
      .then((data) => {
        if (data?.status) {
          setTournaments(data?.tournaments);
        }
      })
      .catch((err) => {
      });
  }, []);
  useEffect(() => {
    if (analysis.tournament_id) {
      GetAllMatchesByTournament(analysis.tournament_id)
        .then((data) => {
          if (data?.status) {
            setMatches(data?.match);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [analysis.tournament_id]);
  useEffect(() => {
    if (analysis.match_id) {
      GetTeamsByMatchID(analysis.match_id)
        .then((data) => {
          if (data?.status) {
            setTeams(data?.teams);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [analysis.match_id]);
  //get users for Umpire
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/camera-app-user`)
      .then(response => {
        setUmpires(response.data.studentData)
      });
  }, [])
  // Function to fetch data from a document
  const getDocument = async (collectionName, documentId) => {
    const documentRef = db.collection(collectionName).doc(documentId);
    const documentSnapshot = await documentRef.get();
    if (documentSnapshot.exists) {
      return documentSnapshot.data();
    } else {
      return null;
    }
  };
  const getAllDocuments = async (collectionName) => {
    const documents = await db.collection(collectionName).get();
    return documents.docs.map((doc) => doc.id);
  };
  const handleChange = async (e, name) => {
    var value_save = false;
    if (name == "camera_1") {
      setCheckingCameraFirstAvailable(true);
      setCameraConfig2(true);
      let cam1_id = e.target.value;
      setumpire1id(cam1_id)
      const documents = await getAllDocuments('StartStopCamCollections');
      setCameraFirstAvailable([]);
      cameraFirstAvailable.length = 0;
      for (let i = 0; i < documents.length; i++) {
        const documentData = await getDocument('StartStopCamCollections', documents[i]);
        if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
          if ((documentData.Camera1.UserID == cam1_id) || (documentData.Camera2.UserID == cam1_id)) {
            let document_detail = { document_name: documents[i] };
            cameraFirstAvailable.push(document_detail);
          }
        }
      }
      setCheckingCameraFirstAvailable(false);
      setCameraConfig2(false);
      if (cameraFirstAvailable.length > 0) {
        setCameraFirstAvailable(cameraFirstAvailable);
        e.target.value = "";
      }
      else {
        setCameraFirstAvailable([]);
        cameraFirstAvailable.length = 0;
        e.target.value = cam1_id;
      }
      if (analysis.camera_2 && analysis.camera_2 == e.target.value) {
        setCameraError({ ...cameraError, camera_1: "This value already selected in camera 2" })
        setTimeout(() => {
          setCameraError({ ...cameraError, camera_1: "" })
        }, 3000)
      } else {
        value_save = true
      }
    } else if (name == "camera_2") {
      setCheckingCameraSecondAvailable(true);
      setCameraConfig1(true);
      let cam2_id = e.target.value;
      setumpire2id(cam2_id)
      const documents = await getAllDocuments('StartStopCamCollections');
      setCameraSecondAvailable([]);
      cameraSecondAvailable.length = 0;
      for (let i = 0; i < documents.length; i++) {
        const documentData = await getDocument('StartStopCamCollections', documents[i]);
        if ((documentData != null) && (typeof documentData.Camera1 != "undefined") && (typeof documentData.Camera2 != "undefined")) {
          if ((documentData.Camera1.UserID == cam2_id) || (documentData.Camera2.UserID == cam2_id)) {
            let document_detail = { document_name: documents[i] };
            cameraSecondAvailable.push(document_detail);
          }
        }
      }
      setCheckingCameraSecondAvailable(false);
      setCameraConfig1(false);
      if (cameraSecondAvailable.length > 0) {
        setCameraSecondAvailable(cameraSecondAvailable);
        e.target.value = "";
      }
      else {
        setCameraSecondAvailable([]);
        cameraSecondAvailable.length = 0;
        e.target.value = cam2_id;
      }
      if (analysis.camera_1 && analysis.camera_1 == e.target.value) {
        setCameraError({ ...cameraError, camera_2: "This value already selected in camera 1" })
        setTimeout(() => {
          setCameraError({ ...cameraError, camera_2: "" })
        }, 3000)
      } else {
        value_save = true
      }
    } else {
      value_save = true
    }
    if (value_save == true) {
      if (name == "match_id") {
        getInstuteIdByMatchId(name, e.target.value)
      } else {
        setAnalysis({
          ...analysis,
          [name]: e.target.value,
        });
      }
    }
  };
  const getInstuteIdByMatchId = (name, match_id) => {
    if (matches && matches.length > 0) {
      var instId = matches.find(res => res.id == match_id)
      if (instId) {
        GetAllTeamsByInstituteID(instId.institute1Id).then(response => {
          setInstitutes(response.teams[0])
          setAnalysis({
            ...analysis,
            [name]: match_id,
            ["instituteId"]: instId.institute1Id,
            ["date"]: instId.date
          });
        })
      }
    }
  }
  const handleSubmitStartAnalysis = () => {
    saveMatchDetail().then(saveInfo => {
      const match = matches.find((el) => el.id === parseInt(analysis.match_id));
      const Camera1_User = umpires.find(u => parseInt(u.id) === parseInt(analysis.camera_1));
      const Camera2_User = umpires.find(u => parseInt(u.id) === parseInt(analysis.camera_2));
      const obj = {
        ...analysis,
        bat2_id: analysis.ball1_id,
        ball2_id: analysis.bat1_id,
        overs: match.overs
      };
      StartAnalysis(obj).then((data) => {
        if (data?.status && data?.created_analysis) {
          navigate("/MatchCenter/MatchAnalysisInput", {
            state: {
              ...data?.created_analysis,
              doc: saveInfo,
              Camera1User: { id: Camera1_User.id, name: Camera1_User.full_name },
              Camera2User: { id: Camera2_User.id, name: Camera2_User.full_name }
            }
          });
        }
      }).catch((e) => console.log(e));
    });
  };
  const isValid = () => {
    const { cameraError, isValid } = validateInput(analysis);
    if (!isValid) {
      setCameraError(cameraError);
    }
    return isValid;
  };
  const saveMatchDetail = () => {
    if (!isValid()) {
      return;
    }
    return new Promise((resolve, reject) => {
      const Camera_1_User_ID = umpires.find(u => u.id == analysis.camera_1).id;
      const Camera_2_User_ID = umpires.find(u => u.id == analysis.camera_2).id;
      startStopCamCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const _doc = { ...doc.data() };
          if ((typeof doc.get('Camera1') != "undefined") && (doc.get('Camera1').UserID === Camera_1_User_ID)) {
            _doc.Camera1.UserID = "0"
          }
          if ((typeof doc.get('Camera1') != "undefined") && (doc.get('Camera1').UserID === Camera_2_User_ID)) {
            _doc.Camera1.UserID = "0"
          }
          if ((typeof doc.get('Camera2') != "undefined") && (doc.get('Camera2').UserID === Camera_1_User_ID)) {
            _doc.Camera2.UserID = "0"
          }
          if ((typeof doc.get('Camera2') != "undefined") && (doc.get('Camera2').UserID === Camera_2_User_ID)) {
            _doc.Camera2.UserID = "0"
          }
          if (JSON.stringify(doc.data()) !== JSON.stringify(_doc)) {
            doc.ref.set(_doc, { merge: true })
          }
        });
        GetStartAnalysisDetail({
          tournament_id: analysis.tournament_id,
          match_id: analysis.match_id
        })
          .then(async (analysis_data) => {
            let batting_team_name = '';
            if (analysis_data.AnalysisMasterDetail.length > 0) {
              if (analysis_data.AnalysisMasterDetail[0].current_inning == 1) {
                batting_team_name = analysis_data.AnalysisMasterDetail[0].team_bat1;
              }
              else {
                batting_team_name = analysis_data.AnalysisMasterDetail[0].team_bat2;
              }
            }
            else {
              batting_team_name = batting_team_name;
              for (let i = 0; i < teams.length; i++) {
                if (teams[i].id == analysis.bat1_id) {
                  batting_team_name = teams[i].name;
                }
              }
            }
            const docKey = `${tournaments.find(t => t.id == analysis.tournament_id).name}_${matches.find(m => m.id == analysis.match_id).name}_${batting_team_name}_${analysis.date}`;
            const doc = {
              Camera1: {
                CameraStatus: false,
                UserID: Camera_1_User_ID.toString()
              },
              Camera2: {
                CameraStatus: false,
                UserID: Camera_2_User_ID.toString()
              }
            }
            startStopCamCollection.doc(docKey).set(doc).then(() => {
              startStopLiveCollection.doc(docKey).set(doc).then(() => {
              });
              Swal.mixin({ position: 'bottom' }).fire({ text: `${docKey} added sucessfully.` });
              resolve({ [docKey]: doc });
            }).catch(err => {
              Swal.mixin({ position: 'bottom' }).fire({ text: `Error while adding document.` });
              reject(err);
            });
          });
      });
    });
  }
  const previousRecords = () => {
    navigate("/PrevoiusMatchData")
  }
  const ReleaseUmpire1 = (document_name) => {
    CheckUmpireOTP({ umpireid: umpire1id, umpire_otp: umpire1otp }).then(async (data) => {
      if (data.msg == 'match found') {
        document_name = documentName1;
        const docRef = db.collection('StartStopCamCollections').doc(document_name);
        const LivedocRef = db.collection('StartStopLiveCollections').doc(document_name);
        docRef.delete()
          .then(() => {
            const updatedData = cameraFirstAvailable.filter(item => item.document_name !== document_name);
            setCameraFirstAvailable(updatedData);
            setUmpire1Popup(false)
            Swal.fire("Deleted", "Selected match record has been deleted successfully");
          })
          .catch((error) => {
          });
        LivedocRef.delete()
          .then(() => {
          })
          .catch((error) => {
          });
      } else {
        Swal.fire({
          icon: 'error', title: '👎', text: 'Please enter valid match code'
        })
      }
    }).catch((err) => {
      Swal.fire({
        icon: 'error', title: '⛔', text: err
      })
    })
  }
  const ReleaseUmpire2 = (document_name) => {
    CheckUmpireOTP({ umpireid: umpire2id, umpire_otp: umpire2otp }).then(async (data) => {
      if (data.msg == 'match found') {
        document_name = documentName2;
        const docRef = db.collection('StartStopCamCollections').doc(document_name);
        const LivedocRef = db.collection('StartStopLiveCollections').doc(document_name);
        docRef.delete()
          .then(() => {
            const updatedData = cameraSecondAvailable.filter(item => item.document_name !== document_name);
            setCameraSecondAvailable(updatedData);
            setUmpire2Popup(false)
            Swal.fire("Deleted", "Selected match record has been deleted successfully"
            );
          })
          .catch((error) => {
          });
        LivedocRef.delete()
          .then(() => {
          })
          .catch((error) => {
          });
      } else {
        Swal.fire({
          icon: 'error', title: '👎', text: 'Please enter valid match code'
        })
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', title: '⛔', text: err })
    })
  }
  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }}>
          New Analysis
        </h1>
      </div>
      <div className=" h-screen">
        <div className="grid grid-cols-8">
          <div></div>
          <div className="pt-24 col-span-6">
            <form onSubmit={handleSubmit(handleSubmitStartAnalysis)}>
              <div className="p-4 shadow-2xl bg-white p-10">
                <h1 className="uppercase" style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "15px", }}>
                  Match Details
                </h1>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label htmlFor="sdate" className="form-label inline-block mb-2 text-gray-700">
                      Tournament<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      {...register("tournament", {
                        required: true,
                        validate: () =>
                          getValues("tournament") != "Select Tournament",
                      })}
                      id="tournament"
                      defaultValue="Select Tournament"
                      onChange={(e) => handleChange(e, "tournament_id")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                      <option>Select Tournament</option>
                      {tournaments.map((tournament, index) => (
                        <option value={tournament.id} key={index}>
                          {tournament.name}
                        </option>
                      ))}
                    </select>
                    {errors.tournament &&
                      (errors.tournament.type === "required" ||
                        errors.tournament.type === "validate") ? (
                      <p className="errorMsg">Tournament is required.</p>
                    ) :
                      cameraError && cameraError.tournament_id &&
                      <p className="errorMsg">{cameraError.tournament_id}</p>
                    }
                  </div>
                  <div>
                    <label
                      htmlFor="match"
                      className="form-label inline-block mb-2 text-gray-700">
                      Match Name<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      id="match"
                      {...register("match_id", {
                        required: true,
                        validate: () => getValues("match_id") != "Select Match Name",
                      })}
                      onChange={(e) => handleChange(e, "match_id")}
                      defaultValue="Select Match Name"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                      <option>Select Match Name</option>
                      {matches.map((match, index) => (
                        <option value={match.id} key={index}>
                          {match.name}
                        </option>
                      ))}
                    </select>
                    {errors.match_id &&
                      (errors.match_id.type === "required" ||
                        errors.match_id.type === "validate") ? (
                      <p className="errorMsg">Match Name is required.</p>
                    ) :
                      cameraError && cameraError.match_id &&
                      <p className="errorMsg">{cameraError.match_id}</p>
                    }
                  </div>
                </div> <br />
                <h1 className="uppercase" style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "15px", }} >
                  Camera Config
                </h1>
                <div className="grid grid-cols-2 gap-3">
                  {/* Camera 1 */}
                  <div>
                    <label
                      htmlFor="sdate"
                      className="form-label inline-block mb-2 text-gray-700">
                      Camera 1<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      id="camera_1"
                      {...register("camera_1", {
                        required: "Camera 1 is required", // Provide a custom error message
                        validate: (value) => value !== "Select Camera" || "Camera 1 is required.",
                      })}
                      onChange={(e) => handleChange(e, "camera_1")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      value={analysis && analysis.camera_1 || ""}
                      disabled={cameraConfig1}>
                      <option>Select Camera </option>
                      {umpires?.map((u, index) => (
                        <option value={u.id} key={index}>
                          {u.full_name}
                        </option>
                      ))}
                    </select>
                    {errors.camera_1 && <p className="errorMsg">{errors.camera_1.message}</p>}
                    {cameraError && cameraError.camera_2 &&
                      <p className="errorMsg">{cameraError.camera_2}</p>
                    }
                    {checkingCameraFirstAvailable &&
                      <p style={{ color: '#006400' }}>Please Wait, Checking in Firebase...</p>
                    }
                    <table style={{ border: '1px solid #000000' }}>
                      <tbody>
                        {cameraFirstAvailable?.map((u, index) => (
                          <>
                            <tr>
                              <td style={{ border: '1px solid #9e9e9e' }}>{index + 1}.</td>
                              <td style={{ border: '1px solid #9e9e9e' }}>{u.document_name}</td>
                              <td style={{ border: '1px solid #9e9e9e' }} onClick={() => OpenUmpire1Popup(u.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                            </tr>
                          </>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* Camera 2 */}
                  <div>
                    <label
                      htmlFor="sdate" className="form-label inline-block mb-2 text-gray-700">
                      Camera 2<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      id="camera_2"
                      {...register("camera_2", {
                        required: "Camera 2 is required", // Provide a custom error message
                        validate: (value) => value !== "Select Camera" || "Camera 2 is required.",
                      })}
                      onChange={(e) => handleChange(e, "camera_2")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      value={analysis && analysis.camera_2 || ""}
                      disabled={cameraConfig2}>
                      <option>Select Camera </option>
                      {umpires?.map((u, index) => (
                        <option value={u.id} key={index}>
                          {u.full_name}
                        </option>
                      ))}
                    </select>
                    {errors.camera_2 && <p className="errorMsg">{errors.camera_2.message}</p>}
                    {checkingCameraSecondAvailable &&
                      <p style={{ color: '#006400' }}>Please Wait, Checking in Firebase...</p>
                    }
                    <table style={{ border: '1px solid #000000' }}>
                      <tbody>
                        {cameraSecondAvailable?.map((u, index) => (
                          <>
                            <tr>
                              <td style={{ border: '1px solid #9e9e9e' }}>{index + 1}.</td>
                              <td style={{ border: '1px solid #9e9e9e' }}>{u.document_name}</td>
                              <td style={{ border: '1px solid #9e9e9e' }} onClick={() => OpenUmpire2Popup(u.document_name)}><CancelIcon style={{ color: '#FF0000', cursor: 'pointer' }} ></CancelIcon></td>
                            </tr>
                          </>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div><br />
                <h1 className="uppercase" style={{
                  color: "#0DF6E3", fontWeight: "bold", fontSize: "15px",
                }} >
                  First Inning
                </h1>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label
                      htmlFor="sdate"
                      className="form-label inline-block mb-2 text-gray-700" >
                      Batting Team<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      {...register("bat1", {
                        required: true,
                        validate: () =>
                          getValues("bat1") != "Select Batting Team",
                      })}
                      id="tournament"
                      onChange={(e) => handleChange(e, "bat1_id")}
                      defaultValue="Select Batting Team"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    >
                      <option>Select Batting Team</option>
                      {teams.map((team, index) => (
                        <option value={team.id} key={index}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                    {errors.bat1 &&
                      (errors.bat1.type === "required" ||
                        errors.bat1.type === "validate") && (
                        <p className="errorMsg">Batting Team is required.</p>
                      )}
                  </div>
                  <div>
                    <label
                      htmlFor="edate"
                      className="form-label inline-block mb-2 text-gray-700"
                    >
                      Bowling Team<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <select
                      {...register("ball", {
                        required: true,
                        validate: () =>
                          getValues("ball") != "Select Bowling Team",
                      })}
                      id="tournament"
                      onChange={(e) => handleChange(e, "ball1_id")}
                      defaultValue="Select Bowling Team"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    >
                      <option>Select Bowling Team</option>
                      {teams.map((team, index) => (
                        <option value={team.id} key={index}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                    {errors.ball &&
                      (errors.ball.type === "required" ||
                        errors.ball.type === "validate") && (
                        <p className="errorMsg">Bowling Team is required.</p>
                      )}
                  </div>
                </div>
                <br />
                <div className="text-right pt-5 form-control">
                  <Button type="submit" size="medium" style={{ backgroundColor: "#0DF6E3", color: "#2B303B", marginLeft: 20 }} variant="contained" onClick={previousRecords}>
                    Previous Record
                  </Button>
                  <Button type="submit" size="medium" style={{ backgroundColor: "#0DF6E3", color: "#2B303B", marginLeft: 20 }} variant="contained">
                    Start Analysis
                  </Button>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {umpire1Popup ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    Umpire 1
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setUmpire1Popup(false)}
                  ><ClearIcon />
                  </button>
                </div>
                <div className="relative p-2 flex-auto">
                  <div className="m-2 grid gap-5">
                    <input
                      type="number"
                      id="overs"
                      placeholder="Match Code"
                      onChange={(e) => { setumpire1otp(e.target.value) }}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button"
                    onClick={() => setUmpire1Popup(false)}>
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button" onClick={ReleaseUmpire1}>
                    Verify Match Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {umpire2Popup ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold p-3 ml-auto bg-transparent border-0 text-black  float-right leading-none font-semibold outline-none focus:outline-none">
                    Umpire 2
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setUmpire2Popup(false)}
                  ><ClearIcon />
                  </button>
                </div>
                <div className="relative p-2 flex-auto">
                  <div className="m-2 grid gap-5">
                    <input
                      type="number"
                      id="overs"
                      placeholder="Match Code"
                      onChange={(e) => { setumpire2otp(e.target.value) }}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button"
                    onClick={() => setUmpire2Popup(false)}>
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 modalButton"
                    type="button" onClick={ReleaseUmpire2}>
                    Verify Match Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
export default MatchAna;
