import React, { useEffect, useState } from "react";
import { fetchPlayerActiveSubscriptionList } from "../../../../Helper";

const ActivePlansList = ({
  selectedPlayerIndex,
  loggedInUserInstitutionID,
  addedNewSubscriptionReload,
  isActiveSubscriptionListLoading,
  setIsActiveSubscriptionListLoading,
  isActiveSubscriptionList,
  setIsActiveSubscriptionList,
}) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPlayerActiveSubscriptionList(
          selectedPlayerIndex,
          loggedInUserInstitutionID
        );
        console.log("data:", data);
        console.log(typeof data);
        setIsActiveSubscriptionList(data);
        // if (Array.isArray(data)) {
        //   setIsActiveSubscriptionList(data);
        // } else {
        //   setIsActiveSubscriptionList(data.message);
        // }
        setIsActiveSubscriptionListLoading(false);
      } catch (error) {
        console.log("Error in fetchPlayerFeeDetails:", error.message);
      }
    };
    if (selectedPlayerIndex != "" && loggedInUserInstitutionID != "") {
      setIsActiveSubscriptionListLoading(true);
      fetchData();
      console.log("in fetchdata");
    }
  }, [selectedPlayerIndex, addedNewSubscriptionReload]);

  // Define a function to calculate the total fee amount
  function calculateNetFee(activeSubscription) {
    const startDate = new Date(activeSubscription.active_plan_valid_from);
    const endDate = new Date();
    const monthsDifference =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

    // Add a conditional check for monthsDifference
    const calculatedMonthsDifference =
      monthsDifference == 0 ? 1 : monthsDifference;

    // Calculate the net fee amount
    const netFeeAmount =
      ((activeSubscription.fee - activeSubscription.total_discount) *
        calculatedMonthsDifference) /
        12 -
      activeSubscription.total_fees_paid;

    // Add a conditional check to ensure the result is not negative
    return Math.max(0, netFeeAmount).toFixed(0);
  }

  return (
    <div className=" max-h-24 overflow-auto">
      {isActiveSubscriptionListLoading ? (
        selectedPlayerIndex == "" ? null : (
          <div>Subscriptions list is loading...</div>
        )
      ) : isActiveSubscriptionList.length > 0 ? (
        isActiveSubscriptionList.map((activeSubscription, index) => {
          return (
            <div
              className="grid grid-cols-11 border-black border-b"
              key={index}
            >
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {index + 1}
              </div>
              <div className="col-span-2 flex pl-2 border-r border-black text-center">
                {activeSubscription.plan_name}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {new Date(activeSubscription.active_plan_valid_from)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                    year: "numeric",
                  })
                  .replace(/\b0+/g, "")}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {new Date(activeSubscription.active_plan_valid_till)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                    year: "numeric",
                  })
                  .replace(/\b0+/g, "")}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {activeSubscription.fee}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {activeSubscription.total_discount}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {activeSubscription.total_fees_paid
                  ? activeSubscription.total_fees_paid
                  : 0}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {activeSubscription.fee -
                  activeSubscription.total_discount -
                  activeSubscription.total_fees_paid}
              </div>
              <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
                {/* {(activeSubscription.fee - activeSubscription.total_discount) *((new Date() - new Date(activeSubscription.active_plan_valid_from)))/
                  12 -
                  activeSubscription.total_fees_paid} */}
                {calculateNetFee(activeSubscription)}
              </div>
              <div
                // className="col-span-1 flex items-center justify-center border-black text-center"
                className={`col-span-1 flex items-center justify-center ${
                  activeSubscription.fees_paid_till &&
                  new Date(activeSubscription.fees_paid_till) < new Date()
                    ? "bg-red-500"
                    : ""
                } `}
              >
                {activeSubscription.fees_paid_till
                  ? new Date(activeSubscription.fees_paid_till)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                        year: "numeric",
                      })
                      .replace(/\b0+/g, "")
                  : ""}
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center">No subscription data found.</div>
      )}
    </div>
  );
};

export default ActivePlansList;
