import React from "react";
import { useState, useEffect } from "react";
import { fetchInstitutionPlayers } from "../../../Helper";

const PlayerTable = ({
  selectedPlayerIndex,
  setSelectedPlayerIndex,
  searchInput,
  selectedFilter,
  setSelectedPlayerName,
  loggedInUserInstitutionID,
  reloadPlayerList,
}) => {
  const [playerObjectArray, setPlayerObjectArray] = useState([]);
  const [isInstituteIDLoading, setIsInstituteIDIsLoading] = useState(true); // Add the loading state
  const [searchedPlayerObjectArray, setSearchedPlayerObjectArray] = useState(
    []
  ); // Step 1: State for filtered players

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutionID = sessionStorage.getItem("loggedUserInstitutionID");
        if (!institutionID) {
          console.log("Institution ID not available in sessionStorage");
        }

        const data = await fetchInstitutionPlayers(institutionID);
        setPlayerObjectArray(data);
        setIsInstituteIDIsLoading(false);
      } catch (error) {
        console.log("Error in fetchInstitutionPlayers:", error.message);
      }
      // finally {
      //   setIsInstituteIDIsLoading(false); // Set loading to false regardless of success or error
      // }
    };
    fetchData();
  }, [loggedInUserInstitutionID, reloadPlayerList]);

  const selectPlayer = (index) => {
    // console.log("Selected this player:", index);
    setSelectedPlayerIndex(index);
  };

  useEffect(() => {
    setSelectedPlayerName(
      playerObjectArray.findIndex(
        (playerObject) => playerObject.id === selectedPlayerIndex
      ) !== -1
        ? playerObjectArray.find(
            (playerObject) => playerObject.id === selectedPlayerIndex
          )?.full_name || ""
        : ""
    );
  }, [selectedPlayerIndex]);

  // Function to calculate age till latest sept 1 based on the date of birth
  const calculateAgeTillLatestSeptember1 = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    // Set the target date to the latest September 1st
    const targetDate = new Date(currentDate.getFullYear(), 8, 1); // 8 represents September (months are 0-based)

    let age = targetDate.getFullYear() - birthDate.getFullYear();

    // Compare the birthdate with the target date
    const monthDiff = targetDate.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && targetDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (!Array.isArray(playerObjectArray)) {
      // Handle the case where playerObjectArray is not an array
      console.error("playerObjectArray is not an array");
      return; // Exit early to prevent further processing
    }
    // Filter player objects based on searchInput
    const filteredPlayers = playerObjectArray.filter((playerObject) =>
      playerObject.full_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setSearchedPlayerObjectArray(filteredPlayers);
  }, [searchInput, playerObjectArray]);

  // Apply filtering based on the selectedFilter and age of players
  const filteredPlayerObjectArray = searchedPlayerObjectArray.filter(
    (playerObject) => {
      if (selectedFilter === "All") {
        return true; // Display all players when 'All' is selected
      } else if (selectedFilter === "Fees Due") {
        // Filter players whose valid_till is before today's date
        const currentDate = new Date();
        const validTillDate = new Date(playerObject.valid_till);
        return validTillDate < currentDate;
      } else if (selectedFilter === "Under-12") {
        const age = calculateAgeTillLatestSeptember1(
          playerObject.date_of_birth
        );
        return age < 12;
      } else if (selectedFilter === "Under-14") {
        const age = calculateAgeTillLatestSeptember1(
          playerObject.date_of_birth
        );
        return age < 14;
      } else if (selectedFilter === "Under-18") {
        const age = calculateAgeTillLatestSeptember1(
          playerObject.date_of_birth
        );
        return age < 18;
      }
      // Add other filter conditions here if needed
      return false;
    }
  );

  // Conditional rendering based on isInstituteIDLoading
  if (isInstituteIDLoading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator.
  }
  return (
    <>
      {filteredPlayerObjectArray.map((playerObject, index) => {
        // console.log(playerObject);
        return (
          <div
            key={index}
            className={`grid grid-cols-9 border-black border-b cursor-pointer ${
              selectedPlayerIndex === playerObject.id ? "bg-neutral-400" : ""
            }`}
            onClick={() => selectPlayer(playerObject.id)}
          >
            <div className="col-span-1 flex justify-center border-r border-black">
              {index + 1}
            </div>
            <div className="col-span-2 flex items-center border-r border-black pl-2">
              {playerObject.full_name}
            </div>
            <div className="col-span-1 flex items-center justify-center border-r border-black">
              {playerObject.gender}
            </div>
            <div className="col-span-1 flex items-center justify-center border-r border-black">
              {playerObject.mobile}
            </div>
            <div className="col-span-1 flex items-center justify-center border-r border-black">
              {playerObject.date_of_birth}
            </div>
            <div className="col-span-1 flex items-center justify-center border-r border-black">
              {playerObject.profile}
            </div>
            <div className="col-span-1 flex items-center justify-center border-r border-black">
              {playerObject.batting != "NA" ? playerObject.batting : ""}
            </div>
            <div className="col-span-1 flex items-center justify-center">
              {playerObject.bowling != "NA" ? playerObject.bowling : ""}
            </div>
            {/* <div
            className={`col-span-1 flex items-center justify-center ${
              playerObject.valid_till &&
              new Date(playerObject.valid_till) < new Date()
                ? "bg-red-500"
                : ""
            } `}
          >
            {playerObject.valid_till
              ? new Date(playerObject.valid_till)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short", // Use "short" for abbreviated month name (e.g., Aug)
                    year: "numeric",
                  })
                  .replace(/\b0+/g, "")
              : ""}
          </div> */}
          </div>
        );
      })}
    </>
  );
};

export default PlayerTable;
