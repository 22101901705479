export const DeepFineLeg = {
  x1: 251,
  y1: 248,
  x2: 251,
  y2: 63,
  x3: 374,
  y3: 123,
};

export const ShortFineLegFirst = {
  x1: 261,
  y1: 235,
  x2: 254,
  y2: 230,
  x3: 286,
  y3: 152,
  x4: 315,
  y4: 177
};

export const ShortFineLegSecond = {
  x1: 261,
  y1: 233,
  x2: 254,
  y2: 225,
  x3: 253,
  y3: 143,
  x4: 293,
  y4: 156
};

export const DeepFineLegFirst = {
  x1: 319,
  y1: 174,
  x2: 290,
  y2: 146,
  x3: 317,
  y3: 59,
  x4: 384,
  y4: 108
};

export const DeepFineLegSecond = {
  x1: 294,
  y1: 151,
  x2: 254,
  y2: 138,
  x3: 254,
  y3: 46,
  x4: 341,
  y4: 71
};

export const DeepSquareLeg = {
  x1: 251,
  y1: 248,
  x2: 374,
  y2: 123,
  x3: 409,
  y3: 247,
};

export const SquareLegFirst = {
  x1: 260,
  y1: 246,
  x2: 261,
  y2: 240,
  x3: 328,
  y3: 210,
  x4: 329,
  y4: 245
};


export const SquareLegSecond = {
  x1: 257,
  y1: 242,
  x2: 318,
  y2: 182,
  x3: 331,
  y3: 222,
  x4: 262,
  y4: 246
};

export const DeepSquareLegFirst = {
  x1: 333,
  y1: 245,
  x2: 334,
  y2: 210,
  x3: 420,
  y3: 186,
  x4: 423,
  y4: 245
};


export const DeepSquareLegSecond = {
  x1: 334,
  y1: 213,
  x2: 323,
  y2: 178,
  x3: 386,
  y3: 114,
  x4: 419,
  y4: 179
};

export const DeepMidWicket = {
  x1: 251,
  y1: 248,
  x2: 409,
  y2: 247,
  x3: 377,
  y3: 373,
};

export const MidWicketFirst = {
  x1: 318,
  y1: 308,
  x2: 261,
  y2: 254,
  x3: 261,
  y3: 248,
  x4: 329,
  y4: 278
};

export const MidWicketSecond = {
  x1: 331,
  y1: 279,
  x2: 261,
  y2: 254,
  x3: 261,
  y3: 250,
  x4: 330,
  y4: 249
};

export const DeepMidWicketFirst = {
  x1: 389,
  y1: 381,
  x2: 323,
  y2: 312,
  x3: 338,
  y3: 281,
  x4: 420,
  y4: 309
};

export const DeepMidWicketSecond = {
  x1: 333,
  y1: 286,
  x2: 335,
  y2: 249,
  x3: 424,
  y3: 249,
  x4: 415,
  y4: 330
};

export const LongOn = {
  x1: 251,
  y1: 248,
  x2: 377,
  y2: 373,
  x3: 252,
  y3: 473,
};

export const MidOnFirst = {
  x1: 253,
  y1: 353,
  x2: 254,
  y2: 265,
  x3: 262,
  y3: 261,
  x4: 296,
  y4: 337
};

export const MidOnSecond = {
  x1: 285,
  y1: 345,
  x2: 253,
  y2: 266,
  x3: 261,
  y3: 259,
  x4: 316,
  y4: 314
};


export const LongOnFirst = {
  x1: 254,
  y1: 452,
  x2: 253,
  y2: 358,
  x3: 297,
  y3: 343,
  x4: 354,
  y4: 415
};

export const LongOnSecond = {
  x1: 287,
  y1: 349,
  x2: 322,
  y2: 325,
  x3: 386,
  y3: 385,
  x4: 317,
  y4: 436
};

export const MidOffFirst = {
  x1: 184,
  y1: 315,
  x2: 244,
  y2: 256,
  x3: 249,
  y3: 262,
  x4: 207,
  y4: 340
};

export const MidOffSecond = {
  x1: 208,
  y1: 341,
  x2: 240,
  y2: 261,
  x3: 249,
  y3: 262,
  x4: 249,
  y4: 352
};

export const LongOff = {
  x1: 251,
  y1: 248,
  x2: 252,
  y2: 473,
  x3: 125,
  y3: 373,
};

export const LongOffFirst = {
  x1: 115,
  y1: 385,
  x2: 180,
  y2: 320,
  x3: 212,
  y3: 348,
  x4: 177,
  y4: 434
};

export const LongOffSecond = {
  x1: 161,
  y1: 427,
  x2: 211,
  y2: 348,
  x3: 249,
  y3: 360,
  x4: 248,
  y4: 451
};

export const DeepCover = {
  x1: 251,
  y1: 248,
  x2: 125,
  y2: 373,
  x3: 91,
  y3: 247,
};

export const DeepCoverFirst = {
  x1: 86,
  y1: 329,
  x2: 78,
  y2: 248,
  x3: 165,
  y3: 249,
  x4: 168,
  y4: 294
};

export const DeepCoverSecond = {
  x1: 81,
  y1: 312,
  x2: 168,
  y2: 289,
  x3: 177,
  y3: 315,
  x4: 110,
  y4: 379
};

export const CoverFirst = {
  x1: 172,
  y1: 248,
  x2: 240,
  y2: 246,
  x3: 241,
  y3: 255,
  x4: 171,
  y4: 281
};

export const CoverSecond = {
  x1: 171,
  y1: 282,
  x2: 239,
  y2: 248,
  x3: 239,
  y3: 256,
  x4: 181,
  y4: 309
};

export const DeepPoint = {
  x1: 251,
  y1: 248,
  x2: 91,
  y2: 247,
  x3: 127,
  y3: 121,
};

export const DeepPointFirst = {
  x1: 81,
  y1: 242,
  x2: 89,
  y2: 162,
  x3: 166,
  y3: 199,
  x4: 162,
  y4: 242
};

export const DeepPointSecond = {
  x1: 87,
  y1: 167,
  x2: 115,
  y2: 115,
  x3: 177,
  y3: 178,
  x4: 165,
  y4: 213
};

export const PointFirst = {
  x1: 170,
  y1: 246,
  x2: 173,
  y2: 209,
  x3: 237,
  y3: 235,
  x4: 241,
  y4: 245
};

export const PointSecond = {
  x1: 170,
  y1: 216,
  x2: 183,
  y2: 182,
  x3: 241,
  y3: 238,
  x4: 240,
  y4: 246
};

export const ThirdManFirst = {
  x1: 119,
  y1: 109,
  x2: 176,
  y2: 65,
  x3: 215,
  y3: 143,
  x4: 181,
  y4: 170
  // x1: 251,
  // y1: 248,
  // x2: 127,
  // y2: 121,
  // x3: 251,
  // y3: 63,
};

export const ThirdManSecond = {
  x1: 164,
  y1: 72,
  x2: 248,
  y2: 46,
  x3: 247,
  y3: 137,
  x4: 205,
  y4: 149
};

export const ShortThirdManFirst = {
  x1: 187,
  y1: 177,
  x2: 220,
  y2: 150,
  x3: 245,
  y3: 219,
  x4: 237,
  y4: 226
};

export const ShortThirdManSecond = {
  x1: 205,
  y1: 161,
  x2: 249,
  y2: 143,
  x3: 247,
  y3: 222,
  x4: 237,
  y4: 226
};



//left hand batsamn
export const Left_Leg_Short = {
  x1: 30,
  y1: 320,
  x2: 57,
  y2: 219,
  x3: 142,
  y3: 219,
  x4: 139,
  y4: 319
}

//Mid will be same for both right and left
export const Right_Mid_Short = {
  x1: 138,
  y1: 320,
  x2: 139,
  y2: 219,
  x3: 155,
  y3: 219,
  x4: 155,
  y4: 322
}

export const Right_Off_Short = {
  x1: 29,
  y1: 322,
  x2: 55,
  y2: 220,
  x3: 139,
  y3: 219,
  x4: 135,
  y4: 322
}

export const Right_Leg_Short = {
  x1: 156,
  y1: 321,
  x2: 155,
  y2: 219,
  x3: 244,
  y3: 219,
  x4: 270,
  y4: 320
}

//for left hand batsman leg--->off
export const Left_Off_Short = {
  x1: 159,
  y1: 321,
  x2: 159,
  y2: 219,
  x3: 244,
  y3: 218,
  x4: 271,
  y4: 321
}



//left hand batsman
export const Left_Leg_Good = {
  x1: 62,
  y1: 199,
  x2: 66,
  y2: 181,
  x3: 143,
  y3: 181,
  x4: 142,
  y4: 199
}


export const Left_Off_Good = {
  x1: 160,
  y1: 201,
  x2: 161,
  y2: 182,
  x3: 235,
  y3: 181,
  x4: 240,
  y4: 199
}

export const Right_Mid_Good = {
  x1: 139,
  y1: 202,
  x2: 140,
  y2: 181,
  x3: 155,
  y3: 181,
  x4: 156,
  y4: 202
}

export const Right_Off_Good = {
  x1: 61,
  y1: 201,
  x2: 66,
  y2: 181,
  x3: 139,
  y3: 181,
  x4: 139,
  y4: 202
}

export const Right_Leg_Good = {
  x1: 155,
  y1: 201,
  x2: 155,
  y2: 181,
  x3: 235,
  y3: 181,
  x4: 239,
  y4: 201
}


export const Left_Leg_Full = {
  x1: 66,
  y1: 183,
  x2: 75,
  y2: 147,
  x3: 142,
  y3: 148,
  x4: 141,
  y4: 183
}


export const Left_Off_Full = {
  x1: 159, y1: 183,
  x2: 159, y2: 148,
  x3: 226, y3: 147,
  x4: 235, y4: 183
}


export const Right_Mid_Full = {
  x1: 139,
  y1: 183,
  x2: 139,
  y2: 148,
  x3: 155,
  y3: 148,
  x4: 155,
  y4: 183
}

export const Right_Off_Full = {
  x1: 66,
  y1: 181,
  x2: 76,
  y2: 147,
  x3: 139,
  y3: 146,
  x4: 139,
  y4: 183
}

export const Right_Leg_Full = {
  x1: 157, y1: 183,
  x2: 155, y2: 148,
  x3: 227, y3: 147,
  x4: 235, y4: 183
}


export const Left_Leg_Yorker = {
  x1: 75,
  y1: 147,
  x2: 80,
  y2: 135,
  x3: 143,
  y3: 136,
  x4: 142,
  y4: 148
}


export const Left_Off_Yorker = {
  x1: 159,
  y1: 150,
  x2: 159,
  y2: 136,
  x3: 222,
  y3: 135,
  x4: 227,
  y4: 148
}

export const Right_Mid_Yorker = {
  x1: 142,
  y1: 148,
  x2: 142,
  y2: 133,
  x3: 155,
  y3: 133,
  x4: 156,
  y4: 147
}

export const Right_Off_Yorker = {
  x1: 73,
  y1: 147,
  x2: 78,
  y2: 133,
  x3: 142,
  y3: 133,
  x4: 140,
  y4: 149
}

export const Right_Leg_Yorker = {
  x1: 155,
  y1: 148,
  x2: 155,
  y2: 133,
  x3: 223,
  y3: 135,
  x4: 226,
  y4: 146
}

// export const AwayFromOff = {
//   x1: 181,
//   y1: 170,
//   x2: 119,
//   y2: 169,
//   x3: 119,
//   y3: 31,
//   x4: 179,
//   y4: 32
// }

// export const AwayFromLeg = {
//   x1: 248,
//   y1: 169,
//   x2: 309,
//   y2: 169,
//   x3: 248,
//   y3: 32,
//   x4: 309,
//   y4: 28
// }

// export const OutSideOff = {
//   x1: 181,
//   y1: 168,
//   x2: 201,
//   y2: 168,
//   x3: 200,
//   y3: 29,
//   x4: 182,
//   y4: 28
// }

// export const OutSideLeg = {
//   x1: 225,
//   y1: 168,
//   x2: 225,
//   y2: 28,
//   x3: 243,
//   y3: 168,
//   x4: 224,
//   y4: 28
// }

// export const OnOffStump = {
//   x1: 203,
//   y1: 168,
//   x2: 208,
//   y2: 168,
//   x3: 208,
//   y3: 123,
//   x4: 203,
//   y4: 124
// }

// export const OnMidStump = {
//   x1: 216,
//   y1: 168,
//   x2: 215,
//   y2: 122,
//   x3: 213,
//   y3: 123,
//   x4: 212,
//   y4: 168
// }

// export const OnLegStump = {
//   x1: 219,
//   y1: 168,
//   x2: 219,
//   y2: 123,
//   x3: 123,
//   y3: 223,
//   x4: 222,
//   y4: 168
// }

// export const BetweenMidOffStump = {
//   x1: 208,
//   y1: 123,
//   x2: 212,
//   y2: 123,
//   x3: 212,
//   y3: 167,
//   x4: 207,
//   y4: 168
// }

// export const BetweenMidLegStump = {
//   x1: 215,
//   y1: 121,
//   x2: 219,
//   y2: 121,
//   x3: 219,
//   y3: 167,
//   x4: 215,
//   y4: 167
// }

// export const BouncerStump = {
//   x1: 201,
//   y1: 30,
//   x2: 215,
//   y2: 30,
//   x3: 215,
//   y3: 79,
//   x4: 201,
//   y4: 79
// }

// export const AwayFromLegStump = {
//   x1: 216,
//   y1: 30,
//   x2: 308,
//   y2: 30,
//   x3: 308,
//   y3: 165,
//   x4: 216,
//   y4: 165
// }

//starting setting new coordinates as per redesign(akshay's changes) pitchmap
export const Right_Hand_New_Good = {
  x1: 63,
  y1: 187,
  x2: 239,
  y2: 187,
  x3: 244,
  y3: 202,
  x4: 58,
  y4: 202
}

export const Right_Hand_New_Back_of_length = {
  x1: 244,
  y1: 203,
  x2: 249,
  y2: 222,
  x3: 53,
  y3: 220,
  x4: 58,
  y4: 203
}



export const Leg_Left_Hand_New_Back_of_length = {
  x1: 56,
  y1: 221,
  x2: 61,
  y2: 199,
  x3: 143,
  y3: 199,
  x4: 140,
  y4: 222
}

export const Off_Left_Hand_New_Back_of_length = {
  x1: 160,
  y1: 221,
  x2: 161,
  y2: 200,
  x3: 241,
  y3: 200,
  x4: 246,
  y4: 221
}

export const Mid_Right_Hand_New_Back_of_length = {
  x1: 139,
  y1: 222,
  x2: 139,
  y2: 200,
  x3: 155,
  y3: 199,
  x4: 155,
  y4: 222
}

export const Off_Right_Hand_New_Back_of_length = {
  x1: 55,
  y1: 221,
  x2: 63,
  y2: 200,
  x3: 139,
  y3: 199,
  x4: 139,
  y4: 221
}

export const Leg_Right_Hand_New_Back_of_length = {
  x1: 156,
  y1: 222,
  x2: 155,
  y2: 199,
  x3: 239,
  y3: 199,
  x4: 245,
  y4: 221
}

export const Right_Hand_New_Full = {
  x1: 72,
  y1: 151,
  x2: 228,
  y2: 151,
  x3: 236,
  y3: 183,
  x4: 64,
  y4: 182
}

export const Right_Hand_New_Yorker = {
  x1: 76,
  y1: 134,
  x2: 225,
  y2: 133,
  x3: 228,
  y3: 148,
  x4: 72,
  y4: 149
}

export const Right_Hand_New_Short = {
  x1: 53,
  y1: 224,
  x2: 249,
  y2: 227,
  x3: 274,
  y3: 323,
  x4: 28,
  y4: 321
}

// export const Right_Hand_New_AwayFromOff = {
//   x1: 77,
//   y1: 24,
//   x2: 122,
//   y2: 25,
//   x3: 123,
//   y3: 128,
//   x4: 77,
//   y4: 130
// }

// export const Right_Hand_New_AwayFromLeg = {
//   x1: 176,
//   y1: 24,
//   x2: 225,
//   y2: 23,
//   x3: 224,
//   y3: 130,
//   x4: 176,
//   y4: 129
// }

// export const Right_Hand_New_OutSideOff = {
//   x1: 142,
//   y1: 129,
//   x2: 141,
//   y2: 23,
//   x3: 124,
//   y3: 21,
//   x4: 125,
//   y4: 129
// }

// export const Right_Hand_New_OutSideLeg = {
//   x1: 174,
//   y1: 129,
//   x2: 174,
//   y2: 23,
//   x3: 157,
//   y3: 23,
//   x4: 157,
//   y4: 128
// }

export const Right_Hand_New_OnOffStump = {
  x1: 144,
  y1: 99,
  x2: 144,
  y2: 130,
  x3: 146,
  y3: 99,
  x4: 146,
  y4: 130
}

export const Right_Hand_New_OnLegStump = {
  x1: 153,
  y1: 99,
  x2: 153,
  y2: 130,
  x3: 155,
  y3: 99,
  x4: 155,
  y4: 130
}

export const Right_Hand_New_OnMidStump = {
  x1: 140,
  y1: 127,
  x2: 140,
  y2: 95,
  x3: 159,
  y3: 95,
  x4: 159,
  y4: 126
}

export const Right_Hand_New_BetweenMidOffStump = {
  x1: 146,
  y1: 98,
  x2: 146,
  y2: 129,
  x3: 148,
  y3: 98,
  x4: 148,
  y4: 129
}

export const Right_Hand_New_BetweenMidLegStump = {
  x1: 152,
  y1: 98,
  x2: 152,
  y2: 129,
  x3: 154,
  y3: 98,
  x4: 154,
  y4: 129
}

// export const Right_Hand_New_BouncerStump = {
//   x1: 144,
//   y1: 98,
//   x2: 154,
//   y2: 94,
//   x3: 156,
//   y3: 23,
//   x4: 142,
//   y4: 22
// }

// export const Right_Hand_New_AwayFromLegStump = {
//   x1: 216,
//   y1: 30,
//   x2: 308,
//   y2: 30,  
//   x3: 308,
//   y3: 165,
//   x4: 216,
//   y4: 165
// }


//bowling analysis for left hand batsman
//starting setting new coordinates as per redesign(akshay's changes) pitchmap
export const Left_Hand_New_Good = {
  x1: 63,
  y1: 187,
  x2: 239,
  y2: 187,
  x3: 244,
  y3: 202,
  x4: 58,
  y4: 202
}

export const Left_Hand_New_Back_of_length = {
  x1: 244,
  y1: 203,
  x2: 249,
  y2: 222,
  x3: 53,
  y3: 220,
  x4: 58,
  y4: 203
}

export const Left_Hand_New_Full = {
  x1: 72,
  y1: 151,
  x2: 228,
  y2: 151,
  x3: 236,
  y3: 183,
  x4: 64,
  y4: 182
}

export const Left_Hand_New_Yorker = {
  x1: 76,
  y1: 134,
  x2: 225,
  y2: 133,
  x3: 228,
  y3: 148,
  x4: 72,
  y4: 149
}

export const Left_Hand_New_Short = {
  x1: 53,
  y1: 224,
  x2: 249,
  y2: 227,
  x3: 274,
  y3: 323,
  x4: 28,
  y4: 321
}

// export const Left_Hand_New_AwayFromLeg = {
//   x1: 77,
//   y1: 24,
//   x2: 122,
//   y2: 25,
//   x3: 123,
//   y3: 128,
//   x4: 77,
//   y4: 130
// }

// export const Left_Hand_New_AwayFromOff = {
//   x1: 176,
//   y1: 24,
//   x2: 225,
//   y2: 23,
//   x3: 224,
//   y3: 130,
//   x4: 176,
//   y4: 129
// }

// export const Left_Hand_New_OutSideLeg = {
//   x1: 142,
//   y1: 129,
//   x2: 141,
//   y2: 23,
//   x3: 124,
//   y3: 21,
//   x4: 125,
//   y4: 129
// }

// export const Left_Hand_New_OutSideOff = {
//   x1: 174,
//   y1: 129,
//   x2: 174,
//   y2: 23,
//   x3: 157,
//   y3: 23,
//   x4: 157,
//   y4: 128
// }

export const Left_Hand_New_OnLegStump = {
  x1: 144,
  y1: 99,
  x2: 144,
  y2: 130,
  x3: 146,
  y3: 99,
  x4: 146,
  y4: 130
}

export const Left_Hand_New_OnOffStump = {
  x1: 153,
  y1: 99,
  x2: 153,
  y2: 130,
  x3: 155,
  y3: 99,
  x4: 155,
  y4: 130
}

export const Left_Hand_New_OnMidStump = {
  x1: 143,
  y1: 127,
  x2: 143,
  y2: 95,
  x3: 157,
  y3: 95,
  x4: 159,
  y4: 127
}

export const Left_Hand_New_BetweenMidLegStump = {
  x1: 146,
  y1: 98,
  x2: 146,
  y2: 129,
  x3: 148,
  y3: 98,
  x4: 148,
  y4: 129
}

export const Left_Hand_New_BetweenMidOffStump = {
  x1: 152,
  y1: 98,
  x2: 152,
  y2: 129,
  x3: 154,
  y3: 98,
  x4: 154,
  y4: 129
}

// export const Left_Hand_New_BouncerStump = {
//   x1: 144,
//   y1: 98,
//   x2: 154,
//   y2: 94,
//   x3: 156,
//   y3: 23,
//   x4: 142,
//   y4: 22
// }

export const Left_Hand_New_AwayFromOffStump = {
  x1: 216,
  y1: 30,
  x2: 308,
  y2: 30,
  x3: 308,
  y3: 165,
  x4: 216,
  y4: 165
}


//starting setting new coordinates as per redesign(akshay's changes) waghoon wheel Right hand batsman

export const Right_Hand_New_ShortFineLegFirst = {
  x1: 155,
  y1: 161,
  x2: 154,
  y2: 84,
  x3: 187,
  y3: 97,
  x4: 158,
  y4: 158
};

export const Right_Hand_New_ShortFineLegSecond = {
  x1: 155,
  y1: 156,
  x2: 175,
  y2: 90,
  x3: 203,
  y3: 112,
  x4: 160,
  y4: 158
};

export const Right_Hand_New_DeepFineLegFirst = {
  x1: 151,
  y1: 1,
  x2: 151,
  y2: 82,
  x3: 180,
  y3: 90,
  x4: 213,
  y4: 17
};

export const Right_Hand_New_DeepFineLegSecond = {
  x1: 216,
  y1: 17,
  x2: 182,
  y2: 92,
  x3: 207,
  y3: 112,
  x4: 263,
  y4: 56
};

export const Right_Hand_New_SquareLegFirst = {
  x1: 159,
  y1: 162,
  x2: 207,
  y2: 116,
  x3: 217,
  y3: 146,
  x4: 160,
  y4: 166
};


export const Right_Hand_New_SquareLegSecond = {
  x1: 159,
  y1: 166,
  x2: 218,
  y2: 146,
  x3: 218,
  y3: 171,
  x4: 159,
  y4: 171
};

export const Right_Hand_New_DeepSquareLegFirst = {
  x1: 264,
  y1: 58,
  x2: 207,
  y2: 112,
  x3: 216,
  y3: 132,
  x4: 297,
  y4: 130
};


export const Right_Hand_New_DeepSquareLegSecond = {
  x1: 217,
  y1: 137,
  x2: 297,
  y2: 132,
  x3: 297,
  y3: 170,
  x4: 218,
  y4: 171
};

export const Right_Hand_New_MidWicketFirst = {
  x1: 217,
  y1: 172,
  x2: 217,
  y2: 200,
  x3: 160,
  y3: 177,
  x4: 159,
  y4: 172
};

export const Right_Hand_New_MidWicketSecond = {
  x1: 160,
  y1: 181,
  x2: 208,
  y2: 229,
  x3: 217,
  y3: 201,
  x4: 159,
  y4: 177
};

export const Right_Hand_New_DeepMidWicketFirst = {
  x1: 219,
  y1: 172,
  x2: 297,
  y2: 172,
  x3: 297,
  y3: 210,
  x4: 218,
  y4: 202
};

export const Right_Hand_New_DeepMidWicketSecond = {
  x1: 218,
  y1: 203,
  x2: 209,
  y2: 230,
  x3: 267,
  y3: 288,
  x4: 297,
  y4: 210
};

export const Right_Hand_New_MidOnFirst = {
  x1: 160,
  y1: 209,
  x2: 184,
  y2: 253,
  x3: 207,
  y3: 229,
  x4: 160,
  y4: 182
};

export const Right_Hand_New_MidOnSecond = {
  x1: 151,
  y1: 209,
  x2: 159,
  y2: 209,
  x3: 183,
  y3: 255,
  x4: 151,
  y4: 261
};


export const Right_Hand_New_LongOnFirst = {
  x1: 209,
  y1: 232,
  x2: 266,
  y2: 286,
  x3: 203,
  y3: 333,
  x4: 180,
  y4: 258
};

export const Right_Hand_New_LongOnSecond = {
  x1: 189,
  y1: 253,
  x2: 231,
  y2: 321,
  x3: 151,
  y3: 342,
  x4: 153,
  y4: 265
};

export const Right_Hand_New_MidOffFirst = {
  x1: 139,
  y1: 209,
  x2: 120,
  y2: 256,
  x3: 149,
  y3: 262,
  x4: 149,
  y4: 209
};

export const Right_Hand_New_MidOffSecond = {
  x1: 139,
  y1: 183,
  x2: 139,
  y2: 208,
  x3: 118,
  y3: 254,
  x4: 92,
  y4: 230
};

export const Right_Hand_New_LongOffFirst = {
  x1: 116,
  y1: 254,
  x2: 149,
  y2: 264,
  x3: 150,
  y3: 346,
  x4: 85,
  y4: 331
};

export const Right_Hand_New_LongOffSecond = {
  x1: 91,
  y1: 230,
  x2: 114,
  y2: 253,
  x3: 80,
  y3: 331,
  x4: 32,
  y4: 289
};

export const Right_Hand_New_DeepCoverFirst = {
  x1: 2,
  y1: 171,
  x2: 80,
  y2: 172,
  x3: 80,
  y3: 201,
  x4: 7,
  y4: 241
};
export const Right_Hand_New_DeepCoverSecond = {
  x1: 4,
  y1: 226,
  x2: 81,
  y2: 208,
  x3: 89,
  y3: 230,
  x4: 32,
  y4: 289
};

export const Right_Hand_New_CoverFirst = {
  x1: 91,
  y1: 229,
  x2: 81,
  y2: 197,
  x3: 139,
  y3: 176,
  x4: 139,
  y4: 181
};

export const Right_Hand_New_CoverSecond = {
  x1: 81,
  y1: 198,
  x2: 139,
  y2: 176,
  x3: 139,
  y3: 171,
  x4: 82,
  y4: 172
};

export const Right_Hand_New_DeepPointFirst = {
  x1: 3,
  y1: 170,
  x2: 78,
  y2: 170,
  x3: 80,
  y3: 147,
  x4: 4,
  y4: 122
};

export const Right_Hand_New_DeepPointSecond = {
  x1: 3,
  y1: 124,
  x2: 79,
  y2: 148,
  x3: 90,
  y3: 111,
  x4: 35,
  y4: 58
};

export const Right_Hand_New_PointFirst = {
  x1: 81,
  y1: 146,
  x2: 139,
  y2: 164,
  x3: 139,
  y3: 170,
  x4: 81,
  y4: 170
};

export const Right_Hand_New_PointSecond = {
  x1: 81,
  y1: 150,
  x2: 140,
  y2: 166,
  x3: 139,
  y3: 161,
  x4: 93,
  y4: 114
};

export const Right_Hand_New_ThirdManFirst = {
  x1: 35,
  y1: 56,
  x2: 90,
  y2: 15,
  x3: 119,
  y3: 89,
  x4: 92,
  y4: 110
};

export const Right_Hand_New_ThirdManSecond = {
  x1: 86,
  y1: 16,
  x2: 116,
  y2: 91,
  x3: 150,
  y3: 80,
  x4: 150,
  y4: 1
};

export const Right_Hand_New_ShortThirdManFirst = {
  x1: 116,
  y1: 93,
  x2: 140,
  y2: 140,
  x3: 149,
  y3: 140,
  x4: 149,
  y4: 84
};

export const Right_Hand_New_ShortThirdManSecond = {
  x1: 92,
  y1: 113,
  x2: 117,
  y2: 92,
  x3: 138,
  y3: 139,
  x4: 139,
  y4: 160
};


//starting setting new coordinates as per redesign(akshay's changes) waghoon wheel left hand batsman
//Left_Hand_New_ShortFineLegFirst
export const Left_Hand_New_ShortThirdManFirst = {
  x1: 152,
  y1: 84,
  x2: 152,
  y2: 137,
  x3: 158,
  y3: 138,
  x4: 180,
  y4: 91
};

export const Left_Hand_New_ShortThirdManSecond = {
  x1: 180,
  y1: 91,
  x2: 160,
  y2: 140,
  x3: 160,
  y3: 159,
  x4: 209,
  y4: 114
};

//deep fine leg to third man
//Left_Hand_New_DeepFineLegFirst
export const Left_Hand_New_ThirdManFirst = {
  x1: 151,
  y1: 1,
  x2: 151,
  y2: 82,
  x3: 180,
  y3: 90,
  x4: 213,
  y4: 17
};

//Left_Hand_New_DeepFineLegSecond
export const Left_Hand_New_ThirdManSecond = {
  x1: 216,
  y1: 17,
  x2: 182,
  y2: 92,
  x3: 207,
  y3: 112,
  x4: 263,
  y4: 56
};
//Left_Hand_New_SquareLegFirst
export const Left_Hand_New_PointFirst = {
  x1: 159,
  y1: 162,
  x2: 207,
  y2: 116,
  x3: 217,
  y3: 146,
  x4: 160,
  y4: 166
};

//Left_Hand_New_SquareLegSecond
export const Left_Hand_New_PointSecond = {
  x1: 159,
  y1: 166,
  x2: 218,
  y2: 146,
  x3: 218,
  y3: 171,
  x4: 159,
  y4: 171
};
//Left_Hand_New_DeepSquareLegFirst
export const Left_Hand_New_DeepPointFirst = {
  x1: 264,
  y1: 58,
  x2: 207,
  y2: 112,
  x3: 216,
  y3: 132,
  x4: 297,
  y4: 130
};

//Left_Hand_New_DeepSquareLegSecond
export const Left_Hand_New_DeepPointSecond = {
  x1: 217,
  y1: 137,
  x2: 297,
  y2: 132,
  x3: 297,
  y3: 170,
  x4: 218,
  y4: 171
};
//Left_Hand_New_MidWicketFirst(idhrr mujhse gaadbaad ho gaya hai)
export const Left_Hand_New_CoverFirst = {
  x1: 217,
  y1: 172,
  x2: 217,
  y2: 200,
  x3: 160,
  y3: 177,
  x4: 159,
  y4: 172
};
//Left_Hand_New_CoverSecond(idhrr mujhse gaadbaad ho gaya hai)
export const Left_Hand_New_CoverSecond = {
  x1: 160,
  y1: 181,
  x2: 208,
  y2: 229,
  x3: 217,
  y3: 201,
  x4: 159,
  y4: 177
};
//Left_Hand_New_DeepMidWicketFirst
export const Left_Hand_New_DeepCoverFirst = {
  x1: 219,
  y1: 172,
  x2: 297,
  y2: 172,
  x3: 297,
  y3: 210,
  x4: 218,
  y4: 202
};
//Left_Hand_New_DeepMidWicketSecond
export const Left_Hand_New_DeepCoverSecond = {
  x1: 218,
  y1: 203,
  x2: 209,
  y2: 230,
  x3: 267,
  y3: 288,
  x4: 297,
  y4: 210
};
//Left_Hand_New_MidOnFirst
export const Left_Hand_New_MidOffFirst = {
  x1: 153,
  y1: 262,
  x2: 154,
  y2: 186,
  x3: 159,
  y3: 185,
  x4: 192,
  y4: 246
};
//Left_Hand_New_MidOnSecond
export const Left_Hand_New_MidOffSecond = {
  x1: 153,
  y1: 185,
  x2: 158,
  y2: 182,
  x3: 206,
  y3: 231,
  x4: 169,
  y4: 258
};

//Left_Hand_New_LongOnFirst
export const Left_Hand_New_LongOffFirst = {
  x1: 153,
  y1: 266,
  x2: 186,
  y2: 257,
  x3: 235,
  y3: 318,
  x4: 154,
  y4: 346
};

//Left_Hand_New_LongOnSecond
export const Left_Hand_New_LongOffSecond = {
  x1: 183,
  y1: 259,
  x2: 208,
  y2: 233,
  x3: 264,
  y3: 289,
  x4: 201,
  y4: 336
};
//Left_Hand_New_MidOffFirst
export const Left_Hand_New_MidOnFirst = {
  x1: 93,
  y1: 230,
  x2: 146,
  y2: 180,
  x3: 150,
  y3: 188,
  x4: 125,
  y4: 258
};

export const Left_Hand_New_MidOnSecond = {
  x1: 109,
  y1: 248,
  x2: 146,
  y2: 182,
  x3: 151,
  y3: 188,
  x4: 150,
  y4: 262
};
//Left_Hand_New_LongOffFirst
export const Left_Hand_New_LongOnFirst = {
  x1: 33,
  y1: 290,
  x2: 90,
  y2: 232,
  x3: 118,
  y3: 259,
  x4: 94,
  y4: 334
};

export const Left_Hand_New_LongOnSecond = {
  x1: 115,
  y1: 258,
  x2: 150,
  y2: 266,
  x3: 148,
  y3: 344,
  x4: 64,
  y4: 318
};
//Left_Hand_New_DeepCoverFirst
export const Left_Hand_New_DeepMidWicketFirst = {
  x1: 3,
  y1: 225,
  x2: 81,
  y2: 208,
  x3: 89,
  y3: 230,
  x4: 32,
  y4: 287
};

export const Left_Hand_New_DeepMidWicketSecond = {
  x1: 2,
  y1: 172,
  x2: 79,
  y2: 172,
  x3: 80,
  y3: 203,
  x4: 7,
  y4: 236
};
//done
export const Left_Hand_New_MidWicketFirst = {
  x1: 91,
  y1: 229,
  x2: 81,
  y2: 197,
  x3: 139,
  y3: 176,
  x4: 139,
  y4: 181
};

export const Left_Hand_New_MidWicketSecond = {
  x1: 81,
  y1: 198,
  x2: 139,
  y2: 176,
  x3: 139,
  y3: 171,
  x4: 82,
  y4: 172
};
//done
export const Left_Hand_New_DeepSquareLegFirst = {
  x1: 3,
  y1: 170,
  x2: 78,
  y2: 170,
  x3: 80,
  y3: 147,
  x4: 4,
  y4: 122
};
//done
export const Left_Hand_New_DeepSquareLegSecond = {
  x1: 3,
  y1: 124,
  x2: 79,
  y2: 148,
  x3: 90,
  y3: 111,
  x4: 35,
  y4: 58
};

export const Left_Hand_New_SqaureLegFirst = {
  x1: 81,
  y1: 146,
  x2: 139,
  y2: 164,
  x3: 139,
  y3: 170,
  x4: 81,
  y4: 170
};

export const Left_Hand_New_SqaureLegSecond = {
  x1: 81,
  y1: 150,
  x2: 140,
  y2: 166,
  x3: 139,
  y3: 161,
  x4: 93,
  y4: 114
};
//done
export const Left_Hand_New_DeepFineLegFirst = {
  x1: 35,
  y1: 56,
  x2: 90,
  y2: 15,
  x3: 119,
  y3: 89,
  x4: 92,
  y4: 110
};

export const Left_Hand_New_DeepFineLegSecond = {
  x1: 86,
  y1: 16,
  x2: 116,
  y2: 91,
  x3: 150,
  y3: 80,
  x4: 150,
  y4: 1
};
//done
export const Left_Hand_New_ShortFineLegFirst = {
  x1: 116,
  y1: 93,
  x2: 140,
  y2: 140,
  x3: 149,
  y3: 140,
  x4: 149,
  y4: 84
};
//done
export const Left_Hand_New_ShortFineLegSecond = {
  x1: 92,
  y1: 113,
  x2: 117,
  y2: 92,
  x3: 138,
  y3: 139,
  x4: 139,
  y4: 160
};

//half stump for right hand batsman(off side)
export const Right_Hand_Off_side_half_stump = {
  x1: 78, y1: 127,
  x2: 78, y2: 113,
  x3: 142, y3: 112,
  x4: 142, y4: 128
}

//half stump for right hand batsman(leg side)
export const Right_Hand_Leg_side_half_stump = {
  x1: 157, y1: 128,
  x2: 156, y2: 114,
  x3: 222, y3: 112,
  x4: 220, y4: 128
}

//full stump for right hand batsman(leg side)
export const Right_Hand_Leg_side_full_stump = {
  x1: 157, y1: 117,
  x2: 158, y2: 95,
  x3: 220, y3: 97,
  x4: 220, y4: 116
}

//full stump for right hand batsman(off side)
export const Right_Hand_Off_side_full_stump = {
  x1: 78, y1: 115,
  x2: 77, y2: 95,
  x3: 139, y3: 95,
  x4: 139, y4: 115
}


//half stump for left hand batsman(off side)
export const Left_Hand_Off_side_half_stump = {
  x1: 159, y1: 127,
  x2: 159, y2: 114,
  x3: 222, y3: 114,
  x4: 221, y4: 127
}

//half stump for left hand batsman(leg side)
export const Left_Hand_Leg_side_half_stump = {
  x1: 78, y1: 127,
  x2: 78, y2: 112,
  x3: 143, y3: 111,
  x4: 141, y4: 125
}

//full stump for left hand batsman(off side)
export const Left_Hand_Off_side_full_stump = {
  x1: 159, y1: 115,
  x2: 159, y2: 97,
  x3: 222, y3: 98,
  x4: 222, y4: 116
}

//full stump for left hand batsman(leg side)
export const Left_Hand_Leg_side_full_stump = {
  x1: 78, y1: 116,
  x2: 78, y2: 95,
  x3: 142, y3: 96,
  x4: 142, y4: 116
}


export const Left_Hand_Mid_side_OneHalf_stump = {
  x1: 143, y1: 98,
  x2: 143, y2: 77,
  x3: 158, y3: 75,
  x4: 157, y4: 100
}

//one half for right hand batsman(mid side)
export const Right_Hand_Mid_side_OneHalf_stump = {
  x1: 143, y1: 99,
  x2: 143, y2: 79,
  x3: 157, y3: 77,
  x4: 156, y4: 102
}

//one half for right hand batsman(off side)
export const Right_Hand_Off_side_OneHalf_stump = {
  x1: 79, y1: 101,
  x2: 79, y2: 75,
  x3: 142, y3: 75,
  x4: 142, y4: 99
}

//one half for right hand batsman(leg side)
export const Right_Hand_Leg_side_OneHalf_stump = {
  x1: 158, y1: 99,
  x2: 155, y2: 75,
  x3: 222, y3: 75,
  x4: 221, y4: 99
}

//Double for right hand batsman(mid side)
export const Right_Hand_Mid_side_double_stump = {
  x1: 143, y1: 80,
  x2: 143, y2: 59,
  x3: 157, y3: 59,
  x4: 156, y4: 80,
}

//Double for right hand batsman(off side)
export const Right_Hand_Off_side_double_stump = {
  x1: 78, y1: 79,
  x2: 78, y2: 59,
  x3: 143, y3: 59,
  x4: 143, y4: 79,
}

//Double for right hand batsman(leg side)
export const Right_Hand_Leg_side_double_stump = {
  x1: 155, y1: 82,
  x2: 154, y2: 59,
  x3: 221, y3: 59,
  x4: 222, y4: 79
}

//one half for right hand batsman(leg side)
export const Left_Hand_Leg_side_OneHalf_stump = {
  x1: 77, y1: 99,
  x2: 78, y2: 77,
  x3: 142, y3: 77,
  x4: 142, y4: 98
}

//one half for right hand batsman(off side)
export const Left_Hand_Off_side_OneHalf_stump = {
  x1: 159, y1: 100,
  x2: 157, y2: 78,
  x3: 222, y3: 76,
  x4: 222, y4: 99
}

export const Left_Hand_Mid_side_double_stump = {
  x1: 145, y1: 82,
  x2: 145, y2: 59,
  x3: 156, y3: 59,
  x4: 157, y4: 83
}

//Double for right hand batsman(leg side)
export const Left_Hand_Leg_side_double_stump = {
  x1: 79, y1: 79,
  x2: 77, y2: 58,
  x3: 145, y3: 60,
  x4: 143, y4: 81
}


export const Left_Hand_Mid_side_above_stump = {
  x1: 143, y1: 61,
  x2: 142, y2: 23,
  x3: 156, y3: 23,
  x4: 156, y4: 60
}

//Double for right hand batsman(off side)
export const Left_Hand_Off_side_double_stump = {
  x1: 158, y1: 82,
  x2: 158, y2: 59,
  x3: 223, y3: 59,
  x4: 223, y4: 79
}

//Above stumps right hand batsman(Mid side)
export const Right_Hand_Mid_side_above_stump = {
  x1: 140, y1: 62,
  x2: 140, y2: 23,
  x3: 160, y3: 23,
  x4: 158, y4: 61
}

//Above stumps right hand batsman(Off side)
export const Right_Hand_Off_side_above_stump = {
  x1: 77, y1: 63,
  x2: 79, y2: 24,
  x3: 139, y3: 24,
  x4: 140, y4: 63
}

//Above stumps right hand batsman(Leg side)
export const Right_Hand_Leg_side_above_stump = {
  x1: 157, y1: 63,
  x2: 155, y2: 23,
  x3: 222, y3: 24,
  x4: 222, y4: 64
}


//Above stumps left hand batsman(left side)
export const Left_Hand_Leg_side_above_stump = {
  x1: 78, y1: 63,
  x2: 78, y2: 23,
  x3: 143, y3: 23,
  x4: 142, y4: 62
}

//Above stumps left hand batsman(off side)
export const Left_Hand_Off_side_above_stump = {
  x1: 157, y1: 61,
  x2: 157, y2: 25,
  x3: 222, y3: 25,
  x4: 223, y4: 61
}

export const Left_Mid_Yorker = {
  x1: 145, y1: 149,
  x2: 145, y2: 135,
  x3: 156, y3: 135,
  x4: 158, y4: 148
}

export const Left_Mid_Full = {
  x1: 143, y1: 183,
  x2: 143, y2: 148,
  x3: 158, y3: 147,
  x4: 159, y4: 182
}

export const Left_Mid_Good = {
  x1: 143, y1: 199,
  x2: 143, y2: 181,
  x3: 159, y3: 180,
  x4: 159, y4: 199
}

export const Mid_Left_Hand_New_Back_of_length = {
  x1: 143, y1: 223,
  x2: 143, y2: 199,
  x3: 159, y3: 199,
  x4: 159, y4: 222
}


export const Left_Mid_Short = {
  x1: 141, y1: 323,
  x2: 143, y2: 219,
  x3: 159, y3: 219,
  x4: 159, y4: 323
}

export function area(x1, y1, x2, y2, x3, y3) {
  return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2.0);
}

export function isInsideTriangle({ x1, y1, x2, y2, x3, y3 }, { x, y }) {
  let A = area(x1, y1, x2, y2, x3, y3);

  let A1 = area(x, y, x2, y2, x3, y3);

  let A2 = area(x1, y1, x, y, x3, y3);

  let A3 = area(x1, y1, x2, y2, x, y);

  return A == A1 + A2 + A3;
}

export function isInsideRectangle({ x1, y1, x2, y2, x3, y3, x4, y4 }, { x, y }) {
  let A = area(x1, y1, x2, y2, x3, y3) + area(x1, y1, x4, y4, x3, y3);

  let A1 = area(x, y, x1, y1, x2, y2);

  let A2 = area(x, y, x2, y2, x3, y3);

  let A3 = area(x, y, x3, y3, x4, y4);

  let A4 = area(x, y, x1, y1, x4, y4);

  return A == A1 + A2 + A3 + A4;
}

export const GreenBallArea = {
  x1: 27,
  y1: 323,
  x2: 78,
  y2: 134,
  x3: 225,
  y3: 135,
  x4: 274,
  y4: 323
}

export const RedBallArea = {
  x1: 77,
  y1: 23,
  x2: 225,
  y2: 23,
  x3: 222,
  y3: 128,
  x4: 76,
  y4: 128
}

export const ordinal_suffix_of = (i) => {
  const j = i % 10;
  const k = i % 100;

  if (j == 1 && k != 11) return i + "st";
  if (j == 2 && k != 12) return i + "nd";
  if (j == 3 && k != 13) return i + "rd";
  return i + "th";
};

export const nextBall = (ball) => {
  const decimal = (ball - Math.floor(ball)).toFixed(1);
  return decimal < 0.5 ? Number(ball) + 0.1 : Math.floor(ball) + 1;
};
