import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { AddVideo, GetCategories, GetLearningCategories, GetLearningCenters, GetMatchCategories, GetMatchCenters } from "./Helper";
import { useForm } from "react-hook-form";
import { GetAllTournaments } from "../MatchCenter/Tournament/Helper";
import { GetAllMatchesByTournament } from "../MatchCenter/MatchAna/Helper";
import LoadingIndicator from "../../components/LoadingIndicator";

const Video = () => {
  const [customErrors, setCustomErrors] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [video, setVideo] = useState({
    title: "",
    category: "",
    sub_category1: "",
    sub_category2: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState([]);
  const [subCategories2, setSubCategories2] = useState([]);
  const [hideSub, setHideSub] = useState(false);
  const [hideInning, setHideInning] = useState(true);
  const [file, setFile] = useState();
  const onDrop = (e) => {
    if ((e[0].type.split("/")[0] === "video") || (e[0].type.split("/")[0] === "image")) {
      if ((e[0].size / (1024 * 1024)) > 1000) {
        Swal.fire({
          icon: 'info',
          title: '😑',
          text: "File size must be less than 1 GB"
        })
      }
      else {
        setFile(e[0]);
        setCustomErrors(null)
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: '😑',
        text: "Only video files are accepted"
      })
    }
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  }

  const [message, setMessage] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  useEffect(() => {
    setHeaderTitle("Video Upload");
    GetCategories().then((data) => {
      if (data?.status) {
        setCategories(data?.categories);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Unable to fetch categories⛔',
          text: "Something went wrong"
        })
      }
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Unable to fetch categories⛔',
        text: err
      })
    })
  }, []);
  useEffect(() => {
    if (video.category !== undefined) {
      if (video.category === "1") {
        setHideSub(false);
        GetLearningCenters().then((data) => {
          if (data?.status) {
            setSubCategories1(data?.learning_centers)
          }
        }).catch((err) => {
        })
      } else if (video.category === "2") {
        setHideSub(true);
      } else if (video.category === "3") {
        setHideSub(false);
        setHideInning(false);
        GetAllTournaments().then((data) => {
          if (data?.status) {
            setSubCategories1(data?.tournaments)
          }
        }).catch((err) => {
        })
      }
      else if (video.category === "4") {
        setHideSub(true);
        setHideInning(true);
        setMessage(true);
      }
    }
  }, [video.category]);

  useEffect(() => {
    if (video.category !== undefined && video.sub_category1 !== undefined) {
      if (video.category === "1") {
        GetLearningCategories(video.sub_category1).then((data) => {
          if (data?.status) {
            setSubCategories2(data?.learning_categories)
          }
        }).catch((err) => {
        })
      } else if (video.category === "3") {
        GetAllMatchesByTournament(video.sub_category1).then((data) => {
          if (data?.status) {
            setSubCategories2(data?.match)
          }
        }).catch((err) => {
        })
      }
    }
  }, [video.sub_category1]);

  const handleChange = (e, name) => {
    setVideo({
      ...video,
      [name]: e.target.value,
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitVideo = (details) => {
    if (file == undefined) {
      setCustomErrors({ input: { type: "required" } })
      return
    }
    else {
      setCustomErrors(null)
    }
    setIsLoading(true);
    var formData = new FormData();
    formData.append("file", file);

    formData.append("title", details.title);
    formData.append("category", details.category);
    formData.append("sub_category1", details.sub_category1);
    formData.append("sub_category2", details.sub_category2);
    formData.append("inning", details.inning);
    formData.append("message", details.message);
    AddVideo(formData).then((data) => {
      setIsLoading(false);
      if (data?.status) {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Video added successfully'
        })
        setFile(undefined);
        reset();
      } else {
        Swal.fire({
          icon: 'error',
          title: '👎',
          text: 'Unable to add video'
        })
      }
    }).catch((err) => {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: '⛔',
        text: err
      })
    })
  };
  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }}>
          Video
        </h1>
      </div>
      <div className="p-5 h-screen">
        <div className="grid grid-cols-4 gap-5">
          <div></div>
          <div className=" col-span-2 ">
            <form onSubmit={handleSubmit(handleSubmitVideo)}>
              <div className="p-4 shadow-2xl bg-white">
                <h1
                  className="text-center m-2"
                  style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "18px" }}>
                  Add Video / Image
                </h1>
                <div className="pt-2">
                  <label
                    htmlFor="title"
                    className="form-label inline-block mb-2 text-gray-700">
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    {...register("title", {
                      required: true,
                      pattern: /^.*[a-zA-Z0-9]+.*$/
                    })}
                    placeholder="Video / Image Title"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" />
                  {errors.title && errors.title.type === "required" && (
                    <p className="errorMsg">Video Title is required.</p>
                  )}
                  {errors.title && errors.title.type === "pattern" && (
                    <p className="errorMsg">Video Title can't be blank</p>)}
                </div>
                <div className="pt-2">
                  <label
                    htmlFor="category"
                    className="form-label inline-block mb-2 text-gray-700">
                    Category
                  </label>
                  <select
                    {...register("category", {
                      required: true,
                      validate: () => (getValues("category") != "Select Category")
                    })}
                    id="category"
                    onChange={(e) => handleChange(e, "category")}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                    <option selected>Select Category</option>
                    {categories?.map((cat, index) => {
                      return (
                        <option value={cat?.id}>
                          {cat?.category}
                        </option>
                      );
                    })
                    }
                  </select>
                  {errors.category && errors.category.type === "required" && (
                    <p className="errorMsg">Category is required.</p>
                  )}
                  {errors.category && errors.category.type === "validate" && (
                    <p className="errorMsg">Category is required</p>
                  )}
                </div>
                {message ? (
                  <div className="pt-2">
                    <label
                      htmlFor="message"
                      className="form-label inline-block mb-2 text-gray-700">
                      Message
                    </label>
                    <textarea
                      id="message"
                      {...register("message", { required: true })}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" />
                    {errors.message && (
                      <p className="errorMsg">Message is required.</p>
                    )}
                  </div>
                ) : null}
                {!hideSub ?
                  <div className="pt-2">
                    <label
                      htmlFor="category"
                      className="form-label inline-block mb-2 text-gray-700">
                      Sub Category 1
                    </label>
                    <select
                      id="category"
                      {...register("sub_category1", {
                        required: true,
                        validate: () => (hideSub || (getValues("sub_category1") != "Select Sub Category 1"))
                      })}
                      onChange={(e) => handleChange(e, "sub_category1")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                      <option selected>Select Sub Category 1</option>
                      {subCategories1?.map((cat, index) => {
                        return (
                          <option value={cat?.id}>
                            {cat?.title !== undefined ? cat?.title : cat?.name}
                          </option>
                        );
                      })
                      }
                    </select>
                    {errors.sub_category1 && errors.sub_category1.type === "required" && (
                      <p className="errorMsg">Sub Category 1 is required.</p>
                    )}
                    {errors.sub_category1 && errors.sub_category1.type === "validate" && (
                      <p className="errorMsg">Sub Category 1 is required</p>
                    )}
                  </div>
                  : null}
                {!hideSub ?
                  <div className="pt-2">
                    <label
                      htmlFor="category"
                      className="form-label inline-block mb-2 text-gray-700" >
                      Sub Category 2
                    </label>
                    <select
                      id="category"
                      {...register("sub_category2", {
                        required: true,
                        validate: () => (hideSub || (getValues("sub_category2") != "Select Sub Category 2"))
                      })}
                      onChange={(e) => handleChange(e, "sub_category2")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                      <option selected>Select Sub Category 2</option>
                      {subCategories2?.map((cat, index) => {
                        return (
                          <option value={cat?.id}>
                            {cat?.title !== undefined ? cat?.title : cat?.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.sub_category2 && errors.sub_category2.type === "required" && (
                      <p className="errorMsg">Sub Category 2 is required.</p>
                    )}
                    {errors.sub_category2 && errors.sub_category2.type === "validate" && (
                      <p className="errorMsg">Sub Category 2 is required</p>)}
                  </div>
                  : null}
                {!hideInning ?
                  <div className="pt-2">
                    <label
                      htmlFor="inning"
                      className="form-label inline-block mb-2 text-gray-700">
                      Select Inning
                    </label>
                    <select
                      id="inning"
                      {...register("inning", {
                        required: true,
                        validate: () => (hideSub || (getValues("inning") != "Select Inning"))
                      })}
                      onChange={(e) => handleChange(e, "sub_category2")}
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                      <option selected>Select Inning</option>
                      <option value={"1"}>First Inning</option>
                      <option value={"2"}>Second Inning</option>
                    </select>
                  </div>
                  : null}
                <div className="pt-2">
                  <div className="flex items-center justify-center w-full" {...getRootProps()}>
                    <label
                      for="dropzone-file"
                      onClick={(e) => { e.stopPropagation() }}
                      className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                      <div className="flex flex-col items-center justify-center pt-3 pb-3">
                        {file !== undefined ? (
                          <span className="font-semibold text-base mb-3">{file?.name}</span>
                        ) : (
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                        )}
                        <p className="mb-2 text-sm text-gray-500">
                          <span className="font-semibold">Click to upload</span> or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">
                          JPEG, PNG, GIF, MP4, MMV, AVI, MOV or MKV (MAX. 1 GB)
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="video/*;"
                        onChange={handleFileSelect}
                        {...getInputProps()}
                        capture />
                    </label>
                    {customErrors != null && customErrors?.input && customErrors?.input?.type === "required" && (
                      <p className="errorMsg">Video is required.</p>
                    )}
                  </div>
                </div>
                <div className="pt-4">
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
                    variant="contained"
                    fullWidth>
                    Add Video / Image
                  </Button>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LoadingIndicator loading={isLoading} />
    </>
  );
};
export default Video;
