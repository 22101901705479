import React from "react";
import { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";


const Institutions = () => {
    const [headerTitle, setHeaderTitle] = useOutletContext();
    return (
        <Outlet context={[headerTitle, setHeaderTitle]} />
    )
}

export default Institutions