import React, { useEffect, useState, useMemo } from 'react';
import Pagination from '../../assets/js/pagination';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import Select from 'react-select';
import "./RoleAndAccess.css";
import {
    Button, CircularProgress,
} from "@mui/material";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { GetErrorLogAPI, GetControllerList, AddRole, GetAllRoles, EditRole, UpdateRole, getUserListRole, updateUserRoleAccess } from './Helper';
import { useForm } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import { useOutletContext, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ADMIN_MENU } from '../../utils/constant';

const RoleAndAccess = () => {
    const [editRoleId, setEditRoleId] = useState("");
    const [editAdminMenu, setEditAdminMenu] = useState(ADMIN_MENU);
    const [role_name, setrole_name] = useState('');
    const [editrole_name, seteditrole_name] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedEditItems, setCheckedEditItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousMatches, setPreviousMatches] = useState([]);
    const [subscriptiondata, setSubscriptionData] = useState([]);
    const [razorpayplan, setRazorpayplan] = useState([]);
    const [appcitydata, setappcitydata] = useState([]);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [razorpayid, setrazorpayid] = useState([]);
    const PageSize = 10;
    const [pageCount, setPageCount] = useState(1);

    const [headerTitle, setHeaderTitle] = useOutletContext();
    const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
    const [showAddTask, setShowAddTask] = React.useState(false);
    const [showAddPlan, setShowAddPlan] = React.useState(false);
    const [showedit, setshowedit] = React.useState(false);

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEditCity, setSelectedEditCity] = useState(null);
    const [errorLogData, setErrorLogData] = useState([]);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null)

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedController, setSelectedController] = useState('');
    const [menu_list, setMenu_list] = useState([]);

    const [controllerList, setControllerList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [editRoleList, setEditRoleList] = useState([]);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        setHeaderTitle("Role and Access");

        GetAllRoles()
            .then((data) => {
                setRoleList(data.role_list);
                setPageCount(data.role_list.length);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [currentPage, setHeaderTitle]);
    const filteredData = useMemo(() => {
        return roleList.filter((log) =>
            log.role_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [roleList, searchQuery, selectedController]);

    const currentTableData = useMemo(() => {
        const startIndex = (currentPage - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const slicedData = filteredData.slice(startIndex, endIndex);
        setPageCount(filteredData.length);
        return slicedData;
    }, [currentPage, PageSize, filteredData]);

    const handleRoleName = (e) => {
        setrole_name(e.target.value)
    }
    const handleEditRoleName = (e) => {
        seteditrole_name(e.target.value)
    }
    const handleAddCheck = (id, menuItem) => (e) => {
        if (checkedItems.includes(menuItem)) {
            setCheckedItems(checkedItems.filter((checkedItems) => checkedItems !== menuItem));
        } else {
            setCheckedItems([...checkedItems, menuItem]);
        }
    }
    const updateUserRole = (e, user_id) => {
        let update_data = {};
        update_data["admin_role_id"] = e.target.value;
        update_data["user_id"] = user_id;

        updateUserRoleAccess(update_data)
            .then((data) => {
                handleAssignRole();
                Swal.fire({
                    icon: 'success',
                    title: '🚀',
                    text: 'User role updated successfully'
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const saveaddRole = (e) => {
        if (role_name != '') {
            const lowercaseMenuList = checkedItems.map(menuItem => {
                return {
                    "main_menu": menuItem.MAIN_MENU.toLowerCase(),
                    "sub_menu": menuItem.SUB_MENU.toLowerCase(),
                    "unique_identifier": menuItem.UNIQUE_IDENTIFIER.toLowerCase()
                };
            });

            AddRole({
                'role_name': role_name,
                'menu_list': lowercaseMenuList,
            }).then((data) => {
                if (data?.isExist == true) {
                    Swal.fire({
                        icon: 'warning',
                        title: '🚀',
                        text: 'Role name is already exist'
                    });
                }
                else {
                    Swal.fire({
                        icon: 'success',
                        title: '🚀',
                        text: 'Role added successfully'
                    });
                    GetAllRoles()
                        .then((data) => {
                            setRoleList(data.role_list);
                            setPageCount(data.role_list.length);

                            setshowadd(false);
                            setCheckedItems([]);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: '⛔',
                    text: err
                })
            })
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: '⛔',
                text: 'Please enter role name'
            })
        }
    }
    const saveEditRole = (id) => (e) => {
        if (editrole_name != '') {
            let editNewMenuSubList = [];
            const lowercaseMenuList = editAdminMenu.map(menuItem => {
                if (menuItem.CHECKED == true) {
                    editNewMenuSubList.push({
                        "main_menu": menuItem.MAIN_MENU.toLowerCase(),
                        "sub_menu": menuItem.SUB_MENU.toLowerCase(),
                        "unique_identifier": menuItem.UNIQUE_IDENTIFIER.toLowerCase()
                    });
                    return {
                        "main_menu": menuItem.MAIN_MENU.toLowerCase(),
                        "sub_menu": menuItem.SUB_MENU.toLowerCase(),
                        "unique_identifier": menuItem.UNIQUE_IDENTIFIER.toLowerCase()
                    };
                } else {
                    return;
                }

            });
            UpdateRole({
                'id': editRoleId,
                'role_name': editrole_name,
                'menu_list': editNewMenuSubList,

            }).then((data) => {
                if (data?.isExist == true) {
                    Swal.fire({
                        icon: 'warning',
                        title: '🚀',
                        text: 'Role name is already exist'
                    });
                }
                else {
                    Swal.fire({
                        icon: 'success',
                        title: '🚀',
                        text: 'Role updated successfully'
                    });
                    setshowedit(false)
                    GetAllRoles()
                        .then((data) => {
                            setRoleList(data.role_list);
                            setPageCount(data.role_list.length);

                            setshowadd(false);
                            setCheckedItems([]);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: '⛔',
                    text: err
                })
            })
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: '⛔',
                text: 'Please enter role name'
            })
        }
    }
    const formatDate = (timestamp) => {
        if (!timestamp) return 'NA';

        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const handleReset = () => {
        setSearchQuery('');
        setSelectedController('');
    };
    const [showadd, setshowadd] = useState(false)
    const handleAdd = () => {
        setshowadd(true);
        setrole_name("");
    }

    const [showAssignRole, setShowAssignRole] = useState(false)
    const handleAssignRole = () => {
        getUserListRole()
            .then((data) => {
                setUserList(data.user_list);
                setShowAssignRole(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };
    const handleControllerChange = (event) => {
        setSelectedController(event.target.value);
        setCurrentPage(1);
    };
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };
    const handleEditCheck = (index, menuItem, newEditAdminMenu) => {

        let tempAdminMenu = [...newEditAdminMenu];
        tempAdminMenu[index]["CHECKED"] = !tempAdminMenu[index]["CHECKED"];
        setEditAdminMenu(tempAdminMenu);

    }

    const handleModalEditProgram = (id) => (e) => {
        setEditRoleId(id);
        const updatedAdminMenu = ADMIN_MENU.map(item => ({ ...item, CHECKED: false }));
        setEditAdminMenu(updatedAdminMenu);

        EditRole({ "id": id })
            .then((data) => {
                let tempAdminMenu = [...updatedAdminMenu];
                let new_format_list = [];
                if (data.role_detail.menu_list.length > 0) {
                    for (let i = 0; i < data.role_detail.menu_list.length; i++) {

                        var menu_unique_identifier = data.role_detail.menu_list[i].unique_identifier;
                        let admin_unique_identifier;
                        admin_unique_identifier = updatedAdminMenu.filter((item) => item.UNIQUE_IDENTIFIER === menu_unique_identifier);
                        if (admin_unique_identifier.length > 0) {
                            const index = updatedAdminMenu.findIndex((item) => item.UNIQUE_IDENTIFIER === menu_unique_identifier);

                            tempAdminMenu[index]["CHECKED"] = true;
                        }

                        new_format_list.push(admin_unique_identifier[0]);
                    }
                }
                setEditAdminMenu(tempAdminMenu);
                setEditRoleList(new_format_list);
                seteditrole_name(data.role_detail.role_name);
            })
            .catch((err) => {
                console.log(err);
            });

        setshowedit(true);
    };

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        return dateObject.toISOString().split('T')[0];
    }

    return (
        <>
            <div>
                <h1
                    className="text-center m-2"
                    style={{
                        color: "#000", backgroundColor: "#0df6e3",
                        fontSize: "18px",
                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                    }}
                >
                    Role and Access
                </h1>
            </div>

            <div style={{ textAlign: 'center' }}>
                {loading ? (
                    <>
                        <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>
                            Please Wait Data is{" "}
                            <span style={{ color: 'orange', fontWeight: '600' }}>
                                Loading...
                            </span>
                        </h2>
                    </>
                ) : (
                    <>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search by Role Name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-input"
                            />
                            <div >
                                <button type="button" style={{ marginBottom: '2px', backgroundColor: '#00efdc', color: 'black' }} onClick={handleReset} className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <RefreshIcon /> Reset
                                </button>
                                <button type="button" style={{
                                    marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', textAlign: 'end', position: 'fixed',
                                    right: '20px'
                                }} onClick={handleAdd} className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <AddIcon /> Add
                                </button>

                                <button type="button" style={{
                                    marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', textAlign: 'end', position: 'fixed',
                                    right: '110px'
                                }} onClick={handleAssignRole} className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <AddIcon /> Assign Role
                                </button>
                            </div>
                        </div>
                        <table className="custom-row-colors" style={{ borderCollapse: 'collapse', width: '100%', marginTop: "12px" }}>
                            <thead>
                                <tr>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Sr. No.</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Role Name</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Created</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Modified</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Action</th>
                                </tr>
                            </thead>
                            {currentTableData.length === 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'red', fontWeight: '600' }}>Data not found</p>
                                    </td>
                                </tr>
                            ) : (
                                <tbody>
                                    {currentTableData.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='dataTd'>{((currentPage - 1) * 10) + (index + 1)}</td>
                                            <td className='dataTd'>{item.role_name}</td>
                                            <td className='dataTd'>{formatDateString(item.createdAt)}</td>
                                            <td className='dataTd'>{formatDateString(item.updatedAt)}</td>
                                            <td className='dataTd'>
                                                <Button title="View" onClick={handleModalEditProgram(item.id)}>
                                                    <EditIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={pageCount}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                        {/* edit modal */}
                        <form >
                            {showedit ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%', overflow: 'auto' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                <div

                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >

                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        Edit Role
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'black' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setshowedit(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>
                                                <div style={{ margin: '18px' }}>
                                                    <div className="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div className="flex flex-row" style={{ placeItems: 'baseline', marginBottom: '14px' }}>
                                                            <span style={{ color: 'black', fontWeight: '600', marginRight: '20px' }}>Role Name<span style={{ color: '#FF0000' }}>*</span>:</span>
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                <input type="text" name="price" id="price" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6" placeholder="Role Name" value={editrole_name} onChange={handleEditRoleName} />

                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div style={{ height: '460px', overflowY: 'scroll' }}>
                                                        <table className="table-auto">
                                                            <thead style={{ backgroundColor: '#1f242cd4', color: 'white', overflow: 'auto' }}>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Main Module</th>
                                                                    <th>Sub Module</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {editAdminMenu.map((menuItem, index) => (
                                                                    <>
                                                                        <tr key={index} >
                                                                            <td>{index + 1}</td>
                                                                            <td>{menuItem.MAIN_MENU}</td>
                                                                            <td>{menuItem.SUB_MENU}</td>
                                                                            <td>

                                                                                <input type="checkbox" name="" id="isChecked"
                                                                                    checked={menuItem.CHECKED}
                                                                                    onChange={() => handleEditCheck(index, menuItem, editAdminMenu)}
                                                                                />

                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                    <button
                                                        className="bg-blue-500 text-white py-1 px-3 rounded mr-2"
                                                        type="button"
                                                        onClick={saveEditRole(editRoleList.id)}
                                                    >
                                                        Update
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>
                        {/* //Add modal */}
                        <form >
                            {showadd ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%', overflow: 'auto' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                                <div

                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >

                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        Add Role
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'black' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setshowadd(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>
                                                <div style={{ margin: '18px' }}>
                                                    <div className="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div className="flex flex-row" style={{ placeItems: 'baseline', marginBottom: '14px' }}>
                                                            <span style={{ color: 'black', fontWeight: '600', marginRight: '20px' }}>Role Name<span style={{ color: '#FF0000' }}>*</span>:</span>
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                <input type="text" name="price" id="price" className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black-600 sm:text-sm sm:leading-6" placeholder="Role Name" onChange={handleRoleName} />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div style={{ height: '460px', overflowY: 'scroll' }}>
                                                        <table className="">
                                                            <thead style={{ backgroundColor: '#1f242cd4', color: 'white' }}>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Main Module</th>
                                                                    <th>Sub Module</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ADMIN_MENU.map((menuItem, index) => (
                                                                    <tr key={index} >
                                                                        <td>{index + 1}</td>
                                                                        <td>{menuItem.MAIN_MENU}</td>
                                                                        <td>{menuItem.SUB_MENU}</td>
                                                                        <td>
                                                                            <input type="checkbox" name="" id="isChecked" onClick={handleAddCheck(index + 1, menuItem)} />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">

                                                    <button
                                                        className="bg-blue-500 text-white py-1 px-3 rounded mr-2"
                                                        type="button"
                                                        onClick={saveaddRole}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>

                        {/* //Assign Role modal */}
                        <form >
                            {showAssignRole ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%', overflow: 'auto' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                                <div

                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >


                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        Assign Role
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'black' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setShowAssignRole(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>
                                                <div style={{ margin: '18px' }}>
                                                    <div style={{ height: '460px', overflowY: 'scroll' }}>
                                                        <table className="">
                                                            <thead style={{ backgroundColor: '#1f242cd4', color: 'white' }}>
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Full Name</th>
                                                                    <th>Email</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {userList.map((menuItem, index) => (
                                                                    <tr key={index} >
                                                                        <td>{index + 1}</td>
                                                                        <td>{menuItem.name}</td>
                                                                        <td>{menuItem.email}</td>
                                                                        <td>
                                                                            <select
                                                                                value={menuItem.admin_role_id}
                                                                                id="type"
                                                                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"

                                                                                onChange={(e) => updateUserRole(e, menuItem.id)}
                                                                            >
                                                                                <option value="Select Role">
                                                                                    Select Role
                                                                                </option>
                                                                                {roleList.map((type, id) => (
                                                                                    <option value={type.id} key={id}>
                                                                                        {type.role_name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>
                    </>
                )}
            </div>
        </>
    );
}
export default RoleAndAccess;
