import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { memo } from "react"
/**
 * Dialog for video player
 * @param {object} { videoInfo: { src, fileName }, handleClose } 
 * @returns 
 */
const VideoPlayerDialog = ({ videoInfo: { src, fileName }, handleClose }) => {
    return (
        <Dialog onClose={handleClose} open={Boolean(src)}>
            <DialogTitle>{`Playing Video ${fileName}`}</DialogTitle>
            <DialogContent dividers className="self-center">
                <video controls autoPlay style={{ maxHeight: '700px' }}>
                    <source src={src} type="video/mp4" />
                </video>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(VideoPlayerDialog);