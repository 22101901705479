import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Creatable from 'react-select/creatable';

const MatchDetailsTable = () => {
    const [uniqueMatches, setUniqueMatches] = useState([]);
    const [selectedMatchId, setSelectedMatchId] = useState(null);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [newVideoSubscribersList, setNewVideoSubscribersList] = useState([]);
    const [newVideoSubscribersList1, setNewVideoSubscribersList1] = useState([]);
    const [newVideoSubscribersList2, setNewVideoSubscribersList2] = useState([]);
    const [existingUsersList, setExistingUsersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [teamId1, setTeamId1] = useState(null);
    const [teamId2, setTeamId2] = useState(null);
    const [allUsersOptions, setAllUsersOptions] = useState([]);
    const [newVideoSubscribersListAll, setNewVideoSubscribersListAll] = useState([]);


    const [matchDetails, setMatchDetails] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/get-all_matches-details`)
            .then((response) => {
                setMatchDetails(response.data.matches);
            })
            .catch((error) => {
                console.error('Error fetching match details:', error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/get-match-details`)
            .then((response) => {
                const uniqueMatches = Array.from(new Set(response.data.map((match) => match.match_id)))
                    .map((matchId) => response.data.find((match) => match.match_id === matchId));
                // setAllMatches(response.data);
                setUniqueMatches(uniqueMatches);

                const numbers = Array.from({ length: uniqueMatches.length }, (_, index) => index + 1);
                setSerialNumbers(numbers);

                setFilteredMatches(uniqueMatches);
            })
            .catch((error) => {
                console.error('Error fetching match details:', error);
            });
    }, []);

    useEffect(() => {
        // Fetch existing users list
        const fetchExistingUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-video-access`);
                setExistingUsersList(response.data);
            } catch (error) {
                console.error('Error fetching existing users:', error);
            }
        };

        fetchExistingUsers();
    }, []);

    // const matchDetails = useMatchDetails();

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/get-all-users`)
            .then((response) => {
                const usersOptions = response.data.map((user) => ({
                    value: user.id,
                    label: user.full_name
                }));
                setAllUsersOptions(usersOptions);
            })
            .catch((error) => {
                console.error('Error fetching all users:', error);
            });
    }, []);

    const handleRowClick = async (matchId) => {
        console.log('Row clicked. Match ID:', matchId);
        setSelectedMatchId(matchId);

        try {
            // Get user_ids of preselected users for Team 1
            const preselectedUsersTeam1Ids = existingUsersList
                .filter((user) => user.match_id === matchId && user.is_subscriber === 1)
                .map((user) => user.user_id);

            // Map user data from allUsersOptions to the desired format for Team 1
            const mappedUsersTeam1 = allUsersOptions.filter(user => preselectedUsersTeam1Ids.includes(user.value));

            console.log('Preselected users for Team 1:', mappedUsersTeam1);

            // Set state for Team 1 subscribers list
            setNewVideoSubscribersList1(mappedUsersTeam1);
            setNewVideoSubscribersList2(mappedUsersTeam1);
            setNewVideoSubscribersListAll(mappedUsersTeam1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        // Reset state for other teams
        setNewVideoSubscribersList([]);
        toast.success(`Match ID ${matchId} selected!`);
    };


    const updateMatchVideoAccess = async () => {
        try {
            setLoading(true);

            // Combine users from all three sources
            const addedVideoUsersTeam1 = newVideoSubscribersList1.map((subscriber) => subscriber.value);
            const addedVideoUsersTeam2 = newVideoSubscribersList2.map((subscriber) => subscriber.value);
            const addedVideoUsersAll = [
                ...newVideoSubscribersList.map((subscriber) => subscriber.value),
                ...newVideoSubscribersListAll.map((viewer) => viewer.value),
            ];

            // Filter out users for Team 1
            const addedVideoUsersTeam1Filtered = addedVideoUsersTeam1.filter(
                (user) => !existingUsersList.some(
                    (existingUser) => existingUser.user_id === user && existingUser.matchId === selectedMatchId
                )
            );

            // Filter out users for Team 2
            const addedVideoUsersTeam2Filtered = addedVideoUsersTeam2.filter(
                (user) => !existingUsersList.some(
                    (existingUser) => existingUser.user_id === user && existingUser.matchId === selectedMatchId
                )
            );

            // Filter out users for All
            const addedVideoUsersAllFiltered = addedVideoUsersAll.filter(
                (user) => !existingUsersList.some(
                    (existingUser) => existingUser.user_id === user && existingUser.matchId === selectedMatchId
                )
            );

            // Identify removed users for Team 1
            const removedUsersTeam1 = existingUsersList
                .filter((existingUser) => !addedVideoUsersTeam1.includes(existingUser.user_id))
                .map((user) => user.user_id);

            // Identify removed users for Team 2
            const removedUsersTeam2 = existingUsersList
                .filter((existingUser) => !addedVideoUsersTeam2.includes(existingUser.user_id))
                .map((user) => user.user_id);

            // Identify removed users for All
            const removedUsersAll = existingUsersList
                .filter((existingUser) => !addedVideoUsersAll.includes(existingUser.user_id))
                .map((user) => user.user_id);

            const uniqueRemovedUsers = Array.from(new Set([...removedUsersTeam1, ...removedUsersTeam2, ...removedUsersAll]));

            // Combine added and removed users
            const data = {
                match_id: selectedMatchId,
                addedVideoUsers: [...addedVideoUsersTeam1Filtered, ...addedVideoUsersTeam2Filtered, ...addedVideoUsersAllFiltered],
                removedVideoUsers: uniqueRemovedUsers,
            };

            if (data.match_id && data.addedVideoUsers.length > 0) {
                const res = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/updateMatchVideoAccess`,
                    data,
                );

                toast.success(`Update successful!`);
            } else {
                toast.warn(`Update unsuccessful!`);
            }
        } catch (error) {
            console.error('Error updating match video access:', error);
            toast.error('Failed to update match video access.');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(searchText);

        if (searchText === '') {
            setFilteredMatches(uniqueMatches);
        } else {
            const filtered = uniqueMatches.filter((match) =>
                matchDetails.find((detail) => detail.id === match.match_id)?.name.toLowerCase().includes(searchText)
            );
            setFilteredMatches(filtered);
        }
    };

    useEffect(() => {
        if (selectedMatchId) {
            const selectedMatch = matchDetails.find((match) => match.id === selectedMatchId);
            const teamId1 = selectedMatch?.team1Id;
            const teamId2 = selectedMatch?.team2Id;

            setTeamId1(teamId1);
            setTeamId2(teamId2);
        }
    }, [selectedMatchId, matchDetails]);


    const [teamMembersTeam1, setTeamMembersTeam1] = useState([]);
    const [teamMembersTeam2, setTeamMembersTeam2] = useState([]);

    useEffect(() => {
        const fetchTeamMembersWithDetails = async (teamId, setTeamMembers) => {
            try {
                // const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-teammembers-by-team/${teamId}`,
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-teammembers-by-team/${teamId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
                    },
                });
                const teamMembersWithDetails = response.data.all_team_members_with_details;
                const teamMembersNames = response.data.team_members;

                // Map team members with details to an array of objects
                const teamMembersArray = teamMembersNames.map((name, index) => ({
                    id: teamMembersWithDetails[index].id,
                    name: name,
                    batting: teamMembersWithDetails[index].batting,
                    bowling: teamMembersWithDetails[index].bowling,
                }));

                setTeamMembers(teamMembersArray);
            } catch (error) {
                console.error(`Error fetching team members for team ${teamId}:`, error);
            }
        };

        if (teamId1) {
            fetchTeamMembersWithDetails(teamId1, setTeamMembersTeam1);
        }

        if (teamId2) {
            fetchTeamMembersWithDetails(teamId2, setTeamMembersTeam2);
        }

    }, [teamId1, teamId2]);


    const handleTeamSelection = (teamId) => {
        if (teamId === 'team1') {
            setNewVideoSubscribersList(teamMembersTeam1.map((member) => ({ value: member.id, label: member.name })));
        } else if (teamId === 'team2') {
            setNewVideoSubscribersList(teamMembersTeam2.map((member) => ({ value: member.id, label: member.name })));
        } else {
            setNewVideoSubscribersList([]);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <h2 className="text-2xl font-bold mb-4">Video Access To Users </h2>
            <div className="mb-4">
                <label htmlFor="filterInput" className="mr-2">
                    Filter by Match Name:
                </label>
                <input
                    type="text"
                    id="filterInput"
                    value={filterText}
                    onChange={handleFilterChange}
                    className="border border-gray-500 p-2"
                />
            </div>
            <div className="max-h-96 overflow-y-auto border border-blue-500 rounded">
                <table className="w-full border-collapse border-2 border-blue-500">

                    <thead>
                        <tr>
                            <th className="border border-gray-500 p-2 sticky top-0 bg-white z-10">Serial Number</th>
                            <th className="border border-gray-500 p-2 sticky top-0 bg-white z-10">Match ID</th>
                            <th className="border border-gray-500 p-2 sticky top-0 bg-white z-10">Tournament ID</th>
                            <th className="border border-gray-500 p-2 sticky top-0 bg-white z-10">Match Name</th>
                        </tr>
                    </thead>

                    <tbody className={`cursor-pointer ${selectedMatchId ? 'bg-blue-200' : 'bg-white'} !important`}>
                        {filteredMatches.map((match, index) => (
                            <tr
                                key={match.match_id}
                                onClick={() => handleRowClick(match.match_id)}
                                className={`cursor-pointer ${selectedMatchId === match.match_id ? 'bg-blue-200' : 'bg-white'} !important`}
                            >
                                <td className="border border-gray-500 p-2">{serialNumbers[index]}</td>
                                <td className="border border-gray-500 p-2">{match.match_id}</td>
                                <td className="border border-gray-500 p-2">{match.tournament_id}</td>
                                <td className="border border-gray-500 p-2">
                                    {matchDetails.find((detail) => detail.id === match.match_id)?.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            <div className="mt-4">
                <div className="col-span-5">Video Subscribers:</div>
                <Creatable
                    options={teamMembersTeam1.map((member) => ({ value: member.id, label: member.name }))}
                    isMulti
                    isSearchable
                    isClearable
                    placeholder="Select video subscribers for team 1"
                    onChange={(selectedOption) => setNewVideoSubscribersList1(selectedOption)}
                    value={newVideoSubscribersList1}
                    className="mt-2"
                />
                <Creatable
                    options={teamMembersTeam2.map((member) => ({ value: member.id, label: member.name }))}
                    isMulti
                    isSearchable
                    isClearable
                    placeholder="Select video subscribers for team 2"
                    onChange={(selectedOption) => setNewVideoSubscribersList2(selectedOption)}
                    value={newVideoSubscribersList2}
                    className="mt-2"
                />
                <Creatable
                    options={[
                        { value: 'team1', label: 'Whole Team 1' },
                        { value: 'team2', label: 'Whole Team 2' },
                    ]}
                    isMulti
                    isSearchable
                    isClearable
                    placeholder="Select video subscribers..."
                    onChange={(selectedOption) => {
                        if (selectedOption && selectedOption.length === 1 && ['team1', 'team2'].includes(selectedOption[0].value)) {
                            handleTeamSelection(selectedOption[0].value);
                        } else {
                            setNewVideoSubscribersList(selectedOption);
                        }
                    }}
                    value={newVideoSubscribersList}
                    className="mt-2"
                />

                <Creatable
                    options={allUsersOptions}
                    isMulti
                    isSearchable
                    isClearable
                    placeholder="Select video subscribers for all users"
                    onChange={(selectedOption) => setNewVideoSubscribersListAll(selectedOption)}
                    value={newVideoSubscribersListAll}
                    className="mt-2"
                />



            </div>
            <section className="flex justify-center mt-4">
                <button
                    className={`p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 shadow-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={updateMatchVideoAccess}
                    disabled={loading}
                >
                    {loading ? 'Updating...' : 'Update Access'}
                </button>
            </section>


        </div>
    );
};

export default MatchDetailsTable;
