import React from "react";
import ActivePlansHeader from "./Components/ActivePlansHeader";
import ActivePlansList from "./Components/ActivePlansList";
const ActivePlans = ({
  selectedPlayerIndex,
  loggedInUserInstitutionID,
  addedNewSubscriptionReload,
  isActiveSubscriptionListLoading,
  setIsActiveSubscriptionListLoading,
  isActiveSubscriptionList,
  setIsActiveSubscriptionList,
}) => {
  return (
    <div className="border border-black">
      <ActivePlansHeader />
      <ActivePlansList
        selectedPlayerIndex={selectedPlayerIndex}
        loggedInUserInstitutionID={loggedInUserInstitutionID}
        addedNewSubscriptionReload={addedNewSubscriptionReload}
        isActiveSubscriptionListLoading={isActiveSubscriptionListLoading}
        setIsActiveSubscriptionListLoading={setIsActiveSubscriptionListLoading}
        isActiveSubscriptionList={isActiveSubscriptionList}
        setIsActiveSubscriptionList={setIsActiveSubscriptionList}
      />
    </div>
  );
};

export default ActivePlans;
