import axios from "axios";

export const getInstitutionVideoList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-institution-video-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//controller file list

export const GetTypeofErrorList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-controller-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateInstitutionVideoStatus = async (practice_video) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-institution-video-status`,
      practice_video,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const updateAppUserBlockedStatus = async (app_user_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-app-user-blocked-status`,
      app_user_detail,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};