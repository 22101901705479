import axios from 'axios';

export const isLoggedIn = () => {
	if (localStorage.getItem('WellplayedToken') !== null) {
		return true;
	} else {
		return false;
	}
};

export const login = (creds) => {
	if (creds !== null || undefined) {
		localStorage.setItem('WellplayedToken', JSON.stringify(creds));
		return true;
	} else {
		return false;
	}
};

export const logout = () => {
	if (localStorage.getItem('WellplayedToken') !== null) {
		localStorage.removeItem('WellplayedToken');
	}
};
	
/**
 *
 * @param {{email: string, password: string}} data - userId and password
 */
export const userLogin = (data) => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}/signin`, data, {
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

/**
 *
 * @param {string} userId - email/phone number
 */
export const forgotPassword = (userId) => {
	return axios.post(
		`${process.env.REACT_APP_BASE_URL}/forgotpassword`,
		{
			userId: userId,
		},
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
};

/**
 *
 * @param {{password: string, confirmPassword: string}} data - password and confirmPassword
 */
export const changePassword = (data, token) => {
	return axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/changepassword`, data, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
};

export const userLogout = () => {
	localStorage.removeItem('WellplayedToken');
};


export const getUserRoleAccessSidebar = async (user_id) => {
	try {
	  const res = await axios.post(
		`${process.env.REACT_APP_BASE_URL}/get-user-role-access-sidebar`,
		{user_id},
		{
		  headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${
			  JSON.parse(localStorage.getItem("WellplayedToken")).token
			}`,
		  },
		}
	  );
	  return res.data;
	} catch (err) {
	  console.log(err);
	}
  };
