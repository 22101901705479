import React from 'react'
import { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Button, FormControl, FormControlLabel, RadioGroup, Radio, ButtonGroup, IconButton, Typography, ListItem, ListItemText, List, CircularProgress, Checkbox } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  GetActivityCategory, GetFocusArea, GetFocusAreaSubCategory, GetEvaluationBy, GetTaskPayment, AddNewTask, AddFocusArea, AddFocusAreaSubCategory, AddEvaluationBy, GetCoachList, AddPayment,
  GetTaskList, GetTaskIdDetails, UpdateTask, GetProgramList, GetProgramIdDetails, AddNewTaskPlanning, UpdateTaskPlanning, Teams, Players, GetProgramNameList, DeleteTasks, previewDeleteTaskPlanning, DeleteProgram,
  MakeDuplicateProgram
} from "./Helper";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import PublishIcon from '@mui/icons-material/Publish';
import { useForm } from "react-hook-form";

const TaskPlanner = () => {
  const { register, handleSubmit,
    formState: { errors },
    getValues, reset,
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [showAddTask, setShowAddTask] = React.useState(false);
  const [stateChange, setStateChange] = React.useState(false);
  const [showEditTask, setShowEditTask] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileEdit, setSelectedFileEdit] = useState(null);
  const [editVideoUrl, setEditVideoUrl] = useState("");
  const [addRepeatations, setAddRepeatations] = useState(false);
  const [task_duration, setTask_duration] = useState(0);
  const [task_repeat, setTask_repeat] = useState(0);
  const [showDeleteTask, setShowDeleteTask] = React.useState(false);
  const [showDeleteProgram, setShowDeleteProgram] = React.useState(false);
  const [changeStatus, setChangeStatus] = React.useState(false);
  const handleRepeatations = (task_detail) => (event) => {
    event.preventDefault(); // Prevents the form from submitting
    setTask_duration(task_detail)
    setTask_repeat(task_detail.task_repeat)
    setAddRepeatations(true)
  }
  const [deleteProgramList, setDeleteProgramList] = useState([]);
  const [deleteTransactionList, setdeleteTransactionList] = useState([]);
  const [deletePlayerCompletedList, setdeletePlayerCompletedList] = useState([]);
  const capitalizeFirstLetter = (str) => {
    if (!str) return "N/A";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  const handleModalDeleteTask = (e) => {
    e.preventDefault();
    if (selectedTaskId === "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select a task for delete'
      });
    } else {
      let task_detail = {};
      task_detail["task_id"] = selectedTaskId;
      GetTaskIdDetails(task_detail)
        .then((task_data) => {
          setEditTaskName(task_data.querydata.taskName);
          GetProgramNameList(task_detail)
            .then((programList) => {
              setDeleteProgramList(programList.querydata)
              setdeleteTransactionList(programList.task_planning_transaction)
              setdeletePlayerCompletedList(programList.task_program_submited)
            })
            .catch((error) => {
            });
          setShowDeleteTask(true);
        })
    }
  };
  const handleDeleteProgramSubmit = (details) => {
    let evaluation_by_type = selectedEvaluationBy === "" ? "well_played" : "coach";
    let evaluation_by_id = selectedEvaluationBy === "" ? "" : selectedEvaluationBy;
    let task_detail = {
      task_planning_id: selectedProgramId
    };
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgram(task_detail)
          .then((task_detail_data) => {
            Swal.fire('Deleted!', 'Program deleted successfully', 'success'
            );
            setSelectedProgramId("")
            setShowAddTask(false);
            setShowDeleteProgram(false);
            GetTaskList([])
              .then((task_data) => {
                setTaskList(task_data.querydata);
              });
            GetProgramList([])
              .then((program_data) => {
                let program_list = program_data.querydata;
                if (program_list.length > 0) {
                  for (var i = 0; i < program_list.length; i++) {
                    let task_duration = 0;
                    let task_difficulty_number = 0;
                    let task_difficulty_count = 0;
                    let focus_area_detail = program_list[i]["focus_area_detail"];
                    if (focus_area_detail.length > 0) {
                      for (var j = 0; j < focus_area_detail.length; j++) {
                        let task_list = focus_area_detail[j]["task_list"];
                        task_difficulty_count = task_difficulty_count + (task_list.length);
                        if (task_list.length > 0) {
                          for (var k = 0; k < task_list.length; k++) {
                            if (task_list[k]["duration_type"] == "Y") {
                              task_duration = task_duration + (task_list[k]["duration"] * 365)
                            }
                            else if (task_list[k]["duration_type"] == "M") {
                              task_duration = task_duration + (task_list[k]["duration"] * 30)
                            }
                            else {
                              task_duration = task_duration + (task_list[k]["duration"]);
                            }
                            if (task_list[k]["difficulty"] == "easier") {
                              task_difficulty_number = task_difficulty_number + 1;
                            }
                            else if (task_list[k]["difficulty"] == "easy") {
                              task_difficulty_number = task_difficulty_number + 2;
                            }
                            else if (task_list[k]["difficulty"] == "medium") {
                              task_difficulty_number = task_difficulty_number + 3;
                            }
                            else if (task_list[k]["difficulty"] == "hard") {
                              task_difficulty_number = task_difficulty_number + 4;
                            }
                            else if (task_list[k]["difficulty"] == "harder") {
                              task_difficulty_number = task_difficulty_number + 5;
                            }
                          }
                        }
                      }
                    }
                    let average_difficulty = 0;
                    average_difficulty = task_difficulty_number / task_difficulty_count;
                    average_difficulty = Math.round(average_difficulty);
                    program_list[i]["average_difficulty"] = average_difficulty;
                    program_list[i]["task_duration"] = task_duration;
                  }
                }
                setProgramList(program_data.querydata);
              });
          })
          .catch((error) => {
            Swal.fire('Error', 'Something went wrong while deleting the task', 'error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your program is safe :)', 'error');
        setShowDeleteProgram(false);
      }
    });
  }
  const handleDeleteTaskSubmit = (details) => {
    let evaluation_by_type = selectedEvaluationBy === "" ? "well_played" : "coach";
    let evaluation_by_id = selectedEvaluationBy === "" ? "" : selectedEvaluationBy;
    let task_detail = { task_id: selectedTaskId };
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteTasks(task_detail)
          .then((task_detail_data) => {
            Swal.fire('Deleted!', 'Task deleted successfully', 'success');
            setSelectedTaskId("");
            setShowAddTask(false);
            setShowDeleteTask(false);
            GetTaskList([])
              .then((task_data) => {
                setTaskList(task_data.querydata);
              });
            GetProgramList([])
              .then((program_data) => {
                let program_list = program_data.querydata;
                if (program_list.length > 0) {
                  for (var i = 0; i < program_list.length; i++) {
                    let task_duration = 0;
                    let task_difficulty_number = 0;
                    let task_difficulty_count = 0;
                    let focus_area_detail = program_list[i]["focus_area_detail"];
                    if (focus_area_detail.length > 0) {
                      for (var j = 0; j < focus_area_detail.length; j++) {
                        let task_list = focus_area_detail[j]["task_list"];
                        task_difficulty_count = task_difficulty_count + (task_list.length);
                        if (task_list.length > 0) {
                          for (var k = 0; k < task_list.length; k++) {
                            if (task_list[k]["duration_type"] == "Y") {
                              task_duration = task_duration + (task_list[k]["duration"] * 365)
                            }
                            else if (task_list[k]["duration_type"] == "M") {
                              task_duration = task_duration + (task_list[k]["duration"] * 30)
                            }
                            else {
                              task_duration = task_duration + (task_list[k]["duration"]);
                            }
                            if (task_list[k]["difficulty"] == "easier") {
                              task_difficulty_number = task_difficulty_number + 1;
                            }
                            else if (task_list[k]["difficulty"] == "easy") {
                              task_difficulty_number = task_difficulty_number + 2;
                            }
                            else if (task_list[k]["difficulty"] == "medium") {
                              task_difficulty_number = task_difficulty_number + 3;
                            }
                            else if (task_list[k]["difficulty"] == "hard") {
                              task_difficulty_number = task_difficulty_number + 4;
                            }
                            else if (task_list[k]["difficulty"] == "harder") {
                              task_difficulty_number = task_difficulty_number + 5;
                            }
                          }
                        }
                      }
                    }
                    let average_difficulty = 0;
                    average_difficulty = task_difficulty_number / task_difficulty_count;
                    average_difficulty = Math.round(average_difficulty);
                    program_list[i]["average_difficulty"] = average_difficulty;
                    program_list[i]["task_duration"] = task_duration;
                  }
                }
                setProgramList(program_data.querydata);
              });
          })
          .catch((error) => {
            Swal.fire('Error', 'Something went wrong while deleting the task', 'error'
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your task is safe :)',
          'error'
        );
      }
    });
  }
  const [previewdeleteProgramList, setPreviewDeleteProgramList] = useState([]);
  const [previewProgramActivePlayerList, setpreviewProgramActivePlayerList] = useState([]);
  const [previewTaskPlanningPlayerList, setpreviewTaskPlanningPlayerList] = useState([]);
  const [previewTaskPlanningTeamList, setpreviewTaskPlanningTeamList] = useState([]);
  const [previewTaskPlanningTransactionList, setpreviewTaskPlanningTransactionList] = useState([]);
  const [previewTaskProgramSubmitedList, setpreviewTaskProgramSubmitedList] = useState([]);
  const handleModalDeleteProgram = (e) => {
    e.preventDefault();
    if (selectedProgramId == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select program for delete '
      })
    }
    else {
      let program_detail = {};
      program_detail["program_id"] = selectedProgramId;
      GetProgramIdDetails(program_detail)
        .then((program_data) => {
          let program_detail = program_data.querydata;
          setEditProgramName(program_detail.planningName);
          let previewTask = { "task_planning_id": selectedProgramId }
          previewDeleteTaskPlanning(previewTask) // Pass the selectedTaskId as the argument here
            .then((previewprogramList) => {
              setPreviewDeleteProgramList(previewprogramList.querydata.task_list)
              setpreviewProgramActivePlayerList(previewprogramList.querydata.task_planning_active_players_list)
              setpreviewTaskPlanningPlayerList(previewprogramList.querydata.task_planning_player_list)
              setpreviewTaskPlanningTeamList(previewprogramList.querydata.task_planning_team_list)
              setpreviewTaskPlanningTransactionList(previewprogramList.querydata.task_planning_transaction_list)
              setpreviewTaskProgramSubmitedList(previewprogramList.querydata.task_program_submited_list)
            })
            .catch((error) => {
            });
          setShowDeleteProgram(true)
        });
    }
  }
  const handleRepeatationInput = (e) => {
    setTask_repeat(e.target.value)
  }
  const handleAddRepeatationSubmit = (task_duration) => {
    task_duration.repeat_task = task_repeat;
    let addProgramFocusListCommon = addProgramFocusList;
    let focus_area_detail = [];
    let task_list = [];
    let check = 0, i = 0;
    if (addProgramFocusListCommon.length > 0) {
      for (i = 0; i < addProgramFocusListCommon.length; i++) {
        if (addProgramFocusListCommon[i]["id"] == task_duration.focus_area_id) {
          let is_task_available = 0;
          for (let j = 0; j < addProgramFocusListCommon[i]["task_list"].length; j++) {
            if (task_duration["id"] == addProgramFocusListCommon[i]["task_list"][j]["id"]) {
              addProgramFocusListCommon[i]["task_list"][j]["task_repeat"] = task_repeat;
              is_task_available++;
            }
          }
        }
      }
    }

    setAddProgramFocusList(addProgramFocusListCommon);
    setStateChange(!stateChange);
    setAddRepeatations(false)
  }
  const [EditTaskName, setEditTaskName] = React.useState("");
  const [EditTaskDifficulty, setEditTaskDifficulty] = React.useState("");
  const [EditTaskDurationType, setEditTaskDurationType] = React.useState("");
  const handleChangeEditTaskDifficulty = (event) => {
    setEditTaskDifficulty(event.target.value);
  };
  const handleChangeEditTaskDurationType = (event) => {
    setEditTaskDurationType(event.target.value);
  };
  const [EditTaskDuration, setEditTaskDuration] = React.useState("");
  const [EditTaskDescription, setEditTaskDescription] = React.useState("");
  const [EditTaskResourceUrl, setEditTaskResourceUrl] = React.useState("");
  const [showAddPayment, setShowAddPayment] = React.useState(false);
  const [coachList, setCoachList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);
  const [programList, setProgramList] = React.useState([]);
  const [addProgramFocusList, setAddProgramFocusList] = useState([]);
  const [editProgramFocusList, setEditProgramFocusList] = useState([]);
  localStorage.setItem("addProgramFocusList", JSON.stringify([]));
  const [showAddFocusArea, setShowAddFocusArea] = React.useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const handleSelectTask = (task_id) => (e) => {
    setSelectedTaskId(task_id);
  };
  const [showFocusDetail, setShowFocusDetail] = useState('');
  const handleShowFocus = (focus_id) => (e) => {
    setShowFocusDetail(focus_id);
  };
  const [selectedProgramId, setSelectedProgramId] = useState('');
  const handleSelectProgram = (program_id) => (e) => {
    setSelectedProgramId(program_id);
    setColumnSecond(false);
    setColumnEditPlanning(false);
  };
  const [columnSecond, setColumnSecond] = useState(false);
  const [columnEditPlanning, setColumnEditPlanning] = useState(false);
  const [editProgramName, setEditProgramName] = useState("");
  const [editProgramFromDate, setEditProgramFromDate] = useState("");
  const [editProgramToDate, setEditProgramToDate] = useState("");
  const [program_visibility, setProgram_visibility] = useState(false);
  const toggleShowIsvisible = () => setProgram_visibility(!program_visibility);
  const handleResetAddColumn2Submit = (e) => {
    reset()
    setColumnSecond(false);
    setColumnEditPlanning(false);
  }
  const [addProgramFocusListNew, setAddProgramFocusListNew] = useState([]);
  const [addProgromSaveClicked, setAddProgromSaveClicked] = useState();
  const [addProgromPublishClicked, setAddProgromPublishClicked] = useState();
  const AddProgromSaveClicked = () => {
    setAddProgromSaveClicked("save");
  }
  const AddProgromPublishClicked = () => {
    setAddProgromSaveClicked("publish");
  }
  const [editProgromSaveClicked, setEditProgromSaveClicked] = useState();
  const [editProgromPublishClicked, setEditProgromPublishClicked] = useState();
  const EditProgromSaveClicked = () => {
    setEditProgromSaveClicked("save");
  }
  const EditProgromPublishClicked = () => {
    setEditProgromSaveClicked("publish");
  }
  const handleAddColumn2Submit = (details) => {
    let task_list = [];
    if (addProgramFocusList.length > 0) {
      for (let i = 0; i < addProgramFocusList.length; i++) {
        if (addProgramFocusList[i]["task_list"].length > 0) {
          for (let j = 0; j < addProgramFocusList[i]["task_list"].length; j++) {
            task_list.push({ task_id: addProgramFocusList[i]["task_list"][j]["id"], task_repeat: addProgramFocusList[i]["task_list"][j]["task_repeat"] })
          }
        }
      }
    }
    let program_detail = {
      planningName: details.program_name,
      visibility: program_visibility,
      from_date: details.from_date,
      to_date: details.to_date,
      task_list: task_list,
      shared_with: selectedOption,
      teams: selectedTeamOptions,
      players: selectedPlayerOptions,
      publish: addProgromSaveClicked
    }
    if ((task_list.length != 0) && (addProgromSaveClicked == "publish")) {
      AddNewTaskPlanning(program_detail)
        .then((data) => {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Program added successfully'
          });
          GetProgramList([])
            .then((program_data) => {
              let program_list = program_data.querydata;
              if (program_list.length > 0) {
                for (var i = 0; i < program_list.length; i++) {
                  let task_duration = 0;
                  let task_difficulty_number = 0;
                  let task_difficulty_count = 0;
                  let focus_area_detail = program_list[i]["focus_area_detail"];
                  if (focus_area_detail.length > 0) {
                    for (var j = 0; j < focus_area_detail.length; j++) {
                      let task_list = focus_area_detail[j]["task_list"];
                      task_difficulty_count = task_difficulty_count + (task_list.length);
                      if (task_list.length > 0) {
                        for (var k = 0; k < task_list.length; k++) {
                          if (task_list[k]["duration_type"] == "Y") {
                            task_duration = task_duration + (task_list[k]["duration"] * 365)
                          }
                          else if (task_list[k]["duration_type"] == "M") {
                            task_duration = task_duration + (task_list[k]["duration"] * 30)
                          }
                          else {
                            task_duration = task_duration + (task_list[k]["duration"]);
                          }
                          if (task_list[k]["difficulty"] == "easier") {
                            task_difficulty_number = task_difficulty_number + 1;
                          }
                          else if (task_list[k]["difficulty"] == "easy") {
                            task_difficulty_number = task_difficulty_number + 2;
                          }
                          else if (task_list[k]["difficulty"] == "medium") {
                            task_difficulty_number = task_difficulty_number + 3;
                          }
                          else if (task_list[k]["difficulty"] == "hard") {
                            task_difficulty_number = task_difficulty_number + 4;
                          }
                          else if (task_list[k]["difficulty"] == "harder") {
                            task_difficulty_number = task_difficulty_number + 5;
                          }
                        }
                      }
                    }
                  }
                  let average_difficulty = 0;
                  average_difficulty = task_difficulty_number / task_difficulty_count;
                  average_difficulty = Math.round(average_difficulty);
                  program_list[i]["average_difficulty"] = average_difficulty;
                  program_list[i]["task_duration"] = task_duration;
                }
              }
              setProgramList(program_data.querydata);
            });
          reset({
            program_name: "",
            from_date: "",
            to_date: ""
          })
          setSelectedOption("global");
          setSelectedPlayerOptions([])
          setSelectedTeamOptions([])
          setAddProgramFocusList([]);
          setProgram_visibility(false)
          setColumnSecond(false)
        });
    }
    else if ((addProgromSaveClicked == "save")) {
      AddNewTaskPlanning(program_detail)
        .then((data) => {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Program added successfully'
          });
          GetProgramList([])
            .then((program_data) => {
              let program_list = program_data.querydata;
              if (program_list.length > 0) {
                for (var i = 0; i < program_list.length; i++) {
                  let task_duration = 0;
                  let task_difficulty_number = 0;
                  let task_difficulty_count = 0;
                  let focus_area_detail = program_list[i]["focus_area_detail"];
                  if (focus_area_detail.length > 0) {
                    for (var j = 0; j < focus_area_detail.length; j++) {

                      let task_list = focus_area_detail[j]["task_list"];
                      task_difficulty_count = task_difficulty_count + (task_list.length);
                      if (task_list.length > 0) {
                        for (var k = 0; k < task_list.length; k++) {
                          if (task_list[k]["duration_type"] == "Y") {
                            task_duration = task_duration + (task_list[k]["duration"] * 365)
                          }
                          else if (task_list[k]["duration_type"] == "M") {
                            task_duration = task_duration + (task_list[k]["duration"] * 30)
                          }
                          else {
                            task_duration = task_duration + (task_list[k]["duration"]);
                          }
                          if (task_list[k]["difficulty"] == "easier") {
                            task_difficulty_number = task_difficulty_number + 1;
                          }
                          else if (task_list[k]["difficulty"] == "easy") {
                            task_difficulty_number = task_difficulty_number + 2;
                          }
                          else if (task_list[k]["difficulty"] == "medium") {
                            task_difficulty_number = task_difficulty_number + 3;
                          }
                          else if (task_list[k]["difficulty"] == "hard") {
                            task_difficulty_number = task_difficulty_number + 4;
                          }
                          else if (task_list[k]["difficulty"] == "harder") {
                            task_difficulty_number = task_difficulty_number + 5;
                          }
                        }
                      }
                    }
                  }

                  let average_difficulty = 0;
                  average_difficulty = task_difficulty_number / task_difficulty_count;
                  average_difficulty = Math.round(average_difficulty);
                  program_list[i]["average_difficulty"] = average_difficulty;
                  program_list[i]["task_duration"] = task_duration;
                }
              }
              setProgramList(program_data.querydata);
            });
          reset({
            program_name: "",
            from_date: "",
            to_date: ""
          })
          setSelectedOption("global");
          setSelectedPlayerOptions([])
          setSelectedTeamOptions([])
          setAddProgramFocusList([]);
          setProgram_visibility(false)
          setColumnSecond(false)
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: '🚀',
        text: 'Please add atleast one task'
      });
    }
  }

  const handleEditColumn2Submit = (details) => {
    let task_list = [];
    if (addProgramFocusList.length > 0) {
      for (let i = 0; i < addProgramFocusList.length; i++) {
        if (addProgramFocusList[i]["task_list"].length > 0) {
          for (let j = 0; j < addProgramFocusList[i]["task_list"].length; j++) {
            task_list.push({ task_id: addProgramFocusList[i]["task_list"][j]["id"], task_repeat: addProgramFocusList[i]["task_list"][j]["task_repeat"] })
          }
        }
      }
    }
    let program_detail = {
      task_planning_id: selectedProgramId,
      planningName: details.program_name,
      visibility: program_visibility,
      from_date: details.from_date,
      to_date: details.to_date,
      task_list: task_list,
      shared_with: selectedEditOption,
      teams: selectedEditTeamOptions,
      players: selectedEditPlayerOptions,
      publish: editProgromSaveClicked
    }
    if ((task_list.length != 0) && (editProgromSaveClicked == "publish")) {
      UpdateTaskPlanning(program_detail)
        .then((data) => {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Program updated successfully'
          });
          GetProgramList([])
            .then((program_data) => {
              let program_list = program_data.querydata;
              if (program_list.length > 0) {
                for (var i = 0; i < program_list.length; i++) {
                  let task_duration = 0;
                  let task_difficulty_number = 0;
                  let task_difficulty_count = 0;
                  let focus_area_detail = program_list[i]["focus_area_detail"];
                  if (focus_area_detail.length > 0) {
                    for (var j = 0; j < focus_area_detail.length; j++) {
                      let task_list = focus_area_detail[j]["task_list"];
                      task_difficulty_count = task_difficulty_count + (task_list.length);
                      if (task_list.length > 0) {
                        for (var k = 0; k < task_list.length; k++) {
                          if (task_list[k]["duration_type"] == "Y") {
                            task_duration = task_duration + (task_list[k]["duration"] * 365)
                          }
                          else if (task_list[k]["duration_type"] == "M") {
                            task_duration = task_duration + (task_list[k]["duration"] * 30)
                          }
                          else {
                            task_duration = task_duration + (task_list[k]["duration"]);
                          }
                          if (task_list[k]["difficulty"] == "easier") {
                            task_difficulty_number = task_difficulty_number + 1;
                          }
                          else if (task_list[k]["difficulty"] == "easy") {
                            task_difficulty_number = task_difficulty_number + 2;
                          }
                          else if (task_list[k]["difficulty"] == "medium") {
                            task_difficulty_number = task_difficulty_number + 3;
                          }
                          else if (task_list[k]["difficulty"] == "hard") {
                            task_difficulty_number = task_difficulty_number + 4;
                          }
                          else if (task_list[k]["difficulty"] == "harder") {
                            task_difficulty_number = task_difficulty_number + 5;
                          }
                        }
                      }
                    }
                  }
                  let average_difficulty = 0;
                  average_difficulty = task_difficulty_number / task_difficulty_count;
                  average_difficulty = Math.round(average_difficulty);
                  program_list[i]["average_difficulty"] = average_difficulty;
                  program_list[i]["task_duration"] = task_duration;
                }
              }
              setProgramList(program_data.querydata);
            });
          reset({
            program_name: "",
            from_date: "",
            to_date: ""
          })
          setSelectedEditPlayerOptions([])
          setSelectedEditTeamOptions([])
          setAddProgramFocusList([]);
          setProgram_visibility(false)
          setColumnSecond(false);
          setColumnEditPlanning(false);
          setStateChange(!stateChange);
        });
    }
    else if (editProgromSaveClicked == "save") {
      UpdateTaskPlanning(program_detail)
        .then((data) => {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Program updated successfully'
          });
          GetProgramList([])
            .then((program_data) => {
              let program_list = program_data.querydata;
              if (program_list.length > 0) {
                for (var i = 0; i < program_list.length; i++) {
                  let task_duration = 0;
                  let task_difficulty_number = 0;
                  let task_difficulty_count = 0;
                  let focus_area_detail = program_list[i]["focus_area_detail"];
                  if (focus_area_detail.length > 0) {
                    for (var j = 0; j < focus_area_detail.length; j++) {
                      let task_list = focus_area_detail[j]["task_list"];
                      task_difficulty_count = task_difficulty_count + (task_list.length);
                      if (task_list.length > 0) {
                        for (var k = 0; k < task_list.length; k++) {
                          if (task_list[k]["duration_type"] == "Y") {
                            task_duration = task_duration + (task_list[k]["duration"] * 365)
                          }
                          else if (task_list[k]["duration_type"] == "M") {
                            task_duration = task_duration + (task_list[k]["duration"] * 30)
                          }
                          else {
                            task_duration = task_duration + (task_list[k]["duration"]);
                          }
                          if (task_list[k]["difficulty"] == "easier") {
                            task_difficulty_number = task_difficulty_number + 1;
                          }
                          else if (task_list[k]["difficulty"] == "easy") {
                            task_difficulty_number = task_difficulty_number + 2;
                          }
                          else if (task_list[k]["difficulty"] == "medium") {
                            task_difficulty_number = task_difficulty_number + 3;
                          }
                          else if (task_list[k]["difficulty"] == "hard") {
                            task_difficulty_number = task_difficulty_number + 4;
                          }
                          else if (task_list[k]["difficulty"] == "harder") {
                            task_difficulty_number = task_difficulty_number + 5;
                          }
                        }
                      }
                    }
                  }
                  let average_difficulty = 0;
                  average_difficulty = task_difficulty_number / task_difficulty_count;
                  average_difficulty = Math.round(average_difficulty);
                  program_list[i]["average_difficulty"] = average_difficulty;
                  program_list[i]["task_duration"] = task_duration;
                }
              }
              setProgramList(program_data.querydata);
            });
          reset({
            program_name: "",
            from_date: "",
            to_date: ""
          })
          setSelectedEditPlayerOptions([])
          setSelectedEditTeamOptions([])
          setAddProgramFocusList([]);
          setProgram_visibility(false)
          setColumnSecond(false);
          setColumnEditPlanning(false);
          setStateChange(!stateChange);
        });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: '🚀',
        text: 'Please add atleast one task'
      });
    }
  }
  function deleteTaskFromList(delete_task) {
    let remove_task_id = delete_task.id;
    let program_list = [];
    program_list = addProgramFocusList;
    let task_list = [];
    if (program_list.length > 0) {
      for (let i = 0; i < program_list.length; i++) {
        if (program_list[i]["task_list"].length > 0) {
          let filter_task_list = [];
          let task_num = 0;
          for (let j = 0; j < program_list[i]["task_list"].length; j++) {
            if (remove_task_id == program_list[i]["task_list"][j]["id"]) {
              program_list[i]["task_list"].filter(item => item.id !== remove_task_id);
            }
            else {
              filter_task_list[task_num] = program_list[i]["task_list"][j];
              task_num++;
            }
          }
          program_list[i]["task_list"] = filter_task_list;
        }
      }
    }
    let focus_program_list = [];
    if (program_list.length > 0) {
      for (let i = 0; i < program_list.length; i++) {
        if (program_list[i]["task_list"].length > 0) {
          focus_program_list[i] = program_list[i];
        }
      }
    }
    setAddProgramFocusList(focus_program_list);
    setStateChange(!stateChange);
  }
  function convertDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getUTCDate().toString().padStart(2, '0');
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getUTCFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  function addTaskToFocus(add_task) {
    add_task['task_repeat'] = 0;
    let addProgramFocusListCommon = addProgramFocusList;
    let focus_area_detail = [];
    let task_list = [];
    let check = 0, i = 0;
    if (addProgramFocusListCommon.length > 0) {
      for (i = 0; i < addProgramFocusListCommon.length; i++) {
        if (addProgramFocusListCommon[i]["id"] == add_task.focus_area_id) {
          let is_task_available = 0;
          for (let j = 0; j < addProgramFocusListCommon[i]["task_list"].length; j++) {
            if (add_task["id"] == addProgramFocusListCommon[i]["task_list"][j]["id"]) {
              is_task_available++;
            }
          }
          if (is_task_available > 0) {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'This task is already added in the program'
            })
          }
          else {
            addProgramFocusListCommon[i]["task_list"].push(add_task);
          }
          setAddProgramFocusList(addProgramFocusListCommon);
          setStateChange(!stateChange);
          check++;
        }
      }
      if (check == 0) {
        let single_focus_area = {};
        single_focus_area = add_task["focus_area_detail"];
        single_focus_area["task_list"] = [];
        single_focus_area["task_list"].push(add_task);
        let addProgramFocusListNew = addProgramFocusList;//..
        addProgramFocusListNew.push(single_focus_area);
        setAddProgramFocusList(addProgramFocusListNew);
        setStateChange(!stateChange);
      }
    }
    else {
      let single_focus_area = {};
      single_focus_area = add_task["focus_area_detail"];
      let task_list_array = [];
      task_list_array.push(add_task);
      single_focus_area["task_list"] = task_list_array;
      let single_focus_area_array = [];
      single_focus_area_array.push(single_focus_area);
      setAddProgramFocusList(single_focus_area_array);
    }
  }
  function replacer(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (key === 'task_list') {
        return '[Circular Reference]';
      }
    }
    return value;
  }

  const [newFocusArea, setNewFocusArea] = useState('');
  const handleNewFocusAreaChange = (event) => {
    setNewFocusArea(event.target.value);
  };

  const [showAddFocusAreaSubCategory, setShowAddFocusAreaSubCategory] = React.useState(false);
  const [newFocusAreaSubCategory, setNewFocusAreaSubCategory] = useState('');
  const handleNewFocusAreaSubCategoryChange = (event) => { setNewFocusAreaSubCategory(event.target.value); };
  const [showAddEvaluationBy, setShowAddEvaluationBy] = React.useState(false);
  const [newEvaluationBy, setNewEvaluationBy] = useState('');
  const handleNewEvaluationByChange = (event) => { setNewEvaluationBy(event.target.value); };
  const [selectedResourceOption, setSelectedResourceOption] = useState('url');
  const handleResourceOptionChange = (event) => { setSelectedResourceOption(event.target.value); };
  const [selectedSearchTask, setSelectedSearchTask] = useState('task_name');
  const handleSearchTaskClick = (search_task) => { setSelectedSearchTask(search_task); };
  const handleSearchTaskDifficultyClick = (event) => {
    let search_task_detail = { filter_type: 'difficulty', search_keyword: event.target.value }
    if (event.target.value == "") {
      GetTaskList([])
        .then((task_data) => {
          setTaskList(task_data.querydata);
        });
    }
    else {
      GetTaskList(search_task_detail)
        .then((task_data) => {
          setTaskList(task_data.querydata);
        });
    }
  }
  const handleSearchTaskDurationTypeClick = (event) => {
    let search_task_detail = {
      filter_type: 'duration',
      search_keyword: event.target.value
    }
    if (event.target.value == "") {
      GetTaskList([])
        .then((task_data) => {
          setTaskList(task_data.querydata);
        });
    }
    else {
      GetTaskList(search_task_detail)
        .then((task_data) => {
          setTaskList(task_data.querydata);
        });
    }
  };
  const [selectedSearchProgram, setSelectedSearchProgram] = useState('program_name');
  const handleSearchProgramClick = (search_program) => { setSelectedSearchProgram(search_program); };
  const handleSearchDifficultyClick = (event) => {
    let search_program_detail = {
      filter_type: 'difficulty',
      search_keyword: event.target.value
    }
    if (event.target.value == "") {
      GetProgramList([])
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["average_difficulty"] = average_difficulty;
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
    else {
      GetProgramList(search_program_detail)
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["average_difficulty"] = average_difficulty;
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
  };
  const handleSearchDurationTypeClick = (event) => {
    let search_program_detail = {
      filter_type: 'duration',
      search_keyword: event.target.value
    }
    if (event.target.value == "") {
      GetProgramList([])
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["average_difficulty"] = average_difficulty;
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
    else {
      GetProgramList(search_program_detail)
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["average_difficulty"] = average_difficulty;
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
  };
  const handleSearchPaymentClick = (event) => {
    let search_program_detail = { filter_type: 'payment', search_keyword: event.target.value }
    if (event.target.value == "") {
      GetProgramList([])
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["average_difficulty"] = average_difficulty;
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
    else {
      GetProgramList(search_program_detail)
        .then((program_data) => {
          let program_list = program_data.querydata;
          if (program_list.length > 0) {
            for (var i = 0; i < program_list.length; i++) {
              let task_duration = 0;
              let task_difficulty_number = 0;
              let task_difficulty_count = 0;
              let focus_area_detail = program_list[i]["focus_area_detail"];
              if (focus_area_detail.length > 0) {
                for (var j = 0; j < focus_area_detail.length; j++) {
                  let task_list = focus_area_detail[j]["task_list"];
                  task_difficulty_count = task_difficulty_count + (task_list.length);
                  if (task_list.length > 0) {
                    for (var k = 0; k < task_list.length; k++) {
                      if (task_list[k]["duration_type"] == "Y") {
                        task_duration = task_duration + (task_list[k]["duration"] * 365)
                      }
                      else if (task_list[k]["duration_type"] == "M") {
                        task_duration = task_duration + (task_list[k]["duration"] * 30)
                      }
                      else {
                        task_duration = task_duration + (task_list[k]["duration"]);
                      }
                      if (task_list[k]["difficulty"] == "easier") {
                        task_difficulty_number = task_difficulty_number + 1;
                      }
                      else if (task_list[k]["difficulty"] == "easy") {
                        task_difficulty_number = task_difficulty_number + 2;
                      }
                      else if (task_list[k]["difficulty"] == "medium") {
                        task_difficulty_number = task_difficulty_number + 3;
                      }
                      else if (task_list[k]["difficulty"] == "hard") {
                        task_difficulty_number = task_difficulty_number + 4;
                      }
                      else if (task_list[k]["difficulty"] == "harder") {
                        task_difficulty_number = task_difficulty_number + 5;
                      }
                    }
                  }
                }
              }
              let average_difficulty = 0;
              average_difficulty = task_difficulty_number / task_difficulty_count;
              average_difficulty = Math.round(average_difficulty);
              program_list[i]["task_duration"] = task_duration;
            }
          }
          setProgramList(program_data.querydata);
        });
    }
  };
  const [newPaymentName, setNewPaymentName] = useState('');
  const handleNewPaymentNameChange = (event) => { setNewPaymentName(event.target.value); };
  const [newPaymentDuration, setNewPaymentDuration] = useState('');
  const handleNewPaymentDurationChange = (event) => { setNewPaymentDuration(event.target.value); };
  const [searchTaskName, setSearchTaskName] = useState('');
  const handleSearchTaskNameChange = (event) => { setSearchTaskName(event.target.value); };
  const [searchProgramName, setSearchProgramName] = useState('');
  const handleSearchProgramNameChange = (event) => { setSearchProgramName(event.target.value); };
  const [activityCategory, setActivityCategory] = useState([]);
  const [selectedActivityCategory, setSelectedActivityCategory] = useState("");
  const [focusArea, setFocusArea] = useState([]);
  const [selectedFocusArea, setSelectedFocusArea] = useState("");
  const [focusAreaSubCategory, setFocusAreaSubCategory] = useState([]);
  const [selectedFocusAreaSubCategory, setSelectedFocusAreaSubCategory] = useState("");
  const [selectedEvaluationBy, setSelectedEvaluationBy] = useState("");
  const [evaluationBy, setEvaluationBy] = useState([]);
  const [selectedTaskPayment, setSelectedTaskPayment] = useState("");
  const [taskPayment, setTaskPayment] = useState([]);
  const [team, setTeam] = useState([]);
  const [player, setPlayer] = useState([]);
  useEffect(() => {
    setHeaderTitle("Task Planner");
    Teams().then((data) => {
      let teamOptions = data.teams.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setTeam(teamOptions)
    });
    Players().then((data) => {
      let playerOptions = data.data.map((item) => ({
        value: item.id,
        label: item.full_name,
      }));
      setPlayer(playerOptions)
    });
  }, []);
  const [isSubmissionRead, setIsSubmissionRead] = useState("Compulsory");
  const handleSubmissionRead = (val) => (e) => {
    setIsSubmissionRead(val);
  };

  const [isSubmissionWatchVideo, setIsSubmissionWatchVideo] = useState("Compulsory");
  const handleSubmissionWatchVideo = (val) => (e) => { setIsSubmissionWatchVideo(val); };
  const [isSubmissionRequestVideo, setIsSubmissionRequestVideo] = useState("Compulsory");
  const handleSubmissionRequestVideo = (val) => (e) => { setIsSubmissionRequestVideo(val); };

  function handleActivityCategoryClick(activity_value) {
    setSelectedActivityCategory(activity_value);
    let activity_data = {};
    activity_data["activity_category_id"] = activity_value;
    GetFocusArea(activity_data)
      .then((focus_area_data) => {
        setFocusArea(focus_area_data.querydata);
        let first_focus_area_id = "";
        if (focus_area_data.querydata.length > 0) {
          first_focus_area_id = focus_area_data.querydata[0]["id"];
        }
        setSelectedFocusArea(first_focus_area_id);
      });

    GetFocusAreaSubCategory(activity_data)
      .then((focus_area_sub_category_data) => {
        setFocusAreaSubCategory(focus_area_sub_category_data.querydata);
        let first_focus_area_sub_category_id = "";
        if (focus_area_sub_category_data.querydata.length > 0) {
          first_focus_area_sub_category_id = focus_area_sub_category_data.querydata[0]["id"];
        }
        setSelectedFocusAreaSubCategory(first_focus_area_sub_category_id);
      });
  }

  function handleFocusAreaClick(focus_area_value) { setSelectedFocusArea(focus_area_value); }
  function handleEvaluationByClick(evaluation_by_value) { setSelectedEvaluationBy(evaluation_by_value); }
  function handleTaskPaymentClick(task_payment_value) {
    setSelectedTaskPayment(task_payment_value);
  }
  function handleFocusAreaSubCategoryClick(focus_area_value) {
    setSelectedFocusAreaSubCategory(focus_area_value);
  }

  useEffect(() => {
    GetTaskList([])
      .then((task_data) => {
        setTaskList(task_data.querydata);
      });

    GetProgramList([])
      .then((program_data) => {
        let program_list = program_data.querydata;
        if (program_list.length > 0) {
          for (var i = 0; i < program_list.length; i++) {
            let task_duration = 0;
            let task_difficulty_number = 0;
            let task_difficulty_count = 0;
            let focus_area_detail = program_list[i]["focus_area_detail"];
            if (focus_area_detail.length > 0) {
              for (var j = 0; j < focus_area_detail.length; j++) {
                let task_list = focus_area_detail[j]["task_list"];
                task_difficulty_count = task_difficulty_count + (task_list.length);
                if (task_list.length > 0) {
                  for (var k = 0; k < task_list.length; k++) {
                    if (task_list[k]["duration_type"] == "Y") {
                      task_duration = task_duration + (task_list[k]["duration"] * 365)
                    }
                    else if (task_list[k]["duration_type"] == "M") {
                      task_duration = task_duration + (task_list[k]["duration"] * 30)
                    }
                    else {
                      task_duration = task_duration + (task_list[k]["duration"]);
                    }
                    if (task_list[k]["difficulty"] == "easier") {
                      task_difficulty_number = task_difficulty_number + 1;
                    }
                    else if (task_list[k]["difficulty"] == "easy") {
                      task_difficulty_number = task_difficulty_number + 2;
                    }
                    else if (task_list[k]["difficulty"] == "medium") {
                      task_difficulty_number = task_difficulty_number + 3;
                    }
                    else if (task_list[k]["difficulty"] == "hard") {
                      task_difficulty_number = task_difficulty_number + 4;
                    }
                    else if (task_list[k]["difficulty"] == "harder") {
                      task_difficulty_number = task_difficulty_number + 5;
                    }
                  }
                }
              }
            }
            let average_difficulty = 0;
            average_difficulty = task_difficulty_number / task_difficulty_count;
            average_difficulty = Math.round(average_difficulty);
            program_list[i]["average_difficulty"] = average_difficulty;
            program_list[i]["task_duration"] = task_duration;
          }
        }
        setProgramList(program_data.querydata);
      });
  }, []);

  const handleModalAddProgram = (e) => {
    e.preventDefault();
    setColumnEditPlanning(false);
    setColumnSecond(false);
    setColumnSecond(true);
    setStateChange(!stateChange);

    reset({
      program_name: "",
      from_date: "",
      to_date: ""
    })
    setAddProgramFocusList([]);
    setProgram_visibility(false)
  }

  const handleCopyProgram = async (programId) => {
    try {
      const program_detail = { program_id: programId };
      const programData = await GetProgramIdDetails(program_detail);

      if (programData.status) {
        const duplicateResponse = await MakeDuplicateProgram(program_detail);

        if (duplicateResponse.status) {
          Swal.fire(
            'Duplicated!!',
            'Program duplicated successfully',
            'success'
          );
          GetProgramList([])
            .then((program_data) => {
              let program_list = program_data.querydata;
              if (program_list.length > 0) {
                for (var i = 0; i < program_list.length; i++) {
                  let task_duration = 0;
                  let task_difficulty_number = 0;
                  let task_difficulty_count = 0;
                  let focus_area_detail = program_list[i]["focus_area_detail"];
                  if (focus_area_detail.length > 0) {
                    for (var j = 0; j < focus_area_detail.length; j++) {
                      let task_list = focus_area_detail[j]["task_list"];
                      task_difficulty_count = task_difficulty_count + (task_list.length);
                      if (task_list.length > 0) {
                        for (var k = 0; k < task_list.length; k++) {
                          if (task_list[k]["duration_type"] == "Y") {
                            task_duration = task_duration + (task_list[k]["duration"] * 365)
                          }
                          else if (task_list[k]["duration_type"] == "M") {
                            task_duration = task_duration + (task_list[k]["duration"] * 30)
                          }
                          else {
                            task_duration = task_duration + (task_list[k]["duration"]);
                          }
                          if (task_list[k]["difficulty"] == "easier") {
                            task_difficulty_number = task_difficulty_number + 1;
                          }
                          else if (task_list[k]["difficulty"] == "easy") {
                            task_difficulty_number = task_difficulty_number + 2;
                          }
                          else if (task_list[k]["difficulty"] == "medium") {
                            task_difficulty_number = task_difficulty_number + 3;
                          }
                          else if (task_list[k]["difficulty"] == "hard") {
                            task_difficulty_number = task_difficulty_number + 4;
                          }
                          else if (task_list[k]["difficulty"] == "harder") {
                            task_difficulty_number = task_difficulty_number + 5;
                          }
                        }
                      }
                    }
                  }

                  let average_difficulty = 0;
                  average_difficulty = task_difficulty_number / task_difficulty_count;
                  average_difficulty = Math.round(average_difficulty)
                  program_list[i]["average_difficulty"] = average_difficulty;
                  program_list[i]["task_duration"] = task_duration;
                }
              }
              setSelectedProgramId(duplicateResponse.querydata.add_task_planning.id);
              setProgramList(program_data.querydata);
              const fn = handleModalEditProgram(duplicateResponse.querydata.add_task_planning.id);
              fn();
            });
        } else {
        }
      } else {
      }
    } catch (error) {
    }
  };

  const handleModalEditProgram = (val) => async (e) => {
    let selected_program_id = selectedProgramId;
    if (val != "") {
      selected_program_id = val;
    }
    if (selected_program_id == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select program'
      })
    }
    else {
      setColumnSecond(false);
      setColumnEditPlanning(true);
      let program_detail = {};
      program_detail["program_id"] = selected_program_id;
      GetProgramIdDetails(program_detail)
        .then((program_data) => {
          let program_detail = program_data.querydata;
          reset(
            {
              program_name: program_detail.planningName,
              from_date: program_detail.from_date,
              to_date: program_detail.to_date
            });
          setEditProgramName(program_detail.planningName);
          setProgram_visibility(program_detail.visibility);
          setEditProgramFromDate(program_detail.from_date);
          setEditProgramToDate(program_detail.to_date);
          setAddProgramFocusList(program_detail.focus_area_detail);
          setStateChange(!stateChange);
          if ((program_detail.shared_with == "") || (program_detail.shared_with == null)) {
            setSelectedEditOption("global");
          }
          else {
            setSelectedEditOption(program_detail.shared_with)
          }

          const updatedData = program_detail.teams_id.map(item => {
            const updatedItem = {
              value: item.id,
              label: item.name,
            };
            return updatedItem;
          });
          setSelectedEditTeamOptions(updatedData)
          const updatedData2 = program_detail.players_id.map(item => {
            const updatedItem2 = {
              value: item.id,
              label: item.full_name,
            };
            return updatedItem2;
          });
          setSelectedEditPlayerOptions(updatedData2)
        });
    }
  }

  const handleModalEditTask = (e) => {
    e.preventDefault();
    if (selectedTaskId == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select activity'
      })
    }
    else {

      let task_detail = {};
      task_detail["task_id"] = selectedTaskId;
      GetTaskIdDetails(task_detail)
        .then((task_data) => {
          reset(
            {
              task: task_data.querydata.taskName,
              duration: task_data.querydata.duration,
              description: task_data.querydata.description,
              resource_url: task_data.querydata.resource_url
            });

          setEditTaskName(task_data.querydata.taskName);
          setEditTaskDifficulty(task_data.querydata.difficulty);
          setEditTaskDuration(task_data.querydata.duration);
          setEditTaskDurationType(task_data.querydata.duration_type);
          setEditTaskDescription(task_data.querydata.description);
          setEditTaskResourceUrl(task_data.querydata.resource_url);
          setIsSubmissionRead(task_data.querydata.submission_read);
          setIsSubmissionWatchVideo(task_data.querydata.submission_watch_video);
          setIsSubmissionRequestVideo(task_data.querydata.submission_request_video);
          setEditVideoUrl(task_data.querydata.url);
          setSelectedResourceOption(task_data.querydata.resource_type);

          GetActivityCategory()
            .then((data) => {
              setActivityCategory(data.querydata);
              let first_activity_id = "";
              if (data.querydata.length > 0) {
                first_activity_id = data.querydata[0]["id"];
              }
              setSelectedActivityCategory(task_data.querydata.activity_category_id);

              let activity_data = {};
              activity_data["activity_category_id"] = task_data.querydata.activity_category_id;
              GetFocusArea(activity_data)
                .then((focus_area_data) => {
                  setFocusArea(focus_area_data.querydata);
                  let first_focus_area_id = "";
                  if (focus_area_data.querydata.length > 0) {
                    first_focus_area_id = focus_area_data.querydata[0]["id"];
                  }
                  setSelectedFocusArea(task_data.querydata.focus_area_id);
                });

              GetFocusAreaSubCategory(activity_data)
                .then((focus_area_sub_category_data) => {
                  setFocusAreaSubCategory(focus_area_sub_category_data.querydata);
                  let first_focus_area_sub_category_id = "";
                  if (focus_area_sub_category_data.querydata.length > 0) {
                    first_focus_area_sub_category_id = focus_area_sub_category_data.querydata[0]["id"];
                  }
                  setSelectedFocusAreaSubCategory(task_data.querydata.focus_area_sub_category_id);
                });
            });

          GetEvaluationBy()
            .then((evaluation_by_data) => {
              let evaluation_data = [
                {
                  "id": 0,
                  "evaluation_by_type": "well_played",
                  "evaluation_title": "Well Played"
                },
              ];

              const mergedEvaluationBy = evaluation_data.concat(evaluation_by_data.querydata);
              setEvaluationBy(mergedEvaluationBy);
              setSelectedEvaluationBy(task_data.querydata.evaluation_by_id);
            });

          GetCoachList()
            .then((evaluation_list) => {
              setCoachList(evaluation_list.querydata);
            });

          GetTaskPayment()
            .then((task_payment_data) => {
              setTaskPayment(task_payment_data.querydata);
              let first_task_payment_id = "";
              if (task_payment_data.querydata.length > 0) {
                first_task_payment_id = task_payment_data.querydata[0]["id"];
              }
              setSelectedTaskPayment(task_data.querydata.task_payment_id);
            });
        });
      setShowEditTask(true);
    }
  }

  const handleModalAddTask = (e) => {
    e.preventDefault();
    reset(
      {
        task: "",
        duration: "",
        description: "",
        resource_url: ""
      });
    setIsSubmissionRead("Optional");
    setIsSubmissionWatchVideo("Optional");
    setIsSubmissionRequestVideo("Optional");

    GetActivityCategory()
      .then((data) => {
        setActivityCategory(data.querydata);
        let first_activity_id = "";
        if (data.querydata.length > 0) {
          first_activity_id = data.querydata[0]["id"];
        }
        setSelectedActivityCategory(first_activity_id);
        let activity_data = {};
        activity_data["activity_category_id"] = first_activity_id;
        GetFocusArea(activity_data)
          .then((focus_area_data) => {
            setFocusArea(focus_area_data.querydata);
            let first_focus_area_id = "";
            if (focus_area_data.querydata.length > 0) {
              first_focus_area_id = focus_area_data.querydata[0]["id"];
            }
            setSelectedFocusArea(first_focus_area_id);
          });

        GetFocusAreaSubCategory(activity_data)
          .then((focus_area_sub_category_data) => {
            setFocusAreaSubCategory(focus_area_sub_category_data.querydata);
            let first_focus_area_sub_category_id = "";
            if (focus_area_sub_category_data.querydata.length > 0) {
              first_focus_area_sub_category_id = focus_area_sub_category_data.querydata[0]["id"];
            }
            setSelectedFocusAreaSubCategory(first_focus_area_sub_category_id);
          });
      });

    GetEvaluationBy()
      .then((evaluation_by_data) => {
        let evaluation_data = [
          {
            "id": 0,
            "evaluation_by_type": "well_played",
            "evaluation_title": "Well Played"
          },
        ];

        const mergedEvaluationBy = evaluation_data.concat(evaluation_by_data.querydata);
        setEvaluationBy(mergedEvaluationBy);
        setSelectedEvaluationBy(0);
      });

    GetTaskPayment()
      .then((task_payment_data) => {
        setTaskPayment(task_payment_data.querydata);

        let first_task_payment_id = "";
        if (task_payment_data.querydata.length > 0) {
          first_task_payment_id = task_payment_data.querydata[0]["id"];
        }

        setSelectedTaskPayment(first_task_payment_id);
      });

    GetCoachList()
      .then((evaluation_list) => {
        setCoachList(evaluation_list.querydata);
      });
    setShowAddTask(true);
  }

  const saveFocusArea = () => {

    if (newFocusArea == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please specify focus area'
      })
    }
    else {
      let add_focus_area_detail = {
        focus_area_name: newFocusArea,
        activity_category_id: selectedActivityCategory
      }

      AddFocusArea(add_focus_area_detail)
        .then((focus_area_detail_data) => {
          if (focus_area_detail_data.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Focus area added successfully'
            });
            setShowAddFocusArea(false);

            let activity_data = {};
            activity_data["activity_category_id"] = selectedActivityCategory;
            GetFocusArea(activity_data)
              .then((focus_area_data) => {
                setFocusArea(focus_area_data.querydata);
                setNewFocusArea("");
              });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Focus area is already exist. Please try another.'
            })
          }
        });
    }
  }

  const saveFocusAreaSubCategory = () => {
    if (newFocusAreaSubCategory == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please specify focus area sub category'
      })
    }
    else {
      let add_focus_area_sub_category_detail = {
        focus_area_sub_category_name: newFocusAreaSubCategory,
        activity_category_id: selectedActivityCategory
      }

      AddFocusAreaSubCategory(add_focus_area_sub_category_detail)
        .then((focus_area_sub_category_detail_data) => {
          if (focus_area_sub_category_detail_data.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Focus area sub category added successfully'
            });
            setShowAddFocusAreaSubCategory(false);
            let activity_data = {};
            activity_data["activity_category_id"] = selectedActivityCategory;
            GetFocusAreaSubCategory(activity_data)
              .then((focus_area_sub_category_data) => {
                setFocusAreaSubCategory(focus_area_sub_category_data.querydata);
                setNewFocusAreaSubCategory("");
              });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Focus area sub category is already exist. Please try another.'
            })
          }
        });
    }
  }

  const saveEvaluationBy = () => {
    if (newEvaluationBy == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select coach'
      })
    }
    else {
      let add_evaluation_by_detail = {
        app_user_id: newEvaluationBy
      }
      AddEvaluationBy(add_evaluation_by_detail)
        .then((evaluation_by_detail_data) => {

          if (evaluation_by_detail_data.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Coach added successfully'
            });
            setShowAddEvaluationBy(false);
            GetEvaluationBy()
              .then((evaluation_by_data) => {
                let evaluation_data = [
                  {
                    "id": 0,
                    "evaluation_by_type": "well_played",
                    "evaluation_title": "Well Played"
                  },
                ];

                const mergedEvaluationBy = evaluation_data.concat(evaluation_by_data.querydata);
                setEvaluationBy(mergedEvaluationBy);
                setNewEvaluationBy(0);
              });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Coach is already exist. Please try another.'
            })
          }
        });
    }
  }

  const handleKeyPressSearchTask = (event) => {
    if (event.key === 'Enter') {
      let search_task_detail = {
        filter_type: selectedSearchTask,
        search_keyword: searchTaskName
      }
      if (searchTaskName == "") {
        GetTaskList([])
          .then((task_data) => {
            setTaskList(task_data.querydata);
          });
      }
      else {
        GetTaskList(search_task_detail)
          .then((task_data) => {
            setTaskList(task_data.querydata);
          });
      }
    }
  };

  const handleKeyPressSearchProgram = (event) => {
    if (event.key === 'Enter') {

      let search_program_detail = {
        filter_type: selectedSearchProgram,
        search_keyword: searchProgramName
      }
      if (searchProgramName == "") {
        GetProgramList([])
          .then((program_data) => {
            let program_list = program_data.querydata;
            if (program_list.length > 0) {
              for (var i = 0; i < program_list.length; i++) {
                let task_duration = 0;
                let task_difficulty_number = 0;
                let task_difficulty_count = 0;
                let focus_area_detail = program_list[i]["focus_area_detail"];
                if (focus_area_detail.length > 0) {
                  for (var j = 0; j < focus_area_detail.length; j++) {
                    let task_list = focus_area_detail[j]["task_list"];
                    task_difficulty_count = task_difficulty_count + (task_list.length);
                    if (task_list.length > 0) {
                      for (var k = 0; k < task_list.length; k++) {
                        if (task_list[k]["duration_type"] == "Y") {
                          task_duration = task_duration + (task_list[k]["duration"] * 365)
                        }
                        else if (task_list[k]["duration_type"] == "M") {
                          task_duration = task_duration + (task_list[k]["duration"] * 30)
                        }
                        else {
                          task_duration = task_duration + (task_list[k]["duration"]);
                        }
                        if (task_list[k]["difficulty"] == "easier") {
                          task_difficulty_number = task_difficulty_number + 1;
                        }
                        else if (task_list[k]["difficulty"] == "easy") {
                          task_difficulty_number = task_difficulty_number + 2;
                        }
                        else if (task_list[k]["difficulty"] == "medium") {
                          task_difficulty_number = task_difficulty_number + 3;
                        }
                        else if (task_list[k]["difficulty"] == "hard") {
                          task_difficulty_number = task_difficulty_number + 4;
                        }
                        else if (task_list[k]["difficulty"] == "harder") {
                          task_difficulty_number = task_difficulty_number + 5;
                        }
                      }
                    }
                  }
                }

                let average_difficulty = 0;
                average_difficulty = task_difficulty_number / task_difficulty_count;
                average_difficulty = Math.round(average_difficulty);
                program_list[i]["average_difficulty"] = average_difficulty;
                program_list[i]["task_duration"] = task_duration;
              }
            }
            setProgramList(program_data.querydata);
          });
      }
      else {
        GetProgramList(search_program_detail)
          .then((program_data) => {
            let program_list = program_data.querydata;
            if (program_list.length > 0) {
              for (var i = 0; i < program_list.length; i++) {
                let task_duration = 0;
                let task_difficulty_number = 0;
                let task_difficulty_count = 0;
                let focus_area_detail = program_list[i]["focus_area_detail"];
                if (focus_area_detail.length > 0) {
                  for (var j = 0; j < focus_area_detail.length; j++) {
                    let task_list = focus_area_detail[j]["task_list"];
                    task_difficulty_count = task_difficulty_count + (task_list.length);
                    if (task_list.length > 0) {
                      for (var k = 0; k < task_list.length; k++) {
                        if (task_list[k]["duration_type"] == "Y") {
                          task_duration = task_duration + (task_list[k]["duration"] * 365)
                        }
                        else if (task_list[k]["duration_type"] == "M") {
                          task_duration = task_duration + (task_list[k]["duration"] * 30)
                        }
                        else {
                          task_duration = task_duration + (task_list[k]["duration"]);
                        }
                        if (task_list[k]["difficulty"] == "easier") {
                          task_difficulty_number = task_difficulty_number + 1;
                        }
                        else if (task_list[k]["difficulty"] == "easy") {
                          task_difficulty_number = task_difficulty_number + 2;
                        }
                        else if (task_list[k]["difficulty"] == "medium") {
                          task_difficulty_number = task_difficulty_number + 3;
                        }
                        else if (task_list[k]["difficulty"] == "hard") {
                          task_difficulty_number = task_difficulty_number + 4;
                        }
                        else if (task_list[k]["difficulty"] == "harder") {
                          task_difficulty_number = task_difficulty_number + 5;
                        }
                      }
                    }
                  }
                }

                let average_difficulty = 0;
                average_difficulty = task_difficulty_number / task_difficulty_count;
                average_difficulty = Math.round(average_difficulty);
                program_list[i]["average_difficulty"] = average_difficulty;
                program_list[i]["task_duration"] = task_duration;
              }
            }
            setProgramList(program_data.querydata);
          });
      }
    }
  };

  const savePayment = () => {
    if (newPaymentName == "") {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please specify payment name'
      })
    }
    else {
      let add_payment_detail = {
        payment_name: newPaymentName,
        payment_duration: newPaymentDuration
      }
      AddPayment(add_payment_detail)
        .then((payment_detail_data) => {
          if (payment_detail_data.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Payment added successfully'
            });
            setShowAddPayment(false);

            GetTaskPayment()
              .then((payment_data) => {
                setTaskPayment(payment_data.querydata);
                setNewPaymentName("");
                setNewPaymentDuration("");
              });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Payment is already exist. Please try another.'
            })
          }
        });
    }
  }

  const handleEditTaskSubmit = (details) => {
    let evaluation_by_type = "";
    let evaluation_by_id = "";
    if (selectedEvaluationBy == "") {
      evaluation_by_type = "well_played";
      evaluation_by_id = "";
    }
    else {
      evaluation_by_type = "coach";
      evaluation_by_id = selectedEvaluationBy;
    }

    if ((EditTaskDurationType == "") || (EditTaskDifficulty == "")) {
      return false;
    }

    var formData = new FormData();
    formData.append("file", file_edit);
    formData.append("task_id", selectedTaskId);
    formData.append("taskName", details.task);
    formData.append("difficulty", EditTaskDifficulty);
    formData.append("duration", details.duration);
    formData.append("duration_type", EditTaskDurationType);
    formData.append("activity_category_id", selectedActivityCategory);
    formData.append("focus_area_id", selectedFocusArea);
    formData.append("focus_area_sub_category_id", selectedFocusAreaSubCategory);
    formData.append("description", details.description);
    formData.append("resource_url", details.resource_url);
    formData.append("resource_type", selectedResourceOption);
    formData.append("submission_read", isSubmissionRead);
    formData.append("submission_watch_video", isSubmissionWatchVideo);
    formData.append("submission_request_video", isSubmissionRequestVideo);
    formData.append("evaluation_by_type", evaluation_by_type);
    formData.append("evaluation_by_id", 0);
    formData.append("task_payment_id", selectedTaskPayment);

    let task_detail = {
      task_id: selectedTaskId,
      taskName: details.task,
      difficulty: details.difficulty,
      duration: details.duration,
      duration_type: details.duration_type,
      activity_category_id: selectedActivityCategory,
      focus_area_id: selectedFocusArea,
      focus_area_sub_category_id: selectedFocusAreaSubCategory,
      description: details.description,
      resource_url: details.resource_url,
      resource_type: selectedResourceOption,
      submission_read: isSubmissionRead,
      submission_watch_video: isSubmissionWatchVideo,
      submission_request_video: isSubmissionRequestVideo,
      evaluation_by_type: evaluation_by_type,
      evaluation_by_id: evaluation_by_id,
      task_payment_id: selectedTaskPayment
    }

    UpdateTask(formData)
      .then((task_detail_data) => {
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Activity edited successfully'
        });
        setShowEditTask(false);
        setFileEdit("")
        GetTaskList([])
          .then((task_data) => {
            setTaskList(task_data.querydata);
          });

      });
  }
  const handleAddTaskSubmit = (details) => {
    if (selectedFocusArea == '') {
      Swal.fire({
        icon: 'info',
        title: '🚀',
        text: 'Please add focus area'
      });
    }
    else if (selectedFocusAreaSubCategory == '') {
      Swal.fire({
        icon: 'info',
        title: '🚀',
        text: 'Please add focus area sub category'
      });
    }
    else {
      let evaluation_by_type = "";
      let evaluation_by_id = "";
      if (selectedEvaluationBy == "") {
        evaluation_by_type = "well_played";
        evaluation_by_id = "";
      }
      else {
        evaluation_by_type = "coach";
        evaluation_by_id = selectedEvaluationBy;
      }
      var formData = new FormData();
      formData.append("file", file);
      formData.append("taskName", details.task);
      formData.append("difficulty", details.difficulty);
      formData.append("duration", details.duration);
      formData.append("duration_type", details.duration_type);
      formData.append("activity_category_id", selectedActivityCategory);
      formData.append("focus_area_id", selectedFocusArea);
      formData.append("focus_area_sub_category_id", selectedFocusAreaSubCategory);
      formData.append("description", details.description);
      formData.append("resource_url", details.resource_url);
      formData.append("resource_type", selectedResourceOption);
      formData.append("submission_read", isSubmissionRead);
      formData.append("submission_watch_video", isSubmissionWatchVideo);
      formData.append("submission_request_video", isSubmissionRequestVideo);
      formData.append("evaluation_by_type", evaluation_by_type);
      formData.append("evaluation_by_id", 0);
      formData.append("task_payment_id", selectedTaskPayment);

      let task_detail = {
        taskName: details.task,
        difficulty: details.difficulty,
        duration: details.duration,
        duration_type: details.duration_type,
        activity_category_id: selectedActivityCategory,
        focus_area_id: selectedFocusArea,
        focus_area_sub_category_id: selectedFocusAreaSubCategory,
        description: details.description,
        resource_url: details.resource_url,
        resource_type: selectedResourceOption,
        submission_read: isSubmissionRead,
        submission_watch_video: isSubmissionWatchVideo,
        submission_request_video: isSubmissionRequestVideo,
        evaluation_by_type: evaluation_by_type,
        evaluation_by_id: evaluation_by_id,
        task_payment_id: selectedTaskPayment,
        file: file,
        formData: formData
      }

      AddNewTask(formData)
        .then((task_detail_data) => {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Activity added successfully'
          });
          setShowAddTask(false);
          GetTaskList([])
            .then((task_data) => {
              setTaskList(task_data.querydata);
            });
        });
    }
  }
  const [file, setFile] = useState();
  const [file_edit, setFileEdit] = useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  }
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  }
  const handleFileSelectEdit = (event) => {
    const file_edit = event.target.files[0];
    setSelectedFileEdit(file_edit);
    setFileEdit(file_edit);
  }
  const [customErrors, setCustomErrors] = useState()
  const onDrop = (e) => {
    if (e[0].type.split("/")[0] === "video") {
      if ((e[0].size / (1024 * 1024)) > 1000) {
        Swal.fire({
          icon: 'info',
          title: '😑',
          text: "File size must be less than 1 GB"
        })
      }
      else {
        setFile(e[0]);
        setCustomErrors(null)
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: '😑',
        text: "Only video files are accepted"
      })
    }
  }

  const [selectedOption, setSelectedOption] = useState('global');
  const handleTaskClick = (option) => {
    setSelectedPlayerOptions([])
    setSelectedTeamOptions([])
    setSelectedOption(option);
  };
  const [selectedPlayerOptions, setSelectedPlayerOptions] = useState([]);
  const [selectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const handleChangeTeam = (selectedOption) => { setSelectedTeamOptions(selectedOption); };
  const handleChangePlayer = (selectedOption) => { setSelectedPlayerOptions(selectedOption); };
  //Edit 
  const [selectedEditOption, setSelectedEditOption] = useState('global');
  const handleEditTaskClick = (option) => {
    setSelectedEditPlayerOptions([])
    setSelectedEditTeamOptions([])
    setSelectedEditOption(option);
  };

  const [selectedEditPlayerOptions, setSelectedEditPlayerOptions] = useState([]);
  const [selectedEditTeamOptions, setSelectedEditTeamOptions] = useState([]);
  const handleChangeEditTeam = (selectedOption) => { setSelectedEditTeamOptions(selectedOption); };
  const handleChangeEditPlayer = (selectedOption) => { setSelectedEditPlayerOptions(selectedOption); };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="w-full flex " >
        <div className="grid grid-cols-3 divide-x" style={{
          backgroundColor: "white",
          width: "100%"
        }}>
          {/* Plan List Start */}
          <div className="w-full" style={{ width: "100%" }}>
            <div className="bg-white p-2 h-max " >
              <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: "10px", height: '98vh' }}>
                <div style={{ textAlign: 'center' }} className="text-base ">

                  <input
                    type="text"
                    placeholder="Mention search keyword and enter"
                    onKeyDown={handleKeyPressSearchProgram}
                    value={searchProgramName}
                    onChange={handleSearchProgramNameChange}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    style={{ width: '100%' }} />

                  <div className="bowlerButtonGroup" style={{
                    float: "left",
                    marginTop: "1%"
                  }}>
                    <ButtonGroup
                      value={selectedSearchProgram}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '2px',
                        justifyContent: 'center'
                      }} >
                      <FormControlLabel
                        key="ProgramName"
                        value="program_name"
                        onClick={() => handleSearchProgramClick("program_name")}
                        control={<Button variant={selectedSearchProgram === "program_name" ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '120px' }} ><p style={{ fontSize: '13px' }}>Program</p></Button>}
                        sx={{ padding: "0", fontSize: '13px' }} />

                      <select
                        id="difficulty"
                        size="small"
                        style={{ height: "35px", width: '120px' }}
                        onChange={handleSearchDifficultyClick}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      >
                        <option value=''>Select Difficulty</option>
                        <option value="easier">Easier</option>
                        <option value="easy">Easy</option>
                        <option value="medium">Medium</option>
                        <option value="hard">Hard</option>
                        <option value="harder">Harder</option>
                      </select>
                      <select
                        id="duration_type"
                        style={{ height: "35px", width: '120px' }}
                        onChange={handleSearchDurationTypeClick}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      >
                        <option value=''>Select Duration Type</option>
                        <option value="D"> &#8804; 1 Week</option>
                        <option value="M">&gt; 1 Week &lt; 1 Month</option>
                        <option value="Y">&gt; 1 Month</option>
                      </select>

                      <FormControlLabel
                        key="Focus Area"
                        value="focus_area"
                        onClick={() => handleSearchProgramClick("focus_area")}
                        control={<Button variant={selectedSearchProgram === "focus_area" ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '120px' }} ><p style={{ fontSize: '13px' }}>Focus Area</p></Button>}
                        sx={{ padding: "0", fontSize: '13px' }} />

                      <select
                        id="duration_type"
                        style={{ height: "35px", width: '120px' }}
                        onChange={handleSearchPaymentClick}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      >
                        <option value=''>Select Payment Type</option>
                        <option value="free">Free</option>
                        <option value="paid">Paid</option>
                      </select>

                    </ButtonGroup>
                  </div>
                  <div style={{ height: '85vh' }}>
                    <div className='program_scroller'>
                      {programList.map((program_detail, id) => (
                        <div className="w-full" style={{ width: "100%", display: 'flow-root' }} key={id}>
                          <div className="p-2 h-max " >
                            <div className={`grid grid-cols-1 gap-2 p-2 ${selectedProgramId === program_detail.id ? 'selected_program' : ''}`} style={{ background: program_detail.publish == "publish" ? 'rgb(119 182 245)' : '', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', borderRadius: "5px", textAlign: "left" }}>

                              <span className='grid grid-cols-2'>
                                <strong onClick={handleSelectProgram(program_detail.id)} style={{ cursor: 'pointer' }}>{program_detail.planningName}</strong>
                                <span style={{ textAlign: 'right' }}>
                                  <button className="bg-green-500 text-black rounded" onClick={() => handleCopyProgram(program_detail.id)} type="button">
                                    <ContentCopyIcon />
                                  </button>
                                </span>
                              </span>
                              <h2 className="" style={{ color: "#2B303B", display: 'flex' }}>
                                <span style={{ width: '70%', display: 'flex' }}>
                                  <>
                                    <div className={program_detail.average_difficulty == 1 ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'green', padding: program_detail.average_difficulty == 1 ? '4px 30px' : '1px 20px' }}></div>
                                    <div className={program_detail.average_difficulty == 2 ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'yellow', padding: program_detail.average_difficulty == 2 ? '4px 30px' : '1px 20px' }}></div>
                                    <div className={program_detail.average_difficulty == 3 ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'orange', padding: program_detail.average_difficulty == 3 ? '4px 30px' : '1px 20px' }}></div>
                                    <div className={program_detail.average_difficulty == 4 ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'red', padding: program_detail.average_difficulty == 4 ? '4px 30px' : '1px 20px' }}></div>
                                    <div className={program_detail.average_difficulty == 5 ? 'progressSelect' : 'progress'} style={{ backgroundColor: '#850505', padding: program_detail.average_difficulty == 5 ? '4px 30px' : '1px 20px' }}></div>
                                  </>
                                </span>
                                <span style={{ textAlign: 'right', width: '30%' }}>
                                  <strong>{program_detail.task_duration}D</strong>
                                </span>
                              </h2>
                              {showFocusDetail === program_detail.id ? <>
                                <span style={{ textAlign: "right", color: "blue", cursor: "pointer" }} onClick={handleShowFocus('')}>Show Less...</span>
                              </> : <>
                                <span style={{ textAlign: "right", color: "blue", cursor: "pointer" }} onClick={handleShowFocus(program_detail.id)}>Show More...</span>
                              </>}

                              {program_detail.focus_area_detail.map((focus_detail, id) => (
                                <div className="w-full" style={{ width: '100%', display: showFocusDetail === program_detail.id ? 'flow-root' : 'none' }} key={id}>
                                  <div className="p-2 h-max " >
                                    <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ccc', borderRadius: "5px", textAlign: "left" }}>
                                      <strong>{focus_detail.focus_area_name}</strong>

                                      {focus_detail.task_list.map((task_detail, id) => (
                                        <div className="w-full" style={{ width: "100%", display: 'flow-root' }} key={id}>
                                          <div className="p-2 h-max " >
                                            <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ededed', borderRadius: "5px", textAlign: "left" }}>
                                              <strong>{task_detail.taskName}</strong>

                                              <h2 className="" style={{ color: "#2B303B", display: 'flex' }}>
                                                <span style={{ width: '70%', display: 'flex' }} >
                                                  {task_detail.difficulty ? (
                                                    <>
                                                      <div className={task_detail.difficulty == "easier" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'green', padding: task_detail.difficulty == 'easier' ? '4px 30px' : '1px 20px' }}></div>
                                                      <div className={task_detail.difficulty == "easy" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'yellow', padding: task_detail.difficulty == 'easy' ? '4px 30px' : '1px 20px' }}></div>
                                                      <div className={task_detail.difficulty == "medium" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'orange', padding: task_detail.difficulty == 'medium' ? '4px 30px' : '1px 20px' }}></div>
                                                      <div className={task_detail.difficulty == "hard" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'red', padding: task_detail.difficulty == 'hard' ? '4px 30px' : '1px 20px' }}></div>
                                                      <div className={task_detail.difficulty == "harder" ? 'progressSelect' : 'progress'} style={{ backgroundColor: '#850505', padding: task_detail.difficulty == 'harder' ? '4px 30px' : '1px 20px' }}></div>
                                                    </>
                                                  ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}
                                                </span>
                                                <span style={{ textAlign: 'right', width: '30%' }}>
                                                  <strong>
                                                    {task_detail.duration ? (
                                                      <>{task_detail.duration}</>
                                                    ) : (<><span style={{ color: "#FF0000" }}>0</span></>)}{task_detail.duration_type}</strong>
                                                </span>
                                              </h2>
                                              <strong>Description :</strong>
                                              {task_detail.description ? (
                                                <>{task_detail.description}</>
                                              ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}

                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <div className="w-full" style={{ width: '100%', display: showFocusDetail === program_detail.id ? 'flow-root' : 'none' }} >
                                {program_detail.focus_area_detail.length == 0 ? (<><span style={{ color: "#FF0000" }}>No record found</span></>) : (<></>)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {programList.length == 0 ? (<><span style={{ color: "#FF0000" }}>No record found</span></>) : (<></>)}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <button className="flex-1 bg-green-500 text-black py-2 px-1 rounded mr-2" onClick={handleModalAddProgram}>
                      <AddIcon /> Add
                    </button>
                    <button className="flex-1 bg-yellow-500 text-black py-2 px-1 rounded mr-2" onClick={handleModalEditProgram("")}>
                      <EditIcon /> Edit
                    </button>
                    <button className="flex-1 bg-red-500 text-black py-2 px-1 rounded"
                    >
                      <DeleteIcon /> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit Plan Start - Add Form*/}
          {columnSecond ? (
            <form onSubmit={handleSubmit(handleAddColumn2Submit)}>
              <div className="w-full" style={{ width: "100%" }}>
                <div className="bg-white p-2 h-max " >
                  <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: "10px", height: '98vh' }}>
                    <div style={{ textAlign: 'center' }} className="text-base ">
                      <input
                        type="text"
                        id="program_name"
                        {...register("program_name", {
                          required: true
                        })}
                        placeholder="Please enter plan name"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        style={{ width: '100%' }}
                      />
                      {errors.program_name && errors.program_name.type === "required" && (
                        <p className="errorMsg" style={{ textAlign: 'left' }}>Please enter program name</p>
                      )}
                      <h2 className="flex w-full" style={{ color: "#2B303B", display: 'flex', marginTop: '1%' }}>
                        <span style={{ width: '22%', marginRight: '20px' }}>
                          Visibility
                        </span>
                        <span style={{ textAlign: 'right', width: '16%' }}>
                          <ButtonGroup

                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '2px',
                              justifyContent: 'center'
                            }}>

                            <FormControlLabel
                              key="On"
                              value="On"
                              control={<Button size="small" id="program_visibility"
                                variant={program_visibility == true ? "contained" : "outlined"}
                                onClick={toggleShowIsvisible} style={{ height: "40px", width: '70px' }} >
                                <p style={{ fontSize: '13px' }}>{program_visibility == true ? "ON" : "OFF"}</p></Button>}
                              sx={{ padding: "0", fontSize: '13px' }} />

                          </ButtonGroup>
                        </span>

                        <span style={{ textAlign: 'right', width: '30%', marginRight: '10px' }}>
                          <input
                            type="date"
                            id="from_date"

                            {...register("from_date", {
                              required: true
                            })}
                            placeholder="Match Date"
                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          />
                          {errors.from_date && errors.from_date.type === "required" && (
                            <p className="errorMsg" style={{ textAlign: 'left' }}>Please select from date</p>
                          )}
                        </span>
                        <span style={{ textAlign: 'right', width: '30%' }}>
                          <input
                            type="date"
                            id="to_date"
                            {...register("to_date", {
                              required: true
                            })}
                            placeholder="Match Date"
                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          />
                          {errors.to_date && errors.to_date.type === "required" && (
                            <p className="errorMsg" style={{ textAlign: 'left' }}>Please select to date</p>
                          )}
                        </span>
                      </h2>

                      <div style={{ height: '79vh' }}>
                        <div className='add_task_scroller'>
                          <h2 className="flex w-full" style={{ color: "#2B303B", display: 'flex', marginTop: '1%' }}>
                            <span style={{ width: '22%' }}>
                              Shared With
                            </span>
                            <span style={{ textAlign: 'left', width: '78%' }}>
                              <ButtonGroup
                                value={selectedOption}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px',
                                  justifyContent: 'center'
                                }} >
                                <FormControlLabel
                                  key="Global"
                                  value="global"
                                  onClick={() => handleTaskClick("global")}
                                  control={<Button size="small" variant={selectedOption === "global" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                    <p style={{ fontSize: '13px' }}>Global</p></Button>}
                                  sx={{ padding: "0", fontSize: '13px' }} />
                                <FormControlLabel
                                  key="Team"
                                  value="team"
                                  onClick={() => handleTaskClick("team")}
                                  control={<Button size="small" variant={selectedOption === "team" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                    <p style={{ fontSize: '13px' }}>Team</p></Button>}
                                  sx={{ padding: "0", fontSize: '13px' }}
                                />

                                <FormControlLabel
                                  key="Player"
                                  value="player"
                                  onClick={() => handleTaskClick("player")}
                                  control={<Button size="small" variant={selectedOption === "player" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                    <p style={{ fontSize: '13px' }}>Player</p></Button>}
                                  sx={{ padding: "0", fontSize: '13px' }}
                                />
                              </ButtonGroup>
                            </span>
                          </h2>
                          <div style={{ marginTop: '4px' }}>
                            {selectedOption == 'team' ? (<>
                              <Select
                                isMulti
                                options={team}
                                value={selectedTeamOptions}
                                onChange={handleChangeTeam}
                              />
                            </>) : (<></>)}
                            {selectedOption == 'player' ? (<>
                              <Select
                                isMulti
                                options={player}
                                value={selectedPlayerOptions}
                                onChange={handleChangePlayer}
                              />
                            </>) : (<></>)}
                          </div>

                          {addProgramFocusList.map((focus_detail, id) => (
                            <div className="w-full" style={{ width: '100%', display: 'flow-root' }} key={id}>
                              <div className="p-2 h-max " >
                                <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ccc', borderRadius: "5px", textAlign: "left" }}>
                                  <strong>{focus_detail.focus_area_name}</strong>

                                  {focus_detail.task_list.map((task_detail, id) => (
                                    <div className="w-full" style={{ width: "100%", display: 'flow-root' }} key={id}>
                                      <div className="p-2 h-max " >
                                        <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ededed', borderRadius: "5px", textAlign: "left" }}>

                                          <span className='grid grid-cols-2'>
                                            <strong style={{ cursor: 'pointer' }}>{task_detail.taskName}</strong>
                                            <span style={{ textAlign: 'right' }}>
                                              <button className="bg-red-500 text-black rounded " onClick={() => deleteTaskFromList(task_detail)} type='button'>
                                                <DeleteIcon />
                                              </button>
                                            </span>
                                          </span>

                                          <h2 className="" style={{ color: "#2B303B", display: 'flex' }}>
                                            <span style={{ width: '70%', display: 'flex' }} >
                                              {task_detail.difficulty ? (
                                                <>
                                                  <div className={task_detail.difficulty == "easier" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'green', padding: task_detail.difficulty == 'easier' ? '4px 30px' : '1px 20px' }}></div>
                                                  <div className={task_detail.difficulty == "easy" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'yellow', padding: task_detail.difficulty == 'easy' ? '4px 30px' : '1px 20px' }}></div>
                                                  <div className={task_detail.difficulty == "medium" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'orange', padding: task_detail.difficulty == 'medium' ? '4px 30px' : '1px 20px' }}></div>
                                                  <div className={task_detail.difficulty == "hard" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'red', padding: task_detail.difficulty == 'hard' ? '4px 30px' : '1px 20px' }}></div>
                                                  <div className={task_detail.difficulty == "harder" ? 'progressSelect' : 'progress'} style={{ backgroundColor: '#850505', padding: task_detail.difficulty == 'harder' ? '4px 30px' : '1px 20px' }}></div>
                                                </>
                                              ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}
                                            </span>
                                            <span style={{ textAlign: 'right', width: '30%' }}>
                                              <strong>
                                                {task_detail.duration ? (
                                                  <>{task_detail.duration}
                                                  </>
                                                ) : (<><span style={{ color: "#FF0000" }}>0</span></>)}{task_detail.duration_type}</strong>
                                              <button type='button' style={{ color: '#1565c0', margin: '0 3px', fontWeight: 'bold' }} onClick={handleRepeatations(task_detail)}>{task_detail.task_repeat}X</button>

                                            </span>
                                          </h2>
                                          <strong>Description :</strong>
                                          {task_detail.description ? (
                                            <>{task_detail.description}</>
                                          ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}

                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex w-full gap-2">
                        <button className="flex-1 bg-green-500 text-black py-2 px-4 rounded"
                          type='submit' id="save_add" onClick={AddProgromSaveClicked}
                        >
                          <SaveIcon /> Save
                        </button>
                        <button type="button" className="flex-1 bg-red-500 text-black py-2 px-4 rounded" onClick={handleResetAddColumn2Submit}>
                          <CancelIcon /> Cancel
                        </button>
                        <button className="flex-1 bg-blue-500 text-black py-2 px-4 rounded" id="publish_add" onClick={AddProgromPublishClicked}>
                          <PublishIcon />
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (

            columnEditPlanning ? (
              <form onSubmit={handleSubmit(handleEditColumn2Submit)}>
                <div className="w-full" style={{ width: "100%" }}>
                  <div className="bg-white p-2 h-max " >
                    <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: "10px", height: '98vh' }}>
                      <div style={{ textAlign: 'center' }} className="text-base ">
                        <input
                          type="text"
                          id="program_name"
                          defaultValue={editProgramName}
                          {...register("program_name", {
                            required: true
                          })}
                          placeholder="Please enter plan name"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          style={{ width: '100%' }}
                        />
                        {errors.program_name && errors.program_name.type === "required" && (
                          <p className="errorMsg" style={{ textAlign: 'left' }}>Please enter program name</p>
                        )}
                        <h2 className="flex w-full" style={{ color: "#2B303B", display: 'flex', marginTop: '1%' }}>
                          <span style={{ width: '22%', marginRight: '20px' }}>
                            Visibility
                          </span>
                          <span style={{ textAlign: 'right', width: '16%' }}>
                            <ButtonGroup
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '2px',
                                justifyContent: 'center'
                              }}
                            >

                              <FormControlLabel
                                key="On"
                                value="On"
                                control={<Button size="small" id="program_visibility"
                                  variant={program_visibility == true ? "contained" : "outlined"}
                                  onClick={toggleShowIsvisible} style={{ height: "40px", width: '70px' }} >
                                  <p style={{ fontSize: '13px' }}>{program_visibility == true ? "ON" : "OFF"}</p></Button>}
                                sx={{ padding: "0", fontSize: '13px' }}
                              />

                            </ButtonGroup>
                          </span>
                          <span style={{ textAlign: 'right', width: '30%', marginRight: '10px' }}>
                            <input
                              type="date"
                              id="from_date"
                              defaultValue={editProgramFromDate}
                              {...register("from_date", {
                                required: true
                              })}
                              placeholder="From Date"
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.from_date && errors.from_date.type === "required" && (
                              <p className="errorMsg" style={{ textAlign: 'left' }}>Please select from date</p>
                            )}
                          </span>
                          <span style={{ textAlign: 'right', width: '30%' }}>
                            <input
                              type="date"
                              id="to_date"
                              defaultValue={editProgramToDate}
                              {...register("to_date", {
                                required: true
                              })}
                              placeholder="To Date"
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.to_date && errors.to_date.type === "required" && (
                              <p className="errorMsg" style={{ textAlign: 'left' }}>Please select to date</p>
                            )}
                          </span>

                        </h2>

                        <div style={{ height: '79vh' }}>
                          <div className='add_task_scroller'>
                            <h2 className="flex w-full" style={{ color: "#2B303B", display: 'flex', marginTop: '1%' }}>
                              <span style={{ width: '22%' }}>
                                Shared With
                              </span>
                              <span style={{ textAlign: 'left', width: '78%' }}>
                                <ButtonGroup
                                  value={selectedEditOption}
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '2px',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <FormControlLabel
                                    key="Global"
                                    value="Global"
                                    onClick={() => handleEditTaskClick("global")}
                                    control={<Button size="small" variant={selectedEditOption === "global" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                      <p style={{ fontSize: '13px' }}>Global</p></Button>}
                                    sx={{ padding: "0", fontSize: '13px' }}
                                  />
                                  <FormControlLabel
                                    key="Team"
                                    value="team"
                                    onClick={() => handleEditTaskClick("team")}
                                    control={<Button size="small" variant={selectedEditOption === "team" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                      <p style={{ fontSize: '13px' }}>Team</p></Button>}
                                    sx={{ padding: "0", fontSize: '13px' }}
                                  />
                                  <FormControlLabel
                                    key="Player"
                                    value="player"
                                    onClick={() => handleEditTaskClick("player")}
                                    control={<Button size="small" variant={selectedEditOption === "player" ? "contained" : "outlined"} style={{ height: "40px", width: '100%' }} >
                                      <p style={{ fontSize: '13px' }}>Player</p></Button>}
                                    sx={{ padding: "0", fontSize: '13px' }}
                                  />
                                </ButtonGroup>
                              </span>
                            </h2>
                            <div style={{ marginTop: '4px' }}>
                              {selectedEditOption == 'team' ? (<>
                                <Select
                                  isMulti
                                  options={team}
                                  value={selectedEditTeamOptions}
                                  onChange={handleChangeEditTeam}
                                />
                              </>) : (<></>)}
                              {selectedEditOption == 'player' ? (<>
                                <Select
                                  isMulti
                                  options={player}
                                  value={selectedEditPlayerOptions}
                                  onChange={handleChangeEditPlayer}
                                />
                              </>) : (<></>)}
                            </div>

                            {addProgramFocusList.map((focus_detail, id) => (
                              <div className="w-full" style={{ width: '100%', display: 'flow-root' }} key={id}>
                                <div className="p-2 h-max " >
                                  <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ccc', borderRadius: "5px", textAlign: "left" }}>
                                    <strong>{focus_detail.focus_area_name}</strong>
                                    {focus_detail.task_list.map((task_detail, id) => (
                                      <div className="w-full" style={{ width: "100%", display: 'flow-root' }} key={id}>
                                        <div className="p-2 h-max " >
                                          <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ededed', borderRadius: "5px", textAlign: "left" }}>
                                            <span className='grid grid-cols-2'>
                                              <strong style={{ cursor: 'pointer' }}>{task_detail.taskName}</strong>
                                              <span style={{ textAlign: 'right' }}>
                                                <button className="bg-red-500 text-black rounded " onClick={() => deleteTaskFromList(task_detail)} type='button'>
                                                  <DeleteIcon />
                                                </button>
                                              </span>
                                            </span>

                                            <h2 className="" style={{ color: "#2B303B", display: 'flex' }}>
                                              <span style={{ width: '70%', display: 'flex' }} >
                                                {task_detail.difficulty ? (
                                                  <>
                                                    <div className={task_detail.difficulty == "easier" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'green', padding: task_detail.difficulty == 'easier' ? '4px 30px' : '1px 20px' }}></div>
                                                    <div className={task_detail.difficulty == "easy" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'yellow', padding: task_detail.difficulty == 'easy' ? '4px 30px' : '1px 20px' }}></div>
                                                    <div className={task_detail.difficulty == "medium" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'orange', padding: task_detail.difficulty == 'medium' ? '4px 30px' : '1px 20px' }}></div>
                                                    <div className={task_detail.difficulty == "hard" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'red', padding: task_detail.difficulty == 'hard' ? '4px 30px' : '1px 20px' }}></div>
                                                    <div className={task_detail.difficulty == "harder" ? 'progressSelect' : 'progress'} style={{ backgroundColor: '#850505', padding: task_detail.difficulty == 'harder' ? '4px 30px' : '1px 20px' }}></div>
                                                  </>
                                                ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}
                                              </span>
                                              <span style={{ textAlign: 'right', width: '30%' }}>
                                                <strong>
                                                  {task_detail.duration ? (
                                                    <>{task_detail.duration}
                                                    </>
                                                  ) : (<><span style={{ color: "#FF0000" }}>0</span></>)}{task_detail.duration_type}</strong>
                                                <button type='button' style={{ color: '#1565c0', margin: '0 3px', fontWeight: 'bold' }} onClick={handleRepeatations(task_detail)}>{task_detail.task_repeat}X</button>

                                              </span>
                                            </h2>
                                            <strong>Description :</strong>
                                            {task_detail.description ? (
                                              <>{task_detail.description}</>
                                            ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}

                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="flex w-full gap-2">
                          <button className="flex-1 bg-green-500 text-black py-2 px-4 rounded"
                            type='submit' onClick={EditProgromSaveClicked}
                          >
                            <SaveIcon /> Update
                          </button>
                          <button type="button" className="flex-1 bg-red-500 text-black py-2 px-4 rounded" onClick={handleResetAddColumn2Submit}>
                            <CancelIcon /> Cancel
                          </button>
                          <button className="flex-1 bg-blue-500 text-black py-2 px-4 rounded" onClick={EditProgromPublishClicked}>
                            <PublishIcon />
                            Publish
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="w-full" style={{ width: "100%" }}>
                <div className="bg-white p-2 h-max " >
                  <div style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: "10px", height: '98vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Select Add Program or Edit Program Action
                  </div>
                </div>
              </div>
            )
          )}
          {/* Edit Plan End */}
          {addRepeatations ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto " style={{ width: '30%' }}>

                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >

                    <div style={{
                      margin: '2px',
                      justifyContent: 'end'
                    }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                      <button style={{ width: '40px', margin: '0' }}
                        className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setAddRepeatations(false)}
                      ><ClearIcon /></button>
                    </div>
                    <div className="relative p-6 flex-auto" style={{
                      width: '100%',
                      paddingTop: '0', textAlign: 'center', margin: 'auto'
                    }}>
                      <div className="grid grid-rows-1 gap-3 p-2">
                        <div>
                          Start with task on <span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px", marginRight: "5px" }}><strong>Day</strong></span><span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px" }}><strong>1</strong></span> of Program
                        </div>
                        <div>
                          Continue for <span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px", marginRight: "5px" }}><strong>{task_duration.duration}</strong></span><span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px", marginRight: "5px" }}><strong>{task_duration.duration_type}</strong></span>

                        </div>
                        <div style={{
                          alignItems: 'baseline', textAlign: 'left', display: 'flex',
                          justifyContent: 'center'
                        }}>Repeat &nbsp;
                          <input type="number" style={{ width: '30%' }} placeholder="Enter repeatation" className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" value={task_repeat} onChange={handleRepeatationInput} />&nbsp; after a gap of &nbsp; <span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px", marginRight: "5px" }}><strong>{task_duration.duration}</strong></span><span style={{ padding: "6px", background: "#D3D1D1", borderRadius: "5px", marginRight: "5px" }}><strong>{task_duration.duration_type}</strong></span>
                        </div>
                      </div>
                      <button className='flex-1 bg-green-500 text-black py-2 px-4 rounded' onClick={() => handleAddRepeatationSubmit(task_duration)}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}


          {/* Task List Start */}
          <div className="w-full" style={{ width: "100%" }}>
            <div className="bg-white p-2 h-max " >
              <div className="grid grid-cols-1 gap-2 p-2" style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#d5fff7', borderRadius: "10px", height: '98vh' }}>
                <div style={{ textAlign: 'center' }} className="text-base ">
                  <input
                    type="text"
                    placeholder="Mention search keyword and enter"
                    onKeyDown={handleKeyPressSearchTask}
                    value={searchTaskName}
                    onChange={handleSearchTaskNameChange}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    style={{ width: '100%' }}
                  />

                  <div className="bowlerButtonGroup" style={{
                    float: "left",
                    marginTop: "1%",
                    marginLeft: '2%'
                  }}>
                    <ButtonGroup
                      value={selectedSearchTask}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '2px',
                        justifyContent: 'center'
                      }}
                    >
                      <FormControlLabel
                        key="TaskName"
                        value="task_name"
                        onClick={() => handleSearchTaskClick("task_name")}
                        control={<Button variant={selectedSearchTask === "task_name" ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '130px', marginLeft: '5px' }} ><p style={{ fontSize: '13px' }}>Activity Name</p></Button>}
                        sx={{ padding: "0", fontSize: '13px' }}
                      />

                      <select
                        id="difficulty"
                        size="small"
                        style={{ height: "35px", width: '120px' }}
                        onChange={handleSearchTaskDifficultyClick}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      >
                        <option value=''>Select Difficulty</option>
                        <option value="easier">Easier</option>
                        <option value="easy">Easy</option>
                        <option value="medium">Medium</option>
                        <option value="hard">Hard</option>
                        <option value="harder">Harder</option>
                      </select>
                      <select
                        id="duration_type"
                        style={{ height: "35px", width: '120px' }}
                        onChange={handleSearchTaskDurationTypeClick}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      >
                        <option value=''>Select Duration Type</option>
                        <option value="D"> &#8804; 1 Week</option>
                        <option value="M">&gt; 1 Week &lt; 1 Month</option>
                        <option value="Y">&gt; 1 Month</option>
                      </select>

                    </ButtonGroup>
                  </div>

                  <div style={{ height: '85vh' }}>

                    <div className="task_scroller">
                      {taskList.map((task_detail, id) => (
                        <div key={id} className="w-full" style={{ width: "100%", display: 'flow-root' }}>
                          <div className="p-2 h-max " >
                            <div className={`grid grid-cols-1 gap-2 p-2 ${selectedTaskId === task_detail.id ? 'selected_task' : ''}`} style={{ boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', background: '#ededed', borderRadius: "5px", textAlign: "left" }}>
                              <span className='grid grid-cols-2'>
                                <strong onClick={handleSelectTask(task_detail.id)} style={{ cursor: 'pointer' }}>{task_detail.taskName}</strong>
                                <span style={{ textAlign: 'right' }}>
                                  <button className="bg-green-500 text-black rounded " onClick={() => addTaskToFocus(task_detail)} type='button'>
                                    <AddIcon />
                                  </button>
                                </span>
                              </span>
                              <h2 className="" style={{ color: "#2B303B", display: 'flex' }}>
                                <span style={{ width: '70%', display: 'flex' }} >
                                  {task_detail.difficulty ? (
                                    <>
                                      <div className={task_detail.difficulty == "easier" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'green', padding: task_detail.difficulty == 'easier' ? '4px 30px' : '1px 20px' }}></div>
                                      <div className={task_detail.difficulty == "easy" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'yellow', padding: task_detail.difficulty == 'easy' ? '4px 30px' : '1px 20px' }}></div>
                                      <div className={task_detail.difficulty == "medium" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'orange', padding: task_detail.difficulty == 'medium' ? '4px 30px' : '1px 20px' }}></div>
                                      <div className={task_detail.difficulty == "hard" ? 'progressSelect' : 'progress'} style={{ backgroundColor: 'red', padding: task_detail.difficulty == 'hard' ? '4px 30px' : '1px 20px' }}></div>
                                      <div className={task_detail.difficulty == "harder" ? 'progressSelect' : 'progress'} style={{ backgroundColor: '#850505', padding: task_detail.difficulty == 'harder' ? '4px 30px' : '1px 20px' }}></div>
                                    </>
                                  ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}
                                </span>
                                <span style={{ textAlign: 'right', width: '30%' }}>
                                  <strong>
                                    {task_detail.duration ? (
                                      <>{task_detail.duration}</>
                                    ) : (<><span style={{ color: "#FF0000" }}>0</span></>)}{task_detail.duration_type}</strong>
                                </span>

                              </h2>
                              <strong>Description :</strong>
                              {task_detail.description ? (
                                <>{task_detail.description}</>
                              ) : (<><span style={{ color: "#FF0000" }}>Not Mentioned</span></>)}

                            </div>
                          </div>
                        </div>
                      ))}
                      {taskList.length == 0 ? (<><span style={{ color: "#FF0000" }}>No record found</span></>) : (<></>)}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <button className="flex-1 bg-green-500 text-black py-2 px-1 rounded mr-2" onClick={handleModalAddTask} type='button'>
                      <AddIcon /> Add
                    </button>
                    <button className="flex-1 bg-yellow-500 text-black py-2 px-1 rounded mr-2" onClick={handleModalEditTask}>
                      <EditIcon /> Edit
                    </button>
                    <button className="flex-1 bg-red-500 text-black py-2 px-1 rounded"
                    >
                      <DeleteIcon /> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Task List End */}
        </div>
      </div>

      <form onSubmit={handleSubmit(handleAddTaskSubmit)}>
        {showAddTask ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>Add Activity </h2>
                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowAddTask(false)}
                    ><ClearIcon />
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', margin: 'auto', height: '600px', overflowY: 'scroll'
                  }}>

                    <div>
                      <div>
                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="task"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Activity<span className="errorMsg">*</span>
                            </label>
                            <input
                              type="text"
                              id="task"
                              {...register("task", {
                                required: true
                              })}
                              placeholder="Please enter activity "
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.task && errors.task.type === "required" && (
                              <p className="errorMsg">Please enter activity </p>
                            )}
                          </div>

                          <div className="form-control">
                            <label
                              htmlFor="difficulty"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Difficulty<span className="errorMsg">*</span>
                            </label>
                            <select
                              id="difficulty"
                              {...register("difficulty", {
                                required: true,
                                validate: () => getValues("difficulty") != "Select Difficulty",
                              })}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            >
                              <option>Select Difficulty</option>
                              <option value="easier">Easier</option>
                              <option value="easy">Easy</option>
                              <option value="medium">Medium</option>
                              <option value="hard">Hard</option>
                              <option value="harder">Harder</option>
                            </select>
                            {errors.difficulty && errors.difficulty.type === "required" && (
                              <p className="errorMsg">Please select difficulty</p>
                            )}
                            {errors.difficulty && errors.difficulty.type === "validate" && (
                              <p className="errorMsg">Please select difficulty</p>
                            )}
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="duration"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Duration<span className="errorMsg">*</span>
                            </label>
                            <input
                              type="number"
                              id="duration"
                              {...register("duration", {
                                required: true,
                                min: {
                                  value: 1
                                },
                              })}
                              placeholder="Number of duration"
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.duration && errors.duration.type === "required" && (
                              <p className="errorMsg">Please enter duration</p>
                            )}
                            {errors.duration && errors.duration.type === "min" && (
                              <p className="errorMsg">Please enter a duration more than 0</p>
                            )}
                          </div>
                          <div className="form-control">
                            <label
                              htmlFor="Duration Type"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Duration Type<span className="errorMsg">*</span>
                            </label>
                            <select
                              id="duration_type"
                              {...register("duration_type", {
                                required: true,
                                validate: () => getValues("duration_type") != "Select Duration Type",
                              })}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            >
                              <option>Select Duration Type</option>
                              <option value="D">Day</option>
                              <option value="M">Month</option>
                              <option value="Y">Year</option>
                            </select>
                            {errors.duration_type && errors.duration_type.type === "required" && (
                              <p className="errorMsg">Please select duration type</p>
                            )}
                            {errors.duration_type && errors.duration_type.type === "validate" && (
                              <p className="errorMsg">Please select duration type</p>
                            )}
                          </div>

                        </div>
                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Activity"
                            className="form-label inline-block text-gray-700"
                          >
                            Skill
                          </label>
                          <div >
                            <ButtonGroup
                              value={selectedActivityCategory}
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '2px'
                              }}
                            >
                              {activityCategory.map((type, id) => (
                                <FormControlLabel
                                  key={id}
                                  value={type?.id}
                                  onClick={() => handleActivityCategoryClick(type?.id)}
                                  control={<Button variant={selectedActivityCategory === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '150px' }} ><p style={{ fontSize: '13px' }}>{type?.category_name}</p></Button>}

                                />
                              ))}
                            </ButtonGroup>
                          </div>

                        </div>

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Focus Area"
                            className="form-label inline-block text-gray-700"
                          >
                            Focus Area
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedFocusArea}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {focusArea.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleFocusAreaClick(type?.id)}
                                    control={<Button variant={selectedFocusArea === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>{type?.focus_area_name}</p></Button>}

                                  />
                                ))}
                              </ButtonGroup>
                              {focusArea.length == 0 ? (<p style={{ color: '#1565c0' }}>Please add custom focus area</p>) : (<></>)}
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddFocusArea(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>

                        {showAddFocusArea ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Focus Area"
                                className="form-label inline-block text-gray-700"
                              >
                                Focus Area
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_focus_area"
                                    value={newFocusArea}
                                    onChange={handleNewFocusAreaChange}
                                    placeholder="Please enter focus area"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddFocusArea(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveFocusArea()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Focus Area Sub Category"
                            className="form-label inline-block text-gray-700"
                          >
                            Focus Area Sub Category
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedFocusAreaSubCategory}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {focusAreaSubCategory.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleFocusAreaSubCategoryClick(type?.id)}
                                    control={<Button variant={selectedFocusAreaSubCategory === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>{type?.focus_area_sub_category_name}</p></Button>}

                                  />
                                ))}
                              </ButtonGroup>
                              {focusAreaSubCategory.length == 0 ? (<p style={{ color: '#1565c0' }}>Please add custom focus area sub category</p>) : (<></>)}
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddFocusAreaSubCategory(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>

                        {showAddFocusAreaSubCategory ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Focus Area"
                                className="form-label inline-block text-gray-700"
                              >
                                Focus Area Sub Category
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_focus_area_sub_category"
                                    value={newFocusAreaSubCategory}
                                    onChange={handleNewFocusAreaSubCategoryChange}
                                    placeholder="Please enter focus area sub category"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddFocusAreaSubCategory(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveFocusAreaSubCategory()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>

                            </div>
                          </>) : (<></>)}

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Description
                            </label>
                            <textarea
                              id="description"
                              placeholder="Please enter description"
                              {...register("description", {
                                required: false,
                              })}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="resources"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Resources &nbsp;&nbsp;(&nbsp;
                              <label>
                                <input
                                  type="radio"
                                  value="url"
                                  checked={selectedResourceOption === "url"}
                                  onChange={handleResourceOptionChange}
                                />
                                &nbsp;URL&nbsp;
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  value="file_upload"
                                  checked={selectedResourceOption === "file_upload"}
                                  onChange={handleResourceOptionChange}
                                />
                                &nbsp;File Upload
                              </label>
                            </label>
                            &nbsp;)

                            {selectedResourceOption === "url" ? (
                              <input
                                id="resource_url"
                                placeholder="Please enter resource URL"
                                {...register("resource_url", {
                                  required: false,
                                })}
                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                              />
                            ) : (

                              <div className="pt-2">
                                <div className="flex items-center justify-center w-full" {...getRootProps()}>
                                  <label
                                    for="dropzone-file"
                                    onClick={(e) => { e.stopPropagation() }}
                                    className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                  >
                                    <div className="flex flex-col items-center justify-center pt-3 pb-3">
                                      {file !== undefined ? (
                                        <span className="font-semibold text-base mb-3">{file?.name}</span>
                                      ) : (
                                        <svg
                                          aria-hidden="true"
                                          className="w-10 h-10 mb-3 text-gray-400"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                          ></path>
                                        </svg>
                                      )}
                                      <p className="mb-2 text-sm text-gray-500">
                                        <span className="font-semibold">Click to upload</span> or drag
                                        and drop
                                      </p>
                                      <p className="text-xs text-gray-500">
                                        MP4, MMV, AVI, MOV or MKV (MAX. 1 GB).
                                      </p>
                                    </div>
                                    <input
                                      id="dropzone-file"
                                      type="file"
                                      className="hidden"
                                      accept="video/*;"
                                      onChange={handleFileSelect}
                                      {...getInputProps()}
                                    />
                                  </label>
                                  {customErrors != null && customErrors?.input && customErrors?.input?.type === "required" && (
                                    <p className="errorMsg">Video is required.</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{
                                  backgroundColor: '#04AA6D',
                                  color: 'white'
                                }}>
                                  <th style={{ width: '60%' }}>Submissions</th>
                                  <th style={{ width: '40%' }}>Compulsory / Optional</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr >
                                  <td>Read</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRead("Compulsory")}
                                        variant={isSubmissionRead === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRead("Optional")}
                                        variant={isSubmissionRead === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                                <tr >
                                  <td>Watch Video</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionWatchVideo("Compulsory")}
                                        variant={isSubmissionWatchVideo === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionWatchVideo("Optional")}
                                        variant={isSubmissionWatchVideo === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                                <tr >
                                  <td>Request Video</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRequestVideo("Compulsory")}
                                        variant={isSubmissionRequestVideo === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRequestVideo("Optional")}
                                        variant={isSubmissionRequestVideo === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {showAddEvaluationBy ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Evaluation By"
                                className="form-label inline-block text-gray-700"
                              >
                                Coach
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >
                                  <select
                                    id="new_evaluation_by"
                                    value={newEvaluationBy}
                                    onChange={handleNewEvaluationByChange}
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                  >
                                    <option>Select Coach</option>
                                    {coachList.map((coach, id) => (
                                      <option value={coach.id} key={id}>
                                        {coach.full_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddEvaluationBy(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveEvaluationBy()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Payment"
                            className="form-label inline-block text-gray-700"
                          >
                            Payment
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedTaskPayment}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {taskPayment.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleTaskPaymentClick(type?.id)}
                                    control={<Button variant={selectedTaskPayment === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>

                                      {type?.payment_duration != null ? (
                                        <>{type?.payment_name}/{type?.payment_duration}</>

                                      ) : (
                                        <>
                                          {type?.payment_name > 0 ? (
                                            <>{type?.payment_name}/-</>

                                          ) : (
                                            <>{type?.payment_name}</>
                                          )
                                          }
                                        </>
                                      )
                                      }

                                    </p></Button>}

                                  />
                                ))}
                              </ButtonGroup>
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddPayment(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>

                        {showAddPayment ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Payment Name"
                                className="form-label inline-block text-gray-700"
                              >
                                Payment Name & Duration
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "30%",
                                  marginRight: "5px"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_payment_name"
                                    value={newPaymentName}
                                    onChange={handleNewPaymentNameChange}
                                    placeholder="Please enter payment name"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />

                                </div>
                                <div style={{
                                  width: "30%"
                                }}
                                >
                                  <select
                                    id="new_payment_duration"
                                    value={newPaymentDuration}
                                    onChange={handleNewPaymentDurationChange}
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                  >
                                    <option>Select Duration</option>
                                    <option value="D">Day</option>
                                    <option value="M">Month</option>
                                    <option value="Y">Year</option>
                                  </select>
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddPayment(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => savePayment()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                      type="button"
                      onClick={() => setShowAddTask(false)}
                    >
                      <CancelIcon /> Cancel Activity
                    </button>
                    <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit'>
                      <SaveIcon /> Save Changes
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>


      <form onSubmit={handleSubmit(handleEditTaskSubmit)}>
        {showEditTask ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">
                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>Edit Activity </h2>
                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowEditTask(false)}
                    ><ClearIcon />
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', margin: 'auto', height: '600px', overflowY: 'scroll'
                  }}>

                    <div>
                      <div>
                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="task"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Activity<span className="errorMsg">*</span>
                            </label>
                            <input
                              type="text"
                              id="task"
                              defaultValue={EditTaskName}
                              {...register("task", {
                                required: true
                              })}
                              placeholder="Please enter activity "
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.task && errors.task.type === "required" && (
                              <p className="errorMsg">Please enter activity </p>
                            )}
                          </div>

                          <div className="form-control">
                            <label
                              htmlFor="difficulty"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Difficulty<span className="errorMsg">*</span>
                            </label>
                            <select
                              id="difficulty"
                              onChange={handleChangeEditTaskDifficulty}
                              value={EditTaskDifficulty}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            >
                              <option value="">Select Difficulty</option>
                              <option value="easier">Easier</option>
                              <option value="easy">Easy</option>
                              <option value="medium">Medium</option>
                              <option value="hard">Hard</option>
                              <option value="harder">Harder</option>
                            </select>
                            {EditTaskDifficulty === "" && (
                              <p className="errorMsg">Please select difficulty</p>
                            )}
                            {errors.difficulty && errors.difficulty.type === "validate" && (
                              <p className="errorMsg">Please select difficulty</p>
                            )}
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="duration"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Duration<span className="errorMsg">*</span>
                            </label>
                            <input
                              type="number"
                              id="duration"
                              defaultValue={EditTaskDuration}
                              {...register("duration", {
                                required: true,
                                min: {
                                  value: 1
                                }
                              })}
                              placeholder="Number of days"
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                            {errors.duration && errors.duration.type === "required" && (
                              <p className="errorMsg">Please enter duration</p>
                            )}
                            {errors.duration && errors.duration.type === "min" && (
                              <p className="errorMsg">Please enter a duration more than 0</p>
                            )}
                          </div>

                          <div className="form-control">
                            <label
                              htmlFor="Duration Type"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Duration Type<span className="errorMsg">*</span>
                            </label>
                            <select
                              id="duration_type"
                              value={EditTaskDurationType}
                              onChange={handleChangeEditTaskDurationType}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            >
                              <option value=''>Select Duration Type</option>
                              <option value="D">Day</option>
                              <option value="M">Month</option>
                              <option value="Y">Year</option>
                            </select>
                            {EditTaskDurationType === "" && (
                              <p className="errorMsg">Please select duration type</p>
                            )}
                            {errors.duration_type && errors.duration_type.type === "validate" && (
                              <p className="errorMsg">Please select duration type</p>
                            )}
                          </div>

                        </div>
                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Activity"
                            className="form-label inline-block text-gray-700"
                          >
                            Skill
                          </label>
                          <div >
                            <ButtonGroup
                              value={selectedActivityCategory}
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '2px'
                              }}
                            >
                              {activityCategory.map((type, id) => (
                                <FormControlLabel
                                  key={id}
                                  value={type?.id}
                                  onClick={() => handleActivityCategoryClick(type?.id)}
                                  control={<Button variant={selectedActivityCategory === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '150px' }} ><p style={{ fontSize: '13px' }}>{type?.category_name}</p></Button>}

                                />
                              ))}
                            </ButtonGroup>
                          </div>
                        </div>

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Focus Area"
                            className="form-label inline-block text-gray-700"
                          >
                            Focus Area
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedFocusArea}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {focusArea.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleFocusAreaClick(type?.id)}
                                    control={<Button variant={selectedFocusArea === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>{type?.focus_area_name}</p></Button>}

                                  />
                                ))}
                              </ButtonGroup>
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddFocusArea(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>


                        {showAddFocusArea ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Focus Area"
                                className="form-label inline-block text-gray-700"
                              >
                                Focus Area
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_focus_area"
                                    value={newFocusArea}
                                    onChange={handleNewFocusAreaChange}
                                    placeholder="Please enter focus area"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddFocusArea(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveFocusArea()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Focus Area Sub Category"
                            className="form-label inline-block text-gray-700"
                          >
                            Focus Area Sub Category
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedFocusAreaSubCategory}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {focusAreaSubCategory.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleFocusAreaSubCategoryClick(type?.id)}
                                    control={<Button variant={selectedFocusAreaSubCategory === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>{type?.focus_area_sub_category_name}</p></Button>}

                                  />
                                ))}
                              </ButtonGroup>
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddFocusAreaSubCategory(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>

                        {showAddFocusAreaSubCategory ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Focus Area"
                                className="form-label inline-block text-gray-700"
                              >
                                Focus Area Sub Category
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_focus_area_sub_category"
                                    value={newFocusAreaSubCategory}
                                    onChange={handleNewFocusAreaSubCategoryChange}
                                    placeholder="Please enter focus area sub category"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />
                                </div>

                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddFocusAreaSubCategory(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveFocusAreaSubCategory()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}


                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Description
                            </label>

                            <textarea
                              id="description"
                              placeholder="Please enter description"
                              defaultValue={EditTaskDescription}
                              {...register("description", {
                                required: false,
                              })}
                              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                            />
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="resources"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              Resources &nbsp;&nbsp;(&nbsp;
                              <label>
                                <input
                                  type="radio"
                                  value="url"
                                  checked={selectedResourceOption === "url"}
                                  onChange={handleResourceOptionChange}
                                />
                                &nbsp;URL&nbsp;
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  value="file_upload"
                                  checked={selectedResourceOption === "file_upload"}
                                  onChange={handleResourceOptionChange}
                                />
                                &nbsp;File Upload
                              </label>
                            </label>
                            &nbsp;)

                            {selectedResourceOption === "url" ? (
                              <input
                                id="resource_url"
                                placeholder="Please enter resource URL"
                                defaultValue={EditTaskResourceUrl}
                                {...register("resource_url", {
                                  required: false,
                                })}
                                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                              />
                            ) : (<>&nbsp;
                              {editVideoUrl ? (<><a href={editVideoUrl} target='_blank' style={{ color: 'blue' }}>Download Video</a></>) : (<></>)}
                              < label
                                for="dropzone-file"
                                onClick={(e) => { e.stopPropagation() }}
                                className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                              >
                                <div className="flex flex-col items-center justify-center pt-3 pb-3">
                                  {file_edit !== undefined ? (
                                    <span className="font-semibold text-base mb-3">{file_edit?.name}</span>
                                  ) : (
                                    <svg
                                      aria-hidden="true"
                                      className="w-10 h-10 mb-3 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                      ></path>
                                    </svg>
                                  )}
                                  <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">Click to upload</span> or drag
                                    and drop
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    MP4, MMV, AVI, MOV or MKV (MAX. 1 GB)
                                  </p>
                                </div>
                                <input
                                  id="dropzone-file"
                                  type="file"
                                  className="hidden"
                                  accept="video/*;"
                                  onChange={handleFileSelectEdit}
                                  capture
                                />
                              </label>
                            </>)}
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{
                                  backgroundColor: '#04AA6D',
                                  color: 'white'
                                }}>
                                  <th style={{ width: '60%' }}>Submissions</th>
                                  <th style={{ width: '40%' }}>Compulsory / Optional</th>
                                </tr>
                              </thead>
                              <tbody>

                                <tr >
                                  <td>Read</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRead("Compulsory")}
                                        variant={isSubmissionRead === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRead("Optional")}
                                        variant={isSubmissionRead === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                                <tr >
                                  <td>Watch Video</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionWatchVideo("Compulsory")}
                                        variant={isSubmissionWatchVideo === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionWatchVideo("Optional")}
                                        variant={isSubmissionWatchVideo === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                                <tr >
                                  <td>Request Video</td>
                                  <td>
                                    <ButtonGroup
                                      variant="outlined"
                                      size="small"
                                      aria-label="outlined button group"
                                    >
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRequestVideo("Compulsory")}
                                        variant={isSubmissionRequestVideo === "Compulsory" ? "contained" : "outlined"}>
                                        Compulsory
                                      </Button>
                                      <Button style={{ padding: "8px" }} onClick={handleSubmissionRequestVideo("Optional")}
                                        variant={isSubmissionRequestVideo === "Optional" ? "contained" : "outlined"}>
                                        Optional
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {showAddEvaluationBy ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Evaluation By"
                                className="form-label inline-block text-gray-700"
                              >
                                Coach
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "50%"
                                }}
                                >
                                  <select
                                    id="new_evaluation_by"
                                    value={newEvaluationBy}
                                    onChange={handleNewEvaluationByChange}
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                  >
                                    <option>Select Coach</option>
                                    {coachList.map((coach, id) => (
                                      <option value={coach.id} key={id}>
                                        {coach.full_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>


                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddEvaluationBy(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => saveEvaluationBy()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>

                            </div>
                          </>) : (<></>)}

                        <div className="m-4 grid grid-cols-1 gap-2 form-control">
                          <label
                            htmlFor="Payment"
                            className="form-label inline-block text-gray-700"
                          >
                            Payment
                          </label>

                          <div style={{ display: 'flex' }}>
                            <div style={{
                              width: "82%"
                            }}
                            >
                              <ButtonGroup
                                value={selectedTaskPayment}
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '2px'
                                }}
                              >
                                {taskPayment.map((type, id) => (
                                  <FormControlLabel
                                    key={id}
                                    value={type?.id}
                                    onClick={() => handleTaskPaymentClick(type?.id)}
                                    control={<Button variant={selectedTaskPayment === type?.id ? "contained" : "outlined"} size="small" style={{ height: "35px", width: '100%' }} ><p style={{ fontSize: '13px' }}>

                                      {type?.payment_duration != null ? (
                                        <>{type?.payment_name}/{type?.payment_duration}</>

                                      ) : (
                                        <>
                                          {type?.payment_name > 0 ? (
                                            <>{type?.payment_name}/-</>

                                          ) : (
                                            <>{type?.payment_name}</>
                                          )
                                          }
                                        </>
                                      )
                                      }

                                    </p></Button>}
                                  />
                                ))}
                              </ButtonGroup>
                            </div>

                            <div>
                              <button
                                className="bg-yellow-500 text-black py-1 px-3 rounded mr-2"
                                type="button"
                                onClick={() => setShowAddPayment(true)}
                              >
                                <AddIcon /> Add Custom
                              </button>
                            </div>
                          </div>
                        </div>

                        {showAddPayment ? (
                          <>
                            <div className="m-4 grid grid-cols-1 gap-2 form-control">
                              <label
                                htmlFor="Payment Name"
                                className="form-label inline-block text-gray-700"
                              >
                                Payment Name & Duration
                              </label>

                              <div style={{ display: 'flex' }}>
                                <div style={{
                                  width: "30%",
                                  marginRight: "5px"
                                }}
                                >

                                  <input
                                    type="text"
                                    id="new_payment_name"
                                    value={newPaymentName}
                                    onChange={handleNewPaymentNameChange}
                                    placeholder="Please enter payment name"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mr-2"
                                  />

                                </div>
                                <div style={{
                                  width: "30%"
                                }}
                                >
                                  <select
                                    id="new_payment_duration"
                                    value={newPaymentDuration}
                                    onChange={handleNewPaymentDurationChange}
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                  >
                                    <option>Select Duration</option>
                                    <option value="D">Day</option>
                                    <option value="M">Month</option>
                                    <option value="Y">Year</option>
                                  </select>
                                </div>


                                <div style={{ marginLeft: '8px' }}>
                                  <button
                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => setShowAddPayment(false)}
                                  >
                                    <CancelIcon /> Cancel
                                  </button>
                                  <button
                                    className="bg-green-500 text-black py-1 px-3 rounded mr-2"
                                    type="button"
                                    onClick={() => savePayment()}
                                  >
                                    <SaveIcon /> Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>) : (<></>)}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                      type="button"
                      onClick={() => setShowEditTask(false)}
                    >
                      <CancelIcon /> Cancel Activity
                    </button>
                    <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit'>
                      <SaveIcon /> Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form >

      <form onSubmit={handleSubmit(handleDeleteTaskSubmit)}>
        {showDeleteTask ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  {/*header*/}
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>Delete Task</h2>

                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowDeleteTask(false)}
                    ><ClearIcon />
                    </button>
                  </div>


                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', margin: 'auto', height: '600px', overflowY: 'scroll'
                  }}>

                    <div>
                      <div>
                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="task"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Task:</strong> <span>{EditTaskName}</span>
                            </label>
                            <p id="task">

                            </p>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Programs:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '10%' }}>Sr. No.</th>
                                  <th style={{ width: '90%' }}>Program Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {deleteProgramList.map((program_detail, index) => (
                                  <tr >
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.planningName ? program_detail.planningName : ""}
                                    </td>
                                  </tr>
                                ))}

                                {deleteProgramList.length == 0 ? (<><tr >
                                  <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={2}>No record found</td></tr></>) : (<></>)}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Transactions:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '8%' }}>Sr. No.</th>
                                  <th style={{ width: '20%' }}>User Name</th>
                                  <th style={{ width: '25%' }}>Program Name</th>
                                  <th style={{ width: '10%' }}>Price</th>
                                  <th style={{ width: '10%' }}>Status</th>
                                  <th style={{ width: '30%' }}>Order Date</th>
                                </tr>
                              </thead>

                              <tbody>
                                {deleteTransactionList.map((program_detail, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.user_detail ? program_detail.user_detail.full_name : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.task_planning_detail
                                        ? program_detail.task_planning_detail.planningName
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.price ? program_detail.price : "N/A"}
                                    </td>

                                    <td>
                                      {program_detail.status ? capitalizeFirstLetter(program_detail.status.toString()) : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.order_date ? convertDate(program_detail.order_date) : "N/A"}
                                    </td>
                                  </tr>
                                ))}
                                {deleteTransactionList.length === 0 ? (
                                  <tr>
                                    <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={6}>
                                      No record found
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Player Completed Tasks:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '8%' }}>Sr. No.</th>
                                  <th style={{ width: '15%' }}>Player Name</th>
                                  <th style={{ width: '22%' }}>Program Name</th>
                                  <th style={{ width: '13%' }}>Focus Area</th>
                                  <th style={{ width: '25%' }}>Mobile Evaluation Type</th>
                                  <th style={{ width: '20%' }}>Coach/Self Name</th>
                                </tr>
                              </thead>

                              <tbody>
                                {deletePlayerCompletedList.map((program_detail, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.player_detail ? program_detail.player_detail.full_name : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.programplaning_detail
                                        ? program_detail.programplaning_detail.planningName
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.focus_area_detail ? program_detail.focus_area_detail.focus_area_name : "N/A"}
                                    </td>

                                    <td>
                                      {program_detail.mobile_evaluation_from_type ? program_detail.mobile_evaluation_from_type : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.mobile_evaluation_from_detail ? program_detail.mobile_evaluation_from_detail.full_name : "N/A"}
                                    </td>
                                  </tr>
                                ))}
                                {deletePlayerCompletedList.length === 0 ? (
                                  <tr>
                                    <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={6}>
                                      No record found
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                      type="button"
                      onClick={() => setShowDeleteTask(false)}
                    >
                      <CancelIcon /> Cancel Activity
                    </button>
                    <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit'>
                      <SaveIcon /> Delete Task
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form >

      <form onSubmit={handleSubmit(handleDeleteProgramSubmit)}>
        {showDeleteProgram ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                  <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>Delete Program</h2>

                    <button style={{ width: '40px', margin: '0' }}
                      className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowDeleteProgram(false)}
                    ><ClearIcon />
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto" style={{
                    width: '100%',
                    paddingTop: '0', margin: 'auto', height: '600px', overflowY: 'scroll'
                  }}>
                    <div>
                      <div>
                        <div className="m-2 grid grid-cols-2 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="task"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Program:</strong> <span>{editProgramName}</span>
                            </label>
                            <p id="task">

                            </p>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Programs Active Players:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '10%' }}>Sr. No.</th>
                                  <th style={{ width: '20%' }}>Player Name</th>
                                  <th style={{ width: '20%' }}>Evalution By</th>
                                  <th style={{ width: '20%' }}>Coach/Self Name</th>
                                  <th style={{ width: '20%' }}>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {previewProgramActivePlayerList.map((program_detail, index) => (
                                  <tr >
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.user_detail ? program_detail.user_detail.full_name : "NA"}
                                    </td>
                                    <td>
                                      {program_detail.evalution_by ? program_detail.evalution_by : "NA"}
                                    </td>
                                    <td>
                                      {program_detail.coach_detail ? program_detail.coach_detail.full_name : "NA"}
                                    </td>
                                    <td>
                                      {program_detail.status ? program_detail.status : "NA"}
                                    </td>
                                  </tr>
                                ))}

                                {previewProgramActivePlayerList.length === 0 ? (
                                  <tr>
                                    <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={6}>
                                      No record found
                                    </td>
                                  </tr>
                                ) : null}

                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Tasks:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '10%' }}>Sr. No.</th>
                                  <th style={{ width: '90%' }}>Task Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {previewdeleteProgramList.map((program_detail, index) => (
                                  <tr >
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.task_detail ? program_detail.task_detail.taskName : ""}
                                    </td>
                                  </tr>
                                ))}

                                {previewdeleteProgramList.length == 0 ? (<><tr >
                                  <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={2}>No record found</td></tr></>) : (<></>)}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Program Shared With Players:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '10%' }}>Sr. No.</th>
                                  <th style={{ width: '90%' }}>Player Name</th>
                                </tr>
                              </thead>
                              <tbody>

                                {previewTaskPlanningPlayerList.map((program_detail, index) => (
                                  <tr >
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.player_detail ? program_detail.player_detail.full_name : "N/A"}
                                    </td>
                                  </tr>
                                ))}

                                {previewTaskPlanningPlayerList.length == 0 ? (<><tr >
                                  <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={2}>No record found</td></tr></>) : (<></>)}

                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">
                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Program Shared With Teams:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '10%' }}>Sr. No.</th>
                                  <th style={{ width: '90%' }}>Team Name</th>
                                </tr>
                              </thead>
                              <tbody>

                                {previewTaskPlanningTeamList.map((program_detail, index) => (
                                  <tr >
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.team_detail ? program_detail.team_detail.name : "N/A"}
                                    </td>
                                  </tr>
                                ))}

                                {previewTaskPlanningTeamList.length == 0 ? (<><tr >
                                  <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={2}>No record found</td></tr></>) : (<></>)}

                              </tbody>
                            </table>
                          </div>
                        </div>


                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Transactions:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '8%' }}>Sr. No.</th>
                                  <th style={{ width: '20%' }}>User Name</th>
                                  <th style={{ width: '25%' }}>Task Name</th>
                                  <th style={{ width: '10%' }}>Price</th>
                                  <th style={{ width: '10%' }}>Status</th>
                                  <th style={{ width: '15%' }}>Order Date</th>
                                </tr>
                              </thead>

                              <tbody>
                                {previewTaskPlanningTransactionList.map((program_detail, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.user_detail ? program_detail.user_detail.full_name : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.task_detail
                                        ? program_detail.task_detail.taskName
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.price ? program_detail.price : "N/A"}
                                    </td>

                                    <td>
                                      {program_detail.status ? capitalizeFirstLetter(program_detail.status.toString()) : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.order_date ? convertDate(program_detail.order_date) : "N/A"}
                                    </td>
                                  </tr>
                                ))}
                                {previewTaskPlanningTransactionList.length === 0 ? (
                                  <tr>
                                    <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={6}>
                                      No record found
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <label
                              htmlFor="description"
                              className="form-label inline-block mb-2 text-gray-700"
                            >
                              <strong>Player Completed Tasks:</strong>
                            </label>
                          </div>
                        </div>

                        <div className="m-2 grid grid-cols-1 gap-2">

                          <div className="form-control">
                            <table className="table-auto custom-table" style={{ border: '1px solid white', backgroundColor: '#d5fff7', width: '100%' }}>
                              <thead>
                                <tr style={{ backgroundColor: '#04AA6D', color: 'white' }}>
                                  <th style={{ width: '8%' }}>Sr. No.</th>
                                  <th style={{ width: '15%' }}>Player Name</th>
                                  <th style={{ width: '22%' }}>Task Name</th>
                                  <th style={{ width: '13%' }}>Focus Area</th>
                                  <th style={{ width: '25%' }}>Mobile Evaluation Type</th>
                                  <th style={{ width: '20%' }}>Coach/Self Name</th>
                                </tr>
                              </thead>

                              <tbody>
                                {previewTaskProgramSubmitedList.map((program_detail, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {program_detail.player_detail ? program_detail.player_detail.full_name : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.task_detail
                                        ? program_detail.task_detail.taskName
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.focus_area_detail ? program_detail.focus_area_detail.focus_area_name : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.mobile_evaluation_from_type ? program_detail.mobile_evaluation_from_type : "N/A"}
                                    </td>
                                    <td>
                                      {program_detail.mobile_evaluation_from_detail ? program_detail.mobile_evaluation_from_detail.full_name : "N/A"}
                                    </td>
                                  </tr>
                                ))}
                                {previewTaskProgramSubmitedList.length === 0 ? (
                                  <tr>
                                    <td style={{ color: "#FF0000", textAlign: "center" }} colSpan={6}>
                                      No record found
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                      type="button"
                      onClick={() => setShowDeleteProgram(false)}
                    >
                      <CancelIcon /> Cancel Program
                    </button>
                    <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit'>
                      <SaveIcon /> Delete Program
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
    </>
  )
}
export default TaskPlanner