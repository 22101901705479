import React from "react";

const ListHeader = () => {
  return (
    <div className="grid grid-cols-9 border-black border-b sticky top-0 z-10 bg-slate-300">
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Sr. No.
      </div>
      <div className="col-span-2 flex items-center justify-center border-r border-black text-center">
        Player Name
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Gender
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Contact Number
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Date of Birth
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Player Profile
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Batting
      </div>
      <div className="col-span-1 flex items-center justify-center text-center">
        Bowling
      </div>
    </div>
  );
};

export default ListHeader;
