import axios from "axios";

const cancelToken = axios.CancelToken;
const requests = [];

export const GetAllMatchesByTournament = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-matches-by-tournament/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetAllBatBallTypes = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-bat-ball-types`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetTeamsByMatchID = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-match-teams/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const StartAnalysis = async (analysis) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-analysis`,
      analysis,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const GetAllTeamMembersListByTeamID = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-teammemberslist-by-team/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddAnalysis = async (analysis) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/cricket-analysis`,
      analysis,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const UpdateScoreAnalysis = async (analysis) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-score-analysis`,
      analysis,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// BODY: tournament_id, match_id, current_inning, current_ball
export const getBallAnalysis = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-ball-analysis`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};




export const getScoreOverAnalysis = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-score-over-analysis-web`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const changeMasterInningSwitch = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/change-master-inning-switch`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const GetAllTeamMatchMembersListByTeamID = async (id, match_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-teammatchmemberslist-by-team/${id}/${match_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const CheckSubmitInning = async (analysis) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check-current-ball`,
      analysis,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const getLiveAnalysisDetail = async (analysis_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-live-analysis-detail`,
      analysis_id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const GetStartAnalysisDetail = async (tournament_match_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-start-analysis-detail`,
      tournament_match_detail,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const updateLiveStreamingDetail = async (live_streaming_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-live-streaming-detail`,
      live_streaming_detail,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const createLiveStream = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/create-live-stream`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLiveStreamDetails = async (streamId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-live-stream-details/${streamId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const finishLiveStream = async (streamId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/finish-live-stream/${streamId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const deleteAllLiveStream = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/delete-all-live-stream`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const AddLogCheckPageActiveFirebase = async (analysis) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-log-check-page-active-firebase`,
      analysis,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const GetAppUserList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-app-user-list`, {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddPlayerInTeamFromMatchAnalysis = async (player) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/create-player-in-team-for-playing-match`, player,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const CreateUserInTeamFromMatchAnalysis = async (player) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/create-player-in-team-from-match-analysis`, player,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetLatestThreeBallDetail = async (matchDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-latest-three-ball-detail`, matchDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetSingleBowlerStatistics = async (bowlerDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-single-bowler-statistics`, bowlerDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getStrikerNonStrikerStatistics = async (bowlerDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-striker-non-striker-statistics`, bowlerDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserForScoreboard = async (scoreboardDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-user-for-scoreboard`, scoreboardDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getBowlerForScoreboard = async (scoreboardDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-bowler-for-scoreboard`, scoreboardDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getListOfFirstTotalOver = async (scoreboardOversDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-list-of-first-total-over`, scoreboardOversDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateBookmarkToBall = async (bookmark) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-bookmark-to-ball`, bookmark,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateForceCompleteToBall = async (forceComplete) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-force-complete-to-ball`, forceComplete,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const updateNeglectToBall = async (is_neglect_ball) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-neglect-to-ball`, is_neglect_ball,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLatestEighteenBallDetail = async (matchDetail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-latest-eighteen-ball-detail`, matchDetail,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const unZipVideoFromFileName = async (file_detail) => {
  const source = cancelToken.source();
  requests.push(source);
  //console.log("unZipVideoFromFileName requests",requests);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/un-zip-video-from-file-name`, file_detail,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    //console.log(err);
  }
};

export const getCurrentBallVideo = async (file_detail) => {
  const source = cancelToken.source();
  requests.push(source);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-current-ball-video`, file_detail,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    //console.log(err);
  }
};


export const cancelAllRequests = async () => {

  try {
    requests.forEach((source) => {
      source.cancel('Request canceled', source);
    });
    requests.length = 0; // Clear the requests array
  } catch (err) {
    console.log(err);
  }
};

export const updateInninginMaster = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-inning-in-master`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getPromicingPlayerList = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-promicing-player-list`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const AddPromicingPlayerList = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-promicing-player-list`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const AddPromicingBatsman = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-promicing-player-to-batter-bowller`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};



export const updateVideoUrlPrevious = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-video-url-previous`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const addErrorLogMatchAnalysis = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-error-log-match-analysis`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const addErrorLogMatchAnalysisInternet = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-error-log-match-analysis-internet`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const calculateInternetSpeed = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/calculate-internet-speed`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
          responseType: 'arraybuffer'
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const GetNonDeliveredBallAnalysis = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-non-ball-delivered-analysis`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
//CheckSwtchinning
export const CheckSwtchinning = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-check-inning`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};



export const getWideNoBallCount = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-wide-no-ball-count`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const CheckUmpireOTP = async (umpire_otp) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check_umpire_otp`,
      umpire_otp,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};



export const update_random_analysis_db = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-random-analysis-db`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};


export const checkLastBallDeliverData = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check-last-ball-deliver-data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const logRecordingTime = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/log-recording-time`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
            }`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};