import axios from 'axios';

export const AddVideo = (video) =>{
	return axios
		.post(`${process.env.REACT_APP_BASE_URL}/add-video`, video,{
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
}

export const GetCategories = () => {
	return axios
		.get(`${process.env.REACT_APP_BASE_URL}/get-categories`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};

export const GetLearningCenters = () => {
	return axios
		.get(`${process.env.REACT_APP_BASE_URL}/get-learning-centers`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};

export const GetMatchCenters = () => {
	return axios
		.get(`${process.env.REACT_APP_BASE_URL}/get-match-centers`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};

export const GetLearningCategories = (id) => {
	return axios
		.get(`${process.env.REACT_APP_BASE_URL}/get-learning-categories/${id}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};

export const GetMatchCategories = (id) => {
	return axios
		.get(`${process.env.REACT_APP_BASE_URL}/get-match-categories/${id}`, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};