import React, { useState, useEffect } from "react";
import ImageMapper from "react-image-mapper";
import RightHandBowlingPitch from "../assets/Right_hand_batsman_pitchman.png";
import RedBall from "../assets/redball.png";
import GreenBall from "../assets/greenball.png";
import LeftHandBowlingPitch from "../assets/Left_hand_batsman_pitchman.png";
import {
  Right_Off_Full,
  Right_Leg_Full,
  Right_Mid_Full,
  Left_Off_Full,
  Left_Leg_Full,
  Right_Off_Good,
  Right_Leg_Good,
  Left_Off_Good,
  Left_Leg_Good,
  Right_Mid_Good,
  isInsideRectangle,
  Right_Off_Short,
  Right_Leg_Short,
  Right_Mid_Short,
  Left_Off_Short,
  Left_Leg_Short,
  Right_Off_Yorker,
  Right_Leg_Yorker,
  Right_Mid_Yorker,
  Left_Leg_Yorker,
  Left_Off_Yorker,
  GreenBallArea,
  RedBallArea
} from "../utils";

import {
  Right_Hand_Off_side_half_stump,
  Right_Hand_Leg_side_half_stump,
  Right_Hand_Off_side_full_stump,
  Right_Hand_Leg_side_full_stump,
  Off_Right_Hand_New_Back_of_length,
  Leg_Right_Hand_New_Back_of_length,
  Mid_Right_Hand_New_Back_of_length,
  Leg_Left_Hand_New_Back_of_length,
  Off_Left_Hand_New_Back_of_length,
  Right_Hand_Off_side_above_stump,
  Right_Hand_Leg_side_above_stump,
  Right_Hand_New_BetweenMidLegStump,
  Right_Hand_New_OnOffStump,
  Right_Hand_New_OnLegStump,
  Right_Hand_New_OnMidStump,
  Right_Hand_New_BetweenMidOffStump,
  Left_Hand_New_BetweenMidLegStump,
  Left_Hand_New_OnOffStump,
  Left_Hand_New_OnLegStump,
  Left_Hand_New_OnMidStump,
  Left_Hand_New_BetweenMidOffStump,
  Left_Hand_Off_side_half_stump,
  Left_Hand_Leg_side_half_stump,
  Left_Hand_Off_side_full_stump,
  Left_Hand_Leg_side_full_stump,
  Right_Hand_Off_side_OneHalf_stump,
  Right_Hand_Leg_side_OneHalf_stump,
  Right_Hand_Off_side_double_stump,
  Right_Hand_Leg_side_double_stump,
  Left_Hand_Leg_side_above_stump,
  Left_Hand_Off_side_above_stump,
  Left_Hand_Leg_side_OneHalf_stump,
  Left_Hand_Off_side_OneHalf_stump,
  Left_Hand_Leg_side_double_stump,
  Left_Hand_Off_side_double_stump,
  Right_Hand_Mid_side_above_stump,
  Right_Hand_Mid_side_double_stump,
  Right_Hand_Mid_side_OneHalf_stump,
  Left_Hand_Mid_side_OneHalf_stump,
  Left_Hand_Mid_side_double_stump,
  Left_Hand_Mid_side_above_stump,
  Left_Mid_Yorker,
  Left_Mid_Full,
  Left_Mid_Good,
  Mid_Left_Hand_New_Back_of_length,
  Left_Mid_Short
} from "../utils";

const BowlingInput = (props) => {
  const [parentMarkerData, setParentMarkerData] = useState(props.parentMarkerData);
  const [parentBatsamanData, setParentBatsamanData] = useState(props.positionBatType);
  const [ballResult, setBallResult] = useState("Right");
  const [showBall, setShowBall] = useState(false);
  const [leftHandBatsman, setLeftHandBatsman] = useState(false);
  const [righttHandBatsman, setRightHandBatsman] = useState(true);
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [pitchMsg, setPitchMsg] = useState();

  const [stumpMsg, setStumpMsg] = useState();
  const [hoveredArea, setHoveredArea] = useState();
  const [msg, setMsg] = useState("");
  const [msgClick, setMsgClick] = useState("");
  const [cords, setCords] = useState();
  const [bowlingData, setBowlingData] = useState({
    bowling_result: "",
    bowling_length: "",
  });
  const [batsManTypeMsg, setBatsManTypeMsg] = useState("");
  const [positionBatType, setPositionBatType] = useState({
    pitch_position_bat_type: "",
  });
  const [bowlingPitchData, setBowlingPitchData] = useState({
    position_pitch_x: 0,
    position_pitch_y: 0
  });

  const [bowlingStumpData, setBowlingStumpData] = useState({
    position_stump_x: 0,
    position_stump_y: 0
  });

  const [selectedMarkerOption, setSelectedMarkerOption] = useState('pitch');
  const [selectbattingType, setselectbattingType] = useState('Right');
  const [stateChange, setStateChange] = React.useState(false);

  useEffect(() => {
    if (props.parentMarkerData.parent == true) {
      if (props.parentMarkerData.parent_selectedMarkerOption == 'pitch') {
        if ((props.parentMarkerData.parent_position_pitch_x != 0) && (props.parentMarkerData.parent_position_pitch_y != 0) && (props.parentMarkerData.parent_position_pitch_x != null) && (props.parentMarkerData.parent_position_pitch_y != null)) {
          setShowBall(true);
          const positionX = props.parentMarkerData.parent_position_pitch_x;
          const positionY = props.parentMarkerData.parent_position_pitch_y;
          setBallPosition({ x: positionX - 10, y: positionY - 10 });
          props.setBowlingPitchData({
            position_pitch_x: positionX,
            position_pitch_y: positionY
          });
          setPitchMsg(props.parentMarkerData.parent_bowling_length);
          localStorage.setItem('setPitchMsg', props.parentMarkerData.parent_bowling_length);
        }
        else {
          setShowBall(false);
          props.setBowlingPitchData({
            position_pitch_x: 0,
            position_pitch_y: 0
          });
          setPitchMsg("");
          localStorage.setItem('setPitchMsg', "");
        }

        if ((props.parentMarkerData.parent_position_stump_x != 0) && (props.parentMarkerData.parent_position_stump_y != 0)) {
          setShowGreenBall(true);
          const GreenPositionX = props.parentMarkerData.parent_position_stump_x;
          const GreenPositionY = props.parentMarkerData.parent_position_stump_y;
          setGreenBallPosition({ x: GreenPositionX - 8, y: GreenPositionY - 8 });
          props.setBowlingStumpData({
            position_stump_x: GreenPositionX,
            position_stump_y: GreenPositionY
          });
          setStumpMsg(props.parentMarkerData.parent_bowling_result);
          localStorage.setItem('setStumpMsg', props.parentMarkerData.parent_bowling_result);
        }
        else {
          setShowGreenBall(false);
          props.setBowlingStumpData({
            position_stump_x: 0,
            position_stump_y: 0
          });
          setStumpMsg("");

          localStorage.setItem('setStumpMsg', "");
        }
        props.parentMarkerData.parent = false;
      }
    }
  });

  useEffect(() => {
    if (positionBatType.pitch_position_bat_type !== "") {
      props.setPositionBatType(positionBatType);
    }
    if (bowlingData.bowling_length != "" && bowlingData.bowling_result != "") {
      props.setBowlingData(bowlingData);
    }
  }, [msgClick]);

  const load = () => {
  };
  const clickedOutside = (area) => {
  };
  const enterArea = (area) => {
    setHoveredArea(area);
  };
  const leaveArea = (area) => {
    setHoveredArea(null);
  };
  const moveOnArea = (area, evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setCords(coords);
  };

  const [showMsgProps, setShowMsgProps] = React.useState("");
  useEffect(() => {
    if (showMsgProps != "") {
      show_message(showMsgProps)
    }

  }, [selectedMarkerOption, showMsgProps]);

  const newClicked = (area) => {
    setStateChange(!stateChange);
    if (isInsideRectangle(GreenBallArea, cords)) {
      setSelectedMarkerOption('pitch')
      setShowBall(true);
      const positionX = cords.x;
      const positionY = cords.y;
      setBallPosition({ x: positionX - 10, y: positionY - 10 });
      props.setBowlingPitchData({
        position_pitch_x: positionX,
        position_pitch_y: positionY
      });
      props.parentMarkerData.parent = false;
      props.parentMarkerData.parent_position_pitch_x = 0;
      props.parentMarkerData.parent_position_pitch_y = 0;
    }

    if (isInsideRectangle(RedBallArea, cords)) {

      setSelectedMarkerOption('stump')

      setShowGreenBall(true);
      const positionX = cords.x;
      const positionY = cords.y;
      setGreenBallPosition({ x: positionX - 8, y: positionY - 8 });
      props.setBowlingStumpData({
        position_stump_x: positionX,
        position_stump_y: positionY
      });
    }

    setShowMsgProps(props);
  };

  function show_message(props) {
    if (isInsideRectangle(Right_Hand_New_OnMidStump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("On Stump");
        localStorage.setItem('setPitchMsg', "On Stump");
      } else {
        setStumpMsg("On Stump");
        localStorage.setItem('setStumpMsg', "On Stump");
      }

      setMsgClick("On Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "On Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Off_side_half_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Half Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Half Stump");
      }

      setMsgClick("Away From Off Stumps - Half Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Half Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Leg_side_half_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Half Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Half Stump");
      }

      setMsgClick("Away From Leg Stumps - Half Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Half Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Off_side_full_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Full Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Full Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Full Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Full Stump");
      }

      setMsgClick("Away From Off Stumps - Full Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Full Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Leg_side_full_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Full Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Full Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Full Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Full Stump");
      }

      setMsgClick("Away From Leg Stumps - Full Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Full Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Mid_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - One Half Stump");
      }

      setMsgClick("Away From Mid Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Mid Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Off_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Off Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - One Half Stump");
      }

      setMsgClick("Away From Off Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Off Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Leg_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - One Half Stump");
      }

      setMsgClick("Away From Leg Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Leg Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Mid_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - Double Stump");
      }

      setMsgClick("Away From Mid Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Mid Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Off_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Double Stump");
      }

      setMsgClick("Away From Off Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Off Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Leg_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Double Stump");
      }

      setMsgClick("Away From Leg Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Leg Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Mid_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - Above Stump");
      }

      setMsgClick("Away From Mid Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Mid Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Off_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Above Stump");
      }

      setMsgClick("Away From Off Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Right_Hand_Leg_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Above Stump");
      }

      setMsgClick("Away From Leg Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Right_Mid_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Yorker Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Yorker Length");
      } else {
        setStumpMsg("Mid Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Yorker Length");
      }
      setMsgClick("Mid Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Right_Off_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Right") {

      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Yorker Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Yorker Length");
      } else {
        setStumpMsg("Away From Off Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Yorker Length");
      }
      setMsgClick("Away From Off Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Right_Leg_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Yorker Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Yorker Length");
      }
      setMsgClick("Away From Leg Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Right_Mid_Full, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Full Length");
      } else {
        setStumpMsg("Mid Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Full Length");
      }

      setMsgClick("Mid Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Right_Off_Full, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Full Length");
      } else {
        setStumpMsg("Away From Off Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Full Length");
      }

      setMsgClick("Away From Off Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Right_Leg_Full, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Full Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Full Length");
      }

      setMsgClick("Away From Leg Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Right_Mid_Good, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Good Length");
      } else {
        setStumpMsg("Mid Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Good Length");
      }

      setMsgClick("Mid Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Right_Off_Good, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Good Length");
      } else {
        setStumpMsg("Away From Off Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Good Length");
      }

      setMsgClick("Away From Off Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Right_Leg_Good, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Good Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Good Length");
      }

      setMsgClick("Away From Leg Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Mid_Right_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Back Of Length");
      } else {
        setStumpMsg("Mid Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Back Of Length");
      }

      setMsgClick("Mid Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Off_Right_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Back Of Length");
      } else {
        setStumpMsg("Away From Off Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Back Of Length");
      }

      setMsgClick("Away From Off Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Leg_Right_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Back Of Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Back Of Length");
      }

      setMsgClick("Away From Leg Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Right_Mid_Short, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Short Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Short Length");
      } else {
        setStumpMsg("Mid Stumps - Short Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Short Length");
      }

      setMsgClick("Mid Stumps - Short Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Short Length",
      });
    }

    else if (isInsideRectangle(Right_Off_Short, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Short length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Short length");
      } else {
        setStumpMsg("Away From Off Stumps - Short length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Short length");
      }

      setMsgClick("Away From Off Stumps - Short length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Short length",
      });
    }
    else if (isInsideRectangle(Right_Leg_Short, cords) && props.positionBatType.pitch_position_bat_type == "Right") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Short Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Short Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Short Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Short Length");
      }

      setMsgClick(" Away From Leg Stumps - Short Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Right"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: " Away From Leg Stumps - Short Length",
      });
    }



    if (isInsideRectangle(Left_Hand_New_OnMidStump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("On Stump");
        localStorage.setItem('setPitchMsg', "On Stump");
      } else {
        setStumpMsg("On Stump");
        localStorage.setItem('setStumpMsg', "On Stump");
      }

      setMsgClick("On Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "On Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Off_side_half_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {

      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Half Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Half Stump");
      }

      setMsgClick("Away From Off Stumps - Half Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Half Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Leg_side_half_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Half Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Half Stump");
      }

      setMsgClick("Away From Leg Stumps - Half Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Half Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Off_side_full_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Full Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Full Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Full Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Full Stump");
      }

      setMsgClick("Away From Off Stumps - Full Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Full Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Leg_side_full_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Full Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Full Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Full Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Full Stump");
      }

      setMsgClick("Away From Leg Stumps - Full Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Full Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Mid_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - One Half Stump");
      }

      setMsgClick("Away From Mid Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Mid Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Off_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Off Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - One Half Stump");
      }

      setMsgClick("Away From Off Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Off Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Leg_side_OneHalf_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - One Half Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - One Half Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - One Half Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - One Half Stump");
      }

      setMsgClick("Away From Leg Stumps - One Half Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Leg Stumps - One Half Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Mid_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - Double Stump");
      }

      setMsgClick("Away From Mid Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Mid Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Off_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Double Stump");
      }

      setMsgClick("Away From Off Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Off Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Leg_side_double_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Double Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Double Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Double Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Double Stump");
      }

      setMsgClick("Away From Leg Stumps - Double Stump");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_result: "Away From Leg Stumps - Double Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Mid_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Mid Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Mid Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Mid Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Mid Stumps - Above Stump");
      }

      setMsgClick("Away From Mid Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Mid Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Off_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Off Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Above Stump");
      }

      setMsgClick("Away From Off Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Left_Hand_Leg_side_above_stump, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Above Stump");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Above Stump");
      } else {
        setStumpMsg("Away From Leg Stumps - Above Stump");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Above Stump");
      }

      setMsgClick("Away From Leg Stumps - Above Stump");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Above Stump",
      });
    }

    else if (isInsideRectangle(Left_Mid_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Yorker Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Yorker Length");
      } else {
        setStumpMsg("Mid Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Yorker Length");
      }
      setMsgClick("Mid Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Left_Off_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Left") {

      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Yorker Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Yorker Length");
      } else {
        setStumpMsg("Away From Off Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Yorker Length");
      }
      setMsgClick("Away From Off Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Left_Leg_Yorker, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Yorker Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Yorker Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Yorker Length");
      }
      setMsgClick("Away From Leg Stumps - Yorker Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })

      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Yorker Length",
      });
    }

    else if (isInsideRectangle(Left_Mid_Full, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Full Length");
      } else {
        setStumpMsg("Mid Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Full Length");
      }

      setMsgClick("Mid Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Left_Off_Full, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Full Length");
      } else {
        setStumpMsg("Away From Off Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Full Length");
      }

      setMsgClick("Away From Off Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Left_Leg_Full, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Full Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Full Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Full Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Full Length");
      }

      setMsgClick("Away From Leg Stumps - Full Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Full Length",
      });
    }

    else if (isInsideRectangle(Left_Mid_Good, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Good Length");
      } else {
        setStumpMsg("Mid Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Good Length");
      }

      setMsgClick("Mid Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Left_Off_Good, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Good Length");
      } else {
        setStumpMsg("Away From Off Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Good Length");
      }

      setMsgClick("Away From Off Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Left_Leg_Good, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Good Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Good Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Good Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Good Length");
      }

      setMsgClick("Away From Leg Stumps - Good Length");
      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Good Length",
      });
    }

    else if (isInsideRectangle(Mid_Left_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Back Of Length");
      } else {
        setStumpMsg("Mid Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Back Of Length");
      }

      setMsgClick("Mid Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Off_Left_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Back Of Length");
      } else {
        setStumpMsg("Away From Off Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Back Of Length");
      }

      setMsgClick("Away From Off Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Leg_Left_Hand_New_Back_of_length, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Back Of Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Back Of Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Back Of Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Back Of Length");
      }

      setMsgClick("Away From Leg Stumps - Back Of Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Leg Stumps - Back Of Length",
      });
    }

    else if (isInsideRectangle(Left_Mid_Short, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Mid Stumps - Short Length");
        localStorage.setItem('setPitchMsg', "Mid Stumps - Short Length");
      } else {
        setStumpMsg("Mid Stumps - Short Length");
        localStorage.setItem('setStumpMsg', "Mid Stumps - Short Length");
      }

      setMsgClick("Mid Stumps - Short Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Mid Stumps - Short Length",
      });
    }

    else if (isInsideRectangle(Left_Off_Short, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Off Stumps - Short length");
        localStorage.setItem('setPitchMsg', "Away From Off Stumps - Short length");
      } else {
        setStumpMsg("Away From Off Stumps - Short length");
        localStorage.setItem('setStumpMsg', "Away From Off Stumps - Short length");
      }

      setMsgClick("Away From Off Stumps - Short length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: "Away From Off Stumps - Short length",
      });
    }
    else if (isInsideRectangle(Left_Leg_Short, cords) && props.positionBatType.pitch_position_bat_type == "Left") {
      if (selectedMarkerOption == 'pitch') {
        setPitchMsg("Away From Leg Stumps - Short Length");
        localStorage.setItem('setPitchMsg', "Away From Leg Stumps - Short Length");
      } else {
        setStumpMsg("Away From Leg Stumps - Short Length");
        localStorage.setItem('setStumpMsg', "Away From Leg Stumps - Short Length");
      }

      setMsgClick(" Away From Leg Stumps - Short Length");

      setPositionBatType({
        ...positionBatType,
        pitch_position_bat_type: "Left"
      })
      setBowlingData({
        ...bowlingData,
        bowling_length: " Away From Leg Stumps - Short Length",
      });
    }


  }

  const moveOnImage = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMsg(`Current Coordinates : ${JSON.stringify(coords)} !`);
    setCords(coords);
  };

  const [showGreenBall, setShowGreenBall] = useState(false);
  const [greenBallPosition, setGreenBallPosition] = useState({ x: 0, y: 0 });

  const handleBatsmanType = (val) => (e) => {
    e.preventDefault();
    setPitchMsg("");
    setStumpMsg("");
    setShowBall(false);
    setShowGreenBall(false);
    setselectbattingType(val);
    if (val === "Right") {
      props.positionBatType.pitch_position_bat_type = "Right"
    } else {
      props.positionBatType.pitch_position_bat_type = "Left"
    }
  };

  const handlePitchStump = (val) => (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="containerIMG relative z-0 " id="bowlingAnalysisBg">
        {(props.setBatsmanType === "Right Hand") && (<ImageMapper
          src={RightHandBowlingPitch}
          width={300}
          onLoad={() => {
            load()
            setShowBall(false)
            setShowGreenBall(false)
            setPitchMsg('')
            setStumpMsg('')
          }}
          onClick={(area) => newClicked(area)}
          onMouseEnter={(area) => enterArea(area)}
          onMouseLeave={(area) => leaveArea(area)}
          onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
          onImageClick={(evt) => newClicked(evt)}
          onImageMouseMove={(evt) => moveOnImage(evt)}
        />)}

        {(props.setBatsmanType === "Left Hand") && (<ImageMapper
          src={LeftHandBowlingPitch}
          width={300}
          onLoad={() => {
            load()
            setShowBall(false)
            setShowGreenBall(false)
            setPitchMsg('')
            setStumpMsg('')
          }}
          onClick={(area) => newClicked(area)}
          onMouseEnter={(area) => enterArea(area)}
          onMouseLeave={(area) => leaveArea(area)}
          onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
          onImageClick={(evt) => newClicked(evt)}
          onImageMouseMove={(evt) => moveOnImage(evt)}
        />)}

        {showBall && (
          <div style={{ position: 'absolute', top: ballPosition.y, left: ballPosition.x, zIndex: 1 }}>
            <img src={RedBall} alt="Ball" style={{ width: 20 }} />
          </div>
        )}

        {showGreenBall && (
          <div style={{ position: 'absolute', top: greenBallPosition.y, left: greenBallPosition.x, zIndex: 1 }}>
            <img src={GreenBall} alt="Ball" style={{ width: 16 }} />
          </div>
        )}

        {pitchMsg ? (
          <h2 style={{ color: "#FF0000", fontSize: '13px' }} className="text-center">
            Pitch - {pitchMsg}
          </h2>
        ) : null}
        {stumpMsg ? (
          <h2 style={{ color: "#04AA6D", fontSize: '13px' }} className="text-center">
            Stump - {stumpMsg}
          </h2>
        ) : null}

      </div>
    </>
  );
};
export default BowlingInput;