import React, { useEffect, useState, useMemo } from 'react';
import Pagination from '../../assets/js/pagination';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import Select from 'react-select';
import "./MatchAnalysisErrorLog.css";
import { Button, CircularProgress, } from "@mui/material";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { getRrrorLogMatchAnalysisList, GetTypeofErrorList, GetMatchByTournamentErrorLog, GetAllTournamentsErrorLog } from './Helper';
import { useForm } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import { useOutletContext, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import RemoveIcon from '@mui/icons-material/Remove';
//import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const MatchAnalysisErrorLog = () => {
    const [loading, setLoading] = useState(true);
    const [loadingforsearch, setLoadingForSearch] = useState(false);
    const [paginationVisible, setPaginationVisible] = useState(true);


    const [currentPage, setCurrentPage] = useState(1);
    const [previousMatches, setPreviousMatches] = useState([]);
    const [subscriptiondata, setSubscriptionData] = useState([]);
    const [couterror, setCouterror] = useState([]);
    const [razorpayplan, setRazorpayplan] = useState([]);
    const [appcitydata, setappcitydata] = useState([]);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [razorpayid, setrazorpayid] = useState([]);
    const PageSize = 10;
    const [pageCount, setPageCount] = useState(1);

    const [headerTitle, setHeaderTitle] = useOutletContext();
    const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
    const [showAddTask, setShowAddTask] = React.useState(false);
    const [showAddPlan, setShowAddPlan] = React.useState(false);
    const [showeditplan, setshoweditplan] = React.useState(false);

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEditCity, setSelectedEditCity] = useState(null);
    const [errorLogData, setErrorLogData] = useState([]);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
    } = useForm();

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedController, setSelectedController] = useState('');
    const [selectedMatchId, setSelectedMatchId] = useState('');
    const [MatchlogsToFetch, setMatchLogsToFetch] = useState(100);
    const [Matchloading, setMatchLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [totalMatchDataLoaded, setTotalMatchDataLoaded] = useState(0);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [tournaments, setTournaments] = useState([]);
    // const [matches, setMatches] = useState([]);
    // const [analysis, setAnalysis] = useState({});
    const [analysis, setAnalysis] = useState('');
    const [selectedTournament, setSelectedTournament] = React.useState('');
    const [matches, setMatches] = React.useState([]);
    const [showTable, setShowTable] = useState(false);
    const totalErrorCount = couterror.reduce((total, error) => total + error.count, 0);

    const handleToggleTable = () => {
        setShowTable(!showTable);
    };


    const loadMoreMatchData = async () => {
        try {
            setLoadingMessage('Loading...');
            setLoading(true);


            const data = await getRrrorLogMatchAnalysisList(subscriptiondata.length, selectedController, selectedMatchId);

            if (data?.status) {
                if (data.querydata.get_error_log.length > 0) {
                    setSubscriptionData((prevData) => [...prevData, ...data?.querydata.get_error_log]);
                    setPageCount(prevPageCount => prevPageCount + data.querydata.get_error_log.length);
                    setTotalMatchDataLoaded(prevTotalDataLoaded => prevTotalDataLoaded + data.querydata.get_error_log.length);
                    setCurrentPage(prevPage => prevPage + 1);

                } else {
                    setShowLoadMoreButton(false);
                }

            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            setLoadingMessage('');
        }
    };

    // Initial load without click
    useEffect(() => {
        const initialLoad = async () => {
            try {
                setHeaderTitle("Previous Match Data");
                setLoading(true);

                const data = await getRrrorLogMatchAnalysisList(0);

                if (data?.status) {
                    setSubscriptionData(data?.querydata.get_error_log);
                    setCouterror(data?.querydata.error_counts);
                    setCouterror(data?.querydata.error_counts);
                    setPageCount(data.querydata.get_error_log.length);
                    setTotalMatchDataLoaded(data.querydata.get_error_log.length);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        initialLoad();
    }, []);

    // Use loadMoreData when clicking on the "Load More" button
    const handleMatchLoadMore = () => {
        // setCurrentPage(prevPage => prevPage + 1);
        loadMoreMatchData();
    };

    useEffect(() => {
        GetAllTournamentsErrorLog()
            .then((data) => {
                if (data?.status) {
                    setTournaments(data?.tournaments);
                    console.log('Tournaments:', data?.tournaments);
                }
            })
            .catch((err) => {
                console.error('Error fetching tournaments:', err);
            });
    }, []);

    useEffect(() => {
        if (selectedTournament) {
            GetMatchByTournamentErrorLog(selectedTournament)
                .then((data) => {
                    if (data?.status) {
                        setMatches(data?.match);
                        console.log('Matches:', data?.match);
                    }
                })
                .catch((err) => console.error('Error fetching matches:', err));
        }
    }, [selectedTournament]);






    const [controllerList, setControllerList] = useState([]);

    useEffect(() => {
        setHeaderTitle("Previous Match Data");

    }, [currentPage, setHeaderTitle]);

    const formatDate = (timestamp) => {
        if (!timestamp) return 'NA';
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    // const handleReset = () => {
    //     setSearchQuery('');
    //     setSelectedController('');
    //     setTournaments('');
    //     setMatches('');
    //     setShowLoadMoreButton(true);
    // };
    const handleReset = () => {
        setSelectedTournament('');
        setAnalysis({ ...analysis, match_id: '' });
        setSelectedMatchId('')
        setSearchQuery('');
        setSelectedController('');
        handleMatchChange({ target: { value: '' } });
        handleControllerChange({ target: { value: '' } });
        const initialLoad = async () => {
            try {
                setHeaderTitle("Previous Match Data");
                setLoading(true);

                const data = await getRrrorLogMatchAnalysisList(0);

                if (data?.status) {
                    setSubscriptionData(data?.querydata.get_error_log);
                    setCouterror(data?.querydata.error_counts);
                    setCouterror(data?.querydata.error_counts);
                    setPageCount(data.querydata.get_error_log.length);
                    setTotalMatchDataLoaded(data.querydata.get_error_log.length);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        initialLoad();
        setShowLoadMoreButton(true);
        // setCurrentPage(1);
    };


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };
    const handleControllerChange = (event) => {
        setLoadingForSearch(true);
        setSelectedController(event.target.value);
        console.log("selectedController==>", selectedController)
        setCurrentPage(1);
        // setShowLoadMoreButton(false);
        Swal.fire({
            title: "😣Please Wait Data is Loading......",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        getRrrorLogMatchAnalysisList(0, event.target.value, selectedMatchId)
            .then((data) => {
                setLoadingForSearch(false)
                if (data?.status) {
                    if (data.querydata.get_error_log.length > 0) {
                        setSubscriptionData(data.querydata.get_error_log);
                        setCouterror(data?.querydata.error_counts);
                        setPageCount(data.querydata.get_error_log.length);
                        setTotalMatchDataLoaded(data.querydata.get_error_log.length);
                    } else {
                        setSubscriptionData([]);
                        setPageCount(0);
                        setTotalMatchDataLoaded(0);
                    }
                }
                Swal.close();
            })
            .catch((error) => {
                Swal.close();
                setLoadingForSearch(false);
                // Handle the API error
                console.error(error);
            });
    };
    const handleMatchChange = (event) => {
        setLoadingForSearch(true);
        setSelectedMatchId(event.target.value);
        setCurrentPage(1);

        // console.log("selectedmatchid==>", selectedMatchId)
        // setAnalysis({ ...analysis, match_id: selectedMatchId });
        // setShowLoadMoreButton(false);
        Swal.fire({
            title: "😣Please Wait Data is Loading......",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });


        // const selectedMatch = matches.find((match) => match.id === selectedMatchId);

        getRrrorLogMatchAnalysisList(0, selectedController, event.target.value)
            .then((data) => {
                setLoadingForSearch(false);
                if (data?.status) {
                    if (data.querydata.get_error_log.length > 0) {
                        setSubscriptionData(data.querydata.get_error_log);
                        setCouterror(data?.querydata.error_counts);
                        setPageCount(data.querydata.get_error_log.length);
                        setTotalMatchDataLoaded(data.querydata.get_error_log.length);
                    } else {
                        setSubscriptionData([]);
                        setPageCount(0);
                        setTotalMatchDataLoaded(0);
                    }
                }
                Swal.close();
            })
            .catch((error) => {
                Swal.close();
                setLoadingForSearch(false);
                // Handle the API error
                console.error(error);
            });

    };
    useEffect(() => {
        console.log("selectedmatchid==>", selectedMatchId);
    }, [selectedMatchId]);



    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };

    const handleModalEditProgram = (log) => {
        setSelectedErrorLog(log);
        setshoweditplan(true);
    };
    const filteredData = useMemo(() => {
        return subscriptiondata.filter((log) =>
            (selectedController === '' || log.type_of_error === selectedController)
            //  &&
            // (selectedMatchId === '' || log.match_detail.name === selectedMatchId)
        );
    }, [subscriptiondata, selectedController]);

    const currentTableData = useMemo(() => {
        const startIndex = (currentPage - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const slicedData = filteredData.slice(startIndex, endIndex);
        setPageCount(filteredData.length);
        return slicedData;
    }, [currentPage, PageSize, filteredData]);


    // const filteredData = useMemo(() => {

    //     return subscriptiondata.filter((log) =>
    //         (selectedController === '' || log.type_of_error === selectedController)
    //     );

    // }, [subscriptiondata, searchQuery, selectedController]);

    // const currentTableData = useMemo(() => {
    //     const startIndex = (currentPage - 1) * PageSize;
    //     const endIndex = startIndex + PageSize;
    //     const slicedData = filteredData.slice(startIndex, endIndex);
    //     setPageCount(filteredData.length);
    //     return slicedData;

    // }, [currentPage, PageSize, filteredData]);

    return (
        <>
            <div>
                <h1
                    className="text-center m-2"
                    style={{
                        color: "#000", backgroundColor: "#0df6e3",
                        fontSize: "18px",
                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
                    }}
                >
                    Match Analysis Error Logs
                </h1>
            </div>
            <div style={{ textAlign: 'center' }}>
                {loading ? (
                    <>
                        <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>
                            Please Wait Data is{" "}
                            <span style={{ color: 'orange', fontWeight: '600' }}>
                                Loading...
                            </span>
                        </h2>
                    </>
                ) : (
                    <>

                        <div className="search-container">
                            <select style={{ backgroundColor: 'white', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", marginRight: '10px' }}
                                value={selectedController}
                                onChange={handleControllerChange}
                                className="Typeerror-select"
                            >
                                <option value="">All Types of Errors</option>
                                <option value="Batsman Handedness Unavailable">Batsman Handedness Unavailable</option>
                                <option value="Bowler Handedness Unavailable">Bowler Handedness Unavailable</option>
                                <option value="Firebase Database Unavailable">Firebase Database Unavailable</option>
                                <option value="Innings Locked Until Submitted Over">Innings Locked Until Submitted Over</option>
                                <option value="Internet Server Down">Internet Server Down</option>
                                <option value="Mobile Internet Connection Unavailable">Mobile Internet Connection Unavailable</option>
                                <option value="Mobile Video Window Off">Mobile Video Window Off</option>
                                <option value="Next Ball Unavailable">Next Ball Unavailable</option>
                                <option value="Pitch Map Detail">Pitch Map Detail</option>
                                <option value="Player Already Exist">Player Already Exist</option>
                                <option value="Player Already Selected">Player Already Selected</option>
                                <option value="Player Detail Unavailable">Player Detail Unavailable</option>
                                <option value="Player Exist">Player Exist</option>
                                <option value="Player Exist - New">Player Exist - New</option>
                                <option value="Player Shuffle">Player Shuffle</option>
                                <option value="Previous Ball Unavailable">Previous Ball Unavailable</option>
                                <option value="Second Inning Ended">Second Inning Ended</option>
                                <option value="Second Inning Not Started">Second Inning Not Started</option>
                                <option value="Start Recording Not Clicked">Start Recording Not Clicked</option>
                                <option value="Wagon Wheel Detail">Wagon Wheel Detail</option>
                                <option value="Wicket Type Unavailable">Wicket Type Unavailable</option>

                            </select>
                            <select className="Typeerror-select" style={{ backgroundColor: 'white', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", marginRight: "10px", width: '20%' }}
                                value={selectedTournament}
                                onChange={(e) => setSelectedTournament(e.target.value)}
                            >
                                <option value="">Select Tournament</option>
                                {tournaments.map((tournament) => (
                                    <option key={tournament.id} value={tournament.id}>
                                        {tournament.name}
                                    </option>
                                ))}
                            </select>
                            <select
                                className="Typeerror-select"
                                style={{ backgroundColor: 'white', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", width: '15%' }}
                                value={selectedMatchId}
                                onChange={handleMatchChange}
                            >
                                <option value="">Select Match</option>
                                {matches.map((match) => (
                                    <option key={match.id} value={match.id}>
                                        {match.name}
                                    </option>
                                ))}
                            </select>


                            {/* <select className="Typeerror-select" style={{ backgroundColor: 'white', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", width: '15%' }}
                                value={analysis.match_id}
                                onChange={(e) => setAnalysis({ ...analysis, match_id: e.target.value })}
                            >
                                <option value="">Select Match</option>
                                {matches.map((match) => (
                                    <option key={match.id} value={match.id}>
                                        {match.name}
                                    </option>
                                ))}
                            </select> */}
                            <div >
                                <button variant='contained' type="button" style={{ marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', marginLeft: '20px', height: '38px', borderRadius: '4px', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }} onClick={handleReset} class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <RefreshIcon /> Reset
                                </button>
                            </div>
                            <button variant='contained' id="errorcount" type="button" style={{ marginBottom: '2px', backgroundColor: '#00efdc', color: 'black', marginLeft: '23px', height: '38px', borderRadius: '4px', boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }} onClick={handleToggleTable} class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {showTable ? <RemoveIcon /> : <AddIcon />}
                                {showTable ? 'Hide Count' : 'Show Count'}
                            </button>
                            <div style={{ position: 'fixed', right: '-35px', zIndex: '999', marginTop: '10px' }}>
                                <div style={{ float: 'right', marginTop: '-20px', marginRight: '110px', color: 'blue' }}> <p>{loadingMessage && <p>{loadingMessage} <CircularProgress size="1rem" /></p>}</p> </div>
                                {showLoadMoreButton && (
                                    <Button onClick={handleMatchLoadMore} id="matchloadmore" style={{ height: '30px', backgroundColor: '#00efdc', color: 'black', marginTop: '4px', }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                                )}
                            </div>
                        </div>

                        {showTable && (
                            <div className="w-full bg-white shadow-md1 rounded-lg p-4" style={{ width: '99%', marginLeft: '10px' }}>
                                <div className={couterror.length > 10 ? "max-h-96 overflow-y-auto" : ""}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="previousDatath">Sr. No.</th>
                                                <th className="previousDatath">Type of Error</th>
                                                <th className="previousDatath">Count</th>
                                                <th className="previousDatath">% of Errors Occurred</th>
                                            </tr>
                                        </thead>
                                        {loadingforsearch ? (
                                            <tr>
                                                <td colSpan="10" style={{ textAlign: 'center' }}>
                                                    <p>Loading...</p>
                                                </td>
                                            </tr>
                                        ) : !loadingforsearch && currentTableData.length === 0 ? (
                                            <tr>
                                                <td colSpan="10" style={{ textAlign: 'center' }}>
                                                    <p style={{ color: 'red', fontWeight: '600' }}>Data not found</p>
                                                </td>
                                            </tr>
                                        ) : (
                                            <><tbody>
                                                {couterror.sort((a, b) => a.type_of_error.localeCompare(b.type_of_error)).map((error, index) => {
                                                    // Calculate the percentage
                                                    const percentage = (error.count / totalErrorCount) * 100;

                                                    // Define gradient colors based on the percentage range
                                                    let gradientColor;
                                                    let textcolor;
                                                    if (percentage <= 25) {
                                                        gradientColor = 'linear-gradient(to left, #b4ff00, #04dd46)'; // Green gradient
                                                    } else if (percentage <= 50) {
                                                        gradientColor = 'linear-gradient(to right, yellow, #ffcc00)'; // Yellow gradient
                                                    } else if (percentage <= 75) {
                                                        gradientColor = 'linear-gradient(to right, orange, #ff6600)'; // Orange gradient
                                                    } else {
                                                        textcolor = "white";
                                                        gradientColor = 'linear-gradient(to right, red, #cc0000)'; // Red gradient
                                                    }

                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{error.type_of_error}</td>
                                                            <td>{error.count}</td>
                                                            <td>
                                                                <div className="progress_error" style={{ height: '20px', width: '100%' }}>
                                                                    <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`, background: gradientColor, color: textcolor, marginTop: '-2px', borderRadius: '10px', position: 'relative' }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">{`${percentage.toFixed(2)}%`}</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                                {/* <tfoot>
                                                    <tr>
                                                        <td colSpan="2" className="text-right">Total:</td>
                                                        <td>{couterror.reduce((total, error) => total + error.count, 0)}</td>
                                                    </tr>
                                                </tfoot> */}
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="2" className="text-right" style={{ fontWeight: '600', color: 'black' }}>Total:</td>
                                                        <td style={{ fontWeight: '600', color: 'blue' }}>{couterror.reduce((total, error) => total + error.count, 0)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" style={{ borderBottom: '1px solid black' }}></td>
                                                    </tr>
                                                </tfoot>

                                            </>
                                        )}
                                    </table>


                                </div>
                            </div>
                        )}





                        <table className="custom-row-colors" style={{ borderCollapse: 'collapse', width: '100%', marginTop: "12px" }}>
                            <thead>
                                <tr>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Sr. No.</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Tournament</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Match</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Current Inning</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Current Ball</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Type of Error</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Error Description</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Input Data</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>Created At</th>
                                    <th className="previousDatath" style={{ textAlign: "center" }}>View</th>
                                </tr>
                            </thead>
                            {loadingforsearch ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>
                                        <p>Loading...</p>
                                    </td>
                                </tr>
                            ) : !loadingforsearch && currentTableData.length === 0 ? (
                                <tr>
                                    <td colSpan="10" style={{ textAlign: 'center' }}>
                                        <p style={{ color: 'red', fontWeight: '600' }}>Data not found</p>
                                    </td>
                                </tr>
                            ) : (
                                <tbody>
                                    {currentTableData.map((log, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='dataTdLog'>{((currentPage - 1) * 10) + (index + 1)}</td>
                                            <td className='dataTdLog'>{log.tournament_detail.name ? log.tournament_detail.name : 'NA'}</td>
                                            <td className='dataTdLog'>{log.match_detail.name ? log.match_detail.name : 'NA'}</td>
                                            <td className='dataTdLog'>{log.current_inning ? log.current_inning : 'NA'}</td>
                                            <td className='dataTdLog'>{log.current_ball ? log.current_ball : 'NA'}</td>
                                            <td className='dataTdLog'>{log.type_of_error ? log.type_of_error : 'NA'}</td>
                                            <td className='dataTdLog'>{log.error_description ? truncateString(log.error_description, 20) : 'NA'}</td>
                                            <td className='dataTdLog'>{log.input_data ? truncateString(log.input_data, 20) : 'NA'}</td>
                                            <td className='dataTdLog'>{log.createdAt ? formatDate(log.createdAt) : 'NA'}</td>
                                            <td className='dataTdLog'>
                                                <Button title="View" onClick={() => handleModalEditProgram(log)}>
                                                    <VisibilityIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}

                        </table>


                        <form >
                            {showeditplan && selectedErrorLog ? (
                                <>
                                    <div
                                        className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className="relative w-auto my-6 mx-auto max-w-8xl"
                                            style={{ width: '90%', maxHeight: '100%' }}
                                        >
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                <div
                                                    style={{ margin: '18px', backgroundColor: '#1f242cd4', color: 'white' }}
                                                    className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t"
                                                >
                                                    <h2 style={{ textAlign: 'left', margin: '7px' }}>
                                                        <span style={{ color: 'white', fontWeight: '400' }}>ID : </span>{' '}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>
                                                            {selectedErrorLog.id ? selectedErrorLog.id : 'NA'}
                                                        </span>
                                                    </h2>

                                                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>
                                                        <span style={{ color: 'white', fontWeight: '400' }}>Type of Error : </span>{' '}
                                                        <span style={{ color: 'white', fontWeight: '400' }}>
                                                            {selectedErrorLog.type_of_error ? selectedErrorLog.type_of_error : 'NA'}
                                                        </span>
                                                    </h2>
                                                    <button
                                                        style={{ width: '40px', margin: '0', color: 'white' }}
                                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() => setshoweditplan(false)}
                                                    >
                                                        <ClearIcon />
                                                    </button>
                                                </div>

                                                <div>
                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Tournament:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.tournament_detail.name ? selectedErrorLog.tournament_detail.name : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Match Name:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.match_detail.name ? selectedErrorLog.match_detail.name : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>

                                                <div>
                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Current Inning:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.current_inning ? (selectedErrorLog.current_inning) : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Current Ball:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>

                                                                {selectedErrorLog.current_ball ? (selectedErrorLog.current_ball) : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>

                                                <div>
                                                    <div class="flex-container" style={{ marginLeft: '15px' }}>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Total Overs:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.overs ? (selectedErrorLog.overs) : 'NA'}
                                                            </span>
                                                        </div>
                                                        <div class="flex-item">
                                                            <span style={{ color: 'black', fontWeight: '600' }}>Created At:</span>{' '}
                                                            <span style={{ color: 'darkcyan', fontWeight: '600' }}>
                                                                {selectedErrorLog.createdAt ? formatDate(selectedErrorLog.createdAt) : 'NA'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div>

                                                    <div
                                                        className="overflow-x-auto overflow-y-auto"
                                                        style={{ maxHeight: '70vh', padding: '0 25px' }}
                                                    >
                                                        <div className="flex" style={{ textAlign: 'left' }}>

                                                            <div style={{ width: "100%" }}>

                                                                <p className="text-gray-600 border-b mb-2 pb-2">

                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Error Description:</span>{' '}
                                                                    {selectedErrorLog.error_description ? (
                                                                        <pre style={{ color: '#008b8b', fontWeight: '600' }}>
                                                                            {selectedErrorLog.error_description}
                                                                        </pre>

                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                                <p className="text-gray-600 border-b mb-2 pb-2">

                                                                    <span style={{ color: 'black', fontWeight: '600' }}>Input Data:</span>{' '}
                                                                    {selectedErrorLog.input_data ? (
                                                                        <pre style={{ background: '#000', color: '#FFF' }}>
                                                                            {JSON.stringify(
                                                                                JSON.parse(selectedErrorLog.input_data),
                                                                                null,
                                                                                2
                                                                            )}
                                                                        </pre>
                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                    <button style={{ color: 'white' }}
                                                        className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                                        type="button"
                                                        onClick={() => {
                                                            setshoweditplan(false);
                                                        }}
                                                    >
                                                        <CancelIcon /> Cancel Log
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </form>

                        {/* <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={pageCount}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                            onVisibilityChange={visible => setPaginationVisible(visible)}
                        />
                        <div style={{ float: 'right', marginTop: '-32px', marginRight: '110px', color: 'blue' }}> <p>{loadingMessage && <p>{loadingMessage} <CircularProgress size="1rem" /></p>}</p> </div>
                        {paginationVisible && showLoadMoreButton && (
                            <Button onClick={handleMatchLoadMore} id="matchloadmore" style={{ float: 'right', marginRight: '15px', height: '30px', backgroundColor: '#00efdc', color: 'black', marginTop: '4px', }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                        )} */}
                        {/* <div className="row">
                            <div className="col-md-6 d-flex justify-content-start align-items-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={pageCount}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                    onVisibilityChange={visible => setPaginationVisible(visible)}
                                />
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <div>
                                    <p>{loadingMessage && <span>{loadingMessage} <CircularProgress size="1rem" /></span>}</p>
                                </div>
                                {paginationVisible && showLoadMoreButton && (
                                    <Button onClick={handleMatchLoadMore} id="matchloadmore" data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                                )}
                            </div>
                        </div> */}
                        <div className="search-container2">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pageCount}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}

                            />
                            {/* <div >
                                <div style={{ float: 'right', marginTop: '-32px', marginRight: '110px', color: 'blue' }}> <p>{loadingMessage && <p>{loadingMessage} <CircularProgress size="1rem" /></p>}</p> </div>
                                {paginationVisible && showLoadMoreButton && (
                                    <Button onClick={handleMatchLoadMore} id="matchloadmore" style={{ float: 'right', marginRight: '15px', height: '30px', backgroundColor: '#00efdc', color: 'black', marginTop: '4px', }} data-tip="Click to load more" variant="contained"><ReadMoreIcon /></Button>
                                )}
                            </div> */}

                        </div>



                        <ReactTooltip
                            anchorId="matchloadmore"
                            place="left"
                            content="Click to load more"
                            className="tooltip"
                        />
                        <ReactTooltip
                            anchorId="errorcount"
                            place="left"
                            html="
                                    <h2 style='text-align:center;color:lightgreen'>Type Of Errors</h2><hr/>
                                    <h3 style='text-align:center;'>View the total count of each type of error<br />based on your filtered criteria.</h3>
                                    "
                            // content="This button will enable users to refresh the video URL for individual videos in cases where videos are not tagged properly."
                            className="tooltip" />
                    </>
                )}
            </div>
        </>
    );
}
export default MatchAnalysisErrorLog;
