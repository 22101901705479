import React, { useEffect, useState } from "react";
import { fetchPlayerFeeDetails } from "../../Helper";
import "react-datepicker/dist/react-datepicker.css";
import AddFees from "./AddFees";
import FeeHistoryList from "./FeeHistory/FeeHistoryList";

const FeeHistory = ({
  selectedPlayerName,
  selectedPlayerIndex,
  isActiveSubscriptionListLoading,
  setIsActiveSubscriptionListLoading,
  isActiveSubscriptionList,
  setIsActiveSubscriptionList,
  addedNewSubscriptionReload,
  setAddNewSubscriptionReload,
}) => {
  const [isFeeDataLoading, setIsFeeDataLoading] = useState(true);
  const [feeDataArray, setFeeDataArray] = useState([]);

  // State variables to hold the updated input values

  const [selectedSubscriptionOption, setSelectedSubscriptionOption] =
    useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPlayerFeeDetails(selectedPlayerIndex);
        setFeeDataArray(data);
        setIsFeeDataLoading(false);
      } catch (error) {
        console.log("Error in fetchPlayerFeeDetails:", error.message);
      }
    };
    if (selectedPlayerIndex != "") {
      fetchData();
    }
  }, [selectedPlayerIndex]);

  useEffect(() => {
    console.log(feeDataArray);
  }, [feeDataArray]);
  return (
    <>
      <div className="flex flex-col gap-2">
        <AddFees
          selectedPlayerIndex={selectedPlayerIndex}
          feeDataArray={feeDataArray}
          setFeeDataArray={setFeeDataArray}
          setIsFeeDataLoading={setIsFeeDataLoading}
          // isActiveSubscriptionListLoading={isActiveSubscriptionListLoading}
          // setIsActiveSubscriptionListLoading={
          //   setIsActiveSubscriptionListLoading
          // }
          isActiveSubscriptionList={isActiveSubscriptionList}
          // setIsActiveSubscriptionList={setIsActiveSubscriptionList}
          addedNewSubscriptionReload={addedNewSubscriptionReload}
          setAddNewSubscriptionReload={setAddNewSubscriptionReload}
        />
        {isFeeDataLoading && selectedPlayerIndex != "" ? (
          <div>Data loading...</div>
        ) : (
          <FeeHistoryList
            feeDataArray={feeDataArray}
            isActiveSubscriptionList={isActiveSubscriptionList}
            selectedSubscriptionOption={selectedSubscriptionOption}
            setSelectedSubscriptionOption={setSelectedSubscriptionOption}
            addedNewSubscriptionReload={addedNewSubscriptionReload}
            setAddNewSubscriptionReload={setAddNewSubscriptionReload}
            setIsFeeDataLoading={setIsFeeDataLoading}
            selectedPlayerIndex={selectedPlayerIndex}
            setFeeDataArray={setFeeDataArray}
          />
        )}
      </div>
    </>
  );
};

export default FeeHistory;
