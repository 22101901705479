import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";

const AddPlayerFees = (
  { selectedPlanDataObject, setFeeDetails, dateOfJoining },
  addPlayerFeesRef
) => {
  const [fee, setFee] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountReason, setDiscountReason] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [validFrom, setValidFrom] = useState(new Date());
  // Calculate validTill as validFrom + 1 month
  const validTillDate = new Date(validFrom);
  validTillDate.setMonth(validTillDate.getMonth() + 1);
  validTillDate.setDate(validTillDate.getDate() - 1);
  const [validTill, setValidTill] = useState(validTillDate);

  const handleFeeChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setFee(numericInput);
  };

  const handleDiscountChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setDiscount(numericInput);
  };
  const handleDiscountReasonChange = (e) => {
    setDiscountReason(e.target.value);
  };
  const handlePaymentDateChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setPaymentDate(utcDate);
  };
  const handleValidFromChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidFrom(utcDate);
  };
  const handleValidTillChange = (date) => {
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidTill(utcDate);
  };

  const changeValidTillAsPerInputs = () => {
    if (selectedPlanDataObject) {
      // total discount field won't exist here as this entry hasn't yet been created
      const netFeesToBePaidPerMonthAfterAddingNewDiscount =
        (selectedPlanDataObject.fee - discount) / 12;
      // following ternary is a special case which handles validtill in case of cancel click
      const feeToNetFeesToBePaidPerMonthAfterAddingNewDiscountRatio =
        Math.ceil(fee / netFeesToBePaidPerMonthAfterAddingNewDiscount) < 1
          ? 1
          : Math.ceil(fee / netFeesToBePaidPerMonthAfterAddingNewDiscount);

      const newValidTill = new Date(validFrom);
      newValidTill.setMonth(
        newValidTill.getMonth() +
        feeToNetFeesToBePaidPerMonthAfterAddingNewDiscountRatio
      );
      newValidTill.setDate(newValidTill.getDate() - 1);
      setValidTill(newValidTill);
    }
  };

  useEffect(() => {
    changeValidTillAsPerInputs();
  }, [fee, discount, validFrom]);

  useEffect(() => {
    setValidFrom(dateOfJoining);
  }, [dateOfJoining]);

  // for collating and sending feeData to newplayerRegistration Page
  useEffect(() => {
    const feeDetails = {
      fee,
      discount,
      discountReason,
      paymentDate,
      validFrom,
      validTill,
    };
    setFeeDetails(feeDetails);
  }, [fee, discount, discountReason, paymentDate, validFrom, validTill]);

  const resetFields = () => {
    setValidFrom(new Date());
    setFee("");
    setDiscount("");
    setDiscountReason("");
    setPaymentDate(new Date());
  };

  useImperativeHandle(addPlayerFeesRef, () => ({
    resetFields: resetFields,
  }));

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-center text-xl font-bold">Player Fees</h2>
      <section className="text-center grid grid-cols-12 items-center align-center justify-items-stretch">
        <div>Fee:</div>
        <input
          type="text"
          id="fees"
          value={fee}
          onChange={handleFeeChange}
          required
          className="col-span-1 p-1 rounded text-center w-full border border-black"
          placeholder="Fee(₹)"
        />

        <div>Discount:</div>
        <input
          type="text"
          id="discount"
          value={discount}
          onChange={handleDiscountChange}
          required
          className="col-span-1 p-1 rounded text-center w-full border border-black"
          placeholder="Discount(₹)"
        />

        <div>Discount Reason:</div>
        <input
          type="text"
          id="reason for discount"
          value={discountReason}
          onChange={handleDiscountReasonChange}
          required
          className="col-span-1 p-1 rounded text-center w-full border border-black"
          placeholder="Reason for discount."
        />
        <div>Fees Payment Date:</div>
        <div>
          <DatePicker
            selected={paymentDate}
            onChange={handlePaymentDateChange}
            dateFormat="dd-MMM-yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={75}
            className="flex justify-center text-center w-full rounded border border-black p-1"
          />
        </div>
        <div>Fees Valid from:</div>
        <div>
          <DatePicker
            selected={validFrom}
            onChange={handleValidFromChange}
            dateFormat="dd-MMM-yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={75}
            className="flex justify-center text-center w-full rounded border border-black p-1"
          />
        </div>

        <div>Fees Valid till:</div>
        <div>
          <DatePicker
            selected={validTill}
            onChange={handleValidTillChange}
            dateFormat="dd-MMM-yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={75}
            placeholderText=""
            className="flex justify-center text-center w-full rounded border border-black p-1"
          />
        </div>
      </section>
    </div>
  );
};

export default forwardRef(AddPlayerFees);
