import Validator from "validator";
import isEmpty from "lodash/isEmpty";

export default function validateInput(state) {
  console.log("state", state);
  //var letterNumber = /^[a-zA-Z]+$/;

  let cameraError = {};
 
 

  if (!state.camera_1 || Validator.isEmpty(state.camera_1)) {
    cameraError.camera_1 = "Camera 1 is required.";
  }

  if (!state.camera_2 || Validator.isEmpty(state.camera_2)) {
    cameraError.camera_2 = "Camera 2 is required.";
  }

  if (!state.tournament_id || Validator.isEmpty(state.tournament_id)) {
    cameraError.tournament_id = "Tournament Name is required.";
  }

  if (!state.match_id || Validator.isEmpty(state.match_id)) {
    cameraError.match_id = "Match Name is required.";
  }

  
 
  return {
    cameraError,
    isValid: isEmpty(cameraError),
  };
}
