import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

const MatchCenter = () => {
  const [headerTitle, setHeaderTitle] = useOutletContext();
  return (
    <Outlet context={[headerTitle, setHeaderTitle]}/>
  )
}

export default MatchCenter