import React from "react";
import Creatable from "react-select/creatable";
import DatePicker from "react-datepicker";

const FeeForm = ({
  planName,
  handlePlanNameChange,
  feePlanCategory,
  handleFeeCategoryChange,
  fee,
  handleFeeChange,
  feePlanDuration,
  handleFeeDurationChange,
  paymentFrequency,
  handlePaymentFrequencyChange,
  validFrom,
  handleValidFromChange,
  validTill,
  handleValidTillChange,
}) => {
  const defaultFeeCategoryList = [
    { value: "Coaching", label: "Coaching" },
    { value: "Hostel", label: "Hostel" },
    { value: "Sports Kit", label: "Sports Kit" },
    { value: "Food & Nutrition", label: "Food & Nutrition" },
    { value: "Personal Coaching", label: "Personal Coaching" },
    { value: "Other", label: "Other" },
  ];

  const defaultFeeDurationList = [
    { value: "Annual", label: "Annual" },
    { value: "Semi-Annual", label: "Semi-Annual" },
    { value: "Tri-Annual", label: "Tri-Annual" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Monthly", label: "Monthly" },
  ];
  return (
    <div className="grid grid-cols-7 gap-2 border border-black rounded p-2 items-center">
      <div>
        <input
          type="text"
          id="planName"
          value={planName}
          onChange={handlePlanNameChange}
          required
          className="p-2 rounded w-full col-span-1"
          placeholder="Subscription Name"
        />
      </div>
      <div>
        <Creatable
          options={defaultFeeCategoryList}
          placeholder="Category"
          value={feePlanCategory}
          onChange={handleFeeCategoryChange}
          isSearchable={true}
          isCreatable={false}
          isRequired
          className="col-span-1"
        />
      </div>
      <div>
        <input
          type="text"
          id="fee"
          value={fee}
          onChange={handleFeeChange}
          required
          className="p-2 rounded w-full col-span-1"
          placeholder="Fee(₹)"
        />
      </div>
      <div>
        <Creatable
          options={defaultFeeDurationList}
          placeholder="Subscription Duration"
          value={feePlanDuration}
          onChange={handleFeeDurationChange}
          isSearchable={true}
          isCreatable={false}
          isRequired
          className="col-span-1"
        />
      </div>
      <div>
        <Creatable
          options={defaultFeeDurationList}
          placeholder="Subscription Frequency"
          value={paymentFrequency}
          onChange={handlePaymentFrequencyChange}
          isSearchable={true}
          isCreatable={false}
          isRequired
          className="col-span-1"
        />
      </div>
      <div className="z-20 flex items-center justify-center">
        <DatePicker
          selected={validFrom}
          onChange={handleValidFromChange}
          dateFormat="dd-MM-yyyy"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={75}
          className="flex justify-center text-center p-1 w-full col-span-1 rounded"
          placeholderText="Valid From"
        />
      </div>
      <div className="z-20 flex items-center justify-center">
        <DatePicker
          selected={validTill}
          onChange={handleValidTillChange}
          dateFormat="dd-MM-yyyy"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={75}
          placeholderText="Valid Till"
          className="flex justify-center text-center p-1 w-full col-span-1 rounded"
        />
      </div>
    </div>
  );
};

export default FeeForm;
