import React from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const MembershipReports = () => {
    const [headerTitle, setHeaderTitle] = useOutletContext();
    useEffect(() => {
        setHeaderTitle("Membership Reports");
    }, []);
}

export default MembershipReports