import React, { useEffect, useState, useMemo } from 'react';
import Pagination from '../../assets/js/pagination';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import Select from 'react-select';
import { Button, CircularProgress, } from "@mui/material";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { GetAppPlansList, AddAppPlansList, GetAppCityList, GetRazorPayPlan, UpdateAppPlans, GetAppPlansListByID } from './Helper';
import { useForm } from "react-hook-form";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import { useOutletContext, useNavigate } from "react-router-dom";

const SubscriptionPlans = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousMatches, setPreviousMatches] = useState([]);
    const [subscriptiondata, setSubscriptionData] = useState([]);
    const [razorpayplan, setRazorpayplan] = useState([]);
    const [appcitydata, setappcitydata] = useState([]);
    const [razorpayPlans, setRazorpayPlans] = useState([]);
    const [razorpayid, setrazorpayid] = useState([]);
    const PageSize = 10;
    const [pageCount, setPageCount] = useState(0);
    const [headerTitle, setHeaderTitle] = useOutletContext();
    const [IsUnzipVideoClick, setIsUnzipVideoClick] = useState(false);
    const [showAddTask, setShowAddTask] = React.useState(false);
    const [showAddPlan, setShowAddPlan] = React.useState(false);
    const [showeditplan, setshoweditplan] = React.useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEditCity, setSelectedEditCity] = useState(null);

    const handleCityChange = selectedOption => {
        setSelectedCity(selectedOption);
        setFieldErrors(prevErrors => ({
            ...prevErrors,
            app_city_id: undefined,
        }));
        setFormData(prevFormData => ({
            ...prevFormData,
            app_city_id: selectedOption.value,
        }));
    };
    const handleEditCityChange = selectedOption => {
        setSelectedEditCity(selectedOption);
        setFieldErrors(prevErrors => ({
            ...prevErrors,
            app_city_id: undefined,
        }));
        setEditFormData(prevFormData => ({
            ...prevFormData,
            app_city_id: selectedOption.value,
        }));
    };
    const cityOptions = appcitydata.map(city => ({
        value: city.id,
        label: `${city.city}, ${city.state_detail ? city.state_detail.name : ''}`
    }));
    const sortedCityOptions = cityOptions.slice().sort((cityA, cityB) =>
        cityA.label.localeCompare(cityB.label)
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
    } = useForm();

    useEffect(() => {
        setHeaderTitle("Previous Match Data");
        GetAppPlansList()
            .then((data) => {

                if (data?.status) {
                    setSubscriptionData(data?.querydata.app_planlist);
                    setPageCount(data.querydata.app_planlist.length);
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentPage, setHeaderTitle]);

    const currentTableData = useMemo(() => {
        const startIndex = (currentPage - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        return subscriptiondata?.slice(startIndex, endIndex);
    }, [currentPage, subscriptiondata, appcitydata]);

    useEffect(() => {
        GetAppPlansList().then(data => {
            setSubscriptionData(data.querydata.app_planlist);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        GetAppCityList().then(data => {
            setappcitydata(data.querydata.city_list);

            setLoading(false);
        });
    }, []);
    useEffect(() => {
        async function fetchRazorPayPlans() {
            try {
                const plans = await GetRazorPayPlan();
                setRazorpayPlans(plans.appUserPlan.items);
            } catch (error) {
                console.error(error);
            }
        }
        fetchRazorPayPlans();
    }, []);
    function formatStateName(stateName) {
        if (!stateName) return '';

        const formattedName = stateName.charAt(0).toUpperCase() + stateName.slice(1).toLowerCase();
        return formattedName;
    }

    const [formData, setFormData] = useState({
        app_city_id: "",
        period: "",
        interval: "",
        name: "",
        amount: "",
        currency: "",
        description: "",
        razorpay_plan_id: "",
        discounted_amount: "",
        razorpay_offer_id: "",
    });
    const [editformData, setEditFormData] = useState({
        app_city_id: "",
        period: "",
        interval: "",
        name: "",
        amount: "",
        currency: "",
        description: "",
        razorpay_plan_id: "",
        discounted_amount: "",
        razorpay_offer_id: "",
    });
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [id]: undefined,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };
    const handleEditInputChange = (event) => {
        const { id, value } = event.target;
        setEditFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    const [fieldErrors, setFieldErrors] = useState({});
    const handleAddAppPlanSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = true;
        const updatedFieldErrors = {
            app_city_id: "",
            period: "",
            interval: "",
            name: "",
            amount: "",
            currency: "",
            description: "",
            razorpay_plan_id: "",
            discounted_amount: "",
            razorpay_offer_id: "",
        };

        if (!formData.period) {
            updatedFieldErrors.period = "Please select the period";
            formIsValid = false;
        }
        if (!formData.interval) {
            updatedFieldErrors.interval = "Please enter interval";
            formIsValid = false;
        }
        if (!formData.app_city_id) {
            updatedFieldErrors.app_city_id = "Please select the city";
            formIsValid = false;
        }
        if (!formData.razorpay_plan_id) {
            updatedFieldErrors.razorpay_plan_id = "Please select the razorpay plan id";
            formIsValid = false;
        }
        if (!formData.amount) {
            updatedFieldErrors.amount = "Please enter amount";
            formIsValid = false;
        }
        if (!formData.currency) {
            updatedFieldErrors.currency = "Please select currency";
            formIsValid = false;
        }
        if (!formData.name) {
            updatedFieldErrors.name = "Please enter name";
            formIsValid = false;
        }

        if (!formIsValid) {
            setFieldErrors(updatedFieldErrors);
            return;
        }
        try {
            const response = await AddAppPlansList(formData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Subscription plan added successfully!',
            });

            GetAppPlansList().then(data => {
                setSubscriptionData(data.querydata.app_planlist);
                setLoading(false);
            });

            // Reset the form data
            setFormData({
                ...formData,
                app_city_id: "",
                period: "",
                interval: "",
                name: "",
                amount: "",
                currency: "",
                description: "",
                razorpay_plan_id: "",
                discounted_amount: "",
                razorpay_offer_id: "",
            });
            setShowAddPlan(false);

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (!showAddPlan) {
            reset();

        }
    }, [showAddPlan]);

    //update
    const handleEditAppPlanSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await UpdateAppPlans(editformData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Subscription plan edited successfully!',
            });

            GetAppPlansList().then(data => {
                setSubscriptionData(data.querydata.app_planlist);
                setLoading(false);
            });
            reset();
            setshoweditplan(false);
        } catch (error) {
            console.error(error);
        }
    };

    const resetForm = () => {
        setSelectedCity(null);
        setFormData({
            app_city_id: "",
            period: "",
            interval: "",
            name: "",
            amount: "",
            currency: "",
            description: "",
            razorpay_plan_id: "",
            discounted_amount: "",
            razorpay_offer_id: "",
        });
        const updatedFieldErrors = {
            app_city_id: "",
            period: "",
            interval: "",
            name: "",
            amount: "",
            currency: "",
            description: "",
            razorpay_plan_id: "",
            discounted_amount: "",
            razorpay_offer_id: "",
        };
        setFieldErrors(updatedFieldErrors);
    };

    const handleModalDeleteTask = (e) => {
        e.preventDefault();
        setShowAddPlan(true);

    };

    const handleModalEditProgram = (planId) => {
        GetAppPlansListByID({ id: planId })
            .then((data) => {
                const planData = data.querydata[0]; // Assuming the API returns an array with one object (plan data)
                const selectedCityOption = {
                    value: planData.app_city_id,
                    label: findCityStateDetails(planData.app_city_id)
                };

                setSelectedEditCity(selectedCityOption);
                setEditFormData(planData);

                reset({
                    app_city_id: selectedCityOption,
                });

                setshoweditplan(true);
            })
            .catch((error) => {
            });
    };


    useEffect(() => {
        if (!showeditplan) {
            reset();
        }
    }, [showeditplan]);

    const findCityStateDetails = (cityId) => {
        const city = appcitydata.find(city => city.id === cityId);
        return city ? `${city.city}, ${city.state_detail ? city.state_detail.name : ''}` : '';
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {loading ? (
                <>
                    <h2 style={{ paddingTop: '150px', fontSize: '20px' }}>
                        Please Wait Data is{" "}
                        <span style={{ color: 'orange', fontWeight: '600' }}>
                            Loading...
                        </span>
                    </h2>
                </>
            ) : (
                <>
                    <Button
                        size="medium"
                        style={{ backgroundColor: "#0DF6E3", color: "#2B303B", marginLeft: 20, float: 'right', marginBottom: '10px' }}
                        variant="contained" onClick={handleModalDeleteTask}
                    >
                        <AddIcon /> &nbsp;Add Subscription Plan &nbsp;{IsUnzipVideoClick ? <><div className="loader"></div> &nbsp; <span style={{ color: "#FF0000" }}>Compressing, Please Wait...</span></> : ''}
                    </Button>
                    <table className="alternate-row-colors " style={{ borderCollapse: 'collapse', width: '100%', }}>
                        <thead>
                            <tr>
                                <th className="previousDatath" style={{ textAlign: "center" }}>City</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Period</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Interval</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Name</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Amount</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Currency</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Razorpay Plan Id</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Discount</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Status</th>
                                <th className="previousDatath" style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTableData.map((plan, index) => (
                                <tr key={index}>
                                    <td className='dataTd'>
                                        {plan.app_city_detail.city}, {plan.state_detail ? formatStateName(plan.state_detail.name) : ''}
                                    </td>
                                    <td className='dataTd'>{plan.period ? plan.period : 'NA'}</td>
                                    <td className='dataTd'>{plan.interval ? plan.interval : 'NA'}</td>
                                    <td className='dataTd'>{plan.name ? plan.name : 'NA'}</td>
                                    <td className='dataTd'>{plan.amount ? plan.amount : 'NA'}</td>
                                    <td className='dataTd'>{plan.currency ? plan.currency : 'NA'}</td>
                                    <td className='dataTd'>{plan.razorpay_plan_id ? plan.razorpay_plan_id : 'NA'}</td>
                                    <td className='dataTd'>{plan.discounted_amount ? plan.discounted_amount : 'NA'}</td>
                                    <td className='dataTd'>{plan.status ? plan.status : 'NA'}</td>
                                    <td className='dataTd'>
                                        <Button title="Edit" onClick={() => handleModalEditProgram(plan.id)}>
                                            <EditIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={pageCount}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />

                    {/* Add subscription plan */}
                    <form >
                        {showAddPlan ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                                            <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                                                <h2 style={{ textAlign: 'center', margin: 'auto' }}>Add Subscription Plan</h2>
                                                <button style={{ width: '40px', margin: '0' }}
                                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => setShowAddPlan(false)}
                                                ><ClearIcon />

                                                </button>
                                            </div>

                                            <div className="relative p-6 flex-auto" style={{
                                                width: '100%',
                                                paddingTop: '0', margin: 'auto', height: '440px', overflowY: 'scroll', marginTop: "15px"
                                            }}>
                                                <div>
                                                    <div style={{ textAlign: "left" }}>
                                                        <div className="m-2 grid grid-cols-2 gap-2">

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="difficulty"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    City<span className="errorMsg">*</span>
                                                                </label>

                                                                <Select
                                                                    id="app_city_id"
                                                                    value={selectedCity}
                                                                    onChange={handleCityChange}
                                                                    options={sortedCityOptions}
                                                                    placeholder="Select City"
                                                                    isSearchable
                                                                />

                                                                {fieldErrors.app_city_id && (
                                                                    <p className="errorMsg">{fieldErrors.app_city_id}</p>
                                                                )}
                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="period"
                                                                    className="form-label inline-block mb-2 text-gray-700 text-left"
                                                                >
                                                                    Period<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="period"
                                                                    {...register("period", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.period}
                                                                    onChange={handleInputChange}
                                                                    className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="" disabled>Select a period</option>
                                                                    <option value="daily">Daily</option>
                                                                    <option value="weekly">Weekly</option>
                                                                    <option value="monthly">Monthly</option>
                                                                    <option value="yearly">Yearly</option>
                                                                </select>

                                                                {fieldErrors.period && (
                                                                    <p className="errorMsg">{fieldErrors.period}</p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Interval<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="interval"
                                                                    {...register("interval", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.interval}
                                                                    onChange={handleInputChange}
                                                                    placeholder="interval"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                                {fieldErrors.interval && (
                                                                    <p className="errorMsg">{fieldErrors.interval}</p>
                                                                )}

                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="Duration Type"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Razorpay Plan Id<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="razorpay_plan_id"
                                                                    {...register("duration_type", {
                                                                        required: true,
                                                                        validate: () => getValues("razorpay_plan_id") != "Select Duration Type",
                                                                    })}
                                                                    value={formData.razorpay_plan_id}
                                                                    onChange={handleInputChange}

                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="">Select Razorpay Plan</option>
                                                                    {razorpayPlans.map((plan) => (
                                                                        <option key={plan.id} value={plan.id}>
                                                                            {plan.id}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {fieldErrors.razorpay_plan_id && (
                                                                    <p className="errorMsg">{fieldErrors.razorpay_plan_id}</p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">
                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Amount<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    id="amount"
                                                                    {...register("amount", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.amount}
                                                                    onChange={handleInputChange}

                                                                    placeholder="Number of amount"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                                {fieldErrors.amount && (
                                                                    <p className="errorMsg">{fieldErrors.amount}</p>
                                                                )}
                                                            </div>
                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Currency<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="currency"
                                                                    {...register("currency", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.currency}
                                                                    onChange={handleInputChange}
                                                                    className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="" disabled>Select a currency</option>
                                                                    <option value="INR">INR</option>
                                                                </select>

                                                                {fieldErrors.currency && (
                                                                    <p className="errorMsg">{fieldErrors.currency}</p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">
                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Name<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    {...register("name", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.name}
                                                                    onChange={handleInputChange}
                                                                    placeholder="name"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                                {fieldErrors.name && (
                                                                    <p className="errorMsg">{fieldErrors.name}</p>
                                                                )}

                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Discount
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="discounted_amount"
                                                                    {...register("discounted_amount", {
                                                                        required: true
                                                                    })}
                                                                    value={formData.discounted_amount}
                                                                    onChange={handleInputChange}
                                                                    placeholder="discount"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                <button
                                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                                    type="button"

                                                    onClick={() => {
                                                        setShowAddPlan(false);
                                                        resetForm();
                                                    }}
                                                >
                                                    <CancelIcon /> Cancel Plan
                                                </button>
                                                <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit' onClick={handleAddAppPlanSubmit} >
                                                    <SaveIcon /> Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    </form>
                    {/* update */}
                    <form >
                        {showeditplan ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-auto my-6 mx-auto " style={{ width: '1000px' }}>
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" >
                                            <div style={{ margin: '2px' }} className="flex items-start justify-between border-b border-solid border-slate-200 rounded-t">

                                                <h2 style={{ textAlign: 'center', margin: 'auto' }}>Edit Subscription Plan</h2>

                                                <button style={{ width: '40px', margin: '0' }}
                                                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => setshoweditplan(false)}
                                                ><ClearIcon />
                                                </button>
                                            </div>
                                            <div className="relative p-6 flex-auto" style={{
                                                width: '100%',
                                                paddingTop: '0', margin: 'auto', height: '440px', overflowY: 'scroll', marginTop: "15px"
                                            }}>
                                                <div>
                                                    <div style={{ textAlign: "left" }}>
                                                        <div className="m-2 grid grid-cols-2 gap-2">
                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="difficulty"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    City<span className="errorMsg">*</span>
                                                                </label>
                                                                <Select
                                                                    name="app_city_id"
                                                                    options={appcitydata
                                                                        .sort((cityA, cityB) => cityA.city.localeCompare(cityB.city))
                                                                        .map(city => ({
                                                                            value: city.id,
                                                                            label: `${city.city}, ${city.state_detail ? city.state_detail.name : ''}`
                                                                        }))
                                                                    }
                                                                    value={selectedEditCity}
                                                                    onChange={selectedOption => handleEditCityChange(selectedOption)}
                                                                />
                                                            </div>
                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="period"
                                                                    className="form-label inline-block mb-2 text-gray-700 text-left"
                                                                >
                                                                    Period<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="period"
                                                                    {...register("period", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.period}
                                                                    onChange={handleEditInputChange}
                                                                    className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="" disabled>Select a period</option>
                                                                    <option value="daily">Daily</option>
                                                                    <option value="weekly">Weekly</option>
                                                                    <option value="monthly">Monthly</option>
                                                                    <option value="yearly">Yearly</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Interval<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="interval"
                                                                    {...register("interval", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.interval}
                                                                    onChange={handleEditInputChange}
                                                                    placeholder="interval"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="Duration Type"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Razorpay Plan Id<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="razorpay_plan_id"
                                                                    {...register("duration_type", {
                                                                        required: true,
                                                                        validate: () => getValues("razorpay_plan_id") != "Select Duration Type",
                                                                    })}
                                                                    value={editformData.razorpay_plan_id}
                                                                    onChange={handleEditInputChange}
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="">Select Razorpay Plan</option>
                                                                    {razorpayPlans.map((plan) => (
                                                                        <option key={plan.id} value={plan.id}>
                                                                            {plan.id}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Amount<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    id="amount"
                                                                    {...register("amount", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.amount}
                                                                    onChange={handleEditInputChange}

                                                                    placeholder="Number of amount"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Currency<span className="errorMsg">*</span>
                                                                </label>
                                                                <select
                                                                    id="currency"
                                                                    {...register("currency", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.currency}
                                                                    onChange={handleEditInputChange}
                                                                    className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                >
                                                                    <option value="" disabled>Select a currency</option>
                                                                    <option value="INR">INR</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="m-2 grid grid-cols-2 gap-2">

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Name<span className="errorMsg">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    {...register("name", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.name}
                                                                    onChange={handleEditInputChange}
                                                                    placeholder="name"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />

                                                            </div>

                                                            <div className="form-control">
                                                                <label
                                                                    htmlFor="duration"
                                                                    className="form-label inline-block mb-2 text-gray-700"
                                                                >
                                                                    Discount
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="discounted_amount"
                                                                    {...register("discounted_amount", {
                                                                        required: true
                                                                    })}
                                                                    value={editformData.discounted_amount}
                                                                    onChange={handleEditInputChange}
                                                                    placeholder="discount"
                                                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                                <button
                                                    className="bg-red-500 text-black py-1 px-3 rounded mr-2"
                                                    type="button"
                                                    onClick={() => { setshoweditplan(false); }}
                                                >
                                                    <CancelIcon /> Cancel Plan
                                                </button>
                                                <button className="bg-green-500 text-black py-1 px-3 rounded " type='submit' onClick={handleEditAppPlanSubmit} >
                                                    <SaveIcon /> Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    </form>
                </>
            )}
        </div>
    );
}
export default SubscriptionPlans;
