import React from "react";
import demoImage from '../../../assets/redball.jpg'

const Header = ({ title }) => {
  return (
    <section className="pb-20 flex flex-row justify-center relative">
      <img src={demoImage} alt="institution logo" className="w-20 absolute top-0 left-0" />
      <h1 className="text-4xl font-bold text-center">{title}</h1>
    </section>
  )
}

export default Header;
