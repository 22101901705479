import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  ButtonGroup,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  List,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import firebase from "../../firebase";
import "firebase/storage";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import MuxVideo from "@mux/mux-video-react";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import {
  getLiveAnalysisDetail,
  updateLiveStreamingDetail,
  createLiveStream,
  getLiveStreamDetails,
  finishLiveStream,
  deleteAllLiveStream,
} from "./MatchAna/Helper";

const LiveFeed = () => {
  const startStopLiveCollection = firebase
    .firestore()
    .collection("StartStopLiveCollections");
  const [isRecording, setIsRecording] = useState(false);
  const [isDisabledRecordButton, setIsDisabledRecordButton] = useState(false);
  const [selecteCamera, setSelecteCamera] = useState("Camera1");
  const [playbackId, setPlaybackId] = useState("");
  // const [oldPlaybackId, setOldPlaybackId] = useState("");
  // const [streamId, setStreamId] = useState("");
  // const [streamKey, setStreamKey] = useState("");
  // const [assetId, setAssetId] = useState("");

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const analysis_master_id = queryParams.analysis_master_id;
  const [tournamentMatchName, setTournamentMatchName] = useState("");

  const [CameraFirstName, setCameraFirstName] = useState("");
  const [CameraSecondName, setCameraSecondName] = useState("");

  const [CameraFirstId, setCameraFirstId] = useState("");
  const [CameraSecondId, setCameraSecondId] = useState("");
  const [CameraInningOver, setCameraInningOver] = useState("");

  const [timer, setTimer] = useState(10);

  const handleRecording = (value) => (event) => {
    if (updateFormData.inning == null || updateFormData.over == null) {
      Swal.fire("Info", "Please select inning and over");
    } else {
      if (value == true) {
        localStorage.setItem('videoStreamTimer', "timerStart");
      }
      else {
        localStorage.setItem('videoStreamTimer', "timerEnd");
      }

      setIsDisabledRecordButton(true);

      setTimeout(() => setIsDisabledRecordButton(false), 5000);

      getLiveAnalysisDetail({
        analysis_master_id: analysis_master_id,
      }).then(async (data) => {
        console.log(
          "data.AnalysisMasterDetail.length ==================>" +
          data.AnalysisMasterDetail.length
        );
        if (data.AnalysisMasterDetail.length > 0) {
          const doc = startStopLiveCollection.doc(
            data.AnalysisMasterDetail[0].full_tournament_name
          );

          doc.get().then((res) => {
            if (!res.exists) {
              Swal.fire(
                "Info",
                "Firebase database record not available to start video"
              );
              return;
            }

            let docToUpdate;
            if (value == true) {
              console.log("Start Recording ================>" + value.toString());

              if (selecteCamera == "Camera1") {
                docToUpdate = {
                  Camera1: {
                    CameraStatus: true,
                    UserID: CameraFirstId.toString(),
                  },
                  Camera2: {
                    CameraStatus: false,
                    UserID: CameraSecondId.toString(),
                  },
                };
              } else {
                docToUpdate = {
                  Camera1: {
                    CameraStatus: false,
                    UserID: CameraFirstId.toString(),
                  },
                  Camera2: {
                    CameraStatus: true,
                    UserID: CameraSecondId.toString(),
                  },
                };
              }

              createLiveStream().then((result) => {
                console.log("result ==========1=====>" + JSON.stringify(result));

                console.log("result ===============>" + result.live_streaming["data"]["stream_key"]);

                docToUpdate.streamKey = result.live_streaming["data"]["stream_key"];
                docToUpdate.streamId = result.live_streaming["data"]["id"];

                localStorage.setItem("streamId", result.live_streaming["data"]["id"]);
                localStorage.setItem("oldPlaybackId", result.live_streaming["data"]["playback_ids"][0]["id"]);

                // setStreamId(result.live_streaming["data"]["id"]);
                // setStreamKey(result.live_streaming["data"]["stream_key"]);
                // setOldPlaybackId(result.live_streaming["data"]["playback_ids"][0]["id"]);

                doc.update(docToUpdate)
                  .then(() => {
                    setIsRecording(value);
                  })
                  .catch((err) => {
                    console.log("err", err);
                    setIsRecording(false);
                  });
              });
            } else {
              console.log("Stop Recording ================>" + value.toString());

              docToUpdate = {
                Camera1: {
                  CameraStatus: false,
                  UserID: CameraFirstId.toString(),
                },
                Camera2: {
                  CameraStatus: false,
                  UserID: CameraSecondId.toString(),
                },
                streamKey: "",
              };

              localStorage.setItem("streamId", null);
              localStorage.setItem("oldPlaybackId", null);

              doc.update(docToUpdate)
                .then(() => {
                  setIsRecording(value);
                  localStorage.setItem('videoStreamTimer', "timerEnd");
                  window.close();
                })
                .catch((err) => {
                  console.log("err", err);
                  setIsRecording(false);
                });
            }

            //console.log("docToUpdatedocToUpdate", docToUpdate);
          });
        }
      });
    }

    if (value === false) {
      finishLiveStream(localStorage.getItem("streamId")).then((result) => {
        console.log("result ==========3=====>" + JSON.stringify(result.finish_live_stream));
      });
    }
  };

  const onCameraSelect = (event) => {
    setSelecteCamera(event.target.value);
  };

  const [updateFormData, setUpdateFormData] = useState({
    inning: null,
    over: null,
  });

  const setFormData = (field) => (e) => {
    // setUpdateFormData({
    //   ...updateFormData, [field]: parseInt(e.target.value)
    // });
    if (field == "inning") {
      updateFormData.inning = parseInt(e.target.value);
    }
    if (field == "over") {
      updateFormData.over = parseInt(e.target.value);
    }
  };

  useEffect(() => {

    if (localStorage.getItem("streamId") == null) {
      localStorage.setItem("streamId", "null");
    }

    if (localStorage.getItem("oldPlaybackId") == null) {
      localStorage.setItem("oldPlaybackId", "null");
    }

    // console.log("window.location.pathname", analysis_master_id);
    // deleteAllLiveStream();

    var streamId = localStorage.getItem("streamId");
    var oldPlaybackId = localStorage.getItem("oldPlaybackId");

    console.log("streamId ===============>" + streamId);
    console.log("oldPlaybackId ===============>" + oldPlaybackId);

    if (streamId != null) {

      getLiveAnalysisDetail({
        analysis_master_id: analysis_master_id,
      })
        .then(async (data) => {
          console.log("FirstTimeOnlydatadatadatadata--->", data)
          if (data.AnalysisMasterDetail.length > 0) {
            setTournamentMatchName(data.AnalysisMasterDetail[0].full_tournament_name);
            setCameraFirstName(data.AnalysisMasterDetail[0].Camera1UserName);
            setCameraSecondName(data.AnalysisMasterDetail[0].Camera2UserName);
            setCameraFirstId(data.AnalysisMasterDetail[0].Camera1User);
            setCameraSecondId(data.AnalysisMasterDetail[0].Camera2User);
            setCameraInningOver(data.AnalysisMasterDetail[0].overs);

            const timeoutFunction = setInterval(() => {
              console.log("updateFormData", updateFormData);
              if (
                updateFormData.inning != null &&
                updateFormData.over != null
              ) {
                const doc = startStopLiveCollection.doc(
                  data.AnalysisMasterDetail[0]
                    .full_tournament_name
                );
                doc.onSnapshot((doc) => {
                  // console.log("data ==============>" + JSON.stringify(doc));

                  if (doc.exists) {
                    const start_collection_data = doc.data();

                    if (oldPlaybackId !== "null" && streamId !== "null") {
                      console.log("streamId =======2=========>", streamId);

                      getLiveStreamDetails(streamId).then((result) => {
                        console.log("result =========2======>" + JSON.stringify(result));

                        if (
                          result.live_streaming_details["data"]["status"] === "active"
                        ) {
                          setPlaybackId(oldPlaybackId);

                          localStorage.setItem("assetId", result.live_streaming_details["data"]["active_asset_id"]);

                          updateLiveStreamingDetail({
                            analysis_master_id: analysis_master_id,
                            inning: updateFormData.inning,
                            over: updateFormData.over,
                            full_tournament_name: data.AnalysisMasterDetail[0].full_tournament_name,
                            livePlaybackId: oldPlaybackId,
                            asset_id: result.live_streaming_details["data"]["active_asset_id"],
                            stream_key: result.live_streaming_details["data"]["stream_key"],
                          }).then(
                            async (update_data) => { }
                          );

                          localStorage.setItem("oldPlaybackId", "null");
                          oldPlaybackId = "null";
                        }
                      });
                    }
                  }
                });
              } else {
                setPlaybackId("");
              }
            }, 5000);

            if (playbackId !== "") {
              return () => clearInterval(timeoutFunction);
            }

          }
        })
        .catch((error) => {
          console.debug("error while getting video files...", error);
          return error;
        });
    }
  }, [analysis_master_id, playbackId, startStopLiveCollection, timer, updateFormData]);


  return (
    <>
      <div style={{ marginTop: "-35px" }}>
        <h1>
          Live Streaming -{" "}
          <span style={{ color: "#00008B" }}>
            {tournamentMatchName}
          </span>
        </h1>
        <MuxVideo
          style={{ height: "300px", maxWidth: "450px" }}
          playbackId={playbackId} //"DS00Spx1CV902MCtPj5WknGlR102V5HFkDe"
          metadata={{
            video_id: "video-id-123456",
            video_title: "Super Interesting Video",
            viewer_user_id: "user-id-bc-789",
          }}
          streamType="live"
          controls
          autoPlay
          muted
        />
      </div>

      <table>
        <tr>
          <td>
            <div className="w-[200px]">
              <label
                htmlFor="editInnings"
                className="form-label inline-block mb-2 text-gray-700 font-semibold pt-2"
              >
                Innings
              </label>

              <select
                id="editInnings"
                defaultValue="Select Inning"
                onChange={setFormData("inning")}
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
              >
                <option>Select Inning</option>
                <option value={1}>First Inning</option>
                <option value={2}>Second Inning</option>
              </select>
            </div>
          </td>
          <td>
            <div className="w-[200px]">
              <label
                htmlFor="editOver"
                className="form-label inline-block mb-2 text-gray-700 font-semibold pt-2"
              >
                Overs
              </label>
              <select
                id="editOver"
                defaultValue="Select Over"
                onChange={setFormData("over")}
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
              >
                <option>Select Over</option>
                {Array(CameraInningOver)
                  .fill(null)
                  .map((_, id) => (
                    <option value={id} key={id}>
                      {id + 1}
                    </option>
                  ))}
              </select>
            </div>
          </td>
        </tr>
      </table>

      <Typography variant="h6">Cameras</Typography>
      <RadioGroup value={selecteCamera} onChange={onCameraSelect}>
        <FormControlLabel
          value="Camera1"
          control={<Radio size="small" />}
          label={`Camera 1 (${CameraFirstName})`}
          disabled={isRecording}
        />
        <FormControlLabel
          value="Camera2"
          control={<Radio size="small" />}
          label={`Camera 2 (${CameraSecondName})`}
          disabled={isRecording}
        />
      </RadioGroup>
      <div>
        {isRecording ? (
          <>
            <IconButton
              title={`Stop Streaming Video`}
              onClick={handleRecording(false)}
              disabled={isDisabledRecordButton}
            >
              <StopCircleIcon
                fontSize="large"
                htmlColor="#0df315ff"
              />
            </IconButton>
            Stop Streaming Video
          </>
        ) : (
          <>
            <IconButton
              title={`Start Streaming Video`}
              onClick={handleRecording(true)}
              disabled={isDisabledRecordButton}
            >
              <RadioButtonCheckedIcon
                fontSize="large"
                color="error"
              />
            </IconButton>
            Start Streaming Video
          </>
        )}
      </div>
    </>
  );
};

export default LiveFeed;
