import axios from 'axios';

export const AddTournament = async (tournament) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/tournaments`,
			tournament,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAllTournaments = async () => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_BASE_URL}/tournaments`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};