import React, { useState, useEffect } from "react";
import Creatable from "react-select/creatable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addNewSubscription } from "../../../Helper";

const AddPlayerToPlan = ({
  selectedPlayerIndex,
  selectedPlayerName,
  loggedInUserInstitutionID,
  validPlanList,
  addedNewSubscriptionReload,
  setAddNewSubscriptionReload,
}) => {
  const [addSubscriptionToggle, setAddSubscriptionToggle] = useState(false);
  //   const [selectedPlanIndex, setSelectedPlanIndex] = useState("");
  //   const [selectedPlanName, setSelectedPlanName] = useState("");
  const [selectedPlanOption, setSelectedPlanOption] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  // const [dateToday, setDateToday] = useState(new Date());
  // const [validFrom, setValidFrom] = useState(new Date());
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTill, setValidTill] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  // console.log("date today:", dateToday.toISOString().slice(0, 10));
  // console.log("valid from", validFrom.toISOString().slice(0, 10));

  const handlePlanSelection = (selectedOption) => {
    if (selectedOption) {
      const selectedPlanData = validPlanList.find(
        (plan) => plan.plan_name === selectedOption.label
      );

      setSelectedPlanOption({
        plan_id: selectedPlanData.id,
        plan_name: selectedOption.label,
        // payment_duration: selectedPlanData.payment_duration,
      });
      setSelectedPlan({
        plan_id: selectedPlanData.id,
        institution_id: selectedPlanData.institution_id,
        plan_name: selectedOption.label,
        payment_duration: selectedPlanData.payment_duration,
      });
      // console.log(selectedPlan);
    } else {
      setSelectedPlan(null);
    }
  };

  const handleValidFromChange = (valid_from) => {
    // setDateOfJoining(dateOfJoining);
    const utcDate = new Date(valid_from);
    utcDate.setUTCHours(0, 0, 0, 0);
    // Increase the date by one day
    // utcDate.setDate(utcDate.getDate() + 1);
    setValidFrom(utcDate);
    // setValidTill();
  };

  const handleAddSubscriptionClick = async () => {
    const subscriptionData = {};
    subscriptionData.user_id = selectedPlayerIndex;
    subscriptionData.institution_id = selectedPlan.institution_id;
    subscriptionData.added_to_plan_id = selectedPlan.plan_id;
    subscriptionData.valid_from = validFrom;
    subscriptionData.valid_till = validTill;
    try {
      const data = await addNewSubscription(subscriptionData);
      console.log(data);
      // if (data.status) {
      // this is for reloading active plans list with newly added plan
      setAddNewSubscriptionReload(addedNewSubscriptionReload + 1);
      setSubmitStatus(data.status);
      setSubmitMessage(data.message);
      setShowSubmitMessage(true);
      setAddSubscriptionToggle(!addSubscriptionToggle);
      handleReset();
    } catch (error) {
      console.log(error);
      setSubmitStatus(false);
      setSubmitMessage(
        "Error while submitting. Try to submit again in some time."
      );
      setShowSubmitMessage(true);
    }

    // Clear the message after 2 seconds
    setTimeout(() => {
      setSubmitStatus(false);
      setSubmitMessage("");
      setShowSubmitMessage(false);
    }, 2000);
  };

  const handleReset = () => {
    setSelectedPlan(null);
    setSelectedPlanOption(null);
    setValidFrom(new Date());
    setValidTill("");
  };
  useEffect(() => {
    setAddSubscriptionToggle(false);
    handleReset();
  }, [selectedPlayerIndex]);

  useEffect(() => {
    if (validFrom && selectedPlan?.payment_duration) {
      let monthsToAdd = 0;

      switch (selectedPlan?.payment_duration) {
        case "Annual":
          monthsToAdd = 12;
          break;
        case "Semi-Annual":
          monthsToAdd = 6;
          break;
        case "Tri-Annual":
          monthsToAdd = 4;
          break;
        case "Quarterly":
          monthsToAdd = 3;
          break;
        case "Monthly":
          monthsToAdd = 1;
          break;
        default:
          monthsToAdd = 0;
      }

      const newValidTill = new Date(validFrom);
      newValidTill.setMonth(newValidTill.getMonth() + monthsToAdd);
      setValidTill(newValidTill);
    }
  }, [selectedPlan, validFrom]);

  return (
    <div className="flex flex-col gap-1">
      <p>
        Selected user:{" "}
        {selectedPlayerName ? selectedPlayerName : "No user selected yet."}
      </p>
      <div className="flex flex-row gap-4">
        {!addSubscriptionToggle ? (
          <button
            onClick={() => setAddSubscriptionToggle(!addSubscriptionToggle)}
            className="rounded-lg border border-black p-2 transform active:scale-x-75 active:scale-y-75 transition-transform"
          >
            Add Subscription
          </button>
        ) : selectedPlayerIndex != "" ? (
          <div className="grid grid-cols-7 gap-4 items-center z-20">
            <div className="col-span-2">
              Add a subscription for {selectedPlayerName}:
            </div>
            <div className="col-span-1">
              <Creatable
                options={validPlanList
                  .filter((plan) => new Date(plan.valid_till) > new Date())
                  .map((plan) => ({
                    label: plan.plan_name,
                    value: plan.plan_name,
                  }))}
                placeholder="Choose Plan"
                value={
                  selectedPlanOption
                    ? {
                        label: selectedPlanOption.plan_name,
                        value: selectedPlanOption.plan_name,
                      }
                    : null
                }
                onChange={handlePlanSelection}
                isSearchable={true}
                isCreatable={false}
                isRequired
              />
            </div>
            <div>
              <div className="text-center">Valid From</div>
              <DatePicker
                selected={validFrom}
                onChange={handleValidFromChange}
                dateFormat="dd-MMM-yyyy"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={50}
                className="text-center p-1 rounded border border-black w-full"
              />
            </div>
            <div className="text-center">
              {validTill
                ? "Valid till: " +
                  validTill.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : ""}
            </div>
            <button
              onClick={handleAddSubscriptionClick}
              className="bg-green-500 hover:bg-green-400 rounded p-2"
            >
              Add
            </button>
            <button
              onClick={() => setAddSubscriptionToggle(!addSubscriptionToggle)}
              className="bg-red-600 hover:bg-red-500 rounded p-2"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="py-2">Select a player first!</div>
        )}
      </div>
      <p
        className={`text-center ${
          showSubmitMessage ? "visible" : "invisible"
        } ${submitStatus ? "bg-green-500" : "bg-red-500"}`}
        style={{ minHeight: "1em" }}
      >
        {submitMessage ? submitMessage : ""}
      </p>
    </div>
  );
};

export default AddPlayerToPlan;
