import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Header from "./Components/Header";
import FeePlanList from "./Components/SetupFees/FeePlanList";
import FeePlanListHeader from "./Components/SetupFees/FeePlanListHeader";
import AddNewOrEditExistingPlan from "./Components/SetupFees/AddNewOrEditExistingPlan";
import { getLoggedInUserInstitutionID } from "./Helper";

const SetupFees = () => {
  const [headerTitle, setHeaderTitle] = useOutletContext();
  useEffect(() => {
    setHeaderTitle("Setup Fees");
  }, []);
  const [addNewPlanToggle, setAddNewPlanToggle] = useState(true);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState("");
  const [loggedInUserInstitutionID, setLoggedInUserInstitutionID] =
    useState("");
  const [
    isLoadingLoggedInUserInstitutionID,
    setIsLoadingLoggedInUserInstitutionID,
  ] = useState(true);
  const [postSaveRefresh, setPostSaveRefresh] = useState(0);

  // this sets institution id in browser session and is used by playerList
  useEffect(() => {
    const fetchData = async () => {
      const data = await getLoggedInUserInstitutionID();
      setLoggedInUserInstitutionID(data);
      setIsLoadingLoggedInUserInstitutionID(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="m-12 px-12 py-6 border border-black border-2 rounded-xl flex flex-col gap-4">
        <Header title={"Setup Fees"} />
        {!loggedInUserInstitutionID ? (
          <p>Loading institution info...</p>
        ) : (
          <div className="flex flex-col gap-4">
            <AddNewOrEditExistingPlan
              addNewPlanToggle={addNewPlanToggle}
              setAddNewPlanToggle={setAddNewPlanToggle}
              selectedPlanIndex={selectedPlanIndex}
              setSelectedPlanIndex={setSelectedPlanIndex}
              loggedInUserInstitutionID={loggedInUserInstitutionID}
              postSaveRefresh={postSaveRefresh}
              setPostSaveRefresh={setPostSaveRefresh}
            />
            <div className="flex flex-col relative border border-black">
              <FeePlanListHeader />
              <FeePlanList
                selectedPlanIndex={selectedPlanIndex}
                setSelectedPlanIndex={setSelectedPlanIndex}
                loggedInUserInstitutionID={loggedInUserInstitutionID}
                postSaveRefresh={postSaveRefresh}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SetupFees;
