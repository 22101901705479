import axios from "axios";
import Swal from "sweetalert2";

export const GetAllInstitutes = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/institutions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetAllAnalysis = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-previous-analysis-data`,
      {},
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addMissingVideoLink = async (analysis_master_detail) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-missing-video-link`,
      { analysis_master_detail },
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addMissingVideoLinkNew = async (analysis_master_detail) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-missing-video-link-new`,
      { analysis_master_detail },
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const DeCompressAllVideosAPI = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/de-compress-all-videos`,
      {},
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const CheckUmpireOTP = async (umpire_otp) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check_umpire_otp`,
      umpire_otp,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const ZipDownloadMatchAPI = async (match_data, filename) => {
  try {
    // Swal.showLoading();
    Swal.fire({
      title: "Downloading files...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/download-video-in-zip-file`,
      match_data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
        responseType: "arraybuffer",
      }
    );

    if (res.data.byteLength == 0) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "👎",
        text: "Sorry, There is no record available for download",
      });
      return { success: false, message: "No record available for download" };
    } else {
      const blob = new Blob([res.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      // a.download = "files.zip";
      a.download = filename + ".zip"; // Set the filename here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "👍",
        text: "Files downloaded successfully!",
      });
      return { success: true, message: "Files downloaded successfully" };
    }
  } catch (error) {
    console.error("Error downloading zip file:", error.message);
  }
};

export const GetPlayerListName = async (match_id) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-team-playerlist-bymatchid`,
      { match_id },
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// export const ZipDownloadMatchCount = async (match_id) => {
//   try {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${
//         JSON.parse(localStorage.getItem("WellplayedToken")).token
//       }`,
//     };

//     const res = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/count-video-in-zip-file`,
//       { match_id },
//       { headers }
//     );
//     return res.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const ZipDownloadMatchCount = async (
  match_id,
  current_inning,
  player_id,
  download_type
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/count-video-in-zip-file`,
      { match_id, current_inning, player_id, download_type },
      { headers }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const AddVideoTagList = async (match_id) => {
  try {
    Swal.fire({
      title: "😣Please Wait Data is Loading......",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/manual-job-unzipping-tagging-video`,
      { match_id },
      { headers }
    );
    Swal.close();
    // console.log("Response data:", res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const VideoGalleryListByInning = async (
  match_id,
  current_inning,
  offset
) => {
  try {
    Swal.fire({
      title: "😣Please Wait Data is Loading......",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("WellplayedToken")).token
      }`,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-video-gallery-list-by-inning`,
      { match_id, current_inning, offset },
      { headers }
    );
    Swal.close();
    // console.log("Response data:", res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
