import React, { useState, useEffect } from "react";
import Creatable from "react-select/creatable";
import DatePicker from "react-datepicker";
import EditIcon from "@mui/icons-material/Edit";
import FeeHistoryHeader from "./../FeeHistoryHeader";
import { fetchPlayerFeeDetails, updatePlayerFeeDetails } from "../../../Helper";

const FeeHistoryList = ({
  feeDataArray,
  //   editedRowIndex,
  isActiveSubscriptionList,
  selectedSubscriptionOption,
  setSelectedSubscriptionOption,
  addedNewSubscriptionReload,
  setAddNewSubscriptionReload,
  setIsFeeDataLoading,
  selectedPlayerIndex,
  setFeeDataArray,
}) => {
  const dateToday = new Date();
  const [updatedFeesPaid, setUpdatedFeesPaid] = useState("");
  const [updatedDiscount, setUpdatedDiscount] = useState("");
  const [updatedDiscountReason, setUpdatedDiscountReason] = useState("");
  const [updatedDateOfPayment, setUpdatedDateOfPayment] = useState(dateToday);
  const [updatedValidFrom, setUpdatedValidFrom] = useState(dateToday);
  const [updatedValidTill, setUpdatedValidTill] = useState(dateToday);
  const [isDateOfPaymentUpdated, setIsDateOfPaymentUpdated] = useState(false);
  const [isValidFromUpdated, setIsValidFromUpdated] = useState(false);
  const [isValidTillUpdated, setIsValidTillUpdated] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");
  const [editedRowIndex, setEditedRowIndex] = useState(-1);
  const [selectedFilter, setSelectedFilter] = useState("");

  const handleSubscriptionSelect = (selectedOption) => {
    if (selectedOption) {
      const selectedPlanData = isActiveSubscriptionList.find(
        (plan) => plan.plan_name === selectedOption.label
      );

      setSelectedSubscriptionOption({
        plan_id: selectedPlanData.id,
        plan_name: selectedOption.label,
        // payment_duration: selectedPlanData.payment_duration,
      });
      setSelectedSubscriptionPlan(selectedPlanData.id);
      // console.log(selectedPlan);
    } else {
      setSelectedSubscriptionOption("");
      setSelectedSubscriptionPlan(null);
    }
  };

  const handleFeesPaidChange = (event) => {
    setUpdatedFeesPaid(event.target.value);
  };

  // Handle changes in the 'discount' input field
  const handleDiscountChange = (event) => {
    setUpdatedDiscount(event.target.value);
  };

  // Handle changes in the 'discount' input field
  const handleDiscountReasonChange = (event) => {
    setUpdatedDiscountReason(event.target.value);
  };

  // Handle changes in the 'date_of_payment' DatePicker
  const handleDateOfPaymentChange = (date) => {
    setIsDateOfPaymentUpdated(true);
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setUpdatedDateOfPayment(utcDate);
  };

  // Handle changes in the 'valid_from' DatePicker
  const handleValidFromChange = (date) => {
    setIsValidFromUpdated(true);
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setUpdatedValidFrom(utcDate);
  };

  // Handle changes in the 'valid_till' DatePicker
  const handleValidTillChange = (date) => {
    setIsValidTillUpdated(true);
    const utcDate = new Date(date);
    utcDate.setUTCHours(0, 0, 0, 0);
    setUpdatedValidTill(utcDate);
  };

  const handleSaveChanges = async (selectedDbRowIndex) => {
    const updatedData = {};

    if (selectedSubscriptionPlan != "") {
      updatedData.active_plan_id = selectedSubscriptionPlan;
    }
    if (updatedFeesPaid !== "") {
      updatedData.fees_paid = updatedFeesPaid;
    }
    if (updatedDiscount !== "") {
      updatedData.discount = updatedDiscount;
    }
    if (updatedDiscountReason !== "") {
      updatedData.discount_reason = updatedDiscountReason;
    }

    if (isDateOfPaymentUpdated) {
      updatedData.date_of_payment = updatedDateOfPayment;
    }

    if (isValidFromUpdated) {
      updatedData.valid_from = updatedValidFrom;
    }
    if (isValidTillUpdated) {
      updatedData.valid_till = updatedValidTill;
    }

    if (Object.keys(updatedData).length > 0) {
      try {
        const response = await updatePlayerFeeDetails(
          updatedData,
          selectedDbRowIndex
        );
        setAddNewSubscriptionReload(addedNewSubscriptionReload + 1);
      } catch (error) {
        console.log("Error while updating PlayerFeeDetails:", error.message);
      } finally {
        setSelectedSubscriptionPlan("");
        setSelectedSubscriptionOption("");
        setUpdatedFeesPaid("");
        setUpdatedDiscount("");
        setUpdatedDiscountReason("");
        setIsDateOfPaymentUpdated(false);
        setIsValidFromUpdated(false);
        setIsValidTillUpdated(false);
        setIsFeeDataLoading(true);
        // setDbRowDataIndex('')
        // re-fetch updated data
        try {
          const data = await fetchPlayerFeeDetails(selectedPlayerIndex);
          setFeeDataArray(data);
          setIsFeeDataLoading(false);
        } catch (error) {
          console.log("Error in fetchPlayerFeeDetails:", error.message);
        }
      }
    } else {
      console.log("No fields to update.");
    }
  };

  const handleCancelChanges = () => {
    // first reset edited data
    setSelectedSubscriptionPlan("");
    setSelectedSubscriptionOption("");
    setUpdatedFeesPaid("");
    setUpdatedDiscount("");
    setUpdatedDiscountReason("");
    setIsDateOfPaymentUpdated(false);
    setIsValidFromUpdated(false);
    setIsValidTillUpdated(false);
    // then close the opened row
    setEditedRowIndex(-1);
  };

  useEffect(() => {
    console.log("selectedSubscriptionOption:", selectedSubscriptionOption);
    console.log("feeData:", selectedSubscriptionOption);
    if (editedRowIndex != -1) {
      const selectedPlan = isActiveSubscriptionList.find(
        (plan) => plan.id === feeDataArray[editedRowIndex].active_plan_id
      );

      if (selectedPlan) {
        setSelectedSubscriptionOption({
          plan_id: selectedPlan.plan_name,
          plan_name: selectedPlan.plan_name,
        });
      }
    }
  }, [editedRowIndex]);

  const filterOptions = isActiveSubscriptionList.map((subscription) => ({
    label: subscription.plan_name,
    value: subscription.plan_name,
  }));

  // Filter dropdown to select the active subscription
  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption ? selectedOption.value : "");
  };

  // Button to clear the filter
  // const clearFilter = () => {
  //   setSelectedFilter("");
  // };

  // Filter the feeDataArray based on the selected filter option
  const filteredFeeDataArray = selectedFilter
    ? feeDataArray.filter(
        (feeDataObject) =>
          feeDataObject.active_plan_id ===
          isActiveSubscriptionList.find(
            (plan) => plan.plan_name === selectedFilter
          )?.id
      )
    : feeDataArray;

  return (
    <div className="flex flex-col gap-4">
      {/* Filter dropdown */}
      <div className="flex w-60 z-20">
        <Creatable
          options={filterOptions}
          placeholder="Filter fees by subscription"
          value={
            selectedFilter
              ? { label: selectedFilter, value: selectedFilter }
              : null
          }
          onChange={handleFilterChange}
          isSearchable={true}
          // Allow clearing the filter
          isClearable={true}
          className="items-center text-sm z-10 w-full"
        />
      </div>

      {/* Clear filter button */}
      {/* {selectedFilter && (
          <button onClick={clearFilter} className="mt-2">
            Clear Filter
          </button>
        )} */}
      <div className="border-y border-black ">
        <FeeHistoryHeader />
        <div className="max-h-60 overflow-auto">
          {filteredFeeDataArray.length > 0 ? (
            filteredFeeDataArray.map((feeDataObject, index) => {
              return editedRowIndex == index ? (
                <div
                  key={index}
                  className="grid grid-cols-12 border-black border-l border-b cursor-pointer"
                >
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {index + 1}
                  </div>
                  <div className="col-span-3 flex items-center justify-center border-black border-r ">
                    <Creatable
                      options={filterOptions}
                      placeholder="Choose subscription"
                      value={
                        selectedSubscriptionOption
                          ? {
                              label: selectedSubscriptionOption.plan_name,
                              value: selectedSubscriptionOption.plan_name,
                            }
                          : null
                      }
                      onChange={handleSubscriptionSelect}
                      isSearchable={true}
                      isCreatable={false}
                      isRequired
                      className="flex items-center text-xs z-10 w-full justify-center"
                    />
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    <input
                      type="text"
                      className="w-full text-center border border-black rounded m-2"
                      defaultValue={feeDataArray[editedRowIndex]?.fees_paid}
                      onChange={handleFeesPaidChange}
                    />
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    <input
                      type="text"
                      className="w-full text-center border border-black rounded m-2"
                      defaultValue={feeDataArray[editedRowIndex]?.discount}
                      onChange={handleDiscountChange}
                    />
                  </div>
                  <div className="col-span-2 flex items-center justify-center border-r border-black">
                    <input
                      type="text"
                      className="w-full text-center border border-black rounded m-2"
                      defaultValue={
                        feeDataArray[editedRowIndex]?.discount_reason
                      }
                      onChange={handleDiscountReasonChange}
                    />
                  </div>
                  <div className="col-span-1 flex p-2 items-center justify-center content-center border-r border-black z-20">
                    <DatePicker
                      selected={
                        !isDateOfPaymentUpdated
                          ? new Date(
                              feeDataArray[editedRowIndex]?.date_of_payment
                            )
                          : updatedDateOfPayment
                      }
                      onChange={handleDateOfPaymentChange}
                      dateFormat="d/MMM/yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={75}
                      className="flex justify-center text-center w-full"
                    />
                  </div>
                  <div className="col-span-1 flex p-2 items-center justify-center content-center border-r border-black z-20">
                    <DatePicker
                      selected={
                        !isValidFromUpdated
                          ? new Date(feeDataArray[editedRowIndex]?.valid_from)
                          : updatedValidFrom
                      }
                      onChange={handleValidFromChange}
                      dateFormat="d/MMM/yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={75}
                      className="flex justify-center text-center w-full rounded border border-black"
                    />
                  </div>
                  <div className="col-span-1 flex p-2 items-center justify-center content-center border-r border-black z-20">
                    <DatePicker
                      selected={
                        !isValidTillUpdated
                          ? new Date(feeDataArray[editedRowIndex]?.valid_till)
                          : updatedValidTill
                      }
                      onChange={handleValidTillChange}
                      dateFormat="d/MMM/yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={75}
                      className="flex justify-center text-center w-full rounded border border-black"
                    />
                  </div>
                  <div className="col-span-1 grid grid-cols-2">
                    <div
                      className="col-span-1 flex items-center justify-center border-r border-black bg-green-500 hover:bg-green-600"
                      onClick={() => {
                        const updatedIndex = feeDataArray[editedRowIndex]?.id;
                        // setDbRowDataIndex(updatedIndex); // this might take time to get updated and hence is not directly passed to handle save changes
                        handleSaveChanges(updatedIndex); // Pass the updated index directly
                        setEditedRowIndex(-1); // Reset the editedRowIndex to exit edit mode
                      }}
                    >
                      <button>Save</button>
                    </div>
                    <div
                      className="col-span-1 flex items-center justify-center border-r border-black bg-red-500 hover:bg-red-600"
                      onClick={() => {
                        // const updatedIndex = feeDataArray[editedRowIndex]?.id;
                        handleCancelChanges(); // Pass the updated index directly
                        setEditedRowIndex(-1); // Reset the editedRowIndex to exit edit mode
                      }}
                    >
                      <button>Cancel</button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className={`grid grid-cols-12 border-black border-l border-b cursor-pointer ${
                    editedRowIndex === index ? "hidden" : ""
                  }`}
                >
                  <div className="col-span-1 flex justify-center border-r border-black">
                    {index + 1}
                  </div>
                  <div className="col-span-3 flex items-center justify-center border-r border-black">
                    {/* {isActiveSubscriptionList.feeDataObject.active_plan_id} */}
                    {isActiveSubscriptionList.find(
                      (plan) => plan.id === feeDataObject.active_plan_id
                    )?.plan_name || "Plan Not Found"}
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {feeDataObject.fees_paid}
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {feeDataObject.discount}
                  </div>
                  <div className="col-span-2 flex pl-2 border-r border-black">
                    {feeDataObject.discount_reason}
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {new Date(feeDataObject.date_of_payment)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/\b0+/g, "")}
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {new Date(feeDataObject.valid_from)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/\b0+/g, "")}
                  </div>
                  <div className="col-span-1 flex items-center justify-center border-r border-black">
                    {new Date(feeDataObject.valid_till)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/\b0+/g, "")}
                  </div>
                  <div
                    className="col-span-1 flex items-center justify-center border-r border-black"
                    onClick={() => setEditedRowIndex(index)}
                  >
                    <button>
                      <EditIcon />
                    </button>
                  </div>
                </div>
              );
            })
          ) : selectedPlayerIndex == "" ? (
            <div className="border-x border-black text-center">
              No player selected.
            </div>
          ) : (
            <div className="border-x border-black text-center">
              No fee record found for the selected player.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeeHistoryList;
