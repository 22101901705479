import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PlanOptionsBar from "./Components/AddNewOrExistingPlan/PlanOptionsBar";
import { addNewFeePlan } from "../../Helper";
import FeeForm from "./Components/AddNewOrExistingPlan/FeeForm";

const AddNewOrEditExistingPlan = ({
  addNewPlanToggle,
  setAddNewPlanToggle,
  selectedPlanIndex,
  setSelectedPlanIndex,
  loggedInUserInstitutionID,
  postSaveRefresh,
  setPostSaveRefresh,
}) => {
  const [planName, setPlanName] = useState("");
  const [originalPlanName, setOriginalPlanName] = useState("");
  const [feePlanCategory, setFeePlanCategory] = useState("");
  const [originalFeePlanCategory, setOriginalFeePlanCategory] = useState("");
  // feeplanduration is called subscription duration from here on
  const [feePlanDuration, setFeePlanDuration] = useState("");
  const [originalFeePlanDuration, setOriginalFeePlanDuration] = useState("");
  const [paymentFrequency, setPaymentFrequency] = useState("");
  const [fee, setFee] = useState("");
  const [originalFee, setOriginalFee] = useState("");
  const [validFrom, setValidFrom] = useState(new Date());
  const [originalValidFrom, setOriginalValidFrom] = useState("");
  const [validTill, setValidTill] = useState(new Date());
  const [originalValidTill, setOriginalValidTill] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState(false);

  const handlePlanNameChange = (e) => {
    setPlanName(e.target.value);
  };

  const handleFeeCategoryChange = (selectedFeeCategory) => {
    setFeePlanCategory(selectedFeeCategory);
  };

  const handleFeeChange = (e) => {
    const inputValue = e.target.value;
    // Using a regular expression to allow only numbers (digits) in the input field
    const numericInput = inputValue.replace(/\D/g, "");
    setFee(numericInput);
  };

  const handleFeeDurationChange = (selectedFeeDuration) => {
    setFeePlanDuration(selectedFeeDuration);
  };

  const handlePaymentFrequencyChange = (selectedPaymentFrequency) => {
    setPaymentFrequency(selectedPaymentFrequency);
  };

  const handleValidTillChange = (valid_till) => {
    // Following 2 lines are used to fix discrepencies in date records
    const utcDate = new Date(valid_till);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidTill(utcDate);
  };

  const handleValidFromChange = (valid_from) => {
    // Following 2 lines are used to fix discrepencies in date records
    const utcDate = new Date(valid_from);
    utcDate.setUTCHours(0, 0, 0, 0);
    setValidFrom(utcDate);
  };

  const handleSavePlanToggleClick = async () => {
    const updatedFeePlanData = {};
    if (selectedPlanIndex == "") {
      updatedFeePlanData.institution_id = loggedInUserInstitutionID;
      updatedFeePlanData.plan_name = planName;
      updatedFeePlanData.plan_fee_category = feePlanCategory;
      updatedFeePlanData.fee = fee;
      updatedFeePlanData.payment_duration = feePlanDuration;
      updatedFeePlanData.payment_frequency = paymentFrequency;
      updatedFeePlanData.valid_from = validFrom;
      updatedFeePlanData.valid_till = validTill;
      console.log(updatedFeePlanData);
      // Check if any key in updatedFeePlanData has an empty string value
      let doesPlanHaveEmptyValue = false;
      for (const key in updatedFeePlanData) {
        if (updatedFeePlanData[key] === "") {
          doesPlanHaveEmptyValue = true;
          break;
        }
      }

      if (!doesPlanHaveEmptyValue) {
        try {
          const response = await addNewFeePlan(updatedFeePlanData);
          console.log(response);
          setSubmitStatus(response.status);
          setSubmitMessage(response.message);
        } catch (error) {
          setSubmitStatus(error.status);
          setSubmitMessage(error.message);
        }

        console.log("saving new plan");
        setAddNewPlanToggle((addNewPlanToggle) => !addNewPlanToggle);
        setPostSaveRefresh(postSaveRefresh + 1);
      } else {
        setSubmitStatus(false);
        setDisplaySubmitMessage(true);
        setSubmitMessage("Nothing to save! Enter plan details before saving.");
        setTimeout(hideMessages, 2000);
      }
    } else {
      console.log("editing selected plan");
    }
  };

  const handleCancelPlanToggleClick = () => {
    setAddNewPlanToggle((addNewPlanToggle) => !addNewPlanToggle);
    handleResetPlan();
  };

  const handleResetPlan = () => {
    setPlanName("");
    setFeePlanCategory("");
    setFeePlanDuration("");
    setPaymentFrequency("");
    setFee("");
    setValidFrom(new Date());
    setValidTill(new Date());
  };

  const hideMessages = () => {
    setDisplaySubmitMessage(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <PlanOptionsBar
        addNewPlanToggle={addNewPlanToggle}
        setAddNewPlanToggle={setAddNewPlanToggle}
        handleSavePlanToggleClick={handleSavePlanToggleClick}
        handleCancelPlanToggleClick={handleCancelPlanToggleClick}
        selectedPlanIndex={selectedPlanIndex}
        setSelectedPlanIndex={setSelectedPlanIndex}
        handleResetPlan={handleResetPlan}
      />
      {!addNewPlanToggle ? (
        <FeeForm
          planName={planName}
          handlePlanNameChange={handlePlanNameChange}
          feePlanCategory={feePlanCategory}
          handleFeeCategoryChange={handleFeeCategoryChange}
          fee={fee}
          handleFeeChange={handleFeeChange}
          feePlanDuration={feePlanDuration}
          handleFeeDurationChange={handleFeeDurationChange}
          paymentFrequency={paymentFrequency}
          handlePaymentFrequencyChange={handlePaymentFrequencyChange}
          validFrom={validFrom}
          handleValidFromChange={handleValidFromChange}
          validTill={validTill}
          handleValidTillChange={handleValidTillChange}
        />
      ) : null}
      {displaySubmitMessage && (
        <p
          id="submitMessage"
          className={`text-white p-2 rounded text-center ${
            submitStatus ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {submitMessage}
        </p>
      )}
    </div>
  );
};

export default AddNewOrEditExistingPlan;
