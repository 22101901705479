import React, { useEffect, useState } from "react";

const EditSection = ({
  isEditingForm,
  setIsEditingForm,
  resetUpdatedFields,
  submitStatus,
  submitMessage,
  handleSaveChanges,
  setReloadPlayerList,
  reloadPlayerList,
}) => {
  const [displaySubmitMessage, setDisplaySubmitMessage] =
    useState(submitMessage);

  const handleEditClick = () => {
    setIsEditingForm((isEditingForm) => !isEditingForm);
  };
  const handleSaveClick = () => {
    // console.log("saving...");
    handleSaveChanges();
    setIsEditingForm((isEditingForm) => !isEditingForm);
    setReloadPlayerList((reloadPlayerList) => reloadPlayerList + 1);
  };
  const handleResetClick = () => {
    resetUpdatedFields();
  };
  const handleCancelClick = () => {
    // console.log("cancel...");
    resetUpdatedFields();
    setIsEditingForm((isEditingForm) => !isEditingForm);
  };

  useEffect(() => {
    setDisplaySubmitMessage(submitMessage);

    // Remove submitMessage after 5 seconds
    const timer = setTimeout(() => {
      setDisplaySubmitMessage("");
    }, 3000);

    return () => {
      clearTimeout(timer);
      // ChatGPT-The clearTimeout function is used in the cleanup function of the useEffect to prevent any memory leaks.
    };
  }, [submitMessage]);

  return (
    <div className="flex flex-col w-100 items-center">
      <div className="flex flex-row gap-4">
        {!isEditingForm && (
          <button
            onClick={() => {
              handleEditClick();
            }}
            className="w-50 text-center p-2 mb-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform"
          >
            Edit Player Information
          </button>
        )}
        {isEditingForm && (
          <div className="flex flex-row gap-36">
            <button
              onClick={() => {
                handleSaveClick();
              }}
              className="w-36 p-2 mb-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform"
            >
              Save
            </button>
            <button
              onClick={() => {
                handleResetClick();
              }}
              className="w-36 p-2 mb-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform"
            >
              Reset Changes
            </button>
            <button
              onClick={() => {
                handleCancelClick();
              }}
              className="w-36 p-2 mb-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {displaySubmitMessage && (
        <p
          id="submitMessage"
          className={`text-white p-2 rounded text-center ${
            submitStatus ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {displaySubmitMessage}
        </p>
      )}
    </div>
  );
};

export default EditSection;
