import React, { useState } from "react";
import { useEffect } from "react";
import ImageMapper from "react-image-mapper";
import RighthandBatsmanPitch from "../assets/wagon_wheel_right_hand_bastman.png";
import LeftthandBatsmanPitch from "../assets/Left_hand_waghoon_wheel.png";

import {
  ShortFineLegFirst, ShortFineLegSecond,
  DeepFineLegFirst, DeepFineLegSecond,
  SquareLegFirst, SquareLegSecond,
  DeepSquareLegFirst, DeepSquareLegSecond,
  MidWicketFirst, MidWicketSecond,
  DeepMidWicketFirst, DeepMidWicketSecond,
  MidOnFirst, MidOnSecond,
  LongOnFirst, LongOnSecond,
  MidOffFirst, MidOffSecond,
  LongOffFirst, LongOffSecond,
  CoverFirst, CoverSecond,
  DeepCoverFirst, DeepCoverSecond,
  ThirdManFirst, ThirdManSecond,
  ShortThirdManFirst, ShortThirdManSecond,
  DeepPointFirst, DeepPointSecond,
  PointFirst, PointSecond,
  isInsideRectangle
} from "../utils";

import {
  Right_Hand_New_ShortFineLegFirst, Right_Hand_New_ShortFineLegSecond,
  Right_Hand_New_DeepFineLegFirst,
  Right_Hand_New_DeepFineLegSecond,
  Right_Hand_New_SquareLegFirst,
  Right_Hand_New_SquareLegSecond,
  Right_Hand_New_DeepSquareLegFirst,
  Right_Hand_New_DeepSquareLegSecond,
  Right_Hand_New_MidWicketFirst,
  Right_Hand_New_MidWicketSecond,
  Right_Hand_New_DeepMidWicketFirst,
  Right_Hand_New_DeepMidWicketSecond,
  Right_Hand_New_MidOnFirst,
  Right_Hand_New_MidOnSecond,
  Right_Hand_New_LongOnFirst,
  Right_Hand_New_LongOnSecond,
  Right_Hand_New_MidOffFirst,
  Right_Hand_New_MidOffSecond,
  Right_Hand_New_LongOffFirst,
  Right_Hand_New_LongOffSecond,
  Right_Hand_New_DeepCoverFirst,
  Right_Hand_New_DeepCoverSecond,
  Right_Hand_New_CoverFirst,
  Right_Hand_New_CoverSecond,
  Right_Hand_New_DeepPointFirst,
  Right_Hand_New_DeepPointSecond,
  Right_Hand_New_PointFirst,
  Right_Hand_New_PointSecond,
  Right_Hand_New_ThirdManFirst,
  Right_Hand_New_ThirdManSecond,
  Right_Hand_New_ShortThirdManFirst,
  Right_Hand_New_ShortThirdManSecond,
  Left_Hand_New_ShortFineLegFirst, Left_Hand_New_ShortFineLegSecond,
  Left_Hand_New_DeepFineLegFirst,
  Left_Hand_New_DeepFineLegSecond,
  Left_Hand_New_DeepSquareLegFirst,
  Left_Hand_New_DeepSquareLegSecond,
  Left_Hand_New_MidWicketFirst,
  Left_Hand_New_MidWicketSecond,
  Left_Hand_New_DeepMidWicketFirst,
  Left_Hand_New_DeepMidWicketSecond,
  Left_Hand_New_MidOnFirst,
  Left_Hand_New_MidOnSecond,
  Left_Hand_New_LongOnFirst,
  Left_Hand_New_LongOnSecond,
  Left_Hand_New_MidOffFirst,
  Left_Hand_New_MidOffSecond,
  Left_Hand_New_LongOffFirst,
  Left_Hand_New_LongOffSecond,
  Left_Hand_New_DeepCoverFirst,
  Left_Hand_New_DeepCoverSecond,
  Left_Hand_New_CoverFirst,
  Left_Hand_New_CoverSecond,
  Left_Hand_New_DeepPointFirst,
  Left_Hand_New_DeepPointSecond,
  Left_Hand_New_PointFirst,
  Left_Hand_New_PointSecond,
  Left_Hand_New_ThirdManFirst,
  Left_Hand_New_ThirdManSecond,
  Left_Hand_New_ShortThirdManFirst,
  Left_Hand_New_ShortThirdManSecond,
  Left_Hand_New_SqaureLegFirst,
  Left_Hand_New_SqaureLegSecond
} from "../utils";

import RighthandBatsmanCover from "../assets/RightHandBatsman/Right_hand_batsman_cover.png";
import RighthandBatsmanDeepCover from "../assets/RightHandBatsman/Right_hand_batsman_deep_cover.png";
import RighthandBatsmanDeepFine from "../assets/RightHandBatsman/Right_hand_batsman_deep_fine_leg.png";
import RighthandBatsmanDeepMidwicket from "../assets/RightHandBatsman/Right_hand_batsman_deep_midwicket.png";
import RightHandBatsmanDeepPoint from "../assets/RightHandBatsman/Right_hand_batsman_deep_point.png";
import RightHandBatsmanDeepSqaureLeg from "../assets/RightHandBatsman/Right_hand_batsman_deep_sqaure_leg.png";
import RightHandBatsmanLongOff from "../assets/RightHandBatsman/Right_hand_batsman_long_of.png";
import RightHandBatsmanLongOn from "../assets/RightHandBatsman/Right_hand_batsman_long_on.png";
import RightHandBatsmanMidOff from "../assets/RightHandBatsman/Right_hand_batsman_Mid_off.png";
import RightHandBatsmanMidOn from "../assets/RightHandBatsman/Right_hand_batsman_Mid_on.png";
import RightHandBatsmanMidWicket from "../assets/RightHandBatsman/Right_hand_batsman_Mid_wicket.png";
import RightHandBatsmanPoint from "../assets/RightHandBatsman/Right_hand_batsman_point.png";
import RightHandBatsmanShortFineLeg from "../assets/RightHandBatsman/Right_hand_batsman_short_fine_leg.png";
import RightHandBatsmanShortThirdMan from "../assets/RightHandBatsman/Right_hand_batsman_short_third_man.png";
import RightHandBatsmanSqaureLeg from "../assets/RightHandBatsman/Right_hand_batsman_sqaure_leg.png";
import RightHandBatsmanThirdMan from "../assets/RightHandBatsman/Right_hand_batsman_third_man.png";
import LefthandBatsmanPitch from "../assets/Left_hand_waghoon_wheel.png";
import LefthandBatsmanCover from "../assets/LeftHandBatsman/Left_hand_batsman_covers.png";
import LefthandBatsmanDeepCover from "../assets/LeftHandBatsman/Left_hand_batsman_deep_cover.png";
import LefthandBatsmanDeepFineLeg from "../assets/LeftHandBatsman/Left_hand_batsman_deep_fine_leg.png";
import LefthandBatsmanDeepMidwicket from "../assets/LeftHandBatsman/Left_hand_batsman_deep_midwicket.png";
import LeftHandBatsmanDeepPoint from "../assets/LeftHandBatsman/Left_hand_batsman_deep_point.png";
import LeftHandBatsmanDeepSqaureLeg from "../assets/LeftHandBatsman/Left_hand_batsman_deep_sqaure_leg.png";
import LeftHandBatsmanLongOff from "../assets/LeftHandBatsman/Left_hand_batsman_long_off.png";
import LeftHandBatsmanLongOn from "../assets/LeftHandBatsman/Left_hand_batsman_long_on.png";
import LeftHandBatsmanMidOff from "../assets/LeftHandBatsman/Left_hand_batsman_mid_off.png";
import LeftHandBatsmanMidOn from "../assets/LeftHandBatsman/Left_hand_batsman_mid_on.png";
import LeftHandBatsmanMidWicket from "../assets/LeftHandBatsman/Left_hand_batsman_midwicket.png";
import LefttHandBatsmanPoint from "../assets/LeftHandBatsman/Left_hand_batsman_point.png";
import LefttHandBatsmanShortFineLeg from "../assets/LeftHandBatsman/Left_hand_batsman_short_fine_leg.png";
import LeftHandBatsmanShortThirdMan from "../assets/LeftHandBatsman/Left_hand_batsman_short_third_man.png";
import LeftHandBatsmanSqaureLeg from "../assets/LeftHandBatsman/Left_hand_batsman_sqaure_leg.png";
import LefttHandBatsmanThirdMan from "../assets/LeftHandBatsman/Left_hand_batsman_third_man.png";

const BattingInput = (props) => {
  const [hoveredArea, setHoveredArea] = useState();
  const [msg, setMsg] = useState("");
  const [parentBatsamanData, setParentBatsamanData] = useState(props.positionBatTypeBatInputBatInput);
  const [msgClick, setMsgClick] = useState("");
  const [cords, setCords] = useState();
  const [righttHandBatsman, setRightHandBatsman] = useState(true);
  const [leftHandBatsman, setLeftHandBatsman] = useState(false);
  const [selectbattingType, setselectbattingType] = useState('Right');
  const [RighthandBatsmanPitchDefault, setRighthandBatsmanPitchDefault] = useState(RighthandBatsmanPitch);
  const [LefthandBatsmanPitchDefault, setLefthandBatsmanPitchDefault] = useState(LeftthandBatsmanPitch);
  useEffect(() => {
    if (props.setBatsmanType == 'Left Hand') {
      props.positionBatTypeBatInput.waghoon_wheel_position_bat_type = 'Left';
    }
    else {
      props.positionBatTypeBatInput.waghoon_wheel_position_bat_type = 'Right';
    }

    if (props.parentWagonMsgData.parent == true) {
      if ((props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        if (props.parentWagonMsgData.parent_batting_msg == 'Short Fine Leg') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanShortFineLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Fine Leg') {
          setRighthandBatsmanPitchDefault(RighthandBatsmanDeepFine);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Square Leg') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanSqaureLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Square Leg') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanDeepSqaureLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid-Wicket') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanMidWicket);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Mid-Wicket') {
          setRighthandBatsmanPitchDefault(RighthandBatsmanDeepMidwicket);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid On') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanMidOn);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Long On') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanLongOn);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid Off') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanMidOff);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Long Off') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanLongOff);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Covers') {
          setRighthandBatsmanPitchDefault(RighthandBatsmanCover);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Cover') {
          setRighthandBatsmanPitchDefault(RighthandBatsmanDeepCover);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Point') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanPoint);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Point') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanDeepPoint);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Third Man') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanThirdMan);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Short Third Man') {
          setRighthandBatsmanPitchDefault(RightHandBatsmanShortThirdMan);
        }
        else {
          setRighthandBatsmanPitchDefault(RighthandBatsmanPitch);
        }
      }
      else {
        if (props.parentWagonMsgData.parent_batting_msg == 'Short Fine Leg') {
          setLefthandBatsmanPitchDefault(LefttHandBatsmanShortFineLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Fine Leg') {
          setLefthandBatsmanPitchDefault(LefthandBatsmanDeepFineLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Square Leg') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanSqaureLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid-Wicket') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanMidWicket);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Sqaure Leg') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanDeepSqaureLeg);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Mid-Wicket') {
          setLefthandBatsmanPitchDefault(LefthandBatsmanDeepMidwicket);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid On') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanMidOn);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Long On') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanLongOn);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Mid Off') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanMidOff);
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Long Off') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanLongOff)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Covers') {
          setLefthandBatsmanPitchDefault(LefthandBatsmanCover)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Cover') {
          setLefthandBatsmanPitchDefault(LefthandBatsmanDeepCover)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Point') {
          setLefthandBatsmanPitchDefault(LefttHandBatsmanPoint)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Deep Point') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanDeepPoint)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Third Man') {
          setLefthandBatsmanPitchDefault(LefttHandBatsmanThirdMan)
        }
        else if (props.parentWagonMsgData.parent_batting_msg == 'Short Third Man') {
          setLefthandBatsmanPitchDefault(LeftHandBatsmanShortThirdMan)
        }
        else {
          setLefthandBatsmanPitchDefault(LefthandBatsmanPitch);
        }
      }

      if (props.parentWagonMsgData.parent_batting_msg != '') {
        setMsgClick(props.parentWagonMsgData.parent_batting_msg);
        props.parentWagonMsgData.parent = false;
      }
      else {
        setMsgClick("");
      }
    }
  });

  useEffect(() => {
    if (msgClick != "Please click again") props.setBattingResult(msgClick);
  }, [msgClick]);

  const load = () => {

  };
  const clickedOutside = (area) => {

  };
  const enterArea = (area) => {
    setHoveredArea(area);
  };
  const leaveArea = (area) => {
    setHoveredArea(null);
  };
  const moveOnArea = (area, evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setCords(coords);
  };
  const clicked = (area) => {
    props.parentWagonMsgData.parent = false;
    setMsgClick(
      `You clicked on the image at coords ${JSON.stringify(cords)} !`
    );

    if ((props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
      if ((isInsideRectangle(Right_Hand_New_ShortFineLegFirst, cords) || isInsideRectangle(Right_Hand_New_ShortFineLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Short Fine Leg`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanShortFineLeg);
      } else if ((isInsideRectangle(Right_Hand_New_DeepFineLegFirst, cords) || isInsideRectangle(Right_Hand_New_DeepFineLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Deep Fine Leg`);
        setRighthandBatsmanPitchDefault(RighthandBatsmanDeepFine);
      } else if ((isInsideRectangle(Right_Hand_New_SquareLegFirst, cords) || isInsideRectangle(Right_Hand_New_SquareLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Square Leg`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanSqaureLeg);
      } else if ((isInsideRectangle(Right_Hand_New_DeepSquareLegFirst, cords) || isInsideRectangle(Right_Hand_New_DeepSquareLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Deep Square Leg`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanDeepSqaureLeg);
      } else if ((isInsideRectangle(Right_Hand_New_MidWicketFirst, cords) || isInsideRectangle(Right_Hand_New_MidWicketSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Mid-Wicket`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanMidWicket);
      } else if ((isInsideRectangle(Right_Hand_New_DeepMidWicketFirst, cords) || isInsideRectangle(Right_Hand_New_DeepMidWicketSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Deep Mid-Wicket`);
        setRighthandBatsmanPitchDefault(RighthandBatsmanDeepMidwicket);
      } else if ((isInsideRectangle(Right_Hand_New_MidOnFirst, cords) || isInsideRectangle(Right_Hand_New_MidOnSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Mid On`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanMidOn);
      } else if ((isInsideRectangle(Right_Hand_New_LongOnFirst, cords) || isInsideRectangle(Right_Hand_New_LongOnSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Long On`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanLongOn);
      } else if ((isInsideRectangle(Right_Hand_New_MidOffFirst, cords) || isInsideRectangle(Right_Hand_New_MidOffSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Mid Off`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanMidOff);
      } else if ((isInsideRectangle(Right_Hand_New_LongOffFirst, cords) || isInsideRectangle(Right_Hand_New_LongOffSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Long Off`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanLongOff);
      }
      else if ((isInsideRectangle(Right_Hand_New_CoverFirst, cords) || isInsideRectangle(Right_Hand_New_CoverSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Covers`);
        setRighthandBatsmanPitchDefault(RighthandBatsmanCover);
      } else if ((isInsideRectangle(Right_Hand_New_DeepCoverFirst, cords) || isInsideRectangle(Right_Hand_New_DeepCoverSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Deep Cover`);
        setRighthandBatsmanPitchDefault(RighthandBatsmanDeepCover);
      } else if ((isInsideRectangle(Right_Hand_New_PointFirst, cords) || isInsideRectangle(Right_Hand_New_PointSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Point`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanPoint);
      } else if ((isInsideRectangle(Right_Hand_New_DeepPointFirst, cords) || isInsideRectangle(Right_Hand_New_DeepPointSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Deep Point`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanDeepPoint);
      } else if ((isInsideRectangle(Right_Hand_New_ThirdManFirst, cords) || isInsideRectangle(Right_Hand_New_ThirdManSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Third Man`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanThirdMan);
      } else if ((isInsideRectangle(Right_Hand_New_ShortThirdManFirst, cords) || isInsideRectangle(Right_Hand_New_ShortThirdManSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Right")) {
        setMsgClick(`Short Third Man`);
        setRighthandBatsmanPitchDefault(RightHandBatsmanShortThirdMan);
      } else {
        setMsgClick(`Please click again`);
        setRighthandBatsmanPitchDefault(RighthandBatsmanPitch);
      }
    }

    if ((props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
      if ((isInsideRectangle(Left_Hand_New_ShortFineLegFirst, cords) || isInsideRectangle(Left_Hand_New_ShortFineLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Short Fine Leg`);
        setLefthandBatsmanPitchDefault(LefttHandBatsmanShortFineLeg);
      } else if ((isInsideRectangle(Left_Hand_New_DeepFineLegFirst, cords) || isInsideRectangle(Left_Hand_New_DeepFineLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Fine Leg`);
        setLefthandBatsmanPitchDefault(LefthandBatsmanDeepFineLeg);
      }
      else if ((isInsideRectangle(Left_Hand_New_SqaureLegFirst, cords) || isInsideRectangle(Left_Hand_New_SqaureLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Square Leg`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanSqaureLeg);
      }
      else if ((isInsideRectangle(Left_Hand_New_MidWicketFirst, cords) || isInsideRectangle(Left_Hand_New_MidWicketSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Mid-Wicket`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanMidWicket);
      }
      else if ((isInsideRectangle(Left_Hand_New_DeepSquareLegFirst, cords) || isInsideRectangle(Left_Hand_New_DeepSquareLegFirst, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Sqaure Leg`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanDeepSqaureLeg);
      }
      else if ((isInsideRectangle(Left_Hand_New_DeepSquareLegSecond, cords) || isInsideRectangle(Left_Hand_New_DeepSquareLegSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Sqaure Leg`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanDeepSqaureLeg);
      }
      else if ((isInsideRectangle(Left_Hand_New_DeepMidWicketFirst, cords) || isInsideRectangle(Left_Hand_New_DeepMidWicketSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Mid-Wicket`);
        setLefthandBatsmanPitchDefault(LefthandBatsmanDeepMidwicket);
      } else if ((isInsideRectangle(Left_Hand_New_MidOnFirst, cords) || isInsideRectangle(Left_Hand_New_MidOnSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Mid On`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanMidOn);
      } else if ((isInsideRectangle(Left_Hand_New_LongOnFirst, cords) || isInsideRectangle(Left_Hand_New_LongOnSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Long On`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanLongOn);
      } else if ((isInsideRectangle(Left_Hand_New_MidOffFirst, cords) || isInsideRectangle(Left_Hand_New_MidOffSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Mid Off`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanMidOff);
      } else if ((isInsideRectangle(Left_Hand_New_LongOffFirst, cords) || isInsideRectangle(Left_Hand_New_LongOffSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Long Off`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanLongOff)
      }
      else if ((isInsideRectangle(Left_Hand_New_CoverFirst, cords) || isInsideRectangle(Left_Hand_New_CoverSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Covers`);
        setLefthandBatsmanPitchDefault(LefthandBatsmanCover)
      } else if ((isInsideRectangle(Left_Hand_New_DeepCoverFirst, cords) || isInsideRectangle(Left_Hand_New_DeepCoverSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Cover`);
        setLefthandBatsmanPitchDefault(LefthandBatsmanDeepCover)
      } else if ((isInsideRectangle(Left_Hand_New_PointFirst, cords) || isInsideRectangle(Left_Hand_New_PointSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Point`);
        setLefthandBatsmanPitchDefault(LefttHandBatsmanPoint)
      } else if ((isInsideRectangle(Left_Hand_New_DeepPointFirst, cords) || isInsideRectangle(Left_Hand_New_DeepPointSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Deep Point`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanDeepPoint)
      } else if ((isInsideRectangle(Left_Hand_New_ThirdManFirst, cords) || isInsideRectangle(Left_Hand_New_ThirdManSecond, cords)) && (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Third Man`);
        setLefthandBatsmanPitchDefault(LefttHandBatsmanThirdMan)
      } else if ((isInsideRectangle(Left_Hand_New_ShortThirdManFirst, cords) || isInsideRectangle(Left_Hand_New_ShortThirdManSecond, cords)) || (props.positionBatTypeBatInput.waghoon_wheel_position_bat_type == "Left")) {
        setMsgClick(`Short Third Man`);
        setLefthandBatsmanPitchDefault(LeftHandBatsmanShortThirdMan)
      } else {
        setMsgClick(`Please click again`);
      }
    }
  };
  const moveOnImage = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    if (coords.x)
      setMsg(`You moved on the image at coords ${JSON.stringify(coords)} !`);
    setCords(coords);
  };

  const newClicked = (area) => {
    props.parentWagonMsgData.parent = false;
    setMsgClick(
      `You clicked on the image at coords ${JSON.stringify(cords)} !`
    );

    if (isInsideRectangle(ShortFineLegFirst, cords) || isInsideRectangle(ShortFineLegSecond, cords)) {
      setMsgClick(`Short Fine Leg`);
    } else if (isInsideRectangle(DeepFineLegFirst, cords) || isInsideRectangle(DeepFineLegSecond, cords)) {
      setMsgClick(`Deep Fine Leg`);
    } else if (isInsideRectangle(SquareLegFirst, cords) || isInsideRectangle(SquareLegSecond, cords)) {
      setMsgClick(`Square Leg`);
    } else if (isInsideRectangle(DeepSquareLegFirst, cords) || isInsideRectangle(DeepSquareLegSecond, cords)) {
      setMsgClick(`Deep Square Leg`);
    } else if (isInsideRectangle(MidWicketFirst, cords) || isInsideRectangle(MidWicketSecond, cords)) {
      setMsgClick(`Mid-Wicket`);
    } else if (isInsideRectangle(DeepMidWicketFirst, cords) || isInsideRectangle(DeepMidWicketSecond, cords)) {
      setMsgClick(`Deep Mid-Wicket`);
    } else if (isInsideRectangle(MidOnFirst, cords) || isInsideRectangle(MidOnSecond, cords)) {
      setMsgClick(`Mid On`);
    } else if (isInsideRectangle(LongOnFirst, cords) || isInsideRectangle(LongOnSecond, cords)) {
      setMsgClick(`Long On`);
    } else if (isInsideRectangle(MidOffFirst, cords) || isInsideRectangle(MidOffSecond, cords)) {
      setMsgClick(`Mid Off`);
    } else if (isInsideRectangle(LongOffFirst, cords) || isInsideRectangle(LongOffSecond, cords)) {
      setMsgClick(`Long Off`);
    }
    else if (isInsideRectangle(CoverFirst, cords) || isInsideRectangle(CoverSecond, cords)) {
      setMsgClick(`Covers`);
    } else if (isInsideRectangle(DeepCoverFirst, cords) || isInsideRectangle(DeepCoverSecond, cords)) {
      setMsgClick(`Deep Cover`);
    } else if (isInsideRectangle(PointFirst, cords) || isInsideRectangle(PointSecond, cords)) {
      setMsgClick(`Point`);
    } else if (isInsideRectangle(DeepPointFirst, cords) || isInsideRectangle(DeepPointSecond, cords)) {
      setMsgClick(`Deep Point`);
    } else if (isInsideRectangle(ThirdManFirst, cords) || isInsideRectangle(ThirdManSecond, cords)) {
      setMsgClick(`Third Man`);
    } else if (isInsideRectangle(ShortThirdManFirst, cords) || isInsideRectangle(ShortThirdManSecond, cords)) {
      setMsgClick(`Short Third Man`);
    } else {
      setMsgClick(`Please click again`);
    }
  };

  const handleBatsmanType = (val) => (e) => {
    e.preventDefault();
    setselectbattingType(val);
    if (val === "Right") {
      props.positionBatTypeBatInput.waghoon_wheel_position_bat_type = "Right"
    } else {
      props.positionBatTypeBatInput.waghoon_wheel_position_bat_type = "Left"
    }
  };

  return (
    <div className="containerIMG_batting" style={{
      textAlign: 'center', width: 'min-content', position: 'relative',
      left: '6%', height: '350px'
    }}>
      {(props.setBatsmanType === "Right Hand") && (
        <ImageMapper
          src={RighthandBatsmanPitchDefault}
          width={300}
          onLoad={() => load()}
          onMouseDown={(area) => clicked(area)}
          onMouseEnter={(area) => enterArea(area)}
          onMouseLeave={(area) => leaveArea(area)}
          onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
          onImageClick={(evt) => clicked(evt)}
          onImageMouseMove={(evt) => moveOnImage(evt)}
        />
      )}

      {(props.positionBatTypeBatInput.waghoon_wheel_position_bat_type === "Left") && (<ImageMapper
        src={LefthandBatsmanPitchDefault}
        width={300}
        onLoad={() => load()}
        onMouseDown={(area) => clicked(area)}
        onMouseEnter={(area) => enterArea(area)}
        onMouseLeave={(area) => leaveArea(area)}
        onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
        onImageClick={(evt) => clicked(evt)}
        onImageMouseMove={(evt) => moveOnImage(evt)}
      />)}
    </div>
  );
};
export default BattingInput;