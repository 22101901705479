import React from "react";

const SearchTab = ({ searchInput, setSearchInput }) => {
  return (
    <div className="flex flex-row gap-4 items-center">
      <label>Search player:</label>
      <input
        className="p-1 rounded w-80"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      ></input>
    </div>
  );
};

export default SearchTab;
