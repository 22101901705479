import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { AddTournament } from "./Helper";
import { useForm } from "react-hook-form";
import { GetAllMatcheTypes } from "../Match/Helper";

const Tournament = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [tournamentTypes, setTournamentTypes] = useState([]);
  useEffect(() => {
    setHeaderTitle("Add Tournament");
    GetAllMatcheTypes()
      .then((data) => {
        setTournamentTypes(data.match_types);
      })
      .catch((err) => {
      });
  }, []);
  const handleSubmitTournament = (details) => {
    AddTournament(details).then((data) => {
      if (data?.status) {
        reset();
        Swal.fire({
          icon: 'success',
          title: '🚀',
          text: 'Tournament added successfully'
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: '👎',
          text: 'Enable to add Tournament'
        })
      }
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: '⛔',
        text: err
      })
    })
  }
  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }}>
          New Tournament
        </h1>
      </div>
      <div className="p-5 h-screen">
        <div className="grid grid-cols-4 gap-5"><div></div>
          <div className=" col-span-2 ">
            <div className="shadow-2xl bg-white p-10 form-control">
              <h1 className="text-center" style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "18px" }}>
                Add Tournament</h1><br />
              <form onSubmit={handleSubmit(handleSubmitTournament)}>
                <div className="form-control">
                  <label
                    htmlFor="name"
                    className="form-label inline-block mb-2 text-gray-700" >
                    Tournament Name<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <input type="text" id="name"
                    {...register("name", {
                      required: true,
                      pattern: /^[a-zA-Z0-9 -]*$/
                    })}
                    placeholder="Name"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" />
                  {errors.name && errors.name.type === "required" && (
                    <p className="errorMsg">Tournament name is required.</p>
                  )}
                  {errors.name && errors.name.type === "pattern" && (
                    <p className="errorMsg">Tournament name can't allow any special character except(-)</p>)}
                </div>
                <div className="pt-4 grid grid-cols-2 gap-3">
                  <div className="form-control">
                    <label htmlFor="sdate" className="form-label inline-block mb-2 text-gray-700">
                      Start Date<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <input
                      type="date"
                      id="sdate"
                      {...register("start_date", {
                        required: true,
                      })}
                      placeholder="Start Date"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" />
                    {errors.start_date && errors.start_date.type === "required" && (
                      <p className="errorMsg">Start date is required.</p>
                    )}
                  </div>
                  <div className="form-control">
                    <label
                      htmlFor="edate"
                      className="form-label inline-block mb-2 text-gray-700">
                      End Date<span style={{ color: '#FF0000' }}>*</span>
                    </label>
                    <input type="date" id="edate"
                      {...register("end_date", {
                        required: true,
                        validate: () => (getValues("start_date")) <= (getValues("end_date"))
                      })}
                      placeholder="End Date"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    />
                    {errors.end_date && errors.end_date.type === "required" && (
                      <p className="errorMsg">End date is required.</p>
                    )}
                    {errors.end_date && errors.end_date.type === "validate" && (
                      <p className="errorMsg">End date should be same or after start date.</p>
                    )}
                  </div>
                </div>
                <div className="pt-4">
                  <label
                    htmlFor="ttype"
                    className="form-label inline-block mb-2 text-gray-700" >
                    Tournament Type<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <select
                    {...register("type", {
                      validate: (val) => val !== "Select Tournament Type" || "Tournament type is required.",
                    })}
                    id="type"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                    <option value="Select Tournament Type">
                      Select Tournament Type
                    </option>
                    {tournamentTypes.map(({ type }, id) => (
                      <option value={type} key={id}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors.type && errors.type.message && (
                    <p className="errorMsg">{errors.type.message}</p>
                  )}
                </div>
                <div className="pt-4 form-control">
                  <Button type="submit" style={{ backgroundColor: '#0DF6E3', color: '#2B303B' }} variant="contained" fullWidth>Create Tournament</Button>
                </div>
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tournament;
