import axios from 'axios';

export const ChangeAppUserPassword = (body) => {
	return axios
		.post(`${process.env.REACT_APP_BASE_URL}/change-appuser-password-web`, body, {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log(err);
		});
};
