import axios from "axios";
export const GetActivityCategory = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-activity-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetFocusArea = async (activity_category_data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-focus-area-list`,
      activity_category_data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetFocusAreaSubCategory = async (activity_category_data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-focus-area-sub-category-list`,
      activity_category_data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetEvaluationBy = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-evaluation-by-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetTaskPayment = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-task-payment-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddNewTask = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-new-task`,
      task_detail,
      {
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddFocusArea = async (focus_area_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-focus-area`,
      { focus_area_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddFocusAreaSubCategory = async (
  focus_area_sub_category_detail
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-focus-area-sub-category`,
      { focus_area_sub_category_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddEvaluationBy = async (evaluation_by_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-evaluation-by`,
      { evaluation_by_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetCoachList = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-coach-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddPayment = async (add_payment_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-task-payment`,
      { add_payment_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetTaskList = async (search_task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-task-list`,
      { search_task_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetTaskIdDetails = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-task-id-details`,
      { task_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateTask = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-task`,
      task_detail,
      {
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetProgramList = async (search_program_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-program-list`,
      { search_program_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetProgramIdDetails = async (program_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-program-id-details`,
      { program_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AddNewTaskPlanning = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/add-new-task-planning`,
      { task_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateTaskPlanning = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/update-task-planning`,
      { task_detail },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const Teams = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-all-teams`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const Players = async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-app-user-list`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const DeleteTasks = async (task_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/delete-program-data`,
      task_id,
      {
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetProgramNameList = async (task_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-player-name-list`,
      task_detail,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const DeleteProgram = async (task_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/delete-preview-task-planning`,
      task_id,
      {
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const previewDeleteTaskPlanning = async (program_detail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/preview-delete-task-planning`,
      program_detail,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const MakeDuplicateProgram = async (program_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/dublicate-task-planning`,
      program_id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("WellplayedToken")).token
          }`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
