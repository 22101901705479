import axios from 'axios';

export const GetAllInstitutes = async () => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_BASE_URL}/institutions`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAllMatcheTypes = async () => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_BASE_URL}/get-match-types`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAllTeamsByInstituteID = async (id) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_BASE_URL}/get-teams-by-institute/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};
export const GetAllTeamMembersByTeamID = async (id) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_BASE_URL}/get-teammembers-by-team/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};
export const AddMatch = async (match) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/add-match`, match,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const AddPlayer = async (player) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/add-player`, player,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const GetAppUserList = async () => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-app-user-list`,{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const AddPlayerInTeam = async (player) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/add-player-in-team`, player,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('WellplayedToken')).token}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
}; 
