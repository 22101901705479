import React from "react";

const FeePlanListHeader = () => {
  return (
    <div className="flex border-b border-black bg-slate-300">
      <div className="w-20 text-center border-r border-black">Sr. No.</div>
      <div className="grid grid-cols-8 w-full ">
        <div className="text-center border-r border-black col-span-2">
          Subscription
        </div>
        <div className="text-center border-r border-black">Category</div>
        <div className="text-center border-r border-black">Fee(₹)</div>
        <div className="text-center border-r border-black">
          Subscription Duration
        </div>
        <div className="text-center border-r border-black">
          Payment Frequency
        </div>
        <div className="text-center border-r border-black">Valid From</div>
        <div className="text-center">Valid Till</div>
      </div>
    </div>
  );
};

export default FeePlanListHeader;
