import React, { useState } from "react";
import { Link } from "react-router-dom";

const FilterTab = ({ setSelectedFilter, selectedFilter }) => {
  // Function to handle the filter button click
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="flex flex-row gap-2 items-center justify-between">
      <div className="flex flex-row gap-4 items-center flex-wrap">
        <label htmlFor="">Filters</label>
        <button
          className={`w-24 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            selectedFilter === "All" ? "bg-neutral-400" : ""
          }`}
          onClick={() => handleFilterClick("All")}
        >
          All
        </button>
        <button
          className={`w-24 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            selectedFilter === "Fees Due" ? "bg-neutral-400" : ""
          }`}
          onClick={() => handleFilterClick("Fees Due")}
        >
          Fees Due
        </button>
        <button
          className={`w-24 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            selectedFilter === "Under-13" ? "bg-neutral-400" : ""
          }`}
          onClick={() => handleFilterClick("Under-13")}
        >
          Under-13
        </button>
        <button
          className={`w-24 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            selectedFilter === "Under-16" ? "bg-neutral-400" : ""
          }`}
          onClick={() => handleFilterClick("Under-16")}
        >
          Under-16
        </button>
        <button
          className={`w-24 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform ${
            selectedFilter === "Under-19" ? "bg-neutral-400" : ""
          }`}
          onClick={() => handleFilterClick("Under-19")}
        >
          Under-19
        </button>
      </div>
      <div className="text-xs">
        *Under-categories consider 1<sup>st</sup> September of current year to
        calculate age as per BCCI guidelines.
      </div>
      <Link to="/Institutions/NewPlayerRegistration">
        <button className="w-36 p-2 border border-black rounded transform active:scale-x-75 active:scale-y-75 transition-transform">
          Add New Player
        </button>
      </Link>
    </div>
  );
};

export default FilterTab;
