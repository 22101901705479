import React from "react";

const PlayerListHeader = () => {
  return (
    <div className="grid grid-cols-10 border-black border-b sticky top-0 z-10 bg-slate-300">
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Sr. No.
      </div>
      <div className="col-span-2 flex items-center justify-center border-r border-black text-center">
        Player Name
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Gender
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Contact (Personal)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Contact (Father)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Contact (Mother)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Date of Birth
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Date of Joining
      </div>
      {/* <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
          Institution ID
        </div> */}
      <div className="col-span-1 flex items-center justify-center border-black text-center">
        Pending Fees
      </div>
    </div>
  );
};

export default PlayerListHeader;
