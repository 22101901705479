import axios from "axios";

export const AppUsersPerMonth = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users-per-month`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const AppUsersPerYear = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users-per-year`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const LearningCenterLikesYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/learning-center-likes-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const LearningCenterRatingsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/learning-center-likes-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const LearningCenterViewsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/learning-center-views-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const MatchCenterLikesYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/match-center-likes-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const MatchCenterRatingsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/match-center-ratings-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const MatchCenterViewsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/match-center-views-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const LikeVideoRequestsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/like-video-requests-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const InstitutionPostsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/institution-posts-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const TasksYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/tasks-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const TasksAssignorYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/tasks-assignor-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const TasksAssigneeYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/tasks-assignee-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const VideoAnnotationsYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/video-annotations-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const MatchVideosTaggedYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/match-videos-tagged-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const PracticeVideosYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/practice-videos-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const ResponseFromCoachesYTD = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/response-from-coaches-ytd`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
