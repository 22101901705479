import React from "react";
import SearchTab from "../SearchTab";
import FiltersTab from "./FiltersTab";

const SearchAndFiltersSection = ({ searchInput, setSearchInput }) => {
  return (
    <section className="flex flex-row justify-between gap-4 items-center">
      <SearchTab searchInput={searchInput} setSearchInput={setSearchInput} />
      <FiltersTab />
    </section>
  );
};

export default SearchAndFiltersSection;
