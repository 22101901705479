import React from "react";

const ViewForm = ({
  updatedName,
  updatedAddress,
  updatedCity,
  updatedPincode,
  updatedGender,
  updatedParentOccupation,
  updatedContactNo,
  updatedFatherContactNo,
  updatedMotherContactNo,
  updatedDob,
  isDobNull,
  updatedDateOfJoining,
  isDateOfJoiningNull,
  updatedPreferredTiming,
  updatedNameOfAcademicInstitution,
  updatedBatchAllotted,
  updatedPersona,
  updatedPlayerProfile,
  updatedIsAllRounder,
  updatedIsWicketKeeper,
  updatedBatting,
  updatedBowling,
  updatedFielding,
  updatedMedicalCondition,
}) => {
  const displayItemsLeft = [
    { title: "Name", content: updatedName },
    { title: "Address", content: updatedAddress },
    { title: "City", content: updatedCity },
    { title: "Pincode", content: updatedPincode },
    { title: "Gender", content: updatedGender },
    {
      title: "Parents' Occupation",
      content: updatedParentOccupation
        ? updatedParentOccupation.charAt(0).toUpperCase() +
          updatedParentOccupation.slice(1)
        : null,
    },
    { title: "Contact No.", content: updatedContactNo },
    { title: "Father's Contact No.", content: updatedFatherContactNo },
    { title: "Mother's Contact No.", content: updatedMotherContactNo },
    {
      title: "Date of Birth",
      content: isDobNull
        ? ""
        : updatedDob
            .toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/\b0+/g, ""),
    },
    {
      title: "Date of Joining",
      content: isDateOfJoiningNull
        ? ""
        : updatedDateOfJoining
            .toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
            .replace(/\b0+/g, ""),
    },
    {
      title: "Preferred Timing",
      content: updatedPreferredTiming
        ? updatedPreferredTiming.charAt(0).toUpperCase() +
          updatedPreferredTiming.slice(1)
        : null,
    },

    {
      title: "Name of School/College",
      content: updatedNameOfAcademicInstitution,
    },
  ];

  const displayItemsRight = [
    {
      title: "Persona",
      content:
        updatedPersona == "2" ? "Coach" : updatedPersona == "1" ? "Player" : "",
    },
    {
      title: "Allrounder",
      content:
        updatedIsAllRounder !== null
          ? updatedIsAllRounder === "0"
            ? "No"
            : "Yes"
          : "N/A",
    },
    { title: "Player Profile", content: updatedPlayerProfile },
    {
      title: "Wicketkeeper",
      content: updatedIsWicketKeeper == "0" ? "No" : "Yes",
    },
    { title: "Batting", content: updatedBatting },
    { title: "Bowling", content: updatedBowling },
    {
      title: "Fielding",
      content: updatedFielding == "keeping" ? "Keeping" : updatedFielding,
    },
    {
      title: "Medical Condition",
      content: updatedMedicalCondition,
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-36 border border-black rounded p-10">
      <div className="flex flex-col gap-4">
        {displayItemsLeft.map((displayItem) => {
          //   console.log(displayItem.title, ":", displayItem.content);
          return (
            <div key={displayItem.title}>
              {displayItem.title}:{" "}
              {displayItem.content === null || displayItem.content === "" ? (
                <span style={{ color: "red" }}>No information in record.</span>
              ) : (
                displayItem.content
              )}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-4">
        {displayItemsRight.map((displayItem) => {
          // console.log(displayItem.title, ":", displayItem.content);
          return (
            <div key={displayItem.title}>
              {displayItem.title}:{" "}
              {displayItem.content === null || displayItem.content === "" ? (
                <span style={{ color: "red" }}>No information in record.</span>
              ) : (
                displayItem.content
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewForm;
