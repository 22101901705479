import axios from 'axios';






export const GetLiveStreamingVideos = async (analysis_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-live-streaming-videos`,
			analysis_id,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};



export const GetCropLiveAnalysisDetail = async (analysis_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-crop-live-analysis-detail`,
			analysis_id,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};


export const getCropedTaggedVideo = async (analysis_id) => {
	try {
		//   const res = await axios.post(
		// 	`${process.env.REACT_APP_BASE_URL}/get-live-analysis-detail`,
		// 	analysis_id,
		// 	{
		// 	  headers: {
		// 		"Content-Type": "application/json",
		// 		Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
		// 		  }`,
		// 	  },
		// 	}
		//   );
		//   return res.data;
	} catch (err) {
		console.log(err);
	}
};



export const DownloadMuxUploadAwsServer = async (analysis_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/download-mux-upload-aws-server/` + analysis_id,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};


export const CropAndTagAllLiveVideo = async (analysis_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/crop-and-tag-all-live-video/` + analysis_id,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const getMuxStatusFromAsset = async (asset_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-mux-status-from-asset/` + asset_id,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};


export const GetCropAnalysisDetail = async (analysis_id) => {
	try {
		const res = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/get-crop-analysis-detail`,
			analysis_id,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${JSON.parse(localStorage.getItem("WellplayedToken")).token
						}`,
				},
			}
		);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};