import React from "react";

const ActivePlansHeader = () => {
  return (
    <div className="grid grid-cols-11 border-black border-b sticky top-0 z-10 bg-slate-300">
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Sr. No.
      </div>
      <div className="col-span-2 flex items-center justify-center border-r border-black text-center">
        Subscriptions
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Subscribed on
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Subscription till
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Total Fee(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Discount(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Total Fees Paid(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Total Fees Due(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center border-r border-black text-center">
        Outstanding Monthly Fee(₹)
      </div>
      <div className="col-span-1 flex items-center justify-center text-center">
        Paid Till
      </div>
    </div>
  );
};

export default ActivePlansHeader;
