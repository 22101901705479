import React from "react";
import ListHeader from "./PlayerList/ListHeader";
import PlayerTable from "./PlayerList/PlayerTable";

const PlayerList = ({
  selectedPlayerIndex,
  setSelectedPlayerIndex,
  searchInput,
  selectedFilter,
  setSelectedPlayerName,
  loggedInUserInstitutionID,
  reloadPlayerList,
}) => {
  return (
    <section className="flex flex-col relative border border-black h-60 overflow-auto">
      <ListHeader />
      <PlayerTable
        searchInput={searchInput}
        selectedFilter={selectedFilter}
        setSelectedPlayerName={setSelectedPlayerName}
        loggedInUserInstitutionID={loggedInUserInstitutionID}
        setSelectedPlayerIndex={setSelectedPlayerIndex}
        selectedPlayerIndex={selectedPlayerIndex}
        reloadPlayerList={reloadPlayerList}
      />
    </section>
  );
};

export default PlayerList;
