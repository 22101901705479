import React from "react";
import Plot from "react-plotly.js";
// import Plot from 'plotly.js';

const Graph = (plotData) => {
  // const { title, yAxisTitle, xAxisTitle, x, y } = plotData.plotData;
  // const plotDataObject = plotData;
  // const { title, yAxisTitle, xAxisTitle, dataSets, xKeys, yKeys, queryFunction } = plotDataObject[Object.keys(plotDataObject)];
  const { category, subcategory, title, yAxisTitle, xAxisTitle, dataSets, xKeys, yKeys, queryFunction } = plotData.data;
  // console.log("in graph");
  // console.log(plotData);
  // console.log(plotData.title);
  // console.log(title);
  // console.log(x);
  // console.log(y);

  const plotDataSet = dataSets.map((dataSet, index) => ({
    x: dataSet.x,
    y: dataSet.y,
    type: "scatter",
    mode: "lines+markers",
    marker: { color: dataSet.color },
    name: dataSet.name,
  }));

  // console.log(plotDataSet);

  return (
    <Plot
      data={plotDataSet}
      layout={{
        width: 400,
        height: 300,
        title: { text: title, font: { size: 20 } },
        margin: {
          t: 80,
        },
        xaxis: {
          title: xAxisTitle,
          // tickangle: 45, // Rotate the x-axis labels by 45 degrees
        },
        yaxis: { title: yAxisTitle },
      }}
    />
  );
};

export default Graph;
