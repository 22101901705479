import React from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const AttendanceReports = () => {
    const [headerTitle, setHeaderTitle] = useOutletContext();
    useEffect(() => {
        setHeaderTitle("Attendance Reports");
    }, []);
}

export default AttendanceReports