import { Button, List, ListItem, ListItemButton, ListItemText, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { GetAllTournaments } from "../Tournament/Helper";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';
import {
  AddMatch,
  GetAllInstitutes,
  GetAllMatcheTypes,
  GetAllTeamMembersByTeamID,
  GetAllTeamsByInstituteID,
  AddPlayer,
  GetAppUserList,
  AddPlayerInTeam
} from "./Helper";
import { useForm } from "react-hook-form";

const Match = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const [headerTitle, setHeaderTitle] = useOutletContext();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitBDisabled, setSubmitBDisabled] = useState(false);
  const [submitAppUserDisabled, setSubmitAppUserDisabled] = useState(false);



  const [toggle, setToggle] = useState(true);
  const [newToggle, setNewToggle] = useState(true);
  const [instituteDetails, setInstituteDetails] = useState([null, null]);
  const [teamDetails, setTeamDetails] = useState([null, null]);
  const [tournaments, setTournaments] = useState([]);
  const [institutes, setInstitutes] = useState([[], []]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [teams, setTeams] = useState([[], []]);
  const [activeTeamIndex, setActiveTeamIndex] = useState();
  const [playerList, setPlayerList] = useState([[], []]);
  const [playerDetailList, setplayerDetailList] = useState([]);
  const [teamNames, setTeamNames] = useState(["", ""]);
  const [selectedValueTeamA, setSelectedValueTeamA] = useState('');
  const [selectedValueTeamB, setSelectedValueTeamB] = useState('');

  // useEffect(() => {
  // }, [toggle]);
  useEffect(() => {
    // //setToggle(!toggle);
    // setToggle(toggle => !toggle);
    setTimeout(() => {
      setToggle(!toggle);
    }, 0)
  }, [toggle]);
  useEffect(() => {
    if (activeTeamIndex !== undefined) {
      instituteDetails[activeTeamIndex] && GetAllTeamsByInstituteID(instituteDetails[activeTeamIndex])
        .then((data) => {
          var tempTeams = teams;
          tempTeams[activeTeamIndex] = data.teams;
          setTeams(tempTeams);

          setToggle(!toggle);
        })
        .catch((err) => {

        });
    }
  }, [activeTeamIndex]);
  useEffect(() => {
    setHeaderTitle("Create New Match");
    GetAllTournaments()
      .then((data) => {
        setTournaments(data.tournaments);
      })
      .catch((err) => {
      });
    GetAllInstitutes()
      .then((data) => {
        setInstitutes(data.institutions);
      })
      .catch((err) => {
      });
    GetAllMatcheTypes()
      .then((data) => {
        setMatchTypes(data.match_types);
      })
      .catch((err) => {
      });
  }, []);
  const onInstituteChange = (e, index) => {
    var temp = instituteDetails;
    temp[index] = e.target.value;
    setInstituteDetails(temp);
    setActiveTeamIndex(index);
    GetAllTeamsByInstituteID(temp[index])
      .then((data) => {
        var tempTeams = teams;
        tempTeams[index] = data.teams;
        setTeams(tempTeams);
        setToggle(!toggle);
        if (index == 0) {
          setSelectedValueTeamA('');
          var tempTeamNames = teamNames;
          tempTeamNames[index] = '';
          setTeamNames(tempTeamNames);
        }
        else {
          setSelectedValueTeamB('');
          var tempTeamNames = teamNames;
          tempTeamNames[index] = '';
          setTeamNames(tempTeamNames);
        }
      })
      .catch((err) => {
      });
  };

  const onTeamChange = (e, index) => {
    if (e.target.value === "Select Team") {
      var temp2 = teamDetails;
      temp2[index] = null;
      setTeamDetails(temp2);
      var tempTeamNames2 = teamNames;
      tempTeamNames2[index] = "";
      setTeamNames(tempTeamNames2);
      setToggle(!toggle);
    } else {
      if (index == 0) {
        setSelectedValueTeamA(e.target.value);
      } else {
        setSelectedValueTeamB(e.target.value);
      }
      var temp = teamDetails;
      temp[index] = JSON.parse(e.target.value).id;
      var tempTeamNames = teamNames;
      tempTeamNames[index] = JSON.parse(e.target.value).name;
      setTeamNames(tempTeamNames);
      tempTeamNames != "Select Team" && GetAllTeamMembersByTeamID(JSON.parse(e.target.value).id)
        .then((data) => {
          var tempPlayerList = playerList;
          tempPlayerList[index] = data.team_members;
          setPlayerList(tempPlayerList);
          var tempPlayerDetailList = playerDetailList;
          tempPlayerDetailList[index] = data.all_team_members_with_details;
          setplayerDetailList(tempPlayerDetailList)
        })
        .catch((err) => {
        });
      setTeamDetails(temp);
      setToggle(!toggle);
    }
  };

  const [checkedItems, setCheckedItems] = useState({
    teamA: {},
    teamB: {},
  });
  const [checkedItemsTeamA, setCheckedItemsTeamA] = useState([]);
  const [checkedItemsTeamB, setCheckedItemsTeamB] = useState([]);
  const handleToggle = (team, id) => () => {
    const isCheckedTeamA = checkedItemsTeamA.includes(id);
    const isCheckedTeamB = checkedItemsTeamB.includes(id);
    if (team == 'teamA') {
      if (isCheckedTeamA) {
        setCheckedItemsTeamA(checkedItemsTeamA.filter((checkedItemTeamA) => checkedItemTeamA !== id));
      } else {
        setCheckedItemsTeamA([...checkedItemsTeamA, id]);
      }
    }
    else {
      if (isCheckedTeamB) {
        setCheckedItemsTeamB(checkedItemsTeamB.filter((checkedItemTeamB) => checkedItemTeamB !== id));
      } else {
        setCheckedItemsTeamB([...checkedItemsTeamB, id]);
      }
    }
  };

  useEffect(() => {
  }, [checkedItems]);
  const handleSubmitMatch = (details) => {
    const objA = checkedItems.teamA;
    const objB = checkedItems.teamB;
    let TeamAData = {};
    for (let i = 0; i < checkedItemsTeamA.length; i++) {
      TeamAData[checkedItemsTeamA[i]] = true;
    }
    let TeamBData = {};
    for (let i = 0; i < checkedItemsTeamB.length; i++) {
      TeamBData[checkedItemsTeamB[i]] = true;
    }
    if ((checkedItemsTeamA.length >= 2) && (checkedItemsTeamB.length >= 2)) {
      const userData = JSON.parse(localStorage.getItem("WellplayedToken"));
      const userId = userData.user.id; // Get the user ID
      AddMatch({
        matchDetails: {
          name: details.name,
          type: details.type,
          date: details.date,
          tournament_id: details.tournament_id,
          overs: details.overs

        }, instituteDetails: instituteDetails, teamDetails: teamDetails, teamMatchMembers: {
          "teamA": TeamAData,
          "teamB": TeamBData
        },
        matchcreated_by: userId, // Store the user ID
      }).then((data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Match added successfully'
          });
          reset();
          setPlayerList([[], []]);
          setTeamNames([[], []])
          setTeams([[], []])
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Unable to add Match'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Atleast 2 player in both team are compulsory'
      })
    }
  }
  const handleTeamAPlayerFullname = (val) => (e) => {
    e.preventDefault();
    setTeamAPlayerFullname(e.target.value);
  };
  const handleTeamAPlayerMobile = (val) => (e) => {
    e.preventDefault();
    setTeamAPlayerMobile(e.target.value);
  };
  const handleTeamAPlayerBatting = (val) => (e) => {
    e.preventDefault();
    setTeamAPlayerBatting(e.target.value);
  };
  const handleTeamAPlayerBowling = (val) => (e) => {
    e.preventDefault();
    setTeamAPlayerBowling(e.target.value);
  };
  const handleTeamAModalSubmit = (e) => {
    e.preventDefault();
    if ((TeamAPlayerFullname == '') || (TeamAPlayerMobile == '') || (TeamAPlayerBatting == '') || (TeamAPlayerBowling == '') || (TeamAPlayerBatting == 'Select Batting Type') || (TeamAPlayerBowling == 'Select Bowling Type')) {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'All Fields Are Compulsory'
      })
    }
    else {
      setSubmitDisabled(true); // Disable the button
      AddPlayer({
        playerDetails: {
          fullname: TeamAPlayerFullname,
          mobile: TeamAPlayerMobile,
          batting: TeamAPlayerBatting,
          bowling: TeamAPlayerBowling,
        }, instituteDetails: instituteDetails[0], teamDetails: teamDetails[0], button: "add"
      }).then((data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player added successfully'
          });
          setShowTeamAModal(false)
          GetAllTeamMembersByTeamID(teamDetails[0])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[0] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[0] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          setTeamAPlayerFullname("")
          setTeamAPlayerMobile("")
          setTeamAPlayerBatting("")
          setTeamAPlayerBowling("")
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Player already exist, Please try another mobile number'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      }).finally(() => {
        setSubmitDisabled(false); // Enable the button
      });
    }
  }
  const [showTeamAModal, setShowTeamAModal] = React.useState(false);
  const [TeamAPlayerFullname, setTeamAPlayerFullname] = useState("");
  const [TeamAPlayerMobile, setTeamAPlayerMobile] = useState("");
  const [TeamAPlayerBatting, setTeamAPlayerBatting] = useState("");
  const [TeamAPlayerBowling, setTeamAPlayerBowling] = useState("");
  const [showTeamBModal, setShowTeamBModal] = React.useState(false);
  const [TeamBPlayerFullname, setTeamBPlayerFullname] = useState("");
  const [TeamBPlayerMobile, setTeamBPlayerMobile] = useState("");
  const [TeamBPlayerBatting, setTeamBPlayerBatting] = useState("");
  const [TeamBPlayerBowling, setTeamBPlayerBowling] = useState("");
  const handleTeamBPlayerFullname = (val) => (e) => {
    e.preventDefault();
    setTeamBPlayerFullname(e.target.value);
  };
  const handleTeamBPlayerMobile = (val) => (e) => {
    e.preventDefault();
    setTeamBPlayerMobile(e.target.value);
  };
  const handleTeamBPlayerBatting = (val) => (e) => {
    e.preventDefault();
    setTeamBPlayerBatting(e.target.value);
  };
  const handleTeamBPlayerBowling = (val) => (e) => {
    e.preventDefault();
    setTeamBPlayerBowling(e.target.value);
  };
  const handleTeamBModalSubmit = (e) => {
    e.preventDefault();
    if ((TeamBPlayerFullname == '') || (TeamBPlayerMobile == '') || (TeamBPlayerBatting == '') || (TeamBPlayerBowling == '') || (TeamBPlayerBatting == 'Select Batting Type') || (TeamBPlayerBowling == 'Select Bowling Type')) {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'All Fields Are Compulsory'
      })
    }
    else {
      setSubmitBDisabled(true);
      AddPlayer({
        playerDetails: {
          fullname: TeamBPlayerFullname,
          mobile: TeamBPlayerMobile,
          batting: TeamBPlayerBatting,
          bowling: TeamBPlayerBowling,
        }, instituteDetails: instituteDetails[1], teamDetails: teamDetails[1], button: "add"
      }).then((data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player added successfully'
          });
          setShowTeamBModal(false)
          GetAllTeamMembersByTeamID(teamDetails[1])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[1] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[1] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          setTeamBPlayerFullname("")
          setTeamBPlayerMobile("")
          setTeamBPlayerBatting("")
          setTeamBPlayerBowling("")
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Player already exist, Please try another mobile number'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      }).finally(() => {
        setSubmitBDisabled(false); // Enable the button
      });
    }
  }

  const batting_options = ['Right Hand', 'Left Hand'];
  const bowling_options = ['Fast', 'Leg Spin', 'Off Spin'];
  const [showPlayerEditModal, setShowPlayerEditModal] = React.useState(false);
  const [showPlayerEditModal2, setShowPlayerEditModal2] = React.useState(false);
  const [showPlayerEditModal3, setShowPlayerEditModal3] = React.useState(false);
  const [PlayerInfo, setPlayerInfo] = useState("");
  const [PlayerId, setPlayerId] = useState();
  const [PlayerBatting, setPlayerBatting] = useState("");
  const [PlayerBowling, setPlayerBowling] = useState("");
  const [teamType, setTeamType] = useState("teamA");
  const OpenPlayerEditModal = (playerName, val, teamType) => {
    setTeamType(teamType);
    setShowPlayerEditModal(true)
    setPlayerId(val.id)
    setPlayerInfo(playerName)
    setPlayerBatting(val.batting)
    setPlayerBowling(val.bowling)
  }
  const handlePlayerBatting = (val) => (e) => {
    e.preventDefault();
    setPlayerBatting(e.target.value);
  };
  const handlePlayerBowling = (val) => (e) => {
    e.preventDefault();
    setPlayerBowling(e.target.value);
  };
  const handleModalSubmit = (event) => {
    event.preventDefault();
    setShowTeamBModal(false)
    if (teamType == "teamA") {
      AddPlayer({
        playerDetails: {
          id: PlayerId,
          batting: PlayerBatting,
          bowling: PlayerBowling,
        }, instituteDetails: instituteDetails[0], teamDetails: teamDetails[0], button: "edit"
      }).then((data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player profile has been updated successfully'
          });
          setShowPlayerEditModal(false)
          GetAllTeamMembersByTeamID(teamDetails[0])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[0] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[0] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          setTeamBPlayerFullname("")
          setTeamBPlayerMobile("")
          setTeamBPlayerBatting("")
          setTeamBPlayerBowling("")
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Unable to add Player'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      })
    } else {
      AddPlayer({
        playerDetails: {
          id: PlayerId,
          batting: PlayerBatting,
          bowling: PlayerBowling,
        }, instituteDetails: instituteDetails[1], teamDetails: teamDetails[1], button: "edit"
      }).then((data) => {
        if (data?.status) {
          Swal.fire({
            icon: 'success',
            title: '🚀',
            text: 'Player profile has been updated successfully'
          });
          setShowPlayerEditModal(false)
          GetAllTeamMembersByTeamID(teamDetails[1])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[1] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[1] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          setTeamBPlayerFullname("")
          setTeamBPlayerMobile("")
          setTeamBPlayerBatting("")
          setTeamBPlayerBowling("")
        } else {
          Swal.fire({
            icon: 'error',
            title: '👎',
            text: 'Unable to add Player'
          })
        }
      }).catch((err) => {
        Swal.fire({
          icon: 'error',
          title: '⛔',
          text: err
        })
      })
    }
  }
  const [appUserList, setAppUserList] = useState([])
  const [appUser, setAppUser] = useState()
  const OpenPlayerEditModal2 = () => {
    setShowPlayerEditModal2(true)
    GetAppUserList()
      .then((data) => {
        setAppUserList(data.data)
      })
      .catch((err) => {
      });
  }
  const OpenPlayerEditModal3 = () => {
    setShowPlayerEditModal3(true)
    GetAppUserList()
      .then((data) => {
        setAppUserList(data.data)
      })
      .catch((err) => {
      });
  }
  const handleAppUser = (val) => (e) => {
    e.preventDefault();
    setAppUser(e.target.value);
  };
  const handleAppUserModalSubmit = (val) => (e) => {
    if ((appUser != '') && (appUser != null) && (appUser != 'Select Player')) {
      setSubmitAppUserDisabled(true);
      if (val == 'teamA') {
        AddPlayerInTeam({
          id: appUser,
          instituteDetails: instituteDetails[0], teamDetails: teamDetails[0]
        }).then((data) => {
          setAppUser('')
          GetAllTeamMembersByTeamID(teamDetails[0])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[0] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[0] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          if (data?.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Player added successfully'
            });
            setShowPlayerEditModal2(false)
          } else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Player already exist'
            })
          }
        }).catch((err) => {
          Swal.fire({
            icon: 'error',
            title: '⛔',
            text: err
          })
        }).finally(() => {
          setSubmitAppUserDisabled(false); // Enable the button
        });
      }
      else {
        setShowPlayerEditModal3(false)
        AddPlayerInTeam({
          id: appUser,
          instituteDetails: instituteDetails[1], teamDetails: teamDetails[1]
        }).then((data) => {
          setAppUser('')
          GetAllTeamMembersByTeamID(teamDetails[1])
            .then((data) => {
              var tempPlayerList = playerList;
              tempPlayerList[1] = data.team_members;
              setPlayerList(tempPlayerList);
              var tempPlayerDetailList = playerDetailList;
              tempPlayerDetailList[1] = data.all_team_members_with_details;
              setplayerDetailList(tempPlayerDetailList)
            })
            .catch((err) => {
            });
          if (data?.status) {
            Swal.fire({
              icon: 'success',
              title: '🚀',
              text: 'Player added successfully'
            });
            setShowPlayerEditModal2(false)
          } else {
            Swal.fire({
              icon: 'error',
              title: '👎',
              text: 'Player already exist'
            })
          }
        }).catch((err) => {
          Swal.fire({
            icon: 'error',
            title: '⛔',
            text: err
          })
        }).finally(() => {
          setSubmitAppUserDisabled(false); // Enable the button
        });
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: '👎',
        text: 'Please select player.'
      })
    }
    e.preventDefault();
  }
  return (
    <>
      <div>
        <h1
          className="text-center m-2"
          style={{
            color: "#000", backgroundColor: "#0df6e3",
            fontSize: "18px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
          }} >
          New Match
        </h1>
      </div>
      <form onSubmit={handleTeamAModalSubmit}>
        {showTeamAModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {"TEAM A: " + teamNames[0]}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowTeamAModal(false)}>
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700">
                          Full Name<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={TeamAPlayerFullname}
                          placeholder="Name"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          onChange={handleTeamAPlayerFullname(TeamAPlayerFullname)} />
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="overs"
                          className="form-label inline-block mb-2 text-gray-700">
                          Mobile No.<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="number"
                          id="overs"
                          value={TeamAPlayerMobile}
                          placeholder="Mobile No."
                          onChange={handleTeamAPlayerMobile(TeamAPlayerMobile)}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        />
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700" >
                          Batting<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={TeamAPlayerBatting} onChange={handleTeamAPlayerBatting(TeamAPlayerBatting)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Batting Type</option>
                          {batting_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700" >
                          Bowling<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={TeamAPlayerBowling} onChange={handleTeamAPlayerBowling(TeamAPlayerBowling)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Bowling Type</option>
                          {bowling_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowTeamAModal(false)}>
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit">
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitDisabled}>
                      {submitDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleTeamBModalSubmit}>
        {showTeamBModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {"TEAM B: " + teamNames[1]}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowTeamBModal(false)}>
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          className="form-label inline-block mb-2 text-gray-700">
                          Full Name<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={TeamBPlayerFullname}
                          placeholder="Name"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                          onChange={handleTeamBPlayerFullname(TeamBPlayerFullname)}
                        />
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="overs"
                          className="form-label inline-block mb-2 text-gray-700">
                          Mobile No.<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <input
                          type="number"
                          id="overs"
                          value={TeamBPlayerMobile}
                          placeholder="Mobile No."
                          onChange={handleTeamBPlayerMobile(TeamBPlayerMobile)}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                        />
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700"
                        >
                          Batting<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={TeamBPlayerBatting} onChange={handleTeamBPlayerBatting(TeamBPlayerBatting)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Batting Type</option>
                          {batting_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700">
                          Bowling<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={TeamBPlayerBowling} onChange={handleTeamBPlayerBowling(TeamBPlayerBowling)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Bowling Type</option>
                          {bowling_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowTeamBModal(false)}>
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit">
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitBDisabled}>
                      {submitBDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleModalSubmit}>
        {showPlayerEditModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {PlayerInfo}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowPlayerEditModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700" >
                          Batting
                        </label>
                        <select value={PlayerBatting} onChange={handlePlayerBatting(PlayerBatting)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Batting Type</option>
                          {batting_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700">
                          Bowling
                        </label>
                        <select value={PlayerBowling} onChange={handlePlayerBowling(PlayerBowling)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Bowling Type</option>
                          {bowling_options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowPlayerEditModal(false)} >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit" >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleAppUserModalSubmit("teamA")}>
        {showPlayerEditModal2 ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {"TEAM A: " + teamNames[0]}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowPlayerEditModal2(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700">
                          Players<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={appUser} onChange={handleAppUser(appUser)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Player</option>
                          {appUserList.map((appuser, index) => (
                            <option key={appuser.id} value={appuser.id}>
                              {appuser.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowPlayerEditModal2(false)}>
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit">
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitAppUserDisabled}>
                      {submitAppUserDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleAppUserModalSubmit("teamB")}>
        {showPlayerEditModal3 ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {"TEAM B: " + teamNames[1]}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowPlayerEditModal3(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="m-2 grid grid-cols-3 gap-5">
                      <div className="form-control">
                        <label
                          htmlFor="name"
                          className="form-label inline-block mb-2 text-gray-700" >
                          Players<span style={{ color: '#FF0000' }}>*</span>
                        </label>
                        <select value={appUser} onChange={handleAppUser(appUser)} className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none">
                          <option selected>Select Player</option>
                          {appUserList.map((appuser, index) => (
                            <option key={appuser.id} value={appuser.id}>
                              {appuser.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowPlayerEditModal3(false)}
                    >
                      Close
                    </button>
                    {/* <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save Changes
                    </button> */}
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      disabled={submitAppUserDisabled}>
                      {submitAppUserDisabled ? 'Saving...' : 'Save Changes'}
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </form>
      <form onSubmit={handleSubmit(handleSubmitMatch)}>
        <div>
          <div className="bg-white p-5 h-max shadow-lg" style={{ paddingTop: '5px' }}>
            <h1
              className="m-2 uppercase"
              style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "15px" }}>
              Match Details
            </h1>
            <div className="m-2 grid grid-cols-3 gap-5">
              <div className="form-control">
                <label
                  htmlFor="name"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Match Name<span style={{ color: '#FF0000' }}>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name", {
                    required: true,
                    pattern: /^[a-zA-Z0-9 -]*$/,
                  })}
                  placeholder="Match Name"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                />
                {errors.name && errors.name.type === "required" && (
                  <p className="errorMsg">Match name is required.</p>
                )}
                {errors.name && errors.name.type === "pattern" && (
                  <p className="errorMsg">Match name can't allow any special character except(-)</p>
                )}
              </div>
              <div className="form-control">
                <label
                  htmlFor="mtype"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Match Type<span style={{ color: '#FF0000' }}>*</span>
                </label>
                <select
                  {...register("type", {
                    required: true,
                    validate: () => getValues("type") != "Select Match Type",
                  })}
                  id="mtype"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                >
                  <option selected>Select Match Type</option>
                  {matchTypes.map((type, index) => {
                    return <option value={type.type}>{type.type}</option>;
                  })}
                </select>
                {errors.type && errors.type.type === "required" && (
                  <p className="errorMsg">Match type is required.</p>
                )}
                {errors.type && errors.type.type === "validate" && (
                  <p className="errorMsg">Match type is required.</p>
                )}
              </div>
              <div className="form-control">
                <label
                  htmlFor="overs"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Overs<span style={{ color: '#FF0000' }}>*</span>
                </label>
                <input
                  {...register("overs", {
                    required: true,
                  })}
                  type="number"
                  id="overs"
                  placeholder="Number of Overs"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                />
                {errors.overs && errors.overs.type === "required" && (
                  <p className="errorMsg">Overs Values is required.</p>
                )}
              </div>
              <div className="form-control">
                <label
                  htmlFor="tournament"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Tournament<span style={{ color: '#FF0000' }}>*</span>
                </label>
                <select
                  {...register("tournament_id", {
                    required: true,
                    validate: () =>
                      getValues("tournament_id").toString() !=
                      "Select Tournament",
                  })}
                  id="tournament"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                >
                  <option selected>Select Tournament</option>
                  {tournaments.map((tournament, index) => {
                    return (
                      <option value={tournament.id}>{tournament.name}</option>
                    );
                  })}
                </select>
                {errors.tournament_id && (
                  <p className="errorMsg">Tournament is required.</p>
                )}
              </div>
              <div className="form-control">
                <label
                  htmlFor="mdate"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Match Date<span style={{ color: '#FF0000' }}>*</span>
                </label>
                <input
                  type="date"
                  id="mdate"
                  {...register("date", {
                    required: true,
                    validate: (value) => {
                      let tournament = tournaments.find(
                        (el) => el.id == getValues("tournament_id")
                      );

                      if (tournament !== undefined) {
                        let { start_date, end_date } = tournament;
                        start_date = new Date(start_date);
                        end_date = new Date(end_date);

                        const cur = new Date(value);
                        return start_date <= cur && cur <= end_date;
                      } else return true;
                    },
                  })}
                  placeholder="Match Date"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                />
                {errors.date && (
                  <p className="errorMsg">
                    {errors.date.type === "required"
                      ? "Match Date is required."
                      : "Match Date should be between Tournament Start and End Date."}
                  </p>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="bg-white p-5 h-max shadow-lg">
            <h1
              className="m-2 uppercase"
              style={{ color: "#0DF6E3", fontWeight: "bold", fontSize: "15px" }}
            >
              Institute Details
            </h1>
            <div className="m-2 grid grid-cols-2 gap-5">
              <div>
                <div className="form-control">
                  <label
                    htmlFor="institute1"
                    className="form-label inline-block mb-2 text-gray-700">
                    Institute<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <select
                    id="institute1"
                    {...register("0", {
                      required: true,
                      validate: () => getValues("0") != "Select Institute",
                    })}
                    onChange={(e) => onInstituteChange(e, 0)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                    <option selected>Select Institute</option>
                    {institutes.map((institute, index) => {
                      return (
                        <option value={institute.id}>{institute.name}</option>
                      );
                    })}
                  </select>
                  {errors["0"] && errors["0"].type === "required" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                  {errors["0"] && errors["0"].type === "validate" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                </div>
                <div className="pt-5 form-control">
                  <label
                    htmlFor="team1"
                    className="form-label inline-block mb-2 text-gray-700" >
                    Team<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <select
                    value={selectedValueTeamA}
                    id="team1"
                    {...register("team1", {
                      required: true,
                      validate: () => getValues("team1") != "Select Team",
                    })}
                    onChange={(e) => onTeamChange(e, 0)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                    <option selected>Select Team</option>
                    {teams[0].length > 0
                      ? teams[0]?.map((team, index) => {
                        return (
                          <option value={JSON.stringify(team)}>
                            {team.name}
                          </option>
                        );
                      })
                      : ""}
                  </select>
                  {errors.team1 && errors.team1.type === "required" && (
                    <p className="errorMsg">Team Name is required.</p>
                  )}
                  {errors.team1 && errors.team1.type === "validate" && (
                    <p className="errorMsg">Team Name is required.</p>
                  )}
                </div>
              </div>
              <div>
                <div className="form-control">
                  <label
                    htmlFor="institute2"
                    className="form-label inline-block mb-2 text-gray-700" >
                    Institute<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <select
                    id="institute2"
                    {...register("1", {
                      required: true,
                      validate: () => getValues("1") != "Select Institute",
                    })}
                    onChange={(e) => onInstituteChange(e, 1)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                    <option selected>Select Institute</option>
                    {institutes.map((institute, index) => {
                      return (
                        <option value={institute.id}>{institute.name}</option>
                      );
                    })}
                  </select>
                  {errors["1"] && errors["1"].type === "required" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                  {errors["1"] && errors["1"].type === "validate" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                </div>
                <div className="pt-5 form-control">
                  <label
                    htmlFor="team2"
                    className="form-label inline-block mb-2 text-gray-700">
                    Team<span style={{ color: '#FF0000' }}>*</span>
                  </label>
                  <select
                    value={selectedValueTeamB}
                    id="team2"
                    {...register("team2", {
                      required: true,
                      validate: () => getValues("team2") != "Select Team",
                    })}
                    onChange={(e) => onTeamChange(e, 1)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none" >
                    <option selected >Select Team</option>
                    {teams[1].length > 0
                      ? teams[1]?.map((team, index) => {
                        return (
                          <option value={JSON.stringify(team)}>
                            {team.name}
                          </option>
                        );
                      })
                      : ""}
                  </select>
                  {errors.team2 && errors.team2.type === "required" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                  {errors.team2 && errors.team2.type === "validate" && (
                    <p className="errorMsg">Institute Name is required.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="grid grid-cols-2 gap-7">
            {teamNames[0] != '' ? (
              <div className="bg-white p-5 h-max shadow-lg">
                <div className=" grid grid-cols-2 gap-7">
                  <h1
                    className=" uppercase"
                    style={{
                      color: "#0DF6E3",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {"TEAM A: " + teamNames[0]}
                  </h1>
                  <p className=" uppercase" style={{
                    textAlign: "end", color: "#0DF6E3",
                    fontWeight: "bold",
                    fontSize: "15px"
                  }}> <Button
                    type="button"
                    size="large"
                    style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
                    variant="contained"
                    onClick={() => OpenPlayerEditModal2()}>
                      <AddIcon />
                    </Button> <Button
                      type="button"
                      size="large"
                      style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
                      variant="contained"
                      onClick={() => setShowTeamAModal(true)}>
                      <GroupAddIcon />
                    </Button></p>
                </div>
                <div style={{ maxHeight: "635px", overflowY: "auto" }}>
                  {playerList[0].length > 0 ? (
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Player Name</th>
                          <th>Batting</th>
                          <th>Bowling</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerList[0].map((player, index) => {
                          let playerdetail = playerDetailList[0][index]; // Get the details for the current player
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td onClick={() => OpenPlayerEditModal(`${player}`, playerdetail, "teamA")}>{player}</td>
                              {playerdetail && (
                                <td>{playerdetail.batting}</td>
                              )}
                              {playerdetail && (
                                <td>{playerdetail.bowling}</td>
                              )}
                              {playerdetail && (
                                <td><Checkbox checked={checkedItemsTeamA.includes(`${playerdetail.id}`)} onClick={handleToggle("teamA", `${playerdetail.id}`)} /></td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div style={{
                      color: "#0DF6E3",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}>No data found</div>
                  )}
                </div>

              </div>
            )
              : (
                <div className="bg-white p-5 h-max shadow-lg">
                  <div style={{
                    color: "#0DF6E3",
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center"
                  }}>Please Select team A</div></div>
              )}
            {teamNames[1] != '' ? (
              <div className="bg-white p-5 h-max shadow-lg">

                <div className=" grid grid-cols-2 gap-7">

                  <h1
                    className="m-2 uppercase"
                    style={{
                      color: "#0DF6E3",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {"TEAM B: " + teamNames[1]}
                  </h1>
                  <p className=" uppercase" style={{
                    textAlign: "end", color: "#0DF6E3",
                    fontWeight: "bold",
                    fontSize: "15px"
                  }}> <Button
                    type="button"
                    size="large"
                    style={{ backgroundColor: "#0DF6E3", color: "#2B303B", marginRight: "5px" }}
                    variant="contained"
                    onClick={() => OpenPlayerEditModal3()}
                  >
                      <AddIcon />
                    </Button><Button
                      type="button"
                      size="large"
                      style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
                      variant="contained"
                      onClick={() => setShowTeamBModal(true)}
                    >
                      <GroupAddIcon />
                    </Button></p>
                </div>
                <div style={{ maxHeight: "635px", overflowY: "auto" }}>
                  {playerList[1].length > 0 ? (
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Player Name</th>
                          <th>Batting</th>
                          <th>Bowling</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerList[1].map((player, index) => {
                          let playerdetail = playerDetailList[1][index]; // Get the details for the current player
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td onClick={() => OpenPlayerEditModal(`${player}`, playerdetail, "teamB")}>{player}</td>
                              {playerdetail && (
                                <td>{playerdetail.batting}</td>
                              )}
                              {playerdetail && (
                                <td>{playerdetail.bowling}</td>
                              )}
                              {playerdetail && (
                                <td><Checkbox checked={checkedItemsTeamB.includes(`${playerdetail.id}`)} onClick={handleToggle("teamB", `${playerdetail.id}`)} /></td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div style={{
                      color: "#0DF6E3",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}>No data found</div>
                  )}
                </div>

                {/* <div style={{ maxHeight: "635px", overflowY: "auto" }}>
                  {playerList[1].length > 0 ? (
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      {playerList[1].map((player, index) => {
                        let playerdetail = playerDetailList[1][index]; // Get the details for the current player
                        return (
                          <ListItem key={index} component="div" disablePadding >
                            <ListItemButton >
                              <ListItemText primary={`${index + 1}. ${player}`} onClick={() => OpenPlayerEditModal(`${player}`, playerdetail, "teamB")} />
                              {playerdetail && ( // Check if playerdetail exists before rendering
                                <ListItemText primary={` ${playerdetail.batting}`} />
                              )}
                              {playerdetail && ( // Check if playerdetail exists before rendering
                                <ListItemText primary={` ${playerdetail.bowling}`} />
                              )}
                              {playerdetail && ( // Check if playerdetail exists before rendering
                                <Checkbox checked={checkedItemsTeamB.includes(`${playerdetail.id}`)} onClick={handleToggle("teamB", `${playerdetail.id}`)} />
                              )}
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : (
                    <div style={{
                      color: "#0DF6E3",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center"
                    }}>No data found</div>
                  )}
                </div> */}
              </div>
            )
              : (
                <div className="bg-white p-5 h-max shadow-lg">
                  <div style={{
                    color: "#0DF6E3",
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center"
                  }}>Please Select team B</div></div>
              )}
          </div>
          <div className="text-right pt-5" style={{ marginBottom: '10px', marginRight: '10px' }}>
            <Button
              type="submit"
              size="large"
              style={{ backgroundColor: "#0DF6E3", color: "#2B303B" }}
              variant="contained"
            >
              Create Match
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Match;
